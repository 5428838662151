import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Subscription } from 'rxjs';
import { CurrentUser } from 'src/app/_models/CurrentUser';
@Component({
    selector: 'app-connection',
    templateUrl: './connection.component.html',
    styleUrls: ['./connection.component.scss']
})
export class ConnectionComponent implements OnInit, OnDestroy {
    ConnectionData: any
    isspiner: false
    selectedIndex: any = 0
    sub: Subscription;
    sub1: Subscription;
    currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    constructor(
        private toastr: ToastrService,
        private _snackBar: MatSnackBar,
        private behaviorService: BehaviorService,
        private _mainAPiServiceService: MainAPiServiceService,
    ) { }

      /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit(): void {
        this.getConnection()
    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe();
        this.sub1?.unsubscribe();
    }

    /**
     * This function is used to set the index data
     * @param index -index data value
     */
    setIndex(index: number) {
        this.selectedIndex = index;
    }

    /**
     * This unction is used to get the connection data value
     */
    getConnection() {
        this.ConnectionData = []
        this.sub = this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: { USERGUID: this.currentUser.UserGuid } }, 'oauth-connections').subscribe(response => {
            if (response.CODE === 200 && response.STATUS === 'success') {
                this.ConnectionData = response.DATA;
                this.isspiner = false
                // this.toastr.success('Connection successfully');
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.isspiner = false
                this.toastr.warning(response.MESSAGE);
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.isspiner = false
                this.toastr.error(response.MESSAGE);
            } else if (response.MESSAGE == 'Not logged in') {
                // this.dialogRef.close(false);
            }
        }, error => {
            this.isspiner = false
            this.toastr.error(error);
        });

    }

    /**
     * This function is used to refresh the connection tab data
     */
    refreshConnectionTab() {
        this.getConnection()
    }

    /**
     * This function is used to Delete the connection data value 
     * @param connection -connection data value.
     * @param index -index data value
     */
    DeleteConnection(connection: any, index: any) {
        if (connection.STATUS == 'Connected') {
            this.sub1 = this._mainAPiServiceService.getSetData({ Action: "Delete", DATA: { USERGUID: this.currentUser.UserGuid, "TokenType": connection.TOKENTYPE } }, 'oauth-connections').subscribe(response => {
                if (response.CODE === 200 && response.STATUS === 'success') {
                    this.isspiner = false
                    this.toastr.success('Delete Connection successfully');
                    this.refreshConnectionTab()
                } else if (response.CODE == 451 && response.STATUS == 'warning') {
                    this.isspiner = false
                    this.toastr.warning(response.MESSAGE);
                } else if (response.CODE == 450 && response.STATUS == 'error') {
                    this.isspiner = false
                    this.toastr.error(response.MESSAGE);
                } else if (response.MESSAGE == 'Not logged in') {
                    // this.dialogRef.close(false);
                }
            }, error => {
                this.isspiner = false
                this.toastr.error(error);
            });
        } else if (connection.STATUS == 'Disconnected') {
            let payload = {
                "Action": "OAuth2Start",
                "VALIDATEONLY": false,
                "Data": {
                    "USERGUID": this.currentUser.UserGuid,
                    "AuthType": connection.TOKENTYPE
                }
            }
            this._mainAPiServiceService
            .getSetData(payload, 'oauth-connections')
            .subscribe((response) => {
                if(response.CODE == 200 && response.STATUS == "success") {
                 var win = window.open(
                        response.DATA[0].AuthorizationUrl,
                        "targetWindow",
                        `toolbar=no,
                        location=no,
                        status=no,
                        menubar=no,
                        scrollbars=yes,
                        resizable=yes,
                        width=800,
                        height=600`
                    );

                    var timer = setInterval(() => {
                        if (win.closed) {
                            clearInterval(timer);
                            this.getConnection()
                        }
                    }, 1000);
                }
            });
            // window.open(connection.CONNECTURL,'_blank')
        //     var win = window.open(connection.CONNECTURL, 'targetWindow',
        //         `toolbar=no,
        //   location=no,
        //   status=no,
        //   menubar=no,
        //   scrollbars=yes,
        //   resizable=yes,
        //   width=800,
        //   height=600`);
        //     var timer = setInterval(() => {
        //         if (win.closed) {
        //             clearInterval(timer);
        //             this.getConnection()
        //         }
        //     }, 1000);
        }

    }
}
