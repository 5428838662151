import { BehaviorService } from '@_services/Behavior.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DropDownTreeComponent, SelectEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { Subscription } from 'rxjs';
import { CurrentUser } from 'src/app/_models/CurrentUser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bind-dropdown-tree',
  templateUrl: './bind-dropdown-tree.component.html',
  styleUrls: ['./bind-dropdown-tree.component.scss']
})
export class BindDropdownTreeComponent implements OnInit, OnDestroy {
  @ViewChild("sample", { static: false }) dropdownTree: DropDownTreeComponent;
  @Input() id: any = "";
  @Input() attechmentkey: any = "";
  @Input() Email
  isLoadingResults: boolean;
  matterData: any;
  FolderData: any;
  fieldsEjs: any;
  FolderListArray: any = [];
  expandexNodelist: any = [];
  public treeSettings: any = { loadOnDemand: true };
  windowNameId:any;
  sub: Subscription;
  sub1: Subscription;
  currentuser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
  constructor(
    public behaviorService: BehaviorService,
    private _mainAPiServiceService: MainAPiServiceService,
  ) {
    // this.behaviorService.MatterData$.subscribe(result => {
    //   this.matterData = result;
    // });

    this.sub = this.behaviorService.MatterData$.subscribe(result => {
           const materIDofTab = window.name.split("_");
      this.windowNameId = (materIDofTab && materIDofTab[1])?materIDofTab[1] : undefined;
       this.matterData = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
       if (this.matterData) {
       } else {
         if (result) {
           this.matterData = result;
           localStorage.setItem(this.windowNameId || 'set_active_matters',JSON.stringify(this.matterData))
         }
       }
     });

    this.getFolder({ DONTRECURSESUBFOLDERS: true, DONTADDROOTENTRY: true, FOLDERNAME: '' }, "rootLevel");
  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
  }

  ngOnDestroy(): void {
      this.sub?.unsubscribe();
      this.sub1?.unsubscribe();
  }
  
  /**
   * This function is used to get the folder
   * @param postData 
   * @param levels 
   */
  getFolder(postData, levels) {
    this.isLoadingResults = true;
    let currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
    postData.MATTERGUID = this.matterData.MATTERGUID;
    let Data=postData;
    this.sub1 = this._mainAPiServiceService.getSetData({"Action": "NetDriveGetMatterFolders",Data}, 'cloud-storage').subscribe(res => {
      if (res.CODE == 200 && res.STATUS == "success") {
        this.isLoadingResults = false;
        this.FolderData = res.DATA.FOLDERS;

        if (levels == 'rootLevel') {
          this.FolderData.forEach(e => {
            e.hasChild = true;
            e.expanded = false;
            // let tempData = e;
            this.FolderListArray.push(e);
            // tempData.FOLDERS.forEach(element => {
            //   element.hasChild = true;
            //   element.expanded = false;
            //   element.pid = tempData.FULLPATH;
            //   this.FolderListArray.push(element);
            // });
          });
          this.fieldsEjs = { dataSource: this.FolderListArray, value: 'FULLPATH', text: 'FOLDERNAME', parentValue: 'pid', hasChildren: 'hasChild' };
        } else {
          this.FolderData.forEach(element => {
            element.hasChild = true;
            element.expanded = false;
            element.pid = postData.FOLDERNAME;
            this.FolderListArray.push(element);
          });

          this.expandexNodelist = this.expandexNodelist.filter((element, i) => i === this.expandexNodelist.indexOf(element));
          this.FolderListArray.forEach(item => {
            if (this.expandexNodelist.includes(item.FULLPATH) || item.FULLPATH == "<MatterFolder>") {
              item.expanded = true;
              item.hasChild = true;
            } else {
              item.hasChild = true;
              item.expanded = false;
            }
          });
          this.dropdownTree.refresh();
          (this.dropdownTree as any).treeObj.nodeExpanding = this.triggerfun.bind(this);

          this.dropdownTree.showPopup();
          this.fieldsEjs = { dataSource: this.FolderListArray, value: 'FULLPATH', text: 'FOLDERNAME', parentValue: 'pid', hasChildren: 'hasChild' };
        }
      } else if (res.CODE == "432") {
        this.isLoadingResults = false;
        // window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetDrive&SessionToken=' + currentUser.SessionToken, 'targetWindow',
        // `toolbar=no,
        //  location=no,
        //  status=no,
        //  menubar=no,
        //  scrollbars=yes,
        //  resizable=yes,
        //  width=800,
        //  height=600`);
          let payload = {
            "Action": "OAuth2Start",
            "VALIDATEONLY": false,
            "Data": {
                "USERGUID": this.currentuser.UserGuid,
                "AuthType": "NetDrive"
            }
          }
          this._mainAPiServiceService
          .getSetData(payload, 'oauth-connections')
          .subscribe((response) => {
              if(response.CODE == 200 && response.STATUS == "success") {
                  window.open(
                      response.DATA[0].AuthorizationUrl,
                      "targetWindow",
                      `toolbar=no,
                      location=no,
                      status=no,
                      menubar=no,
                      scrollbars=yes,
                      resizable=yes,
                      width=800,
                      height=600`
                  );
              }
          });
      } else {
        this.isLoadingResults = false;
      }
    }, err => {
      this.isLoadingResults = false;
      // this.toastr.error(err);
    });
  }

  /**
   * This function is used to create the data value.
   */
  created(event) {
    (this.dropdownTree as any).treeObj.nodeExpanding = this.triggerfun.bind(this);
  }

  /**
   * This function is used to triggerfun data value
   */
  triggerfun(arges):void {
    if (!this.expandexNodelist.includes(arges.nodeData.id) && arges.nodeData.id != "<MatterFolder>") {
      this.expandexNodelist.push(arges.nodeData.id);
      this.getFolder({ DONTRECURSESUBFOLDERS: false, DONTADDROOTENTRY: true, FOLDERNAME: arges.nodeData.id }, 'subFolderLevel');
    }
  }

  /**
   * This function is used to change the Folder
   */
  FloderChnage($event: SelectEventArgs, key: any = '') {    
    this.behaviorService.setEjsDropDownTreeData({ event: $event, key: key,Email:this.Email })
  }

}
