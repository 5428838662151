import { Component, OnInit, Inject, ViewChild, ElementRef, OnDestroy } from "@angular/core";
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import * as ClassicEditor from "../../../../../../ckeditor.js";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete/index.js";
import { Router } from "@angular/router";
// import { SelectDocumentsComponent } from "src/app/main/pages/document-register/select-documents/select-documents.component";
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { environment } from "src/environments/environment";
// import { ContactCorresDetailsComponent } from 'src/app/main/pages/contact/contact-corres-details/contact-corres-details.component';
// import { MatterDialogComponent } from 'src/app/main/pages/time-entries/matter-dialog/matter-dialog.component';
// import 'rxjs/add/operator/filter';
// import { ContactSelectDialogComponent } from 'src/app/main/pages/contact/contact-select-dialog/contact-select-dialog.component';
import { ConfirmPopupComponent } from 'src/app/main/confirm-popup/confirm-popup.component';
// import { RenameEmailComponent } from "src/app/main/pages/inbox/rename-email/rename-email.component";
// import { DocumentDailogComponent } from "src/app/main/pages/document-register/document-dailog/document-dailog.component";
import Imgbase64uploderPlugin from '../../../../../helpers/CKeditor/imgbase64uploder';
import { DropDownTreeComponent, SelectEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { debounceTime, distinctUntilChanged, Observable, Subject, Subscription, switchMap, takeUntil } from "rxjs";
import { DatePipe } from '@angular/common';
import { SelectDocumentsComponent } from "@pages/document-register/select-documents/select-documents.component";
import { ContactSelectDialogComponent } from "@pages/contact/contact-select-dialog/contact-select-dialog.component";
import { ContactCorresDetailsComponent } from "@pages/contact/contact-corres-details/contact-corres-details.component";
import { RenameEmailComponent } from "@pages/inbox/rename-email/rename-email.component";
import { MatterDialogComponent } from "@pages/time-entries/matter-dialog/matter-dialog.component";
import { DocumentDailogComponent } from "@pages/document-register/document-dailog/document-dailog.component";
import { MainAPiServiceService } from "@_services/main-api-service.service";
import { BehaviorService } from "@_services/Behavior.service";
import { TierPermissionPipe } from "@_pipes/tier-permission.pipe";
import { CurrentUser } from "src/app/_models/CurrentUser.js";
import { HEIGHT, PASTECleanupSettings, iFRAME, QUICKToolbarSettings, TOOLbarSettings, INSERTImageSettings, fontFamily, backgroundColor, fontColor } from 'src/app/_constant/rich-text-editor-toolbarFeature';
import { MatSlideToggleChange } from "@angular/material/slide-toggle/index.js";

export interface Email {
    name: string;
}
export interface CcEmail {
    name: string;
}
export interface BccEmail {
    name: string;
}

@Component({
    selector: "app-send-email",
    templateUrl: "./send-email.component.html",
    styleUrls: ["./send-email.component.scss"],
    providers: [DatePipe, TierPermissionPipe]
})
export class SendEmailComponent implements OnInit, OnDestroy {
    iframe: any = iFRAME;
    height: any = HEIGHT;
    insertImageSettings: any = INSERTImageSettings;
    pasteCleanupSettings: any = PASTECleanupSettings;
    quickToolbarSettings: any = QUICKToolbarSettings;
    toolbarSettings: any = TOOLbarSettings;
    fontFamily: any = fontFamily;
    backgroundColor=backgroundColor;
    fontColor=fontColor
    // textName:any
    ckeditorValue: any;
    fileToUpload: any;
    imageUrl: any;
    SendEmailForm: FormGroup;
    visible = true;
    DefultData: string = "<p>&nbsp;</p>";
    selectable = true;
    removable = true;
    addOnBlur = true;
    mail: any = [];
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    emails: any = [];
    Ccemails: any = [];
    Bccemails: any = [];
    // isCc: boolean = false;
    // isBcc: boolean = false;
    configsOption: any;
    ckheight: any = 250;
    public Editor = ClassicEditor;
    storeDocGuid: any = [];
    fileNameArray: any = [];
    isspiner: boolean = false;
    errorWarningData: any = {};
    confirmDialogRef: any;
    MatterContactData: any = [];
    matterData: any = {};
    isConfirmBoxTitle: string = "Send Email";
    currentuser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    SAVETOREGISTER: any = this.currentuser.SAVEEMAILSTOREGISTER;
    matterDataforContact: any;
    base64bitDatafile: any = [];
    windowNameId: any;
    mattername: any = [];
    Deliverable: any;
    DeliverableField: any;
    matterlist: Observable<any[]>;
    searchData: any;
    selectedData: any = [];
    searchMatter: any;
    ShowMatterPopupFlag;
    exportdatavalue: any;
    @ViewChild('MatterInput1', { static: false }) MatterInput: ElementRef<HTMLInputElement>;
    @ViewChild("sample", { static: false }) dropdownTree: DropDownTreeComponent;
    fieldsEjs: any = {};
    currentFolder: any = { 'id': '' };
    fileName: string = ''
    isLoadingResults: boolean = false;
    FolderData: any;
    FolderListArray: any = [];
    expandexNodelist: any = [];
    public treeSettings: any = { loadOnDemand: true };
    currentDate: any = (new Date().toLocaleString()).replace(/[\. /,:-]+/g, "-");
    imageSignature: any;
    private _unsubscribeAll$: Subject<void> = new Subject();
    matterautoVal: any
    sub: Subscription;
    private searchTerms = new Subject<string>();
    private subscription: Subscription;
    constructor(
        private _formBuilder: FormBuilder,
        private _mainAPiServiceService: MainAPiServiceService,
        private toastr: ToastrService,
        public MatDialog: MatDialog,
        private router: Router,
        public behaviorService: BehaviorService,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        public datepipe: DatePipe,
        private tierpermission: TierPermissionPipe,
        public confirmDialogRef1: MatDialogRef<SendEmailComponent>
    ) {
        this.SendEmailForm = this._formBuilder.group({
            TOADDRESS: [""],
            CCADDRESS: [""],
            BCCADDRESS: [""],
            SUBJECT: [""],
            CONTENT: [""],
            textName: [""],
            ckeditorName: [""],
            matterautoVal: [""],
            MATTERGUID: [""],
            isCc: [false],
            isBcc: [false]
        });
        this.behaviorService.MatterPopupStatus$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(data => {
            this.ShowMatterPopupFlag = data
        })
        if (_data && _data != "") {
            const mainData = _data.data;
            if (mainData) {
                this.SendEmailForm.patchValue({
                    SUBJECT: mainData.SUBJECT,
                    ckeditorName: mainData.CONTENT,
                });
                if (mainData.TOADDRESS != '') {
                    this.emails.push(mainData.TOADDRESS);
                }
                if (mainData.CCADDRESS != '') {
                    this.Ccemails.push(mainData.CCADDRESS);
                    this.SendEmailForm.controls['isCc'].setValue(true);
                }

                if (mainData.BCCADDRESS != '') {
                    this.Bccemails.push(mainData.BCCADDRESS);
                    this.SendEmailForm.controls['isBcc'].setValue(true);
                }
                this.fileName = mainData.SUBJECT ? mainData.SUBJECT + '-' + this.currentDate : ''
            }
        } else {
            this.emails = [];
            this.Ccemails = [];
            this.Bccemails = [];
        }
        this.ckeditorValue = _data.data.CONTENT;
        this.behaviorService.mtshareData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            if (result) {
                this.MatterContactData = result;
            }
        });
        // this.behaviorService.MatterData$.subscribe(result => {
        //   if (result) {
        //     this.matterDataforContact = result;
        //   }
        // });
        this.behaviorService.MatterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            this.matterData = result;
            // if(this.ShowMatterPopupFlag==true){
            // this.mattername=[this.matterData.SELECTEDDESCRIPTION]
            // }else{
            //   this.mattername=[]
            // }
            if (this.ShowMatterPopupFlag == true) {
                this.mattername = [(this._data.data.SELECTEDDESCRIPTION) ? this._data.data.SELECTEDDESCRIPTION : this.matterData.SELECTEDDESCRIPTION]
            } else {
                this.mattername = [(this._data.data.SELECTEDDESCRIPTION) ? this._data.data.SELECTEDDESCRIPTION : this.matterData.SELECTEDDESCRIPTION]
            }
            const materIDofTab = window.name.split("_");
            this.windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
            this.matterDataforContact = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
            if (this.matterDataforContact) {
            } else {
                if (result) {
                    this.matterDataforContact = result;
                    localStorage.setItem(this.windowNameId || 'set_active_matters', JSON.stringify(this.matterDataforContact))
                }
            }
        });
    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        this.configsOption = { toolbar: { viewportTopOffset: 50, }, fontSize: { options: [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72] }, extraPlugins: [Imgbase64uploderPlugin] };
        if (this.SAVETOREGISTER) {
            this.getMatterGuid();
        }
        if (this._data.data.DocumentData) {
            this.storeDocGuid.push({ DOCUMENTGUID: (this._data.data.DocumentData.DOCUMENTGUID) ? this._data.data.DocumentData.DOCUMENTGUID : this._data.data.DocumentData });
        }
        if (this._data.data.DocumentName) {
            this.fileNameArray.push({ name: (this._data.data.DocumentName.name) ? (this._data.data.DocumentName.name) : this._data.data.DocumentName });
        }

        if (this._data.type == 'new') {
            this.getData();
        } else {
            this.getSignature()
        }
        this.defultAttechemnt();
        this.sub = this.SendEmailForm.controls['SUBJECT'].valueChanges.subscribe((val) => {
            this.fileName = val ? val + '-' + this.currentDate : ''
        })

        this.subscription = this.searchTerms.pipe(
            debounceTime(300), // Wait for 300ms pause in events
            distinctUntilChanged(), // Ignore if the next search term is the same as the previous one
            switchMap((term: string) => this.selectMatterFormSearch(term)) // Switch to new observable on new term
          ).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.exportdatavalue = response.DATA
                this.searchData = response.DATA.RECORDS;
                this.matterlist = this.searchData;
                if (response.DATA.RECORDS.length == '1') {
                    this.matterData = response.DATA.RECORDS[0];
                    this.getFolder({ DONTRECURSESUBFOLDERS: true, DONTADDROOTENTRY: true, FOLDERNAME: '' }, "rootLevel");
                }
            }
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
        this.sub?.unsubscribe();
        this.subscription?.unsubscribe();
    }

    ToggleForUpadte(event: MatSlideToggleChange, field){
        this.SendEmailForm.controls[field].setValue(event.checked);
    }

    /**
     * This function is used to on ready dats
     */
    public onReady(editor) {
        editor.ui.getEditableElement().parentElement.insertBefore(
            editor.ui.view.toolbar.element,
            editor.ui.getEditableElement()
        );
    }

    toggle(event: MatSlideToggleChange){
        if(event.checked){
            this.getMatterGuid()
        }
    }

    /**
     * This function is used to get thge data
     */
    getData() {
        this._mainAPiServiceService.getSetData({ "Action": "GetData", "Filters": {} }, 'preference').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                let DefultVal: any = response.DATA;
                this.ckeditorValue = this.DefultData + DefultVal.SIGNATUREDEFAULT;
                if(DefultVal.SHOWEMAILCC) {
                    this.SendEmailForm.controls['isCc'].setValue(true);
                }
                if(DefultVal.SHOWEMAILBCC) {
                    this.SendEmailForm.controls['isBcc'].setValue(true);
                }
            }
        }, error => {
            this.toastr.error(error);
        });
    }


    getSignature() {
        this._mainAPiServiceService.getSetData({ "Action": "GetData", "Filters": {} }, 'preference').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                let DefultVal: any = response.DATA;
                // this.SendEmailForm.controls['ckeditorName'].value
                this.imageSignature = `<span class="signature-card">` + DefultVal.SIGNATUREDEFAULT + `</span>`;
                this.ckeditorValue = this.ckeditorValue + `<span class="signature-card">` + DefultVal.SIGNATUREDEFAULT + `</span>`;
                //this.ckeditorValue = this.DefultData + DefultVal.SIGNATUREDEFAULT;
                if(DefultVal.SHOWEMAILCC) {
                    this.SendEmailForm.controls['isCc'].setValue(true);
                }
                if(DefultVal.SHOWEMAILBCC) {
                    this.SendEmailForm.controls['isBcc'].setValue(true);
                }
            }
        }, error => {
            this.toastr.error(error);
        });
    }

    defultAttechemnt() {
        let AttechmentData = this._data.data.ATTACHMENTS;
        if (AttechmentData && AttechmentData[0]) {
            AttechmentData.forEach((element, index) => {
                this.storeDocGuid.push({ DOCUMENTGUID: element.DOCUMENTGUID });
                this.fileNameArray.push({ name: element.DESCRIPTION, index: index, });
            });
        }
    }
    get f() {
        return this.SendEmailForm.controls;
    }
    // email for  To start *******************************
    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || "").trim()) {
            this.emails.push(value.trim());
        }
        if (input) {
            input.value = "";
        }
    }
    remove(email: Email): void {
        const index = this.emails.indexOf(email);
        if (index >= 0) {
            this.emails.splice(index, 1);
        }
    }

    // email for  To end *******************************

    // cc section start *************
    addCcEmail(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || "").trim()) {
            this.Ccemails.push(value.trim());
        }
        if (input) {
            input.value = "";
        }
    }
    removeCcEmail(Ccemail: CcEmail): void {
        const index = this.Ccemails.indexOf(Ccemail);
        if (index >= 0) {
            this.Ccemails.splice(index, 1);
        }
    }

    // bcc section end ************************************************
    addBccEmail(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || "").trim()) {
            this.Bccemails.push(value.trim());
        }
        if (input) {
            input.value = "";
        }
    }
    removeBccEmail(Bccemail: CcEmail): void {
        const index = this.Bccemails.indexOf(Bccemail);
        if (index >= 0) {
            this.Bccemails.splice(index, 1);
        }
    }

    getFolder(postData, levels) {
        this.isLoadingResults = true;
        let currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
        postData.MATTERGUID = this.matterData.MATTERGUID;
        let Data=postData;
        this._mainAPiServiceService.getSetData({"Action": "NetDriveGetMatterFolders",Data}, 'cloud-storage').pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.isLoadingResults = false;
                this.FolderData = res.DATA.FOLDERS;

                if (levels == 'rootLevel') {
                    this.FolderListArray = [];
                    this.FolderData.forEach(e => {
                        e.hasChild = true;
                        e.expanded = false;
                        // let tempData = e;
                        this.FolderListArray.push(e);

                        // tempData.FOLDERS.forEach(element => {
                        //   element.hasChild = true;
                        //   element.expanded = false;
                        //   element.pid = tempData.FULLPATH;
                        //   this.FolderListArray.push(element);
                        // });
                    });
                    this.fieldsEjs = { dataSource: this.FolderListArray, value: 'FULLPATH', text: 'FOLDERNAME', parentValue: 'pid', hasChildren: 'hasChild' };
                    this.created('');
                } else {
                    this.FolderData.forEach(element => {
                        element.hasChild = true;
                        element.expanded = false;
                        element.pid = postData.FOLDERNAME;
                        this.FolderListArray.push(element);
                    });

                    this.expandexNodelist = this.expandexNodelist.filter((element, i) => i === this.expandexNodelist.indexOf(element));
                    this.FolderListArray.forEach(item => {
                        if (this.expandexNodelist.includes(item.FULLPATH) || item.FULLPATH == "<MatterFolder>") {
                            item.expanded = true;
                            item.hasChild = true;
                        } else {
                            item.hasChild = true;
                            item.expanded = false;
                        }
                    });
                    this.dropdownTree.refresh();
                    (this.dropdownTree as any).treeObj.nodeExpanding = this.triggerfun.bind(this);

                    this.dropdownTree.showPopup();
                    this.fieldsEjs = { dataSource: this.FolderListArray, value: 'FULLPATH', text: 'FOLDERNAME', parentValue: 'pid', hasChildren: 'hasChild' };
                }
            } else if (res.CODE == "432") {
                this.isLoadingResults = false;
        //         window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetDrive&SessionToken=' + currentUser.SessionToken, 'targetWindow',
        //             `toolbar=no,
        //  location=no,
        //  status=no,
        //  menubar=no,
        //  scrollbars=yes,
        //  resizable=yes,
        //  width=800,
        //  height=600`);
                let payload = {
                    "Action": "OAuth2Start",
                    "VALIDATEONLY": false,
                    "Data": {
                        "USERGUID": this.currentuser.UserGuid,
                        "AuthType": "NetDrive"
                    }
                }
                this._mainAPiServiceService
                .getSetData(payload, 'oauth-connections')
                .subscribe((response) => {
                    if(response.CODE == 200 && response.STATUS == "success") {
                        window.open(
                            response.DATA[0].AuthorizationUrl,
                            "targetWindow",
                            `toolbar=no,
                            location=no,
                            status=no,
                            menubar=no,
                            scrollbars=yes,
                            resizable=yes,
                            width=800,
                            height=600`
                        );
                    }
                });
            } else {
                this.isLoadingResults = false;
            }
        }, err => {
            this.isLoadingResults = false;
            // this.toastr.error(err);
        });
    }

    created(event): void {
        (this.dropdownTree as any).treeObj.nodeExpanding = this.triggerfun.bind(this);
    }

    triggerfun(arges) {
        if (!this.expandexNodelist.includes(arges.nodeData.id) && arges.nodeData.id != "<MatterFolder>") {
            this.expandexNodelist.push(arges.nodeData.id);
            this.getFolder({ DONTRECURSESUBFOLDERS: false, DONTADDROOTENTRY: true, FOLDERNAME: arges.nodeData.id }, 'subFolderLevel');
        }
    }

    FloderChnage($event: SelectEventArgs, key: any = '') {
        // this.mail[key].foldeId = $event.itemData;
        this.currentFolder = $event.itemData
        // this.behaviorService.setEjsDropDownTreeData({ event: $event, key: key })
    }

    onFileInput(fileInput, ckeditor) {
        let file64bit = [];
        let files = fileInput.target.files;
        for (let i = 0; i < files.length; i++) {
            this.fileNameArray.push({ name: files[0].name, index: i });
            var reader = new FileReader();
            reader.readAsDataURL(files[i]);
            reader.onload = function () {
                file64bit.push(reader.result);
            };
            reader.onerror = function (error) {
            };
        }
        this.base64bitDatafile = file64bit;
    }
    remove_file(index) {
        this.fileNameArray.splice(index, 1);
        this.storeDocGuid.splice(index, 1);
    }

    /**
     * This function is used to open the document Register popup
     */
    documentRegPopup() {
        const dialogRef = this.MatDialog.open(SelectDocumentsComponent, {
            disableClose: true,
            panelClass: "Select-Document-dialog",
            data: null,
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                 this.storeDocGuid = [];
                 this.fileNameArray = [];
                this.base64bitDatafile = '';
                result.forEach((element, index) => {
                    if (this.storeDocGuid.findIndex(e => e.DOCUMENTGUID == element.DOCUMENTGUID) == -1) {
                        this.storeDocGuid.push({
                            DOCUMENTGUID: element.DOCUMENTGUID,
                        });
                        this.fileNameArray.push({
                            name: element.DOCUMENTNAME,
                            index: index,
                        });
                    } else if (this.storeDocGuid.length == 0) {
                        this.storeDocGuid.push({
                            DOCUMENTGUID: element.DOCUMENTGUID,
                        });
                        this.fileNameArray.push({
                            name: element.DOCUMENTNAME,
                            index: index,
                        });
                    }
                });
            }
            if (this._data.data.DocumentData) {
                this.storeDocGuid.push(this._data.data.DocumentData);
            }
            if (this._data.data.DocumentName) {
                this.fileNameArray.push(this._data.data.DocumentName);
            }
            this.defultAttechemnt();
        });
    }

    /**
     * This function is used to change the toggle
     */
    toggelChange(event) {

    }

    /**
     * This function is used to select the Matter Contact
     */
    selectMatterContact(val) {
        if (!this.matterData.MATTERGUID) {
            this.confirmDialogRef = this.MatDialog.open(ConfirmPopupComponent, {
                disableClose: true, width: '100%',
            });
            this.confirmDialogRef.componentInstance.confirmMessage = 'You have not selected a matter. Do you want to select from the Contact list?';
            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    const dialogRef = this.MatDialog.open(ContactSelectDialogComponent, { width: '100%', disableClose: true, data: { type: "", } });
                    dialogRef.afterClosed().subscribe(result => {
                        if (result) {
                            if (result.EMAIL != "") {
                                if (val == 'toaddress') {
                                    this.emails.push(result.CONTACTNAME + '<' + result.EMAIL + '>');
                                } else if (val == 'ccaddress') {
                                    this.Ccemails.push(result.CONTACTNAME + '<' + result.EMAIL + '>');
                                } else if (val == 'bccaddress') {
                                    this.Bccemails.push(result.CONTACTNAME + '<' + result.EMAIL + '>');
                                }
                            }
                        }
                    });
                }
                this.confirmDialogRef = null;
            });
        } else {
            const dialogRef = this.MatDialog.open(ContactCorresDetailsComponent, { width: '100%', disableClose: true, data: { ContextGuid: this.matterDataforContact.MATTERGUID, 'callType': 'select', window: 'send email' } });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    if (this.MatterContactData) {
                        if (this.MatterContactData.length > 0) {
                            this.MatterContactData.forEach((element) => {
                                if (element.PERSONEMAIL != "") {
                                    if (val == 'toaddress') {
                                        this.emails.push(element.CONTACTNAME + '<' + element.PERSONEMAIL + '>');
                                    } else if (val == 'ccaddress') {
                                        this.Ccemails.push(element.CONTACTNAME + '<' + element.PERSONEMAIL + '>');
                                    } else if (val == 'bccaddress') {
                                        this.Bccemails.push(element.CONTACTNAME + '<' + element.PERSONEMAIL + '>');
                                    }
                                }
                            });
                        }
                    }
                }
            });
        }
    }

    /**
     * This function is used to send Email
     */
    sendEmail() {
        this.isspiner = true;
        let passdata: any = {
            TOADDRESS: this.emails.toString(),
            CCADDRESS: this.Ccemails.toString(),
            BCCADDRESS: this.Bccemails.toString(),
            IMPORTANCE: "high",
            SUBJECT: this.f.SUBJECT.value,
            CONTENT: this.f.ckeditorName.value,
            CONTENTISHTML: true,
            SAVETOREGISTER: this.SAVETOREGISTER,
            FILENAME: this.fileName + '.eml',
            FOLDERNAME: this.currentFolder.id
        };

        if (this.base64bitDatafile[0] == '' || this.base64bitDatafile[0] == null || this.base64bitDatafile[0] == undefined) {
            passdata.ATTACHMENTLIST = this.storeDocGuid
        } else {
            passdata.FILENAME = this.fileNameArray[0].name;
            passdata.FILEDATA = this.base64bitDatafile[0];
        }
        if (this.SAVETOREGISTER) {
            passdata.MATTERGUID = this.matterData.MATTERGUID;
        }

        passdata.SIGNATUREDEFAULT = this.imageSignature;
        let details = { "Action": "NetEmailSendEmail", VALIDATEONLY: true, Data: passdata };
        this._mainAPiServiceService.getSetData(details, 'cloud-email').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.checkValidation(response.DATA.VALIDATIONS, details);
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.checkValidation(response.DATA.VALIDATIONS, details);
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.checkValidation(response.DATA.VALIDATIONS, details);
            } else if (response.MESSAGE == 'Not logged in') {
                this.confirmDialogRef1.close(false);
            } else if (response.CODE == "428" && response.STATUS == "error") {
                this.nativeWindowOpen();
                this.isspiner = false;
            } else if (response.CODE == 432) {
                this.nativeDrive();
                this.isspiner = false;
            }
        }, error => {
            this.isspiner = false;
            this.toastr.error(error);
        });

    }

    /**
     * This function is used to check the validation
     */
    checkValidation(bodyData: any, details: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value) {
            if (value.VALUEVALID == 'No') {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            }
            else if (value.VALUEVALID == 'Warning') {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }

        });
        this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
        if (Object.keys(errorData).length != 0) {
            this.isspiner = false;
            this.toastr.error(errorData);
        } else if (Object.keys(warningData).length != 0) {
            this.isspiner = false;
            this.confirmDialogRef = this.MatDialog.open(FuseConfirmDialogComponent, {
                disableClose: true,
                width: '100%',
                data: warningData
            });
            this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.SetEmailData(details);
                }
                this.confirmDialogRef = null;
            });
        } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
            this.SetEmailData(details);
        }
    }

    /**
     * This function is used to Set the Email Data
     */
    SetEmailData(data: any) {
        this.isspiner = true;
        data.VALIDATEONLY = false;
        this._mainAPiServiceService.getSetData(data, 'cloud-email').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
                this.toastr.success('Email sent successfully');
                if(this._data && this._data.data){
                    this._data.data.AUTHMULTIEMAIL=true;
                }
                if(this._data && this._data.data && this._data.data.MULTIEMAIL==true){
                    this.confirmDialogRef1.close(this._data);
                }else{
                    this.confirmDialogRef1.close(true);
                }
                if (this.SAVETOREGISTER) {
                    this.mail = response.DATA.EMAILS[0];
                    // this.SaveEmailtoRegister();
                    // this.renameData();
                }
            } else if (response.CODE == 451 && response.STATUS == 'warning') {
                this.toastr.warning(response.MESSAGE);
                this.isspiner = false;
            } else if (response.CODE == 450 && response.STATUS == 'error') {
                this.toastr.error(response.MESSAGE);
                this.isspiner = false;
            } else if (response.MESSAGE == 'Not logged in') {
                this.confirmDialogRef1.close(false);
            } else if (response.CODE == "428" && response.STATUS == "error") {
                this.nativeWindowOpen();
                this.isspiner = false;
            } else if (response.CODE == 432) {
                this.nativeDrive();
                this.isspiner = false;
            } else {
                this.isspiner = false;
            }
        }, error => {
            this.isspiner = false;
            this.toastr.error(error);
        });
    }

    /**
     * This function is used to Rename the data
     */
    renameData() {
        const dialogRef2 = this.MatDialog.open(RenameEmailComponent, {
            width: '100%', disableClose: true,
            data: { 'response': '', 'attachments': [], 'mail': this.mail, 'isGetAttechment': true }
        });
        dialogRef2.afterClosed().subscribe(result2 => {
            if (result2) {
            }
        });
    }

    /**
     * This function is used to open the native Window
     */
    nativeWindowOpen() {
        let currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
    //     window.open(environment.APIEndpoint + "OAuth2Start?AuthType=NetEmail&SessionToken=" + currentUser.SessionToken, 'targetWindow',
    //         `toolbar=no,
    //  location=no,
    //  status=no,
    //  menubar=no,
    //  scrollbars=yes,
    //  resizable=yes,
    //  width=800,
    //  height=600`);
        let payload = {
            "Action": "OAuth2Start",
            "VALIDATEONLY": false,
            "Data": {
                "USERGUID": this.currentuser.UserGuid,
                "AuthType": "NetEmail"
            }
        }
        this._mainAPiServiceService
        .getSetData(payload, 'oauth-connections')
        .subscribe((response) => {
            if(response.CODE == 200 && response.STATUS == "success") {
                window.open(
                    response.DATA[0].AuthorizationUrl,
                    "targetWindow",
                    `toolbar=no,
                    location=no,
                    status=no,
                    menubar=no,
                    scrollbars=yes,
                    resizable=yes,
                    width=800,
                    height=600`
                );
            }
        });
    }

    /**
     * THis function is used to native the drive
     */
    nativeDrive() {
        let currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
    //     window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetDrive&SessionToken=' + currentUser.SessionToken, 'targetWindow',
    //         `toolbar=no,
    //  location=no,
    //  status=no,
    //  menubar=no,
    //  scrollbars=yes,
    //  resizable=yes,
    //  width=800,
    //  height=600`);
        let payload = {
            "Action": "OAuth2Start",
            "VALIDATEONLY": false,
            "Data": {
                "USERGUID": this.currentuser.UserGuid,
                "AuthType": "NetDrive"
            }
        }
        this._mainAPiServiceService
        .getSetData(payload, 'oauth-connections')
        .subscribe((response) => {
            if(response.CODE == 200 && response.STATUS == "success") {
                window.open(
                    response.DATA[0].AuthorizationUrl,
                    "targetWindow",
                    `toolbar=no,
                    location=no,
                    status=no,
                    menubar=no,
                    scrollbars=yes,
                    resizable=yes,
                    width=800,
                    height=600`
                );
            }
        });
    }

    /**
     * This function is used to get the matterGuid
     */
    getMatterGuid() {
        if (this.router.url == "/document-register") {
            this.behaviorService.DocumentRegisterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
                if (result) {
                    let payload = {
                        "Action": "GetData",
                        "Filters": { MATTERGUID: result.MATTERGUID }
                    };

                    this._mainAPiServiceService.getSetData(payload, 'matter').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
                        if (response.CODE === 200 && response.STATUS === 'success') {
                            if (response.DATA.RECORDS[0]) {
                                this.matterData = response.DATA.RECORDS[0];
                                this.getFolder({ DONTRECURSESUBFOLDERS: true, DONTADDROOTENTRY: true, FOLDERNAME: '' }, "rootLevel");
                            }
                        }
                    });
                }
            });
        } else if (this.router.url == "/invoice/sub-invoices" || (this._data.data.fromType && this._data.data.fromType == "invoice")) {
            this.behaviorService.matterInvoice$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
                if (result) {
                    let payload1 = {
                        "Action": "GetData",
                        "Filters": { MATTERGUID: result.MATTERGUID }
                    };

                    this._mainAPiServiceService.getSetData(payload1, 'matter').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
                        if (response.CODE === 200 && response.STATUS === 'success') {
                            if (response.DATA.RECORDS[0]) {
                                // if(this.matterData){

                                let multiselctmatter = JSON.parse(localStorage.getItem('collectSelectedInvoice') ? localStorage.getItem('collectSelectedInvoice') 
                                : localStorage.getItem('set_active_matters'));
                                if (multiselctmatter) {
                                    let index = multiselctmatter.findIndex(e => (e.MATTERGUID == this._data.data.MATTERGUID) && (e.INVOICECODE == this._data.data.INVOICECODE))
                                    this.matterData = multiselctmatter[index] ? multiselctmatter[index] : response.DATA.RECORDS[0]
                                } else {
                                    // this.matterData =  response.DATA.MATTERS[0]
                                }
                                this.getFolder({ DONTRECURSESUBFOLDERS: true, DONTADDROOTENTRY: true, FOLDERNAME: '' }, "rootLevel");
                            } else {
                                this.selectMatterForEmail();
                            }
                        }
                    });
                } else {
                    this.selectMatterForEmail();
                }
            });
        } else if (this.router.url == "/create-document/packs-invoice-template" || this.router.url == "/create-document/email-invoice-template") {
            let payload2 = {
                "Action": "GetData",
                "Filters": { MATTERGUID: this._data.data.MATTERGUID }
            };

            this._mainAPiServiceService.getSetData(payload2, 'matter').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
                if (response.CODE === 200 && response.STATUS === 'success') {
                    if (response.DATA.RECORDS[0]) {
                        this.matterData = response.DATA.RECORDS[0];
                        this.mattername = [this.matterData.SELECTEDDESCRIPTION]
                        this.getFolder({ DONTRECURSESUBFOLDERS: true, DONTADDROOTENTRY: true, FOLDERNAME: '' }, "rootLevel");
                    } else {
                        this.selectMatterForEmail();
                    }
                }
            });
        }else if (this.router.url == "/matter-details/matter-invoices" || this.router.url == "/matter-details/matter-detail-dashboard") {
            let payload2 = {
                "Action": "GetData",
                "Filters": { MATTERGUID: this._data.data.MATTERGUID }
            };

            this._mainAPiServiceService.getSetData(payload2, 'matter').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
                if (response.CODE === 200 && response.STATUS === 'success') {
                    if (response.DATA.RECORDS[0]) {
                        this.matterData = response.DATA.RECORDS[0];
                        this.mattername = [this.matterData.SELECTEDDESCRIPTION]
                        this.getFolder({ DONTRECURSESUBFOLDERS: true, DONTADDROOTENTRY: true, FOLDERNAME: '' }, "rootLevel");
                    } else {
                        this.selectMatterForEmail();
                    }
                }
            });
        } else if (this.router.url == "/create-document/email-matter-template" || this.router.url == "/matters" || this.router.url == "/time-billing/work-in-progress" || this.router.url == "/create-document/packs-matter-template" || (this._data.data.fromType && this._data.data.fromType == "matter") || (this._data.data.ExtraData && this._data.data.ExtraData == "inboxWithTemplate")) {
            if (this.router.url == '/main-report') {
                this.behaviorService.MatterData$.next(null)
                if (this.router.url == '/main-report') {
                    let payload3 = {
                        "Action": "GetData",
                        "Filters": { MATTERGUID: this._data.data.MATTERGUID }
                    };

                    this._mainAPiServiceService.getSetData(payload3, 'matter').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
                        if (response.CODE === 200 && response.STATUS === 'success') {
                            if (response.DATA.RECORDS[0]) {
                                this.matterData = response.DATA.RECORDS[0];
                                this.mattername = [this.matterData.SELECTEDDESCRIPTION]
                                this.getFolder({ DONTRECURSESUBFOLDERS: true, DONTADDROOTENTRY: true, FOLDERNAME: '' }, "rootLevel");
                            } else {
                                this.selectMatterForEmail();
                            }
                        }
                    });
                } else {
                    this.selectMatterForEmail();
                    this.getFolder({ DONTRECURSESUBFOLDERS: true, DONTADDROOTENTRY: true, FOLDERNAME: '' }, "rootLevel");
                }
            } else if (this.router.url != '/main-report') {
                this.behaviorService.MatterData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
                    if (result) {
                        this.matterData = result;
                        this.getFolder({ DONTRECURSESUBFOLDERS: true, DONTADDROOTENTRY: true, FOLDERNAME: '' }, "rootLevel");
                    } else {
                        if (this.router.url == '/trust-end-month/to-do' || this.router.url != '/main-report') {
                            let payload4 = {
                                "Action": "GetData",
                                "Filters": { MATTERGUID: this._data.data.MATTERGUID }
                            };

                            this._mainAPiServiceService.getSetData(payload4, 'matter').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
                                if (response.CODE === 200 && response.STATUS === 'success') {
                                    if (response.DATA.RECORDS[0]) {
                                        this.matterData = response.DATA.RECORDS[0];
                                        this.mattername = [this.matterData.SELECTEDDESCRIPTION]
                                        this.getFolder({ DONTRECURSESUBFOLDERS: true, DONTADDROOTENTRY: true, FOLDERNAME: '' }, "rootLevel");
                                    } else {
                                        this.selectMatterForEmail();
                                    }
                                }
                            });
                        } else {
                            this.selectMatterForEmail();
                            this.getFolder({ DONTRECURSESUBFOLDERS: true, DONTADDROOTENTRY: true, FOLDERNAME: '' }, "rootLevel");
                        }
                    }
                });
            }

            // this.behaviorService.MatterData$.subscribe(result => {
            //   const materIDofTab = window.name.split("_");
            //   this.windowNameId = (materIDofTab && materIDofTab[1])?materIDofTab[1] : undefined;
            //   if (this.windowNameId) {
            //     localStorage.getItem(this.windowNameId)
            //   } else {

            //     JSON.parse(localStorage.getItem('set_active_matters'));
            //   }
            //   this.matterData = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
            //   if (this.matterData) {
            //     this.getFolder({ DONTRECURSESUBFOLDERS: true, DONTADDROOTENTRY: true, FOLDERNAME: '' }, "rootLevel");
            //   } else {
            //     if (result) {
            //       this.matterData = result;
            //       this.getFolder({ DONTRECURSESUBFOLDERS: true, DONTADDROOTENTRY: true, FOLDERNAME: '' }, "rootLevel");
            //       localStorage.setItem(this.windowNameId,JSON.stringify(this.matterData))
            //     } else {
            //         this.selectMatterForEmail();
            //     }
            //   }
            // });

        } else if (this.router.url == "/create-document/email-receive-money-template") {
            this.selectMatterForEmail();
        } else if (this.router.url == "/create-document/email-contact-template") {
            this.selectMatterForEmail();
        } else if (this.router.url == "/create-document/email-safe-custody-template") {
            this.selectMatterForEmail();
        } else if (this.router.url == "/create-document/email-document-register-template") {
            const matterData = JSON.parse(localStorage.getItem((this._data.data.MATTERGUID) ? this._data.data.MATTERGUID : 'set_active_matters'));
            if (matterData) {
                this.matterData = matterData;
            } else {
                this.matterData = JSON.parse(localStorage.getItem('set_active_matters'));
            }
            this.getFolder({ DONTRECURSESUBFOLDERS: true, DONTADDROOTENTRY: true, FOLDERNAME: '' }, "rootLevel");
        }
    }

    /**
     * This Function is used to select the matter For Email
     */
    selectMatterForEmail() {
        this.searchData = [];
        this.matterlist = this.searchData;
        if (this.router.url != "/matters") {
            const dialogRef = this.MatDialog.open(MatterDialogComponent, { width: '100%', disableClose: true, data: null });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    localStorage.setItem('istrackid', 'timeentriesdialogcomponent');
                    this.matterDataforContact = result;
                    this.SendEmailForm.controls['MATTERGUID'].setValue(result.MATTERGUID);
                    this.SendEmailForm.controls['matterautoVal'].setValue(result.SHORTNAME + ' : ' + result.MATTER);
                    this.mattername = [result.SELECTEDDESCRIPTION];
                    this.matterData = result;
                    this.getFolder({ DONTRECURSESUBFOLDERS: true, DONTADDROOTENTRY: true, FOLDERNAME: '' }, "rootLevel");
                }
            });
        }
    }

    /**
     * This function is used to upload the Register popup
     */
    UploadToRegPopup() {
        if (this.matterData.MATTERGUID == '' || this.matterData.MATTERGUID == null) {
            const dialogRef = this.MatDialog.open(MatterDialogComponent, { width: '100%', disableClose: true, data: null });
            dialogRef.afterClosed().subscribe(result => {
                this.matterData = result;
                if (result) {
                    localStorage.setItem('set_active_matters', JSON.stringify(result));
                    const dialogRef = this.MatDialog.open(DocumentDailogComponent, { width: '100%', disableClose: true, data: { action: 'new', is_inboxAttech: true } });
                    dialogRef.afterClosed().subscribe(result => {
                        if (result) {
                            this.base64bitDatafile = [];
                            // this.storeDocGuid = [];
                            // this.fileNameArray = [];
                            this.storeDocGuid.push({
                                DOCUMENTGUID: result.DOCUMENTGUID,
                            });
                            this.fileNameArray.push({
                                name: result.DOCUMENTNAME,
                                index: 0,
                            })
                        }
                    });
                }
            })
        } else {
            // const dialogRef = this.MatDialog.open(MatterDialogComponent, { width: '100%', disableClose: true, data: null });
            // dialogRef.afterClosed().subscribe(result => {
            //   if (result) {
            //     localStorage.setItem('set_active_matters', JSON.stringify(result));
            const dialogRef = this.MatDialog.open(DocumentDailogComponent, { width: '100%', disableClose: true, data: { action: 'new', is_inboxAttech: true } });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.base64bitDatafile = [];
                    // this.storeDocGuid = [];
                    // this.fileNameArray = [];
                    this.storeDocGuid.push({
                        DOCUMENTGUID: result.DOCUMENTGUID,
                    });
                    this.fileNameArray.push({
                        name: result.DOCUMENTNAME,
                        index: 0,
                    })
                }
            });
        }
        //   }
        // });
    }

    /**
     * This function is used to change the file name
     */
    changeFileName(evnt) {
        this.fileName = this.f.SUBJECT.value ? this.f.SUBJECT.value + '-' + this.currentDate : ''
    }

    /**
     * This function is used to Save the Email For the register
     */
    SaveEmailtoRegister() {
        let currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
        let reuestData2 = { MATTERGUID: this.matterData.MATTERGUID, EMAILID: this.mail.ID, FILENAME: this.mail.FILENAME + '.eml', FOLDERNAME: '' };
        this._mainAPiServiceService.getSetData(reuestData2, 'NetEmailSaveEmailAttachment').pipe(takeUntil(this._unsubscribeAll$)).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.toastr.success("Email Register  Saved Successfully");
                this.confirmDialogRef1.close(false);
            } else if (response.CODE == "428" && response.STATUS == "error") {
        //         window.open(environment.APIEndpoint + "OAuth2Start?AuthType=NetEmail&SessionToken=" + currentUser.SessionToken, 'targetWindow',
        //             `toolbar=no,
        //  location=no,
        //  status=no,
        //  menubar=no,
        //  scrollbars=yes,
        //  resizable=yes,
        //  width=800,
        //  height=600`);
                let payload = {
                    "Action": "OAuth2Start",
                    "VALIDATEONLY": false,
                    "Data": {
                        "USERGUID": this.currentuser.UserGuid,
                        "AuthType": "NetEmail"
                    }
                }
                this._mainAPiServiceService
                .getSetData(payload, 'oauth-connections')
                .subscribe((response) => {
                    if(response.CODE == 200 && response.STATUS == "success") {
                        window.open(
                            response.DATA[0].AuthorizationUrl,
                            "targetWindow",
                            `toolbar=no,
                            location=no,
                            status=no,
                            menubar=no,
                            scrollbars=yes,
                            resizable=yes,
                            width=800,
                            height=600`
                        );
                    }
                });
            } else if (response.CODE == "432" && response.STATUS == "error") {
        //         window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetDrive&SessionToken=' + currentUser.SessionToken, 'targetWindow',
        //             `toolbar=no,
        //  location=no,
        //  status=no,
        //  menubar=no,
        //  scrollbars=yes,
        //  resizable=yes,
        //  width=800,
        //  height=600`);
                let payload = {
                    "Action": "OAuth2Start",
                    "VALIDATEONLY": false,
                    "Data": {
                        "USERGUID": this.currentuser.UserGuid,
                        "AuthType": "NetDrive"
                    }
                }
                this._mainAPiServiceService
                .getSetData(payload, 'oauth-connections')
                .subscribe((response) => {
                    if(response.CODE == 200 && response.STATUS == "success") {
                        window.open(
                            response.DATA[0].AuthorizationUrl,
                            "targetWindow",
                            `toolbar=no,
                            location=no,
                            status=no,
                            menubar=no,
                            scrollbars=yes,
                            resizable=yes,
                            width=800,
                            height=600`
                        );
                    }
                });
            }
        }, error => {
            this.toastr.error(error);
        });
    }

    /**
     * This function is used to Remove the data
     */
    removeData(fruit: any) {
        const index = this.mattername.indexOf(fruit);
        if (index >= 0) {
            this.mattername.splice(index, 1);
            this.SendEmailForm.controls['MATTERGUID'].setValue('');
            this.Deliverable = [];

        }
        let sortname = fruit.split('-')[0]
        this.rm(sortname)
    };

    /**
     * This function is used to rm the matter list
     */
    rm(sortname) {
        this.selectedData.forEach((element: any, index: any) => {
            if (element.SHORTNAME.toString() == sortname.replace(/^\s+|\s+$/gm, '')) {
                this.selectedData.splice(index, 1);
            }
        })
    }

    /**
     * This function is used to add te data
     */
    addData(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        this.matterlist.forEach((element: any) => {
            if (element.SHORTNAME == event.value) {
                // Add our fruit
                const array3 = this.mattername;
                if (value) {
                    if (this.mattername.indexOf(value.replace(/^\s+|\s+$/gm, '')) == -1) {
                        this.mattername = [value]
                    }
                }
            }
        });

        // Clear the input value
        if (event.input) {
            event.input.value = '';
        }
        //  event.chipInput!.clear();

        this.SendEmailForm.controls['matterautoVal'].setValue(null);
    };

    /**
     * This function is used to onKey up
     */
    onKeyUp(event: any): void {
        const searchValue = event.target.value;
        this.searchTerms.next(searchValue);
    }

    /**
     * This function is used to select the matter search for the form
     */
    public selectMatterFormSearch(event) {
        // let searchobj = { FastSearch: 'True', SearchString: event }
        let searchobj = {
            "Action": "GetData",
            "Filters": { FastSearch: 'True', SearchString: event }
        };
        return this._mainAPiServiceService.getSetData(searchobj, 'matter')
    }

    /**
     * This function is used to selected Value
     */
    selectedValue(event: MatAutocompleteSelectedEvent): void {
        // const array3 = this.mattername;
        // if (array3.length <1) {
        if (this.mattername.indexOf(event.option.viewValue.replace(/^\s+|\s+$/gm, '')) == -1) {
            // this.mattername.push(event.option.viewValue);
            this.mattername = [event.option.viewValue]
            this.MatterInput.nativeElement.value = '';
            this.SendEmailForm.controls['matterautoVal'].setValue(null);
        }
        // }
    }

    /**
     * This function is used to selected matter guid
     */
    selectedmatterguid(data: any) {

        this.SendEmailForm.controls['MATTERGUID'].setValue(data.MATTERGUID);
        // this.getDeliverabledata(data.MATTERGUID)
        // const array3 = this.selectedData;
        // if (array3.length < 1) {
        if (this.selectedData.map((ele, index) => ele.MATTERGUID == data.MATTERGUID ? index : -1)[0] == -1 || this.selectedData.length == 0) {
            this.selectedData = [data];
        }
        this.matterData = this.selectedData[0];
        this.getFolder({ DONTRECURSESUBFOLDERS: true, DONTADDROOTENTRY: true, FOLDERNAME: '' }, "rootLevel");
        // }
    }

    /**
     * This function is used to data show only
     */
    datashow(event) {
        this.exportdatavalue.RECORDS.forEach((element, i) => {
            if (i == 0) {
                let data = element
                this.mattername = [data.SELECTEDDESCRIPTION]
                this.SendEmailForm.controls['matterautoVal'].setValue(null);

                if (event.input) {
                    event.input.value = '';
                } else {
                    this.MatterInput.nativeElement.value = '';
                }

                this.matterData = data;
                this.getFolder({ DONTRECURSESUBFOLDERS: true, DONTADDROOTENTRY: true, FOLDERNAME: '' }, "rootLevel");

                this.SendEmailForm.controls['MATTERGUID'].setValue(data.MATTERGUID);
                // this.getDeliverabledata(data.MATTERGUID)
                // const array3 = this.selectedData;
                // if (array3.length < 1) {
                if (this.selectedData.map((ele, index) => ele.MATTERGUID == data.MATTERGUID ? index : -1)[0] == -1 || this.selectedData.length == 0) {
                    this.selectedData = [data];
                }
            }
        });
    }
    // async getDeliverabledata(MATTERGUID){
    //   const pipe = this.tierpermission.transform('Matter Deliverables');
    //   if(pipe && this.currentUser_1.ProductType!='Barrister' && pipe.ALLOWED ==1){
    //     return new Promise(async (next , rej)=>{
    //       await this._mainAPiServiceService.getSetData({ Action: "GetData",Filters: {MATTERGUID:MATTERGUID  }, }, 'matter-deliverable ').subscribe(responses => {
    //         if (responses.CODE === "200" && responses.STATUS === 'success') {
    //           this.isLoadingResults = false;
    //            this.Deliverable = responses.DATA.RECORDS;
    //            next(this.Deliverable);
    //         }else{
    //           this.isLoadingResults = false;
    //           next(this.Deliverable);
    //         }
    //       },error=>{
    //         this.isLoadingResults = false;
    //         next(true);
    //       });
    //     })
    //  }
    // }

    /**
     * This function is used to close the email popup
     */
    closeEmailPopup(){
        if(this._data && this._data.data){
            this._data.data.AUTHMULTIEMAIL=false;
        }
        if(this._data && this._data.data && this._data.data.MULTIEMAIL==true){
            this.confirmDialogRef1.close(this._data);
        }else{
            this.confirmDialogRef1.close(true);
        }
    }
}
