import { Component, OnInit, Inject } from '@angular/core';
import { fuseAnimations } from 'src/@fuse/animations';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { BehaviorService } from '@_services/Behavior.service';
import { TooltipService } from '@_services/tooltip.service';
import { TimersService } from '@_services/timers.service';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { BulkProgressComponent } from '@pages/template/bulk-progress/bulk-progress.component';

@Component({
  selector: 'app-write-off-time-entry',
  templateUrl: './write-off-time-entry.component.html',
  styleUrls: ['./write-off-time-entry.component.scss'],
  animations: fuseAnimations
})
export class WriteOffTimeEntryComponent implements OnInit {
  isspiner: boolean = false;
  isLoadingResults: boolean = false;
  errorWarningData: any = {};
  timeEntryData: any = [];
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  writeOffTimerForm: FormGroup;
  successMsg = 'Update success';
  matterShortName: any;
  firstTime: number;
  testBool: boolean=false;
  toolTipList: any;
  RequestGuid: string;
  private _unsubscribeAll$: Subject<void> = new Subject();
  dialogRefdata: MatDialogRef<BulkProgressComponent>;

  constructor(public dialogRef: MatDialogRef<WriteOffTimeEntryComponent>,
    public MatDialog: MatDialog,
    private behaviorService: BehaviorService,
    private _formBuilder: FormBuilder,
    private toastr: ToastrService, public datepipe: DatePipe,
    public tooltipService:TooltipService,
    private Timersservice: TimersService,private router : Router,
    @Inject(MAT_DIALOG_DATA) public writeTimerData: any,private _mainAPiServiceService:MainAPiServiceService
  ) {

    this.RequestGuid =  this._mainAPiServiceService.generateUniqSerial();

    localStorage.setItem('istrackid', 'writeofftimeentrycomponent');
    this.behaviorService.dialogClose$.subscribe(result => {
      if (result != null) {
        if (result.MESSAGE == 'Not logged in') {
          this.dialogRef.close(false);
        }
      }
    });
    this.behaviorService.APIretryCall$.subscribe((result) => {

      if (localStorage.getItem('istrackid') == 'writeofftimeentrycomponent' && result['click'] && result['data']['workitem']) {
        this.ngOnInit();
      }

      if (localStorage.getItem('istrackid') == 'writeofftimeentrycomponent' && result['click'] && result['data']['workitem']) {
        this.SaveClickTimeEntry();
      }
    });
  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    this.writeOffTimerForm = this._formBuilder.group({
      MATTERGUID: ['', Validators.required],
      matterautoVal: [''],
      ITEMTYPE: [''],
      QUANTITYTYPE: ['Hours'],
      ITEMDATE: ['', Validators.required],
      ITEMDATETEXT: [''],
      FEEEARNER: [''],
      QUANTITY: [''],
      PRICE: [''],
      PRICEINCGST: [''],
      ITEMTIME: [''],
      ADDITIONALTEXTSELECT: [''],
      ADDITIONALTEXT: ['', Validators.required],
      COMMENT: [''],
      AmmountRemaing: [''],
      AmmountWirteOff: [''],
      WirteOffExAmount: [0],
      WirteOffInAmount: [0],
      RemainingExAmount: [0],
      RemainingInAmount: [0],
    });
    this.isLoadingResults = true;
    let workerGuid;
    if (this.writeTimerData.type == 'WIP') {
      let selectedWipCheckBox: any = JSON.parse(localStorage.getItem('SelectedWIPCheckbox'));
      if (selectedWipCheckBox && selectedWipCheckBox.length < 2 && selectedWipCheckBox[0]) {
        workerGuid = selectedWipCheckBox[0].WORKITEMGUID;
      } else {
        this.behaviorService.workInProgress$.subscribe(workInProgress => {
          workerGuid = workInProgress ? workInProgress.WORKITEMGUID : localStorage.getItem('edit_WORKITEMGUID');
        });
      }
    } else {
      this.behaviorService.MainTimeEntryData$.subscribe(MainTimeEntryData => {
        workerGuid = MainTimeEntryData ? MainTimeEntryData.WORKITEMGUID : localStorage.getItem('edit_WORKITEMGUID');
      });
    }

    if(this.router.url == '/invoice/bulk-invoices'){
      this.behaviorService.refreshTaggedList.pipe(takeUntil(this._unsubscribeAll$)).subscribe(data=>{
        if(data && data.length >0){
          workerGuid=data[0].WORKITEMGUID;
        }
    })
    }
    this.isLoadingResults = true;
    this.Timersservice.getTimeEnrtyData({ 'WorkItemGuid': workerGuid }).subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        this.timeEntryData = response.DATA.WORKITEMS[0];
        let timeEntryData = response.DATA.WORKITEMS[0];
        this.matterShortName = response.DATA.WORKITEMS[0].SHORTNAME;
        localStorage.setItem('edit_WORKITEMGUID', timeEntryData.WORKITEMGUID);
        if (timeEntryData.ITEMTYPE == "2" || timeEntryData.ITEMTYPE == "3") {
          this.writeOffTimerForm.controls['QUANTITYTYPE'].setValue(timeEntryData.FEETYPE);
        } else {
          this.writeOffTimerForm.controls['QUANTITYTYPE'].setValue(timeEntryData.QUANTITYTYPE);
        }
        this.writeOffTimerForm.controls['matterautoVal'].setValue(timeEntryData.SHORTNAME + ' : ');
        this.writeOffTimerForm.controls['QUANTITY'].setValue(timeEntryData.QUANTITY);
        this.writeOffTimerForm.controls['MATTERGUID'].setValue(timeEntryData.MATTERGUID);
        this.writeOffTimerForm.controls['ITEMTYPE'].setValue(timeEntryData.ITEMTYPE);
        if (timeEntryData.ITEMTIME) {
          let ttyData = moment(timeEntryData.ITEMTIME, 'hh:mm');
          this.writeOffTimerForm.controls['ITEMTIME'].setValue(moment(ttyData).format('hh:mm A'));
        }
        this.writeOffTimerForm.controls['FEEEARNER'].setValue(timeEntryData.FEEEARNER);
        let tempDate = timeEntryData.ITEMDATE.split("/");
        this.writeOffTimerForm.controls['ITEMDATE'].setValue(timeEntryData.ITEMDATE);
        this.writeOffTimerForm.controls['ITEMDATETEXT'].setValue(new Date(tempDate[1] + '/' + tempDate[0] + '/' + tempDate[2]));

        if (this.timeEntryData.QUANTITYTYPE == 'hh:mm') {
          let hms = timeEntryData.QUANTITY;   // your input string
          let a = hms.split(':'); // split it at the colons
          let CURRENTQUANTITY = (+Number(a[0])) * 60 + Number(+a[1]);
          // this.writeOffTimerForm.controls['AmmountWirteOff'].setValue(CURRENTQUANTITY);
          this.writeOffTimerForm.controls['AmmountWirteOff'].setValue(timeEntryData.QUANTITY);
        } else {
          this.writeOffTimerForm.controls['AmmountWirteOff'].setValue(timeEntryData.QUANTITY);
        }
        this.writeOffTimerForm.controls['WirteOffExAmount'].setValue(timeEntryData.PRICE);
        this.writeOffTimerForm.controls['WirteOffInAmount'].setValue(timeEntryData.PRICEINCGST);
        this.writeOffTimerForm.controls['RemainingExAmount'].setValue(0);
        this.writeOffTimerForm.controls['RemainingInAmount'].setValue(0);

        this.writeOffTimerForm.controls['PRICEINCGST'].setValue(timeEntryData.PRICEINCGST);
        this.writeOffTimerForm.controls['PRICE'].setValue(timeEntryData.PRICE);
        this.writeOffTimerForm.controls['ADDITIONALTEXT'].setValue(timeEntryData.ADDITIONALTEXT);
        this.writeOffTimerForm.controls['ADDITIONALTEXTSELECT'].setValue(timeEntryData.ADDITIONALTEXT);
        this.writeOffTimerForm.controls['COMMENT'].setValue(timeEntryData.COMMENT);
      } else if (response.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
      }
      this.isLoadingResults = false;
    }, err => {
      this.isLoadingResults = false;
      this.toastr.error(err);
    });
    this.setTooltipData()}

    /**
     * this function is  used to set tool tip data value
     */
  async setTooltipData(){
    let TooltipData = JSON.parse(localStorage.getItem('FieldTooltipData'));
    if(TooltipData && TooltipData['WorkItems'] ){
      this.toolTipList  = TooltipData['WorkItems'];
    }else{
      this.tooltipService.setToolTipData = ('WorkItems');
     }
  };

  /**
   * This function is used to toggle for the update 
   */
  ToggleForUpadte() {
    this.testBool = !this.testBool;
    this.setTooltipData()
  };

/**
   * This function is called when user type something in Amount Remining field & Amout write off field
   * This function is used for calculate the GST value based on quantity type and duration
   * @param type - type of amount
  */
  calcWriteOff(type: any) {
    let CURRENTQUANTITY: any = 0;
    if (this.timeEntryData.QUANTITYTYPE == 'hh:mm') {
      let hms = this.f.QUANTITY.value;   // your input string
      let a = hms.split(':'); // split it at the colons
      CURRENTQUANTITY = (+Number(a[0])) * 60 + Number(+a[1]);
    } else {
      CURRENTQUANTITY = this.f.QUANTITY.value;
    }
    let WirteOffExAmount: any = this.f.PRICE.value;
    let WirteOffInAmount: any = this.f.PRICEINCGST.value;
    let RemainingExAmount: any = 0;
    let RemainingInAmount: any = 0;
    if (type == 'wo') {
      if (this.timeEntryData.QUANTITYTYPE == 'hh:mm') {
        let awo = this.f.AmmountWirteOff.value;   // your input string
        let value = awo.split(':');
        const hours = parseInt(value[0], 10);
        const minutes = parseInt(value[1], 10);
        const finalValue = (hours * 60) + minutes;
        let AmmountRemaingVal = CURRENTQUANTITY >= finalValue ? CURRENTQUANTITY - finalValue : CURRENTQUANTITY;
        const min = '' + AmmountRemaingVal % 60;
        const finalReminingVal = Math.floor(AmmountRemaingVal / 60) + ':' +(min.length == 1 ? '0' + min : min);
        this.writeOffTimerForm.controls['AmmountRemaing'].setValue(finalReminingVal);
      } else {
        let AmmountRemaingVal = CURRENTQUANTITY >= this.f.AmmountWirteOff.value ? CURRENTQUANTITY - this.f.AmmountWirteOff.value : CURRENTQUANTITY;
        this.writeOffTimerForm.controls['AmmountRemaing'].setValue(AmmountRemaingVal);
      }
    } else {
      if (this.timeEntryData.QUANTITYTYPE == 'hh:mm') {
        let aro = this.f.AmmountRemaing.value;   // your input string
        let roValue = aro.split(':');
        const hours = parseInt(roValue[0], 10);
        const minutes = parseInt(roValue[1], 10);
        const finalValue = (hours * 60) + minutes;
        let AmmountWirteOffVal = CURRENTQUANTITY >= finalValue ? CURRENTQUANTITY - finalValue : CURRENTQUANTITY;
        const min = '' + AmmountWirteOffVal % 60;
        const finalWriteOffVal = Math.floor(AmmountWirteOffVal / 60) + ':' +(min.length == 1 ? '0' + min : min);
        this.writeOffTimerForm.controls['AmmountWirteOff'].setValue(finalWriteOffVal);
      } else {
        let AmmountWirteOffVal = CURRENTQUANTITY >= this.f.AmmountRemaing.value ? CURRENTQUANTITY - this.f.AmmountRemaing.value : CURRENTQUANTITY;
        this.writeOffTimerForm.controls['AmmountWirteOff'].setValue(AmmountWirteOffVal);
      }
    }
    if (this.timeEntryData.QUANTITYTYPE == 'hh:mm') {
      let value1 = this.f.AmmountRemaing.value;   // your input string
      let value2 = value1.split(':');
      const hours = parseInt(value2[0], 10);
      const minutes = parseInt(value2[1], 10);
      const finalValue = (hours * 60) + minutes;
      RemainingExAmount = finalValue * WirteOffExAmount / CURRENTQUANTITY;
      RemainingInAmount = finalValue * WirteOffInAmount / CURRENTQUANTITY;
      WirteOffExAmount = WirteOffExAmount - RemainingExAmount;
      WirteOffInAmount = WirteOffInAmount - RemainingInAmount;
      this.writeOffTimerForm.controls['WirteOffExAmount'].setValue(WirteOffExAmount);
      this.writeOffTimerForm.controls['WirteOffInAmount'].setValue(WirteOffInAmount);
      this.writeOffTimerForm.controls['RemainingExAmount'].setValue(RemainingExAmount);
      this.writeOffTimerForm.controls['RemainingInAmount'].setValue(RemainingInAmount);
    } else {
      RemainingExAmount = this.f.AmmountRemaing.value * WirteOffExAmount / CURRENTQUANTITY;
      RemainingInAmount = this.f.AmmountRemaing.value * WirteOffInAmount / CURRENTQUANTITY;
      WirteOffExAmount = WirteOffExAmount - RemainingExAmount;
      WirteOffInAmount = WirteOffInAmount - RemainingInAmount;
      this.writeOffTimerForm.controls['WirteOffExAmount'].setValue(WirteOffExAmount);
      this.writeOffTimerForm.controls['WirteOffInAmount'].setValue(WirteOffInAmount);
      this.writeOffTimerForm.controls['RemainingExAmount'].setValue(RemainingExAmount);
      this.writeOffTimerForm.controls['RemainingInAmount'].setValue(RemainingInAmount);
    }
  }


  // calcWriteOff(type: any) {
  //   let CURRENTQUANTITY: any = 0;
  //   if (this.timeEntryData.QUANTITYTYPE == 'hh:mm') {
  //     let hms = this.f.QUANTITY.value;   // your input string
  //     let a = hms.split(':'); // split it at the colons
  //     CURRENTQUANTITY = (+Number(a[0])) * 60 + Number(+a[1]);
  //   } else {
  //     CURRENTQUANTITY = this.f.QUANTITY.value;
  //   }
  //   let WirteOffExAmount: any = this.f.PRICE.value;
  //   let WirteOffInAmount: any = this.f.PRICEINCGST.value;
  //   let RemainingExAmount: any = 0;
  //   let RemainingInAmount: any = 0;
  //   if (type == 'wo') {
  //     let AmmountRemaingVal = CURRENTQUANTITY >= this.f.AmmountWirteOff.value ? CURRENTQUANTITY - this.f.AmmountWirteOff.value : CURRENTQUANTITY;
  //     this.writeOffTimerForm.controls['AmmountRemaing'].setValue(AmmountRemaingVal);
  //   } else {
  //     let AmmountWirteOffVal = CURRENTQUANTITY >= this.f.AmmountRemaing.value ? CURRENTQUANTITY - this.f.AmmountRemaing.value : CURRENTQUANTITY;
  //     this.writeOffTimerForm.controls['AmmountWirteOff'].setValue(AmmountWirteOffVal);
  //   }

  //   RemainingExAmount = this.f.AmmountRemaing.value * WirteOffExAmount / CURRENTQUANTITY;
  //   RemainingInAmount = this.f.AmmountRemaing.value * WirteOffInAmount / CURRENTQUANTITY;
  //   WirteOffExAmount = WirteOffExAmount - RemainingExAmount;
  //   WirteOffInAmount = WirteOffInAmount - RemainingInAmount;
  //   this.writeOffTimerForm.controls['WirteOffExAmount'].setValue(WirteOffExAmount);
  //   this.writeOffTimerForm.controls['WirteOffInAmount'].setValue(WirteOffInAmount);
  //   this.writeOffTimerForm.controls['RemainingExAmount'].setValue(RemainingExAmount);
  //   this.writeOffTimerForm.controls['RemainingInAmount'].setValue(RemainingInAmount);
  // }

  /**
   * This function is used to convert the data from seconds to HMS
   * @param d -valu of the time
   * @returns 
   */
  secondsToHms(d: any) {
    d = Number(d);
    var hours = Math.floor(d / 3600) < 10 ? ("00" + Math.floor(d / 3600)).slice(-2) : Math.floor(d / 3600);
    var minutes = ("00" + Math.floor((d % 3600) / 60)).slice(-2);
    var seconds = ("00" + (d % 3600) % 60).slice(-2);
    return hours + ":" + minutes + ":" + seconds;
  }
  get f() {
    return this.writeOffTimerForm.controls;
  }

  /**
   * this function is used to Save time entry data
   */
  SaveClickTimeEntry() {
   this.openBulkProgressbar()
    this.isspiner = true;
    let PostData: any = {
      ADDITIONALTEXT: this.f.ADDITIONALTEXT.value,
      COMMENT: this.f.COMMENT.value,
      FEEEARNER: this.f.FEEEARNER.value,
      ITEMTYPE: this.f.ITEMTYPE.value,
      ITEMDATE: this.f.ITEMDATE.value,
      ITEMTIME: this.f.ITEMTIME.value,
      MATTERGUID: this.f.MATTERGUID.value,
      PRICE: this.f.PRICE.value,
      PRICEINCGST: this.f.PRICEINCGST.value,
      QUANTITY: this.f.QUANTITY.value,
      //   AmmountRemaingVal = this.secondsToHms(AmmountRemaingVal);
      WRITEOFFQUANTITY: this.f.AmmountWirteOff.value,
      REMAININGQUANTITY: this.f.AmmountRemaing.value,
      WorkItemGuid: localStorage.getItem('edit_WORKITEMGUID')
    }
    // if (this.timeEntryData.QUANTITYTYPE == 'hh:mm') {
    //   PostData.WRITEOFFQUANTITY = this.secondsToHms(this.f.AmmountWirteOff.value);
    //   PostData.REMAININGQUANTITY = this.secondsToHms(this.f.AmmountRemaing.value);
    // }
    if (this.f.ITEMTYPE.value == "2" || this.f.ITEMTYPE.value == "3") {
      PostData.FEETYPE = this.f.QUANTITYTYPE.value;
      PostData.QUANTITYTYPE = '';
    } else {
      PostData.QUANTITYTYPE = this.f.QUANTITYTYPE.value;
    }
    let PostTimeEntryData: any = { FormAction: 'write off', VALIDATEONLY: true,RequestGuid:this.RequestGuid, Data: PostData };
    this.Timersservice.SetWorkItems(PostTimeEntryData).subscribe(res => {
      if (res.CODE == 200 && res.STATUS == "success") {
        this.checkValidation(res.DATA.VALIDATIONS, PostTimeEntryData);
      } else if (res.CODE == 451 && res.STATUS == 'warning') {
        this.checkValidation(res.DATA.VALIDATIONS, PostTimeEntryData);
      } else if (res.CODE == 450 && res.STATUS == 'error') {
        this.checkValidation(res.DATA.VALIDATIONS, PostTimeEntryData);
      } else if (res.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
        this.dialogRefdata.close()
      }

    }, err => {
      this.isspiner = false;
      this.toastr.error(err);
    });
  }

  /**
   * This function is used to check the validation
   * @param bodyData -this function is used to body data
   * @param PostTimeEntryData -post time entry data
   */
  checkValidation(bodyData: any, PostTimeEntryData: any) {
    let errorData: any = [];
    let warningData: any = [];
    let tempError: any = [];
    let tempWarning: any = [];
    // errorData
    bodyData.forEach(function (value) {
      if (value.VALUEVALID == 'No' || value.VALUEVALID == 'NO') {
        errorData.push(value.ERRORDESCRIPTION);
        tempError[value.FIELDNAME] = value;
      } else if (value.VALUEVALID == 'WARNING' || value.VALUEVALID == 'Warning') {
        tempWarning[value.FIELDNAME] = value;
        warningData.push(value.ERRORDESCRIPTION);
      }
    });
    this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
    if (Object.keys(errorData).length != 0) {
      this.toastr.error(errorData);
      this.isspiner = false;
    } else if (Object.keys(warningData).length != 0) {
      this.isspiner = false;
      // this.toastr.warning(warningData);
      this.confirmDialogRef = this.MatDialog.open(FuseConfirmDialogComponent, {
        disableClose: true,
        width: '100%',
        data: warningData
      });
      this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isspiner = true;
          this.saveTimeEntry(PostTimeEntryData);
        }else{
          this.dialogRefdata.close(true);
          
        }
        this.confirmDialogRef = null;
      });
    } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
      this.saveTimeEntry(PostTimeEntryData);

    }
  }

  /**
   * This function is used to save the time entry
   * @param PostTimeEntryData -post time Entry data 
   */
  saveTimeEntry(PostTimeEntryData: any) {
    this.RequestGuid =  this._mainAPiServiceService.generateUniqSerial();
    PostTimeEntryData.VALIDATEONLY = false;
    PostTimeEntryData.RequestGuid=this.RequestGuid
    this.Timersservice.SetWorkItems(PostTimeEntryData).subscribe(res => {
      if (res.CODE == 200 && res.STATUS == "success") {
        this.toastr.success(this.successMsg);
        this.dialogRef.close(true);
        this.dialogRefdata.close(true);
      } else if (res.CODE == 451 && res.STATUS == 'warning') {
        this.toastr.warning(res.MESSAGE);
        this.dialogRefdata.close(true);
        this.isspiner = false;
      } else if (res.CODE == 450 && res.STATUS == 'error') {
        this.toastr.warning(res.MESSAGE);
        this.isspiner = false;
        this.dialogRefdata.close(true);

      } else if (res.MESSAGE == 'Not logged in') {
        this.dialogRef.close(false);
        this.dialogRefdata.close(true);

      } else {
        this.isspiner = false;
      }
    }, err => {
      this.isspiner = false;
      this.toastr.error(err);
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll$.next();
    this._unsubscribeAll$.complete();
}

openBulkProgressbar(){
  this.dialogRefdata = this.MatDialog.open(BulkProgressComponent, {
      width: "100%",
      disableClose: true,
      data: "",
  });
  this.dialogRefdata.componentInstance.DialogTitle = "Write Off Entries";
  this.dialogRefdata.afterClosed().subscribe((result) => {
      if (result) {
        
      }
  });
}
}
