<div cdkDrag class="popup_main_div generrate_document_main" >
    <h2 cdkDragHandle mat-dialog-title>
        <span >{{DialogTitle}}</span>
        <button class="btn" mat-icon-button [mat-dialog-close]="false" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>

    <div class="example-loading-shade">
        <mat-spinner [style.zoom]="0.5"></mat-spinner>
    </div>
    <div mat-dialog-content class="mat-typography main" style="margin-top: 30px;" >
        <div class="one" style="width: 100%;" >
          <div style="text-align: center;">
            <!-- <div>invoices created.</div><br> -->
            <strong style="font-size: 15px">Please wait while we process your request</strong> 
             <div *ngIf="DialogTitle == 'Delete Entries'"> {{countdata?.updatedCount}} of {{countdata?.totalcount}} entries deleted</div>
          </div>
        </div>
      </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <!-- <button mat-raised-button color="primary" [mat-dialog-close]="false"  id="canclePopup"
            cdkFocusInitial>Cancel
        </button> -->
    </mat-dialog-actions>
    
</div>