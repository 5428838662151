<div id="checkbankfeed" (click)="BankPop('authorise')"></div>
<div id="deleteTimeEntry" (click)="deleteTimeEntry()"></div>
<div id="EditTimeEntryFromWIP" (click)="EditTimeEntryFromWIP()"></div>

<fuse-sidebar name="setupguide" position="right"
    class="notifications-panel time_entry_sidebar setupguide-sidebar">
    <!-- <app-notifications [notificationList]='notificationList'></app-notifications> -->
    <app-setup-guide [SetUpGuideData]="SetUpGuideData"
        [isLoadingResults]="isLoadingResults"></app-setup-guide>
</fuse-sidebar>
<div>
<fuse-sidebar name="quickPanel" position="right"
    class="quick-panel time_entry_sidebar">
    <quick-panel [eventsData]="prevMatterArray"
        [currentTimerHMS]="currentTimerHMS"
        (StopMatterId)="stopMatterBack($event)"
        (EndMatterId)="endMatterBack($event)"
        (StartMatterId)="startMatterBack($event)"></quick-panel>
</fuse-sidebar>

<fuse-sidebar name="notifications" position="right"
    class="notifications-panel time_entry_sidebar">
    <app-notifications [notificationList]='notificationList'></app-notifications>
</fuse-sidebar>
</div>


<mat-toolbar class="p-0 mat-elevation-z1 top_header" id="tool_baar_main">
    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center"
            class="matbar_header">
            <button mat-icon-button class="navbar-toggle-button"
                *ngIf="!hiddenNavbar && !rightNavbar"
                (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
                <!-- {{'demo'+isTabShow}} -->
            </button>
            <!-- <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div> -->
            <div fxLayout="row" fxLayoutAlign="start center"
                *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <img class="logo-icon"
                        src="assets/images/logos/silq-logo.svg">
                </div>
            </div>
            <ng-container *ngIf="appPermissions">
                <mat-tab-group fxLayout="column"
                class="ribbon-container"
                    (selectedTabChange)="clickMatTab($event)"
                    [(selectedIndex)]="selectedIndex"
                    id="toolbarMatGroup">
                    <!-- matters tab start -->
                    <mat-tab label="MATTERS"
                        *ngIf="isTabShow===1 && hidematterTab">
                        <section class="ribbon-container matter_tab_main"
                            [style.width]="detailwidth">
                            <mat-card>
                                <mat-card-content>
                                    <section
                                        class="group-section-tab action_tab">
                                        <section
                                            [ngClass]="appPermissions[1]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                (click)="toolbarServiceService.matterpopup('new')"
                                                [ngClass]="appPermissions[1]?.Create==0 ? 'disabled-click large-button' : 'large-button'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_matter_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text"><span>New<br>
                                                            Matter</span></div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[1]?.Edit==0 || isMatterData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                (click)="toolbarServiceService.matterpopup('edit')"
                                                [ngClass]="(appPermissions[1]?.Edit==0 || isMatterData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                id="editMatter">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_matter_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Edit<br> Matter</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[1]?.Delete==0 || isMatterData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                (click)="toolbarServiceService.DeleteNewmatterpopup()"
                                                [ngClass]="(appPermissions[1]?.Delete==0 || isMatterData =='empty') ? 'disabled-click large-button' : 'large-button'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_matter_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete<br> Matter</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[1]?.Create==0 || isMatterData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                (click)="toolbarServiceService.matterpopup('duplicate')"
                                                [ngClass]="(appPermissions[1]?.Create==0 || isMatterData =='empty') ? 'disabled-click large-button' : 'large-button'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_duplicate_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Duplicate<br>
                                                            Matter</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Action</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                    <section
                                        class="group-section-tab details_tab">
                                        <!-- OLD UI CHANGES MADE WITH V11 -->
                                        <!-- <section
                                       *ngIf="!matterDetailsFlag"
                                        [ngClass]="isMatterData =='empty' ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                        class="ribbon-section">
                                        <button
                                            [ngClass]="isMatterData =='empty' ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                            mat-button class="large-button"
                                            (click)="setTab($event);targetNewTab('/time-billing','TIMEANDBILLING')">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_invoice_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Time &<br> Billing</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section> -->
                                        <section
                                            [ngClass]="isMatterData =='empty' ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                            class="ribbon-section">
                                            <!-- OLD UI CHANGES MADE WITH V11 -->
                                            <!-- <button *ngIf="!matterDetailsFlag"
                                            [ngClass]="isMatterData =='empty' ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                            mat-button class="large-button"
                                            (click)="setTab($event);targetNewTab('/legal-details','LEGALDETAILS');">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_authorities_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Legal<br> Details</span>
                                                </div>
                                            </section>
                                        </button> -->

                                            <!-- OLD UI CHANGES MADE WITH V11 -->
                                            <button
                                                [ngClass]="isMatterData =='empty' ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                                mat-button class="large-button"
                                                (click)="setTab($event);targetNewTab('/matter-details','MATTERDETAILS');">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_authorities_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Matter<br> Details</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <!-- OLD UI CHANGES MADE WITH V11 -->
                                        <!-- <section
                                        *ngIf="!matterDetailsFlag"
                                        [ngClass]="(appPermissions[6]?.Access==0 ||  isMatterData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="(appPermissions[6]?.Access==0 ||  isMatterData =='empty')? 'disabled-click large-button' : 'large-button'"
                                            (click)="setTab($event);targetNewTab('/document-register','DOCUMENTREGISTER')">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_doc_reg_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Document<br> Register</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section> -->

                                        <!-- <section [ngClass]="(appPermissions[5]?.Access==0 || isMatterData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button [ngClass]="(appPermissions[5]?.Access==0 || isMatterData =='empty') ? 'disabled-click large-button' : 'large-button'" (click)="isMatterClick()" (click)="setTab($event);targetNewTab('/create-document/matter-template','DOCUMENTTEMPLATES')">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_letters_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Document <br> Templates</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section> -->

                                        <!-- Abhay Added  (document fuctionality in popup)-->
                                        <section
                                            [ngClass]="(appPermissions[5]?.Access==0 || isMatterData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'" *ngIf="('Document Generation' | tierPermission : 'Document Generation') && ('Document Generation' | tierPermission : 'Document Generation').ALLOWED == 1">
                                            <button mat-button
                                                [ngClass]="(appPermissions[5]?.Access==0 || isMatterData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.generateDocumentsFromMatters($event);SendEmail(false)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_letters_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Generate <br>
                                                            Documents</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <!-- dummy dashboard -->
                                        <!-- <section [ngClass]="appPermissions[5]?.Access==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button [ngClass]="appPermissions[5]?.Access==0 ? 'disabled-click large-button' : 'large-button'" (click)="isMatterClick()" (click)="setTab($event)" [routerLink]="'/dashboard'">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_doc_reg_d.ico"
                                                        >
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Dashboard</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section> -->

                                        <div class="light_label_txt">Details</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                    <section
                                        class="group-section-tab extras_tab"
                                        [ngClass]="(isGenrateReport) ? 'disabled-click-cursor' : ''">
                                        <section class="ribbon-section">
                                            <mat-nav-list>
                                                <a mat-list-item
                                                    *ngIf="appPermissions[2]"
                                                    (click)="toolbarServiceService.addNewTimeEntryNew('TRUE')"
                                                    [ngClass]="(appPermissions[2] && appPermissions[2]['Create WIP']==0  || isMatterData =='empty') ? 'disabled-click-cursor' : ''">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_d.ico">
                                                    </mat-icon>
                                                    <span>New Time Entry</span>
                                                </a>
                                                <a mat-list-item
                                                    *ngIf="appPermissions[2]"
                                                    (click)="toolbarServiceService.addTimerForMatter()"
                                                    [ngClass]="(appPermissions[2] && appPermissions[2]['Create WIP']==0  || isMatterData =='empty')? 'disabled-click-cursor' : ''">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_d.ico">
                                                    </mat-icon>
                                                    <span>New Timer</span>
                                                </a>
                                                <a mat-list-item *ngIf=" ('File Notes' | tierPermission : 'File Notes') && ('File Notes' | tierPermission : 'File Notes').ALLOWED ==1"
                                                    (click)="toolbarServiceService.FileNotePopup('new')"
                                                    [ngClass]="(appPermissions[13]?.Create==0  || isMatterData =='empty')? 'disabled-click-cursor' : ''">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_filesnotes_new_d.ico">
                                                    </mat-icon>
                                                    <span>New File Note</span>
                                                </a>
                                            </mat-nav-list>
                                        </section>
                                        <section class="ribbon-section">
                                            <mat-nav-list>
                                                <!-- <a mat-list-item
                                                [ngClass]="(appPermissions[3]?.Edit==0 || isMatterData =='empty') ? 'disabled-click-cursor' : ''"
                                                (click)="toolbarServiceService.ContactsDialog('matter_contect')">
                                                <mat-icon>
                                                    <img src="assets/icons/web_app/icon_client_d.ico">
                                                </mat-icon>
                                                <span>{{('' | productType)?'Client Details':'Solicitor Details'}}</span>
                                            </a>
                                            <a mat-list-item
                                                [ngClass]="(appPermissions[3]?.Edit==0 || isMatterData =='empty') ? 'disabled-click-cursor' : ''"
                                                (click)="toolbarServiceService.openCorresDialog()">
                                                <mat-icon>
                                                    <img src="assets/icons/web_app/icon_contact_edit_d.ico">
                                                </mat-icon>
                                                <span>Corres Details</span>
                                            </a> -->

                                                <a mat-list-item
                                                    [ngClass]="(appPermissions[3]?.Edit==0 || isMatterData =='empty') ? 'disabled-click-cursor' : ''"
                                                    (click)="toolbarServiceService.createInstantInvoice(false)">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_invoice_new_d.ico">
                                                    </mat-icon>
                                                    <span>Instant Invoice</span>
                                                </a>
                                                <a mat-list-item
                                                    (click)="toolbarServiceService.createInvoice('preview');"
                                                    [ngClass]="(isMatterData =='empty' || !selectedMatterRow.UNBILLEDVALUEEXGST) ? 'disabled-click-cursor' : ''">
                                                    <!-- <a mat-list-item (click)="createInvoice('preview');" > -->
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_invoice_draft_d.ico">
                                                    </mat-icon>
                                                    <span>Preview Invoice</span>
                                                </a>
                                                <!-- createInvoice('') ;  createInvoicebyhiral('')-->
                                                <a mat-list-item
                                                    (click)="toolbarServiceService.createInvoice('Invoice All WIP')"
                                                    [ngClass]="(isMatterData =='empty' || !selectedMatterRow.UNBILLEDVALUEEXGST)? 'disabled-click-cursor' : ''">
                                                    <!-- <a mat-list-item (click)="createInvoice('');"> -->

                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_invoice_new_d.ico">
                                                    </mat-icon>
                                                    <span>Invoice All WIP</span>
                                                </a>

                                            </mat-nav-list>
                                        </section>
                                        <section class="ribbon-section">
                                            <mat-nav-list>

                                                <a
                                                    (click)="toolbarServiceService.createReceipt('allredy_select_matter')"
                                                    mat-list-item
                                                    [ngClass]="(isMatterData =='empty')? 'disabled-click-cursor' : ''">
                                                    <mat-icon>

                                                        <img
                                                            src="assets/icons/web_app/icon_receipt_new_d.ico">
                                                    </mat-icon>
                                                    <span>New Receipt</span>
                                                </a>
                                                <a mat-list-item
                                                    [ngClass]="(appPermissions[2] && appPermissions[2]['Create Disbursements']==0  || isMatterData =='empty') ? 'disabled-click-cursor' : ''"
                                                    (click)="toolbarServiceService.spendmoneypopup('new','FromWIP','true')">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_expenses_new_d.ico">
                                                    </mat-icon>
                                                    <span>New Matter Expense</span>
                                                </a>
                                                <a mat-list-item
                                                    [ngClass]="( isMatterData =='empty') ? 'disabled-click-cursor' : ''"
                                                    (click)="OpenMatter('MATTERFOLDER','matter_page')">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/folder_large.ico">
                                                    </mat-icon>
                                                    <span>Matter Folder</span>
                                                </a>
                                            </mat-nav-list>
                                        </section>
                                        <section class="ribbon-section">
                                            <mat-nav-list>
                                                <a mat-list-item
                                                    *ngIf="('' | productType) && (('Searching' | tierPermission : 'Searching') && ('Searching' | tierPermission : 'Searching').ALLOWED == 1)"
                                                    [matMenuTriggerFor]="menuInfo">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/infotrack.ico">
                                                    </mat-icon>
                                                    <!-- <span>InfoTrack/GlobalX</span> -->
                                                       <span>InfoTrack</span> 
                                                </a>
                                                <mat-menu #menuInfo="matMenu">
                                                    <button
                                                        (click)="viewOnlineDocument('matter', selectedMatterRow.MATTERGUID)"
                                                        mat-menu-item>
                                                        <mat-icon>
                                                            <img
                                                                src="assets/icons/web_app/infotrack.ico">
                                                        </mat-icon>InfoTrack
                                                        Search
                                                    </button>
                                                    <button
                                                        (click)="viewOnlineDocument('WebVOI', selectedMatterRow.MATTERGUID)"
                                                        mat-menu-item>
                                                        <mat-icon>
                                                            <img
                                                                src="assets/icons/web_app/infotrack.ico">
                                                        </mat-icon>InfoTrack
                                                        WebVOI
                                                    </button>
                                                    <button
                                                        (click)="viewOnlineDocument('CourtSync', selectedMatterRow.MATTERGUID)"
                                                        mat-menu-item>
                                                        <mat-icon>
                                                            <img
                                                                src="assets/icons/web_app/infotrack.ico">
                                                        </mat-icon>InfoTrack
                                                        Court Sync
                                                    </button>
                                                    <!-- <button
                                                        (click)="viewOnlineDocument('GlobalXSearch', selectedMatterRow.MATTERGUID)"
                                                        mat-menu-item>
                                                        <mat-icon>
                                                            <img
                                                                src="assets/icons/web_app/globalx.png">
                                                        </mat-icon>GlobalX
                                                        Search
                                                    </button> -->
                                                </mat-menu>
                                                <!-- <a mat-list-item *ngIf="currentUser?.PRODUCTTYPE!='Barrister'"
                                                (click)="viewOnlineDocument('matter', selectedMatterRow.MATTERGUID)"
                                                [ngClass]="(appPermissions[16]?.Access==0 || isMatterData =='empty') ? 'disabled-click-cursor' : ''">
                                                <mat-icon>
                                                    <mat-icon>
                                                        <img src="assets/icons/web_app/infotrack.ico"
                                                            >
                                                    </mat-icon>
                                                </mat-icon>
                                                <span>InfoTrack Search</span>
                                            </a> -->

                                            </mat-nav-list>
                                        </section>
                                        <mat-progress-bar mode="buffer"
                                            *ngIf="isGenrateReport"></mat-progress-bar>
                                        <div class="light_label_txt">Extras</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>

                                </mat-card-content>
                            </mat-card>
                        </section>
                    </mat-tab>
                    <!-- mattter tab menu end -->
                    <!-- contacts tab menu start -->
                    <mat-tab label="CONTACTS" *ngIf="isTabShow===2">
                        <section class="ribbon-container contact_tab_main"
                            [style.width]="detailwidth">
                            <mat-card>
                                <mat-card-content>
                                    <section
                                        class="group-section-tab action_tab">
                                        <section
                                            [ngClass]="appPermissions[3]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[3]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.ContactsDialog('new')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_contact_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New<br> Contact</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[3]?.Edit==0 || isContactData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[3]?.Edit==0 || isContactData =='empty')? 'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.ContactsDialog('edit')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_contact_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Edit<br> Contact</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[3]?.Delete==0 || isContactData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[3]?.Delete==0 || isContactData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="deleteContact()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_contact_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete<br> Contact</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[3]?.Create==0 || isContactData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[3]?.Create==0 || isContactData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.ContactsDialog('duplicate')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_duplicate_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Duplicate<br>
                                                            Contact</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Action</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                    <section class="group-section-tab action_tab" *ngIf="('Document Generation' | tierPermission : 'Document Generation') && ('Document Generation' | tierPermission : 'Document Generation').ALLOWED == 1">
                                        <section
                                            [ngClass]="(appPermissions[5]?.Access==0 || isContactData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <!-- <button mat-button [ngClass]="(appPermissions[5]?.Access==0 || isContactData =='empty') ? 'disabled-click large-button' : 'large-button'" (click)="setTab($event)" [routerLink]="'/create-document/contact-template'">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_letters_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Generate <br> Document</span>
                                                </div>
                                            </section>
                                        </button> -->

                                            <button mat-button
                                                [ngClass]="(appPermissions[5]?.Access==0 || isContactData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.generateDocumentsFromMatters($event);SendEmail(false)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_letters_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Generate <br>
                                                            Documents</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Details</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                </mat-card-content>
                            </mat-card>
                        </section>
                    </mat-tab>
                    <!-- contacts tab menu end -->
                    <!-- Time and Billing tab menu start -->
                    <mat-tab label="TIME AND BILLING" *ngIf="isTabShow===3">
                        <section class="ribbon-container time_billing_tab_main"
                            [style.width]="detailwidth">
                            <mat-card>
                                <mat-card-content>
                                    <section
                                        class="group-section-tab details_tab">
                                        <section class="ribbon-section">
                                            <button mat-button
                                                class="large-button"
                                                (click)="setTab($event);targetNewTab('/legal-details','LEGALDETAILS');">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_authorities_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Legal <br> Details</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section class="ribbon-section" *ngIf="('Document Register' | tierPermission : 'Document Register') && ('Document Register' | tierPermission : 'Document Register').ALLOWED == 1">
                                            <button mat-button
                                                class="large-button"
                                                (click)="setTab($event);targetNewTab('/document-register','DOCUMENTREGISTER');">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            class="menu-disabled"
                                                            src="assets/icons/web_app/icon_doc_reg_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Document <br>
                                                            Register</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <!-- || isTimeEntryData == 'empty' || timeEntryData.INVOICEGUID != '' -->
                                        <!-- <section class="group-section-tab details_tab"> -->
                                        <!-- <section [ngClass]="(appPermissions[3]?.Edit==0  || isWIPData == 'empty' ) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button [ngClass]="(appPermissions[3]?.Edit==0 || isWIPData == 'empty'  )? 'disabled-click large-button' : 'large-button'" (click)="splittimeentry('Split')">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_time_cost_edit_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Split <br>Time Entry</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section> -->
                                        <!-- </section> -->

                                        <div class="light_label_txt">Go To</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                    <section
                                        class="group-section-tab extras_tab">
                                        <section class="ribbon-section">
                                            <mat-nav-list>
                                                <a mat-list-item
                                                    [routerLink]="'/time-billing/estimate'"
                                                    [ngClass]="{'disabled-click-cursor':appPermissions[4]?.Access==0,'cmn_active_tab':activeSubMenu =='estimate'}">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_estimate_d.ico">
                                                    </mat-icon>
                                                    <span>Estimate</span>
                                                </a>
                                                <a mat-list-item
                                                    [routerLink]="'/time-billing/deliverables'"
                                                    *ngIf=" DerivableDropShow==false && currentUser?.PRODUCTTYPE!='Barrister' && ('Matter Deliverables' | tierPermission : 'Matter Deliverables').ALLOWED ==1"
                                                    [ngClass]="{'cmn_active_tab':activeSubMenu == 'deliverables'}">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/Deliverablesr.png">
                                                    </mat-icon>
                                                    <span>Deliverables</span>
                                                </a>
                                                <a mat-list-item
                                                    [routerLink]="'/time-billing/work-in-progress'"
                                                    [ngClass]="{'disabled-click-cursor':appPermissions[2]?.Access==0,'cmn_active_tab':activeSubMenu =='work-in-progress'}">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_d.ico">
                                                    </mat-icon>
                                                    <span>Work in Progress
                                                    </span>
                                                </a>
                                                <ng-container
                                                    *ngIf="appPermissions && appPermissions[22]">
                                                    <a mat-list-item
                                                        *ngIf="currentUser?.PRODUCTTYPE!='Barrister'"
                                                        [ngClass]="{'disabled-click-cursor':appPermissions[22]['Trust Matter Access']==0,'cmn_active_tab':activeSubMenu =='matter-trust'}"
                                                        [routerLink]="'/time-billing/matter-trust'">
                                                        <mat-icon>
                                                            <img
                                                                src="assets/icons/web_app/icon_trust_d.ico">
                                                        </mat-icon>
                                                        <span>Matter Trust</span>
                                                    </a>
                                                </ng-container>
                                            </mat-nav-list>
                                        </section>
                                        <section class="ribbon-section">
                                            <mat-nav-list>
                                                <a mat-list-item
                                                    [ngClass]="{'disabled-click-cursor':appPermissions[7]?.Access==0,'cmn_active_tab':activeSubMenu =='matter-invoices'}"
                                                    [routerLink]="'/time-billing/matter-invoices'">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_invoice_d.ico">
                                                    </mat-icon>
                                                    <span>Matter Invoices</span>
                                                </a>
                                                <a mat-list-item
                                                    [ngClass]="{'disabled-click-cursor':appPermissions[8]?.Access==0,'cmn_active_tab':activeSubMenu =='receipts-credits'}"
                                                    [routerLink]="'/time-billing/receipts-credits'">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_receipt_d.ico">
                                                    </mat-icon>
                                                    <span>Receipts & Credits</span>
                                                </a>
                                                <a mat-list-item
                                                    [ngClass]="{'disabled-click-cursor':appPermissions[8]?.Access==0,'cmn_active_tab':activeSubMenu =='matter-appointments'}"
                                                    [routerLink]="'/time-billing/matter-appointments'">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_d.ico">
                                                    </mat-icon>
                                                    <span>Matter Appointments</span>
                                                </a>
                                            </mat-nav-list>
                                        </section>
                                        <div class="light_label_txt">View</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                    <section
                                        class="group-section-tab action_tab"
                                        *ngIf="activeSubMenu=='receipts-credits'">
                                        <section
                                            [ngClass]="appPermissions[8]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[8]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="createReceiptForTimeBilling()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_receipt_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New Matter<br>
                                                            Receipt</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[8]?.Edit==0 || isReceiptData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[8]?.Edit==0 || isReceiptData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="ViewReceiptForTimeBilling()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_receipt_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>View<br> Receipt</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[8]?.Delete==0 || isReceiptData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[8]?.Delete==0 || isReceiptData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="deleteReceiceMoany()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_receipts_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete<br> Receipt</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Action</div>
                                    </section>
                                    <section
                                        class="group-section-tab details_tab"
                                        *ngIf="activeSubMenu =='deliverables' && currentUser?.PRODUCTTYPE!='Barrister' && ('Matter Deliverables' | tierPermission : 'Matter Deliverables').ALLOWED ==1  ">
                                        <section
                                            [ngClass]="appPermissions[4]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[4]?.Create==0 ? 'large-button' : 'large-button'"
                                                (click)="adddeliverable('New')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <!-- <img src="assets/icons/web_app/icon_estimate_new_d.ico"> -->
                                                        <img
                                                            src="assets/icons/web_app/Deliverablesr.png">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New Deliver. <br>
                                                            Item</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="isdeliverable =='empty' ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="isdeliverable =='empty'? 'disabled-click large-button' : 'large-button'"
                                                (click)="adddeliverable('edit')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <!-- <img src="assets/icons/web_app/icon_estimate_edit_d.ico"> -->
                                                        <img
                                                            src="assets/icons/web_app/Deliverablesr.png">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Edit Deliver. <br>
                                                            Item</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(isdeliverable =='empty')? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="isdeliverable =='empty'? 'disabled-click large-button' : 'large-button'"
                                                (click)="deletedeliverable()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <!-- <img src="assets/icons/web_app/icon_estimate_delete_d.ico"> -->
                                                        <img
                                                            src="assets/icons/web_app/Deliverablesr.png">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete Deliver.
                                                            <br> Item</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="( isdeliverable =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="isdeliverable =='empty'? 'disabled-click large-button' : 'large-button'"
                                                (click)="adddeliverable('Duplicate')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_duplicate_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Duplicate Deliver.<br>Item</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Action</div>
                                    </section>
                                    <section
                                        class="group-section-tab details_tab"
                                        *ngIf="activeSubMenu =='estimate'">
                                        <section
                                            [ngClass]="appPermissions[4]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[4]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="addNewEstimate('new')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_estimate_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New Est. <br> Item</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[4]?.Edit==0 || isEstimateData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[4]?.Edit==0 || isEstimateData =='empty')? 'disabled-click large-button' : 'large-button'"
                                                (click)="addNewEstimate('edit')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_estimate_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Edit Est. <br>
                                                            Item</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[4]?.Delete==0  || isEstimateData =='empty')? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[4]?.Delete==0  || isEstimateData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="deleteEstimate()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_estimate_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete Est. <br>
                                                            Item</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[4]?.Create==0 || isEstimateData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[4]?.Create==0 || isEstimateData =='empty')? 'disabled-click large-button' : 'large-button'"
                                                (click)="addNewEstimate('duplicate')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_duplicate_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Duplicate Est.<br>Item</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Action</div>
                                    </section>
                                    <section
                                        class="group-section-tab details_tab"
                                        *ngIf="activeSubMenu =='work-in-progress'">
                                        <section
                                            [ngClass]=" appPermissions[2] && appPermissions[2]['Create WIP'] == 0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[2] && appPermissions[2]['Create WIP'] == 0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.addNewTimeEntry('NewTimeEntryMatter')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New Time <br>
                                                            Entry</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <!-- (click)="spendmoneypopup('edit')"  (click)="toolbarServiceService.addNewTimeEntry('Edit')" -->
                                        <section
                                            [ngClass]="(appPermissions[2]?.Edit==0 || isWIPData == 'empty' || WorkInProgressData.INVOICEGUID != '' || WorkInProgressData.ITEMTYPEDESC=='Search') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[2]?.Edit==0 || isWIPData == 'empty' || WorkInProgressData.INVOICEGUID != '' || WorkInProgressData.ITEMTYPEDESC=='Search') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="EditTimeEntryFromWIP()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Edit <br> Entry</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <!-- <section [ngClass]="(appPermissions[2]?.Delete==0 || isWIPData == 'empty' || WorkInProgressData.INVOICEGUID != '' || WorkInProgressData.ITEMTYPEDESC=='Search') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button [ngClass]="(appPermissions[2]?.Delete==0 || isWIPData == 'empty' || WorkInProgressData.INVOICEGUID != '' || WorkInProgressData.ITEMTYPEDESC=='Search') ? 'disabled-click large-button' : 'large-button'" (click)="deleteTimeEntry('timeBilling_delete_timeEntry')">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_time_cost_delete_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Delete <br> Entry</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section> -->
                                        <section
                                            [ngClass]="(appPermissions[2]?.Delete==0 || isWIPData == 'empty' || WorkInProgressData?.INVOICEGUID != '' || WorkInProgressData?.ITEMTYPEDESC=='Search' || this.dissabledeleteButton==true) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[2]?.Delete==0 || isWIPData == 'empty' || WorkInProgressData?.INVOICEGUID != '' || WorkInProgressData?.ITEMTYPEDESC=='Search' || this.dissabledeleteButton==true) ? 'disabled-click large-button' : 'large-button'"
                                                (click)="deleteTimeEntry('timeBilling_delete_timeEntry')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete <br> Entry</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[2] && appPermissions[2]['Create WIP']==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[2] && appPermissions[2]['Create WIP']==0 ? 'disabled-click-cursor large-button' : 'large-button'"
                                                (click)="NewTimeEntry('timeBill')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New<br> Timer</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[2]?.Create==0 || isWIPData == 'empty' || WorkInProgressData.ITEMTYPEDESC=='Search') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[2]?.Create==0 || isWIPData == 'empty' || WorkInProgressData.ITEMTYPEDESC=='Search')? 'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.addNewTimeEntry('Duplicate')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Duplicate <br>Time
                                                            Entry</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[2] && appPermissions[2]['Create Disbursements']==0 || (appPermissions[9]?.Edit==0) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[2] && appPermissions[2]['Create Disbursements']==0 || appPermissions[9]?.Edit==0) ? 'disabled-click large-button' : 'large-button'"
                                                (click)="spendmoneypopup('new','FromWIP','','NEW MATTER EXPENCE')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_expenses_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New Matter <br>Expense</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Action</div>
                                    </section>
                                    <mat-divider vertical
                                        *ngIf="activeSubMenu =='work-in-progress'"></mat-divider>
                                    <!-- ssss -->
                                    <section class="ribbon-section"
                                        *ngIf="activeSubMenu =='work-in-progress'">
                                        <mat-nav-list>
                                            <!-- <a mat-list-item
                                            [ngClass]="(appPermissions[2]?.Create==0) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                            (click)="NewTimeEntry('timeBill')">
                                            <mat-icon>
                                                <img src="assets/icons/web_app/icon_time_cost_new_d.ico">
                                            </mat-icon>
                                            <span>New Timer</span>
                                        </a> -->
                                            <a mat-list-item
                                                [ngClass]="(appPermissions[2]?.Edit==0 || isWIPData == 'empty' || WorkInProgressData.ITEMTYPEDESC=='Search') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                                (click)="ResumeTimePopup('startTimer','')">
                                                <mat-icon>
                                                    <img
                                                        src="assets/icons/web_app/icon_time_cost_edit_d.ico">
                                                </mat-icon>
                                                <span>Resume Timer</span>
                                            </a>
                                            <a mat-list-item *ngIf="('Fixed Fee Items' | tierPermission : 'Fixed Fee Items') && ('Fixed Fee Items' | tierPermission : 'Fixed Fee Items').ALLOWED ==1"
                                                [ngClass]="(appPermissions[2]?.Create==0) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                                (click)="ActivityDialog('new','Activities')">
                                                <mat-icon>
                                                    <img
                                                        src="assets/icons/web_app/icon_time_cost_new_d.ico">
                                                </mat-icon>
                                                <span>New Fixed Fee</span>
                                            </a>
                                            <a mat-list-item *ngIf="('Fixed Fee Items' | tierPermission : 'Fixed Fee Items') && ('Fixed Fee Items' | tierPermission : 'Fixed Fee Items').ALLOWED ==1"
                                                [ngClass]="(appPermissions[2]?.Create==0) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                                (click)="ActivityDialog('new','Sundries')">
                                                <mat-icon>
                                                    <img
                                                        src="assets/icons/web_app/icon_expenses_new_d.ico">
                                                </mat-icon>
                                                <span>New Sundry</span>
                                            </a>
                                        </mat-nav-list>
                                    </section>
                                    <mat-divider vertical
                                        *ngIf="activeSubMenu =='work-in-progress'"></mat-divider>
                                    <!-- ss -->
                                    <section
                                        class="group-section-tab details_tab"
                                        *ngIf="activeSubMenu =='work-in-progress'">
                                        <section
                                            [ngClass]="appPermissions[7]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[7]?.Create==0 ? 'disabled-click-cursor large-button' : 'large-button'"
                                                (click)="toolbarServiceService.createInstantInvoice(false)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_invoice_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Instant <br>
                                                            Invoice</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[7]?.Edit==0 || isWIPData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[7]?.Edit==0 || isWIPData == 'empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.createInvoice('preview');">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_invoice_draft_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Preview <br>
                                                            Invoice</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[7]?.Create==0 ? 'disabled-click ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[7]?.Create==0 ? 'disabled-click-cursor large-button' : 'large-button'"
                                                (click)="toolbarServiceService.createInvoice('')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_invoice_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Create <br>
                                                            Invoice</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                    </section>
                                    <section
                                        class="group-section-tab details_tab"
                                        *ngIf="activeSubMenu =='matter-trust'">
                                        <section
                                            [ngClass]="(appPermissions[22]['Window Report']==0 || !isTrustMoneyListData || isGenrateReport) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[22]['Window Report']==0 || !isTrustMoneyListData || isGenrateReport) ? 'disabled-click large-button' : 'large-button'"
                                                (click)="ReportGenerate('reporttrustrequest','Trust Print Item')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_print_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Print<br>Item</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <mat-divider vertical
                                            *ngIf="activeSubMenu =='matter-trust'"></mat-divider>
                                        <div class="light_label_txt">Action</div>
                                    </section>
                                    <mat-divider vertical
                                        *ngIf="activeSubMenu =='work-in-progress'"></mat-divider>
                                    <section
                                        class="group-section-tab details_tab"
                                        *ngIf="activeSubMenu =='work-in-progress'">
                                        <section class="ribbon-section">
                                            <mat-nav-list>
                                                <a mat-list-item
                                                    [ngClass]="(appPermissions[7]?.Access==0 || isWIPData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                                    *ngIf="isInvoice != 'invoice' && activeSubMenu =='work-in-progress'"
                                                    (click)="ReCalcTimeEntriClick()">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_edit_d.ico">
                                                    </mat-icon>
                                                    <span>Re Calc Time Entries</span>
                                                </a>
                                                <span
                                                    *ngIf="DisEnTimeEntryToolbar == 'write_off'">
                                                    <a mat-list-item
                                                        [ngClass]="(appPermissions[2]?.Edit==0 || isWIPData == 'empty' || WorkInProgressData.INVOICEGUID != '') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                                        (click)="WriteOffTimeEntry('WIP')">
                                                        <mat-icon>
                                                            <img
                                                                src="assets/icons/web_app/icon_time_cost_delete_d.ico">
                                                        </mat-icon>
                                                        <span>Write off Work in
                                                            Progress</span>
                                                    </a>
                                                </span>
                                                <span
                                                    *ngIf="DisEnTimeEntryToolbar == 'undo'">
                                                    <a mat-list-item
                                                        [ngClass]="(appPermissions[7]?.Create==0 || isWIPData == 'empty')  ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                                        (click)="UndoWriteOffTimeEntry('WIP')">
                                                        <mat-icon>
                                                            <img
                                                                src="assets/icons/web_app/icon_time_cost_delete_d.ico">
                                                        </mat-icon>
                                                        <span>Undo - Write off
                                                            Work in Progress</span>
                                                    </a>
                                                </span>

                                                <a mat-list-item
                                                    [ngClass]="(appPermissions[7]?.Access==0 || isWIPData == 'empty')?'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                                    *ngIf="isInvoice != 'invoice' && activeSubMenu =='work-in-progress'"
                                                    (click)="searchAndReplaceWorkItemOnClick()">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_edit_d.ico">
                                                    </mat-icon>
                                                    <span>Find/Replace</span>
                                                </a>
                                            </mat-nav-list>
                                        </section>

                                        <section class="ribbon-section">
                                            <mat-nav-list>
                                                <a mat-list-item
                                                    [ngClass]="(appPermissions[7]?.Access==0 || isWIPData == 'empty' || WorkInProgressData.INVOICEGUID != '' || WorkInProgressData.INVOICEGUID == -1) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                                    *ngIf="isInvoice != 'invoice' && activeSubMenu =='work-in-progress'"
                                                    (click)="moveWIP()">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_edit_d.ico">
                                                    </mat-icon>
                                                    <span>Move Entry</span>
                                                </a>
                                                <!-- <a mat-list-item   [ngClass]="(appPermissions[7]?.Access==0 || isWIPData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'" *ngIf="isInvoice != 'invoice' && activeSubMenu =='work-in-progress' && currentUser?.PRODUCTTYPE !='Barrister'"  (click)="splittimeentry('Split')">
                                                <mat-icon>
                                                    <img src="assets/icons/web_app/icon_time_cost_edit_d.ico">
                                                </mat-icon>
                                                <span>Split Time Entry</span>
                                            </a> -->

                                                <a mat-list-item
                                                    [ngClass]="(appPermissions[2]?.Access==0 || appPermissions[2]?.Create==0  || appPermissions[2]?.Edit==0|| isWIPData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                                    *ngIf="isInvoice != 'invoice' && activeSubMenu =='work-in-progress'"
                                                    (click)="(splitAccessTier.ALLOWED !==1)? goTo(splitAccessTier.URL):splittimeentry('Split')"
                                                    (click)="(splitAccessTier.ALLOWED !==1)? false:true"
                                                    [matTooltip]="('Split Time Entries' | tierPermission : 'Split Time Entries').ALLOWED !== 1?('Split Time Entries' | tierPermission : 'Split Time Entries').DESCRIPTION :''"
                                                    matTooltipPosition="below">
                                                    <mat-icon>
                                                        <img
                                                            [src]="('Split Time Entries' | tierPermission : 'Split Time Entries').ALLOWED == 1?'assets/icons/web_app/icon_time_cost_edit_d.ico':'assets/icons/web_app/Feature_is_Locked.ico'">
                                                    </mat-icon>
                                                    <span>Split Time Entry</span>
                                                </a>
                                            </mat-nav-list>
                                        </section>
                                        <div class="light_label_txt">Extras
                                        </div>
                                    </section>
                                    <section
                                        class="group-section-tab action_tab"
                                        *ngIf="activeSubMenu=='matter-invoices'">
                                        <section
                                            [ngClass]="(appPermissions[7]?.Access==0 || isMatterInvoiceData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[7]?.Access==0 || isMatterInvoiceData == 'empty') ? 'disabled-click-cursor large-button' : 'large-button'"
                                                (click)="InvoiceDetail('isTime');">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_invoice_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>View<br>Invoice</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[7]?.Delete==0 || isMatterInvoiceData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[7]?.Delete==0 || isMatterInvoiceData == 'empty')? 'disabled-click-cursor large-button' : 'large-button'"
                                                (click)="deleteInvoice('isTime');">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_invoice_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete<br> Invoice</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[8]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[8]?.Create==0 ? 'disabled-click-cursor large-button' : 'large-button'"
                                                (click)="createReceiptForTimeBilling()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_receipt_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New<br> Receipt</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[7]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[7]?.Create==0 ? 'disabled-click-cursor large-button' : 'large-button'"
                                                (click)="toolbarServiceService.createInstantInvoice(false)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_invoice_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Instant <br>
                                                            Invoice</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Action</div>
                                    </section>
                                    <mat-divider
                                        *ngIf="activeSubMenu=='matter-invoices'"
                                        vertical></mat-divider>
                                    <section
                                        *ngIf="activeSubMenu=='matter-invoices'"
                                        class="group-section-tab action_tab">
                                        <section
                                            [ngClass]="(appPermissions[5]?.Access==0 || isMatterInvoiceData == 'empty')  ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button
                                                [disabled]="ShowGenerateInvoice =='no'"
                                                (click)="GenerateInvoice()"
                                                mat-button
                                                [ngClass]="(appPermissions[5]?.Access==0 || isMatterInvoiceData == 'empty')  ? 'disabled-click large-button' : 'large-button'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_print_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Generate <br>
                                                            Invoice</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[5]?.Access==0 || isMatterInvoiceData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'" *ngIf="('Document Generation' | tierPermission : 'Document Generation') && ('Document Generation' | tierPermission : 'Document Generation').ALLOWED == 1">
                                            <!-- <button mat-button [ngClass]="(appPermissions[5]?.Access==0 || isMatterInvoiceData == 'empty') ? 'disabled-click large-button' : 'large-button'" (click)="setTab($event)" [routerLink]="'/create-document/invoice-template'">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_letters_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Generate <br> Document</span>
                                                </div>
                                            </section>
                                        </button> -->

                                            <button mat-button
                                                [ngClass]="(appPermissions[5]?.Access==0 || isMatterInvoiceData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.generateDocumentsFromMatters($event);SendEmail(false)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_letters_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Generate <br>
                                                            Documents</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Details</div>
                                    </section>
                                    <mat-divider
                                        *ngIf="activeSubMenu=='matter-invoices'"
                                        vertical></mat-divider>
                                    <section
                                        *ngIf="activeSubMenu=='matter-invoices'"
                                        class="group-section-tab action_tab">
                                        <section
                                            [ngClass]="(appPermissions[7]['Write Off']==0 || isMatterInvoiceData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button (click)="InvoiceWriteoff()"
                                                mat-button
                                                [ngClass]="(appPermissions[7]['Write Off']==0 || isMatterInvoiceData == 'empty') ? 'disabled-click large-button' : 'large-button'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_invoice_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Write off<br>Invoice</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <!-- <section [ngClass]="(appPermissions[5]?.Access==0 || isMatterInvoiceData == 'empty' || isforCredits== 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button (click)="CheckForCredits()" mat-button [ngClass]="(appPermissions[5]?.Access==0 || isMatterInvoiceData == 'empty'  || isforCredits== 'empty') ? 'disabled-click large-button' : 'large-button'">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_receipt_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Check for<br>Credits</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section> -->

                                        <!-- new changes are done by karan -->
                                        <section>
                                            <button (click)="CheckForCredits()"
                                                mat-button Class="large-button">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_receipt_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Check for<br>Credits</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <!-- new changes are done by karan -->

                                        <section class="ribbon-section">
                                            <mat-nav-list>
                                                <a mat-list-item *ngIf="('Interest Calculations' | tierPermission : 'Interest Calculations') && ('Interest Calculations' | tierPermission : 'Interest Calculations').ALLOWED ==1"
                                                    [ngClass]="(appPermissions[5]?.Access==0 || isMatterInvoiceData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                                    (click)="CalculateInterest()">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/percent.ico">
                                                    </mat-icon>
                                                    <span>Calculate Interest</span>
                                                </a>
                                            </mat-nav-list>
                                        </section>
                                        <div class="light_label_txt">Extras</div>
                                    </section>
                                    <mat-divider
                                        *ngIf="activeSubMenu=='matter-invoices'"
                                        vertical></mat-divider>
                                        
                                        <!-- NEW -->
                                    <section
                                        *ngIf="activeSubMenu=='receipts-credits' && ('Document Generation' | tierPermission : 'Document Generation') && ('Document Generation' | tierPermission : 'Document Generation').ALLOWED == 1"
                                        class="group-section-tab action_tab">
                                        <section *ngIf="('Document Generation' | tierPermission : 'Document Generation') && ('Document Generation' | tierPermission : 'Document Generation').ALLOWED == 1"
                                            [ngClass]="(appPermissions[5]?.Access==0 || isReceiptData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button
                                                [disabled]="DisabledReceiptTool=='disabled'"
                                                (click)="GenerateReceipt()"
                                                mat-button
                                                [ngClass]="(appPermissions[5]?.Access==0 || isReceiptData =='empty') ?  'disabled-click large-button' : 'large-button'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_print_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Generate <br>
                                                            Receipt</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[5]?.Access==0 || isReceiptData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'" *ngIf="('Document Generation' | tierPermission : 'Document Generation') && ('Document Generation' | tierPermission : 'Document Generation').ALLOWED == 1">
                                            <!-- <button mat-button [ngClass]="(appPermissions[5]?.Access==0 || isReceiptData =='empty')  ? 'disabled-click large-button' : 'large-button'" (click)="setTab($event)" [routerLink]="'/create-document'">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_letters_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Generate <br> Document</span>
                                                </div>
                                            </section>
                                        </button> -->
                                            <button mat-button
                                                [ngClass]="(appPermissions[5]?.Access==0 || isReceiptData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.generateDocumentsFromMatters($event);SendEmail(false)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_letters_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Generate <br>
                                                            Documents</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Details</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                </mat-card-content>
                            </mat-card>
                            <mat-progress-bar mode="buffer"
                                *ngIf="isGenrateReport"></mat-progress-bar>
                        </section>
                    </mat-tab>
                    <!-- Time and Billing tab menu end -->
                    <!-- legal tab menu start -->
                    <!-- <mat-tab label="LEGAL DETAILS" *ngIf="isTabShow===4 && !matterDetailsFlag">
                    <section class="ribbon-container legal_details_tab_main" [style.width]="detailwidth"
                      >
                        <mat-card>
                            <mat-card-content>
                                <section class="group-section-tab details_tab">
                                    <section class="ribbon-section">
                                        <button mat-button class="large-button"
                                            (click)="setTab($event);targetNewTab('/time-billing','TIMEANDBILLING')">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_invoice_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Time &<br> Billing</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section
                                        [ngClass]="appPermissions[6]?.Access==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="appPermissions[6]?.Access==0 ? 'disabled-click large-button' : 'large-button'"
                                            (click)="setTab($event);targetNewTab('/document-register','DOCUMENTREGISTER')">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_doc_reg_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Document <br>Register</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <div class="light_label_txt">Go To</div>
                                </section>
                                <mat-divider vertical></mat-divider>
                                <section class="group-section-tab extras_tab">
                                    <section class="ribbon-section">
                                        <mat-nav-list>
                                            <a mat-list-item [routerLink]="'/legal-details/chronology'"
                                                [ngClass]="{'disabled-click-cursor':appPermissions[10]?.Access==0,'cmn_active_tab':activeSubMenu =='chronology'}">
                                                <mat-icon>
                                                    <img src="assets/icons/web_app/icon_chronology_small_d.ico">
                                                </mat-icon>
                                                <span>Chronology</span>
                                            </a>
                                            <a mat-list-item [routerLink]="'/legal-details/authority'"
                                                [ngClass]="{'disabled-click-cursor':appPermissions[12]?.Access==0,'cmn_active_tab':activeSubMenu =='authority'}">
                                                <mat-icon>
                                                    <img src="assets/icons/web_app/icon_authorities_d.ico">
                                                </mat-icon>
                                                <span>Authorities</span>
                                            </a>
                                            <a mat-list-item
                                                [ngClass]="{'disabled-click-cursor':appPermissions[18]?.Access==0,'cmn_active_tab':activeSubMenu =='legal-detail-task'}"
                                                [routerLink]="'/legal-details/legal-detail-task'">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_tasks_d.ico">
                                                </mat-icon>
                                                <span> Tasks </span>
                                            </a>
                                        </mat-nav-list>
                                    </section>
                                    <section class="ribbon-section">
                                        <mat-nav-list>
                                            <a mat-list-item [routerLink]="'/legal-details/file-notes'"
                                                [ngClass]="{'disabled-click-cursor':appPermissions[13]?.Access==0,'cmn_active_tab':activeSubMenu =='file-notes'}">
                                                <mat-icon>
                                                    <img src="assets/icons/web_app/icon_filesnotes_small_d.ico">
                                                </mat-icon>
                                                <span>File Notes</span>
                                            </a>
                                            <a mat-list-item *ngIf="'' | productType"
                                                [ngClass]="{'disabled-click-cursor':appPermissions[14]?.Access==0,'cmn_active_tab':activeSubMenu =='safe-custody'}"
                                                [routerLink]="'/legal-details/safe-custody'">
                                                <mat-icon>
                                                    <img src="assets/icons/web_app/icon_safecustody_d.ico">
                                                </mat-icon>
                                                <span>Safe Custody</span>
                                            </a>
                                            <a mat-list-item *ngIf="currentUser?.PRODUCTTYPE!='Barrister'"
                                                [ngClass]="{'disabled-click-cursor':appPermissions[16]?.Access==0,'cmn_active_tab':activeSubMenu =='search'}"
                                                [routerLink]="'/legal-details/search'">
                                                <mat-icon>
                                                    <img src="assets/icons/web_app/icon_search_d.ico">
                                                </mat-icon>
                                                <span>Searching</span>
                                            </a>
                                            <mat-divider vertical></mat-divider>

                                        </mat-nav-list>
                                    </section>
                                    <section class="ribbon-section" *ngIf="currentUser?.PRODUCTTYPE!='Barrister'">
                                        <mat-nav-list>
                                            <a *ngIf="appPermissions[10]?.Access==1" mat-list-item
                                                [routerLink]="'/legal-details/settlement'"
                                                [ngClass]="{'disabled-click-cursor':(appPermissions[10]?.Access==0 || isProperty),'cmn_active_tab':activeSubMenu =='settlement'}">
                                                <mat-icon>
                                                    <img src="assets/icons/web_app/icon_invoice_d.ico">
                                                </mat-icon>
                                                <span>Settlement</span>
                                            </a>
                                            <a mat-list-item [routerLink]="'/legal-details/estate'"
                                                [ngClass]="{'disabled-click-cursor':appPermissions[10]?.Access==0,'cmn_active_tab':activeSubMenu =='estate'}">
                                                <mat-icon>
                                                    <img src="assets/icons/web_app/estate.ico">
                                                </mat-icon>
                                                <span>Estate</span>
                                            </a>
                                        </mat-nav-list>
                                    </section>
                                    <div class="light_label_txt">View</div>
                                </section>
                                <mat-divider vertical></mat-divider>
                                <section class="group-section-tab details_tab" *ngIf="activeSubMenu =='settlement'">
                                    <section
                                        [ngClass]="appPermissions[10]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="appPermissions[10]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                            (click)="AllowancePopup('new')">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_invoice_new_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>New <br>Allowance</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section
                                        [ngClass]="(appPermissions[10]?.Edit==0 || selectedAllowances =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="(appPermissions[10]?.Edit==0 || selectedAllowances =='empty') ? 'disabled-click large-button' : 'large-button'"
                                            (click)="AllowancePopup('edit')">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_invoice_edit_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Edit<br>Allowance</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section
                                        [ngClass]="(appPermissions[10]?.Delete==0 || selectedAllowances =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="(appPermissions[10]?.Delete==0 || selectedAllowances =='empty') ? 'disabled-click large-button' : 'large-button'"
                                            (click)="DeleteAllowance()">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_invoice_delete_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Delete <br>Allowance</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>

                                    <div class="light_label_txt">Action</div>
                                </section>

                                <mat-divider vertical *ngIf="activeSubMenu =='settlement'"></mat-divider>

                                <section class="group-section-tab details_tab" *ngIf="activeSubMenu =='settlement'">
                                    <section
                                        [ngClass]="appPermissions[10]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="appPermissions[10]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                            (click)="ChequePopup('new')">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_expenses_new_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>New <br>Cheque</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section
                                        [ngClass]="(appPermissions[10]?.Edit==0 || selectedChequeStatment=='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="(appPermissions[10]?.Edit==0 || selectedChequeStatment=='empty') ? 'disabled-click large-button' : 'large-button'"
                                            (click)="ChequePopup('edit')">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_expenses_edit_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Edit<br>Cheque</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section
                                        [ngClass]="(appPermissions[10]?.Delete==0 || selectedChequeStatment=='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="(appPermissions[10]?.Delete==0 || selectedChequeStatment=='empty') ? 'disabled-click large-button' : 'large-button'"
                                            (click)="DeleteCheque()">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_expenses_delete_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Delete <br>Cheque</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section
                                        [ngClass]="(appPermissions[10]?.Delete==0 ) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button (click)="RecalcAmounts()"
                                            [ngClass]="(appPermissions[10]?.Delete==0 ) ? 'disabled-click large-button' : 'large-button'">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_invoice_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Recalc <br>Amounts</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section
                                        [ngClass]="(appPermissions[10]?.Delete==0) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button (click)="saveSettlement()"
                                            [ngClass]="(appPermissions[10]?.Delete==0) ? 'disabled-click large-button' : 'large-button'">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_save_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Save</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>

                                    <div class="light_label_txt">Action</div>
                                </section>

                                <mat-divider vertical *ngIf="activeSubMenu =='settlement'"></mat-divider>

                                <section class="group-section-tab details_tab" *ngIf="activeSubMenu =='settlement'">
                                    <section
                                        [ngClass]="appPermissions[10]?.Acess==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="appPermissions[12]?.Acess==0 ?  'disabled-click large-button' : 'large-button'"
                                            (click)="toolbarServiceService.generateDocumentsFromMatters($event)">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_letters_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Generate <br> Documents</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>

                                    <div class="light_label_txt">Details</div>
                                </section>

                                <section class="group-section-tab details_tab" *ngIf="activeSubMenu =='estate'">
                                    <section
                                        [ngClass]="appPermissions[10]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="appPermissions[10]?.Create==0 ? 'disabled-click large-button' : 'large-button'">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_reports_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Extras</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>

                                    <div class="light_label_txt">Extras</div>
                                </section>
                                <mat-divider vertical *ngIf="activeSubMenu =='estate'"></mat-divider>

                                <section class="group-section-tab details_tab" *ngIf="activeSubMenu =='estate'">
                                    <mat-nav-list>
                                        <a mat-list-item
                                            [ngClass]="appPermissions[10]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                            (click)="estateAssets('new')">
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/icon_add_d.ico">
                                            </mat-icon>
                                            <span>New Asset</span>
                                        </a>

                                        <a mat-list-item
                                            [ngClass]="appPermissions[10]?.Edit==0 || (appPermissions[10]?.Edit==1 && editAssest)? 'disabled-click large-button' : 'large-button'"
                                            (click)="estateAssets('edit')">
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/icon_edit_d.ico">
                                            </mat-icon>
                                            <span>Edit Asset</span>

                                        </a>
                                        <a mat-list-item
                                            [ngClass]="appPermissions[10]?.Delete==0 || (appPermissions[10]?.Delete==1 && editAssest)? 'disabled-click large-button' : 'large-button'"
                                            (click)="estateAssets('delete')">
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/icon_delete_d.ico">
                                            </mat-icon>
                                            <span>Delete Asset</span>
                                        </a>
                                    </mat-nav-list>

                                    <div class="light_label_txt">Assets</div>
                                </section>
                                <mat-divider vertical *ngIf="activeSubMenu =='estate'"></mat-divider>

                                <section class="group-section-tab details_tab" *ngIf="activeSubMenu =='estate'">
                                    <mat-nav-list>
                                        <a mat-list-item
                                            [ngClass]="appPermissions[10]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                            (click)="estateLiability('new')">
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/icon_add_d.ico">
                                            </mat-icon>
                                            <span>New Liability</span>
                                        </a>
                                        <a mat-list-item
                                            [ngClass]="appPermissions[10]?.Edit==0 || (appPermissions[10]?.Edit==1 && editliability)? 'disabled-click large-button' : 'large-button'"
                                            (click)="estateLiability('edit')">
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/icon_edit_d.ico">
                                            </mat-icon>
                                            <span>Edit Liability</span>

                                        </a>
                                        <a mat-list-item
                                            [ngClass]="appPermissions[10]?.Delete==0 || (appPermissions[10]?.Delete==1 && editliability)? 'disabled-click large-button' : 'large-button'"
                                            (click)="estateLiability('delete')">
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/icon_delete_d.ico">
                                            </mat-icon>
                                            <span>Delete Liability</span>
                                        </a>
                                    </mat-nav-list>
                                    <div class="light_label_txt">Liabilities</div>
                                </section>
                                <mat-divider vertical *ngIf="activeSubMenu =='estate'"></mat-divider>

                                <section class="group-section-tab details_tab" *ngIf="activeSubMenu =='estate'">
                                    <mat-nav-list>
                                        <a mat-list-item
                                            [ngClass]="appPermissions[10]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                            (click)="estateBeneficiary('new')">
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/icon_add_d.ico">
                                            </mat-icon>
                                            <span>New Beneficiary</span>
                                        </a>
                                        <a mat-list-item
                                            [ngClass]="appPermissions[10]?.Edit==0 || (appPermissions[10]?.Edit==1 && editbeneficiary)? 'disabled-click large-button' : 'large-button'"
                                            (click)="estateBeneficiary('edit')">
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/icon_edit_d.ico">
                                            </mat-icon>
                                            <span>Edit Beneficiary</span>

                                        </a>
                                        <a mat-list-item
                                            [ngClass]="appPermissions[10]?.Delete==0 || (appPermissions[10]?.Delete==1 && editbeneficiary)? 'disabled-click large-button' : 'large-button'"
                                            (click)="estateBeneficiary('delete')">
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/icon_delete_d.ico">
                                            </mat-icon>
                                            <span>Delete Beneficiary</span>
                                        </a>
                                    </mat-nav-list>
                                    <div class="light_label_txt">Beneficiaries</div>
                                </section>
                                <mat-divider vertical *ngIf="activeSubMenu =='estate'"></mat-divider>


                                <section class="group-section-tab details_tab" *ngIf="activeSubMenu =='estate'">

                                    <section
                                        [ngClass]="appPermissions[10]?.Edit==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="appPermissions[10]?.Edit==0 ? 'disabled-click large-button' : 'large-button'"
                                            (click)="estateDetails()">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/estate.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Update Estate <br />Details </span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>

                                    <div class="light_label_txt">Details</div>
                                </section>




                                <section class="group-section-tab details_tab" *ngIf="activeSubMenu ==='search'">
                                    <section
                                        [ngClass]="(isGenrateReport)? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="(isGenrateReport) ? 'disabled-click large-button' : 'large-button'"
                                            (click)="viewOnlineDocument('matter', (selectedMatterTab && selectedMatterTab.MATTERGUID)? selectedMatterTab.MATTERGUID : null)">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/infotrack.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Start InfoTrack <br> Search</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section
                                        [ngClass]="(isGenrateReport)? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="(isGenrateReport) ? 'disabled-click large-button' : 'large-button'"
                                            (click)="viewOnlineDocument('WebVOI', (selectedMatterTab && selectedMatterTab.MATTERGUID)? selectedMatterTab.MATTERGUID : null)">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/infotrack.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Start <br> WebVOI</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section
                                        [ngClass]="(isGenrateReport)? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="(isGenrateReport) ? 'disabled-click large-button' : 'large-button'"
                                            (click)="viewOnlineDocument('CourtSync', (selectedMatterTab && selectedMatterTab.MATTERGUID)? selectedMatterTab.MATTERGUID : null)">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/infotrack.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Start<br> CourtSync</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section
                                        [ngClass]="(appPermissions[16]?.Access==0 || isSerachingDataShow||isGenrateReport)? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button (click)="viewOnlineDocument('download','')"
                                            [ngClass]="(appPermissions[16]?.Access==0 || isSerachingDataShow||isGenrateReport) ? 'disabled-click large-button' : 'large-button'">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_search_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Open<br>file</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section
                                        [ngClass]="(appPermissions[16]?.Access==0 || isSerachingData?.AVAILABLEONLINE=='No' || isSerachingDataShow ||isGenrateReport)  ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="(appPermissions[16]?.Access==0 || isSerachingData?.AVAILABLEONLINE=='No' || isSerachingDataShow ||isGenrateReport)  ? 'disabled-click large-button' : 'large-button'"
                                            (click)="viewOnlineDocument('online','')">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/infotrack.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>View <br>Online</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <mat-progress-bar mode="buffer" *ngIf="isGenrateReport"></mat-progress-bar>
                                    <div class="light_label_txt">Action</div>
                                </section>
                                <mat-divider vertical></mat-divider>
                                <section class="group-section-tab details_tab" *ngIf="activeSubMenu ==='search'">
                                    <section
                                        [ngClass]="(appPermissions[16]?.Access==0 || isSerachingDataShow ||isGenrateReport)  ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="(appPermissions[16]?.Access==0 || isSerachingDataShow ||isGenrateReport)  ? 'disabled-click large-button' : 'large-button'"
                                            (click)="moveSearchItem()">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_search_edit_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Move Search <br>Item</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <mat-progress-bar mode="buffer" *ngIf="isGenrateReport"></mat-progress-bar>
                                    <div class="light_label_txt">Extra</div>
                                </section>


                                <section class="group-section-tab details_tab" *ngIf="activeSubMenu ==='safe-custody'">
                                    <section
                                        [ngClass]="appPermissions[14]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="appPermissions[14]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                            (click)="OpenNewSafeCustody('newlegal')" (click)="setTab($event)">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_safecustody_new_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>New Safe <br />Custody</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section
                                        [ngClass]="(appPermissions[14]?.Edit==0 || isSafeCustody =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="(appPermissions[14]?.Edit==0 || isSafeCustody =='empty')  ? 'disabled-click large-button' : 'large-button'"
                                            (click)="OpenNewSafeCustody('editlegal')">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img class=""
                                                        src="assets/icons/web_app/icon_safecustody_edit_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Edit Safe <br />Custody</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section
                                        [ngClass]="(appPermissions[14]?.Delete==0 || isSafeCustody =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="(appPermissions[14]?.Delete==0 || isSafeCustody =='empty')  ? 'disabled-click large-button' : 'large-button'"
                                            (click)="DeleteSafeCustody()">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img class=""
                                                        src="assets/icons/web_app/icon_safecustody_delete_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Delete Safe <br />Custody</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>

                                    <div class="light_label_txt">Action</div>
                                </section>

                                <section class="group-section-tab details_tab" *ngIf="activeSubMenu ==='file-notes'">
                                    <section
                                        [ngClass]="appPermissions[13]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="appPermissions[13]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                            (click)="FileNotePopup('new')" (click)="setTab($event)">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_filesnotes_new_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>New <br />Note</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section
                                        [ngClass]="(appPermissions[13]?.Edit==0 || isFileNoteData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="(appPermissions[13]?.Edit==0 || isFileNoteData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                            (click)="FileNotePopup('edit')">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img class="" src="assets/icons/web_app/icon_filesnotes_edit_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Edit <br />Note</span>
                                                </div>
                                            </section>
                                        </button>

                                    </section>
                                    <section
                                        [ngClass]="(appPermissions[13]?.Delete==0 || isFileNoteData =='empty')? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button
                                            [ngClass]="(appPermissions[13]?.Delete==0 || isFileNoteData =='empty')? 'disabled-click large-button' : 'large-button'"
                                            (click)="DeleteFileNotes()" mat-button class="large-button">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img class=""
                                                        src="assets/icons/web_app/icon_filesnotes_delete_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Delete <br />Note</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>

                                    <section
                                        [ngClass]="(appPermissions[13]?.Create==0 || isFileNoteData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button
                                            [ngClass]="(appPermissions[13]?.Create==0 || isFileNoteData =='empty')  ? 'disabled-click large-button' : 'large-button'"
                                            (click)="FileNotePopup('duplicate')" mat-button class="large-button">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img class="" src="assets/icons/web_app/icon_duplicate_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Duplicate <br />Note</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>

                                    <div class="light_label_txt">Action</div>
                                </section>

                                <section class="group-section-tab details_tab"
                                    *ngIf="activeSubMenu ==='legal-detail-task'">
                                    <section
                                        [ngClass]="appPermissions[18]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="appPermissions[18]?.Edit==0 ? 'disabled-click large-button' : 'large-button'"
                                            (click)="TaskDialoge('new legal')" (click)="setTab($event)">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_tasks_new_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>New <br />Task</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section
                                        [ngClass]="(appPermissions[18]?.Edit==0 || isTaskData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="(appPermissions[18]?.Edit==0 || isTaskData =='empty')  ? 'disabled-click large-button' : 'large-button'"
                                            (click)="TaskDialoge('edit legal')" (click)="setTab($event)">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_tasks_edit_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Edit <br />Task</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section
                                        [ngClass]="(appPermissions[18]?.Delete==0 || isTaskData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="(appPermissions[18]?.Delete==0 || isTaskData =='empty')? 'disabled-click large-button' : 'large-button'"
                                            (click)="deleteTask()" (click)="setTab($event)">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_tasks_delete_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Delete <br />Task</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section
                                        [ngClass]="(appPermissions[18]?.Delete==0 || isTaskData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="(appPermissions[18]?.Delete==0 || isTaskData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                            (click)="TaskDialoge('copy legal')" (click)="setTab($event)">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_duplicate_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Duplicate <br />Task</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section
                                        [ngClass]="appPermissions[18]?.Delete==0 ? 'disabled-click-cursor ribbon-section' : 'disabled-click-cursor ribbon-section'">
                                        <button mat-button
                                            [ngClass]="appPermissions[18]?.Acess==0 ? 'disabled-click large-button' : 'disabled-click large-button'"
                                            (click)="setTab($event)">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_tasks_edit_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Mark<br />Complete</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>

                                    <div class="light_label_txt">Action</div>
                                </section>

                                <section class="group-section-tab details_tab" *ngIf="activeSubMenu ==='authority'">
                                    <section
                                        [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button [disabled]="LegalAuthotool !=='addMatterHide'" mat-button
                                            [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click large-button' : 'large-button'"
                                            (click)="AddAuthorityFromLegal('add')" (click)="setTab($event)">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_authorities_new_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Add to <br />Matter</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section
                                        [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click large-button' : 'large-button'"
                                            (click)="AddAuthorityFromLegal('delete')" (click)="setTab($event)">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_authorities_delete_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Remove From<br />Matter</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <div class="light_label_txt">Details</div>
                                </section>
                                <mat-divider *ngIf="activeSubMenu ==='authority'" vertical></mat-divider>
                                <section class="group-section-tab details_tab" *ngIf="activeSubMenu ==='authority'">
                                    <section
                                        [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">

                                        <button mat-button
                                            [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click large-button' : 'large-button'"
                                            (click)="toolbarServiceService.generateDocumentsFromMatters($event)">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_letters_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Generate <br> Documents</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section *ngIf="mainlegalAuthWebUrl != ''"
                                        [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click large-button' : 'large-button'"
                                            (click)="setTab($event)">
                                            <a target="_blank" href="{{mainlegalAuthWebUrl}}" mat-list-item>
                                                <section class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img src="assets/icons/web_app/icon_authorities_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Open Master <br />Site/File</span>
                                                    </div>
                                                </section>
                                            </a>
                                        </button>
                                    </section>
                                    <section *ngIf="mainlegalAuthWebUrl == ''"
                                        [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button disabled mat-button
                                            [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click large-button' : 'large-button'"
                                            (click)="setTab($event)">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_authorities_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Open Master <br />Site/File</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section *ngIf="LegalSubAuthotool !=''"
                                        [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click large-button' : 'large-button'"
                                            (click)="setTab($event)">
                                            <a target="_blank" href="{{LegalSubAuthotool}}" mat-list-item>
                                                <section class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img src="assets/icons/web_app/icon_authorities_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Open Matter<br />Site/File</span>
                                                    </div>
                                                </section>
                                            </a>
                                        </button>
                                    </section>
                                    <section *ngIf="LegalSubAuthotool==''"
                                        [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button disabled mat-button
                                            [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click large-button' : 'large-button'"
                                            (click)="setTab($event)">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_authorities_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Open Matter <br />Site/File</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>

                                    <section
                                        [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button [routerLink]="'/authorities/full-authorities'" mat-button
                                            [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click large-button' : 'large-button'"
                                            (click)="setTab($event)">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_authorities_edit_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Edit Master<br />Authority</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>

                                    <div class="light_label_txt">Details</div>
                                </section>

                                <section class="group-section-tab details_tab" *ngIf="activeSubMenu =='chronology'">
                                    <section
                                        [ngClass]="appPermissions[10]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="appPermissions[10]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                            (click)="ChronPopup('new')">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_chronology_new_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>New Chron<br>Item</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section
                                        [ngClass]="(appPermissions[10]?.Edit==0 || isChronoData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="(appPermissions[10]?.Edit==0 || isChronoData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                            (click)="ChronPopup('edit')">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_chronology_edit_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Edit Chron<br>Item</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section
                                        [ngClass]="(appPermissions[10]?.Delete==0 || isChronoData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="(appPermissions[10]?.Delete==0 || isChronoData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                            (click)="DeleteChron()">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_chronology_delete_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Delete Chron<br>Item</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section
                                        [ngClass]="(appPermissions[10]?.Create==0 || isChronoData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="(appPermissions[10]?.Create==0 || isChronoData =='empty')? 'disabled-click large-button' : 'large-button'"
                                            (click)="ChronPopup('duplicate')">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_duplicate_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Duplicate Chron<br>Item</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <div class="light_label_txt">Action</div>
                                </section>
                                <mat-divider *ngIf="activeSubMenu =='chronology'" vertical></mat-divider>
                                <section class="group-section-tab details_tab" *ngIf="activeSubMenu =='chronology'">
                                    <section
                                        [ngClass]="(isChronoData =='empty' || isGenrateReport) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="(isChronoData =='empty' || isGenrateReport) ? 'disabled-click large-button' : 'large-button'"
                                            (click)="OpenMatter('DOCUMENT','chronology')">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_letters_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Open<br>Document</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section
                                        [ngClass]="(isGenrateReport) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="(isGenrateReport) ?  'disabled-click large-button' : 'large-button'"
                                            (click)="toolbarServiceService.generateDocumentsFromMatters($event)">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_letters_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Generate <br> Documents</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <mat-progress-bar mode="buffer" *ngIf="isGenrateReport"></mat-progress-bar>
                                    <div class="light_label_txt">Details</div>
                                </section>
                                <mat-divider vertical></mat-divider>
                            </mat-card-content>
                        </mat-card>
                    </section>
                </mat-tab> -->

                    <!-- Matter tab menu start -->
                    <mat-tab label="MATTER DETAILS" *ngIf="isTabShow===4">
                        <section class="ribbon-container legal_details_tab_main"
                            [style.width]="detailwidth">
                            <mat-card>
                                <mat-card-content>
                                    <!-- <section class="group-section-tab details_tab">
                                    <section class="ribbon-section">
                                        <button mat-button class="large-button" (click)="setTab($event);targetNewTab('/time-billing','TIMEANDBILLING')">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_invoice_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Time &<br> Billing</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section [ngClass]="appPermissions[6]?.Access==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button [ngClass]="appPermissions[6]?.Access==0 ? 'disabled-click large-button' : 'large-button'" (click)="setTab($event);targetNewTab('/document-register','DOCUMENTREGISTER')">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_doc_reg_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Document <br>Register</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <div class="light_label_txt">Go To</div>
                                </section>
                                <mat-divider vertical></mat-divider> -->

                                    <!-- for appointment/diary -->
                                    <!-- <mat-tab label="DIARY" *ngIf="activeSubMenu == 'matter-appointments' && isTabShow===38"> -->
                                    <section
                                        class="ribbon-container diary_tab_main"
                                        [style.width]="detailwidth"
                                        *ngIf="activeSubMenu =='matter-appointments'">
                                        <mat-card>
                                            <mat-card-content>
                                                <section
                                                    class="group-section-tab details_tab">
                                                    <section
                                                        [ngClass]="appPermissions[17]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                                        <button mat-button
                                                            [ngClass]="appPermissions[17]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                            (click)="DiaryAppointment('new','matter-details-new')">
                                                            <section
                                                                class="large-button-container">
                                                                <mat-icon
                                                                    class="main-icon">
                                                                    <img
                                                                        class=" "
                                                                        src="assets/icons/web_app/icon_diary_new_d.ico">
                                                                </mat-icon>
                                                                <div
                                                                    class="main-text">
                                                                    <span>New
                                                                        <br>
                                                                        Appointment</span>
                                                                </div>
                                                            </section>
                                                        </button>
                                                    </section>
                                                    <section
                                                        [ngClass]="(appPermissions[17]?.Edit==0 || isDiaryData =='empty') ? 'disabled-click-cursor ribbon-section' : ' ribbon-section'">
                                                        <button mat-button
                                                            [ngClass]="(appPermissions[17]?.Edit==0 || isDiaryData =='empty'|| !diaryMatterInfo) ? 'disabled-click large-button' : ' large-button'"
                                                            (click)="DiaryAppointment('edit','matter-details-new')">
                                                            <section
                                                                class="large-button-container">
                                                                <mat-icon
                                                                    class="main-icon">
                                                                    <img
                                                                        class=" "
                                                                        src="assets/icons/web_app/icon_diary_edit_d.ico">
                                                                </mat-icon>
                                                                <div
                                                                    class="main-text">
                                                                    <span>Edit
                                                                        <br>
                                                                        Appointment</span>
                                                                </div>
                                                            </section>
                                                        </button>
                                                    </section>
                                                    <section
                                                        [ngClass]="(appPermissions[17]?.Delete==0 || isDiaryData =='empty') ? 'disabled-click-cursor ribbon-section' : ' ribbon-section'">
                                                        <button mat-button
                                                            [ngClass]="(appPermissions[17]?.Delete==0 || isDiaryData =='empty' || !diaryMatterInfo)? 'disabled-click large-button' : ' large-button'"
                                                            (click)="DeleteAppointment('matter-details-new')">
                                                            <section
                                                                class="large-button-container">
                                                                <mat-icon
                                                                    class="main-icon">
                                                                    <img
                                                                        class=" "
                                                                        src="assets/icons/web_app/icon_diary_delete_d.ico">
                                                                </mat-icon>
                                                                <div
                                                                    class="main-text">
                                                                    <span>Delete
                                                                        <br>
                                                                        Appointment</span>
                                                                </div>
                                                            </section>
                                                        </button>
                                                    </section>
                                                    <div class="light_label_txt">Action</div>
                                                </section>
                                                <mat-divider vertical></mat-divider>

                                            </mat-card-content>
                                        </mat-card>
                                    </section>
                                    <!-- </mat-tab> -->
                                    <!-- ends here ~ for appointment/diary -->

                                    <section
                                        class="group-section-tab extras_tab"
                                        *ngIf="showMatterDetailsMenu">
                                        <section class="ribbon-section">
                                            <mat-nav-list>
                                                <a mat-list-item
                                                    [ngClass]="{'disabled-click-cursor':appPermissions[2]?.Access==0,'cmn_active_tab':activeSubMenu =='dashboard'}"
                                                    class="mat-nav-link"
                                                    matTooltip="Dashboard"
                                                    matTooltipPosition="right">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_reports_d.ico">
                                                    </mat-icon>
                                                    <!-- <span> Dashboard</span> -->
                                                </a>

                                                <a mat-list-item
                                                    [routerLink]="'/matter-details/estimate'"
                                                    [ngClass]="{'disabled-click-cursor':appPermissions[4]?.Access==0,'cmn_active_tab':activeSubMenu =='estimate'}"
                                                    class="mat-nav-link"
                                                    matTooltip="Estimate"
                                                    matTooltipPosition="right">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_estimate_d.ico">
                                                    </mat-icon>
                                                    <!-- <span>Estimate</span> -->
                                                </a>

                                                <a mat-list-item
                                                    [ngClass]="{'disabled-click-cursor':appPermissions[8]?.Access==0,'cmn_active_tab':activeSubMenu =='matter-appointments'}"
                                                    class="mat-nav-link"
                                                    matTooltip="Appointments"
                                                    matTooltipPosition="right"
                                                    [routerLink]="'/matter-details/matter-appointments'">
                                                    <mat-icon
                                                        class="mat-icon-matter-details">
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_d.ico">
                                                    </mat-icon>
                                                    <!-- <span>Appointments</span> -->
                                                </a>

                                            </mat-nav-list>
                                        </section>

                                        <section class="ribbon-section">
                                            <mat-nav-list>

                                                <a mat-list-item
                                                    [routerLink]="'/matter-details/file-notes'"
                                                    [ngClass]="{'disabled-click-cursor':appPermissions[13]?.Access==0,'cmn_active_tab':activeSubMenu =='file-notes'}"
                                                    class="mat-nav-link"
                                                    matTooltip="File Notes"
                                                    matTooltipPosition="right">
                                                    <mat-icon
                                                        class="mat-icon-matter-details">
                                                        <img
                                                            src="assets/icons/web_app/icon_filesnotes_small_d.ico">
                                                    </mat-icon>
                                                    <!-- <span>File Notes</span> -->
                                                </a>

                                                <a mat-list-item
                                                    [routerLink]="'/matter-details/deliverables'"
                                                    *ngIf=" DerivableDropShow==false && currentUser?.PRODUCTTYPE!='Barrister' && ('Matter Deliverables' | tierPermission : 'Matter Deliverables').ALLOWED ==1"
                                                    [ngClass]="{'cmn_active_tab':activeSubMenu == 'deliverables'}"
                                                    class="mat-nav-link"
                                                    matTooltip="Deliverables"
                                                    matTooltipPosition="right">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/Deliverablesr.png">
                                                    </mat-icon>
                                                    <!-- <span>Deliverables</span> -->
                                                </a>

                                                <a mat-list-item
                                                    [ngClass]="{'disabled-click-cursor':appPermissions[18]?.Access==0,'cmn_active_tab':activeSubMenu =='legal-detail-task'}"
                                                    class="mat-nav-link"
                                                    matTooltip="Tasks"
                                                    matTooltipPosition="right"
                                                    [routerLink]="'/matter-details/legal-detail-task'">
                                                    <mat-icon
                                                        class="main-icon mat-icon-matter-details">
                                                        <img
                                                            src="assets/icons/web_app/icon_tasks_d.ico">
                                                    </mat-icon>
                                                    <!-- <span> Tasks </span> -->
                                                </a>

                                            </mat-nav-list>
                                        </section>

                                        <section class="ribbon-section">
                                            <mat-nav-list>

                                                <!-- [routerLink]="'/matter-details/matter-invoices'" -->
                                                <a mat-list-item
                                                    [routerLink]="'/matter-details/document-register'"
                                                    [ngClass]="{'disabled-click-cursor':appPermissions[7]?.Access==0,'cmn_active_tab':activeSubMenu =='Doc Register'}"
                                                    class="mat-nav-link"
                                                    matTooltip="Documents"
                                                    matTooltipPosition="right">
                                                    <mat-icon
                                                        class="mat-icon-matter-details">
                                                        <img
                                                            src="assets/icons/web_app/icon_doc_reg_d.ico">
                                                    </mat-icon>
                                                    <!-- <span>Documents </span> -->
                                                </a>

                                                <a mat-list-item
                                                    [routerLink]="'/matter-details/work-in-progress'"
                                                    [ngClass]="{'disabled-click-cursor':appPermissions[2]?.Access==0,'cmn_active_tab':activeSubMenu =='work-in-progress'}"
                                                    class="mat-nav-link"
                                                    matTooltip="Work in Progress"
                                                    matTooltipPosition="right">
                                                    <mat-icon
                                                        class="mat-icon-matter-details">
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_d.ico">
                                                    </mat-icon>
                                                    <!-- <span>Work in Progress </span> -->
                                                </a>

                                                <a
                                                    *ngIf="appPermissions[10]?.Access==1"
                                                    mat-list-item
                                                    [routerLink]="'/matter-details/settlement'"
                                                    [ngClass]="{'disabled-click-cursor':(appPermissions[10]?.Access==0 || isProperty),'cmn_active_tab':activeSubMenu =='settlement'}"
                                                    class="mat-nav-link"
                                                    matTooltip="Settlement"
                                                    matTooltipPosition="right">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_invoice_d.ico">
                                                    </mat-icon>
                                                    <!-- <span>Settlement</span> -->
                                                </a>

                                            </mat-nav-list>
                                        </section>

                                        <section class="ribbon-section"
                                            *ngIf="currentUser?.PRODUCTTYPE!='Barrister'">
                                            <mat-nav-list>

                                                <a mat-list-item
                                                    *ngIf=" DerivableDropShow==false && currentUser?.PRODUCTTYPE!='Barrister' && ('Matter Deliverables' | tierPermission : 'Matter Deliverables').ALLOWED ==1"
                                                    [ngClass]="{'cmn_active_tab':activeSubMenu == 'Timeline'}"
                                                    class="mat-nav-link"
                                                    matTooltip="Timeline"
                                                    matTooltipPosition="right">
                                                    <!-- <mat-icon>
                                                <img src="assets/icons/web_app/Deliverablesr.png">
                                            </mat-icon> -->
                                                    <mat-icon>timeline</mat-icon>
                                                    <!-- <span>Timeline</span> -->
                                                </a>

                                                <a mat-list-item
                                                    [ngClass]="{'disabled-click-cursor':appPermissions[7]?.Access==0,'cmn_active_tab':activeSubMenu =='matter-invoices'}"
                                                    class="mat-nav-link"
                                                    matTooltip="Invoices"
                                                    matTooltipPosition="right"
                                                    [routerLink]="'/matter-details/matter-invoices'">
                                                    <mat-icon
                                                        class="mat-icon-matter-details">
                                                        <img
                                                            src="assets/icons/web_app/icon_invoice_d.ico">
                                                    </mat-icon>
                                                    <!-- <span>Invoices</span> -->
                                                </a>

                                                <a mat-list-item
                                                    [routerLink]="'/matter-details/estate'"
                                                    [ngClass]="{'disabled-click-cursor':appPermissions[10]?.Access==0,'cmn_active_tab':activeSubMenu =='estate'}"
                                                    class="mat-nav-link"
                                                    matTooltip="Estate"
                                                    matTooltipPosition="right">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/estate.ico">
                                                    </mat-icon>
                                                    <!-- <span>Estate</span> -->
                                                </a>

                                            </mat-nav-list>
                                        </section>

                                        <section class="ribbon-section">
                                            <mat-nav-list>

                                                <a mat-list-item
                                                    *ngIf="currentUser?.PRODUCTTYPE!='Barrister'"
                                                    [ngClass]="{'disabled-click-cursor':appPermissions[16]?.Access==0,'cmn_active_tab':activeSubMenu =='search'}"
                                                    [routerLink]="'/matter-details/search'"
                                                    class="mat-nav-link"
                                                    matTooltip="Searching"
                                                    matTooltipPosition="right">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_search_d.ico">
                                                    </mat-icon>
                                                    <!-- <span>Searching</span> -->
                                                </a>

                                                <a mat-list-item
                                                    [ngClass]="{'disabled-click-cursor':appPermissions[8]?.Access==0,'cmn_active_tab':activeSubMenu =='receipts-credits'}"
                                                    class="mat-nav-link"
                                                    matTooltip="Receipts & Credits"
                                                    matTooltipPosition="right"
                                                    [routerLink]="'/matter-details/receipts-credits'">
                                                    <mat-icon
                                                        class="mat-icon-matter-details">
                                                        <img
                                                            src="assets/icons/web_app/icon_receipt_d.ico">
                                                    </mat-icon>
                                                    <!-- <span>Receipts & Credits</span> -->
                                                </a>

                                                <a mat-list-item
                                                    [routerLink]="'/matter-details/chronology'"
                                                    [ngClass]="{'disabled-click-cursor':appPermissions[10]?.Access==0,'cmn_active_tab':activeSubMenu =='chronology'}"
                                                    class="mat-nav-link"
                                                    matTooltip="Chronology"
                                                    matTooltipPosition="right">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_chronology_small_d.ico">
                                                    </mat-icon>
                                                    <!-- <span>Chronology</span> -->
                                                </a>

                                            </mat-nav-list>
                                        </section>

                                        <section class="ribbon-section">
                                            <mat-nav-list>

                                                <a mat-list-item
                                                    *ngIf="'' | productType"
                                                    [ngClass]="{'disabled-click-cursor':appPermissions[14]?.Access==0,'cmn_active_tab':activeSubMenu =='safe-custody'}"
                                                    [routerLink]="'/matter-details/safe-custody'"
                                                    class="mat-nav-link"
                                                    matTooltip="Safe Custody"
                                                    matTooltipPosition="right">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_safecustody_d.ico">
                                                    </mat-icon>
                                                    <!-- <span>Safe Custody</span> -->
                                                </a>
                                                <ng-container
                                                    *ngIf="appPermissions && appPermissions[22]">
                                                    <a mat-list-item
                                                        *ngIf="currentUser?.PRODUCTTYPE!='Barrister'"
                                                        [ngClass]="{'disabled-click-cursor':appPermissions[22]['Trust Matter Access']==0,'cmn_active_tab':activeSubMenu =='matter-trust'}"
                                                        [routerLink]="'/matter-details/matter-trust'"
                                                        class="mat-nav-link"
                                                        matTooltip="Trust"
                                                        matTooltipPosition="right">
                                                        <mat-icon
                                                            class="mat-icon-matter-details">
                                                            <img
                                                                src="assets/icons/web_app/icon_trust_d.ico">
                                                        </mat-icon>
                                                        <!-- <span>Trust</span> -->
                                                    </a>
                                                </ng-container>

                                                <a mat-list-item
                                                    [routerLink]="'/matter-details/authority'"
                                                    [ngClass]="{'disabled-click-cursor':appPermissions[12]?.Access==0,'cmn_active_tab':activeSubMenu =='authority'}"
                                                    class="mat-nav-link"
                                                    matTooltip="Authorities"
                                                    matTooltipPosition="right">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_authorities_d.ico">
                                                    </mat-icon>
                                                    <!-- <span>Authorities</span> -->
                                                </a>

                                            </mat-nav-list>
                                        </section>
                                        <div class="light_label_txt">View</div>
                                    </section>
                                    <!-- <mat-divider vertical *ngIf="activeSubMenu =='settlement'"></mat-divider> -->

                                    <!-- Matter Details Dashboard  -->
                                    <!-- <section class="group-section-tab extras_tab" *ngIf="activeSubMenu =='matter-detail-dashboard'">
                                    <section
                                        [ngClass]="appPermissions[10]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="appPermissions[10]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                            [routerLink]="'/matter-details/timeline'">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon" style="font-size: 30px;">

                                                    timeline
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Timeline</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                </section> -->
                                    <section
                                        class="group-section-tab extras_tab"
                                        *ngIf="activeSubMenu =='matter-detail-dashboard'">
                                        <!-- <section
                                        [ngClass]="appPermissions[10]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="appPermissions[10]?.Create==0 ? 'disabled-click large-button' : 'large-button'">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_reports_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>DashBoard</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section> -->

                                        <section
                                            [ngClass]="(appPermissions[1]?.Edit==0 || isMatterData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                (click)="toolbarServiceService.matterpopup('edit')"
                                                [ngClass]="(appPermissions[1]?.Edit==0 || isMatterData =='empty') ? 'disabled-click large-button' : 'large-button'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_matter_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Edit<br> Matter</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section
                                            [ngClass]="appPermissions[2] && appPermissions[2]['Create WIP'] == 0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[2] && appPermissions[2]['Create WIP'] == 0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.addNewTimeEntry('Add-New')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New Time <br>
                                                            Entry</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <!-- <section
                                        [ngClass]="appPermissions[2]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            class="large-button"
                                            [ngClass]="{'disabled-click-cursor':appPermissions[13]?.Access==0,'cmn_active_tab':activeSubMenu =='file-notes'}"
                                            [routerLink]="'/matter-details/file-notes'">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_filesnotes_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>File <br> Notes</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section> -->

                                        <section  *ngIf=" ('File Notes' | tierPermission : 'File Notes') && ('File Notes' | tierPermission : 'File Notes').ALLOWED ==1"
                                            [ngClass]="appPermissions[13]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[13]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.FileNotePopup('new')"
                                                (click)="setTab($event)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_filesnotes_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span> New File <br />Note</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section *ngIf="('Tasks' | tierPermission : 'Tasks') && (('Tasks' | tierPermission : 'Tasks').ALLOWED ==1)"
                                            [ngClass]="appPermissions[18]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[18]?.Edit==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="TaskDialoge('new legal')"
                                                (click)="setTab($event)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_tasks_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New <br />Task</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section *ngIf="currentUser?.PRODUCTTYPE!='Barrister' && (('Safe Custody' | tierPermission : 'Safe Custody') && ('Safe Custody' | tierPermission : 'Safe Custody').ALLOWED ==1)"
                                            [ngClass]="appPermissions[14]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[14]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="OpenNewSafeCustody('newlegal')"
                                                (click)="setTab($event)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_safecustody_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New Safe <br />Custody</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section
                                            [ngClass]="(appPermissions[5]?.Access==0 || isMatterData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'" *ngIf="('Document Generation' | tierPermission : 'Document Generation') && ('Document Generation' | tierPermission : 'Document Generation').ALLOWED == 1">
                                            <button mat-button
                                                [ngClass]="(appPermissions[5]?.Access==0 || isMatterData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.generateDocumentsFromMatters($event);SendEmail(false)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_letters_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Generate <br>
                                                            Documents</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section
                                            [ngClass]="(appPermissions[5]?.Access==0 || isMatterData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[5]?.Access==0 || isMatterData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.generateDocumentsFromMatters($event);SendEmail(true)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="/assets/icons/web_app/icon_email_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Send <br>
                                                            Email</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                    </section>
                                    <!-- <div class="light_label_txt">Management</div> -->

                                    <section
                                        class="group-section-tab details_tab"
                                        *ngIf="activeSubMenu =='settlement' && settlementAllowances">
                                        <section
                                            [ngClass]="appPermissions[10]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[10]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="AllowancePopup('new')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_invoice_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New <br>Allowance</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[10]?.Edit==0 || selectedAllowances =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[10]?.Edit==0 || selectedAllowances =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="AllowancePopup('edit')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_invoice_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Edit<br>Allowance</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[10]?.Delete==0 || selectedAllowances =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[10]?.Delete==0 || selectedAllowances =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="DeleteAllowance()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_invoice_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete <br>Allowance</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section
                                            [ngClass]="(appPermissions[10]?.Delete==0 ) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                (click)="RecalcAmounts()"
                                                [ngClass]="(appPermissions[10]?.Delete==0 ) ? 'disabled-click large-button' : 'large-button'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_invoice_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Recalc <br>Amounts</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[10]?.Delete==0) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                (click)="saveSettlement()"
                                                [ngClass]="(appPermissions[10]?.Delete==0) ? 'disabled-click large-button' : 'large-button'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_save_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Save</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <div class="light_label_txt">Action</div>
                                    </section>

                                    <!-- <mat-divider vertical *ngIf="activeSubMenu =='settlement'"></mat-divider> -->

                                    <section
                                        class="group-section-tab details_tab"
                                        *ngIf="activeSubMenu =='settlement'">
                                        <section *ngIf="!settlementAllowances"
                                            [ngClass]="appPermissions[10]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[10]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="ChequePopup('new')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_expenses_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New <br>Cheque</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section *ngIf="!settlementAllowances"
                                            [ngClass]="(appPermissions[10]?.Edit==0 || selectedChequeStatment=='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[10]?.Edit==0 || selectedChequeStatment=='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="ChequePopup('edit')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_expenses_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Edit<br>Cheque</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section *ngIf="!settlementAllowances"
                                            [ngClass]="(appPermissions[10]?.Delete==0 || selectedChequeStatment=='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[10]?.Delete==0 || selectedChequeStatment=='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="DeleteCheque()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_expenses_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete <br>Cheque</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section *ngIf="!settlementAllowances"
                                            [ngClass]="(appPermissions[10]?.Delete==0 ) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                (click)="RecalcAmounts()"
                                                [ngClass]="(appPermissions[10]?.Delete==0 ) ? 'disabled-click large-button' : 'large-button'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_invoice_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Recalc <br>Amounts</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section *ngIf="!settlementAllowances"
                                            [ngClass]="(appPermissions[10]?.Delete==0) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                (click)="saveSettlement()"
                                                [ngClass]="(appPermissions[10]?.Delete==0) ? 'disabled-click large-button' : 'large-button'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_save_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Save</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <div class="light_label_txt"
                                            *ngIf="!settlementAllowances">Action</div>
                                    </section>

                                    <mat-divider vertical
                                        *ngIf="activeSubMenu =='settlement'"></mat-divider>

                                    <section
                                        class="group-section-tab details_tab"
                                        *ngIf="activeSubMenu =='settlement' && (('Document Generation' | tierPermission : 'Document Generation') && ('Document Generation' | tierPermission : 'Document Generation').ALLOWED == 1)">
                                        <section
                                            [ngClass]="appPermissions[10]?.Acess==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <!-- <button [routerLink]="'/create-document/matter-template'" mat-button [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click large-button' : 'large-button'" (click)="setTab($event)">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_letters_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Document <br />Templates</span>
                                                </div>
                                            </section>
                                        </button> -->

                                            <button mat-button
                                                [ngClass]="appPermissions[12]?.Acess==0 ?  'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.generateDocumentsFromMatters($event);SendEmail(false)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_letters_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Generate <br>
                                                            Documents</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <div class="light_label_txt">Details</div>
                                    </section>

                                    <!-- For Estate Sub menu -->
                                    <!-- <section class="group-section-tab details_tab" *ngIf="activeSubMenu =='estate'">
                                    <section
                                        [ngClass]="appPermissions[10]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="appPermissions[10]?.Create==0 ? 'disabled-click large-button' : 'large-button'">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_reports_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Extras</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>

                                    <div class="light_label_txt">Extras</div>
                                </section> -->
                                    <!-- <mat-divider vertical *ngIf="activeSubMenu =='estate'"></mat-divider> -->

                                    <section
                                        class="group-section-tab details_tab"
                                        *ngIf="activeSubMenu =='estate' && estate == 0">
                                        <section
                                            [ngClass]="appPermissions[10]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[10]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="estateAssets('new')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_add_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New Asset</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[10]?.Edit==0 || (appPermissions[10]?.Edit==1 && editAssest) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[10]?.Edit==0 || (appPermissions[10]?.Edit==1 && editAssest)? 'disabled-click large-button' : 'large-button'"
                                                (click)="estateAssets('edit')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Edit Asset</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[10]?.Delete==0 || (appPermissions[10]?.Delete==1 && editAssest) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[10]?.Delete==0 || (appPermissions[10]?.Delete==1 && editAssest)? 'disabled-click large-button' : 'large-button'"
                                                (click)="estateAssets('delete')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete Asset</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <!-- <mat-nav-list>
                                        <a mat-list-item
                                            [ngClass]="appPermissions[10]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                            (click)="estateAssets('new')">
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/icon_add_d.ico">
                                            </mat-icon>
                                            <span></span>
                                        </a>

                                        <a mat-list-item
                                            [ngClass]="appPermissions[10]?.Edit==0 || (appPermissions[10]?.Edit==1 && editAssest)? 'disabled-click large-button' : 'large-button'"
                                            (click)="estateAssets('edit')">
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/icon_edit_d.ico">
                                            </mat-icon>
                                            <span>Edit Asset</span>

                                        </a>
                                        <a mat-list-item
                                            [ngClass]="appPermissions[10]?.Delete==0 || (appPermissions[10]?.Delete==1 && editAssest)? 'disabled-click large-button' : 'large-button'"
                                            (click)="estateAssets('delete')">
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/icon_delete_d.ico">
                                            </mat-icon>
                                            <span>Delete Asset</span>
                                        </a>
                                    </mat-nav-list> -->

                                        <div class="light_label_txt">Assets</div>
                                    </section>

                                    <mat-divider vertical
                                        *ngIf="activeSubMenu =='estate' && estate == 0"></mat-divider>

                                    <section
                                        class="group-section-tab details_tab"
                                        *ngIf="activeSubMenu =='estate' && estate == 1">
                                        <section
                                            [ngClass]="appPermissions[10]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[10]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="estateLiability('new')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_add_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New Liability</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[10]?.Edit==0 || (appPermissions[10]?.Edit==1 && editliability) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[10]?.Edit==0 || (appPermissions[10]?.Edit==1 && editliability)? 'disabled-click large-button' : 'large-button'"
                                                (click)="estateLiability('edit')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Edit Liability</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[10]?.Delete==0 || (appPermissions[10]?.Delete==1 && editliability) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[10]?.Delete==0 || (appPermissions[10]?.Delete==1 && editliability)? 'disabled-click large-button' : 'large-button'"
                                                (click)="estateLiability('delete')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete Liability</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <!-- <mat-nav-list>
                                        <a mat-list-item

                                            >
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/icon_add_d.ico">
                                            </mat-icon>
                                            <span></span>
                                        </a>
                                        <a mat-list-item

                                            >
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/icon_edit_d.ico">
                                            </mat-icon>
                                            <span></span>

                                        </a>
                                        <a mat-list-item
                                            [ngClass]="appPermissions[10]?.Delete==0 || (appPermissions[10]?.Delete==1 && editliability)? 'disabled-click large-button' : 'large-button'"
                                            (click)="estateLiability('delete')">
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/icon_delete_d.ico">
                                            </mat-icon>
                                            <span></span>
                                        </a>
                                    </mat-nav-list> -->
                                        <div class="light_label_txt">Liabilities</div>
                                    </section>

                                    <mat-divider vertical
                                        *ngIf="activeSubMenu =='estate' && estate == 1"></mat-divider>

                                    <section
                                        class="group-section-tab details_tab"
                                        *ngIf="activeSubMenu =='estate' && estate == 2">

                                        <section
                                            [ngClass]="appPermissions[10]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[10]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="estateBeneficiary('new')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_add_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New Beneficiary</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[10]?.Edit==0 || (appPermissions[10]?.Edit==1 && editbeneficiary) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[10]?.Edit==0 || (appPermissions[10]?.Edit==1 && editbeneficiary)? 'disabled-click large-button' : 'large-button'"
                                                (click)="estateBeneficiary('edit')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Edit Beneficiary</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[10]?.Delete==0 || (appPermissions[10]?.Delete==1 && editbeneficiary) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[10]?.Delete==0 || (appPermissions[10]?.Delete==1 && editbeneficiary)? 'disabled-click large-button' : 'large-button'"
                                                (click)="estateBeneficiary('delete')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete Beneficiary</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <!-- <mat-nav-list>
                                        <a mat-list-item
                                            [ngClass]="appPermissions[10]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                            (click)="estateBeneficiary('new')">
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/icon_add_d.ico">
                                            </mat-icon>
                                            <span>New Beneficiary</span>
                                        </a>
                                        <a mat-list-item
                                            [ngClass]="appPermissions[10]?.Edit==0 || (appPermissions[10]?.Edit==1 && editbeneficiary)? 'disabled-click large-button' : 'large-button'"
                                            (click)="estateBeneficiary('edit')">
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/icon_edit_d.ico">
                                            </mat-icon>
                                            <span>Edit Beneficiary</span>

                                        </a>
                                        <a mat-list-item
                                            [ngClass]="appPermissions[10]?.Delete==0 || (appPermissions[10]?.Delete==1 && editbeneficiary)? 'disabled-click large-button' : 'large-button'"
                                            (click)="estateBeneficiary('delete')">
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/icon_delete_d.ico">
                                            </mat-icon>
                                            <span>Delete Beneficiary</span>
                                        </a>
                                    </mat-nav-list> -->
                                        <div class="light_label_txt">Beneficiaries</div>
                                    </section>

                                    <mat-divider vertical
                                        *ngIf="activeSubMenu =='estate' && estate == 2"></mat-divider>

                                    <section
                                        class="group-section-tab details_tab"
                                        *ngIf="activeSubMenu =='estate'">

                                        <section
                                            [ngClass]="appPermissions[10]?.Edit==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[10]?.Edit==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="estateDetails()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/estate.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Update Estate <br />Details
                                                        </span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <div class="light_label_txt">Details</div>
                                    </section>

                                    <!-- For  Estate Sub menu End-->
                                    <!-- <mat-divider vertical  *ngIf="activeSubMenu ==='search'"></mat-divider> -->

                                    <section
                                        class="group-section-tab details_tab"
                                        *ngIf="activeSubMenu ==='search'">
                                        <section
                                            [ngClass]="(isGenrateReport)? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(isGenrateReport) ? 'disabled-click large-button' : 'large-button'"
                                                (click)="viewOnlineDocument('matter', (selectedMatterTab && selectedMatterTab.MATTERGUID)? selectedMatterTab.MATTERGUID : null)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/infotrack.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Start InfoTrack
                                                            <br> Search</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(isGenrateReport)? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(isGenrateReport) ? 'disabled-click large-button' : 'large-button'"
                                                (click)="viewOnlineDocument('WebVOI', (selectedMatterTab && selectedMatterTab.MATTERGUID)? selectedMatterTab.MATTERGUID : null)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/infotrack.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Start <br> WebVOI</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(isGenrateReport)? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(isGenrateReport) ? 'disabled-click large-button' : 'large-button'"
                                                (click)="viewOnlineDocument('CourtSync', (selectedMatterTab && selectedMatterTab.MATTERGUID)? selectedMatterTab.MATTERGUID : null)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/infotrack.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Start<br>
                                                            CourtSync</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[16]?.Access==0 || isSerachingDataShow||isGenrateReport)? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                (click)="viewOnlineDocument('download','')"
                                                [ngClass]="(appPermissions[16]?.Access==0 || isSerachingDataShow||isGenrateReport) ? 'disabled-click large-button' : 'large-button'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_search_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Open<br>file</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[16]?.Access==0 || isSerachingData?.AVAILABLEONLINE=='No' || isSerachingDataShow ||isGenrateReport)  ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[16]?.Access==0 || isSerachingData?.AVAILABLEONLINE=='No' || isSerachingDataShow ||isGenrateReport)  ? 'disabled-click large-button' : 'large-button'"
                                                (click)="viewOnlineDocument('online','')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/infotrack.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>View <br>Online</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <mat-progress-bar mode="buffer"
                                            *ngIf="isGenrateReport"></mat-progress-bar>
                                        <div class="light_label_txt">Action</div>
                                    </section>

                                    <mat-divider vertical
                                        *ngIf="activeSubMenu ==='search'"></mat-divider>
                                    <section
                                        class="group-section-tab details_tab"
                                        *ngIf="activeSubMenu ==='search'">
                                        <section
                                            [ngClass]="(appPermissions[16]?.Access==0 || isSerachingDataShow ||isGenrateReport)  ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[16]?.Access==0 || isSerachingDataShow ||isGenrateReport)  ? 'disabled-click large-button' : 'large-button'"
                                                (click)="moveSearchItem()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_search_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Move Search <br>Item</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <mat-progress-bar mode="buffer"
                                            *ngIf="isGenrateReport"></mat-progress-bar>
                                        <div class="light_label_txt">Extra</div>
                                    </section>

                                    <!-- <mat-divider vertical *ngIf="activeSubMenu ==='safe-custody'"></mat-divider> -->
                                    <section
                                        class="group-section-tab details_tab"
                                        *ngIf="activeSubMenu ==='safe-custody'">
                                        <section
                                            [ngClass]="appPermissions[14]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[14]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="OpenNewSafeCustody('newlegal')"
                                                (click)="setTab($event)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_safecustody_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New Safe <br />Custody</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[14]?.Edit==0 || isSafeCustody =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[14]?.Edit==0 || isSafeCustody =='empty')  ? 'disabled-click large-button' : 'large-button'"
                                                (click)="OpenNewSafeCustody('editlegal')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img class
                                                            src="assets/icons/web_app/icon_safecustody_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Edit Safe <br />Custody</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[14]?.Delete==0 || isSafeCustody =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[14]?.Delete==0 || isSafeCustody =='empty')  ? 'disabled-click large-button' : 'large-button'"
                                                (click)="DeleteSafeCustody()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img class
                                                            src="assets/icons/web_app/icon_safecustody_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete Safe <br />Custody</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <div class="light_label_txt">Action</div>
                                    </section>

                                    <!-- <mat-divider vertical *ngIf="activeSubMenu ==='file-notes'"></mat-divider> -->
                                    <section
                                        class="group-section-tab details_tab"
                                        *ngIf="activeSubMenu ==='file-notes'">
                                        <section
                                            [ngClass]="appPermissions[13]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[13]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.FileNotePopup('new')"
                                                (click)="setTab($event)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_filesnotes_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New <br />Note</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[13]?.Edit==0 || isFileNoteData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[13]?.Edit==0 || isFileNoteData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.FileNotePopup('edit')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img class
                                                            src="assets/icons/web_app/icon_filesnotes_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Edit <br />Note</span>
                                                    </div>
                                                </section>
                                            </button>

                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[13]?.Delete==0 || isFileNoteData =='empty')? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button
                                                [ngClass]="(appPermissions[13]?.Delete==0 || isFileNoteData =='empty')? 'disabled-click large-button' : 'large-button'"
                                                (click)="DeleteFileNotes()"
                                                mat-button class="large-button">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img class
                                                            src="assets/icons/web_app/icon_filesnotes_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete <br />Note</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section
                                            [ngClass]="(appPermissions[13]?.Create==0 || isFileNoteData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button
                                                [ngClass]="(appPermissions[13]?.Create==0 || isFileNoteData =='empty')  ? 'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.FileNotePopup('duplicate')"
                                                mat-button class="large-button">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img class
                                                            src="assets/icons/web_app/icon_duplicate_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Duplicate <br />Note</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <div class="light_label_txt">Action</div>
                                    </section>

                                    <!-- <mat-divider vertical *ngIf="activeSubMenu ==='legal-detail-task'"></mat-divider> -->
                                    <section
                                        class="group-section-tab details_tab"
                                        *ngIf="activeSubMenu =='legal-detail-task'">
                                        <section
                                            [ngClass]="appPermissions[18]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[18]?.Edit==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="TaskDialoge('new legal')"
                                                (click)="setTab($event)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_tasks_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New <br />Task</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[18]?.Edit==0 || isTaskData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[18]?.Edit==0 || isTaskData =='empty')  ? 'disabled-click large-button' : 'large-button'"
                                                (click)="TaskDialoge('edit legal')"
                                                (click)="setTab($event)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_tasks_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Edit <br />Task</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[18]?.Delete==0 || isTaskData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[18]?.Delete==0 || isTaskData =='empty')? 'disabled-click large-button' : 'large-button'"
                                                (click)="deleteTask()"
                                                (click)="setTab($event)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_tasks_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete <br />Task</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[18]?.Delete==0 || isTaskData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[18]?.Delete==0 || isTaskData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="TaskDialoge('copy legal')"
                                                (click)="setTab($event)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_duplicate_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Duplicate <br />Task</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[18]?.Delete==0 ? 'disabled-click-cursor ribbon-section' : 'disabled-click-cursor ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[18]?.Acess==0 ? 'disabled-click large-button' : 'disabled-click large-button'"
                                                (click)="setTab($event)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_tasks_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Mark<br />Complete</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <div class="light_label_txt">Action</div>
                                    </section>
                                    <!-- <mat-divider vertical></mat-divider> -->

                                    <!-- <mat-divider vertical *ngIf="activeSubMenu ==='authority'"></mat-divider>  -->

                                    <section
                                        class="group-section-tab details_tab"
                                        *ngIf="activeSubMenu ==='authority'">
                                        <section
                                            [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button
                                                [disabled]="LegalAuthotool !=='addMatterHide'"
                                                mat-button
                                                [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="AddAuthorityFromLegal('add')"
                                                (click)="setTab($event)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_authorities_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Add to <br />Matter</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="AddAuthorityFromLegal('delete')"
                                                (click)="setTab($event)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_authorities_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Remove From<br />Matter</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Details</div>
                                    </section>

                                    <mat-divider
                                        *ngIf="activeSubMenu ==='authority'"
                                        vertical></mat-divider>
                                    <section
                                        class="group-section-tab details_tab"
                                        *ngIf="activeSubMenu ==='authority'">
                                        <section
                                            [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'" *ngIf="(('Document Generation' | tierPermission : 'Document Generation') && ('Document Generation' | tierPermission : 'Document Generation').ALLOWED == 1)">
                                            <!-- <button [routerLink]="'/create-document/matter-template'" mat-button [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click large-button' : 'large-button'" (click)="setTab($event)">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_letters_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Document <br>Templates</span>
                                                </div>
                                            </section>
                                        </button> -->
                                            <button mat-button
                                                [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.generateDocumentsFromMatters($event);SendEmail(false)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_letters_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Generate <br>
                                                            Documents</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            *ngIf="mainlegalAuthWebUrl != ''"
                                            [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="setTab($event)">
                                                <a target="_blank"
                                                    href="{{mainlegalAuthWebUrl}}"
                                                    mat-list-item>
                                                    <section
                                                        class="large-button-container">
                                                        <mat-icon
                                                            class="main-icon">
                                                            <img
                                                                src="assets/icons/web_app/icon_authorities_d.ico">
                                                        </mat-icon>
                                                        <div class="main-text">
                                                            <span>Open Master
                                                                <br />Site/File</span>
                                                        </div>
                                                    </section>
                                                </a>
                                            </button>
                                        </section>
                                        <section
                                            *ngIf="mainlegalAuthWebUrl == ''"
                                            [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button disabled mat-button
                                                [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="setTab($event)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_authorities_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Open Master <br />Site/File</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section *ngIf="LegalSubAuthotool !=''"
                                            [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="setTab($event)">
                                                <a target="_blank"
                                                    href="{{LegalSubAuthotool}}"
                                                    mat-list-item>
                                                    <section
                                                        class="large-button-container">
                                                        <mat-icon
                                                            class="main-icon">
                                                            <img
                                                                src="assets/icons/web_app/icon_authorities_d.ico">
                                                        </mat-icon>
                                                        <div class="main-text">
                                                            <span>Open Matter<br />Site/File</span>
                                                        </div>
                                                    </section>
                                                </a>
                                            </button>
                                        </section>
                                        <section *ngIf="LegalSubAuthotool==''"
                                            [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button disabled mat-button
                                                [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="setTab($event)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_authorities_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Open Matter <br />Site/File</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section
                                            [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button
                                                [routerLink]="'/authorities/full-authorities'"
                                                mat-button
                                                [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="setTab($event)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_authorities_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Edit Master<br />Authority</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <div class="light_label_txt">Details</div>
                                    </section>

                                    <!-- <mat-divider vertical *ngIf="activeSubMenu ==='chronology'"></mat-divider>  -->
                                    <section
                                        class="group-section-tab details_tab"
                                        *ngIf="activeSubMenu =='chronology'">
                                        <section
                                            [ngClass]="appPermissions[10]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[10]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="ChronPopup('new')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_chronology_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New Chron<br>Item</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[10]?.Edit==0 || isChronoData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[10]?.Edit==0 || isChronoData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="ChronPopup('edit')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_chronology_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Edit Chron<br>Item</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[10]?.Delete==0 || isChronoData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[10]?.Delete==0 || isChronoData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="DeleteChron()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_chronology_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete Chron<br>Item</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[10]?.Create==0 || isChronoData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[10]?.Create==0 || isChronoData =='empty')? 'disabled-click large-button' : 'large-button'"
                                                (click)="ChronPopup('duplicate')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_duplicate_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Duplicate Chron<br>Item</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Action</div>
                                    </section>

                                    <mat-divider
                                        *ngIf="activeSubMenu =='chronology'"
                                        vertical></mat-divider>
                                    <section
                                        class="group-section-tab details_tab"
                                        *ngIf="activeSubMenu =='chronology'">
                                        <section
                                            [ngClass]="(isChronoData =='empty' || isGenrateReport) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(isChronoData =='empty' || isGenrateReport) ? 'disabled-click large-button' : 'large-button'"
                                                (click)="OpenMatter('DOCUMENT','chronology')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_letters_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Open<br>Document</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(isGenrateReport) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'" *ngIf="(('Document Generation' | tierPermission : 'Document Generation') && ('Document Generation' | tierPermission : 'Document Generation').ALLOWED == 1)">
                                            <!-- <button mat-button [ngClass]="(isGenrateReport) ? 'disabled-click large-button' : 'large-button'" (click)="isMatterClick()" (click)="setTab($event)" [routerLink]="'/create-document/matter-template'">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_letters_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Document<br>Templates</span>
                                                </div>
                                            </section>
                                        </button> -->

                                            <button mat-button
                                                [ngClass]="(isGenrateReport) ?  'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.generateDocumentsFromMatters($event);SendEmail(false)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_letters_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Generate <br>
                                                            Documents</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <mat-progress-bar mode="buffer"
                                            *ngIf="isGenrateReport"></mat-progress-bar>
                                        <div class="light_label_txt">Details</div>
                                    </section>

                                    <!-- <mat-divider vertical></mat-divider> -->

                                    <!-- ### TIME AND BILLING MERGE -->
                                    <section
                                        class="group-section-tab action_tab"
                                        *ngIf="activeSubMenu=='receipts-credits'">
                                        <section
                                            [ngClass]="appPermissions[8]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[8]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="createReceiptForTimeBilling()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_receipt_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New Matter<br>
                                                            Receipt</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[8]?.Edit==0 || isReceiptData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[8]?.Edit==0 || isReceiptData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="ViewReceiptForTimeBilling()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_receipt_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>View<br> Receipt</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[8]?.Delete==0 || isReceiptData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[8]?.Delete==0 || isReceiptData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="deleteReceiceMoany()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_receipts_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete<br> Receipt</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Action</div>
                                    </section>

                                    <section
                                        class="group-section-tab details_tab"
                                        *ngIf="activeSubMenu =='deliverables' && ('Matter Deliverables' | tierPermission : 'Matter Deliverables').ALLOWED ==1  ">
                                        <section
                                            [ngClass]="appPermissions[32]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[32]?.Create==0 ? 'large-button' : 'large-button'"
                                                (click)="adddeliverable('New')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <!-- <img src="assets/icons/web_app/icon_estimate_new_d.ico"> -->
                                                        <img
                                                            src="assets/icons/web_app/Deliverablesr.png">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New Deliverable</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="isdeliverable =='empty' || appPermissions[32]?.Edit==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="isdeliverable =='empty'  || appPermissions[32]?.Edit==0? 'disabled-click large-button' : 'large-button'"
                                                (click)="adddeliverable('edit')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <!-- <img src="assets/icons/web_app/icon_estimate_edit_d.ico"> -->
                                                        <img
                                                            src="assets/icons/web_app/Deliverablesr.png">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Edit Deliverable</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(isdeliverable =='empty'  || appPermissions[32]?.Edit==0)? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(isdeliverable =='empty' || appPermissions[32]?.Edit==0 )? 'disabled-click large-button' : 'large-button'"
                                                (click)="deletedeliverable()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <!-- <img src="assets/icons/web_app/icon_estimate_delete_d.ico"> -->
                                                        <img
                                                            src="assets/icons/web_app/Deliverablesr.png">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete Deliverable</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="( isdeliverable =='empty' || appPermissions[32]?.Edit==0) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="isdeliverable =='empty'? 'disabled-click large-button' : 'large-button'"
                                                (click)="adddeliverable('Duplicate')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_duplicate_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Duplicate Deliverable</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Action</div>
                                    </section>

                                    <mat-divider vertical
                                        *ngIf="activeSubMenu =='deliverables'"></mat-divider>

                                    <section
                                        class="group-section-tab details_tab"
                                        *ngIf="activeSubMenu =='estimate'">
                                        <section
                                            [ngClass]="appPermissions[4]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[4]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="addNewEstimate('new')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_estimate_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New Est. <br> Item</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[4]?.Edit==0 || isEstimateData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[4]?.Edit==0 || isEstimateData =='empty')? 'disabled-click large-button' : 'large-button'"
                                                (click)="addNewEstimate('edit')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_estimate_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Edit Est. <br>
                                                            Item</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[4]?.Delete==0  || isEstimateData =='empty')? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[4]?.Delete==0  || isEstimateData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="deleteEstimate()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_estimate_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete Est. <br>
                                                            Item</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[4]?.Create==0 || isEstimateData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[4]?.Create==0 || isEstimateData =='empty')? 'disabled-click large-button' : 'large-button'"
                                                (click)="addNewEstimate('duplicate')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_duplicate_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Duplicate Est.<br>Item</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Action</div>
                                    </section>

                                    <section
                                        class="group-section-tab details_tab"
                                        *ngIf="activeSubMenu =='work-in-progress'">
                                        <section
                                            [ngClass]="appPermissions[2] && appPermissions[2]['Create WIP']== 0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[2] && appPermissions[2]['Create WIP']== 0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.addNewTimeEntry('NewTimeEntryMatter')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New Time <br>
                                                            Entry</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <!-- (click)="spendmoneypopup('edit')"  (click)="toolbarServiceService.addNewTimeEntry('Edit')" -->
                                        <section
                                            [ngClass]="(appPermissions[2]?.Edit==0 || isWIPData == 'empty' || WorkInProgressData.INVOICEGUID != '' || WorkInProgressData.ITEMTYPEDESC=='Search') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[2]?.Edit==0 || isWIPData == 'empty' || WorkInProgressData.INVOICEGUID != '' || WorkInProgressData.ITEMTYPEDESC=='Search') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="EditTimeEntryFromWIP()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Edit <br> Entry</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <!-- <section [ngClass]="(appPermissions[2]?.Delete==0 || isWIPData == 'empty' || WorkInProgressData.INVOICEGUID != '' || WorkInProgressData.ITEMTYPEDESC=='Search') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button [ngClass]="(appPermissions[2]?.Delete==0 || isWIPData == 'empty' || WorkInProgressData.INVOICEGUID != '' || WorkInProgressData.ITEMTYPEDESC=='Search') ? 'disabled-click large-button' : 'large-button'" (click)="deleteTimeEntry('timeBilling_delete_timeEntry')">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_time_cost_delete_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Delete <br> Entry</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section> -->
                                        <section
                                            [ngClass]="(appPermissions[2]?.Delete==0 || isWIPData == 'empty' || WorkInProgressData?.INVOICEGUID != '' || WorkInProgressData?.ITEMTYPEDESC=='Search') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[2]?.Delete==0 || isWIPData == 'empty' || WorkInProgressData?.INVOICEGUID != '' || WorkInProgressData?.ITEMTYPEDESC=='Search') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="deleteTimeEntry('timeBilling_delete_timeEntry')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete <br> Entry</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[2] && appPermissions[2]['Create WIP']==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[2] && appPermissions[2]['Create WIP']==0 ? 'disabled-click-cursor large-button' : 'large-button'"
                                                (click)="NewTimeEntry('timeBill')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New<br> Timer</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[2]?.Create==0 || isWIPData == 'empty' || WorkInProgressData.ITEMTYPEDESC=='Search' || WorkInProgressData.ITEMTYPEDESC=='Disbursement'|| WorkInProgressData.ITEMTYPEDESC=='Search' || WorkInProgressData.ITEMTYPEDESC=='Cost Recovery') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[2]?.Create==0 || isWIPData == 'empty' || WorkInProgressData.ITEMTYPEDESC=='Search' || WorkInProgressData.ITEMTYPEDESC=='Disbursement'|| WorkInProgressData.ITEMTYPEDESC=='Search' || WorkInProgressData.ITEMTYPEDESC=='Cost Recovery') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.addNewTimeEntry('Duplicate')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Duplicate <br>Time
                                                            Entry</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[2] && appPermissions[2]['Create Disbursements']==0 || appPermissions[9]?.Edit==0) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[2] && appPermissions[2]['Create Disbursements']==0 || appPermissions[9]?.Edit==0) ? 'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.spendmoneypopup('new','FromWIP','','NEW MATTER EXPENCE')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_expenses_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New Matter <br>Expense</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Action</div>
                                    </section>

                                    <mat-divider vertical
                                        *ngIf="activeSubMenu =='work-in-progress'"></mat-divider>
                                    <!-- ssss -->
                                    <section class="ribbon-section"
                                        *ngIf="activeSubMenu =='work-in-progress'">
                                        <mat-nav-list>
                                            <!-- <a mat-list-item
                                            [ngClass]="(appPermissions[2]?.Create==0) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                            (click)="NewTimeEntry('timeBill')">
                                            <mat-icon>
                                                <img src="assets/icons/web_app/icon_time_cost_new_d.ico">
                                            </mat-icon>
                                            <span>New Timer</span>
                                        </a> -->
                                            <a mat-list-item
                                                [ngClass]="(appPermissions[2]?.Edit==0 || isWIPData == 'empty' || WorkInProgressData.ITEMTYPEDESC=='Search') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                                (click)="ResumeTimePopup('startTimer','')">
                                                <mat-icon>
                                                    <img
                                                        src="assets/icons/web_app/icon_time_cost_edit_d.ico">
                                                </mat-icon>
                                                <span>Resume Timer</span>
                                            </a>
                                            <a mat-list-item *ngIf="('Fixed Fee Items' | tierPermission : 'Fixed Fee Items') && ('Fixed Fee Items' | tierPermission : 'Fixed Fee Items').ALLOWED ==1"
                                                [ngClass]="(appPermissions[2]?.Create==0) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                                (click)="ActivityDialog('new','Activities')">
                                                <mat-icon>
                                                    <img
                                                        src="assets/icons/web_app/icon_time_cost_new_d.ico">
                                                </mat-icon>
                                                <span>New Fixed Fee</span>
                                            </a>
                                            <a mat-list-item *ngIf="('Fixed Fee Items' | tierPermission : 'Fixed Fee Items') && ('Fixed Fee Items' | tierPermission : 'Fixed Fee Items').ALLOWED ==1"
                                                [ngClass]="(appPermissions[2]?.Create==0) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                                (click)="ActivityDialog('new','Sundries')">
                                                <mat-icon>
                                                    <img
                                                        src="assets/icons/web_app/icon_expenses_new_d.ico">
                                                </mat-icon>
                                                <span>New Sundry</span>
                                            </a>
                                        </mat-nav-list>
                                    </section>
                                    <mat-divider vertical
                                        *ngIf="activeSubMenu =='work-in-progress'"></mat-divider>
                                    <!-- ss -->
                                    <section
                                        class="group-section-tab details_tab"
                                        *ngIf="activeSubMenu =='work-in-progress'">
                                        <section
                                            [ngClass]="appPermissions[7]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[7]?.Create==0 ? 'disabled-click-cursor large-button' : 'large-button'"
                                                (click)="toolbarServiceService.createInstantInvoice(false)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_invoice_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Instant <br>
                                                            Invoice</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(isWIPData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(isWIPData == 'empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.createInvoice('preview');">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_invoice_draft_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Preview <br>
                                                            Invoice</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[7]?.Create==0 || isWIPData == 'empty') ? 'disabled-click ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[7]?.Create==0 || isWIPData == 'empty') ? 'disabled-click-cursor large-button' : 'large-button'"
                                                (click)="toolbarServiceService.createInvoice('')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_invoice_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Create <br>
                                                            Invoice</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                    </section>

                                    <section
                                        class="group-section-tab details_tab"
                                        *ngIf="activeSubMenu =='matter-trust'">
                                        <section
                                        [ngClass]="appPermissions[22]['Trust Receipt']==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="appPermissions[22]['Trust Receipt']==0 ? 'disabled-click large-button' : 'large-button'"
                                            (click)="TrustMoneyPopup('receipt')">
                                            <section
                                                class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img
                                                        src="assets/icons/web_app/icon_trust_receipt_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Trust<br>Receipt</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>

                                    <section
                                        [ngClass]="appPermissions[22]['Trust Withdrawal']==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="appPermissions[22]['Trust Withdrawal']==0 ? 'disabled-click large-button' : 'large-button'"
                                            (click)="TrustMoneyPopup('withdrawal')">
                                            <section
                                                class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img
                                                        src="assets/icons/web_app/icon_controlled_withdraw_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Trust<br>Withdrawal</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>


                                    <section
                                        [ngClass]="appPermissions[22]['Trust Transfer']==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="appPermissions[22]['Trust Transfer']==0 ? 'disabled-click large-button' : 'large-button'"
                                            (click)="TrustMoneyPopup('Transfer')">
                                            <section
                                                class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img
                                                        src="assets/icons/web_app/icon_trust_transfer_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Trust<br>Transfer</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>

                                    <section
                                            [ngClass]="appPermissions[22]['Trust Receipt']==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[22]['Trust Receipt']==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="TrustMoneyPopup('office')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_trust_withdrawal_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Trust To<br>Office</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                    <section
                                    [ngClass]="(appPermissions[22]['Reversals']==0 || !isTrustMoneyList) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                    <button mat-button
                                        [ngClass]="(appPermissions[22]['Reversals']==0 || !isTrustMoneyList) ? 'disabled-click large-button' : 'large-button'"
                                        (click)="TrustMoneyPopup('Reverse')">
                                        <section
                                            class="large-button-container">
                                            <mat-icon class="main-icon">
                                                <img
                                                    src="assets/icons/web_app/icon_trust_reversal_d.ico">
                                            </mat-icon>
                                            <div class="main-text">
                                                <span> Reverse<br>Item</span>
                                            </div>
                                        </section>
                                    </button>
                                </section>
                                        <section
                                            [ngClass]="(appPermissions[22]['Window Report']==0 || !isTrustMoneyListData || isGenrateReport) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[22]['Window Report']==0 || !isTrustMoneyListData || isGenrateReport) ? 'disabled-click large-button' : 'large-button'"
                                                (click)="ReportGenerate('reporttrustrequest','Trust Print Item')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_print_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Print<br>Item</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <mat-divider vertical
                                            *ngIf="activeSubMenu =='matter-trust'"></mat-divider>
                                        <div class="light_label_txt">Action</div>
                                    </section>
                                    <section
                                        class="group-section-tab details_tab"
                                        *ngIf="activeSubMenu =='protected-trust'">
                                        <ng-container
                                            *ngIf="activeSubMenu =='protected-trust'">
                                            <section style="margin-top: 14px;"
                                                class="group-section-tab action_tab"
                                                *ngIf="('Protected Trust' | tierPermission : 'Protected Trust').ALLOWED ==1">
                                                <section>
                                                    <button mat-button
                                                        (click)="OpenTrustPopUp('insert')"
                                                        [ngClass]="appPermissions[31]['Create']==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                                        <section
                                                            class="large-button-container">
                                                            <mat-icon
                                                                class="main-icon">
                                                                <img
                                                                    src="assets/icons/web_app/icon_trust_d.ico">
                                                            </mat-icon>
                                                            <div class="main-text">
                                                                <span>New Protected
                                                                    <br>Trust</span>
                                                            </div>
                                                        </section>
                                                    </button>
                                                </section>
                                                <section>
                                                    <button mat-button
                                                        (click)="OpenTrustPopUp('update')"
                                                        [ngClass]="appPermissions[31]['Edit']==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                                        <section
                                                            class="large-button-container">
                                                            <mat-icon
                                                                class="main-icon">
                                                                <img
                                                                    src="assets/icons/web_app/icon_trust_d.ico">
                                                            </mat-icon>
                                                            <div class="main-text">
                                                                <span>Edit Protected
                                                                    <br>Trust</span>
                                                            </div>
                                                        </section>
                                                    </button>
                                                </section>
                                                <section>
                                                    <button mat-button
                                                        (click)="deleteProtectedTrust('DELETE')"
                                                        [ngClass]="appPermissions[31]['Delete']==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                                        <section
                                                            class="large-button-container">
                                                            <mat-icon
                                                                class="main-icon">
                                                                <img
                                                                    src="assets/icons/web_app/icon_trust_d.ico">
                                                            </mat-icon>
                                                            <div class="main-text">
                                                                <span>Delete
                                                                    Protected <br>Trust</span>
                                                            </div>
                                                        </section>
                                                    </button>
                                                </section>
                                                <br>
                                                <div style="margin-top: 10px;"
                                                    class="light_label_txt">Action</div>
                                            </section>
                                        </ng-container>
                                    </section>
                                    <mat-divider vertical
                                        *ngIf="activeSubMenu =='work-in-progress'"></mat-divider>
                                    <section
                                        class="group-section-tab details_tab"
                                        *ngIf="activeSubMenu =='work-in-progress'">
                                        <section class="ribbon-section">
                                            <mat-nav-list>
                                                <a mat-list-item
                                                    [ngClass]="(appPermissions[7]?.Access==0 || isWIPData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                                    *ngIf="isInvoice != 'invoice' && activeSubMenu =='work-in-progress'"
                                                    (click)="ReCalcTimeEntriClick()">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_edit_d.ico">
                                                    </mat-icon>
                                                    <span>Re Calc Time Entries</span>
                                                </a>
                                                <span
                                                    *ngIf="DisEnTimeEntryToolbar == 'write_off'">
                                                    <a mat-list-item
                                                        [ngClass]="(appPermissions[2]?.Edit==0 || isWIPData == 'empty' || WorkInProgressData.INVOICEGUID != '') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                                        (click)="WriteOffTimeEntry('WIP')">
                                                        <mat-icon>
                                                            <img
                                                                src="assets/icons/web_app/icon_time_cost_delete_d.ico">
                                                        </mat-icon>
                                                        <span>Write off Work in
                                                            Progress</span>
                                                    </a>
                                                </span>
                                                <span
                                                    *ngIf="DisEnTimeEntryToolbar == 'undo'">
                                                    <a mat-list-item
                                                        [ngClass]="(appPermissions[7]?.Create==0 || isWIPData == 'empty')  ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                                        (click)="UndoWriteOffTimeEntry('WIP')">
                                                        <mat-icon>
                                                            <img
                                                                src="assets/icons/web_app/icon_time_cost_delete_d.ico">
                                                        </mat-icon>
                                                        <span>Undo - Write off
                                                            Work in Progress</span>
                                                    </a>
                                                </span>

                                                <a mat-list-item
                                                    [ngClass]="(appPermissions[7]?.Access==0 || isWIPData == 'empty')?'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                                    *ngIf="isInvoice != 'invoice' && activeSubMenu =='work-in-progress'"
                                                    (click)="searchAndReplaceWorkItemOnClick()">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_edit_d.ico">
                                                    </mat-icon>
                                                    <span>Find/Replace</span>
                                                </a>
                                            </mat-nav-list>
                                        </section>

                                        <section class="ribbon-section">
                                            <mat-nav-list>
                                                <a mat-list-item
                                                    [ngClass]="(appPermissions[7]?.Access==0 || isWIPData == 'empty' || WorkInProgressData.INVOICEGUID != '' || WorkInProgressData.INVOICEGUID == -1) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                                    *ngIf="isInvoice != 'invoice' && activeSubMenu =='work-in-progress'"
                                                    (click)="moveWIP()">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_edit_d.ico">
                                                    </mat-icon>
                                                    <span>Move Entry</span>
                                                </a>
                                                <!-- <a mat-list-item   [ngClass]="(appPermissions[7]?.Access==0 || isWIPData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'" *ngIf="isInvoice != 'invoice' && activeSubMenu =='work-in-progress' && currentUser?.PRODUCTTYPE !='Barrister'"  (click)="splittimeentry('Split')">
                                                <mat-icon>
                                                    <img src="assets/icons/web_app/icon_time_cost_edit_d.ico">
                                                </mat-icon>
                                                <span>Split Time Entry</span>
                                            </a> -->

                                                <a mat-list-item
                                                    [ngClass]="(appPermissions[2]?.Access==0 || appPermissions[2]?.Create==0  || appPermissions[2]?.Edit==0|| isWIPData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                                    *ngIf="isInvoice != 'invoice' && activeSubMenu =='work-in-progress'"
                                                    (click)="(splitAccessTier.ALLOWED !==1)? goTo(splitAccessTier.URL):splittimeentry('Split')"
                                                    (click)="(splitAccessTier.ALLOWED !==1)? false:true"
                                                    [matTooltip]="('Split Time Entries' | tierPermission : 'Split Time Entries').ALLOWED !== 1?('Split Time Entries' | tierPermission : 'Split Time Entries').DESCRIPTION :''"
                                                    matTooltipPosition="below">
                                                    <mat-icon>
                                                        <img
                                                            [src]="('Split Time Entries' | tierPermission : 'Split Time Entries').ALLOWED == 1?'assets/icons/web_app/icon_time_cost_edit_d.ico':'assets/icons/web_app/Feature_is_Locked.ico'">
                                                    </mat-icon>
                                                    <span>Split Time Entry</span>
                                                </a>
                                            </mat-nav-list>
                                        </section>
                                        <div class="light_label_txt">Extras
                                        </div>
                                    </section>

                                    <section
                                        class="group-section-tab action_tab"
                                        *ngIf="activeSubMenu=='matter-invoices'">
                                        <section
                                            [ngClass]="(appPermissions[7]?.Access==0 || isMatterInvoiceData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[7]?.Access==0 || isMatterInvoiceData == 'empty') ? 'disabled-click-cursor large-button' : 'large-button'"
                                                (click)="InvoiceDetail('isTime');">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_invoice_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>View<br>Invoice</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[7]?.Delete==0 || isMatterInvoiceData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[7]?.Delete==0 || isMatterInvoiceData == 'empty')? 'disabled-click-cursor large-button' : 'large-button'"
                                                (click)="deleteInvoice('isTime');">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_invoice_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete<br> Invoice</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[8]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[8]?.Create==0 ? 'disabled-click-cursor large-button' : 'large-button'"
                                                (click)="createReceiptForTimeBilling()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_receipt_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New<br> Receipt</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[7]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[7]?.Create==0 ? 'disabled-click-cursor large-button' : 'large-button'"
                                                (click)="toolbarServiceService.createInstantInvoice(false)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_invoice_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Instant <br>
                                                            Invoice</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Action</div>
                                    </section>

                                    <mat-divider
                                        *ngIf="activeSubMenu=='matter-invoices'"
                                        vertical></mat-divider>
                                    <section
                                        *ngIf="activeSubMenu=='matter-invoices'"
                                        class="group-section-tab action_tab">
                                        <section
                                            [ngClass]="(appPermissions[5]?.Access==0 || isMatterInvoiceData == 'empty')  ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <!-- [disabled]="ShowGenerateInvoice =='no'" -->
                                            <button
                                                (click)="GenerateInvoice()"
                                                mat-button
                                                [ngClass]="(appPermissions[5]?.Access==0 || isMatterInvoiceData == 'empty')  ? 'disabled-click large-button' : 'large-button'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_print_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Generate <br>
                                                            Invoice</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[5]?.Access==0 || isMatterInvoiceData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'" *ngIf="(('Document Generation' | tierPermission : 'Document Generation') && ('Document Generation' | tierPermission : 'Document Generation').ALLOWED == 1)">
                                            <!-- <button mat-button [ngClass]="(appPermissions[5]?.Access==0 || isMatterInvoiceData == 'empty') ? 'disabled-click large-button' : 'large-button'" (click)="setTab($event)" [routerLink]="'/create-document/invoice-template'">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_letters_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Generate <br> Document</span>
                                                </div>
                                            </section>
                                        </button> -->

                                            <button mat-button
                                                [ngClass]="(appPermissions[5]?.Access==0 || isMatterInvoiceData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.generateDocumentsFromMatters($event);SendEmail(false)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_letters_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Generate <br>
                                                            Documents</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Details</div>
                                    </section>
                                    <mat-divider
                                        *ngIf="activeSubMenu=='matter-invoices'"
                                        vertical></mat-divider>
                                    <section
                                        *ngIf="activeSubMenu=='matter-invoices'"
                                        class="group-section-tab action_tab">
                                        <section
                                            [ngClass]="(appPermissions[7] && appPermissions[7]['Write Off']==0 || isMatterInvoiceData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button (click)="InvoiceWriteoff()"
                                                mat-button
                                                [ngClass]="(appPermissions[7] && appPermissions[7]['Write Off']==0 || isMatterInvoiceData == 'empty') ? 'disabled-click large-button' : 'large-button'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_invoice_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Write off<br>Invoice</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <!-- <section [ngClass]="(appPermissions[5]?.Access==0 || isMatterInvoiceData == 'empty' || isforCredits== 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button (click)="CheckForCredits()" mat-button [ngClass]="(appPermissions[5]?.Access==0 || isMatterInvoiceData == 'empty'  || isforCredits== 'empty') ? 'disabled-click large-button' : 'large-button'">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_receipt_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Check for<br>Credits</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section> -->

                                        <!-- new changes are done by karan -->
                                        <section>
                                            <button (click)="CheckForCredits()"
                                                mat-button Class="large-button"
                                                style="height: auto;padding: 16px 12px;">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_receipt_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Check for<br>Credits</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <!-- new changes are done by karan -->

                                        <section class="ribbon-section">
                                            <mat-nav-list>
                                                <a mat-list-item *ngIf="('Interest Calculations' | tierPermission : 'Interest Calculations') && ('Interest Calculations' | tierPermission : 'Interest Calculations').ALLOWED ==1"
                                                    [ngClass]="(appPermissions[5]?.Access==0 || isMatterInvoiceData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                                    (click)="CalculateInterest()">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/percent.ico">
                                                    </mat-icon>
                                                    <span>Calculate Interest</span>
                                                </a>
                                            </mat-nav-list>
                                        </section>
                                        <div class="light_label_txt">Extras</div>
                                    </section>
                                    <mat-divider
                                        *ngIf="activeSubMenu=='matter-invoices'"
                                        vertical></mat-divider>

                                         <!-- NEW -->
                                    <section
                                        *ngIf="activeSubMenu=='receipts-credits' && ('Document Generation' | tierPermission : 'Document Generation') && ('Document Generation' | tierPermission : 'Document Generation').ALLOWED == 1"
                                        class="group-section-tab action_tab">
                                        <section *ngIf="('Document Generation' | tierPermission : 'Document Generation') && ('Document Generation' | tierPermission : 'Document Generation').ALLOWED == 1"
                                            [ngClass]="(appPermissions[5]?.Access==0 || isReceiptData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button
                                                [disabled]="DisabledReceiptTool=='disabled'"
                                                (click)="GenerateReceipt()"
                                                mat-button
                                                [ngClass]="(appPermissions[5]?.Access==0 || isReceiptData =='empty') ?  'disabled-click large-button' : 'large-button'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_print_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Generate <br>
                                                            Receipt</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[5]?.Access==0 || isReceiptData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'" *ngIf="(('Document Generation' | tierPermission : 'Document Generation') && ('Document Generation' | tierPermission : 'Document Generation').ALLOWED == 1)">
                                            <!-- <button mat-button [ngClass]="(appPermissions[5]?.Access==0 || isReceiptData =='empty')  ? 'disabled-click large-button' : 'large-button'" (click)="setTab($event)" [routerLink]="'/create-document'">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_letters_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Generate <br> Document</span>
                                                </div>
                                            </section>
                                        </button> -->
                                            <button mat-button
                                                [ngClass]="(appPermissions[5]?.Access==0 || isReceiptData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.generateDocumentsFromMatters($event);SendEmail(false)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_letters_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Generate <br>
                                                            Documents</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Details</div>
                                    </section>
                                    <mat-divider vertical
                                        *ngIf="activeSubMenu=='receipts-credits'"></mat-divider>

                                    <!-- Document Register-->
                                    <section
                                        class="group-section-tab action_tab"
                                        *ngIf="activeSubMenu=='document-register'">
                                        <section
                                            [ngClass]="appPermissions[6]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[6]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="DocumntPop('new')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_doc_reg_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New Doc<br>Record</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[6]?.Edit==0 || isDocRegData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[6]?.Edit==0 || isDocRegData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="DocumntPop('edit')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_doc_reg_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Edit Doc<br>Record</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[6]?.Delete==0 || isDocRegData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[6]?.Delete==0 || isDocRegData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="DeleteDocument()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_doc_reg_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete Doc<br>Record</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[6]?.Create==0 || isDocRegData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[6]?.Create==0 || isDocRegData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="DocumntPop('duplicate')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_duplicate_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Duplicate Doc<br>Record</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Action</div>
                                    </section>
                                    <mat-divider vertical
                                        *ngIf="activeSubMenu=='document-register'"></mat-divider>
                                    <section
                                        class="group-section-tab details_tab"
                                        *ngIf="activeSubMenu=='document-register'">
                                        <section
                                            [ngClass]="(appPermissions[7]?.Delete==0||isGenrateReport) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[1]?.Edit==0||isGenrateReport) ? 'disabled-click large-button' : 'large-button'"
                                                (click)="OpenMatter('MATTERFOLDER','matter_document')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/folder_large.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Open Matter<br>Folder</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[7]?.Delete==0 ||isGenrateReport || isDocRegData =='empty')? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[1]?.Edit==0||isGenrateReport || isDocRegData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="OpenMatter('DOCUMENT','safecustody')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_letters_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Open<br>Document</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section
                                            [ngClass]="(appPermissions[7]?.Delete==0 || isGenrateReport || isDocRegData =='empty' || !isOpenDesktopWord )? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[7]?.delete==0|| isGenrateReport || isDocRegData =='empty' || !isOpenDesktopWord) ? 'disabled-click large-button' : 'large-button'"
                                                (click)="OpenInDesktopWord()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/Microsoft_Word_logo.png">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Open<br>in Desktop
                                                            Word</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section
                                            [ngClass]="(appPermissions[7]?.Delete==0 ||isGenrateReport || isDocRegData =='empty')? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[1]?.Edit==0||isGenrateReport || isDocRegData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="setTab($event)"
                                                [routerLink]="'/create-document/email-document-register-template'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_email_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Email<br>Document</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <mat-progress-bar mode="buffer"
                                            *ngIf="isGenrateReport"></mat-progress-bar>
                                        <div class="light_label_txt">Details</div>
                                    </section>
                                    <mat-divider vertical
                                        *ngIf="activeSubMenu=='document-register'"></mat-divider>
                                    <section
                                        class="group-section-tab details_tab"
                                        *ngIf="activeSubMenu=='document-register'">
                                        <section
                                            [ngClass]="(isGenrateReport) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(isGenrateReport) ? 'disabled-click large-button' : 'large-button'"
                                                (click)="OpenMatter('LOADDOCUMENTS','')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_doc_reg_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Load<br>Document</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[6]?.Edit==0 || isDocRegData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[6]?.Edit==0 || isDocRegData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="DocumntPop('rename')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_doc_reg_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Rename/Move <br>Document</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[6]?.Edit==0 || isDocRegData =='empty' || DocRegData.type=='pdf') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[6]?.Edit==0 || isDocRegData =='empty' || DocRegData.type=='pdf') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="PdfDocument()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/pdf.png">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>PDF<br>Document</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[6]?.Edit==0 || isDocRegData =='empty' || DocRegData.type=='pdf') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[6]?.Edit==0 || isDocRegData =='empty' || DocRegData.type=='pdf') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="PdfEmailDocument()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/email.png">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>PDF & Email<br>Document</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <mat-progress-bar mode="buffer"
                                            *ngIf="isGenrateReport"></mat-progress-bar>
                                        <div class="light_label_txt">Extras</div>
                                    </section>
                                </mat-card-content>
                            </mat-card>
                        </section>
                    </mat-tab>
                    <!-- legal tab menu end -->
                    <!-- Diary tab menu start -->
                    <mat-tab label="DIARY" *ngIf="isTabShow===5">
                        <section class="ribbon-container diary_tab_main"
                            [style.width]="detailwidth">
                            <mat-card>
                                <mat-card-content>
                                    <section
                                        class="group-section-tab details_tab">
                                        <section
                                            [ngClass]="appPermissions[17]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[17]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="DiaryAppointment('new')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img class=" "
                                                            src="assets/icons/web_app/icon_diary_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New <br>
                                                            Appointment</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[17]?.Edit==0 || isDiaryData =='empty') ? 'disabled-click-cursor ribbon-section' : ' ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[17]?.Edit==0 || isDiaryData =='empty'|| !DairyData) ? 'disabled-click large-button' : ' large-button'"
                                                (click)="DiaryAppointment('edit')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img class=" "
                                                            src="assets/icons/web_app/icon_diary_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Edit <br>
                                                            Appointment</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[17]?.Delete==0 || isDiaryData =='empty') ? 'disabled-click-cursor ribbon-section' : ' ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[17]?.Delete==0 || isDiaryData =='empty' || !DairyData)? 'disabled-click large-button' : ' large-button'"
                                                (click)="DeleteAppointment()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img class=" "
                                                            src="assets/icons/web_app/icon_diary_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete <br>
                                                            Appointment</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[17]?.Create==0 || isDiaryData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[17]?.Create==0 || isDiaryData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="DiaryAppointment('duplicate')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img class=" "
                                                            src="assets/icons/web_app/icon_diary_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Duplicate <br>
                                                            Appointment</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Edit</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                    <section
                                        class="group-section-tab extras_tab">
                                        <section class="ribbon-section">
                                            <button mat-button
                                                class="large-button"
                                                (click)="setViewType('Day')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_diary_d.ico"
                                                            aria-label="Today">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Day <br> View</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section class="ribbon-section">
                                            <button mat-button
                                                class="large-button"
                                                (click)="setViewType('Week')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_diary_week_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Week <br> View</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section class="ribbon-section">
                                            <button mat-button
                                                class="large-button"
                                                (click)="setViewType('Month')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_diary_month_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Month <br> View</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section class="ribbon-section">
                                            <button mat-button
                                                class="large-button"
                                                (click)="setViewType('Combined')"
                                                [matTooltip]="('Combined Calendar' | tierPermission : 'Combined Calendar').ALLOWED !==1? ('Combined Calendar' | tierPermission : 'Combined Calendar').DESCRIPTION :''"
                                                matTooltipPosition="above">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            [src]="('Combined Calendar' | tierPermission : 'Combined Calendar').ALLOWED ==1?'assets/icons/web_app/icon_diary_week_d.ico':'assets/icons/web_app/Feature_is_Locked.ico'"
                                                            [ngClass]="(getPermission('Combined Calendar').ALLOWED == 0)?'feature_locked':''">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Combined <br> View</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            class="group-section-tab extras_tab">
                                            <section class="ribbon-section">
                                                <mat-nav-list>
                                                    <a mat-list-item
                                                        [matMenuTriggerFor]="menu">
                                                        <mat-icon>
                                                            <img
                                                                src="assets/icons/web_app/icon_diary_new_d.ico">
                                                        </mat-icon>
                                                        <span>Time Scale ...</span>
                                                    </a>
                                                    <mat-menu #menu="matMenu">
                                                        <button mat-menu-item
                                                            (click)="setTimeScale('12')">5
                                                            minutes</button>
                                                        <button mat-menu-item
                                                            (click)="setTimeScale('6')">10
                                                            minutes</button>
                                                        <button mat-menu-item
                                                            (click)="setTimeScale('4')">15
                                                            minutes</button>
                                                        <button mat-menu-item
                                                            (click)="setTimeScale('2')">30
                                                            minutes</button>
                                                        <button mat-menu-item
                                                            (click)="setTimeScale('1')">1
                                                            hour</button>
                                                    </mat-menu>
                                                </mat-nav-list>
                                            </section>
                                        </section>
                                        <div class="light_label_txt">View</div>
                                    </section>

                                    <mat-divider vertical></mat-divider>

                                    <section
                                        class="group-section-tab details_tab">
                                        <section
                                            [ngClass]="(appPermissions[17]?.Access==0 || isDiaryData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[17]?.Access==0 || isDiaryData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                [routerLink]="'/diary/create-diary'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img class=" "
                                                            src="assets/icons/web_app/icon_time_cost_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Create Time<br>Entries</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            *ngIf="currentUser?.PRODUCTTYPE!='Barrister' && ('Combined Calendar' | tierPermission : 'Combined Calendar').ALLOWED ==1"
                                            [ngClass]="appPermissions[17]?.Access==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[17]?.Access==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="ChangeUser()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img class=" "
                                                            src="assets/icons/web_app/icon_contact_d.ico">
                                                    </mat-icon>
                                                    <!-- <div class="main-text">
                                                    <span>Change<br>User</span>
                                                </div> -->
                                                    <div class="main-text">
                                                        <span>Combined<br>View</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Extras</div>
                                    </section>

                                    <mat-divider vertical></mat-divider>
                                </mat-card-content>
                            </mat-card>
                        </section>
                    </mat-tab>
                    <!-- Diary tab menu end -->
                    <!-- time-entries tab menu start -->
                    <mat-tab label="TIME ENTRIES" *ngIf="isTabShow===6">
                        <section class="ribbon-container time_entry_tab_main"
                            [style.width]="detailwidth">
                            <mat-card>
                                <mat-card-content>
                                    <section
                                        class="group-section-tab extras_tab">
                                        <section class="ribbon-section">
                                            <mat-nav-list>
                                                <a mat-list-item
                                                    [ngClass]="{'disabled-click-cursor':appPermissions[2]?.Create==0,'cmn_active_tab':activeSubMenu =='full-time-entries'}"
                                                    [routerLink]="'/time-entries/full-time-entries'">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_d.ico">
                                                    </mat-icon>
                                                    <span>Time Entries</span>
                                                </a>
                                            </mat-nav-list>
                                            <mat-nav-list>

                                                <a mat-list-item
                                                    [ngClass]="{'disabled-click-cursor':appPermissions[2]?.Create==0,'cmn_active_tab':activeSubMenu =='quick-time-entries'}"
                                                    [routerLink]="'/time-entries/quick-time-entries'">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_new_d.ico">
                                                    </mat-icon>
                                                    <span>Quick Time Entry</span>
                                                </a>

                                                <!-- <a *ngIf="('Split Time Entries' | tierPermission : 'Split Time Entries').ALLOWED == 0" mat-list-item [ngClass]="{'disabled-click-cursor':appPermissions[2]?.Create==0,'cmn_active_tab':activeSubMenu =='quick-time-entries'}" (click)="quickTimeEntrygoTo()" [matTooltip]="('Split Time Entries' | tierPermission : 'Split Time Entries').ALLOWED !== 1? ('Split Time Entries' | tierPermission : 'Split Time Entries').DESCRIPTION :''"  matTooltipPosition="above">
                                                <mat-icon >
                                                    <img src="assets/icons/web_app/icon_time_cost_new_d.ico">
                                                </mat-icon>
                                                <span>Quick Time Entry</span>
                                            </a> -->
                                            </mat-nav-list>
                                        </section>
                                        <div class="light_label_txt">View</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                    <section
                                        class="group-section-tab details_tab">
                                        <section
                                            *ngIf="activeSubMenu!='quick-time-entries'"
                                            [ngClass]="appPermissions[2] && appPermissions[2]['Create WIP'] == 0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[2] && appPermissions[2]['Create WIP'] == 0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.addNewTimeEntry('Add-New')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New Time <br>
                                                            Entry</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <!-- toolbarServiceService.addNewTimeEntry('Edit'); instead of this we use the EditTimeEntryFromWIP() this function as per client new changes  -->
                                        <section
                                            [ngClass]="(appPermissions[2]?.Edit==0 || isTimeEntryData == 'empty' || timeEntryData.INVOICEGUID != '') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[2]?.Edit==0 || isTimeEntryData == 'empty'|| timeEntryData.INVOICEGUID != '')? 'disabled-click large-button' : 'large-button'"
                                                (click)="EditTimeEntryFromWIP()"
                                                >
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Edit <br> Entry</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[2]?.Delete==0 || isTimeEntryData == 'empty'|| timeEntryData.INVOICEGUID != '' || this.dissabledeleteButton==true) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[2]?.Delete==0 || isTimeEntryData == 'empty'|| timeEntryData.INVOICEGUID != '' || this.dissabledeleteButton==true) ? 'disabled-click large-button' : 'large-button'"
                                                (click)="deleteTimeEntry('main_delete_timEntry')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete <br> Entry</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            *ngIf="activeSubMenu!='quick-time-entries'"
                                            [ngClass]="(appPermissions[2]?.Create==0 || isTimeEntryData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[2]?.Create==0 || isTimeEntryData == 'empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.addNewTimeEntry('Duplicate')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_duplicate_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Duplicate <br>Time
                                                            Entry</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[2] && appPermissions[2]['Create WIP']==0? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[2] && appPermissions[2]['Create WIP']==0? 'disabled-click-cursor large-button' : 'large-button'"
                                                (click)="NewTimeEntry()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New<br>Timer</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            *ngIf="activeSubMenu!='quick-time-entries'"
                                            [ngClass]="(appPermissions[2]?.Edit==0 || isTimeEntryData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[2]?.Edit==0 || isTimeEntryData == 'empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="ResumeTimePopup('startTimer','')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Resume<br>Timer</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <!-- <section [ngClass]="(appPermissions[2]?.Edit==0 || isTimeEntryData == 'empty' || timeEntryData.INVOICEGUID != '') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button [ngClass]="(appPermissions[2]?.Edit==0 || isTimeEntryData == 'empty'|| timeEntryData.INVOICEGUID != '')? 'disabled-click large-button' : 'large-button'" (click)="splittimeentry('Split')">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_time_cost_edit_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Split <br>Time Entry</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section> -->
                                        <div class="light_label_txt">Action</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                    <section
                                        class="group-section-tab extras_tab">
                                        <section class="ribbon-section"
                                            *ngIf="DisEnTimeEntryToolbar == 'write_off'">
                                            <mat-nav-list>
                                                <!-- appPermissions[7]?.Create==0 || -->
                                                <a mat-list-item
                                                    [ngClass]="( appPermissions[2]?.Edit==0 || isTimeEntryData == 'empty' || timeEntryData.INVOICEGUID != '') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                                    (click)="WriteOffTimeEntry('TNB')">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_delete_d.ico">
                                                    </mat-icon>
                                                    <span>Write off Work in
                                                        Progress</span>
                                                </a>
                                                <!-- For multi select  appPermissions[7]?.Create==0  || -->
                                                <a mat-list-item
                                                    [ngClass]="( appPermissions[2]?.Edit==0 || isTimeEntryData == 'empty' || timeEntryData.INVOICEGUID != '') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                                    class="ribbon-section"
                                                    (click)="MovetoAnotherMatter()">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_edit_d.ico">
                                                    </mat-icon>
                                                    <span>Move to another Matter</span>
                                                </a>

                                                <!--
                                            <a mat-list-item  *ngIf="currentUser?.PRODUCTTYPE !='Barrister'" [ngClass]="(appPermissions[7]?.Create==0 || isTimeEntryData == 'empty' || timeEntryData.INVOICEGUID != '') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'" class="ribbon-section" (click)="splittimeentry('Split')">
                                                <mat-icon>
                                                    <img src="assets/icons/web_app/icon_time_cost_edit_d.ico">
                                                </mat-icon>
                                                <span>Split Time Entry</span>
                                            </a> -->
                                                <a mat-list-item
                                                    [ngClass]="(appPermissions[2]?.Create==0  || appPermissions[2]?.Edit==0 || isTimeEntryData == 'empty' || timeEntryData.INVOICEGUID != '') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                                    (click)="(splitAccessTier.ALLOWED !==1)? goTo(splitAccessTier.URL):splittimeentry('Split')"
                                                    (click)="(splitAccessTier.ALLOWED !==1)? false:true"
                                                    [matTooltip]="('Split Time Entries' | tierPermission : 'Split Time Entries').ALLOWED !== 1?('Split Time Entries' | tierPermission : 'Split Time Entries').DESCRIPTION :''"
                                                    matTooltipPosition="below">
                                                    <mat-icon>
                                                        <img
                                                            [src]="('Split Time Entries' | tierPermission : 'Split Time Entries').ALLOWED == 1?'assets/icons/web_app/icon_time_cost_edit_d.ico':'assets/icons/web_app/Feature_is_Locked.ico'">
                                                    </mat-icon>
                                                    <span>Split Time Entry</span>
                                                </a>

                                                <!-- <a mat-list-item
                                                [ngClass]="(appPermissions[2]?.Create==0  || appPermissions[2]?.Edit==0 || isTimeEntryData == 'empty' || timeEntryData.INVOICEGUID != '') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                                *ngIf="currentUser?.PRODUCTTYPE =='Solicitor'"
                                                (click)="(splitAccessTier.ALLOWED !==1)? goTo(splitAccessTier.URL):''"
                                                (click)="(splitAccessTier.ALLOWED !==1)? false:true"
                                                [matTooltip]="('Split Time Entries' | tierPermission : 'Split Time Entries').ALLOWED !== 1?('Split Time Entries' | tierPermission : 'Split Time Entries').DESCRIPTION :''"
                                                matTooltipPosition="above">
                                                <mat-icon>
                                                    <img
                                                        [src]="('Split Time Entries' | tierPermission : 'Split Time Entries').ALLOWED == 1?'assets/icons/web_app/icon_time_cost_edit_d.ico':'assets/icons/web_app/Feature_is_Locked.ico'">
                                                </mat-icon>
                                                <span>Enter WIP Cut Off Date</span>
                                            </a> -->
                                                <!-- <section [ngClass]="(appPermissions[2]?.Edit==0 || isTimeEntryData == 'empty' || timeEntryData.INVOICEGUID != '') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                                <button mat-button [ngClass]="(appPermissions[2]?.Edit==0 || isTimeEntryData == 'empty'|| timeEntryData.INVOICEGUID != '')? 'disabled-click large-button' : 'large-button'" (click)="splittimeentry('Split')">
                                                    <section class="large-button-container">
                                                        <mat-icon class="main-icon">
                                                            <img src="assets/icons/web_app/icon_time_cost_edit_d.ico">
                                                        </mat-icon>
                                                        <div class="main-text">
                                                            <span>Split <br>Time Entry</span>
                                                        </div>
                                                    </section>
                                                </button>
                                            </section> -->
                                                <!-- disabled-click-cursor -->
                                            </mat-nav-list>
                                        </section>

                                        <section class="ribbon-section">
                                            <mat-nav-list>
                                                <a mat-list-item
                                                    *ngIf="DisEnTimeEntryToolbar == 'undo'"
                                                    [ngClass]="(appPermissions[7]?.Create==0 || isTimeEntryData == 'empty')  ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                                    (click)="UndoWriteOffTimeEntry('TNB')">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_delete_d.ico">
                                                    </mat-icon>
                                                    <span>Undo - Write off Work
                                                        in Progress</span>
                                                </a>
                                                <!-- disabled-click-cursor -->
                                                <!-- (click)="(splitAccessTier.ALLOWED !==1)? goTo(splitAccessTier.URL):''" -->
                                                <a mat-list-item
                                                    [ngClass]="(appPermissions[28]['System Settings']==0)  ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                                    *ngIf="currentUser?.PRODUCTTYPE =='Solicitor' && ('Work In Progress Cutoff Date' | tierPermission : 'Work In Progress Cutoff Date').ALLOWED ==1"

                                                    (click)="(splitAccessTier.ALLOWED !==1)? false:true; CloseYearAccountsWIP()"
                                                    [matTooltip]="('Work In Progress Cutoff Date' | tierPermission : 'Work In Progress Cutoff Date').ALLOWED !== 1?('Work In Progress Cutoff Date' | tierPermission : 'Work In Progress Cutoff Date').DESCRIPTION :''"
                                                    matTooltipPosition="above">
                                                    <mat-icon>
                                                        <img
                                                            [src]="('Work In Progress Cutoff Date' | tierPermission : 'Work In Progress Cutoff Date').ALLOWED == 1?'assets/icons/web_app/icon_time_cost_edit_d.ico':'assets/icons/web_app/Feature_is_Locked.ico'">
                                                    </mat-icon>
                                                    <span>Enter WIP Cut Off Date</span>
                                                </a>
                                            </mat-nav-list>
                                        </section>
                                        <div class="light_label_txt">Extras</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                </mat-card-content>
                            </mat-card>
                        </section>
                    </mat-tab>
                    <!-- time-entries tab menu end -->
                    <!-- Invoice tab menu -->
                    <mat-tab label="INVOICES" *ngIf="isTabShow===7">
                        <div class="mat-card-content mb-0">
                            <section class="group-section-tab extras_tab" *ngIf="('Bulk Billing' | tierPermission : 'Bulk Billing').ALLOWED ==1">
                                <section class="ribbon-section">
                                    <mat-nav-list>
                                        <a mat-list-item [ngClass]="activeSubMenu =='sub-invoices'?'cmn_active_tab':''"
                                            [routerLink]="'/invoice/sub-invoices'">
                                            <mat-icon>
                                                <img src="assets/icons/web_app/icon_trust_d.ico">
                                                <!-- <img src="assets/icons/web_app/icon_tagall_d.ico"> -->
                                            </mat-icon>
                                            <span>Invoices</span>
                                        </a>
                                    </mat-nav-list>
                                    <mat-nav-list>
                                    <a mat-list-item [ngClass]="{'disabled-click-cursor':appPermissions[7]?.Create==0,'cmn_active_tab':activeSubMenu =='bulk-invoices'}"
                                            [routerLink]="'/invoice/bulk-invoices'">
                                            <mat-icon>
                                                <img src="assets/icons/web_app/icon_trust_d.ico">
                                            </mat-icon>
                                            <span>Bulk Invoices</span>
                                        </a>
                                    </mat-nav-list>
                                    <div class="light_label_txt">view</div>
                                </section>
                            </section>
                        <mat-divider *ngIf="('Bulk Billing' | tierPermission : 'Bulk Billing').ALLOWED ==1" style="height: 110px;" vertical></mat-divider>
                                    <ng-container *ngIf="activeSubMenu =='sub-invoices'">
                                        <section class="group-section-tab action_tab">
                                            <section class="group-section-tab action_tab">
                                                                <section [ngClass]="appPermissions[7]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                                                    <button mat-button
                                                                        [ngClass]="appPermissions[7]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                                        (click)="selectMatterInvoice()">
                                                                        <section
                                                                            class="large-button-container">
                                                                            <mat-icon class="main-icon">
                                                                                <img
                                                                                    src="assets/icons/web_app/icon_invoice_new_d.ico">
                                                                            </mat-icon>
                                                                            <div class="main-text">
                                                                                <span>Create<br>Invoice</span>
                                                                            </div>
                                                                        </section>
                                                                    </button>
                                                                </section>
                                                                <section
                                                                    [ngClass]="(appPermissions[7]?.Edit==0 || isInvoiceData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                                                    <button mat-button
                                                                        [ngClass]="(appPermissions[7]?.Edit==0 || isInvoiceData == 'empty') ? 'disabled-click large-button' : 'large-button'"
                                                                        (click)="InvoiceDetail();">
                                                                        <section
                                                                            class="large-button-container">
                                                                            <mat-icon class="main-icon">
                                                                                <img
                                                                                    src="assets/icons/web_app/icon_invoice_edit_d.ico">
                                                                            </mat-icon>
                                                                            <div class="main-text">
                                                                                <span>Invoice<br>
                                                                                    Details</span>
                                                                            </div>
                                                                        </section>
                                                                    </button>
                                                                </section>
                                                                <section
                                                                    [ngClass]="(appPermissions[7]?.Delete==0 || isInvoiceData == 'empty')  ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                                                    <button mat-button
                                                                        [ngClass]="(appPermissions[7]?.Delete==0 || isInvoiceData == 'empty') ? 'disabled-click large-button' : 'large-button'"
                                                                        (click)="deleteInvoice();">
                                                                        <section
                                                                            class="large-button-container">
                                                                            <mat-icon class="main-icon">
                                                                                <img
                                                                                    src="assets/icons/web_app/icon_invoice_delete_d.ico">
                                                                            </mat-icon>
                                                                            <div class="main-text">
                                                                                <span>Delete<br>Invoice</span>
                                                                            </div>
                                                                        </section>
                                                                    </button>
                                                                </section>
                                                                <section
                                                                    [ngClass]="appPermissions[8]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                                                    <button mat-button
                                                                        [ngClass]="appPermissions[8]?.Create==0 ? 'disabled-click-cursor large-button' : 'large-button'"
                                                                        (click)="toolbarServiceService.createReceipt('selected_invoice_matter')">
                                                                        <section
                                                                            class="large-button-container">
                                                                            <mat-icon class="main-icon">
                                                                                <img
                                                                                    src="assets/icons/web_app/icon_receipt_new_d.ico">
                                                                            </mat-icon>
                                                                            <div class="main-text">
                                                                                <span>New<br> Receipt</span>
                                                                            </div>
                                                                        </section>
                                                                    </button>
                                                                </section>
                                                                <section
                                                                    [ngClass]="(appPermissions[5]?.Access==0 || isInvoiceData == 'empty')  ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                                                    <!-- [disabled]="ShowGenerateInvoice =='no' " -->
                                                                    <button
                                                                        (click)="GenerateInvoice()"
                                                                        mat-button
                                                                        [ngClass]="(appPermissions[5]?.Access==0 || isInvoiceData == 'empty') ? 'disabled-click large-button' : 'large-button'">
                                                                        <section
                                                                            class="large-button-container">
                                                                            <mat-icon class="main-icon">
                                                                                <img
                                                                                    src="assets/icons/web_app/icon_print_d.ico">
                                                                            </mat-icon>
                                                                            <div class="main-text">
                                                                                <span>Generate <br>
                                                                                    Invoice</span>
                                                                            </div>
                                                                        </section>
                                                                    </button>
                                                                </section>
                                                                <section
                                                                    [ngClass]="(appPermissions[5]?.Access==0 || isInvoiceData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'" *ngIf="(('Document Generation' | tierPermission : 'Document Generation') && ('Document Generation' | tierPermission : 'Document Generation').ALLOWED == 1)">
                                                                    <!-- <button mat-button [ngClass]="(appPermissions[5]?.Access==0 || isInvoiceData == 'empty') ? 'disabled-click large-button' : 'large-button'" (click)="isInvoiceClick()" (click)="setTab($event)" [routerLink]="'/create-document/invoice-template'">
                                                                    <section class="large-button-container">
                                                                        <mat-icon class="main-icon">
                                                                            <img src="assets/icons/web_app/icon_letters_d.ico">
                                                                        </mat-icon>
                                                                        <div class="main-text">
                                                                            <span> Generate <br> Template</span>
                                                                        </div>
                                                                    </section>
                                                                </button> -->
                                                                    <button mat-button
                                                                        [ngClass]="(appPermissions[5]?.Access==0 || isInvoiceData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                                        (click)="toolbarServiceService.generateDocumentsFromMatters($event,'GenerateDocument');SendEmail(false)">
                                                                        <section
                                                                            class="large-button-container">
                                                                            <mat-icon class="main-icon">
                                                                                <img
                                                                                    src="assets/icons/web_app/icon_letters_d.ico">
                                                                            </mat-icon>
                                                                            <div class="main-text">
                                                                                <span>Generate <br>
                                                                                    Documents</span>
                                                                            </div>
                                                                        </section>
                                                                    </button>
                                                                </section>
                                                                <div class="light_label_txt">Action</div>
                                                            </section>
                                                            <mat-divider vertical></mat-divider>
                                                            <!-- <section class="group-section-tab extras_tab">
                                                            <section [ngClass]="appPermissions[7]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                                                <button mat-button [ngClass]="appPermissions[7]?.Create==0 ? 'disabled-click large-button' : 'large-button'" (click)="setTab($event)" [routerLink]="'/create-document/invoice-multiple-document'">
                                                                    <section class="large-button-container">
                                                                        <mat-icon class="main-icon">
                                                                            <img src="assets/icons/web_app/icon_letter_multi_d.ico">
                                                                        </mat-icon>
                                                                        <div class="main-text">
                                                                            <span>Multiple <br />Document</span>
                                                                        </div>
                                                                    </section>
                                                                </button>
                                                            </section>
                                                            <div class="light_label_txt">Details</div>
                                                         </section> -->

                                                            <section class="group-section-tab extras_tab" *ngIf="('Document Generation' | tierPermission : 'Document Generation') && ('Document Generation' | tierPermission : 'Document Generation').ALLOWED == 1">
                                                                <section
                                                                    [ngClass]="appPermissions[7]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                                                    <button mat-button
                                                                        [ngClass]="appPermissions[7]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                                        (click)="toolbarServiceService.generateDocumentsFromMatters($event,'MultipleDocument')">
                                                                        <section
                                                                            class="large-button-container">
                                                                            <mat-icon class="main-icon">
                                                                                <img
                                                                                    src="assets/icons/web_app/icon_letter_multi_d.ico">
                                                                            </mat-icon>
                                                                            <div class="main-text">
                                                                                <span>Multiple <br />Document</span>
                                                                            </div>
                                                                        </section>
                                                                    </button>
                                                                </section>
                                                                <div class="light_label_txt">Details</div>
                                                            </section>

                                                            <mat-divider vertical></mat-divider>
                                                            <section
                                                                class="group-section-tab extras_tab">
                                                                <section class="ribbon-section">
                                                                    <mat-nav-list>
                                                                        <a mat-list-item
                                                                            [ngClass]="(appPermissions[7]?.Create==0 || isInvoiceData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                                                            (click)="toolbarServiceService.createInstantInvoice(true)">
                                                                            <mat-icon>
                                                                                <img
                                                                                    src="assets/icons/web_app/icon_invoice_new_d.ico">
                                                                            </mat-icon>
                                                                            <span>Instant Invoice</span>
                                                                        </a>
                                                                        <!-- disabled-click-cursor -->
                                                                        <a mat-list-item
                                                                            [ngClass]="(appPermissions[7] && appPermissions[7]['Write Off']==0 || isInvoiceData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                                                            (click)="InvoiceWriteoff()">
                                                                            <mat-icon>
                                                                                <img
                                                                                    src="assets/icons/web_app/icon_invoice_delete_d.ico">
                                                                            </mat-icon>
                                                                            <span>Write off Invoice</span>
                                                                        </a>
                                                                        <a mat-list-item *ngIf="('Interest Calculations' | tierPermission : 'Interest Calculations') && ('Interest Calculations' | tierPermission : 'Interest Calculations').ALLOWED ==1"
                                                                            [ngClass]="(appPermissions[7]?.Create==0 || isInvoiceData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                                                            (click)="CalculateInterest()">
                                                                            <mat-icon>
                                                                                <img
                                                                                    src="assets/icons/web_app/percent.ico">
                                                                            </mat-icon>
                                                                            <span>Calculate Interest</span>
                                                                        </a>
                                                                    </mat-nav-list>
                                                                </section>
                                                                <div class="light_label_txt">Extras</div>
                                                            </section>
                                                            <mat-divider vertical></mat-divider>


                                        </section>
                                    </ng-container>
                        <mat-divider style="height: 110px;" vertical *ngIf="activeSubMenu =='bulk-invoices'"></mat-divider>
                                    <ng-container
                                        *ngIf="activeSubMenu =='bulk-invoices'">

                                        <section [ngClass]="isShownBulkInvoice==false ? 'disabled-click large-button' : 'large-button'" >
                                            <!-- [ngClass]="appPermissions[7]?.Create==0 ? 'disabled-click large-button' : 'large-button'" -->
                                            <button mat-button  class="large-button" (click)="CallingBulInvoices('Create Bulk Preview')">

                                                <section class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img src="assets/icons/web_app/icon_invoice_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Bulk<br>Preview</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                                <section [ngClass]="isShownBulkInvoice==false ? 'disabled-click large-button' : 'large-button'" >
                                                    <!-- [ngClass]="appPermissions[7]?.Create==0 ? 'disabled-click large-button' : 'large-button'" -->
                                                    <button mat-button  class="large-button" (click)="CallingBulInvoices('Create Bulk Invoice')">

                                                        <section class="large-button-container">
                                                            <mat-icon class="main-icon">
                                                                <img src="assets/icons/web_app/icon_invoice_new_d.ico">
                                                            </mat-icon>
                                                            <div class="main-text">
                                                                <span>Create<br>Bulk Invoices</span>
                                                            </div>
                                                        </section>
                                                    </button>
                                                </section>
                                                <!-- [ngClass]="appPermissions[7]?.Create==0 ? 'disabled-click large-button' : 'large-button'" -->
                                                <section [ngClass]="isShownBulkInvoice==false ? 'disabled-click large-button' : 'large-button'">
                                                    <button mat-button class="large-button" (click)="CallingBulInvoices('Create+Generate Bulk Invoice')">
                                                        <section class="large-button-container">
                                                            <mat-icon class="main-icon">
                                                                <img src="assets/icons/web_app/icon_invoice_new_d.ico">
                                                            </mat-icon>
                                                            <div class="main-text">
                                                                <span>Create +<br>Gen. Bulk Invoices</span>
                                                            </div>
                                                        </section>
                                                    </button>
                                                </section>
                                                <!-- [ngClass]="appPermissions[7]?.Create==0 ? 'disabled-click large-button' : 'large-button'" -->
                                                <section [ngClass]="isShownBulkInvoice==false ? 'disabled-click large-button' : 'large-button'">
                                                    <button mat-button class="large-button" (click)="CallingBulInvoices('Create+Email Bulk Invoice')">

                                                        <section class="large-button-container">
                                                            <mat-icon class="main-icon">
                                                                <img src="assets/icons/web_app/icon_invoice_new_d.ico">
                                                            </mat-icon>
                                                            <div class="main-text">
                                                                <span>Create +<br>Email Bulk Invoices</span>
                                                            </div>

                                                        </section>
                                                    </button>
                                                </section>
                                    </ng-container>
                        </div>
                    </mat-tab>
                    <!-- End Invoice tab menu -->
                    <!-- Spend Money Tab menu -->
                    <mat-tab label="SPEND MONEY" *ngIf="isTabShow===8">
                        <section class="ribbon-container contact_tab_main"
                            [style.width]="detailwidth">
                            <mat-card>
                                <mat-card-content>

                                    <section *ngIf="('Batch Payments' | tierPermission : 'Batch Payments').ALLOWED ==1"
                                    class="group-section-tab extras_tab">
                                    <section class="ribbon-section">
                                        <mat-nav-list>
                                            <a mat-list-item
                                                [ngClass]="activeSubMenu =='full-spend-money'?'cmn_active_tab':''"
                                                [routerLink]="'/spend-money/full-spend-money'">
                                                <mat-icon>
                                                    <img
                                                        src="assets/icons/web_app/icon_trust_d.ico">
                                                    <!-- <img src="assets/icons/web_app/icon_tagall_d.ico"> -->
                                                </mat-icon>
                                                <span>Spend Money</span>
                                            </a>
                                        </mat-nav-list>
                                        <mat-nav-list >
                                            <a mat-list-item
                                                [routerLink]="'/spend-money/batch-payment'"
                                                *ngIf="('Batch Payments' | tierPermission : 'Batch Payments').ALLOWED ==1"
                                                [ngClass]="{'cmn_active_tab':activeSubMenu =='batch-payment'}">
                                                <mat-icon>
                                                    <img
                                                        src="assets/icons/web_app/icon_trust_d.ico">
                                                </mat-icon>
                                                <span>Batch Payment</span>
                                            </a>
                                        </mat-nav-list>
                                    </section>
                                </section>
                                <mat-divider style="height: 110px;" vertical *ngIf="('Batch Payments' | tierPermission : 'Batch Payments').ALLOWED ==1"></mat-divider>
                                    <section
                                        class="group-section-tab action_tab" *ngIf="activeSubMenu =='full-spend-money'">
                                        <section
                                            [ngClass]="appPermissions[9]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[9]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.spendmoneypopup('new')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_expenses_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New<br> Spend</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[9]?.Edit==0 || isSpendMoneyData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[9]?.Edit==0 || isSpendMoneyData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.spendmoneypopup('edit')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_expenses_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Edit<br> Spend</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[9]?.Delete==0 || isSpendMoneyData =='empty')? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[9]?.Delete==0 || isSpendMoneyData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="Deletespendmoneypopup()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_expenses_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete<br> Spend</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section
                                            [ngClass]="(appPermissions[9]?.Create==0 || isSpendMoneyData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[9]?.Create==0 || isSpendMoneyData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.spendmoneypopup('duplicate')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_duplicate_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Duplicate<br>Spend
                                                            Money</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <div class="light_label_txt">Action</div>
                                    </section>
                                    <section
                                    class="group-section-tab action_tab" *ngIf="activeSubMenu =='batch-payment' && ('Batch Payments' | tierPermission : 'Batch Payments').ALLOWED ==1">
                                    <section
                                        [ngClass]="(appPermissions[9]?.Create==0 || isDownloadABAFile) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="(appPermissions[9]?.Create==0 || isDownloadABAFile) ? 'disabled-click large-button' : 'large-button'"
                                            (click)="BankingDialogOpenonly('new')">
                                            <section
                                                class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img
                                                        src="assets/icons/web_app/icon_expenses_new_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>New<br>Batch Payment</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section
                                        [ngClass]="(appPermissions[9]?.Edit==0 || isSpendMoneyData =='empty' || BatchPaymentData == null || BatchPaymentData == undefined || isDownloadABAFile) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="(appPermissions[9]?.Edit==0 || isSpendMoneyData =='empty' || BatchPaymentData == null || BatchPaymentData == undefined || isDownloadABAFile) ? 'disabled-click large-button' : 'large-button'"
                                            (click)="deleteBatchPayment('DELETE')">
                                            <section
                                                class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img
                                                        src="assets/icons/web_app/icon_expenses_edit_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Delete<br>Batch Payment</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section
                                        [ngClass]="(appPermissions[9]?.Delete==0 || isSpendMoneyData =='empty'|| BatchPaymentData == null || BatchPaymentData == undefined || isDownloadABAFile)? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="(appPermissions[9]?.Delete==0 || isSpendMoneyData =='empty'|| BatchPaymentData == null || BatchPaymentData == undefined || isDownloadABAFile) ? 'disabled-click large-button' : 'large-button'"
                                            (click)="DownloadBatchPayment()">
                                            <section
                                                class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img
                                                        src="assets/icons/web_app/download-multiple@2x.png">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Download<br>ABA File</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <mat-progress-bar mode="buffer"
                                    *ngIf="isDownloadABAFile"></mat-progress-bar>
                                    <div class="light_label_txt">Action</div>
                                </section>
                                    <mat-divider vertical></mat-divider>
                                </mat-card-content>
                            </mat-card>
                        </section>
                    </mat-tab>
                    <!-- End Spend Money Tab menu -->

                    <!-- Dashboard Start -->

                    <mat-tab label="DASHBOARD" *ngIf="isTabShow===29">
                        <div>
                            <!-- dashboard riboon -->
                            <!-- <mat-tab-group fxLayout="column" (selectedTabChange)="clickMatTab($event)" [(selectedIndex)]="selectedIndex"> -->
                            <!-- <mat-tab label=" MULTI DASHBOARD"> -->
                            <section #widgetsContent
                                class="ribbon-container goto_tab_main"
                                [style.width]="detailwidth">
                                <mat-card>
                                    <mat-card-content>
                                        <!-- Account Section Start-->
                                        <section class="group-section-tab">
                                            <section
                                                class="ribbon-section ribbon-section_1">
                                                <!-- <button mat-button class="large-button" (click)="setTab($event); targetNewTab('/dashboard/individual-dashboard', 'DASHBOARD');">
                                                     -->
                                                <button mat-button
                                                    class="large-button"
                                                    [routerLink]="'/dashboard/individual-dashboard'"
                                                    [ngClass]="(appPermissions[33] && appPermissions[33]?.['Individual Dashboard']==0)? 'disabled-click large-button' : 'large-button'">

                                                    <section
                                                        class="large-button-container">
                                                        <mat-icon class="main-icon1">
                                                            <!-- <img
                                                                src="assets/icons/web_app/Individual.png"> -->
                                                                <img src="assets/icons/web_app/3.svg" class="individual-iconblock" >
                                                        </mat-icon>
                                                        <div class="main-text">
                                                            <span>Individual</span>
                                                        </div>
                                                    </section>
                                                </button>
                                            </section>
                                            <section
                                                class="ribbon-section ribbon-section_2"
                                                *ngIf=" ('' | productType) && (('Teams Dashboard' | tierPermission : 'Teams Dashboard') && ('Teams Dashboard' | tierPermission : 'Teams Dashboard').ALLOWED ==1)">
                                                <!-- <button mat-button class="large-button" (click)="setTab($event); targetNewTab('/dashboard/teams-dashboard', 'FREEERNERDASHBOARD');"> -->
                                                <button mat-button
                                                    class="large-button"
                                                    [routerLink]="'/dashboard/teams-dashboard'"
                                                    [ngClass]="(appPermissions[33] && appPermissions[33]?.['Teams Dashboard']==0)? 'disabled-click large-button' : 'large-button'">

                                                    <section
                                                        class="large-button-container">
                                                        <mat-icon
                                                            class="main-icon1">
                                                            <!-- <img
                                                                src="assets/icons/web_app/Team.png"> -->
                                                                <img src="assets/icons/web_app/2.svg" >
                                                        </mat-icon>
                                                        <div class="main-text">
                                                            <!-- <span> Team<br>Dashboard</span> -->
                                                            <span>Team</span>
                                                        </div>
                                                    </section>
                                                </button>
                                            </section>
                                            <section *ngIf="(('Firm Dashboard' | tierPermission : 'Firm Dashboard') && ('Firm Dashboard' | tierPermission : 'Firm Dashboard').ALLOWED ==1) "
                                                class="ribbon-section ribbon-section_3">
                                                <!-- <button mat-button class="large-button" (click)="setTab($event); targetNewTab('/dashboard/frim-dashboard','firmdashboard');"> -->
                                                <button mat-button
                                                    class="large-button"
                                                    [routerLink]="'/dashboard/firm-dashboard'"
                                                    [ngClass]="(appPermissions[33] && appPermissions[33]?.['Firm Dashboard']==0)? 'disabled-click large-button' : 'large-button'">

                                                    <section
                                                        class="large-button-container">
                                                        <mat-icon
                                                            class="main-icon1">
                                                            <!-- <img
                                                                src="assets/icons/web_app/Firm.png"> -->
                                                                <img  src="assets/icons/web_app/1.svg" >
                                                        </mat-icon>
                                                        <div class="main-text">
                                                            <span> {{('' |
                                                                productType)?
                                                                "Firm" :"Practice"}}</span>
                                                        </div>
                                                    </section>
                                                </button>
                                            </section>
                                            <div class="light_label_txt">Dashboards</div>
                                        </section>
                                        <mat-divider vertical></mat-divider>
                                    </mat-card-content>
                                </mat-card>
                            </section>
                            <!-- </mat-tab> -->
                            <!-- </mat-tab-group> -->
                        </div>
                    </mat-tab>

                    <!-- dashboard end  -->
                    <!-- Receive Money Start tab menu start -->
                    <mat-tab label="RECEIVE MONEY"
                        *ngIf="isTabShow===9 || activeSubMenu =='time-billing/receipts-credits'">
                        <section class="ribbon-container contact_tab_main"
                            [style.width]="detailwidth">
                            <mat-card>
                                <mat-card-content>
                                    <section
                                        class="group-section-tab action_tab">
                                        <section
                                            [ngClass]="appPermissions[8]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[8]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.createReceipt('select_matter')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_receipt_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New Matter<br>
                                                            Receipt</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[8]?.Edit==0 || isReceiptData == 'empty')? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[8]?.Edit==0 || isReceiptData == 'empty')? 'disabled-click large-button' : 'large-button'"
                                                (click)="ViewReceipt('edit')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_receipt_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>{{receipTitle}}<br>
                                                            Receipt</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[8]?.Delete==0 || isReceiptData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[8]?.Delete==0 || isReceiptData == 'empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="deleteReceiceMoany()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_receipts_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete<br> Receipt</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[8]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[8]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="NewGeneralReceipt('new')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_receipt_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New General<br>
                                                            Receipt</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[8]?.Create==0 || isReceiptData == 'empty' || disable) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[8]?.Create==0 || isReceiptData == 'empty' || disable)? 'disabled-click large-button' : 'large-button'"
                                                (click)="NewGeneralReceipt('duplicate')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_duplicate_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Duplicate<br>General
                                                            Receipt</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Action</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>

                                     <!-- NEW -->
                                    <section *ngIf="('Document Generation' | tierPermission : 'Document Generation') && ('Document Generation' | tierPermission : 'Document Generation').ALLOWED == 1"
                                        class="group-section-tab action_tab">
                                        <section [ngClass]="isReceiptData == 'empty' ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'" *ngIf="('Document Generation' | tierPermission : 'Document Generation') && ('Document Generation' | tierPermission : 'Document Generation').ALLOWED == 1">
                                            <!-- <button [ngClass]="isReceiptData == 'empty' ? 'disabled-click-cursor large-button' : 'large-button'" mat-button class="large-button" (click)="isMatterClick()" (click)="setTab($event)" [routerLink]="'/create-document/receive-money-template'">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_letters_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Generate <br> Template</span>
                                                </div>
                                            </section>
                                        </button> -->
                                            <button mat-button
                                                [ngClass]="isReceiptData == 'empty' ? 'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.generateDocumentsFromMatters($event);SendEmail(false)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_letters_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Generate <br>
                                                            Documents</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="isReceiptData == 'empty' ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button
                                                [ngClass]="(appPermissions[5]?.Access==0 || isReceiptData == 'empty') ? 'disabled-click-cursor large-button' : 'large-button'"
                                                mat-button class="large-button"
                                                (click)="GenerateReceipt()"
                                                (click)="setTab($event)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_print_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Generate <br>Receipt</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Details</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                </mat-card-content>
                            </mat-card>
                        </section>
                    </mat-tab>
                    <!-- Receive Money Start tab menu End -->
                    <!-- Start Create Document menu -->
                    <mat-tab label="CREATE DOCUMENT" *ngIf="isTabShow===10">
                        <section class="ribbon-container contact_tab_main"
                            [style.width]="detailwidth">
                            <mat-card>
                                <mat-card-content>
                                    <section
                                        class="group-section-tab details_tab">
                                        <section class="ribbon-section">
                                            <button mat-button
                                                class="large-button"
                                                (click)="setTab($event);targetNewTab('/legal-details','LEGALDETAILS');">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_authorities_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Legal <br> Details</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section class="ribbon-section">
                                            <button mat-button
                                                class="large-button"
                                                (click)="setTab($event)"
                                                (click)="setTab($event);targetNewTab('/time-billing','TIMEANDBILLING')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_invoice_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Time &<br> Billing</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Go To</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                    <section
                                        class="group-section-tab action_tab">
                                        <section class="ribbon-section">
                                            <mat-nav-list>
                                                <!-- <button mat-button class="large-button"> -->
                                                <a mat-list-item
                                                    [routerLink]="TemplateUrlHandel"
                                                    [ngClass]="{'cmn_active_tab': (['safe-custody-template','matter-template','contact-template','invoice-template','receive-money-template','invoice-multiple-document'].indexOf(activeSubMenu) > -1)}">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_letters_small_d.ico">
                                                    </mat-icon>
                                                    <span>Templates </span>
                                                </a>
                                                <!-- added by web 19  -->
                                                <a
                                                    [ngClass]="{'cmn_active_tab': (['email-safe-custody-template','email-matter-template','email-contact-template','email-invoice-template','email-receive-money-template','email-invoice-multiple-document'].indexOf(activeSubMenu) > -1) ,'disabled-click-cursor':appPermissions[5]?.Access==0}"
                                                    mat-list-item
                                                    [routerLink]="emailrouting">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_email_d.ico">
                                                    </mat-icon>
                                                    <span>Emails </span>
                                                </a>
                                                <a
                                                    [ngClass]="{'cmn_active_tab': (['packs-safe-custody-template','packs-matter-template','packs-contact-template','packs-invoice-template','packs-receive-money-template','packs-invoice-multiple-document'].indexOf(activeSubMenu) > -1) ,'disabled-click-cursor':appPermissions[5]?.Access==0}"
                                                    mat-list-item
                                                    [routerLink]="packrouting">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_doc_pack_d.ico">
                                                    </mat-icon>
                                                    <span>Packs </span>
                                                </a>
                                            </mat-nav-list>
                                        </section>
                                        <section class="ribbon-section">
                                            <mat-nav-list>
                                                <a mat-list-item
                                                    [routerLink]="precedentsRouting"
                                                    *ngIf="currentUser?.PRODUCTTYPE!='Barrister'"
                                                    [ngClass]="{'cmn_active_tab': (['precedents-matter-template','precedents-invoice-template','precedents-contact-template','precedents-receive-money-template','precedents-safe-custody-template','precedents-invoice-multiple-document'].indexOf(activeSubMenu) > -1)}">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_letter_multi_d.ico">
                                                    </mat-icon>
                                                    <span> Precedents </span>
                                                </a>
                                                <a mat-list-item
                                                    [routerLink]="courtFormsRouting"
                                                    [ngClass]="{'cmn_active_tab': (['court-forms-safe-custody-template','court-forms-receive-money-template','court-forms-contact-template','court-forms-invoice-template','court-forms-matter-template','court-invoice-multiple-document'].indexOf(activeSubMenu) > -1)}">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_courtforms_d.ico">
                                                    </mat-icon>
                                                    <span> Court Forms </span>
                                                </a>
                                            </mat-nav-list>
                                        </section>
                                        <div class="light_label_txt">View</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                    <section
                                        class="group-section-tab action_tab">
                                        <section
                                            [ngClass]="appPermissions[5]?.Access==0 || isDocTempletFolder ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                            *ngIf="activeSubMenu ==='matter-template' || activeSubMenu ==='invoice-template' ||activeSubMenu ==='contact-template' ||activeSubMenu ==='document-register-template' || activeSubMenu ==='invoice-multiple-document'
                                        ||activeSubMenu ==='receive-money-template' || activeSubMenu ==='safe-custody-template' || packsToobar=='Template'">
                                            <button mat-button
                                                [ngClass]="appPermissions[5]?.Access==0 || isDocTempletFolder ? 'disabled-click large-button' : 'large-button'"
                                                (click)="GenarateDocument();SendEmail(false)"
                                                [disabled]="isDocumentGenerateHide =='yes' || isDocTempletFolder">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_letters_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Generate <br>
                                                            Document</span>

                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[5]?.Access==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                            *ngIf="activeSubMenu ==='email-matter-template' || activeSubMenu ==='email-invoice-template' ||activeSubMenu ==='email-contact-template' ||activeSubMenu ==='email-document-register-template' || activeSubMenu ==='email-invoice-multiple-document'
                                        ||activeSubMenu ==='email-receive-money-template' || activeSubMenu ==='email-safe-custody-template' || packsToobar=='Email' ">
                                            <button mat-button
                                                [ngClass]="appPermissions[5]?.Access==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="GenarateEmail()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_email_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Generate <br>
                                                            Email</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[5]?.Access==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                            *ngIf="(activeSubMenu ==='packs-matter-template' || activeSubMenu ==='packs-invoice-template' ||activeSubMenu ==='packs-contact-template' ||activeSubMenu ==='packs-document-register-template' ||activeSubMenu ==='packs-receive-money-template' || activeSubMenu ==='packs-invoice-multiple-document' || activeSubMenu ==='packs-safe-custody-template') && packsToobar == 'Packs'">
                                            <button mat-button
                                                [ngClass]="appPermissions[5]?.Access==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="GenaratePacks()"
                                                [disabled]="isDocumentGenerateHide =='yes'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_doc_pack_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Generate <br>Pack</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[5]?.Access==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                            *ngIf="(activeSubMenu ==='court-forms-matter-template' || activeSubMenu ==='court-forms-invoice-template' ||activeSubMenu ==='court-forms-contact-template' || activeSubMenu ==='court-forms-document-register-template'
                                    ||activeSubMenu ==='court-forms-receive-money-template' || activeSubMenu ==='court-forms-safe-custody-template' || activeSubMenu ==='court-invoice-multiple-document' ) && packsToobar == 'Packs'">
                                            <button
                                                (click)="GenarateCourtForm()"
                                                mat-button
                                                [ngClass]="appPermissions[5]?.Access==0 ? 'disabled-click large-button' : 'large-button'"
                                                [disabled]="isDocumentGenerateHide =='yes'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon
                                                        class="main-icon set-special-hige">
                                                        <img
                                                            src="assets/icons/web_app/icon_courtforms_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Generate <br>Form</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[5]?.Access==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'"
                                            *ngIf="(activeSubMenu ==='precedents-matter-template' || activeSubMenu ==='precedents-invoice-template' ||activeSubMenu ==='precedents-contact-template' || activeSubMenu ==='precedents-document-register-template'
                                ||activeSubMenu ==='precedents-receive-money-template' || activeSubMenu ==='precedents-safe-custody-template' || activeSubMenu ==='precedents-invoice-multiple-document' ) && packsToobar == 'Packs'">
                                            <button
                                                (click)="GenaratePrecedents()"
                                                mat-button
                                                [ngClass]="appPermissions[5]?.Access==0 ? 'disabled-click large-button' : 'large-button'"
                                                [disabled]="isDocumentGenerateHide =='yes'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon
                                                        class="main-icon set-special-hige">
                                                        <img
                                                            src="assets/icons/web_app/icon_letter_multi_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Generate <br>Precedents</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Generate</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                    <section
                                        class="group-section-tab action_tab"
                                        *ngIf="activeSubMenu ==='email-matter-template' || activeSubMenu ==='email-invoice-template' ||activeSubMenu ==='email-contact-template' ||activeSubMenu ==='email-document-register-template' || activeSubMenu ==='email-invoice-multiple-document'
                                    ||activeSubMenu ==='email-receive-money-template'  ||activeSubMenu ==='email-safe-custody-template' || packsToobar=='Email'">
                                        <section
                                            [ngClass]="appPermissions[5]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                (click)="EmailTempletePopUp('new')"
                                                [ngClass]="appPermissions[5]?.Create==0 ? 'disabled-click large-button' : 'large-button'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_email_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text"><span>New
                                                            Email<br> Template</span></div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[5]?.Edit==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                (click)="EmailTempletePopUp('edit')"
                                                [ngClass]="appPermissions[5]?.Edit==0 ? 'disabled-click large-button' : 'large-button'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_email_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Edit Email<br>
                                                            Template</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[5]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                (click)="EmailTempletePopUp('copy')"
                                                [ngClass]="appPermissions[5]?.Create==0 ? 'disabled-click large-button' : 'large-button'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_email_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Copy Email<br>
                                                            Template</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Action</div>
                                    </section>

                                    <section
                                        class="group-section-tab action_tab"
                                        *ngIf="activeSubMenu ==='matter-template' || activeSubMenu ==='invoice-template' || activeSubMenu ==='contact-template' || activeSubMenu ==='document-register-template' || activeSubMenu ==='invoice-multiple-document'
                                    ||activeSubMenu ==='receive-money-template' ||activeSubMenu ==='safe-custody-template' || packsToobar=='Template'">
                                        <!-- <section [ngClass]="appPermissions[5]?.Edit==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button [ngClass]="appPermissions[5]?.Edit==0 ?  'disabled-click large-button' : 'large-button'" (click)="EditTemplatePopup()">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_letters_edit_d.ico">
                                                </mat-icon>
                                                <div class="main-text"><span>Edit<br> Template</span></div>
                                            </section>
                                        </button>
                                    </section>
                                    <section [ngClass]="appPermissions[5]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button [ngClass]="appPermissions[5]?.Create==0 ? 'disabled-click large-button' : 'large-button'" (click)="CopyTemplatePopup()">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_letters_new_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Copy<br> Template</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section [ngClass]="appPermissions[7]?.Edit==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button [ngClass]="appPermissions[1]?.Edit==0 ? 'disabled-click large-button' : 'large-button'" (click)="SetLetterHeadPopup()">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_letters_edit_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Set<br>Letterhead</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section> -->

                                        <!-- <section [ngClass]="0!=0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button [ngClass]="0!=0 ? 'disabled-click large-button' : 'large-button'" (click)="UploadTemplates()">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_letters_edit_u.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Upload <br>Templates</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section> -->

                                        <section
                                            [ngClass]="0!=0 || isGenrateReport || isDocTempletFolder ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="0!=0 || isGenrateReport || isDocTempletFolder ? 'disabled-click large-button' : 'large-button'"
                                                (click)="DownloadTemplates('single')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/download@2x.png">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Download Single<br>Templates</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section
                                            [ngClass]="0!=0 || isGenrateReport  ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="0!=0  || isGenrateReport ? 'disabled-click large-button' : 'large-button'"
                                                (click)="DownloadTemplates('all')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/download-multiple@2x.png">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Download All<br>Templates</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section
                                            [ngClass]="isDocTempletType  ? 'ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="isDocTempletType ? 'large-button' : 'large-button'"
                                                (click)="createNewFolder('new')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/create_folder.png">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Create<br>New
                                                            Folder</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section
                                            [ngClass]="isDocTempletType  ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="isDocTempletType ? 'disabled-click large-button' : 'large-button'"
                                                (click)="createNewFolder('update')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/Rename48x48.png">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Rename<br>Folder</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="isDocTempletType  ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="isDocTempletType ? 'disabled-click large-button' : 'large-button'"
                                                (click)="createNewFolder('delete')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/close-circle48x48.png">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete<br>Folder</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="!isDocTempletType  ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="!isDocTempletType ? 'disabled-click large-button' : 'large-button'"
                                                (click)="createNewFolder('delete')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/close-circle48x48.png">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete<br>Template
                                                        </span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <div class="light_label_txt">Action</div>
                                        <mat-progress-bar mode="buffer"
                                            *ngIf="isGenrateReport"></mat-progress-bar>

                                    </section>
                                    <!-- Packs Start-->
                                    <section
                                        class="group-section-tab action_tab"
                                        *ngIf="(activeSubMenu ==='packs-matter-template' || activeSubMenu ==='packs-invoice-template' ||activeSubMenu ==='packs-contact-template' ||activeSubMenu ==='packs-document-register-template'
                                    ||activeSubMenu ==='packs-receive-money-template'||activeSubMenu ==='packs-safe-custody-template') && packsToobar == 'Packs'">
                                        <section
                                            [ngClass]="appPermissions[7]?.Delete==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                (click)="PackModule('new')"
                                                [ngClass]="appPermissions[1]?.Edit==0 ? 'disabled-click large-button' : 'large-button'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_doc_pack_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text"><span>New<br>
                                                            Pack</span></div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[7]?.Delete==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                (click)="PackModule('edit')"
                                                [ngClass]="appPermissions[1]?.Edit==0 ? 'disabled-click large-button' : 'large-button'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_doc_pack_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Edit<br> Pack</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[7]?.Delete==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                (click)="DeletePack()"
                                                [ngClass]="appPermissions[1]?.Edit==0 ? 'disabled-click large-button' : 'large-button'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_doc_pack_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete<br> Pack</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Action</div>
                                    </section>
                                    <!-- Packs End-->
                                    <!-- court form start -->
                                    <!-- <section class="group-section-tab action_tab"
                                *ngIf="activeSubMenu ==='court-forms-matter-template' || activeSubMenu ==='court-forms-invoice-template' ||activeSubMenu ==='court-forms-contact-template' || activeSubMenu ==='court-forms-document-register-template'
                                ||activeSubMenu ==='court-forms-receive-money-template' ||activeSubMenu ==='court-forms-safe-custody-template' || packsToobar=='Template'">
                                <section
                                    [ngClass]="appPermissions[5]?.Edit==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                    <button mat-button
                                        [ngClass]="appPermissions[5]?.Edit==0 ?  'disabled-click large-button' : 'large-button'"
                                        >
                                        <section class="large-button-container">
                                            <mat-icon class="main-icon">
                                                <img src="assets/icons/web_app/icon_courtforms_d.ico"
                                                    >
                                            </mat-icon>
                                            <div class="main-text"><span>Load Court<br>Forms</span></div>
                                        </section>
                                    </button>
                                </section>


                                <div class="light_label_txt">Action</div>
                            </section> -->
                                    <!-- court form end  -->
                                </mat-card-content>
                            </mat-card>
                        </section>
                    </mat-tab>
                    <!-- End  Start Create Document menu  -->
                    <!-- User tab Start-->

                    <!-- <mat-tab label="USERS" *ngIf="isTabShow===12">
                    <section class="ribbon-container contact_tab_main" [style.width]="detailwidth"
                        >
                        <mat-card>
                            <mat-card-content>
                                <section class="group-section-tab action_tab">


                                    <section class="userMenuContainer">
                                        <div class="nav-item name userMenuNavItem selUserMenuItem" aria-label="inbox"
                                            (click)="backToUser();" style="height: 30px;">

                                            <mat-icon class="user-menu-icon-container">
                                                <img src="assets/icons/users.png" class="user-menu-icon">
                                            </mat-icon>
                                            <span class="nav-item-link" matRipple>
                                                <span class="title">Users 1</span>
                                            </span>
                                        </div>
                                        <div class="nav-item name userMenuNavItem" aria-label="inbox"
                                            (click)="UserActivityClick();" style="height: 30px;">
                                            <mat-icon class="user-menu-icon-container">
                                                <img src="assets/icons/web_app/icon_time_cost_d.ico"
                                                    class="user-menu-icon">
                                            </mat-icon>
                                            <span class="nav-item-link" matRipple>
                                                <span class="title">User Activity</span>
                                            </span>
                                        </div>

                                    </section>

                                    <mat-divider vertical></mat-divider>


                                    <section
                                        [ngClass]="appPermissions[29]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="appPermissions[29]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                            (click)="userDialog('new')">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_add_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>New<br> User</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section
                                        [ngClass]="(appPermissions[29]?.Edit==0 || isUserData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="(appPermissions[29]?.Edit==0 || isUserData =='empty')? 'disabled-click large-button' : 'large-button'"
                                            (click)="userDialog('edit')" id="editUserBtn">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_edit_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Edit<br> User</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section
                                        [ngClass]="(appPermissions[29]?.Delete==0 || isUserData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="(appPermissions[29]?.Delete==0 || isUserData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                            (click)="DeleteUser()">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_delete_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Delete<br> User</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section
                                        [ngClass]="(appPermissions[29]?.Create==0 || isUserData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="(appPermissions[29]?.Create==0 || isUserData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                            (click)="userDialog('duplicate')">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_duplicate_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Duplicate<br> User</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>

                                    <section class="ribbon-section">
                                        <button mat-button class="large-button" (click)="loggedOnUserDialog()">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/blogger.png">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Logged On<br> Users</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>

                                    <div class="light_label_txt">Action</div>
                                </section>
                                <mat-divider vertical></mat-divider>
                            </mat-card-content>
                        </mat-card>
                    </section>
                </mat-tab> -->
                    <!-- User tab End-->
                    <!-- Activity tab Start-->
                    <mat-tab label="Activities" *ngIf="isTabShow===13">
                        <section class="ribbon-container contact_tab_main"
                            [style.width]="detailwidth">
                            <mat-card>
                                <mat-card-content>
                                    <section
                                        class="group-section-tab action_tab">
                                        <section
                                            [ngClass]="appPermissions[30]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[30]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="ActivitySundryDialog('new')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New<br> Activity</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[30]?.Edit==0 || isActivityData=='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[30]?.Edit==0 || isActivityData=='empty')? 'disabled-click large-button' : 'large-button'"
                                                (click)="ActivitySundryDialog('edit')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Edit<br> Activity</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[30]?.Delete==0 || isActivityData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[30]?.Delete==0 || isActivityData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="DeleteActivityDialog()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete<br>
                                                            Activity</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[30]?.Create==0 || isActivityData=='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[30]?.Create==0 || isActivityData=='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="ActivitySundryDialog('Duplicate')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_duplicate_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Duplicate<br>
                                                            Activity</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Action</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                    <mat-divider vertical></mat-divider>
                                </mat-card-content>
                            </mat-card>
                        </section>
                    </mat-tab>
                    <!-- Activity tab End-->
                    <!-- Document Register tab Start-->
                    <mat-tab label="DOCUMENT REGISTER" *ngIf="isTabShow===14">
                        <section class="ribbon-container legal_details_tab_main"
                            [style.width]="detailwidth">
                            <mat-card>
                                <mat-card-content>
                                    <section
                                        class="group-section-tab details_tab"
                                        *ngIf="!matterDetailsFlag">
                                        <section class="ribbon-section">
                                            <button mat-button
                                                class="large-button"
                                                (click)="setTab($event);targetNewTab('/legal-details','LEGALDETAILS');">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_authorities_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Legal <br> Details</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section class="ribbon-section">
                                            <button mat-button
                                                class="large-button"
                                                (click)="setTab($event)"
                                                (click)="setTab($event);targetNewTab('/time-billing','TIMEANDBILLING')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_invoice_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Time &<br> Billing</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Go To</div>
                                    </section>
                                    <mat-divider vertical
                                        *ngIf="!matterDetailsFlag"></mat-divider>
                                    <section
                                        class="group-section-tab action_tab">
                                        <section
                                            [ngClass]="appPermissions[6]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[6]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="DocumntPop('new')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_doc_reg_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New Doc<br>Record</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[6]?.Edit==0 || isDocRegData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[6]?.Edit==0 || isDocRegData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="DocumntPop('edit')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_doc_reg_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Edit Doc<br>Record</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[6]?.Delete==0 || isDocRegData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[6]?.Delete==0 || isDocRegData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="DeleteDocument()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_doc_reg_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete Doc<br>Record</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[6]?.Create==0 || isDocRegData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[6]?.Create==0 || isDocRegData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="DocumntPop('duplicate')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_duplicate_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Duplicate Doc<br>Record</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Action</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                    <section
                                        class="group-section-tab details_tab">
                                        <section
                                            [ngClass]="(appPermissions[7]?.Delete==0||isGenrateReport) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[1]?.Edit==0||isGenrateReport) ? 'disabled-click large-button' : 'large-button'"
                                                (click)="OpenMatter('MATTERFOLDER','matter_document')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/folder_large.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Open Matter<br>Folder</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[7]?.Delete==0 ||isGenrateReport || isDocRegData =='empty')? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[1]?.Edit==0||isGenrateReport || isDocRegData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="OpenMatter('DOCUMENT','safecustody')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_letters_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Open<br>Document</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section
                                            [ngClass]="(appPermissions[7]?.Delete==0 || isGenrateReport || isDocRegData =='empty' || !isOpenDesktopWord )? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[7]?.delete==0|| isGenrateReport || isDocRegData =='empty' || !isOpenDesktopWord) ? 'disabled-click large-button' : 'large-button'"
                                                (click)="OpenInDesktopWord()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/Microsoft_Word_logo.png">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Open<br>in Desktop
                                                            Word</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section
                                            [ngClass]="(appPermissions[7]?.Delete==0 ||isGenrateReport || isDocRegData =='empty')? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[1]?.Edit==0||isGenrateReport || isDocRegData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="setTab($event)"
                                                [routerLink]="'/create-document/email-document-register-template'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_email_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Email<br>Document</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <mat-progress-bar mode="buffer"
                                            *ngIf="isGenrateReport"></mat-progress-bar>
                                        <div class="light_label_txt">Details</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                    <section
                                        class="group-section-tab details_tab">
                                        <section
                                            [ngClass]="(isGenrateReport) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(isGenrateReport) ? 'disabled-click large-button' : 'large-button'"
                                                (click)="OpenMatter('LOADDOCUMENTS','')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_doc_reg_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Load<br>Document</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[6]?.Edit==0 || isDocRegData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[6]?.Edit==0 || isDocRegData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="DocumntPop('rename')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_doc_reg_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Rename/Move <br>Document</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[6]?.Edit==0 || isDocRegData =='empty' || DocRegData.type=='pdf') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[6]?.Edit==0 || isDocRegData =='empty' || DocRegData.type=='pdf') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="PdfDocument()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/pdf.png">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>PDF<br>Document</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[6]?.Edit==0 || isDocRegData =='empty' || DocRegData.type=='pdf') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[6]?.Edit==0 || isDocRegData =='empty' || DocRegData.type=='pdf') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="PdfEmailDocument()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/email.png">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>PDF & Email<br>Document</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <mat-progress-bar mode="buffer"
                                            *ngIf="isGenrateReport"></mat-progress-bar>
                                        <div class="light_label_txt">Extras</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                </mat-card-content>
                            </mat-card>
                        </section>
                    </mat-tab>
                    <!-- Document Register tab End-->
                    <!-- Chart Of Account tab Start-->
                    <mat-tab label="{{ShowMatLable}}" *ngIf="isTabShow===15">
                        <!-- <mat-tab label="TRUST CHART OF ACCOUNTS" *ngIf="isTabShow===15 && ShowMatLable =='WithTrust'"> -->
                        <section class="ribbon-container legal_details_tab_main"
                            [style.width]="detailwidth">
                            <mat-card>
                                <mat-card-content>
                                    <section
                                        class="group-section-tab action_tab">
                                        <section
                                            [ngClass]="(appPermissions[19]?.Create==0 || (ClickTypeForTrustChartHD == 'WithTrust' &&  accountClassName !='Controlled Money Account')) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[19]?.Create==0 || (ClickTypeForTrustChartHD == 'WithTrust' && accountClassName !='Controlled Money Account')) ? 'disabled-click large-button' : 'large-button'"
                                                (click)="AccountPop('new')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_add_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New<br>Account</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <!-- (appPermissions[19]?.Edit==0  || ClickTypeForTrustChartHD == 'WithTrust' && (accountClassName !='Controlled Money Account' && AccountId !='1000' ) -->
                                        <section
                                            [ngClass]="(appPermissions[19]?.Edit==0  || !isAccountEditable) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[19]?.Edit==0  || !isAccountEditable) ? 'disabled-click large-button' : 'large-button'"
                                                (click)="AccountPop('edit')">

                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text"><span>Edit
                                                            <br>Account</span></div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[19]?.Delete==0 || isMainAccount  || ClickTypeForTrustChartHD == 'WithTrust') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[19]?.Delete==0 || isMainAccount || ClickTypeForTrustChartHD == 'WithTrust') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="DeleteAccount()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text"><span>Delete<br>Account</span></div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            *ngIf="accountClassName!='Controlled Money Account'"
                                            [ngClass]="(appPermissions[19]?.Create==0 || isMainAccount || AccountClassForTrustChartHD =='Controlled Money Account') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[19]?.Create==0 || isMainAccount ||  AccountClassForTrustChartHD =='Controlled Money Account') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="AccountPop('duplicate')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_duplicate_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text"><span>Duplicate<br>Account</span></div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Action</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                    <section
                                        class="group-section-tab action_tab">
                                        <section
                                            *ngIf="ClickTypeForTrustChartHD != 'WithTrust'"
                                            [ngClass]="isMainAccount || !isMainAccount && appPermissions[21] && appPermissions[21]['Account Transactions']==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [routerLink]="'/account/transactions'"
                                                [ngClass]="isMainAccount || !isMainAccount && appPermissions[21] && appPermissions[21]['Account Transactions']==0 ? 'disabled-click large-button' : 'large-button'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_arrowdown_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Account<br>Transactions</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            *ngIf="ClickTypeForTrustChartHD == 'WithTrust'"
                                            [ngClass]="isMainAccount || !isMainAccount && appPermissions[21] && appPermissions[21]['Account Transactions']==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [routerLink]="'/account/trust-transactions'"
                                                [ngClass]="isMainAccount || !isMainAccount && appPermissions[21] && appPermissions[21]['Account Transactions']==0 ? 'disabled-click large-button' : 'large-button'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_arrowdown_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Account<br>Transactions</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            *ngIf="ClickTypeForTrustChartHD != 'WithTrust'"
                                            [ngClass]="(chartAccountDetail?.ACCOUNTTYPE!=3 || isMainAccount )  ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(chartAccountDetail?.ACCOUNTTYPE!=3 || isMainAccount)  ? 'disabled-click large-button' : 'large-button'"
                                                (click)="setTab($event); targetNewTabByAccount('/account-reconciliation', 'ACCOUNTRECONCILIATION')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_receipt_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Reconcile<br>Account</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            *ngIf="ClickTypeForTrustChartHD == 'WithTrust'"
                                            [ngClass]="((chartAccountDetail?.ACCOUNTCLASSNAME !='Trust Account' && chartAccountDetail?.ACCOUNTCLASSNAME !='Controlled Money Account')  || isMainAccount )  ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="((chartAccountDetail?.ACCOUNTCLASSNAME !='Trust Account' && chartAccountDetail?.ACCOUNTCLASSNAME !='Controlled Money Account') || isMainAccount)  ? 'disabled-click large-button' : 'large-button'"
                                                (click)="setTab($event)"
                                                [routerLink]="'/account-reconciliation'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_receipt_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Reconcile <br>Account</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Details</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                    <section
                                        class="group-section-tab action_tab">
                                        <section
                                            *ngIf="ClickTypeForTrustChartHD != 'WithTrust' && ('Xero Integration' | tierPermission : 'Xero Integration').ALLOWED ==1"
                                            [ngClass]="'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="'large-button'"
                                                (click)="SyncToXero()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/xero.ico"
                                                            matTooltip
                                                            matTooltipPosition="above">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Sync to<br>Xero</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            *ngIf="ClickTypeForTrustChartHD != 'WithTrust' && (('MYOB Integration' | tierPermission : 'MYOB Integration') && ('MYOB Integration' | tierPermission : 'MYOB Integration').ALLOWED ==1)"
                                            [ngClass]="appPermissions[21]['Export Accounts']==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[21]['Export Accounts']==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="exportData('MYOB')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/myob.png"
                                                            matTooltip
                                                            matTooltipPosition="above">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Export to<br> MYOB</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            *ngIf="ClickTypeForTrustChartHD != 'WithTrust'"
                                            [ngClass]="appPermissions[21]['Export Accounts']==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[21]['Export Accounts']==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="exportData('QuickBooks')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/quickbook.png"
                                                            matTooltip
                                                            matTooltipPosition="above">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Export to<br>
                                                            QuickBooks</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            *ngIf="ClickTypeForTrustChartHD != 'WithTrust'"
                                            [ngClass]="appPermissions[21]['Export Accounts']==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <!-- (click)="BankPop()" -->
                                            <button mat-button
                                                id="connectBankFeedWindow"
                                                [ngClass]="('Bank Feeds' | tierPermission : 'Bank Feeds') && ('Bank Feeds' | tierPermission : 'Bank Feeds').ALLOWED == 0 ? 'disabled-click large-button' : 'large-button'"
                                                [routerLink]="'/bank-feed/bank-feed-dashboard'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_add_d.ico"
                                                            matTooltip
                                                            matTooltipPosition="above">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <!-- <span>Connect <br>Bank
                                                            Feed</span> -->
                                                            <span>Bank <br>Feeds</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Sync Data</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                </mat-card-content>
                            </mat-card>
                        </section>
                    </mat-tab>
                    <!-- Chart Of Account tab End-->
                    <!-- TRUST ACCOUNT TRANSACTIONS && ACCOUNT TRANSACTIONS tab Start-->
                    <mat-tab label="{{ShowTranstionsLable}}"
                        *ngIf="isTabShow===32">
                        <!-- <mat-tab label="TRUST CHART OF ACCOUNTS" *ngIf="isTabShow===15 && ShowMatLable =='WithTrust'"> -->
                        <section class="ribbon-container legal_details_tab_main"
                            [style.width]="detailwidth">
                            <mat-card>
                                <mat-card-content>
                                    <section
                                        class="group-section-tab action_tab">
                                        <section
                                            *ngIf="ClickTypeForTrustChartHD != 'WithTrust'"
                                            [ngClass]="appPermissions[19]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                (click)="ViewDetails()"
                                                [ngClass]="appPermissions[19]?.Create==0 ? 'disabled-click large-button' : 'large-button'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Details</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <!-- <section *ngIf="ClickTypeForTrustChartHD == 'WithTrust'"
                                        [ngClass]="appPermissions[19]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button (click)="ViewDetails()"
                                            [ngClass]="appPermissions[19]?.Create==0 ? 'disabled-click large-button' : 'large-button'">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_edit_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Details</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section> -->
                                        <div class="light_label_txt">Details</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                </mat-card-content>
                            </mat-card>
                        </section>
                    </mat-tab>
                    <!-- TRUST ACCOUNT TRANSACTIONS && ACCOUNT TRANSACTIONS tab End-->
                    <!-- General Journal tab Start-->
                    <mat-tab label="{{ShowMatLable}}" *ngIf="isTabShow===16">
                        <section class="ribbon-container legal_details_tab_main"
                            [style.width]="detailwidth">
                            <mat-card>
                                <mat-card-content>
                                    <section
                                        class="group-section-tab details_tab">
                                        <section
                                            *ngIf="isClickTypeTrust!='WithTrust'"
                                            [ngClass]="appPermissions[20]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[20]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="NewGeneral('new')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_add_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[20]?.Edit==0  || isGeneralJounralData =='empty')? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[20]?.Edit==0  || isGeneralJounralData =='empty')? 'disabled-click large-button' : 'large-button'"
                                                (click)="ViewDetails()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>{{journalText}}<br>Details</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            *ngIf="isClickTypeTrust!='WithTrust'"
                                            [ngClass]="(appPermissions[20]?.Delete==0 || isGeneralJounralData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[20]?.Delete==0 || isGeneralJounralData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="DeleteGeneral()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            *ngIf="isClickTypeTrust!='WithTrust'"
                                            [ngClass]="(appPermissions[20]?.Create==0 || isGeneralJounralData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[20]?.Create==0 || isGeneralJounralData =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="NewGeneral('duplicate')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_duplicate_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Duplicate</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Action</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                    <section
                                        class="group-section-tab action_tab">
                                        <section *ngIf="appPermissions[20]"
                                            [ngClass]="(appPermissions[20]['Print']==0 || isGeneralJounralData =='empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                *ngIf="appPermissions[20]"
                                                [ngClass]="(appPermissions[20]['Print']==0 || isGeneralJounralData =='empty')? 'disabled-click large-button' : 'large-button'"
                                                (click)="PrintGj('withtrust')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_print_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Print<br>GJ</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Details</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                </mat-card-content>
                            </mat-card>
                        </section>
                    </mat-tab>
                    <!-- General Journal tab End-->
                    <!-- Confict Check Start-->
                    <mat-tab label="CONFLICT CHECK" *ngIf="isTabShow===17">
                        <section class="ribbon-container matter_tab_main"
                            [style.width]="detailwidth">
                            <mat-card>
                                <mat-card-content>
                                    <section
                                        class="group-section-tab action_tab">
                                        <!-- <span class="action-section">Action</span> -->

                                        <section
                                            [ngClass]="appPermissions[1]?.Edit==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                (click)="ConflictStart()"
                                                [ngClass]="appPermissions[1]?.Edit==0 ? 'disabled-click large-button' : 'large-button'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/Select.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Start</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Action</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                    <section
                                        [ngClass]="(appPermissions[1]?.Edit==0 || disconflictToolbar=='yes') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button
                                            [disabled]="disconflictToolbar=='yes'"
                                            mat-button
                                            (click)="toolbarServiceService.matterpopup('edit')"
                                            [ngClass]="(appPermissions[1]?.Edit==0 || disconflictToolbar=='yes') ? 'disabled-click large-button' : 'large-button'">
                                            <section
                                                class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img
                                                        src="assets/icons/web_app/icon_matter_edit_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Edit<br> Matter</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section
                                        class="group-section-tab details_tab">
                                        <section
                                            [ngClass]="(appPermissions[7]?.Delete==0 || disconflictToolbar=='yes') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button
                                                [disabled]="disconflictToolbar=='yes'"
                                                mat-button
                                                [ngClass]="(appPermissions[1]?.Edit==0 || disconflictToolbar=='yes') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="setTab($event);targetNewTab('/time-billing','TIMEANDBILLING')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_invoice_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Time &<br> Billing</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[7]?.Delete==0 || disconflictToolbar=='yes')  ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button
                                                [disabled]="disconflictToolbar=='yes'"
                                                mat-button
                                                [ngClass]="(appPermissions[1]?.Edit==0  || disconflictToolbar=='yes')? 'disabled-click large-button' : 'large-button'"
                                                (click)="setTab($event)"
                                                [routerLink]="'/legal-details'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_authorities_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Legal<br> Details</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Details</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                    <section
                                        class="group-section-tab details_tab">
                                        <section class="ribbon-section">
                                            <button mat-button
                                                class="large-button"
                                                (click)="setTab($event);targetNewTab('/matters','MATTERS');toolbarServiceService.matterpopup('conflicNewMatter');">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_close_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Close</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Close</div>
                                    </section>
                                </mat-card-content>
                            </mat-card>
                        </section>
                    </mat-tab>
                    <!-- Confict Check End-->
                    <!-- Global Authorities Start-->
                    <mat-tab label="AUTHORITIES" *ngIf="isTabShow===18">
                        <section class="ribbon-container contact_tab_main"
                            [style.width]="detailwidth">
                            <mat-card>
                                <mat-card-content>
                                    <section
                                        class="group-section-tab extras_tab">
                                        <section class="ribbon-section">
                                            <mat-nav-list>
                                                <a mat-list-item
                                                    [routerLink]="'authorities/full-authorities'"
                                                    [ngClass]="activeSubMenu =='full-authorities'?'cmn_active_tab':''">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_tagall_d.ico">
                                                    </mat-icon>
                                                    <span>Authorities</span>
                                                </a>
                                                <a mat-list-item
                                                    [ngClass]="activeSubMenu =='topic'?'cmn_active_tab':''"
                                                    [routerLink]="'authorities/topic'">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_trust_d.ico">
                                                    </mat-icon>
                                                    <span>Topic</span>
                                                </a>
                                            </mat-nav-list>
                                        </section>
                                        <div class="light_label_txt">View</div>
                                    </section>
                                    <!-- <section class="group-section-tab action_tab">
                                    <section [ngClass]="appPermissions[12]?.Access==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button [ngClass]="appPermissions[12]?.Access==0 ? 'disabled-click large-button' : 'large-button'" (click)="setTab($event)" [routerLink]="'/legal-details/authority'">
                                               <section class="large-button-container">
                                                  <mat-icon class="main-icon">
                                                        <img src="assets/icons/web_app/icon_authorities_d.ico"  >
                                                  </mat-icon>
                                                  <div class="main-text">
                                                        <span>Authorities</span>
                                                  </div>
                                               </section>
                                            </button>

                                    </section>
                                    <div class="light_label_txt">View</div>
                                </section> -->
                                    <mat-divider vertical></mat-divider>

                                    <section
                                        class="group-section-tab action_tab"
                                        *ngIf="activeSubMenu=='full-authorities'">
                                        <section
                                            [ngClass]="appPermissions[12]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[12]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="AuthorityDialog('new')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_authorities_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New <br>Authority</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[12]?.Edit==0 ||  DisMainAuthorityToolbar == 'Autho_no' )? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[12]?.Edit==0 ||  DisMainAuthorityToolbar == 'Autho_no' ) ? 'disabled-click large-button' : 'large-button'"
                                                (click)="AuthorityDialog('edit')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_authorities_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Edit<br> Authority</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section
                                            [ngClass]="(appPermissions[12]?.Delete==0 ||  DisMainAuthorityToolbar == 'Autho_no' ) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[12]?.Delete==0 ||  DisMainAuthorityToolbar == 'Autho_no' ) ? 'disabled-click large-button' : 'large-button'"
                                                (click)="deleteAuthority()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_authorities_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete<br>
                                                            Authority</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section
                                            [ngClass]="(appPermissions[12]?.Create==0 ||  DisMainAuthorityToolbar == 'Autho_no' ) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[12]?.Create==0 ||  DisMainAuthorityToolbar == 'Autho_no' ) ? 'disabled-click large-button' : 'large-button'"
                                                (click)="AuthorityDialog('duplicate')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_duplicate_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Duplicate<br>
                                                            Authority</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Action</div>
                                    </section>
                                    <section
                                        class="group-section-tab action_tab"
                                        *ngIf="activeSubMenu=='topic'">
                                        <section
                                            [ngClass]="appPermissions[11]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[11]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="TopicDialog('new')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_add_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New <br>Topic</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[12]?.Edit==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[12]?.Edit==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="TopicDialog('edit')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Edit<br> Topic</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[11]?.Delete==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[11]?.Delete==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="DeleteTopicData()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete<br> Topic</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[11]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[11]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="TopicDialog('duplicate')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_duplicate_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Duplicate<br>
                                                            Topic</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Action</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>

                                    <section
                                        class="group-section-tab action_tab"
                                        *ngIf="activeSubMenu=='full-authorities'">
                                        <section
                                            *ngIf="mainlegalAuthWebUrl == ''"
                                            [ngClass]="(appPermissions[12]?.Create==0 || mainlegalAuthWebUrl =='')? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[12]?.Create==0 || mainlegalAuthWebUrl =='')? 'disabled-click large-button' : 'large-button'"
                                                (click)="setTab($event)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_authorities_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Open Master <br />Site/File</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            *ngIf="mainlegalAuthWebUrl != ''"
                                            [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[12]?.Acess==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="setTab($event)">
                                                <a target="_blank"
                                                    href="{{mainlegalAuthWebUrl}}"
                                                    mat-list-item>
                                                    <section
                                                        class="large-button-container">
                                                        <mat-icon
                                                            class="main-icon">
                                                            <img
                                                                src="assets/icons/web_app/icon_authorities_d.ico">
                                                        </mat-icon>
                                                        <div class="main-text">
                                                            <span>Open Master
                                                                <br />Site/File</span>
                                                        </div>
                                                    </section>
                                                </a>
                                            </button>
                                        </section>

                                        <mat-divider vertical></mat-divider>
                                        <div class="light_label_txt">Details</div>
                                    </section>
                                </mat-card-content>
                            </mat-card>
                        </section>
                    </mat-tab>
                    <!-- Global Authorities End-->
                    <!-- searching start  -->
                    <mat-tab label="SEARCHING" *ngIf="isTabShow===19">
                        <section class="ribbon-container contact_tab_main"
                            [style.width]="detailwidth">
                            <mat-card>
                                <mat-card-content>
                                    <section
                                        class="group-section-tab action_tab">
                                        <section
                                            [ngClass]="(appPermissions[16]?.Access==0||isGenrateReport)? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[16]?.Access==0||isGenrateReport)  ? 'disabled-click large-button' : 'large-button'"
                                                (click)="StartInfoDialog('matter')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/infotrack.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Start InfoTrack
                                                            <br> Search</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[16]?.Access==0||isGenrateReport)? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[16]?.Access==0||isGenrateReport)  ? 'disabled-click large-button' : 'large-button'"
                                                (click)="StartInfoDialog('WebVOI')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/infotrack.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Start <br> WebVOI</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[16]?.Access==0||isGenrateReport)? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[16]?.Access==0||isGenrateReport)  ? 'disabled-click large-button' : 'large-button'"
                                                (click)="StartInfoDialog('CourtSync')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/infotrack.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Start<br>CourtSync</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[16]?.Access==0 || isSerachingDataShow||isGenrateReport)? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                (click)="viewOnlineDocument('download','')"
                                                [ngClass]="(appPermissions[16]?.Access==0 || isSerachingDataShow||isGenrateReport) ? 'disabled-click large-button' : 'large-button'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_search_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Open<br>file</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section
                                            [ngClass]="(appPermissions[16]?.Access==0 || isSerachingData?.AVAILABLEONLINE=='No' || isSerachingDataShow ||isGenrateReport)  ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[16]?.Access==0 || isSerachingData?.AVAILABLEONLINE=='No' || isSerachingDataShow ||isGenrateReport)  ? 'disabled-click large-button' : 'large-button'"
                                                (click)="viewOnlineDocument('online','')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/infotrack.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>View <br>Online</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <mat-progress-bar mode="buffer"
                                            *ngIf="isGenrateReport"></mat-progress-bar>
                                        <div class="light_label_txt">Action</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                </mat-card-content>
                            </mat-card>
                        </section>
                    </mat-tab>
                    <!-- searching end  -->
                    <!-- ACCOUNTS RECONCILIATION Start-->
                    <mat-tab label="{{recouncileTab}}" *ngIf="isTabShow===20">
                        <section class="ribbon-container legal_details_tab_main"
                            [style.width]="detailwidth">
                            <mat-card>
                                <mat-card-content>
                                    <!-- <section class="group-section-tab extras_tab">
                                    <section class="ribbon-section">
                                        <mat-nav-list>
                                            <a mat-list-item
                                                [routerLink]="'/account-reconciliation/reconciliation-item'"
                                                [ngClass]="activeSubMenu =='reconciliation-item'  ? 'cmn_active_tab':''">
                                                <mat-icon>
                                                    <img src="assets/icons/web_app/icon_tagitem_d.ico">
                                                </mat-icon>
                                                <span>Reconciliation Items</span>
                                            </a>

                                            <a mat-list-item
                                                [routerLink]="'/account-reconciliation/past-reconciliation'"
                                                [ngClass]="activeSubMenu =='past-reconciliation'  ? 'cmn_active_tab':''">
                                                <mat-icon>
                                                    <img src="assets/icons/web_app/icon_tagitem_d.ico">
                                                </mat-icon>
                                                <span>Past Reconciliations</span>
                                            </a>
                                        </mat-nav-list>
                                    </section>
                                    <section class="ribbon-section">
                                        <mat-nav-list>
                                            <a mat-list-item [routerLink]="'/account-reconciliation/past-banking'"
                                                [ngClass]="activeSubMenu =='past-banking'  ? 'cmn_active_tab':''">
                                                <mat-icon>
                                                    <img src="assets/icons/web_app/icon_receipt_d.ico">
                                                </mat-icon>
                                                <span>Past Bankings</span>
                                            </a>
                                        </mat-nav-list>
                                    </section>
                                    <div class="light_label_txt">View</div>
                                </section> -->
                                    <!-- <mat-divider vertical></mat-divider> -->
                                    <section
                                        class="group-section-tab extras_tab"
                                        *ngIf="activeSubMenu == 'reconciliation-item'">
                                        <section
                                            *ngIf="isStoreTrustVal && isStoreTrustVal.ClickType && (isStoreTrustVal.ClickType == 'WithoutTrust')"
                                            [ngClass]="appPermissions[9]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button
                                                (click)="toolbarServiceService.spendmoneypopup('new')"
                                                mat-button
                                                [ngClass]="appPermissions[9]?.Create==0 ? 'disabled-click large-button' : 'large-button'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_expenses_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New<br>Spend Money</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            *ngIf="isStoreTrustVal && isStoreTrustVal.ClickType && (isStoreTrustVal.ClickType == 'WithoutTrust')"
                                            [ngClass]="appPermissions[8]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button
                                                (click)="NewGeneralReceipt('add')"
                                                mat-button
                                                [ngClass]="appPermissions[8]?.Create==0 ? 'disabled-click large-button' : 'large-button'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_receipt_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New General<br>Receipt</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <!-- ******************************* -->
                                        <mat-divider vertical></mat-divider>
                                        <section
                                            *ngIf="isStoreTrustVal && isStoreTrustVal.ClickType && (isStoreTrustVal.ClickType == 'WithTrust')"
                                            [ngClass]="appPermissions[9]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button
                                                (click)="TrustRecouncileitemPopup('Withdrawal')"
                                                mat-button
                                                [ngClass]="appPermissions[9]?.Create==0 ? 'disabled-click large-button' : 'large-button'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_expenses_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Withdrawal<br>Adjustment</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            *ngIf="isStoreTrustVal && isStoreTrustVal.ClickType && (isStoreTrustVal.ClickType == 'WithTrust')"
                                            [ngClass]="appPermissions[8]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button
                                                (click)="TrustRecouncileitemPopup('Deposit')"
                                                mat-button
                                                [ngClass]="appPermissions[8]?.Create==0 ? 'disabled-click large-button' : 'large-button'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_receipt_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Deposit<br>Adjustment</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section class="ribbon-section">
                                            <button
                                                (click)="RecouncileAccount()"
                                                class="large-button" mat-button>
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_save_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Reconcile<br>Account</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Action</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                    <section
                                        class="group-section-tab extras_tab"
                                        *ngIf="activeSubMenu === 'past-banking'">
                                        <section class="ribbon-section">
                                            <button mat-button
                                                class="large-button"
                                                (click)="printBankingSlip()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_print_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Print<br>Banking</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <mat-progress-bar mode="buffer"
                                            *ngIf="isGenrateReport"></mat-progress-bar>
                                        <div class="light_label_txt">Details</div>
                                    </section>

                                    <section
                                        class="group-section-tab extras_tab"
                                        *ngIf="activeSubMenu === 'past-reconciliation'">
                                        <section class="ribbon-section">
                                            <button
                                                [disabled]="!isPastRecouncile"
                                                mat-button class="large-button"
                                                (click)="ReportGenerate('reportaccountreconciliation','Print Bank Rec Report')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_print_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Print Bank <br>Rec
                                                            Report</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section class="ribbon-section">
                                            <button
                                                [disabled]="!isPastRecouncile"
                                                mat-button class="large-button"
                                                (click)="ReportGenerate('reportaccountreconciliation','Print Bank Rec W/Report')">
                                                <section
                                                    class="large-button-container ">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_print_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Print Bank <br>with
                                                            details</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <mat-progress-bar mode="buffer"
                                            *ngIf="isGenrateReport"></mat-progress-bar>
                                        <div class="light_label_txt">Details</div>
                                    </section>

                                    <mat-divider vertical></mat-divider>
                                    <section
                                        class="group-section-tab extras_tab"
                                        *ngIf="activeSubMenu !== 'past-banking' && activeSubMenu !== 'bank-feed'">
                                        <section class="ribbon-section">
                                            <button
                                                (click)="undoRecouncilebtn()"
                                                mat-button class="large-button">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_generic_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Undo Last<br>Reconciliation</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section class="ribbon-section"
                                            *ngIf="activeSubMenu !== 'past-reconciliation'"
                                            [ngClass]="!isUnusedAdjustment ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button
                                                [disabled]="!isUnusedAdjustment"
                                                (click)=" removeUnusedAdjustment()"
                                                mat-button class="large-button"
                                                [ngClass]="!isUnusedAdjustment ? 'disabled-click large-button' : 'large-button'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_generic_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Remove Unused<br>Adjustment</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Extras</div>
                                    </section>
                                    <!-- <mat-divider vertical></mat-divider> -->
                                    <!-- <section
                                        class="group-section-tab extras_tab"
                                        *ngIf="activeSubMenu === 'bank-feed'">
                                        <section class="ribbon-section">
                                            <button
                                                (click)="BankPop()"
                                                mat-button class="large-button">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Manage<br>Bank Feeds</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                    </section> -->
                                    <mat-divider vertical></mat-divider>
                                </mat-card-content>
                            </mat-card>
                        </section>
                    </mat-tab>
                    
                    <mat-tab label="Bank Feed" *ngIf="isTabShow === 36">
                        <section class="ribbon-container legal_details_tab_main"
                            [style.width]="detailwidth">
                            <mat-card>
                                <mat-card-content>
                                    <section
                                        class="group-section-tab extras_tab">
                                        <section class="ribbon-section">
                                            <button
                                                (click)="BankFeedInstruct('authorise')"
                                                mat-button class="large-button">
                                                <section
                                                    class="large-button-container">
                                                    <!-- <mat-icon class="main-icon">
                                                    </mat-icon> -->
                                                    <!-- <mat-icon class="mat-icon-size" class="gray" style="margin: auto;">settings</mat-icon> -->
                                                    <mat-icon class="main-icon" style="margin: auto;">
                                                        <img src="assets/icons/web_app/icon_new_d.ico" matTooltip=""
                                                            matTooltipPosition="above">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Add<br>Bank Feed</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                    </section>
                                    <!-- <mat-divider vertical></mat-divider> -->
                                    <section
                                        class="group-section-tab extras_tab">
                                        <section class="ribbon-section">
                                            <button
                                                (click)="BankPop('manageconsent')"
                                                mat-button class="large-button">
                                                <section
                                                    class="large-button-container">
                                                    <!-- <mat-icon class="main-icon">
                                                    </mat-icon> -->
                                                    <!-- <mat-icon class="mat-icon-size" class="gray" style="margin: auto;">settings</mat-icon> -->
                                                    <mat-icon class="main-icon" style="margin: auto;">
                                                        <img src="assets/icons/web_app/icon_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Manage <br>Consent</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                    </section>
                                    <!-- <mat-divider vertical></mat-divider> -->
                                    <section
                                        class="group-section-tab extras_tab">
                                        <section class="ribbon-section">
                                            <button
                                                (click)="BankPop('extendconsent')"
                                                mat-button class="large-button">
                                                <section
                                                    class="large-button-container">
                                                    <!-- <mat-icon class="main-icon">
                                                    </mat-icon> -->
                                                    <!-- <mat-icon class="mat-icon-size" class="gray" style="margin: auto;">settings</mat-icon> -->
                                                    <!-- main-icon-class -->
                                                    <!-- <mat-icon class="mat-icon-size" style="margin: auto;">
                                                        <img src="assets/icons/web_app/image (49).png" matTooltip=""
                                                            matTooltipPosition="above">
                                                    </mat-icon> -->
                                                    <mat-icon class="main-icon" style="margin: auto;">
                                                        <img src="assets/icons/web_app/icon_duplicate_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Extend <br>Consent</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                </mat-card-content>
                            </mat-card>
                        </section>
                    </mat-tab>
                    <!-- ACCOUNTS RECONCILIATION End-->
                    <!-- Safe Custody Start-->
                    <mat-tab label="SAFE CUSTODY" *ngIf="isTabShow===22">
                        <section class="ribbon-container contact_tab_main"
                            [style.width]="detailwidth">
                            <mat-card>
                                <mat-card-content>
                                    <section
                                        class="group-section-tab action_tab">
                                        <section class="ribbon-section">
                                            <mat-nav-list>
                                                <!-- added by web 6  -->
                                                <a mat-list-item
                                                    [routerLink]="'/full-Safe-Custody/Safe-Custody'"
                                                    [ngClass]="{'cmn_active_tab':activeSubMenu=='Safe-Custody'}">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_safecustody_d.ico">
                                                    </mat-icon>
                                                    <span>Safe Custody</span>
                                                </a>
                                                <!-- added by web 6  -->
                                                <a
                                                    [routerLink]="'/full-Safe-Custody/packets'"
                                                    [ngClass]="{'cmn_active_tab':activeSubMenu=='packets','disabled-click-cursor':appPermissions[15]?.Access==0}"
                                                    mat-list-item>
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_doc_pack_d.ico">
                                                    </mat-icon>
                                                    <span>Packets</span>
                                                </a>
                                            </mat-nav-list>
                                        </section>
                                        <div class="light_label_txt">View</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                    <!---FULL SAFE CUSTODY START -->
                                    <section
                                        class="group-section-tab action_tab"
                                        *ngIf="activeSubMenu === 'Safe-Custody'">
                                        <section
                                            [ngClass]="appPermissions[14]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[14]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="OpenNewSafeCustody('new client')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_safecustody_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New Client<br>Safe
                                                            Custody</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[14]?.Edit==0 || isSafeCustody == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[14]?.Edit==0 || isSafeCustody == 'empty')  ? 'disabled-click large-button' : 'large-button'"
                                                (click)="OpenNewSafeCustody('edit')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_safecustody_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Edit Client<br>Safe
                                                            Custody</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section
                                            [ngClass]="(appPermissions[14]?.Delete==0 || isSafeCustody == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[14]?.Delete==0 || isSafeCustody == 'empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="DeleteSafeCustody()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_safecustody_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete Client<br>Safe
                                                            Custody</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section
                                            [ngClass]="appPermissions[14]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[14]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="OpenNewSafeCustody('new matter')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_safecustody_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New Matter<br>Safe
                                                            Custody</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[14]?.Create==0 || isSafeCustody == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[14]?.Create==0 || isSafeCustody == 'empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="OpenNewSafeCustody('copy')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_duplicate_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Duplicate Client<br>safe
                                                            custody</span>
                                                    </div>
                                                    <!-- (SafeCustodyData.DOCUMENTNAME == '' ||SafeCustodyData.DOCUMENTNAME == null || SafeCustodyData.DOCUMENTNAME == 'undefined' ) -->

                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Action
                                        </div>
                                    </section>
                                    <!---FULL SAFE CUSTODY END -->
                                    <mat-divider vertical></mat-divider>
                                    <section
                                        class="group-section-tab action_tab"
                                        *ngIf="activeSubMenu === 'Safe-Custody'">
                                        <!-- <section [ngClass]="(appPermissions[14]?.Create==0 || isSafeCustody == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button [ngClass]="(appPermissions[14]?.Create==0 || isSafeCustody == 'empty')  ? 'disabled-click large-button' : 'large-button'" (click)="setTab($event)">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_template_edit_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Open <br />Document</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section> -->
                                        <section
                                            [ngClass]="(appPermissions[14]?.Create==0 || isSafeCustody == 'empty' || isGenrateReport) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[14]?.Create==0 || isSafeCustody == 'empty' || (isSafeCustody != 'empty' && SafeCustodyData.DOCUMENTNAME == '') )  ? 'disabled-click large-button' : 'large-button'"
                                                (click)="setTab($event); OpenMatter('DOCUMENTSAFE','safecustody')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_template_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Open <br />Document</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[14]?.Create==0 || isSafeCustody == 'empty')  ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'" *ngIf="('Document Generation' | tierPermission : 'Document Generation') && ('Document Generation' | tierPermission : 'Document Generation').ALLOWED == 1">
                                            <!-- <button mat-button [ngClass]="(appPermissions[14]?.Create==0 || isSafeCustody == 'empty') ? 'disabled-click large-button' : 'large-button'" (click)="setTab($event)" [routerLink]="'/create-document/safe-custody-template'">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_letters_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Document <br> Templates</span>
                                                </div>
                                            </section>
                                        </button> -->

                                            <button mat-button
                                                [ngClass]="(appPermissions[5]?.Access==0 || isSafeCustody =='empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="toolbarServiceService.generateDocumentsFromMatters($event);SendEmail(false)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_letters_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Generate <br>
                                                            Documents</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <mat-divider vertical></mat-divider>
                                        <div class="light_label_txt">Details</div>
                                        <mat-progress-bar mode="buffer"
                                            *ngIf="isGenrateReport"></mat-progress-bar>
                                    </section>
                                    <!--PACEKTS SECTION START-->
                                    <section
                                        class="group-section-tab action_tab"
                                        *ngIf="activeSubMenu === 'packets'">
                                        <section
                                            [ngClass]="appPermissions[15]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[15]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="OpenPacket('new')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_doc_pack_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New<br>Packet</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[15]?.Edit==0 || isSafePackData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[15]?.Edit==0 || isSafePackData == 'empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="OpenPacket('edit')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_doc_pack_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Edit<br>Packet</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[15]?.Delete==0 || isSafePackData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[15]?.Delete==0 || isSafePackData == 'empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="DeletePacket()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_doc_pack_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete <br>Packet</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[15]?.Create==0 || isSafePackData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[15]?.Create==0 || isSafePackData == 'empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="OpenPacket('duplicate')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_duplicate_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Duplicate<br>Packet</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Action</div>
                                    </section>
                                    <!--PACKETS SECTION END-->
                                </mat-card-content>
                            </mat-card>
                        </section>
                    </mat-tab>
                    <!-- Safe Custody End-->
                    <!--Trust Money Start-->
                    <mat-tab label="TRUST MONEY" *ngIf="isTabShow===23">
                        <section class="ribbon-container contact_tab_main"
                            [ngClass]="isGenrateReport ? 'disabled-sections-of-trust' : ''"
                            [style.width]="detailwidth">
                            <mat-card>
                                <mat-card-content>
                                    <section
                                        class="group-section-tab extras_tab">
                                        <section class="ribbon-section">
                                            <mat-nav-list>
                                                <a mat-list-item
                                                    [ngClass]="activeSubMenu =='full-trust-money'?'cmn_active_tab':''"
                                                    [routerLink]="'/trust-money/full-trust-money'">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_trust_d.ico">
                                                        <!-- <img src="assets/icons/web_app/icon_tagall_d.ico"> -->
                                                    </mat-icon>
                                                    <span>Trust Money</span>
                                                </a>
                                            </mat-nav-list>
                                            <mat-nav-list>
                                                <a mat-list-item
                                                    [routerLink]="'/trust-money/protected-trust'"
                                                    *ngIf="('Protected Trust' | tierPermission : 'Protected Trust').ALLOWED ==1"
                                                    [ngClass]="{'cmn_active_tab':activeSubMenu =='protected-trust','disabled-click-cursor':appPermissions[31]?.Access==0}">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_trust_d.ico">
                                                    </mat-icon>
                                                    <span>Protected Trust</span>
                                                </a>
                                            </mat-nav-list>
                                        </section>
                                    </section>
                                    <mat-divider style="height: 110px;" vertical></mat-divider>
                                    <ng-container
                                        *ngIf="activeSubMenu =='protected-trust'">
                                        <section style="margin-top: 14px;"
                                            class="group-section-tab action_tab"
                                            *ngIf="('Protected Trust' | tierPermission : 'Protected Trust').ALLOWED ==1">
                                            <section>
                                                <button mat-button
                                                    (click)="OpenTrustPopUp('insert')"
                                                    [ngClass]="appPermissions[31]['Create']==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                                    <section
                                                        class="large-button-container">
                                                        <mat-icon
                                                            class="main-icon">
                                                            <img
                                                                src="assets/icons/web_app/icon_trust_d.ico">
                                                        </mat-icon>
                                                        <div class="main-text">
                                                            <span>New Protected
                                                                <br>Trust</span>
                                                        </div>
                                                    </section>
                                                </button>
                                            </section>
                                            <section>
                                                <button mat-button
                                                    (click)="OpenTrustPopUp('update')"
                                                    [ngClass]="appPermissions[31]['Edit']==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                                    <section
                                                        class="large-button-container">
                                                        <mat-icon
                                                            class="main-icon">
                                                            <img
                                                                src="assets/icons/web_app/icon_trust_d.ico">
                                                        </mat-icon>
                                                        <div class="main-text">
                                                            <span>Edit Protected
                                                                <br>Trust</span>
                                                        </div>
                                                    </section>
                                                </button>
                                            </section>
                                            <section>
                                                <button mat-button
                                                    (click)="deleteProtectedTrust('DELETE')"
                                                    [ngClass]="appPermissions[31]['Delete']==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                                    <section
                                                        class="large-button-container">
                                                        <mat-icon
                                                            class="main-icon">
                                                            <img
                                                                src="assets/icons/web_app/icon_trust_d.ico">
                                                        </mat-icon>
                                                        <div class="main-text">
                                                            <span>Delete
                                                                Protected <br>Trust</span>
                                                        </div>
                                                    </section>
                                                </button>
                                            </section>
                                            <br>
                                            <div style="margin-top: 10px;"
                                                class="light_label_txt">Action</div>
                                        </section>
                                    </ng-container>
                                    <mat-divider style="height: 110px;" vertical
                                        *ngIf="activeSubMenu =='protected-trust'"></mat-divider>
                                    <section
                                        class="group-section-tab action_tab"
                                        *ngIf="activeSubMenu =='full-trust-money'">
                                        <section
                                            [ngClass]="appPermissions[22]['Trust Receipt']==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[22]['Trust Receipt']==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="TrustMoneyPopup('receipt')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_trust_receipt_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Trust<br>Receipt</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[22]['Trust Withdrawal']==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[22]['Trust Withdrawal']==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="TrustMoneyPopup('withdrawal')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_controlled_withdraw_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Trust<br>Withdrawal</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section
                                            [ngClass]="appPermissions[22]['Trust Transfer']==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[22]['Trust Transfer']==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="TrustMoneyPopup('Transfer')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_trust_transfer_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Trust<br>Transfer</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section
                                            [ngClass]="appPermissions[22]['Trust Receipt']==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[22]['Trust Receipt']==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="TrustMoneyPopup('office')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_trust_withdrawal_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Trust To<br>Office</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section
                                            [ngClass]="appPermissions[22]['Controlled Money Receipt']==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[22]['Controlled Money Receipt']==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="TrustMoneyPopup('money receipt')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_controlled_receipt_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Controlled Money<br>
                                                            Receipt</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section
                                            [ngClass]="appPermissions[22]['Controlled Money Withdrawal']==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[22]['Controlled Money Withdrawal']==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="TrustMoneyPopup('money withdrawal')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_controlled_withdraw_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Controlled Money<br>
                                                            Withdrawal</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section
                                            [ngClass]="(appPermissions[22]['Reversals']==0 || !isTrustMoneyList) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[22]['Reversals']==0 || !isTrustMoneyList) ? 'disabled-click large-button' : 'large-button'"
                                                (click)="TrustMoneyPopup('Reverse')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_trust_reversal_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span> Reverse<br>Item</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Action</div>
                                    </section>
                                    <mat-divider vertical
                                        *ngIf="activeSubMenu =='full-trust-money'"></mat-divider>
                                    <section
                                        *ngIf="activeSubMenu =='full-trust-money'"
                                        class="group-section-tab action_tab">
                                        <section
                                            [ngClass]="(appPermissions[22]['Window Report']==0 || !isTrustMoneyListData || isGenrateReport) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[22]['Window Report']==0 || !isTrustMoneyListData || isGenrateReport) ? 'disabled-click large-button' : 'large-button'"
                                                (click)="ReportGenerate('reporttrustrequest','Trust Print Item')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_print_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Print<br>Item</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[22]['Banking']==0 || isGenrateReport)  ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[22]['Banking']==0 || isGenrateReport)  ? 'disabled-click large-button' : 'large-button'"
                                                (click)="BankingDialogOpen('INCOME','','WithTrust')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_receipt_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Do<br>Banking</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[22]?.Access==0)  ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[22]?.Access==0) ? 'disabled-click large-button' : 'large-button'"
                                                (click)="BankingDialogOpen('BANK ACCOUNT','Reconclie Practice','WithTrust')"
                                                (click)="setTab($event)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_receipt_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Account <br>Reconciliation</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Details</div>
                                    </section>
                                    <mat-divider vertical
                                        *ngIf="activeSubMenu =='full-trust-money'"></mat-divider>
                                    <section
                                        class="group-section-tab extras_tab"
                                        *ngIf="activeSubMenu =='full-trust-money'">
                                        <section class="ribbon-section">
                                            <mat-nav-list>
                                                <a
                                                    (click)="TrustMoneyPopup('Cancelled Cheque')"
                                                    mat-list-item
                                                    [ngClass]="{'disabled-click-cursor':appPermissions[4]?.Access==0,'cmn_active_tab':activeSubMenu =='estimate'}">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_trust_reversal_d.ico">
                                                    </mat-icon>
                                                    <span>Cancelled Cheque
                                                    </span>
                                                </a>
                                                <a
                                                    (click)="TrustMoneyPopup('Unknown Deposit')"
                                                    mat-list-item
                                                    [ngClass]="{'disabled-click-cursor':appPermissions[2]?.Access==0,'cmn_active_tab':activeSubMenu =='work-in-progress'}">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_trust_receipt_d.ico">
                                                    </mat-icon>
                                                    <span>Unknown Deposit
                                                    </span>
                                                </a>
                                                <a
                                                    (click)="TrustMoneyPopup('Transfer Unknown Deposit')"
                                                    mat-list-item
                                                    [ngClass]="{'disabled-click-cursor':appPermissions[2]?.Access==0,'cmn_active_tab':activeSubMenu =='matter-trust'}">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_trust_transfer_d.ico">
                                                    </mat-icon>
                                                    <span>Transfer Unknown
                                                        Deposit</span>
                                                </a>
                                            </mat-nav-list>
                                        </section>
                                        <section class="ribbon-section">
                                            <mat-nav-list>
                                                <a
                                                    (click)="TrustMoneyPopup('Statutory Deposit')"
                                                    mat-list-item
                                                    [ngClass]="{'disabled-click-cursor':appPermissions[7]?.Access==0,'cmn_active_tab':activeSubMenu =='matter-invoices'}">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_controlled_withdraw_d.ico">
                                                    </mat-icon>
                                                    <span>Statutory Deposit(to
                                                        Law Society)</span>
                                                </a>
                                                <a
                                                    (click)="TrustMoneyPopup('Statutory Receipt')"
                                                    mat-list-item
                                                    [ngClass]="{'disabled-click-cursor':appPermissions[8]?.Access==0,'cmn_active_tab':activeSubMenu =='receipts-credits'}">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_controlled_receipt_d.ico">
                                                    </mat-icon>
                                                    <span>Statutory Receipt(from
                                                        Law Society)</span>
                                                </a>
                                                <!-- <a (click)="TrustMoneyPopup('Release Trust')" mat-list-item [ngClass]="{'disabled-click-cursor':appPermissions[8]?.Access==0,'cmn_active_tab':activeSubMenu =='receipts-credits'}">
                                                <mat-icon>
                                                    <img src="assets/icons/web_app/icon_trust_reversal_d.ico"  >
                                                </mat-icon>
                                                <span>Release Trust</span>
                                            </a> -->
                                            </mat-nav-list>
                                        </section>
                                        <div class="light_label_txt">Extras</div>
                                    </section>
                                </mat-card-content>
                            </mat-card>
                            <mat-progress-bar mode="buffer"
                                *ngIf="isGenrateReport"></mat-progress-bar>
                        </section>
                    </mat-tab>
                    <!--Trust Money End-->
                    <!-- Trust End Of Month  Start-->
                    <mat-tab label="TRUST END OF MONTH" *ngIf="isTabShow===24">
                        <section
                            class="ribbon-container contact_tab_main contact_tab_main_bar"
                            [style.width]="detailwidth">
                            <mat-card>
                                <mat-card-content>
                                    <section
                                        class="group-section-tab extras_tab">
                                        <section class="ribbon-section">
                                            <mat-nav-list>
                                                <a mat-list-item
                                                    [ngClass]="activeSubMenu =='to-do'?'cmn_active_tab':''"
                                                    [routerLink]="'/trust-end-month/to-do'">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_tagall_d.ico">
                                                    </mat-icon>
                                                    <span>To Do</span>
                                                </a>
                                                <a mat-list-item
                                                    [routerLink]="'/trust-end-month/end-month-history'"
                                                    [ngClass]="{'disabled-click-cursor':appPermissions[22]?.Access==0,'cmn_active_tab':activeSubMenu =='end-month-history'}">
                                                    <mat-icon>
                                                        <img
                                                            src="assets/icons/web_app/icon_trust_d.ico">
                                                    </mat-icon>
                                                    <span>End Of Month History</span>
                                                </a>
                                            </mat-nav-list>
                                        </section>

                                        <div class="light_label_txt">View</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                    <section
                                        class="group-section-tab action_tab"
                                        *ngIf="activeSubMenu ==='to-do'">
                                        <section
                                            [ngClass]="(appPermissions[22]?.Access==0 ||  isGenrateReport || EndOfMonthData?.COMPLETE==1) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[22]?.Access==0 ||  isGenrateReport || EndOfMonthData?.COMPLETE==1) ? 'disabled-click large-button' : 'large-button'"
                                                (click)="processEndOfMonthData(EndOfMonthData)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_save_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Process<br>Item</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[22]?.Access==0 ||  isGenrateReport||EndOfMonthDataSelected.length<=0) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[22]?.Access==0 ||  isGenrateReport||EndOfMonthDataSelected.length<=0) ? 'disabled-click large-button' : 'large-button'"
                                                (click)="processEndOfMonthTagged('true')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_save_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Process<br>Tagged</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <!-- || !ENDOFMONTHDATE?.noCount>0 -->
                                        <section
                                            [ngClass]="(appPermissions[22]?.Access==0 || isGenrateReport || (!ENDOFMONTHDATE?.Date && !ENDOFMONTHDATE?.noCount>1)) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[22]?.Access==0 || isGenrateReport || (!ENDOFMONTHDATE?.Date && !ENDOFMONTHDATE?.noCount>1)) ? 'disabled-click large-button' : 'large-button'"
                                                (click)="SetEndOfMonthData('false')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_save_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Close<br>Month</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <mat-progress-bar mode="buffer"
                                            *ngIf="isGenrateReport"></mat-progress-bar>
                                        <div class="light_label_txt">Action</div>
                                    </section>
                                    <section
                                        class="group-section-tab action_tab"
                                        *ngIf="activeSubMenu ==='end-month-history'">
                                        <section
                                            [ngClass]="appPermissions[22]?.Access==0 || isTrustEndofMonthReport || isGenrateReport ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[22]?.Access==0 || isTrustEndofMonthReport|| isGenrateReport ? 'disabled-click large-button' : 'large-button'"
                                                (click)="ReportGenerate('trustreportscashbook','Print Trust Cashbook')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_print_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Print Trust<br>Cashbook</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[22]?.Access==0 || isTrustEndofMonthReport ||isGenrateReport ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[22]?.Access==0 || isTrustEndofMonthReport || isGenrateReport ? 'disabled-click large-button' : 'large-button'"
                                                (click)="ReportGenerate('trusttrialbalance','Print Trial Balance')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_print_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Print Trial<br>Balance</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section
                                            [ngClass]="appPermissions[22]?.Access==0 || isTrustEndofMonthReport || isGenrateReport ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[22]?.Access==0 || isTrustEndofMonthReport || isGenrateReport ? 'disabled-click large-button' : 'large-button'"
                                                (click)="ReportGenerate('trustcontrolaccount','Print Control Account')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_print_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Print Control<br>Account</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section
                                            [ngClass]="appPermissions[22]?.Access==0 || isTrustEndofMonthReport || isGenrateReport ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[22]?.Access==0 || isTrustEndofMonthReport || isGenrateReport ? 'disabled-click large-button' : 'large-button'"
                                                (click)="ReportGenerate('trusttransferjournal','Print Transfer Journal')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_print_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Print Transfer<br>Journal</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[22]?.Access==0 || isTrustEndofMonthReport || isGenrateReport ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[22]?.Access==0 || isTrustEndofMonthReport || isGenrateReport ? 'disabled-click large-button' : 'large-button'"
                                                (click)="ReportGenerate('trustdebitbalance','Print Debit Balance')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_print_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Print Debit<br>Balance</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[22]?.Access==0 || isTrustEndofMonthReport || isGenrateReport ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[22]?.Access==0 || isTrustEndofMonthReport || isGenrateReport ? 'disabled-click large-button' : 'large-button'"
                                                (click)="ReportGenerate('filemaintenanceaudit','Print Audit Log')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_print_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Print <br>Audit
                                                            Log</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section
                                            [ngClass]="appPermissions[22]?.Access==0 || isTrustEndofMonthReport || isGenrateReport ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[22]?.Access==0 || isTrustEndofMonthReport || isGenrateReport ? 'disabled-click large-button' : 'large-button'"
                                                (click)="ReportGenerate('trustreportcontrolledaccountlisting','Print Controlled Listing')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_print_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Print Controlled
                                                            <br>Listing</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[22]?.Access==0 || isTrustEndofMonthReport || isGenrateReport ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[22]?.Access==0 || isTrustEndofMonthReport || isGenrateReport ? 'disabled-click large-button' : 'large-button'"
                                                (click)="ReportGenerate('trustreportcontrolledcashbook','Print Controlled CashBook')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_print_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Print Controlled
                                                            <br>CashBook</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <mat-progress-bar mode="buffer"
                                            *ngIf="isGenrateReport"></mat-progress-bar>
                                        <div class="light_label_txt">Action</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                </mat-card-content>
                            </mat-card>
                        </section>
                    </mat-tab>
                    <!-- Trust End Of Month End-->
                    <!-- TASK Start -->
                    <mat-tab label="TASK" *ngIf="isTabShow===25">
                        <section class="ribbon-container contact_tab_main"
                            [style.width]="detailwidth">
                            <mat-card>
                                <mat-card-content>
                                    <section
                                        class="group-section-tab action_tab">
                                        <section *ngIf="appPermissions[18]"
                                            [ngClass]="appPermissions[18]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button *ngIf="appPermissions[18]"
                                                mat-button
                                                [ngClass]="appPermissions[18]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="TaskDialoge('new matter')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_tasks_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New Matter <br>Task</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[18]?.Edit==0 || isTaskData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[18]?.Edit==0 || isTaskData == 'empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="TaskDialoge('edit')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_tasks_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Edit<br>Task</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section
                                            [ngClass]="(appPermissions[18]?.Delete==0 || isTaskData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="(appPermissions[18]?.Delete==0 || isTaskData == 'empty')? 'disabled-click large-button' : 'large-button'"
                                                (click)="deleteTask()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img class
                                                            src="assets/icons/web_app/icon_tasks_delete_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Delete<br>Task</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section *ngIf="appPermissions[18]"
                                            [ngClass]="appPermissions[18]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button *ngIf="appPermissions[18]"
                                                mat-button
                                                [ngClass]="appPermissions[18]?.Create==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="TaskDialoge('new general')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_tasks_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New General<br>Task</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section *ngIf="appPermissions[18]"
                                            [ngClass]="(appPermissions[18]?.Create==0 || isTaskData == 'empty') ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button *ngIf="appPermissions[18]"
                                                mat-button
                                                [ngClass]="(appPermissions[18]?.Create==0 || isTaskData == 'empty') ? 'disabled-click large-button' : 'large-button'"
                                                (click)="TaskDialoge('copy')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_duplicate_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Duplicate Matter<br>Task</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Action</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                </mat-card-content>
                            </mat-card>
                        </section>
                    </mat-tab>
                    <!-- TASK Start End -->
                    <!-- CREATE DIARY ENTTRIES Start -->
                    <mat-tab label="CREATE DIARY ENTTRIES"
                        *ngIf="isTabShow===26">
                        <section class="ribbon-container legal_details_tab_main"
                            [style.width]="detailwidth">
                            <mat-card>
                                <mat-card-content>
                                    <section
                                        class="group-section-tab action_tab">
                                        <section
                                            [ngClass]="appPermissions[19]?.Access==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[19]?.Access==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="CreateDiary()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/Select.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Create<br>Entries</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Action</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                </mat-card-content>
                            </mat-card>
                        </section>
                    </mat-tab>
                    <!-- CREATE DIARY ENTTRIES End -->
                    <!-- Trust General Journal Start -->
                    <mat-tab label="Trust General Journal"
                        *ngIf="isTabShow===28">
                        <section class="ribbon-container contact_tab_main"
                            [style.width]="detailwidth">
                            <mat-card>
                                <mat-card-content>
                                    <section
                                        class="group-section-tab action_tab">
                                        <section *ngIf="appPermissions[28]"
                                            [ngClass]="appPermissions[28]?.Create==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button *ngIf="appPermissions[28]"
                                                mat-button
                                                [ngClass]="appPermissions[18]?.Create==0 ? 'disabled-click large-button' : 'disabled-click large-button'"
                                                (click)="ViewDetails()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_edit_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>View <br>Details</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Action</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                    <section
                                        class="group-section-tab action_tab">
                                        <section *ngIf="appPermissions[20]"
                                            [ngClass]="appPermissions[20]['Print']==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                *ngIf="appPermissions[20]"
                                                [ngClass]="appPermissions[20]['Print']==0 ? 'disabled-click large-button' : 'disabled-click large-button'"
                                                (click)="PrintGj('withouttrust')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_print_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Print<br>GJ</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Details</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                </mat-card-content>
                            </mat-card>
                        </section>
                    </mat-tab>
                    <!-- Trust General Journal End-->
                    <!-- INBOX start-->
                    <mat-tab label="INBOX" *ngIf="isTabShow===31">
                        <section class="ribbon-container contact_tab_main"
                            [style.width]="detailwidth">
                            <mat-card>
                                <mat-card-content>
                                    <section
                                        class="group-section-tab action_tab">
                                        <section class="ribbon-section">
                                            <button mat-button
                                                class="large-button"
                                                (click)="composeEmail('withTemplate')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_email_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New Email <br>with
                                                            Template</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section class="ribbon-section">
                                            <button mat-button
                                                class="large-button"
                                                (click)="composeEmail('withoutTemplate')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_email_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>New Email <br>without
                                                            Template</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Compose</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                    <section
                                        class="group-section-tab action_tab">
                                        <section class="ribbon-section">
                                            <button mat-button
                                                class="large-button"
                                                (click)="signaturDialog()">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_expenses_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Signatures</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <!-- <section class="ribbon-section">
                                      <button mat-button class="large-button" (click)="toolbarServiceService.generateDocumentsFromMatters($event)">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_letters_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Generate <br> Documents</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section> -->

                                        <!-- <section class="ribbon-section">
                                            <button mat-button
                                                class="large-button">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_email_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Switch<br>
                                                            Accounts</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section> -->
                                        <section
                                            [ngClass]="selectedEmailList.length < 1 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                (click)="saveMultiEmails()"
                                                [ngClass]="selectedEmailList.length < 1 ? 'disabled-click large-button' : 'large-button'">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_email_new_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Save <br>Multiple
                                                            Email</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Action</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                </mat-card-content>
                            </mat-card>
                        </section>
                    </mat-tab>
                    <!-- INBOXl End-->
                    <!-- User Activity tab menu start -->

                    <!-- <mat-tab [label]="isUsersUserActivity ? 'USERS':'User Activity'" *ngIf="isTabShow===33">
                    <section class="ribbon-container diary_tab_main" [style.width]="detailwidth"
                       >
                        <mat-card>
                            <mat-card-content>


                                <ng-container *ngIf="isUsersUserActivity">
                                    <section class="userMenuContainer">
                                        <div class="nav-item name userMenuNavItem" aria-label="inbox"
                                            (click)="backToUser();" style="height: 30px;">

                                            <mat-icon class="user-menu-icon-container">
                                                <img src="assets/icons/users.png" class="user-menu-icon">
                                            </mat-icon>
                                            <span class="nav-item-link" matRipple>
                                                <span class="title">Users</span>
                                            </span>
                                        </div>
                                        <div class="nav-item name userMenuNavItem selUserMenuItem" aria-label="inbox"
                                            (click)="UserActivityClick();" style="height: 30px;">
                                            <mat-icon class="user-menu-icon-container">
                                                <img src="assets/icons/web_app/icon_time_cost_d.ico"
                                                    class="user-menu-icon">
                                            </mat-icon>
                                            <span class="nav-item-link" matRipple>
                                                <span class="title">User Activity</span>
                                            </span>
                                        </div>

                                    </section>
                                    <mat-divider vertical></mat-divider>
                                </ng-container>


                                <section class="group-section-tab details_tab">
                                    <section class="ribbon-section">
                                        <button mat-button class="large-button" (click)="setViewType('Day')">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_diary_d.ico" aria-label="Today">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Day <br> View</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section class="ribbon-section">
                                        <button mat-button class="large-button" (click)="setViewType('Week')">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_diary_week_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Week <br> View</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section class="ribbon-section">
                                        <button mat-button class="large-button" (click)="setViewType('Month')">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_diary_month_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Month <br> View</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                    <section class="group-section-tab extras_tab">
                                        <section class="ribbon-section">
                                            <mat-nav-list>
                                                <a mat-list-item [matMenuTriggerFor]="menu">
                                                    <mat-icon>
                                                        <img src="assets/icons/web_app/icon_diary_new_d.ico">
                                                    </mat-icon>
                                                    <span>Time Scale ...</span>
                                                </a>
                                                <mat-menu #menu="matMenu">
                                                    <button mat-menu-item (click)="setTimeScale('12')">5
                                                        minutes</button>
                                                    <button mat-menu-item (click)="setTimeScale('6')">10
                                                        minutes</button>
                                                    <button mat-menu-item (click)="setTimeScale('4')">15
                                                        minutes</button>
                                                    <button mat-menu-item (click)="setTimeScale('2')">30
                                                        minutes</button>
                                                    <button mat-menu-item (click)="setTimeScale('1')">1 hour</button>
                                                </mat-menu>
                                            </mat-nav-list>
                                        </section>
                                    </section>
                                    <div class="light_label_txt">View</div>
                                </section>

                                <mat-divider vertical></mat-divider>

                                <section class="group-section-tab details_tab">
                                    <section *ngIf="currentUser?.PRODUCTTYPE!='Barrister'"
                                        [ngClass]="appPermissions[17]?.Access==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button
                                            [ngClass]="appPermissions[17]?.Access==0 ? 'disabled-click large-button' : 'large-button'"
                                            (click)="ChangeUserOfUserActivity()">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img class=" " src="assets/icons/web_app/icon_contact_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Combined<br>View</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>

                                    <div class="light_label_txt">Extras</div>
                                </section>

                                <mat-divider vertical></mat-divider>
                            </mat-card-content>
                        </mat-card>
                    </section>
                </mat-tab> -->
                    <!-- User Activity tab menu end -->
                    <!-- Audit Logs tab menu start -->
                    <mat-tab label="AUDIT LOGS" *ngIf="isTabShow===34"></mat-tab>
                    <!-- Audit Logs tab menu end -->
                    <mat-tab label="TEMPLATE EDITOR" *ngIf="isTabShow===35">
                    <!-- <section class="ribbon-container matter_tab_main"
                        [style.width]="detailwidth">
                        <mat-card>
                            <mat-card-content>
                                <section
                                    class="group-section-tab action_tab">
                                    <section
                                        class="ribbon-section">
                                        <button mat-button
                                            class="large-button">
                                            <section
                                                class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_save_d.ico">
                                                </mat-icon>
                                                <div class="main-text"><span>Save</span></div>
                                            </section>
                                        </button>
                                    </section>
                                    <section
                                        class="ribbon-section">
                                        <button mat-button
                                            [ngClass]="'large-button'"
                                            id="editMatter">
                                            <section
                                                class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_save_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Save<br> As</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section>
                                </section>
                            </mat-card-content>
                        </mat-card>
                    </section> -->
                </mat-tab>
                    <!-- goto tab menu start -->
                    <mat-tab label="GO TO">
                        <section #widgetsContent
                            class="ribbon-container goto_tab_main"
                            [style.width]="detailwidth">
                            <mat-card>
                                <mat-card-content>
                                    <section class="group-section-tab">
                                        <section
                                            [ngClass]="appPermissions[1]?.Access==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[1]?.Access==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="setTab($event);targetNewTab('/matters','MATTERS')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_matter_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Matters</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[3]?.Access==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[3]?.Access==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="setTab($event);targetNewTab('/contact','CONTACTS')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_contact_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Contacts</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section class="ribbon-section"   *ngIf="('Conflict Check' | tierPermission : 'Conflict Check') && ('Conflict Check' | tierPermission : 'Conflict Check').ALLOWED ==1">
                                            <button mat-button
                                                class="large-button"
                                                (click)="setTab($event);targetNewTab('/conflict-check','CONFLICTCHECK')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/Select.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Conflict<br>Check</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <div class="light_label_txt">Management</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                    <section class="group-section-tab">
                                        <section
                                            [ngClass]="appPermissions[2]?.Access==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[2]?.Access==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="setTab($event);targetNewTab('/time-entries','TIMEENTRIES')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_time_cost_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Time <br>Entries</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[7]?.Access==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[7]?.Access==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="setTab($event);targetNewTab('/invoice/sub-invoices','INVOICES')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_invoice_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Invoices</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[9]?.Access==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[9]?.Access==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="setTab($event);targetNewTab('/spend-money','SPENDMONEY')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_expenses_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Spend <br> Money</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[8]?.Access==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[8]?.Access==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="setTab($event);targetNewTab('/receive-money','SPENDMONEY')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_receipt_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Receive<br> Money</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Time and
                                            Billing</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                    <section class="group-section-tab">
                                        <section *ngIf="('Diary' | tierPermission : 'Diary') && (('Diary' | tierPermission : 'Diary').ALLOWED ==1)"
                                            [ngClass]="appPermissions[17]?.Access==0 ? 'disabled-click-cursor ribbon-section' : ' ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[17]?.Access==0 ? 'disabled-click large-button' : ' large-button'"
                                                (click)="setTab($event);targetNewTab('/diary','DIARY');">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_diary_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Diary</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section  *ngIf="('Tasks' | tierPermission : 'Tasks') && (('Tasks' | tierPermission : 'Tasks').ALLOWED ==1)"
                                            [ngClass]="appPermissions[18]?.Access==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[18]?.Access==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="setTab($event);targetNewTab('/task','TASK');">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_tasks_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Tasks</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="(appPermissions[5]?.Access==0 || isInboxDisable) ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'" *ngIf="('Inbox' | tierPermission : 'Inbox') && ('Inbox' | tierPermission : 'Inbox').ALLOWED ==1">
                                            <button mat-button
                                                [ngClass]="(appPermissions[5]?.Access==0 || isInboxDisable) ? 'disabled-click large-button' : 'large-button'"
                                                (click)="setTab($event);targetNewTab('/email','EMAIL');">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_email_u.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Inbox</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <div   *ngIf="('Diary' | tierPermission : 'Diary') && (('Diary' | tierPermission : 'Diary').ALLOWED ==1) && ('Tasks' | tierPermission : 'Tasks') && (('Tasks' | tierPermission : 'Tasks').ALLOWED ==1) && ('Inbox' | tierPermission : 'Inbox') && (('Inbox' | tierPermission : 'Inbox').ALLOWED ==1)" class="light_label_txt">Productivity</div>
                                    </section>
                                    <mat-divider  *ngIf="('Diary' | tierPermission : 'Diary') && (('Diary' | tierPermission : 'Diary').ALLOWED ==1) && ('Tasks' | tierPermission : 'Tasks') && (('Tasks' | tierPermission : 'Tasks').ALLOWED ==1) && ('Inbox' | tierPermission : 'Inbox') && (('Inbox' | tierPermission : 'Inbox').ALLOWED ==1)" vertical></mat-divider>
                                    <!-- Legal -->
                                    <section class="group-section-tab">
                                        <section
                                            *ngIf="currentUser?.PRODUCTTYPE!='Barrister' && ('Searching' | tierPermission : 'Searching') && (('Searching' | tierPermission : 'Searching').ALLOWED == 1)"
                                            [ngClass]="appPermissions[16]?.Access==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[16]?.Access==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="setTab($event);targetNewTab('/searching','SEARCHING');">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_search_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Searching</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <section
                                            *ngIf="currentUser?.PRODUCTTYPE!='Barrister' && (('Safe Custody' | tierPermission : 'Safe Custody') && ('Safe Custody' | tierPermission : 'Safe Custody').ALLOWED ==1)"
                                            [ngClass]="appPermissions[14]?.Access==0   ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[14]?.Access==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="setTab($event);targetNewTab('/full-Safe-Custody','FULLSAFECUSTODY');">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_safecustody_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Safe Custody</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                        *ngIf="('Authorities' | tierPermission : 'Authorities') && ('Authorities' | tierPermission : 'Authorities').ALLOWED ==1"
                                            [ngClass]="appPermissions[12]?.Access==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[12]?.Access==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="setTab($event);targetNewTab('/authorities/full-authorities','FULLAUTHORITIES');">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_authorities_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Authorities <br>and
                                                            Topics</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>

                                        <div class="light_label_txt" *ngIf="('Safe Custody' | tierPermission : 'Safe Custody') && (('Safe Custody' | tierPermission : 'Safe Custody').ALLOWED ==1) && ('Authorities' | tierPermission : 'Authorities') && (('Authorities' | tierPermission : 'Authorities').ALLOWED ==1) " >Legal</div>
                                    </section>
                                    <mat-divider *ngIf="('Safe Custody' | tierPermission : 'Safe Custody') && (('Safe Custody' | tierPermission : 'Safe Custody').ALLOWED ==1) && ('Authorities' | tierPermission : 'Authorities') && (('Authorities' | tierPermission : 'Authorities').ALLOWED ==1) " vertical></mat-divider>

                                    <!-- Firm Directory -->
                                    <section class="group-section-tab" *ngIf="(('Firm Directory' | tierPermission : 'Firm Directory') && ('Firm Directory' | tierPermission : 'Firm Directory').ALLOWED == 1)">
                                        <section
                                            *ngIf="currentUser?.PRODUCTTYPE=='Solicitor'"
                                            class="large-button">
                                            <button mat-button
                                                class="firm-button"
                                                (click)="targetNewTab('/Firm-Directory','FIRMDIRECTORY');gotoFirmDirectory()">
                                                <mat-icon
                                                    class="main-icon op-icon">
                                                    <img
                                                        src="assets/icons/fd.png">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Firm Directory</span>
                                                </div>

                                            </button>
                                        </section>
                                        <div
                                            *ngIf="currentUser?.PRODUCTTYPE=='Solicitor'"
                                            class="light_label_txt">Resources</div>
                                    </section>
                                    <mat-divider
                                        *ngIf="currentUser?.PRODUCTTYPE=='Solicitor'"
                                        vertical></mat-divider>
                                </mat-card-content>
                            </mat-card>
                        </section>
                    </mat-tab>
                    <!-- goto tab menu end -->

                    <!-- ACCOUNTS tab menu START -->
                    <mat-tab label="ACCOUNTS">
                        <section #widgetsContent
                            class="ribbon-container goto_tab_main"
                            [style.width]="detailwidth">
                            <mat-card>
                                <mat-card-content>
                                    <!-- Account Section Start-->
                                    <section class="group-section-tab">
                                        <section
                                            [ngClass]="appPermissions[19]?.Access==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[19]?.Access==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="ChartAccount('WithoutTrust'); setTab($event); targetNewTab('/chart-account', CHARTACCOUNT);">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_receipt_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Chart Of<br>Accounts</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <ng-container
                                            *ngIf="appPermissions && (appPermissions[1] || appPermissions[7] || appPermissions[21])">
                                            <section
                                                [ngClass]="appPermissions[7]?.Delete==0 ||appPermissions[21]['Bank Reconciliations']==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                                <button mat-button
                                                    [ngClass]="appPermissions[1]?.Edit==0 ||appPermissions[21]['Bank Reconciliations']==0 ? 'disabled-click large-button' : 'large-button'"
                                                    (click)="BankingDialogOpen('Bank Account','Reconclie Practice','WithoutTrust')">
                                                    <section
                                                        class="large-button-container">
                                                        <mat-icon
                                                            class="main-icon">
                                                            <img
                                                                src="assets/icons/web_app/icon_receipt_d.ico">
                                                        </mat-icon>
                                                        <div class="main-text">
                                                            <span>Reconcile<br>Practice</span>
                                                        </div>
                                                    </section>
                                                </button>
                                            </section>
                                            
                                            <section
                                                [ngClass]="('Bank Feeds' | tierPermission : 'Bank Feeds') && ('Bank Feeds' | tierPermission : 'Bank Feeds').ALLOWED == 0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                                <button mat-button
                                                    [ngClass]="('Bank Feeds' | tierPermission : 'Bank Feeds') && ('Bank Feeds' | tierPermission : 'Bank Feeds').ALLOWED == 0 ? 'disabled-click large-button' : 'large-button'"
                                                    [routerLink]="'/bank-feed/bank-feed-dashboard'">
                                                    <section
                                                        class="large-button-container">
                                                        <mat-icon
                                                            class="main-icon">
                                                            <img
                                                                src="assets/icons/web_app/icon_receipt_d.ico">
                                                        </mat-icon>
                                                        <div class="main-text">
                                                            <span>Bank <br>Feeds</span>
                                                        </div>
                                                    </section>
                                                </button>
                                            </section>
                                        </ng-container>
                                        <section
                                            [ngClass]="appPermissions[20]?.Access==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <!-- <button mat-button [ngClass]="appPermissions[20]?.Access==0 ? 'disabled-click large-button' : 'large-button'" (click)="GeneralJournal('WithoutTrust')" (click)="setTab($event)" [routerLink]="'/general-journal'"> -->
                                            <button mat-button
                                                [ngClass]="appPermissions[20]?.Access==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="GeneralJournal('WithoutTrust'); setTab($event); targetNewTab('/general-journal', GENERALJOURNAL)">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_receipt_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>General<br>Journal</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[20]?.Access==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[20]?.Access==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="CloseYearAccounts('WithoutTrust')"
                                                [matTooltip]="('Close Year Accounts' | tierPermission : 'Close Year Accounts').ALLOWED !== 1?('Close Year Accounts' | tierPermission : 'Close Year Accounts').DESCRIPTION :''"
                                                matTooltipPosition="above">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            [src]="('Close Year Accounts' | tierPermission : 'Close Year Accounts').ALLOWED == 1?'assets/icons/web_app/icon_receipt_d.ico':'assets/icons/web_app/Feature_is_Locked.ico'"
                                                            class="feature_locked">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Close<br>Year
                                                            Accounts</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <ng-container
                                            *ngIf="appPermissions && (appPermissions[1] || appPermissions[7] || appPermissions[21])">
                                            <section
                                                [ngClass]="appPermissions[7]?.Delete==0 || appPermissions[21]['Bank Reconciliations']==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                                <button mat-button
                                                    [ngClass]="appPermissions[1]?.Edit==0 || appPermissions[21]['Bank Reconciliations']==0? 'disabled-click large-button' : 'large-button'"
                                                    (click)="BankingDialogOpen('BANK ACCOUNT','','WithoutTrust')">
                                                    <section
                                                        class="large-button-container">
                                                        <mat-icon
                                                            class="main-icon">
                                                            <img
                                                                src="assets/icons/web_app/icon_receipt_d.ico">
                                                        </mat-icon>
                                                        <div class="main-text">
                                                            <span>Banking</span>
                                                        </div>
                                                    </section>
                                                </button>
                                            </section>
                                        </ng-container>
                                        <div class="light_label_txt">Accounts</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                    <!-- Account Section End -->
                                    <!-- Trust money start -->
                                    <section class="group-section-tab"
                                        *ngIf="currentUser?.PRODUCTTYPE!='Barrister' && ('Trust Accounts' | tierPermission : 'Trust Accounts') && (('Trust Accounts' | tierPermission : 'Trust Accounts').ALLOWED ==1)">
                                        <section
                                            [ngClass]="appPermissions[22]?.Access==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[22]?.Access==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="setTab($event);targetNewTab('/trust-money','TRUSTMONEY');">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_controlled_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Trust<br>Money</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <ng-container
                                            *ngIf="appPermissions && (appPermissions[1] || appPermissions[7] || appPermissions[22])">
                                            <section
                                                [ngClass]="appPermissions[7]?.Delete==0 || appPermissions[22]['End Of Month']==0? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                                <button mat-button
                                                    [ngClass]="appPermissions[1]?.Edit==0 || appPermissions[22]['End Of Month']==0 ? 'disabled-click large-button' : 'large-button'"
                                                    (click)="setTab($event);targetNewTab('/trust-end-month/to-do','TRUSTENDOFMONTH');">
                                                    <section
                                                        class="large-button-container">
                                                        <mat-icon
                                                            class="main-icon">
                                                            <img
                                                                src="assets/icons/web_app/icon_controlled_d.ico">
                                                        </mat-icon>
                                                        <div class="main-text">
                                                            <span>End Of<br>Month</span>
                                                        </div>
                                                    </section>
                                                </button>
                                            </section>
                                        </ng-container>
                                        <section
                                            [ngClass]="appPermissions[23]?.Access==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[23]?.Access==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="setTab($event); ChartAccount('WithTrust'); targetNewTab('/trust-chart-account', 'TRUSTEDCHARTACCOUNT')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_receipt_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Trust Chart of<br>Accounts</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <ng-container
                                            *ngIf="appPermissions && (appPermissions[1] || appPermissions[7] || appPermissions[22])">
                                            <section
                                                [ngClass]="appPermissions[7]?.Delete==0 || appPermissions[22]['Reconcile Trust Account']==0? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                                <button mat-button
                                                    [ngClass]="appPermissions[1]?.Edit==0 || appPermissions[22]['Reconcile Trust Account']==0 ? 'disabled-click large-button' : 'large-button'"
                                                    (click)="BankingDialogOpen('BANK ACCOUNT','Reconclie Practice','WithTrust')">
                                                    <section
                                                        class="large-button-container">
                                                        <mat-icon
                                                            class="main-icon">
                                                            <img
                                                                src="assets/icons/web_app/icon_controlled_d.ico">
                                                        </mat-icon>
                                                        <div class="main-text">
                                                            <span>Reconcile<br>
                                                                Trust</span>
                                                        </div>
                                                    </section>
                                                </button>
                                            </section>
                                        </ng-container>
                                        <section
                                            [ngClass]="appPermissions[24]?.Delete==0 || appPermissions[24]?.Access==0? 'disabled-click-cursor ribbon-section' : 'disabled-click-cursor ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[24]?.Edit==0 || appPermissions[24]?.Access==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="GeneralJournal('WithTrust');setTab($event); targetNewTab('/trust-general-journal', 'TrustGeneralJournal')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_controlled_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>General<br>
                                                            Journal</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <section
                                            [ngClass]="appPermissions[22]?.Create==0 || appPermissions[22]?.Banking ==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[22]?.Create==0 || appPermissions[22]?.Banking ==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="BankingDialogOpen('BANK ACCOUNT','','WithTrust')">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon">
                                                        <img
                                                            src="assets/icons/web_app/icon_controlled_d.ico">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Trust Banking</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Trust</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                </mat-card-content>
                            </mat-card>
                        </section>
                    </mat-tab>
                    <!-- goto tab menu end -->
                    <!-- report tab menu start -->
                    <!-- <mat-tab label="REPORTS"></mat-tab> -->
                    <mat-tab label="REPORTS">
                        <section #widgetsContent
                            class="ribbon-container goto_tab_main"
                            [style.width]="detailwidth">
                            <mat-card>
                                <mat-card-content>
                                    <!-- Account Section Start-->
                                    <section class="group-section-tab"
                                        *ngIf="appPermissions && appPermissions[33]">
                                        <section
                                            [ngClass]="appPermissions[33] &&  appPermissions[33]['Firm Dashboard']==0 && appPermissions[33]['Individual Dashboard']==0 && appPermissions[33]['Teams Dashboard']==0? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                [ngClass]="appPermissions[33] &&  appPermissions[33]['Firm Dashboard']==0 && appPermissions[33]['Individual Dashboard']==0 && appPermissions[33]['Teams Dashboard']==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="setTab($event); targetNewTab('/dashboard', DASHBOARD);">
                                                <section
                                                    class="large-button-container">
                                                    <mat-icon class="main-icon1">
                                                        <!-- <img
                                                            src="assets/icons/web_app/icon_reports_d.ico"> -->
                                                            <img src="assets/icons/web_app/4.svg">
                                                    </mat-icon>
                                                    <div class="main-text">
                                                        <span>Dashboard</span>
                                                    </div>
                                                </section>
                                            </button>
                                        </section>
                                        <!-- <section [ngClass]="appPermissions[27] && appPermissions[27]['Fee Earner Snap Shot']==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                        <button mat-button [ngClass]="appPermissions[27] && appPermissions[27]['Fee Earner Snap Shot']==0 ? 'disabled-click large-button' : 'large-button'" (click)="setTab($event); targetNewTab('/dashboard/fee-earner-breakdown-dashboard', FREEERNERDASHBOARD);">
                                            <section class="large-button-container">
                                                <mat-icon class="main-icon">
                                                    <img src="assets/icons/web_app/icon_reports_d.ico">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Fee Earner Dashboard</span>
                                                </div>
                                            </section>
                                        </button>
                                    </section> -->
                                        <div class="light_label_txt">Dashboards</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>

                                    <!-- Audit Logs-->
                                    <section class="group-section-tab"
                                        *ngIf="appPermissions && appPermissions[25]">
                                        <section class="ribbon-section"
                                            [ngClass]="appPermissions[25] && appPermissions[25]['Audit Log']==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                                            <button mat-button
                                                class="audit-button"
                                                [ngClass]="appPermissions[25] && appPermissions[25]['Audit Log']==0 ? 'disabled-click large-button' : 'large-button'"
                                                (click)="targetNewTab('/audit-logs','AUDITLOGS')">
                                                <mat-icon class="main-icon1" style="margin-bottom: 3px;">
                                                    <img src="assets/icons/web_app/6.svg" class="audit_log_block">
                                                </mat-icon>
                                                <div class="main-text">
                                                    <span>Audit Logs</span>
                                                </div>
                                            </button>
                                        </section>
                                        <div class="light_label_txt">Management</div>
                                    </section>
                                    <mat-divider vertical></mat-divider>
                                </mat-card-content>
                            </mat-card>
                        </section>
                    </mat-tab>

                    <!-- report tab menu end -->

                </mat-tab-group>
            </ng-container>
            <!-- <div class="quick-summary"></div> -->
        </div>
        <div fxFlex="0 1 auto" fxLayoutAlign="center" class="user_dropdown">
            <!-- <button mat-icon-button (click)="SetUpGuideOpenToggle('SetUpGuideOpen')">
                <mat-icon class=''>flag</mat-icon>
                SetUp Guide
            </button> -->
            <!-- <div class="toolbar-separator top_seprator"></div> -->

            <!-- <mat-slide-toggle class="Toggle" [(ngModel)]='USEV11UIValue'
            name='USEV11UI' (change)="themePanel('USEV11UI' , $event,'toolbarUSEV11UI')">
                <b>New SILQ </b>
           </mat-slide-toggle> -->
            <button class="setupguide-btn" mat-icon-button style="display: none;" (click)="toggleSidebarOpen('setupguide');LoadSetUpGuideData()">
                <mat-icon ><img src="assets/images/flags/finish.png"></mat-icon>
                SetUp Guide
            </button>

            <button mat-icon-button [matMenuTriggerFor]="menu"
                class="iconbtn-plus">
                <mat-icon class='add-circle-color'>add_circle</mat-icon>
            </button>

            <mat-menu #menu="matMenu" class="toolbar-overflow">
                <ng-container *ngFor="let data of listMenu;trackBy: trackByFn">
                <button mat-menu-item *ngIf="data.isShown == true"
                    (click)="addData(data)"
                    [ngClass]="appPermissions[data.PermissionId]?.Create==0 || data.id == 4 && (appPermissions[data.PermissionId]?.Create==0 || appPermissions[2] && appPermissions[2]['Create WIP']==0)? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">
                    <mat-icon role="img"
                        class="mat-icon notranslate main-icon material-icons mat-ligature-font mat-icon-no-color icon-width">
                        <img src="assets/icons/web_app/{{data.icon}}">
                    </mat-icon>
                    {{data.name}}
                </button>
            </ng-container>
            </mat-menu>

            <button mat-icon-button (click)="toggleSidebarOpen('notifications')">
                <mat-icon class="gray" [matBadge]="notificationBadge"
                    matBadgeSize="small"
                    matBadgeColor="warn">notifications</mat-icon>
            </button>

            <button mat-icon-button *ngIf="SnapShotAppPermissions"
                [matMenuTriggerFor]="userMenu1" (click)="DashboardAPI()">
                <mat-icon class="gray">
                    <img src="assets/icons/web_app/Chart_Icon.png" matTooltip
                        matTooltipPosition="above">
                </mat-icon>
            </button>

            <mat-menu #userMenu1="matMenu" [overlapTrigger]="false">
                <button mat-menu-item><span><b> Unbilled:</b> <span
                            class="unbilledClassColor">
                            ${{TotalUnbilledWIP | number}}</span></span></button>
                <button mat-menu-item><span><b> Outstanding:</b> <span
                            class="OutstandingClassColor">
                            ${{TotalOutstanding | number}}</span></span></button>
                <hr />

                <mat-card class="toolbar-canvas-matcard">
                    <canvas id="WeekleyGraphDashboardID"></canvas>
                </mat-card>
                <button (click)="setTab($event);targetNewTab('/dashboard','')"
                    mat-menu-item>
                    <span><strong>Go to Dashboard</strong></span>
                </button>
                <!-- <button mat-raised-button color="accent"[routerLink]="'/dashboard'">View Dashboard</button> -->
            </mat-menu>

            <button mat-icon-button id="sidebar_open_button"
                (click)="toggleSidebarOpen('quickPanel')"
                aria-label="Toggle chat panel">
                <mat-icon class="gray" matBadgeSize="small"
                    matBadge="{{TotalTimer}}" matBadgeColor="warn">timer
                </mat-icon>
            </button>

            <button class="gray" *ngIf="appPermissions[28]"
                (click)="setTab($event);targetNewTab('system-setting/name','SYSTEMSETTING')"
                mat-icon-button
                [ngClass]="appPermissions[28]['System Settings']==0 ? 'disabled-click-cursor' : ''"
                [disabled]="appPermissions[28]['System Settings']==0">
                <mat-icon class="mat-icon-size" class="gray">settings</mat-icon>
                <!-- <img style="width: 70%;"   src="assets/icons/web_app/Setting.jpg"> -->
            </button>

            <button mat-icon-button [matMenuTriggerFor]="userMenu"
                class="profile-img">
                <img class="radius-500"
                    src="assets/images/avatars/Velazquez.jpg">
            </button>
            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
                <button mat-menu-item
                    (click)="targetNewTab('my-profile/general','MYPROFILE')">
                    <span>My Profile</span>
                </button>
                <!-- <button mat-menu-item (click)="ChangePass()">
                        <span>Change Password</span>
                    </button>
                    <button mat-menu-item (click)="targetNewTab('/multi-factor-authentication', MULTIFACTORAUTHENTICATION)">
                        <span>Multi Factor Authentication</span>
                    </button> -->
                <!-- <button mat-menu-item (click)="toggleSidebarOpen('themeOptionsPanel')">
                        <span>Preferences</span>
                    </button> -->
                <!-- My-profile INSTED OF PREFERENCE  -->

                <!-- <button *ngIf="appPermissions[28]" (click)="targetNewTab('system-setting/name','SYSTEMSETTING')"
                        mat-menu-item [ngClass]="appPermissions[28]['System Settings']==0 ? 'disabled-click-cursor' : ''">
                        <span>System Settings</span>
                    </button> -->

                <!-- <button (click)="targetNewTab('/system-setting/account-management/basicinfo','ACCOUNTMANAGEMENT')" mat-menu-item>
                        <span>Account Management</span>
                    </button> -->
                <!-- <button (click)="targetNewTab('/users','USERS');setTab($event)" mat-menu-item
                        [ngClass]="appPermissions[30]?.Access==0 ? 'disabled-click-cursor' : ''">
                        <span>Users</span>
                    </button> -->
                <!-- <button [ngClass]="appPermissions[30]?.Access==0 ? 'disabled-click-cursor' : ''" (click)="targetNewTab('/activities','ACTIVITIES'); setTab($event)" mat-menu-item>
                        <span>Activities & Sundries</span>
                    </button> -->
                <!-- <button (click)="targetNewTab('/Firm-Directory','FIRMDIRECTORY')" mat-menu-item>
                        <span>Firm Directory</span>
                    </button> -->
                <!-- *ngIf="currentUser?.PRODUCTTYPE=='Solicitors' && accessTierPermission[3]?.ALLOWED == 1 " -->

                <!-- <button (click)="targetNewTab('/Firm-Directory','FIRMDIRECTORY');gotoFirmDirectory()" mat-menu-item
                        *ngIf="currentUser?.PRODUCTTYPE=='Solicitor'">
                        <span>Firm Directory</span>
                    </button> -->

                <!-- <button (click)="targetNewTab('/audit-logs','AUDITLOGS')" mat-menu-item>
                        <span>Audit Logs</span>
                    </button> -->
                <hr />
                <button mat-menu-item (click)="logOutUser()">
                    <span><strong>SIGN OUT</strong></span>
                </button>

            </mat-menu>
        </div>
    </div>
</mat-toolbar>
