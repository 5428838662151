<!-- <button mat-raised-button type="button" color="primary" id="refreshBank"
    (click)="refreshBank();">Refresh</button> -->
<div
    class="popup_main_div corres_popup_main select_metter_main_div account_select_main_popup"
    cdkDrag>
    <h2 cdkDragHandle mat-dialog-title>{{dialogheader}}
        <button class="btn" mat-icon-button [mat-dialog-close]="false"
            aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography">
        <div class="example-loading-shade" *ngIf="isLoadingResults">
            <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
        </div>
       
        <div class="example-containerdata">
            <ng-container *ngIf="isShownTreegrid">
              <div class="grid-overlay" *ngIf="TreegridData?.data?.length <= 0">
                <div class="grid-overlay-msg" style="margin-left: 170px;"> There is no data to display. </div>
            </div>
                <table mat-table [dataSource]="TreegridData" class="mat-elevation-z8">

                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="select">
                      <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                      [checked]="selection.hasValue() && isAllSelected()"
                                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                                      [aria-label]="checkboxLabel()">
                        </mat-checkbox>
                      </th>
                      <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()"
                                      (change)="$event ? selection.toggle(row) : null"
                                      [checked]="selection.isSelected(row)"
                                      [aria-label]="checkboxLabel(row)">
                        </mat-checkbox>
                      </td>
                    </ng-container>
                  
                    <!-- RECEIVEDDATE Column -->
                    <ng-container matColumnDef="RECEIVEDDATE">
                      <th mat-header-cell *matHeaderCellDef> Received date </th>
                      <td mat-cell *matCellDef="let element"> {{element.RECEIVEDDATE}} </td>
                    </ng-container>
                  
                    <!-- PAYEE Column -->
                    <ng-container matColumnDef="PAYEE">
                      <th mat-header-cell *matHeaderCellDef> Payee </th>
                      <td mat-cell *matCellDef="let element"> {{element.PAYEE}} </td>
                    </ng-container>
                  
                    <!-- AMOUNTINCGST Column -->
                    <ng-container matColumnDef="AMOUNTINCGST">
                      <th mat-header-cell *matHeaderCellDef> Amount Inc GST </th>
                      <td mat-cell *matCellDef="let element"> {{element.AMOUNTINCGST | toFixedAmount:true}} </td>
                    </ng-container>
                  
                    <!-- NOTE Column -->
                    <ng-container matColumnDef="NOTE">
                      <th mat-header-cell *matHeaderCellDef> Note </th>
                      <td mat-cell *matCellDef="let element"> {{element.NOTE}} </td>
                    </ng-container>
                  
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                        (click)="selection.toggle(row)">
                    </tr>
                </table>
            </ng-container>
        </div>
        <div class="example-containerdata">
            <ng-container *ngIf="!isShownTreegrid">
              <div class="display-flex justify-content-between gap-10 topfield-block mb-12" style="margin-top: 2px;">
                <div class="display-flex gap-5" >
              <form class="display-flex gap-5" [formGroup]="unpaideForm">
                <mat-form-field appearance="outline" class="datefield "  >
                    <mat-label>Date</mat-label>
                    <!-- <input type="date" aria-label="Number" matInput formControlName="BATCHDATETEXT" (dateChange)="CommencementDate($event)" > -->
                    <input matInput formControlName="BATCHDATETEXT"
                    (dateChange)="CommencementDate($event)"
                    [matDatepicker]="picker"
                    placeholder="Choose a date">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                <mat-form-field appearance="outline"  class="bankaccfield"
                        >
                        <mat-label>Bank a/c</mat-label>
                        <input matInput formControlName="BANKACCOUNTDATA">
                        <mat-icon matSuffix>
                            <button id="bank" class="matternews menu-disabled" class="matternews" (click)="BankingDialogOpen('BANK ACCOUNT' , 'BankAccountClick',$event)">
                              <img src="assets/icons/web_app/icon_accounts_small_d.ico" matTooltip matTooltipPosition="above">
                            </button>                           
                        </mat-icon>
                </mat-form-field>
                <mat-form-field appearance="outline" class="invoicefield" >
                  <mat-label>Invoice #/Source ref</mat-label>
                  <input type="text" name="Invoice #/Source ref" placeholder="Invoice #/Source ref" formControlName="EFTREFERENCE" matInput   >
                </mat-form-field>
            </form>
            </div>
            <mat-form-field appearance="outline" class="amountfield">
              <mat-label> Amount</mat-label>
              <!-- Disabled input for styling consistency -->
              <input matInput type="text" [value]="totalAmountIncGST | toFixedAmount:true" placeholder="Amount" disabled>
            </mat-form-field>
            </div>
                <table mat-table [dataSource]="SHOWSECONDTABLE" class="mat-elevation-z8 second-table unpaid-accounttable">

                    <!-- AMOUNTINCGST Column -->
                    <ng-container matColumnDef="AMOUNTINCGST">
                        <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Amount Inc GST </th>
                        <td mat-cell *matCellDef="let element"> {{element.AMOUNTINCGST | toFixedAmount:true}} </td>
                      </ng-container>

                    <!-- RECEIVEDDATE Column -->
                    <ng-container matColumnDef="RECEIVEDDATE">
                      <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Received date </th>
                      <td mat-cell *matCellDef="let element"> {{element.RECEIVEDDATE}} </td>
                    </ng-container>
                  
                    <!-- PAYEE Column -->
                    <ng-container matColumnDef="PAYEE">
                      <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Payee </th>
                      <td mat-cell *matCellDef="let element"> {{element.PAYEE}} </td>
                    </ng-container>
                  
                    <!-- NOTE Column -->
                    <ng-container matColumnDef="NOTE">
                      <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Note </th>
                      <td mat-cell *matCellDef="let element"> {{element.NOTE}} </td>
                    </ng-container>

                    <!-- BSB Column -->
                    <ng-container matColumnDef="BSB">
                        <th mat-header-cell *matHeaderCellDef class="bsb-width"> BSB </th>
                        <td mat-cell *matCellDef="let element">
                          <mat-form-field appearance="outline" class="bsb-input">
                            <mat-label>BSB</mat-label>
                            <input matInput [(ngModel)]="element.BSB" placeholder="Enter BSB">
                          </mat-form-field>
                        </td>
                      </ng-container>

                       <!-- ACCOUNTNUMBER Column -->
                       <ng-container matColumnDef="ACCOUNTNUMBER">
                        <th mat-header-cell *matHeaderCellDef class="accountNumber"> Account Number </th>
                        <td mat-cell *matCellDef="let element">
                          <mat-form-field appearance="outline">
                            <mat-label>Account Number</mat-label>
                            <input matInput [(ngModel)]="element.ACCOUNTNUMBER" placeholder="Enter Account Number">
                          </mat-form-field>
                        </td>
                      </ng-container>


                       <!-- ACCOUNTNAME Column -->
                       <ng-container matColumnDef="ACCOUNTNAME">
                        <th mat-header-cell *matHeaderCellDef class="accountName"> Account Name </th>
                        <td mat-cell *matCellDef="let element">
                          <mat-form-field appearance="outline">
                            <mat-label>Account Name</mat-label>
                            <input matInput [(ngModel)]="element.ACCOUNTNAME" placeholder="Enter Account Name">
                          </mat-form-field>
                        </td>
                      </ng-container>
                  
                    <tr mat-header-row *matHeaderRowDef="displayedSecondColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedSecondColumns;"
                        (click)="selection.toggle(row)">
                    </tr>
                </table>
            </ng-container>
        </div>
    </div>
    <!-- [mat-dialog-close]="true" -->
    <mat-dialog-actions align="left" class="popup_ftr contact_select_footer">
        <button *ngIf="isShownTreegrid" mat-raised-button [disabled]="selection.selected.length == 0"
            color="accent" (click)="goToNext()"
            >Next</button>

            <button *ngIf="!isShownTreegrid" mat-raised-button
            color="accent" (click)="isShownTreegrid = true"
            >Back</button>
            <button *ngIf="!isShownTreegrid && isShownDownload == false" mat-raised-button
            color="accent" (click)="SaveUnpaidAccounts()" [disabled]="isLoadingResults"
            >
            <mat-spinner
                    style=" zoom: 0.2;position: absolute;right: 0;left: 0;
                margin: auto;top: 0;bottom: 0;" *ngIf="isLoadingResults">
            </mat-spinner>
            Save</button>

            <button *ngIf="!isShownTreegrid && isShownDownload == true" mat-raised-button [disabled]="isLoadingResults"
            color="accent" (click)="DownloadUnpaidAccounts()"
            >
            <mat-spinner
                    style=" zoom: 0.2;position: absolute;right: 0;left: 0;
                margin: auto;top: 0;bottom: 0;" *ngIf="isLoadingResults">
            </mat-spinner>
            Download</button>
            
        <!-- <button mat-raised-button color="accent" class="mat-accent">New
            Account</button> -->
        <button mat-raised-button color="primary" [mat-dialog-close]="false"
            cdkFocusInitial>Cancel</button>
    </mat-dialog-actions>
</div>
