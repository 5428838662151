<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div class="popup_main_div add_edit_spendmoney_main" cdkDrag>
    <h2 cdkDragHandle mat-dialog-title class="title-withswitch">{{dialogTitle}}
        <mat-slide-toggle (change)="ToggleForUpadte()">Show Template Fields</mat-slide-toggle>
        <button class="btn" mat-icon-button [mat-dialog-close]="false"
            aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </h2>
    <div mat-dialog-content class="mat-typography main">
        <div class="one" fxLayout="column" fxLayoutAlign="start">
            <form fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto"
                name="form" [formGroup]="spendmoneyForm">
                <div fxLayout="row" fxLayoutAlign="start center"
                    fxFlex="1 0 auto">
                    <mat-form-field
                        [ngClass]="{'has-error':errorWarningData.Error?.DATE,'has-warning':errorWarningData.Warning?.DATE}"
                        appearance="outline" class="example" fxFlex="{{size}}"
                        class="pr-4">
                        <mat-label>Incurred Date</mat-label>
                        <input
                            (dateChange)="choosedDateForIncurred('change', $event)"
                            matInput
                            [matDatepicker]="DateIncurred"
                            formControlName="DateIncurred"
                            matTooltip="{{(toolTipList)?toolTipList.DATE_?.HOVER:''}}">
                        <mat-datepicker-toggle matSuffix [for]="DateIncurred"></mat-datepicker-toggle>
                        <mat-datepicker #DateIncurred></mat-datepicker>
                        <div *ngIf="testBool && toolTipList"
                            class="tooltip-wrap"
                            (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.DATE?.COPYVALUE:'')"><input
                                id="myInput" readonly disabled
                                value="{{toolTipList?.DATE?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field class="example" fxFlex="{{size}}"
                        class="pr-4" appearance="outline"
                        [ngClass]="{'has-error':errorWarningData.Error?.STATUS,'has-warning':errorWarningData.Warning?.STATUS}">
                        <mat-label>Paid</mat-label>
                        <mat-select #value name="selected"
                            formControlName="Paid"
                            (selectionChange)="Paidtype($event.value)">
                            <mat-option value="Paid">Paid</mat-option>
                            <mat-option value="Unpaid">Unpaid</mat-option>
                        </mat-select>
                        <div *ngIf="testBool && toolTipList"
                            class="tooltip-wrap"
                            (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.STATUS?.COPYVALUE:'')"><input
                                id="myInput" readonly disabled
                                value="{{toolTipList?.STATUS?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field
                        [ngClass]="{'has-error':errorWarningData.Error?.RECEIVEDDATE,'has-warning':errorWarningData.Warning?.RECEIVEDDATE}"
                        appearance="outline" fxFlex="{{size}}" class="pr-4">
                        <mat-label>Date Paid</mat-label>
                        <input
                            (dateChange)="choosedDateForPaid('change', $event)"
                            matInput [matDatepicker]="DatePaid"
                            formControlName="DatePaid"
                            matTooltip="{{(toolTipList)?toolTipList.RECEIVEDDATE?.HOVER:''}}">
                        <mat-datepicker-toggle matSuffix [for]="DatePaid"></mat-datepicker-toggle>
                        <mat-datepicker #DatePaid></mat-datepicker>
                        <div *ngIf="testBool && toolTipList"
                            class="tooltip-wrap"
                            (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.RECEIVEDDATE?.COPYVALUE:'')"><input
                                id="myInput" readonly disabled
                                value="{{toolTipList?.RECEIVEDDATE?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field class="readonly_apply pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.AMOUNT,'has-warning':errorWarningData.Warning?.AMOUNT}"
                        appearance="outline" fxFlex="{{size}}"
                        *ngIf=" dataTableHide =='yes'">
                        <mat-label>Amount</mat-label>
                        <input currencyMask class="sideText" matInput
                            formControlName="Amount"
                            matTooltip="{{(toolTipList)?toolTipList.AMOUNT?.HOVER:''}}">
                        <!-- <span class="currency_sign" matPrefix>$ &nbsp;</span> -->
                        <div *ngIf="testBool && toolTipList"
                            class="tooltip-wrap"
                            (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.AMOUNT?.COPYVALUE:'')"><input
                                id="myInput" readonly disabled
                                value="{{toolTipList?.AMOUNT?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field class="readonly_apply pr-4"
                        [ngClass]="{'has-error':errorWarningData.Error?.GST,'has-warning':errorWarningData.Warning?.GST}"
                        appearance="outline" fxFlex="{{size}}"
                        *ngIf="dataTableHide =='yes'">
                        <mat-label>GST</mat-label>
                        <input currencyMask class="sideText" matInput
                            formControlName="GST"
                            matTooltip="{{(toolTipList)?toolTipList.GST?.HOVER:''}}">
                        <div *ngIf="testBool && toolTipList"
                            class="tooltip-wrap"
                            (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.GST?.COPYVALUE:'')"><input
                                id="myInput" readonly disabled
                                value="{{toolTipList?.GST?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                    </mat-form-field>
                    <!-- <div fxFlex="15">
                        <mat-checkbox (change)="multilineCheckbox()" class="example-margin" formControlName="MultiLineExpense">Multi Line Expense</mat-checkbox>
                    </div> -->

                    <div fxFlex="15">
                        <mat-slide-toggle (change)="multilineCheckbox()"
                            formControlName="MultiLineExpense"
                            class="mat-slide-toggle  mat-form-field-wrapper ng-tns-c670-169 mat-accent togglechecksol ng-valid ng-dirty ng-touched"
                            id="mat-slide-toggle-35"><label
                                class="mat-slide-toggle-label"
                                for="mat-slide-toggle-35-input">
                                <span class="mat-slide-toggle-content"><span
                                        style="display: none;">&nbsp;</span>Multi
                                    Line Expense </span></label>
                        </mat-slide-toggle>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center"
                    fxFlex="1 0 auto">

                    <!-- for focus in -->
                    <!-- <mat-form-field appearance="outline" fxFlex="27"
                        class="pr-4" style="display: none;">
                        <mat-label *ngIf="classtype !=='Depreciation'">Bank a/c</mat-label>
                        <mat-label *ngIf="classtype=='Depreciation'">Asset a/c</mat-label>
                        <input matInput>
                        <mat-icon matSuffix>
                            <button><img
                                    src="assets/icons/web_app/icon_accounts_small_d.ico"
                                    matTooltip matTooltipPosition="above"></button>
                            <button><img
                                    src="assets/icons/web_app/icon_accounts_small_d.ico"
                                    matTooltip matTooltipPosition="above"></button>
                        </mat-icon>
                    </mat-form-field> -->
                    <!-- ends here ~ for focus in -->

                    <mat-form-field appearance="outline" fxFlex="27"
                        class="pr-4">
                        <mat-label *ngIf="classtype !=='Depreciation'">Bank a/c</mat-label>
                        <mat-label *ngIf="classtype=='Depreciation'">Asset a/c</mat-label>
                        <!-- (keydown.Tab)="getAccountInfo('BankAccountClick')" -->
                        <input matInput formControlName="Bankac"
                            (keyup.enter)="getAccountInfo('BankAccountClick')"
                            matTooltip="{{(toolTipList)?toolTipList.Bankac?.HOVER:''}}">
                        <mat-icon matSuffix>
                            <button *ngIf="classtype !=='Depreciation'"
                                id="bank" class="matternews menu-disabled"
                                [disabled]="Bankhide === true"
                                class="matternews"
                                (click)="BankingDialogOpen('BANK ACCOUNT' , 'BankAccountClick',$event)"><img
                                    src="assets/icons/web_app/icon_accounts_small_d.ico"
                                    matTooltip
                                    matTooltipPosition="above"></button>
                            <button *ngIf="classtype =='Depreciation'" id="bank"
                                class="matternews menu-disabled"
                                [disabled]="Bankhide === true"
                                class="matternews"
                                (click)="BankingDialogOpen('ASSET','BankAccountClick',$event)"><img
                                    src="assets/icons/web_app/icon_accounts_small_d.ico"
                                    matTooltip
                                    matTooltipPosition="above"></button>
                        </mat-icon>
                    </mat-form-field>
                    <mat-form-field
                        [ngClass]="{'has-error':errorWarningData.Error?.EXPENDITURETYPE,'has-warning':errorWarningData.Warning?.EXPENDITURETYPE}"
                        class="example" fxFlex="15" class="pr-4"
                        appearance="outline">
                        <mat-label>Type</mat-label>
                        <mat-select (selectionChange)="Cashtype($event)" #value
                            name="selected" formControlName="Type">
                            <mat-option value="Cash">Cash</mat-option>
                            <mat-option value="Cheque">Cheque</mat-option>
                            <mat-option value="Credit Card">Credit Card</mat-option>
                            <mat-option value="Direct Deposit">Direct Deposit</mat-option>
                            <mat-option value="Direct Withdrawal">Direct
                                Withdrawal</mat-option>
                            <mat-option value="Money Order">Money Order</mat-option>
                            <mat-option value="BPay">BPay</mat-option>
                            <mat-option value="Transfer">Transfer</mat-option>
                            <mat-option value="SilqPay">SilqPay</mat-option>
                            <mat-option value="EFT">EFT</mat-option>
                        </mat-select>
                        <div *ngIf="testBool && toolTipList"
                            class="tooltip-wrap"
                            (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.EXPENDITURETYPE?.COPYVALUE:'')"><input
                                id="myInput" readonly disabled
                                value="{{toolTipList?.EXPENDITURETYPE?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field
                        [ngClass]="{'has-error':errorWarningData.Error?.CHEQUENO,'has-warning':errorWarningData.Warning?.CHEQUENO}"
                        appearance="outline" fxFlex="15" class="pr-4">
                        <mat-label>Cheque No</mat-label>
                        <input matInput formControlName="ChequeNo"
                            matTooltip="{{(toolTipList)?toolTipList.CHEQUENO?.HOVER:''}}">
                        <div *ngIf="testBool && toolTipList"
                            class="tooltip-wrap"
                            (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.CHEQUENO?.COPYVALUE:'')"><input
                                id="myInput" readonly disabled
                                value="{{toolTipList?.CHEQUENO?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field
                        [ngClass]="{'has-error':errorWarningData.Error?.PAYEE,'has-warning':errorWarningData.Warning?.PAYEE}"
                        appearance="outline" fxFlex="27" class="pr-4">
                        <mat-label>Payee</mat-label>
                        <!-- <input matInput placeholder="Payee" formControlName="Payee"  [matAutocomplete]="payeeText" matTooltip="{{(toolTipList)?toolTipList.PAYEE?.HOVER:''}}">
                        <mat-autocomplete #payeeText="matAutocomplete" [panelWidth]="400" (optionSelected)="PayeeTypeChange($event.option.value)">
                            <mat-option *ngFor="let val of filteredOptions | async" [value]="val.CONTACTNAME">
                                {{val.CONTACTNAME}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-icon matSuffix>
                            <button class="matternews1 matternews" (click)="ContactMatter()"><img class="ipad-icon"
                                    src="assets/icons/web_app/icon_contact_d.ico" matTooltip=""
                                    matTooltipPosition="above">
                            </button>
                        </mat-icon> -->
                        <mat-chip-list #chipListContact
                            aria-label="Matter selection">
                            <mat-chip *ngFor="let Contactdata of Contactname"
                                (removed)="removeContact(Contactdata)"
                                class="mat-chips-selected">
                                {{Contactdata| slice:0:40}}<ng-container
                                    *ngIf="Contactdata.length>30">...</ng-container>
                                <button matChipRemove class="canclebutton">
                                    <mat-icon
                                        style="font-size: 18px !important;">cancel</mat-icon>
                                </button>
                            </mat-chip>
                            <!-- (keydown.Tab)="Contactdatashow($event)" -->
                            <input matInput
                                matTooltip="{{(toolTipList)?toolTipList.PAYEE?.HOVER:''}}"
                                formControlName="Payee"
                                #MatterInput2 [matAutocomplete]="autoContact"
                                [matChipInputFor]="chipListContact"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                (matChipInputTokenEnd)="addContact($event)"
                                [ngClass]="Contactname.length > 0?'hideInput':'showInput'">
                        </mat-chip-list>
                        <mat-autocomplete #autoContact="matAutocomplete">
                            <mat-option class="mat-option_1"
                                *ngFor="let ContactData of Contactlist"
                                (click)="selectedContact(ContactData)">
                                <!-- {{data.SHORTNAME}}--{{data.MATTER}} -->
                                <span
                                    *ngIf="ContactData && !ContactData?.CONTACTGUID && ContactData.CONTACTNAME"><b>Add
                                    </b></span>&nbsp; <span
                                    *ngIf="ContactData && !ContactData?.CONTACTGUID && ContactData.CONTACTNAME"><b>"</b></span>{{ContactData.CONTACTNAME}}<span
                                    *ngIf="ContactData && !ContactData?.CONTACTGUID && ContactData.CONTACTNAME"><b>"</b></span>
                            </mat-option>
                        </mat-autocomplete>
                        <mat-icon matSuffix
                            (click)="(this.MatterInputContact && this.MatterInputContact.nativeElement.value =='')? ContactMatter():null"
                            class="payee-icon"
                            [ngClass]="appPermissions[3]?.Access==0 ? 'disabled-click large-button' : 'large-button'">
                            <img src="assets/icons/web_app/icon_contact_d.ico">
                        </mat-icon>

                        <div *ngIf="testBool && toolTipList"
                            class="tooltip-wrap"
                            (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PAYEE?.COPYVALUE:'')"><input
                                id="myInput" readonly disabled
                                value="{{toolTipList?.PAYEE?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field
                        [ngClass]="{'has-error':errorWarningData.Error?.SOURCEREFERENCE,'has-warning':errorWarningData.Warning?.SOURCEREFERENCE}"
                        appearance="outline" fxFlex="16" class="pr-4">
                        <mat-label>Invoice #/Source Ref</mat-label>
                        <input matInput formControlName="Invoice"
                            matTooltip="{{(toolTipList)?toolTipList.SOURCEREFERENCE?.HOVER:''}}">
                        <div *ngIf="testBool && toolTipList"
                            class="tooltip-wrap"
                            (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SOURCEREFERENCE?.COPYVALUE:'')"><input
                                id="myInput" readonly disabled
                                value="{{toolTipList?.SOURCEREFERENCE?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center"
                    fxFlex="1 0 auto">
                    <mat-form-field
                        [ngClass]="{'has-error':errorWarningData.Error?.NOTE,'has-warning':errorWarningData.Warning?.NOTE}"
                        appearance="outline" fxFlex="100" class="pr-4">
                        <mat-label *ngIf="classtype !='Matter Expense'">Notes</mat-label>
                        <mat-label *ngIf="classtype ==='Matter Expense'">Invoice
                            Text</mat-label>
                        <textarea formControlName="Notes" matInput
                            matTooltip="{{(toolTipList)?toolTipList.NOTE?.HOVER:''}}"></textarea>
                        <div *ngIf="testBool && toolTipList"
                            class="tooltip-wrap"
                            (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.NOTE?.COPYVALUE:'')"><input
                                id="myInput" readonly disabled
                                value="{{toolTipList?.NOTE?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center"
                    fxFlex="1 0 auto" *ngIf="dataTableHide =='yes'">
                    <mat-card>
                        <div class="customer spend_scroll">
                            <table class="simple"
                                [@animateStagger]="{value:'50'}">
                                <tbody>
                                    <tr class="text-center">
                                        <th>Expenditure Class</th>
                                        <th>{{barristerTitle}}</th>
                                        <th>Amount</th>
                                        <th>GST</th>
                                        <th>Expense A/C</th>
                                        <th>Note</th>
                                        <th><button mat-flat-button
                                                [disabled]="ExpandDivFlag || disableAddButtonFlag"
                                                (click)="createSpendMoney();"
                                                class="ml-4 ng-tns-c142-6 mdc-button spend-money-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary mat-mdc-button-base"><span
                                                    class="mat-mdc-button-persistent-ripple mdc-button__ripple"></span><mat-icon
                                                    role="img"
                                                    class="mat-icon notranslate mat-icon-no-color"
                                                    aria-hidden="true"
                                                    data-mat-icon-type="svg"
                                                    data-mat-icon-name="plus"
                                                    data-mat-icon-namespace="heroicons_outline"><svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                        fit height="100%"
                                                        width="100%"
                                                        preserveAspectRatio="xMidYMid meet"
                                                        focusable="false">
                                                        <path
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            stroke-width="2"
                                                            d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                                                    </svg></mat-icon><span
                                                    class="mdc-button__label"><span
                                                        class="ml-2 mr-1">Add</span></span><span
                                                    class="mat-mdc-focus-indicator"></span><span
                                                    matripple
                                                    class="mat-ripple mat-mdc-button-ripple"></span><span
                                                    class="mat-mdc-button-touch-target"></span></button></th>

                                    </tr>
                                    <ng-container
                                        *ngFor="let item of getDataForTable ; let index = index">
                                        <tr class="text-center"
                                            *ngIf="Object.keys(item).length !=0"
                                            (click)="highlightedRows=index"
                                            [style.background]="highlightedRows == index ? selectedColore : ''"
                                            [ngClass]="highlightedRows == index ? 'row-text-colore' : ''"
                                            matRipple
                                            [@animate]="{value:'*',params:{y:'100%'}}">
                                            <td>{{item.EXPENDITURECLASS}}</td>
                                            <td>{{item.SHORTNAME}}</td>
                                            <td>{{item.AMOUNT}}</td>
                                            <td>{{item.GST}}</td>
                                            <td>{{item.EXPENSEACCOUNTNUMBER}}</td>
                                            <td>{{item.NOTE}}</td>
                                            <td class="spend-last-td">
                                                <div class="ng-tns-c142-6"><button
                                                        mat-stroked-button
                                                        mat-button
                                                        [disabled]="disableAddButtonFlag"
                                                        (click)="editElement();ExpandDiv(index);editMoney(item ,index)"
                                                        class="min-w-10 min-h-7 h-7 px-2 leading-6 spend-money-dropdown ng-tns-c142-6 mdc-button mdc-button--outlined mat-mdc-outlined-button mat-unthemed mat-mdc-button-base"><span
                                                            class="mat-mdc-button-persistent-ripple mdc-button__ripple"></span><mat-icon>{{(index==lengthsize
                                                            && ExpandDivFlag) ?
                                                            'keyboard_arrow_up'
                                                            :
                                                            'keyboard_arrow_down'
                                                            }}</mat-icon><span
                                                            class="mdc-button__label"></span><span
                                                            class="mat-mdc-focus-indicator"></span><span
                                                            matripple
                                                            class="mat-ripple mat-mdc-button-ripple"></span><span
                                                            class="mat-mdc-button-touch-target"></span></button></div>
                                            </td>
                                        </tr>
                                        <ng-container *ngIf="ExpandDivFlag">
                                            <tr *ngIf="index==lengthsize"
                                                class="text-center"
                                                style="box-shadow: 2px 0px 8px 0px #888888;">

                                                <td [attr.colspan]="7">
                                                    <div
                                                        class="display-flex spend-mooney">

                                                        <div class="div"
                                                            style="width: 130px;">
                                                            <mat-form-field
                                                                [ngClass]="{'has-error':errorWarningData.Error?.EXPENDITURECLASS,'has-warning':errorWarningData.Warning?.EXPENDITURECLASS}"
                                                                class="example"
                                                                fxFlex="10"
                                                                class="pr-4 spend-money-field"
                                                                appearance="outline">
                                                                <mat-label>Class</mat-label>
                                                                <mat-select
                                                                    formControlName="Class"
                                                                    (selectionChange)="Classtype($event.value)">
                                                                    <mat-option
                                                                        value="Expense">Expense</mat-option>
                                                                    <mat-option
                                                                        value="Matter Expense">Matter
                                                                        Expense</mat-option>
                                                                    <mat-option
                                                                        value="Capital">Capital</mat-option>
                                                                    <mat-option
                                                                        value="Pay GST">Pay
                                                                        GST</mat-option>
                                                                    <mat-option
                                                                        value="Pay Tax">Pay
                                                                        Tax</mat-option>
                                                                    <mat-option
                                                                        value="Personal">Personal</mat-option>
                                                                    <mat-option
                                                                        value="Depreciation">Depreciation</mat-option>
                                                                    <mat-option
                                                                        value="Other">Other</mat-option>
                                                                </mat-select>
                                                                <div
                                                                    *ngIf="testBool && toolTipList"
                                                                    class="tooltip-wrap"
                                                                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.EXPENDITURECLASS?.COPYVALUE:'')"><input
                                                                        id="myInput"
                                                                        readonly
                                                                        disabled
                                                                        value="{{toolTipList?.EXPENDITURECLASS?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="div">

                                                            <mat-form-field
                                                                class="pr-4 spend-money-field"
                                                                [ngClass]="{
                                                                            'menu-disabled': hide,
                                                                            'has-error': spendmoneyForm.get('Class')?.value == 'Matter Expense' && errorWarningData.Error?.MATTERGUID,
                                                                            'has-warning': errorWarningData.Warning?.MATTERGUID
                                                                            }"
                                                                appearance="outline"
                                                                fxFlex="20">
                                                                <mat-label>Matter</mat-label>
                                                                <mat-chip-list
                                                                    #chipList
                                                                    aria-label="Matter selection">
                                                                    <mat-chip
                                                                        *ngFor="let matterdata of mattername"
                                                                        (removed)="remove(matterdata)"
                                                                        class="mat-chips-selected">
                                                                        {{matterdata|
                                                                        slice:0:50}}<ng-container
                                                                            *ngIf="matterdata.length>50">...</ng-container>
                                                                        <button
                                                                            matChipRemove
                                                                            class="canclebutton"
                                                                            [ngClass]="hide?'visibility-hidden':''">
                                                                            <mat-icon
                                                                                style="font-size: 18px !important;">cancel</mat-icon>
                                                                        </button>
                                                                    </mat-chip>
                                                                    <!-- (keydown.Tab)="datashow($event)" -->
                                                                    <input
                                                                        matInput
                                                                        formControlName="Matter"
                                                                        #MatterInput1
                                                                        [matAutocomplete]="autoMatter"
                                                                        [matChipInputFor]="chipList"
                                                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                                        (matChipInputTokenEnd)="add($event)"
                                                                        (keyup)="onKeyUp($event)"
                                                                        [ngClass]="mattername.length > 0?'hideInput':'showInput'"
                                                                        matTooltip="{{(toolTipList)?toolTipList.SHORTNAME:''}}">
                                                                </mat-chip-list>
                                                                <mat-autocomplete
                                                                    #autoMatter="matAutocomplete"
                                                                    (optionSelected)="selectedValue($event)">
                                                                    <mat-option
                                                                        class="mat-option_1"
                                                                        *ngFor="let data of matterlist"
                                                                        (click)="selectedmatterguid(data)"
                                                                        value="{{data.MATTERGUID}}">
                                                                        <!-- {{data.SHORTNAME}}--{{data.MATTER}} -->{{data.SELECTEDDESCRIPTION}}
                                                                    </mat-option>
                                                                </mat-autocomplete>
                                                                <mat-icon
                                                                    matSuffix
                                                                    [ngClass]="hide?'visibility-hidden':''">
                                                                    <button
                                                                        id="mattersnew"
                                                                        class="matternews"
                                                                        [ngClass]="hide?'menu-disabled':''"
                                                                        (click)='selectMatter()'><img
                                                                            src="assets/icons/web_app/icon_matter_d.ico"
                                                                            matTooltip
                                                                            matTooltipPosition="above">
                                                                    </button>
                                                                </mat-icon>
                                                                <!-- <mat-icon class="matter_icon_timeDiary" matSuffix (click)='selectMatter()'>
                                                                        <img src="assets/icons/web_app/icon_matter_d.ico" matTooltip="select matter"
                                                                            matTooltipPosition="above">
                                                                    </mat-icon> -->
                                                                <div
                                                                    *ngIf="testBool && toolTipList"
                                                                    class="tooltip-wrap"
                                                                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.EXPENDITURECLASS?.COPYVALUE:'')"><input
                                                                        id="myInput"
                                                                        readonly
                                                                        disabled
                                                                        value="{{toolTipList?.EXPENDITURECLASS?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="div">

                                                            <mat-form-field
                                                                [ngClass]="{'has-error':errorWarningData.Error?.AMOUNT,'has-warning':errorWarningData.Warning?.AMOUNT}"
                                                                appearance="outline"
                                                                fxFlex="15"
                                                                class="pr-4 spend-money-field">
                                                                <mat-label>Amount
                                                                    Inc GST</mat-label>
                                                                <!-- <span class="currency_sign" matPrefix>$ &nbsp;</span> -->
                                                                <!-- type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  -->
                                                                <!-- currencyMask -->
                                                                <!-- type="float"
                                                                    (blur)="amountChange($event, 'AmountIncGST')" -->
                                                                <input
                                                                    style="text-align: right;"
                                                                    [(ngModel)]="GSTValForInGst"
                                                                    class="sideText"
                                                                    matInput
                                                                    type="text"
                                                                    [value]="spendmoneyForm.get('AmountIncGST').value | number: '1.2-2'"
                                                                    mask="separator.2" thousandSeparator="," [allowNegativeNumbers]="true" decimalMarker="." 
                                                                    formControlName="AmountIncGST"
                                                                    (focusout)="amountCal()"
                                                                    [leadZero]="true"
                                                                    matTooltip="{{(toolTipList)?toolTipList.AMOUNT:''}}"
                                                                    >
                                                                <div
                                                                    *ngIf="testBool && toolTipList"
                                                                    class="tooltip-wrap"
                                                                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.AMOUNT?.COPYVALUE:'')"><input
                                                                        id="myInput"
                                                                        readonly
                                                                        disabled
                                                                        value="{{toolTipList?.AMOUNT?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="div">
                                                            <mat-form-field
                                                                class="example spend-money-field pr-4"
                                                                fxFlex="15"
                                                                appearance="outline">
                                                                <mat-label>GST
                                                                    Type</mat-label>
                                                                <mat-select
                                                                    formControlName="GSTType"
                                                                    (selectionChange)="GstTypeforSelect($event.value)">
                                                                    <mat-option
                                                                        value="10% GST">10%
                                                                        GST</mat-option>
                                                                    <mat-option
                                                                        value="No GST">No
                                                                        GST</mat-option>
                                                                    <mat-option
                                                                        value="Less Than 10% GST">Less
                                                                        Than 10%
                                                                        GST</mat-option>
                                                                    <mat-option
                                                                        *ngIf="classtype ==='Matter Expense'"
                                                                        value="No GST(Charge GST)">No
                                                                        GST(Charge
                                                                        GST)
                                                                    </mat-option>
                                                                    <mat-option
                                                                        *ngIf="classtype ==='Matter Expense'"
                                                                        value="Less Than 10% GST(Charge GST)">Less
                                                                        Than 10%
                                                                        GST(Charge
                                                                        GST)</mat-option>
                                                                </mat-select>
                                                                <div
                                                                    *ngIf="testBool && toolTipList"
                                                                    class="tooltip-wrap"
                                                                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.GSTTYPE?.COPYVALUE:'')"><input
                                                                        id="myInput"
                                                                        readonly
                                                                        disabled
                                                                        value="{{toolTipList?.GSTTYPE?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                                                            </mat-form-field>
                                                        </div>

                                                        <div class="div">
                                                            <mat-form-field
                                                                [ngClass]="{'has-error':errorWarningData.Error?.GST,'has-warning':errorWarningData.Warning?.GST}"
                                                                appearance="outline"
                                                                fxFlex="10"
                                                                class="pr-4 spend-money-field">
                                                                <mat-label>GST</mat-label>
                                                                <!-- currencyMask -->
                                                                <!-- [disabled]="GstTypeDiff =='Less Than 10% GST' || 'Less Than 10% GST(Charge GST)'" -->
                                                                <input
                                                                    type="text" mask="separator.2" thousandSeparator="," [allowNegativeNumbers]="true" decimalMarker="." 
                                                                    class="sideText"
                                                                    [max]="maxVal"
                                                                    [leadZero]="true"
                                                                    matInput
                                                                    formControlName="GST1"
                                                                    (focusout)="amountCal()"
                                                                    [(ngModel)]="GSTValAfterCal"
                                                                    matTooltip="{{(toolTipList)?toolTipList.GST:''}}">
                                                                <div
                                                                    *ngIf="testBool && toolTipList"
                                                                    class="tooltip-wrap"
                                                                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.GST?.COPYVALUE:'')"><input
                                                                        id="myInput"
                                                                        readonly
                                                                        disabled
                                                                        value="{{toolTipList?.GST?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="div">
                                                            <mat-form-field
                                                                *ngIf="dataTableHide !='yes'"
                                                                appearance="outline"
                                                                fxFlex="15"
                                                                class="pr-4 ">
                                                                <mat-label>Amount
                                                                    Ex GST
                                                                </mat-label>
                                                                <input
                                                                    type="text" mask="separator.2" thousandSeparator="," [allowNegativeNumbers]="true" decimalMarker="." 
                                                                    class="sideText "
                                                                    [leadZero]="true"
                                                                    matInput
                                                                    formControlName="AmountExGST"
                                                                    [(ngModel)]="GSTValForExGst"
                                                                    matTooltip="{{(toolTipList)?toolTipList.GST?.HOVER:''}}">
                                                                <div
                                                                    *ngIf="testBool && toolTipList"
                                                                    class="tooltip-wrap"
                                                                    (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.GST?.COPYVALUE:'')"><input
                                                                        id="myInput"
                                                                        readonly
                                                                        disabled
                                                                        value="{{toolTipList?.GST?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="div display-flex spend-money-lastpoint">
                                                        <div
                                                            class="div display-flex spend-money-lastp">
                                                            <div class="div">
                                                                <mat-form-field
                                                                    [ngClass]="{'has-error':errorWarningData.Error?.EXPENDITUREITEMS,'has-warning':errorWarningData.Warning?.EXPENDITUREITEMS}"
                                                                    appearance="outline"
                                                                    fxFlex="15"
                                                                    class="pr-4 spend-money-field">
                                                                    <mat-label
                                                                        *ngIf="classtype=='Expense' || classtype=='Matter Expense' || classtype=='Depreciation' ||classtype=='Other'">
                                                                        Expense
                                                                        a/c
                                                                    </mat-label>
                                                                    <mat-label
                                                                        *ngIf="classtype=='Capital'">Asset
                                                                        a/c
                                                                    </mat-label>
                                                                    <mat-label
                                                                        *ngIf="classtype=='Pay GST'">GST
                                                                        a/c
                                                                    </mat-label>
                                                                    <mat-label
                                                                        *ngIf="classtype=='Pay Tax'">Tax
                                                                        a/c</mat-label>
                                                                    <mat-label
                                                                        *ngIf="classtype=='Personal'">Equity
                                                                        a/c
                                                                    </mat-label>
                                                                    <!-- <mat-label *ngIf="classtype=='personal'">Expense a/c </mat-label> -->
                                                                    <!-- (keydown.Tab)="getAccountInfo('ExpenseClick')" -->
                                                                    <input
                                                                        matInput
                                                                        formControlName="Expenseac"
                                                                        (keyup.enter)="getAccountInfo('ExpenseClick')"
                                                                        matTooltip="{{(toolTipList)?toolTipList.EXPENDITUREITEMS?.HOVER:''}}">
                                                                    <mat-icon
                                                                        matSuffix>
                                                                        <button
                                                                            id="expac"
                                                                            class="matternews"
                                                                            (click)="BankingDialogOpen('','ExpenseClick',$event)"
                                                                            [disabled]="expac"><img
                                                                                src="assets/icons/web_app/icon_accounts_small_d.ico"
                                                                                matTooltip
                                                                                matTooltipPosition="above"></button>
                                                                    </mat-icon>
                                                                    <div
                                                                        *ngIf="testBool && toolTipList"
                                                                        class="tooltip-wrap"
                                                                        (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.EXPENDITUREITEMS?.COPYVALUE:'')"><input
                                                                            id="myInput"
                                                                            readonly
                                                                            disabled
                                                                            value="{{toolTipList?.EXPENDITUREITEMS?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                                                                </mat-form-field>
                                                            </div>
                                                            <div
                                                                class="div spend-money-note">
                                                                <mat-form-field
                                                                    [ngClass]="{'has-error':errorWarningData.Error?.NOTE,'has-warning':errorWarningData.Warning?.NOTE}"
                                                                    appearance="outline"
                                                                    fxFlex="20"
                                                                    class="pr-4 spend-money-field"
                                                                    *ngIf="dataTableHide=='yes'">
                                                                    <mat-label>Note</mat-label>
                                                                    <input
                                                                        matInput
                                                                        formControlName="Note"
                                                                        matTooltip="{{(toolTipList)?toolTipList.NOTE?.HOVER:''}}">
                                                                    <div
                                                                        *ngIf="testBool && toolTipList"
                                                                        class="tooltip-wrap"
                                                                        (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.NOTE?.COPYVALUE:'')"><input
                                                                            id="myInput"
                                                                            readonly
                                                                            disabled
                                                                            value="{{toolTipList?.NOTE?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="spend-money-btns display-flex spend-money-deupt"
                                                            *ngIf="item.EXPENSEACCOUNTNUMBER && item.AMOUNT">

                                                            <button
                                                                mat-flat-button
                                                                *ngIf='this.f.MultiLineExpense.value'
                                                                mat-button
                                                                (click)="SaveItemDialog()"
                                                                class="ng-tns-c142-6 mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary  spend-money-updates mat-mdc-button-base">
                                                                Update </button>
                                                            <button
                                                                *ngIf='this.f.MultiLineExpense.value'
                                                                mat-button
                                                                (click)="deleteElement()"
                                                                class=" ng-tns-c142-6 mdc-button mat-mdc-button mat-warn mat-mdc-button-base spend-money-deletes">
                                                                Delete </button>
                                                        </div>
                                                        <div
                                                            class="spend-money-btns display-flex spend-money-deupt"
                                                            *ngIf="!item.EXPENSEACCOUNTNUMBER && !item.AMOUNT">
                                                            <button
                                                                mat-flat-button
                                                                *ngIf='this.f.MultiLineExpense.value'
                                                                mat-button
                                                                (click)="SaveItemDialog()"
                                                                class="ng-tns-c142-6 mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary  spend-money-updates mat-mdc-button-base">
                                                                Save </button>
                                                            <button
                                                                *ngIf='this.f.MultiLineExpense.value'
                                                                mat-button
                                                                (click)="deleteElement()"
                                                                class=" ng-tns-c142-6 mdc-button mat-mdc-button mat-warn mat-mdc-button-base spend-money-deletes">
                                                                Cancel </button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>

                                </tbody>
                            </table>
                        </div>
                    </mat-card>

                </div>
                <div *ngIf="!HideButtonsDiv" class="itemdetails" fxLayout="row"
                    fxLayoutAlign="start center"
                    fxFlex="1 0 auto">
                    <mat-form-field
                        [ngClass]="{'has-error':errorWarningData.Error?.EXPENDITURECLASS,'has-warning':errorWarningData.Warning?.EXPENDITURECLASS}"
                        class="example" fxFlex="10" class="pr-4"
                        appearance="outline">
                        <mat-label>Class</mat-label>
                        <mat-select formControlName="Class"
                            (selectionChange)="Classtype($event.value)">
                            <mat-option value="Expense">Expense</mat-option>
                            <mat-option value="Matter Expense"
                                [disabled]="(appPermissions[2] && appPermissions[2]['Create Disbursements']==0)?true:false"
                                [ngClass]="appPermissions[2] && appPermissions[2]['Create Disbursements']==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">Matter
                                Expense</mat-option>
                            <mat-option value="Capital">Capital</mat-option>
                            <mat-option value="Pay GST">Pay GST</mat-option>
                            <mat-option value="Pay Tax">Pay Tax</mat-option>
                            <mat-option value="Personal">Personal</mat-option>
                            <mat-option value="Depreciation">Depreciation</mat-option>
                            <mat-option value="Other">Other</mat-option>
                        </mat-select>
                        <div *ngIf="testBool && toolTipList"
                            class="tooltip-wrap"
                            (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.EXPENDITURECLASS?.COPYVALUE:'')"><input
                                id="myInput" readonly disabled
                                value="{{toolTipList?.EXPENDITURECLASS?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                    </mat-form-field>

                    <mat-form-field class="pr-4"
                        [ngClass]="{
                                     'menu-disabled': hide,
                                     'has-error': spendmoneyForm.get('Class')?.value == 'Matter Expense' && errorWarningData.Error?.MATTERGUID && mattername.length <=0,
                                    'has-warning': errorWarningData.Warning?.MATTERGUID
                                     }"
                        appearance="outline" fxFlex="20">
                        <mat-label>Matter</mat-label>
                        <mat-chip-list #chipList aria-label="Matter selection">
                            <mat-chip *ngFor="let matterdata of mattername"
                                (removed)="remove(matterdata)"
                                class="mat-chips-selected">

                                {{matterdata| slice:0:50}}<ng-container
                                    *ngIf="matterdata.length>50">...</ng-container>
                                <button matChipRemove class="canclebutton"
                                    [ngClass]="hide?'visibility-hidden':''">
                                    <mat-icon
                                        style="font-size: 18px !important;">cancel</mat-icon>
                                </button>
                            </mat-chip>
                            <!-- (keydown.Tab)="datashow($event)" -->
                            <input matInput
                                formControlName="Matter" #MatterInput1
                                [matAutocomplete]="autoMatter"
                                [matChipInputFor]="chipList"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                (matChipInputTokenEnd)="add($event)"
                                (keyup)="onKeyUp($event)"
                                [ngClass]="mattername.length > 0?'hideInput':'showInput'"
                                matTooltip="{{(toolTipList)?toolTipList.SHORTNAME:''}}"
                                [disabled]="hide">
                        </mat-chip-list>
                        <mat-autocomplete #autoMatter="matAutocomplete"
                            (optionSelected)="selectedValue($event)">
                            <mat-option class="mat-option_1"
                                *ngFor="let data of matterlist"
                                (click)="selectedmatterguid(data)"
                                value="{{data.MATTERGUID}}">
                                {{data.SELECTEDDESCRIPTION}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-icon matSuffix
                            [ngClass]="hide?'visibility-hidden':''">
                            <button id="mattersnew" class="matternews"
                                [ngClass]="hide?'menu-disabled':''"
                                (click)='selectMatter()'><img
                                    src="assets/icons/web_app/icon_matter_d.ico"
                                    matTooltip
                                    matTooltipPosition="above">
                            </button>
                        </mat-icon>
                        <div *ngIf="testBool && toolTipList"
                            class="tooltip-wrap"
                            (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.SHORTNAME?.COPYVALUE:'')"><input
                                id="myInput" readonly disabled
                                value="{{toolTipList?.SHORTNAME?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                    </mat-form-field>

                    <mat-form-field
                        [ngClass]="{'has-error':errorWarningData.Error?.AMOUNT,'has-warning':errorWarningData.Warning?.AMOUNT}"
                        appearance="outline" fxFlex="15" class="pr-4">
                        <mat-label>Amount Inc GST</mat-label>
                        <!-- <span class="currency_sign" matPrefix>$ &nbsp;</span> -->
                        <!-- currencyMask -->
                        <!-- (click)="$event.target.select()" -->
                        <!-- [value]="spendmoneyForm.get('AmountIncGST').value | number: '1.2-2'" -->
                        <!-- type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  -->

                        <!-- type="float" -->
                            <!-- (blur)="amountChange($event, 'AmountIncGST')" -->
                        <input style="text-align: right;" [(ngModel)]="GSTValForInGst"
                            class="sideText" matInput
                            type="text"
                            [value]="spendmoneyForm.get('AmountIncGST').value | number: '1.2-2'"
                            mask="separator.2" thousandSeparator="," [allowNegativeNumbers]="true" decimalMarker="." 
                            [leadZero]="true"
                            formControlName="AmountIncGST" (focusout)="amountCal()"
                            matTooltip="{{(toolTipList)?toolTipList.AMOUNT:''}}">
                        <div *ngIf="testBool && toolTipList"
                            class="tooltip-wrap"
                            (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.AMOUNT?.COPYVALUE:'')"><input
                                id="myInput" readonly disabled
                                value="{{toolTipList?.AMOUNT?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field class="example" fxFlex="15" class="pr-4"
                        appearance="outline">
                        <mat-label>GST Type</mat-label>
                        <mat-select formControlName="GSTType"
                            (selectionChange)="GstTypeforSelect($event.value)">
                            <mat-option value="10% GST">10% GST</mat-option>
                            <mat-option value="No GST">No GST</mat-option>
                            <mat-option value="Less Than 10% GST">Less Than 10%
                                GST</mat-option>
                            <mat-option *ngIf="classtype =='Matter Expense'"
                                value="No GST(Charge GST)">No
                                GST(Charge
                                GST)
                            </mat-option>
                            <mat-option *ngIf="classtype ==='Matter Expense'"
                                value="Less Than 10% GST(Charge GST)">Less
                                Than 10% GST(Charge GST)</mat-option>
                        </mat-select>
                        <div *ngIf="testBool && toolTipList"
                            class="tooltip-wrap"
                            (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.GSTType?.COPYVALUE:'')"><input
                                id="myInput" readonly disabled
                                value="{{toolTipList?.GSTType?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field
                        [ngClass]="{'has-error':errorWarningData.Error?.GST,'has-warning':errorWarningData.Warning?.GST}"
                        appearance="outline" fxFlex="10" class="pr-4">
                        <mat-label>GST</mat-label>
                        <!-- currencyMask -->
                        <!-- (click)="$event.target.select()" -->
                        <!-- type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  -->
                        <!-- (blur)="amountChange($event, 'GST1')" -->
                        
                        <!-- [value]="spendmoneyForm.get('GST1').value | number: '1.2-2'" -->
                        <!-- [disabled]="GstTypeDiff =='Less Than 10% GST' || 'Less Than 10% GST(Charge GST)'" -->
                        <input class="sideText" [max]="maxVal"
                            matInput
                            type="text"
                            mask="separator.2" thousandSeparator="," [allowNegativeNumbers]="true" decimalMarker="." 
                            [leadZero]="true"
                            formControlName="GST1" (focusout)="amountCal()"
                            [(ngModel)]="GSTValAfterCal"
                            matTooltip="{{(toolTipList)?toolTipList.GST:''}}">
                        <div *ngIf="testBool && toolTipList"
                            class="tooltip-wrap"
                            (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.GST?.COPYVALUE:'')"><input
                                id="myInput" readonly disabled
                                value="{{toolTipList?.GST?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field *ngIf="dataTableHide !='yes'"
                        appearance="outline" fxFlex="15" class="pr-4 ">
                        <mat-label>Amount Ex GST </mat-label>
                        <!-- type="text" mask="separator.2" thousandSeparator="," decimalMarker="."  -->
                        <!-- (blur)="amountChange($event, 'AmountExGST')" -->
                        <!-- [value]="spendmoneyForm.get('AmountExGST').value | number: '1.2-2'" -->
                        <input currencyMask class="sideText " matInput
                            formControlName="AmountExGST"
                            type="text" mask="separator.2" thousandSeparator="," [allowNegativeNumbers]="true" decimalMarker="." 
                            [leadZero]="true"
                            [(ngModel)]="GSTValForExGst"
                            matTooltip="{{(toolTipList)?toolTipList.GST?.HOVER:''}}">
                        <div *ngIf="testBool && toolTipList"
                            class="tooltip-wrap"
                            (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.AmountExGST?.COPYVALUE:'')"><input
                                id="myInput" readonly disabled
                                value="{{toolTipList?.AmountExGST?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field
                        [ngClass]="{'has-error':errorWarningData.Error?.EXPENDITUREITEMS,'has-warning':errorWarningData.Warning?.EXPENDITUREITEMS}"
                        appearance="outline" fxFlex="15" class="pr-4">
                        <mat-label
                            *ngIf="classtype=='Expense' || classtype=='Matter Expense' || classtype=='Depreciation' ||classtype=='Other'">
                            Expense a/c </mat-label>
                        <mat-label *ngIf="classtype=='Capital'">Asset a/c
                        </mat-label>
                        <mat-label *ngIf="classtype=='Pay GST'">GST a/c
                        </mat-label>
                        <mat-label *ngIf="classtype=='Pay Tax'">Tax a/c</mat-label>
                        <mat-label *ngIf="classtype=='Personal'">Equity a/c
                        </mat-label>
                        <!-- <mat-label *ngIf="classtype=='personal'">Expense a/c </mat-label> -->
                        <!-- (keydown.Tab)="getAccountInfo('ExpenseClick')" -->
                        <input matInput formControlName="Expenseac"
                            (keyup.enter)="getAccountInfo('ExpenseClick')"
                            matTooltip="{{(toolTipList)?toolTipList.EXPENDITUREITEMS?.HOVER:''}}">
                        <mat-icon matSuffix>
                            <button id="expac" class="matternews"
                                (click)="BankingDialogOpen('','ExpenseClick',$event)"
                                [disabled]="expac"><img
                                    src="assets/icons/web_app/icon_accounts_small_d.ico"
                                    matTooltip
                                    matTooltipPosition="above"></button>
                        </mat-icon>
                        <div *ngIf="testBool && toolTipList"
                            class="tooltip-wrap"
                            (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.EXPENDITUREITEMS?.COPYVALUE:'')"><input
                                id="myInput" readonly disabled
                                value="{{toolTipList?.EXPENDITUREITEMS?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                    </mat-form-field>
                    <mat-form-field
                        [ngClass]="{'has-error':errorWarningData.Error?.NOTE,'has-warning':errorWarningData.Warning?.NOTE}"
                        appearance="outline" fxFlex="20" class="pr-4"
                        *ngIf="dataTableHide=='yes'">
                        <mat-label>Note</mat-label>
                        <input matInput formControlName="Note"
                            matTooltip="{{(toolTipList)?toolTipList.NOTE?.HOVER:''}}">
                        <div *ngIf="testBool && toolTipList"
                            class="tooltip-wrap"
                            (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.NOTE?.COPYVALUE:'')"><input
                                id="myInput" readonly disabled
                                value="{{toolTipList?.NOTE?.COPYVALUE}}"><mat-icon>file_copy</mat-icon></div>
                    </mat-form-field>
                </div>
            </form>
        </div>
    </div>
    <mat-dialog-actions align="end" class="popup_ftr">
        <div class="right_popup_btn">
            <!-- <ng-container *ngIf="!HideButtonsDiv">
            <button [disabled]="Main3btn ==='disabled'" v mat-button (click)="AddMoneyItem()"
                *ngIf='this.f.MultiLineExpense.value || !HideButtonsDiv'>
                <section class="large-button-container">
                    <mat-icon class="main-icon">
                        <img src="assets/icons/web_app/icon_letters_new_d.ico" matTooltip="" matTooltipPosition="above">
                    </mat-icon>
                    <div class="main-text">
                        <span>Add</span>
                    </div>
                </section>
            </button>
            <button [disabled]="Main3btn ==='disabled' || getDataForTable.length === 0"
                *ngIf='this.f.MultiLineExpense.value || !HideButtonsDiv' mat-button (click)="editElement()">
                <section class="large-button-container">
                    <mat-icon class="main-icon">
                        <img src="assets/icons/web_app/icon_letters_edit_d.ico" matTooltip="" matTooltipPosition="above">
                    </mat-icon>
                    <div class="main-text">
                        <span>Edit</span>
                    </div>
                </section>
            </button>
            <button [disabled]="Main3btn ==='disabled' || getDataForTable.length ===0" *ngIf='this.f.MultiLineExpense.value || !HideButtonsDiv'
                mat-button (click)="deleteElement()">
                <section class="large-button-container">
                    <mat-icon class="main-icon">
                        <img src="assets/icons/web_app/icon_letters_delete_d.ico">
                    </mat-icon>
                    <div class="main-text">
                        <span>Delete</span>
                    </div>
                </section>
            </button>
            <button [disabled]="SubMain2btn === 'disabled'" *ngIf="dataTableHide==='yes' || !HideButtonsDiv" mat-button
                (click)="SaveItemDialog()">
                <section class="large-button-container">
                    <mat-icon class="main-icon">
                        <img src="assets/icons/web_app/icon_save_d.ico" matTooltip="Save" matTooltipPosition="above">
                    </mat-icon>
                    <div class="main-text">
                        <span>Save</span>
                    </div>
                </section>
            </button>
            <button [disabled]="SubMain2btn === 'disabled'" *ngIf="dataTableHide==='yes' || !HideButtonsDiv" mat-button
                (click)="CancelItemDialog()">
                <section class="large-button-container">
                    <mat-icon class="main-icon">
                        <img class='clnicon' src="assets/icons/web_app/icon_generic_delete_small_o.ico">
                    </mat-icon>
                    <div class="main-text">
                        <span>Cancel</span>
                    </div>
                </section>
            </button>
        </ng-container> -->
            <button mat-raised-button color="accent" class="mat-accent"
                (click)="FinalSaveData()" [disabled]="isspiner"
                *ngIf="action !== 'edit'">
                <mat-spinner
                    style=" zoom: 0.2;position: absolute;right: 0;left: 0;
                margin: auto;top: 0;bottom: 0;" *ngIf="isspiner"></mat-spinner>Save
            </button>
            <button mat-raised-button color="accent" class="mat-accent"
                (click)="FinalSaveData()" [disabled]="isspiner"
                *ngIf="action === 'edit'">
                <mat-spinner
                    style=" zoom: 0.2;position: absolute;right: 0;left: 0;
                margin: auto;top: 0;bottom: 0;" *ngIf="isspiner"></mat-spinner>Update
            </button>
            <button mat-raised-button color="primary" [mat-dialog-close]="false"
                cdkFocusInitial>Don't Save</button>
        </div>
    </mat-dialog-actions>
</div>

<!-- </form> -->
