import {
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
    Injectable,
    ViewChild,
    ElementRef,
    ChangeDetectorRef,
} from "@angular/core";
import { firstValueFrom, Subject, Subscription, tap } from "rxjs";
import * as _ from "lodash";
import * as $ from "jquery";
import { environment } from "src/environments/environment";
import { FuseConfigService } from "src/@fuse/services/config.service";
import { FuseSidebarService } from "src/@fuse/components/sidebar/sidebar.service";
import { navigation } from "src/app/navigation/navigation";
import { ActivatedRoute, Router } from "@angular/router";
// import { ContactDialogComponent } from "./../../../main/pages/contact/contact-dialog/contact-dialog.component";
import { LicenceAgreementComponent } from "../../../main/licence-agreement/licence-agreement.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { FuseConfirmDialogComponent } from "src/@fuse/components/confirm-dialog/confirm-dialog.component";
// import { ContactCorresDetailsComponent } from 'src/app/main/pages/contact/contact-corres-details/contact-corres-details.component';
import { ReportsComponent } from "src/app/main/reports/reports.component";
import { ToastrService } from "ngx-toastr";
// import { TimeEntriesComponent } from 'src/app/main/pages/time-entries/time-entries.component';
// import { TimeEntryDialogComponent } from "src/app/main/pages/time-entries/time-entry-dialog/time-entry-dialog.component";
// import { MatterPopupComponent } from "src/app/main/pages/matters/matter-popup/matter-popup.component";
// import { MatterDialogComponent } from 'src/app/main/pages/time-entries/matter-dialog/matter-dialog.component';
// import { ReceiptDilogComponent } from 'src/app/main/pages/invoice/receipt-dilog/receipt-dilog.component';
// import { InvoiceDetailComponent } from 'src/app/main/pages/invoice/invoice-detail/invoice-detail.component';
// import { SpendMoneyAddComponent } from "src/app/main/pages/spend-money/spend-money-add-dialog/spend-money-add.component";
// import { GeneralReceiptDilogComponent } from 'src/app/main/pages/receive-money/general-receipt-dilog/general-receipt-dilog.component';
// import { InstantInvoiceDailogComponent } from "src/app/main/pages/invoice/instant-invoice-dailog/instant-invoice-dailog.component";
// import { InvoiceAddDailogComponent } from 'src/app/main/pages/invoice/invoice-add-dailog/invoice-add-dailog.component';
// import { MatterDialogComponentForTemplate } from 'src/app/main/pages/template/matter-dialog/matter-dialog.component';

// import { UserDialogComponent } from './../../../main/pages/users/user-dialog/user-dialog.component';
// import { ActivityDialogComponent } from './../../../main/pages/activities/activity-dialog/activity-dialog.component';
import { ChangePasswordComponent } from "src/app/main/change-password/change-password.component";

// import { DocumentDailogComponent } from './../../../main/pages/document-register/document-dailog/document-dailog.component';
// import { EmailDailogComponent } from './../../../main/pages/template/email-templete/email-dailog/email-dailog.component';
// import { PacksDailogComponent } from './../../../main/pages/template/packs/packs-dailog/packs-dailog.component';
// import { ChartAcDailogComponent } from './../../../main/pages/chart-account/chart-ac-dailog/chart-ac-dailog.component';
// import { FileNoteDialogComponent } from "src/app/main/pages/matters/file-note-dialog/file-note-dialog.component";
// import { BankingDialogComponent } from 'src/app/main/pages/banking/banking-dialog.component';
// import { GeneralDailogComponent } from './../../../main/pages/general-journal/general-dailog/general-dailog.component';
// import { AuthorityDialogComponent } from 'src/app/main/pages/main-authorities/authority-dialog/authority-dialog.component';
// import { ReportFilterComponent } from './../../../main/pages/general-journal/report-filter/report-filter.component';
// import { ChronItemDailogComponent } from './../../../main/pages/legal-details/chronology/chron-item-dailog/chron-item-dailog.component';
// import { DairyDailogComponent } from './../../../main/pages/diary/dairy-dailog/dairy-dailog.component';
// import { ResumeTimerComponent } from 'src/app/main/pages/time-entries/resume-timer/resume-timer.component';
// import { CopyTemplateComponent } from 'src/app/main/pages/template/template-list/copy-template/copy-template.component';
// import { SetLetterHeadComponent } from 'src/app/main/pages/template/template-list/set-letterhead/set-letterhead.component';
// import { EditTemplateComponent } from 'src/app/main/pages/template/template-list/edit-template/edit-template.component';
// import { WriteOffTimeEntryComponent } from 'src/app/main/pages/time-entries/write-off-time-entry/write-off-time-entry.component';
// import { SafeCustodyDialogeComponent } from 'src/app/main/pages/legal-details/safecustody/safe-custody-dialog/safe-custody-dialog.component';
// import { TrustMoneyDialogeComponent } from 'src/app/main/pages/Trust Accounts/trust-money/trust-money-dialoge/trust-money-dialoge.component';
import { ContactSelectDialogComponent } from "src/app/main/pages/contact/contact-select-dialog/contact-select-dialog.component";
// import { TaskDialogeComponent } from 'src/app/main/pages/Task/task-dialoge/task-dialoge.component';
// import { WriteOffInvoiceComponent } from 'src/app/main/pages/invoice/newWriteOffInvoice/newWriteOffInvoice.component';
// import { TopicDialogComponent } from 'src/app/main/pages/main-authorities/topic/topic-dialog/topic-dialog.component';
// import { AuthorityDialogComponent } from 'src/app/main/pages/globally-Authority/main-authorities/authority-dialog/authority-dialog.component';
// import { TopicDialogComponent } from 'src/app/main/pages/globally-Authority/main-authorities/topic/topic-dialog/topic-dialog.component';
// import { EstimateDilogComponent } from 'src/app/main/pages/time-billing/estimate/estimate-dilog/estimate-dilog.component';
// import { GenerateInvoiceComponent } from 'src/app/main/pages/invoice/generate-invoice/generate-invoice.component';
// import { PacketsDialogComponent } from 'src/app/main/pages/globally-safecustody/packets/packets-dialog/packets-dialog.component';
// import { TrustChartOfAccountDailogComponent } from 'src/app/main/pages/trust-chart-of-account/trust-chart-of-account-dailog/trust-chart-of-account-dailog.component'
import { round } from "lodash";
// import { ReCalcTimeEntriesDialogeComponent } from 'src/app/main/pages/time-billing/re-calc-timeEntrie-dialoge/re-calc-timeEntrie-dialoge.component';
import { MatterFolderDailogComponent } from "./matter-folder-dailog/matter-folder-dailog.component";
// import { AdjustmentDialogComponent } from "src/app/main/pages/account-reconciliation/adjustment-dialog/adjustment-dialog.component";
// import { ActivitySundryDialogComponent } from 'src/app/main/pages/activities/activity-sundry-dialog/activity-sundry-dialog.component';
// import { MoveEntryComponent } from "src/app/main/pages/time-entries/move-entry/move-entry.component";
// import { SendEmailComponent } from "src/app/main/pages/template/email-templete/send-email/send-email.component";
// import { CalculateInterestPopupComponent } from 'src/app/main/pages/invoice/calculate-interest-popup/calculate-interest-popup.component';
// import { CheckForCreditsComponent } from 'src/app/main/pages/invoice/check-for-credits/check-for-credits.component';
// import { AllowancePopupComponent } from 'src/app/main/pages/legal-details/settlement/allowance-popup/allowance-popup.component';
// import { ChequePopupComponent } from 'src/app/main/pages/legal-details/settlement/cheque-popup/cheque-popup.component';
// import { GenerateDocumentPopupComponent } from 'src/app/main/pages/template/generate-document-popup/generate-document-popup.component';
// import { GeneratePacksPopupComponent } from 'src/app/main/pages/template/packs/generate-packs-popup/generate-packs-popup.component';
import { ConfirmPopupComponent } from "src/app/main/confirm-popup/confirm-popup.component";
// import { SelectBankingDialogComponent } from 'src/app/main/pages/banking/select-banking-dialog/select-banking-dialog.component';
import { EndOfMonthReportsComponent } from "src/app/main/end-of-month-reports/end-of-month-reports.component";
import { SyncToXeroPopupComponent } from "src/app/main/sync-to-xero-popup/sync-to-xero-popup.component";
import { UploadTemplatesPopupComponent } from "src/app/main/upload-templates-popup/upload-templates-popup.component";
// import { UserSelectPopupComponent } from 'src/app/main/pages/matters/user-select-popup/user-select-popup.component';
// import { FolderCreateComponent } from 'src/app/main/pages/template/template-list/folder-create/folder-create.component';
// import { CloseYearAccountsDialogComponent } from 'src/app/main/pages/close-year-accounts-dialog/close-year-accounts-dialog.component';
// import { SignaturesPopupComponent } from 'src/app/main/pages/inbox/signatures-popup/signatures-popup.component';
// import { RenameEmailComponent } from 'src/app/main/pages/inbox/rename-email/rename-email.component';
// import { SelectEmailPopupComponent } from 'src/app/main/pages/template/packs/select-email-popup/select-email-popup.component';
// import { EstateAssetsComponent } from 'src/app/main/pages/legal-details/estate/estate-assets/estate-assets.component';
// import { EstateLiabilityComponent } from 'src/app/main/pages/legal-details/estate/estate-liability/estate-liability.component';
// import { EstateBeneficiaryComponent } from 'src/app/main/pages/legal-details/estate/estate-beneficiary/estate-beneficiary.component';
// import { EstateDetailsComponent } from 'src/app/main/pages/legal-details/estate/estate-details/estate-details.component';
import { DomSanitizer } from "@angular/platform-browser";
// import { SplitTimeEntryComponent } from 'src/app/main/pages/split-time-entry/split-time-entry.component';
import { Location } from "@angular/common";
// import { DeliverableDailogComponent } from 'src/app/main/pages/time-entries/deliverable-dailog/deliverable-dailog.component';
// import { SearchandreplaceworkitemComponent } from 'src/app/main/pages/time-billing/searchandreplaceworkitem/searchandreplaceworkitem.component';
// import { LoggedonUserComponent } from 'src/app/main/pages/account-managment/loggedon-user/loggedon-user.component';
// import { ProtectedTrustDialogComponent } from 'src/app/main/pages/Trust Accounts/trust-money/protected-trust-dialog/protected-trust-dialog.component';
import { Chart, registerables } from "chart.js";
import { ToolbarServiceService } from "./toolbar-service.service";
// import { CutofdateComponent } from 'src/app/main/pages/cutofdate/cutofdate.component';
// import { FirstTimeLoginBasicInformationComponent } from 'src/app/main/pages/first-time-login-basic-information/first-time-login-basic-information.component';
// import { ConnectBankFeedComponent } from 'src/app/main/pages/chart-account/connect-bank-feed/connect-bank-feed.component';
import { LogoutErrorComponent } from "src/app/main/logout-error/logout-error.component";
import { GlobalFunctionsService } from "@_services/global-functions.service";
import { AuthenticationService } from "@_services/authentication.service";
import { TimersService } from "@_services/timers.service";
import { MainAPiServiceService } from "@_services/main-api-service.service";
import { BehaviorService } from "@_services/Behavior.service";
import { TimeEntriesComponent } from "@pages/time-entries/time-entries.component";
import { ActivityDialogComponent } from "@pages/activities/activity-dialog/activity-dialog.component";
import { ActivitySundryDialogComponent } from "@pages/activities/activity-sundry-dialog/activity-sundry-dialog.component";
import { UserDialogComponent } from "@pages/users/user-dialog/user-dialog.component";
import { ResumeTimerComponent } from "@pages/time-entries/resume-timer/resume-timer.component";
import { SplitTimeEntryComponent } from "@pages/split-time-entry/split-time-entry.component";
import { DeliverableDailogComponent } from "@pages/time-entries/deliverable-dailog/deliverable-dailog.component";
import { WriteOffTimeEntryComponent } from "@pages/time-entries/write-off-time-entry/write-off-time-entry.component";
import { GenerateInvoiceComponent } from "@pages/invoice/generate-invoice/generate-invoice.component";
import { GenerateDocumentPopupComponent } from "@pages/template/generate-document-popup/generate-document-popup.component";
import { EstimateDilogComponent } from "@pages/time-billing/estimate/estimate-dilog/estimate-dilog.component";
import { DocumentDailogComponent } from "@pages/document-register/document-dailog/document-dailog.component";
import { GeneratePacksPopupComponent } from "@pages/template/packs/generate-packs-popup/generate-packs-popup.component";
import { MatterDialogComponentForTemplate } from "@pages/template/matter-dialog/matter-dialog.component";
import { EmailDailogComponent } from "@pages/template/email-templete/email-dailog/email-dailog.component";
import { PacksDailogComponent } from "@pages/template/packs/packs-dailog/packs-dailog.component";
import { MatterDialogComponent } from "@pages/time-entries/matter-dialog/matter-dialog.component";
import { ConnectBankFeedComponent } from "@pages/chart-account/connect-bank-feed/connect-bank-feed.component";
import { ChartAcDailogComponent } from "@pages/chart-account/chart-ac-dailog/chart-ac-dailog.component";
import { AuthorityDialogComponent } from "@pages/globally-Authority/main-authorities/authority-dialog/authority-dialog.component";
import { TopicDialogComponent } from "@pages/globally-Authority/main-authorities/topic/topic-dialog/topic-dialog.component";
import { GeneralDailogComponent } from "@pages/general-journal/general-dailog/general-dailog.component";
import { ReportFilterComponent } from "@pages/general-journal/report-filter/report-filter.component";
import { BankingDialogComponent } from "@pages/banking/banking-dialog.component";
import { CloseYearAccountsDialogComponent } from "@pages/close-year-accounts-dialog/close-year-accounts-dialog.component";
import { ChronItemDailogComponent } from "@pages/legal-details/chronology/chron-item-dailog/chron-item-dailog.component";
import { AllowancePopupComponent } from "@pages/legal-details/settlement/allowance-popup/allowance-popup.component";
import { ChequePopupComponent } from "@pages/legal-details/settlement/cheque-popup/cheque-popup.component";
import { DairyDailogComponent } from "@pages/diary/dairy-dailog/dairy-dailog.component";
import { UserSelectPopupComponent } from "@pages/matters/user-select-popup/user-select-popup.component";
import { InvoiceAddDailogComponent } from "@pages/invoice/invoice-add-dailog/invoice-add-dailog.component";
import { MoveEntryComponent } from "@pages/time-entries/move-entry/move-entry.component";
import { ReceiptDilogComponent } from "@pages/invoice/receipt-dilog/receipt-dilog.component";
import { GeneralReceiptDilogComponent } from "@pages/receive-money/general-receipt-dilog/general-receipt-dilog.component";
import { WriteOffInvoiceComponent } from "@pages/invoice/newWriteOffInvoice/newWriteOffInvoice.component";
import { InvoiceDetailComponent } from "@pages/invoice/invoice-detail/invoice-detail.component";
import { CheckForCreditsComponent } from "@pages/invoice/check-for-credits/check-for-credits.component";
import { AdjustmentDialogComponent } from "@pages/account-reconciliation/adjustment-dialog/adjustment-dialog.component";
import { FirstTimeLoginBasicInformationComponent } from "@pages/first-time-login-basic-information/first-time-login-basic-information.component";
import { TaskDialogeComponent } from "@pages/Task/task-dialoge/task-dialoge.component";
import { PacketsDialogComponent } from "@pages/globally-safecustody/packets/packets-dialog/packets-dialog.component";
import { SafeCustodyDialogeComponent } from "@pages/legal-details/safecustody/safe-custody-dialog/safe-custody-dialog.component";
import { TrustChartOfAccountDailogComponent } from "@pages/trust-chart-of-account/trust-chart-of-account-dailog/trust-chart-of-account-dailog.component";
import { CopyTemplateComponent } from "@pages/template/template-list/copy-template/copy-template.component";
import { SetLetterHeadComponent } from "@pages/template/template-list/set-letterhead/set-letterhead.component";
import { EditTemplateComponent } from "@pages/template/template-list/edit-template/edit-template.component";
import { TrustMoneyDialogeComponent } from "@pages/Trust Accounts/trust-money/trust-money-dialoge/trust-money-dialoge.component";
import { ReCalcTimeEntriesDialogeComponent } from "@pages/time-billing/re-calc-timeEntrie-dialoge/re-calc-timeEntrie-dialoge.component";
import { SearchandreplaceworkitemComponent } from "@pages/time-billing/searchandreplaceworkitem/searchandreplaceworkitem.component";
import { ContactCorresDetailsComponent } from "@pages/contact/contact-corres-details/contact-corres-details.component";
import { SelectBankingDialogComponent } from "@pages/banking/select-banking-dialog/select-banking-dialog.component";
import { CalculateInterestPopupComponent } from "@pages/invoice/calculate-interest-popup/calculate-interest-popup.component";
import { FolderCreateComponent } from "@pages/template/template-list/folder-create/folder-create.component";
import { SendEmailComponent } from "@pages/template/email-templete/send-email/send-email.component";
import { SelectEmailPopupComponent } from "@pages/template/packs/select-email-popup/select-email-popup.component";
import { SignaturesPopupComponent } from "@pages/inbox/signatures-popup/signatures-popup.component";
import { RenameEmailComponent } from "@pages/inbox/rename-email/rename-email.component";
import { EstateAssetsComponent } from "@pages/legal-details/estate/estate-assets/estate-assets.component";
import { EstateLiabilityComponent } from "@pages/legal-details/estate/estate-liability/estate-liability.component";
import { EstateBeneficiaryComponent } from "@pages/legal-details/estate/estate-beneficiary/estate-beneficiary.component";
import { EstateDetailsComponent } from "@pages/legal-details/estate/estate-details/estate-details.component";
import { LoggedonUserComponent } from "@pages/account-managment/loggedon-user/loggedon-user.component";
import { ProtectedTrustDialogComponent } from "@pages/Trust Accounts/trust-money/protected-trust-dialog/protected-trust-dialog.component";
import { CutofdateComponent } from "@pages/cutofdate/cutofdate.component";
import { TierPermissionPipe } from "@_pipes/tier-permission.pipe";
import { BankFeedInstructionsComponent } from "@pages/bank-feed/bank-feed-instructions/bank-feed-instructions.component";
import { UnpaidAccountComponent } from "@pages/spend-money/batch-payment/unpaid-account/unpaid-account.component";
import { HttpClient } from "@angular/common/http";
import { FileSaverService } from "ngx-filesaver";
import { SocketService } from "@_services/socket.service";
Chart.register(...registerables);
declare global {
    interface Window {
        accoutnDataType: any;
    }
}
@Component({
    selector: "toolbar",
    templateUrl: "./toolbar.component.html",
    styleUrls: ["./toolbar.component.scss"],
    providers: [TierPermissionPipe],
    encapsulation: ViewEncapsulation.None,
})
@Injectable()
export class ToolbarComponent implements OnInit, OnDestroy {
    diary = this.tierpermission.transform("Diary");
    fileNotes = this.tierpermission.transform("File Notes");
    tasks = this.tierpermission.transform("Tasks");

    ActiveConflictActionType: any = null;
    // dataFrimDashboard :any ={}
    preferenceObjectData: any;
    EndOfMonthData: any;
    EndOfMonthDataSelected: any;
    DeliverableField: any;
    settlementAllowances: any;
    estate: any;
    AccountId: any;
    accountClassName: string;
    isPastRecouncile: any;
    isSerachingDataShow: any;
    isSerachingData: any;
    isspiner: boolean = false;
    isLoadingResults: boolean = false;
    SetUpGuideData: any;
    RequestGuid: any;
    allowancesData: any;
    chequeData: any;
    DiaryRowData: any;
    setedDiaryViewtype: any;
    matterData: any;
    ShowMatterPopupFlag: any;
    ProtectedTrustList: any;
    BatchPaymentData
    gloableWindowName: any;
    matterselected: any;
    MainAuthorityData: any;
    activeMenu: any;
    target: any;
    TopicMaindata: any;
    LegalAuthorityData: any;
    LegalAuthotool: string;
    LegalAuthoritySubAuthata: any;
    LegalSubAuthotool: any;
    mainlegalAuthWebUrl: any;
    recouncileItemdata: any;
    ShowGenerateInvoice: string;
    DairyData: any;
    JournalData: any;
    receiptData: any;
    receipTitle: any = "View";
    DisabledReceiptTool: string;
    PacketsData: any;
    CurrentDate: any;
    PathOfRouter: any;
    SafeCustodyData: any;
    ShowMatLable: any;
    ShowTranstionsLable: any;
    ChartHandlingData: any = [];
    TotalUnbilledWIP: any = 0;
    TotalOutstanding: any = 0;
    WorkInProgressData: any;
    CommonToolbarHSData: any;
    ClickTypeForTrustChartHD: any;
    AccountClassForTrustChartHD: any;
    isMatterData: string;
    selectedChequeStatment: any;
    selectedAllowances: any;
    isContactData: string;
    isTimeEntryData: string;
    isInvoiceData: string;
    isSpendMoneyData: string;
    isReceiptData: string;
    isTaskData: string;
    isChartaccountData: string;
    isSafeCustody: string;
    isSafePackData: string;
    isGeneralJounralData: string;
    isUserData: string;
    isEstimateData: string;
    isdeliverable: string;
    isMatterInvoiceData: string;
    isWIPData: string;
    isChronoData: string;
    isFileNoteData: string;
    isDocRegData: string;
    isDiaryData: string;
    isActivityData: string;
    isTrustMoneyList: boolean = false;
    isTrustMoneyListData: any;
    DocumentRegisterSelectedFolder: any = [];
    // [x: string]: any;
    sub2: Subscription;
    AUTHORISEIFRAMEURL: string = '';
    url: any;
    isShownBulkInvoice:boolean=false;
    disable:boolean=false
    isGenrateReport: boolean = false;
    appPermissions: any = JSON.parse(localStorage.getItem("app_permissions"));
    SnapShotAppPermissions: boolean = false;
    @ViewChild(TimeEntriesComponent, { static: false })
    TimeEntrieschild: TimeEntriesComponent;
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    navigation: any;
    selectedLanguage: any;
    selectedIndex: number;
    currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
    isTabShow: number;
    ENDOFMONTHDATE: any = {};
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    confirmDialogRef2: MatDialogRef<ConfirmPopupComponent>;
    confirmDialogRef3: MatDialogRef<EndOfMonthReportsComponent>;
    dialogRef: MatDialogRef<LogoutErrorComponent>;
    //timer setting
    timerId: any;
    TotalTimer: number = 0;
    prevMatterArray: any[] = [];
    timerInterval: any;
    currentTimer: any = 0;
    currentTimerHMS: any;
    ReportListObj: any[] = [];
    getContactData: any;
    detailwidth: any;
    activeSubMenu: any = "";
    isInvoice: any;
    isforCredits: string;
    greenTheme: any = false;
    CreatDocumentChild: any;
    TemplateGenerateData: any = [];
    // Private
    private _unsubscribeAll: Subject<any>;
    activedocument: any;
    clickedBtn: string;
    hideShowDoc: string;
    isDocumentGenerateHide: string;
    templateRoter: string;
    spendMoneyMenu: string;
    emailrouting: string;
    precedentsRouting: string; // add by web27
    TemplateUrlHandel: string;
    packrouting: string;
    KitName: any;
    KitGUid: any;
    packsToobar: string;
    EmailtemplateData: any = [];
    SendMoney_dataGUID: any;
    DocRegData: any = [];
    AccountGUID: any;
    chartAccountDetail: any;
    isMainAccount: boolean = false;
    isAccountEditable: boolean = false;
    TaskData: any;
    FileNotesData: any;
    conflictData: any;
    ChronologyLegalData: any;
    disconflictToolbar: string;
    DisEnTimeEntryToolbar: string;
    timeEntryData: any;
    DisMainAuthorityToolbar: string;
    estimateData: any;
    deliverableData: any;
    journalText: any = "View";
    journalLinktype: any;
    storeFolder: any;
    recouncileTab: any;
    isStoreTrustVal: any;
    valOfIsTrust: any;
    storePastRecouncileData: any;
    tabName: any;
    courtFormsRouting: any;
    courtFormData: any;
    TrustEndofMonthReport: any;
    isTrustEndofMonthReport: boolean = false;
    selectedMatterRow: any;
    toolbarsetting: any;
    isDocTempletFolder: boolean = true;
    isDocTempletType: boolean = false;
    isOpenDesktopWord: boolean = false;
    isUnusedAdjustment: boolean = false;
    isClickTypeTrust: any = null;
    notificationList: any = "";
    notificationBadge: number = 0;
    isInboxDisable: boolean = false;
    PrintBankingSlipVal: any;
    invoiceallarr: any = [];
    // this variable use for retry function for report
    ApiRepoParm1: any;
    ApiRepoParm2: any;
    // End this variable use for retry function for Reprot
    DATEDESCdata: any = [];
    DATEDESCValuedata: any = [];
    DATEDESCClrdata: any = [];
    // Retry api For Delete
    DeletePostData: any;
    ApiMethod: any;
    openDifferentTabunsub: Subscription;
    refreshId: any = [];
    USEV11UIValue: any;
    oldIsTrackId: any;
    hidematterTab: boolean = true;
    // Retry api For Delete
    selectedEmailList: any[];
    selectedMatterTab: any;
    windowNameId: any;
    //use for tier permission
    accessTierPermission: any = JSON.parse(
        localStorage.getItem("set_tier_permission")
    );
    openWebsite: any;
    destoryCurrentState: Subscription;
    editAssest: boolean = false;
    editliability: boolean = false;
    editbeneficiary: boolean = false;
    isProperty: boolean = true;
    deliverableDatalegel: any;
    // isProperty:boolean = false
    splitAccessTier: any;
    dissabledeleteButton: boolean = false;
    DerivableDropShow: boolean = false;
    selUserMenuButton: string = "";
    @ViewChild("widgetsContent", { static: true })
    widgetsContent: ElementRef<any>;
    selectedMatterForinvoice: any = [];
    isUsersUserActivity: boolean = false;
    matterDetailsFlag: boolean = false;
    showMatterDetailsMenu: boolean = false;
    diaryMatterInfo: any;
    Object: any;
    USEV11UI;
    listMenu = [
        {
            id: 1,
            name: "New Matter",
            icon: "icon_matter_new_d.ico",
            PermissionId: 1,
            isShown:true
        },
        {
            id: 2,
            name: "New Contact",
            icon: "icon_contact_new_d.ico",
            PermissionId: 3,
            isShown:true
        },
        {
            id: 3,
            name: "New File Note",
            icon: "icon_filesnotes_new_d.ico",
            PermissionId: 13,
            isShown:(this.fileNotes && this.fileNotes.ALLOWED == 1) ? true : false
        },
        {
            id: 4,
            name: "New Time Entry",
            icon: "icon_time_cost_new_d.ico",
            PermissionId: 2,
            isShown:true
        },
        {
            id: 5,
            name: "New Task",
            icon: "icon_tasks_new_d.ico",
            PermissionId: 18,
            isShown:(this.tasks && this.tasks.ALLOWED == 1) ? true : false
        },
        {
            id: 6,
            name: "New Spend Money",
            icon: "icon_expenses_new_d.ico",
            PermissionId: 9,
            isShown:true
        },
        // {
        //     id: 7,
        //     name : "New Matter receipt",
        //     icon : "icon_receipt_new_d.ico"
        // },{
        //     id: 8,
        //     name : "New Safe custody",
        //     icon : "icon_safecustody_new_d.ico"
        // },{
        //     id: 9,
        //     name : "New Instant invoice",
        //     icon : "icon_invoice_new_d.ico"
        // },{
        //     id: 10,
        //     name : "New Itemised invoice",
        //     icon : "icon_invoice_new_d.ico"
        // },
        {
            id: 11,
            name: "New Appointment",
            icon: "icon_diary_new_d.ico",
            PermissionId: 17,
            isShown:(this.diary && this.diary.ALLOWED == 1) ? true : false
        },
    ];
    private _unsubscribeAll$: Subject<void> = new Subject();
    isDownloadABAFile: boolean=false;

    constructor(
        private elementRef: ElementRef,
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private authenticationService: AuthenticationService,
        private router: Router,
        public dialog: MatDialog,
        public _matDialog: MatDialog,
        private toastr: ToastrService,
        private TimersServiceI: TimersService,
        private _mainAPiServiceService: MainAPiServiceService,
        public MatDialog: MatDialog,
        public behaviorService: BehaviorService,
        private cd: ChangeDetectorRef,
        private globalFunctionService: GlobalFunctionsService,
        private sanitizer: DomSanitizer,
        private tierpermission: TierPermissionPipe,
        private route: ActivatedRoute,
        private location: Location,
        private activateroute: ActivatedRoute,
        public toolbarServiceService: ToolbarServiceService,
        private http:HttpClient,
        private fileSaverService: FileSaverService,
        private socketService: SocketService
    ) {
        this.Object = Object;

        router.events.subscribe((url: any) => {
            setTimeout(() => {
                if (url.url) {
                    let currentUrl = url.url;
                    if (
                        currentUrl.trim() == "/users" ||
                        currentUrl.trim() == "/system-setting/users"
                    ) {
                        this.selUserMenuButton = "Users";
                    } else if (
                        currentUrl.trim() == "/users/useractivity" ||
                        currentUrl.trim() ==
                        "/system-setting/users/useractivity"
                    ) {
                        this.isUsersUserActivity = true;
                        this.selUserMenuButton = "UserActivity";
                    } else if (currentUrl.trim() == "/users/loggedon-user") {
                        this.selUserMenuButton = "LoggedOnUser";
                    } else {
                        this.isUsersUserActivity = false;
                    }
                }

                if (
                    url.url == "/Firm-Directory" ||
                    url.url == "/my-profile/connections" ||
                    url.url == "/my-profile/general" ||
                    url.url == "/my-profile/security" ||
                    url.url == "/my-profile/preferences" ||
                    url.url == "/my-profile/useractivity"
                ) {
                    this.gotoFirmDirectory();
                } else {
                    if (url.url) {
                        this.hidematterTab = true;
                    }
                }
                this.appPermissions = JSON.parse(
                    localStorage.getItem("app_permissions")
                );
            }, 500);
        });
        localStorage.removeItem("Folder");

        this.behaviorService.BatchPaymentData$.subscribe((result) => {
            if (result) {
                this.BatchPaymentData = result;
            }
        });

        /**
         *  Subscribes to the clickMaterFromTimeLine$ observable provided by behaviorService.
         * @remarks
         * It is particularly relevant for scenarios where the behaviorService controls the "open in new tab"
         * feature(on or off), and the selection of matterGuid is influenced by whether multiple tabs are open.
         */
        this.behaviorService.clickMaterFromTimeLine$
            .pipe(
                tap(result => {
                    if (result) {
                        this.selectedMatterTab = result;
                    } else {
                        this.selectedMatterTab = JSON.parse(localStorage.getItem(this.windowNameId || 'set_active_matters')) || result;
                        localStorage.setItem(this.windowNameId || 'set_active_matters', JSON.stringify(this.selectedMatterTab));
                    }
                })
            )
            .subscribe();
        const matterId = window.name.split("_");
        this.windowNameId = matterId && matterId[1] ? matterId[1] : undefined;
        // this.selectedMatterTab = JSON.parse(
        //     localStorage.getItem(
        //         this.windowNameId ? this.windowNameId : "set_active_matters"
        //     )
        // );
        // this.behaviorService.MatterData$.subscribe(result => {
        //     this.selectedMatterTab = result;
        // });
        // this.behaviorService.activeTab$.subscribe(result => {
        //     // setTimeout(()=>{

        //         this.isTabShow = result;
        //     // },100)
        // });
        this.behaviorService.toolBaarSetting$
            .subscribe((result) => {
                if (result) {
                    this.toolbarsetting = result;
                    this.target =
                        result && result.OPENINNEWTABWINDOWS
                            ? result.OPENINNEWTABWINDOWS
                            : {};
                }
                // this.target = (this.toolbarsetting && this.toolbarsetting.OPENINNEWTAB) ? "_blank" : "_self";
            });
        this.behaviorService.GetPreferences$.subscribe((result) => {
            if (result) {
                this.preferenceObjectData = result;
            } else {
                this.preferenceObjectData = JSON.parse(
                    localStorage.getItem("currentUser")
                );
            }
            this.themePanel(
                "USEV11UI",
                this.preferenceObjectData?.USEV11UI,
                "preferenceComponent"
            );
        });
        this.behaviorService.TimerCountData$.subscribe((result) => {
            this.TotalTimer = result;
            //this.displayMattterList();
        });
        this.behaviorService.EndOfMonth$.subscribe((result) => {
            this.ENDOFMONTHDATE = result;
        });
        this.behaviorService.EndOfMonthData$.subscribe((result) => {
            this.EndOfMonthData = result;
        });
        this.behaviorService.EndOfMonthDataSelected$.subscribe((result) => {
            this.EndOfMonthDataSelected = result;
        });

        this.behaviorService.appPermissionsSetting$
            .subscribe((result) => {
                if (result == null) {
                    result = this.appPermissions;
                }
                let paresRes: any = result;
                if (paresRes == null) {
                    paresRes = [];
                    this.SnapShotAppPermissions = false;
                } else {
                    this.SnapShotAppPermissions =
                        paresRes[33]["Firm Dashboard"] == 1;
                }

                if (result) {
                    this.appPermissions = JSON.parse(
                        localStorage.getItem("app_permissions")
                    );
                    if (this.appPermissions[28]["System Settings"] == 0) {
                        // this.router.navigate(['/404']);
                    }
                }
            });
        this.behaviorService.TrustDuplicateModuleHandling$.subscribe((result) => {
            if (result != null) {
                this.isClickTypeTrust = result.ClickType;
            }
        });

        this.behaviorService.DocumentRegisterFolderClickData$.subscribe((result) => {
            this.DocumentRegisterSelectedFolder = result;
        });

        // call dashboard showing api
        // this.DashboardAPI();
        this.BehaviourServiceFun();
        // this.GetDashboradList()

        //for navigation bar

        if (this.appPermissions == null) {
            this.appPermissions = [];
        }
        this.navigation = navigation;
        if (localStorage.getItem("theme_type") == "theme-yellow-light")
            this.greenTheme = true;
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        //On inialtial window assign window name
        const URL: any = this.router.url;
        if (this.currentUser && this.currentUser.InitialWindow) {
            if (
                !window.name ||
                window.name == "undefined" ||
                window.name == undefined ||
                window.name == "null" ||
                window.name == null ||
                window.name == ""
            ) {
                let x = URL.split("/");
                let activeMenu = x[1];
                if (
                    activeMenu != "login" ||
                    activeMenu != "sign-up" ||
                    activeMenu != "forgot-password" ||
                    activeMenu != ""
                ) {
                    let target;
                    if (/[,\-|\/]/.test(activeMenu)) {
                        target = activeMenu.replace(/-|\//g, "");
                    } else {
                        target = activeMenu;
                    }
                    window.name = target && target != "" ? target : "matters";
                }
            }
        }

        this.router.events.subscribe((res) => {
            //if (res instanceof ActivationEnd) {
            //  if(res.snapshot.data.title){
            this.navBarSetting(this.router.url);
            //  }
            //  }
        });
        this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
        if (this.currentUser) {
            this.timerId = "timer_" + this.currentUser.UserGuid;
        }
        this.behaviorService.NotificationCall$.subscribe((result) => {
            if (result && this.currentUser) {
                this.LoadNotificationData();
            }
        });
        // this.behaviorService.SetUpGuide$.subscribe(result => {
        //     if (result && this.currentUser) {
        //         this.LoadSetUpGuideData();
        //     }
        // });

        let matterData = JSON.parse(localStorage.getItem("set_active_matters"));
        // call Matter Load   api
        if (
            this.currentUser &&
            this.currentUser.InitialWindow &&
            this.currentUser.InitialWindow?.trim() != "Matter" &&
            !matterData
        ) {
            let FeeEarnerData = this.currentUser.LOADALLUSERSMATTERS
                ? "all"
                : this.currentUser.UserId;
            // let temData = { 'Active': 'active', 'SearchString': '', 'FeeEarner': FeeEarnerData, 'UninvoicedWork': 'All' };

            let temData = {
                Action: "GetData",
                Filters: {
                    Active: "active",
                    SearchString: "",
                    FeeEarner: FeeEarnerData,
                    UninvoicedWork: "All",
                },
            };

            this.getMatterInitialList(temData);
        }
        //Report Listing
        //addedby web19
        let val = localStorage.getItem("handelGenerateDoc");
        if (val == "Folder") {
            this.hideShowDoc = "yes";
        } else {
            this.hideShowDoc = "no";
        }
        this.ResizeWindow();
        this.behaviorService.APIretryCall$.subscribe((result) => {
            if (result["click"] && result["data"]["ReportGenerate"]) {
                this.ReportGenerate(this.ApiRepoParm1, this.ApiRepoParm2);
            }
            if (
                result["click"] &&
            (result["data"]["cloud-storage"] ||
                    result["data"]["NetDriveLoadDocuments"])
            ) {
                this.OpenMatter(this.ApiRepoParm1, this.ApiRepoParm2);
            }
            if (
                result["click"] &&
                (result["data"]["cloud-storage"] ||
                    result["data"]["GetCostRecoveryURL"])
            ) {
                this.GetCostRecoveryAPIFun(this.ApiRepoParm1);
            }

            if (
                localStorage.getItem("istrackid") == "DeleteDataClick" &&
                result["click"]
            ) {
                // this.GetCostRecoveryAPIFun(this.ApiRepoParm1);
                this.DeleteRetryCall(
                    this.DeletePostData,
                    this.ApiMethod,
                    this.refreshId,
                    this.oldIsTrackId
                );
            }
        });

        this.behaviorService.estateAssets$
            .subscribe((result) => {
                this.editAssest = result ? false : true;
            });

        this.behaviorService.estateliability$
            .subscribe((result) => {
                this.editliability = result ? false : true;
            });

        this.behaviorService.estateBeneficiary$
            .subscribe((result) => {
                this.editbeneficiary = result ? false : true;
            });
        this.behaviorService.forDiaryRefersh$
            .subscribe((result) => {
                this.DairyData = result;
            });

        this.behaviorService.DELIVERABLES$.subscribe((data) => {
            this.DeliverableField = data;
            if (this.DeliverableField == "No") {
                this.DerivableDropShow = true;
            } else {
                this.DerivableDropShow = false;
            }
        });

        this.openDifferentTabunsub =
            this.behaviorService.openDifferentTab$.subscribe((data) => {
                if (data) {
                    if (data == "editMatter") {
                        this.toolbarServiceService.matterpopup("edit");
                    } else if (data == "editContact") {
                        this.toolbarServiceService.ContactsDialog("edit");
                    }
                }
            });

        this.behaviorService.appointmentRowData$
            .subscribe((data) => {
                this.isDiaryData = "Notempty";
                this.diaryMatterInfo = data;
            });

        this.behaviorService.matterDetailsTabs$
            .subscribe((result: any) => {
                if (result && result.menu == "settlement") {
                    this.settlementAllowances = result.data;
                } else if (result && result.menu == "Estate") {
                    this.estate = result.data;
                }
            });

        this.behaviorService.SystemSettingUserTab$.subscribe((result) => {
            if (result && result != null) {
                let temActiveTab;
                let route = this.router.url;
                let x = route.split("/");
                if (result.menu == "users") {
                    if (x[1] == "system-setting") {
                        if (x[2] == "users") {
                            temActiveTab = 12;
                        } else if (x[2] == "useractivity") {
                            temActiveTab = 33;
                        } else {
                            temActiveTab = 11;
                        }
                    }

                    this.behaviorService.setactiveTab(temActiveTab);
                    this.activeSubMenu = x[2];
                    // setTimeout(() => {
                    //     this.selectedIndex = 0;
                    // }, 1200);
                    this.behaviorService.SetActiveSubMenu(this.activeSubMenu);
                    setTimeout(() => {
                        document.getElementById("toolbarMatGroup").click();
                        $("#toolbarMatGroup").click();
                    }, 100);
                }
            } else {
                let x = this.router.url.split("/");
                this.activeMenu = x[1];
                if (x[1] == "matter-details" || x[1] == "my-profile" || x[1] == "account-reconciliation") {
                    this.activeSubMenu = x[2] ? x[2] : "";
                    this.behaviorService.SetActiveSubMenu(this.activeSubMenu);
                    this.isInvoice = x[3] ? x[3] : "";
                }
            }
        });

        this.behaviorService.CheckBulkInvoiceData$.subscribe(data=>{
            if(data && data.length >=1){
                this.isShownBulkInvoice=true;
            }else{
                this.isShownBulkInvoice=false;
            }
        })
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

      /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit(): void {
        this.router.events.subscribe((res) => {
            //if (res instanceof ActivationEnd) {
            //  if(res.snapshot.data.title){
            this.navBarSetting(this.router.url);
            //  }
            //  }
        });
        this.behaviorService.activeTab$
            .subscribe((result) => {
                if (result) {
                    this.isTabShow = result;
                    if (!this.cd["destroyed"]) {
                        //  this.cd.detectChanges();
                    }
                }
            });
        //this.LoadSetUpGuideData();
        this.behaviorService.matterDetailsWindow$
            .subscribe((res) => {
                if (res) {
                    this.matterDetailsFlag = res.USEV11UI;
                } else {
                    this.matterDetailsFlag = this.currentUser?.USEV11UI
                        ? this.currentUser.USEV11UI
                        : false;
                }
            });

        this.RequestGuid = this._mainAPiServiceService.generateUniqSerial();
        this.behaviorService.timerRefreshObject$
            .subscribe((result) => {
                if (
                    this.behaviorService.timerRefreshObject$.observers.length >
                    1
                ) {
                    this.behaviorService.timerRefreshObject$.observers.splice(
                        0,
                        1
                    );
                }
                if (result) {
                    this.refreshMattterList();
                    this.behaviorService.setTimerRefreshObject(false);
                }
            });
        this.behaviorService.setTimerCountData();
        // Subscribe to the config changes
        this._fuseConfigService.config
            .subscribe((settings) => {
                this.horizontalNavbar =
                    settings.layout.navbar.position === "top";
                this.rightNavbar = settings.layout.navbar.position === "right";
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });
        this.behaviorService.stopTimeInterval$
            .subscribe((result) => {
                if (result) {
                    this.stopTimer();
                    this.behaviorService.stopTimeInterval(false);
                }
            });
        this.behaviorService.startTimeInterval$
            .subscribe((result) => {
                if (result) {
                    if (localStorage.getItem(this.timerId)) {
                        let timerObj = JSON.parse(
                            localStorage.getItem(this.timerId)
                        );
                        if (timerObj) {
                            timerObj.forEach((items) => {
                                if (items.isStart) {
                                    localStorage.setItem(
                                        "start_" + items.uuid,
                                        items.time
                                    );
                                }
                            });
                        }
                    }
                    this.behaviorService.startInterval(false);
                }
            });
        $(window).resize(() => {
            this.ResizeWindow();
        });
        this.splitAccessTier = this.getPermission("Split Time Entries");
        if (this.currentUser && (this.currentUser.SessionToken && this.currentUser.SHOWSETUPGUIDE == 1)) {
            $('body').addClass('setupguide-active');
           setTimeout(() => {
               $(".setupguide-btn").click();
           }, 1000);
           if(this.currentUser.SETUPGUIDEEXPANDED == 1) {
            setTimeout(() => {
                $(".time_entry_sidebar").addClass("active");
                $(".time_entry_sidebar").addClass("openwidget");
                $('body').css('--spaceRight', '310px');
            }, 1000);
           }
            // this.toggleSidebarOpen('setupguide')
            // this.LoadSetUpGuideData()
        }

        $('.time_entry_sidebar').bind('mouseout', () => {
            $(".time_entry_sidebar").removeClass("openwidget");
            $('body').css('--spaceRight', '70px');
        });

        $('.time_entry_sidebar').bind('mouseleave', () => {
            $(".setupinfo-slider.active").find(".backbtn").trigger("click");
        });

        $('.time_entry_sidebar').bind('mouseover',() => {
            $('body').css('--spaceRight', '310px');
        });
        
        // Mouseout event
        // $('.time_entry_sidebar').bind('mouseout',() => {
        //     console.log('out1111');
        //         $('body').css('--spaceRight', '70px');
        // });
    }

    /**
     * It runs only once after the component's view has been rendered.
     */
    ngAfterViewInit() {
        this.ResizeWindow();
        setTimeout(() => {
            if (this.widgetsContent) {
                this.widgetsContent.nativeElement.scrollTo({
                    left: this.widgetsContent.nativeElement.scrollLeft + 150,
                    behavior: "smooth",
                });
            }
        }, 3000);
        this.cd.detectChanges();
        // this.isProperty = this.selectedMatterTab && this.selectedMatterTab.MATTERCLASSDESC && ((this.selectedMatterTab.MATTERCLASSDESC == 'Business Sale') || (this.selectedMatterTab.MATTERCLASSDESC == 'Business Purchase') || (this.selectedMatterTab.MATTERCLASSDESC == 'Property Purchase') || (this.selectedMatterTab.MATTERCLASSDESC == 'Property Sale')) ? false : true;
        this.isPropertySale();
        if (this.currentUser) {
            if (
                this.currentUser.ONBOARDING?.BASICINCOMPLETE == 1 ||
                this.currentUser.ONBOARDING?.BASICINCOMPLETE == true
            ) {
                this.getDetailsFormCustomer(); // This function tigger "client data capture window"
            }
        }
        this.navBarSetting(this.router.url);
    }

    /**
     * This function is used to sale the prperty
     */
    isPropertySale() {
        const Classess = [
            "Business Sale",
            "Business Purchase",
            "Property Purchase",
            "Property Sale",
        ];
        if (this.selectedMatterRow && this.selectedMatterRow.MATTERCLASSDESC) {
            const valid = Classess.indexOf(
                this.selectedMatterRow.MATTERCLASSDESC
            );
            this.isProperty = valid == -1 ? true : false;
        } else {
            this.isProperty = true;
        }
    }

    /**
     * This function is used to get the Matter Initial list 
     * @param data 
     */
    getMatterInitialList(data) {
        this._mainAPiServiceService
            .getSetData(data, "matter")
            .subscribe(
                (response) => {
                    if (response.CODE == 200 && response.STATUS == "success") {
                        if (response.DATA.RECORDS[0]) {
                            localStorage.setItem(
                                "set_active_matters",
                                JSON.stringify(response.DATA.RECORDS[0])
                            );
                            this.behaviorService.MatterData(
                                response.DATA.RECORDS[0]
                            );
                        }
                    }
                },
                (error) => {
                    this.toastr.error(error);
                }
            );
    }
    ResizeWindow() {
        // const wph = $(window).width();
        // this.detailwidth = wph - 280 - 5 + 'px';
        // if ($(window).width() >= 1280 && $(window).width() < 1440) {
        //     const wph = $(window).width();
        //     this.detailwidth = wph - 65 + 'px';
        //     const nvh = 430;
        //     $('.mat-tab-header').css({ 'width': wph - nvh - 280 + 'px' });
        // } else if ($(window).width() >= 992 && $(window).width() < 1280) {
        //     const wph = $(window).width();
        //     this.detailwidth = wph - 65 + 'px';
        //     const nvh = 56;
        //     $('.mat-tab-header').css({ 'width': wph - nvh - 280 + 'px' });
        // } else if ($(window).width() <= 991) {
        //     const wph = $(window).width();
        //     this.detailwidth = wph - 65 + 'px';
        //     const nvh = 56;
        //     $('.mat-tab-header').css({ 'width': wph - nvh - 226 + 'px' });
        // }
    }

    // commonFocusWindow(url, modul) {
    //     const active_matter = JSON.parse(localStorage.getItem('set_active_matters'));
    //     let random: any = active_matter;
    //     random = window.open(url, modul);
    //     random.focus();
    // }

    /**
     * This function is used to target the NewTabbyAccount data
     * @param URL -url 
     * @param modul -modul name 
     */
    targetNewTabByAccount(URL: any, modul: string) {
        const account = JSON.parse(localStorage.getItem("ChartAccountData"));
        const accountD =
            account && account
                ? account.ACCOUNTGUID
                : this.toastr.info("Please select chart of accounts");
        accountD
            ? this.globalFunctionService.goToIdTab(
                URL,
                `reconciliationitem_${accountD}`
            )
            : this.toastr.info("Please select chart of accounts");
    }

    /**
     * This function is used to call the Dashboard API data.
     */
    DashboardAPI() {
        let currentUser: any = this.authenticationService.currentUserValue;
        if (currentUser) {
            this._mainAPiServiceService
                .getSetData({ Dashboard: "total unbilled wip" }, "GetDashboard")
                .subscribe((res) => {
                    if (res.CODE == 200 && res.STATUS == "success") {
                        if (res.DATA.DASHBOARDDATA[0])
                            this.TotalUnbilledWIP = round(
                                res.DATA.DASHBOARDDATA[0].INCGST
                            );
                    }
                });
            this._mainAPiServiceService
                .getSetData({ Dashboard: "total outstanding" }, "GetDashboard")
                .subscribe((res) => {
                    if (res.CODE == 200 && res.STATUS == "success") {
                        if (res.DATA.DASHBOARDDATA[0])
                            this.TotalOutstanding = round(
                                res.DATA.DASHBOARDDATA[0].INCGST
                            );
                    }
                });
            let data = {
                Dashboard: "user time",
                GROUPBY: "week",
                FEEEARNERGUID: JSON.parse(localStorage.getItem("currentUser"))
                    .UserGuid,
                EXCLUDEPROBONO: false,
                EXCLUDEINTERNALMATTERS: false,
            };
            this._mainAPiServiceService
                .getSetData(data, "GetDashboard")
                .subscribe((res) => {
                    if (res.CODE == 200 && res.STATUS == "success") {
                        this.GraphFun(res);
                    }
                });
        }
    }

    /**
     * This function is used to make the Graph fun
     * @param GraphData 
     */
    GraphFun(GraphData: any) {
        this.DATEDESCdata = [];
        this.DATEDESCValuedata = [];
        this.DATEDESCClrdata = [];
        GraphData.DATA.DASHBOARDDATA.map((row: any) => {
            if (row.DATEDESC != "Total") {
                this.DATEDESCdata.push(row.DATEDESC);
            }
        });
        GraphData.DATA.DASHBOARDDATA.map((row: any) => {
            if (row.DATEDESC != "Total") {
                this.DATEDESCValuedata.push(row.VALUE);
            }
        });
        GraphData.DATA.DASHBOARDDATA.map((row: any) => {
            if (row.DATEDESC != "Total") {
                this.DATEDESCClrdata.push(row.COLOUR);
            }
        });

        const data: any = {
            labels: this.DATEDESCdata,
            datasets: [
                {
                    label: "",
                    data: this.DATEDESCValuedata,
                    // data: [12, 19, 3, 5, 2, 3],
                    backgroundColor: this.DATEDESCClrdata,
                    borderColor: this.DATEDESCClrdata,
                    borderWidth: 0.5,

                    barPercentage: 0.5,
                },
            ],
        };
        let chartStatus = Chart.getChart("WeekleyGraphDashboardID");
        if (chartStatus != undefined) {
            chartStatus.destroy();
        }
        let ctx: any = document.getElementById("WeekleyGraphDashboardID");

        new Chart(ctx, {
            type: "bar",

            data: data,

            options: {
                // responsive:true,
                scales: {
                    legend: {
                        display: false,
                    },

                    x: {
                        ticks: {
                            font: {
                                size: 10,
                            },
                        },
                    },
                    y: {
                        ticks: {
                            font: {
                                size: 10,
                            },
                        },
                    },
                },

                plugins: {
                    legend: {
                        maxHeight: 0,
                        display: false,
                        labels: {
                            boxWidth: 0,
                        },
                    },
                },
            },
        });
    }

    /**
     * This function is used to click mat tab data
     * @param matTabval 
     */
    clickMatTab(matTabval) {
        this.tabName = matTabval.tab.textLabel;
        // if (this.tabName == 'REPORTS') {
        if (this.tabName == "REPORTS" && matTabval.index == "3") {
            if (window.name != "mainreport") {
                // this.selectedIndex = 0;
                // this.router.navigate(['main-report']);
                this.targetNewTab("/main-report", "REPORTS");
            }
            // this.router.navigate(['main-report']);
            // this.targetNewTab('/main-report', 'REPORTS');
        }
        // *************************NOTE::::::::::: please do not open this code becuse if you are open then flicring in tab todo name:::: Flickring Tabs"
        // else {
        //     this.selectedIndex = matTabval.index;
        // }
    }
    targetNewTab(URL: any, modul: string) {
        const currentUser = JSON.parse(localStorage.getItem("currentUser"));
        if (currentUser && currentUser.OPENINNEWTAB) {
            if (
                modul == "DOCUMENTREGISTER" ||
                modul == "TIMEANDBILLING" ||
                modul == "LEGALDETAILS" ||
                modul == "DOCUMENTTEMPLATES" ||
                modul == "FIRMDIRECTORY" ||
                modul == "MATTERDETAILS"
            ) {
                const windowNameId = window.name.split("_");
                const matterIdOfTab =
                    windowNameId && windowNameId[1]
                        ? windowNameId[1]
                        : undefined;
                const _matter = JSON.parse(localStorage.getItem(matterIdOfTab));
                // const active_matter = JSON.parse(
                //     localStorage.getItem(
                //         matterIdOfTab ? matterIdOfTab : "set_active_matters"
                //     )
                // );
                if (!this.selectedMatterTab) {
                    this.toastr.error("Please Select Matter");
                    return;
                }

                let matterGUID = this.selectedMatterTab.MATTERGUID
                let random: any = matterGUID;
                // random =  window.open('/#' + URL, modul + '_' + matterGUID);
                // random.focus();
                if (environment.ISPRO) {
                    this.commonFocusWindow(
                        "/#" + URL,
                        modul + "_" + matterGUID
                    );
                    matterGUID = "";
                } else {
                    // window.open('/silqWebApp/#' + url, '_blank');
                    // this.commonFocusWindow(
                    //     "/" + environment.URLPATH + "/#" + URL,
                    //     modul + "_" + matterGUID
                    // );
                    this.commonFocusWindow(
                        "/#" + URL,
                        modul + "_" + matterGUID
                    );
                    matterGUID = "";
                }
            } else {
                const url = this.router.serializeUrl(
                    this.router.createUrlTree([URL])
                );
                let target;
                if (/[,\-|\/]/.test(URL)) {
                    target = URL.replace(/-|\//g, "");
                } else {
                    target = URL;
                }
                if (environment.ISPRO) {
                    // window.open
                    this.commonFocusWindow("/#" + url, target);
                } else {
                    // window.open
                    // this.commonFocusWindow(
                    //     "/" + environment.URLPATH + "/#" + url,
                    //     target
                    // );
                    this.commonFocusWindow(
                        "/#" + url,
                        target
                    );
                }
            }
        } else {
            setTimeout(() => {
                if (modul == "MATTERDETAILS") {
                    this.isTabShow = 4;
                }

                // if(URL == "/main-report") {
                //     let token = localStorage.getItem("session_token");
                //     this.socketService.openSocketReports(token);
                // }
                this.router.navigate([URL]);
            }, 100);
        }
    }
    commonFocusWindow(url, modul) {
        // const active_matter = JSON.parse(
        //     localStorage.getItem("set_active_matters")
        // );
        // let random: any = this.selectedMatterTab;

        let random = modul;

        random = window.open(url, modul);
        random.focus();
    }
    // DashboardAPI end  //
    BehaviourServiceFun() {
        this.behaviorService.activitiesData$
            .subscribe((result) => {
                if (result == null) {
                    this.isActivityData = "empty";
                } else {
                    this.isActivityData = "Notempty";
                }
            });
        this.behaviorService.DiaryData$.subscribe((result) => {
            if (result == null) {
                this.isDiaryData = "empty";
            } else {
                this.isDiaryData = "Notempty";
            }
        });
        // for matter
        this.behaviorService.MatterData$.subscribe((result) => {
            this.selectedMatterRow = result;
            setTimeout(() => {
                this.isPropertySale();
            }, 1500);
            if (result == null) {
                this.isMatterData = "empty";
            } else {
                this.isMatterData = "Notempty";
            }
        });
        this.behaviorService.chequeStatment$
            .subscribe((result) => {
                if (result == null) {
                    this.selectedChequeStatment = "empty";
                } else {
                    this.selectedChequeStatment = "Notempty";
                }
            });
        this.behaviorService.allowancesData$
            .subscribe((result) => {
                if (result == null) {
                    this.selectedAllowances = "empty";
                } else {
                    this.selectedAllowances = "Notempty";
                }
            });
        //for contact
        this.behaviorService.contactData$
            .subscribe((result) => {
                if (result == null) {
                    this.isContactData = "empty";
                } else {
                    this.isContactData = "Notempty";
                }
            });
        //For receipt
        this.behaviorService.ReceiptData$.subscribe((result) => {
            // this.DisabledReceiptTool = "disabled";
            if (result != null) {
                this.isReceiptData = "Notempty";
                this.receiptData = result;
                this.receipTitle =
                    this.receiptData.INCOMECLASS == "Receipt" ||
                        this.receiptData.INCOMECLASS == "Write Off"
                        ? "View"
                        : "Edit";
                        if(this.receiptData.INCOMECLASS == "Write Off"){
                        this.disable=true
                        }else{
                            this.disable=false;
                        }
            } else {
                this.isReceiptData = "empty";
            }
        });
        this.behaviorService.workInProgress$
            .subscribe((result) => {
                this.WorkInProgressData = result;
                if (result) {
                    this.isWIPData = "Notempty";
                    this.DisEnTimeEntryToolbar =
                        result.INVOICEGUID == "-1" || result.INVOICEGUID == -1
                            ? "undo"
                            : "write_off";
                } else {
                    this.isWIPData = "empty";
                    this.DisEnTimeEntryToolbar = "write_off";
                }
            });

        this.behaviorService.selectedWorkinProgressMatter$
            .subscribe((datavalue) => {
                if (datavalue.length != 0) {
                    for (let i = 0; i <= datavalue.length; i++) {
                        if (datavalue[i].TAGGED == 1) {
                            this.dissabledeleteButton = true;
                            break;
                        } else {
                            this.dissabledeleteButton = false;
                        }
                    }
                } else {
                    this.dissabledeleteButton = false;
                }
            });

            this.behaviorService.selectedtimeEntriesChckbox$
            .subscribe((datavalue) => {
                if (datavalue.length != 0) {
                    for (let i = 0; i <= datavalue.length; i++) {
                        if (datavalue[i].TAGGED == 1) {
                            this.dissabledeleteButton = true;
                            break;
                        } else {
                            this.dissabledeleteButton = false;
                        }
                    }
                } else {
                    this.dissabledeleteButton = false;
                }
            });

        //for Disabled enabled
        this.behaviorService.ConflictDataList$.subscribe((result) => {
            if (result == null) {
                this.disconflictToolbar = "yes";
            } else {
                this.disconflictToolbar = "no";
            }
        });
        this.behaviorService.GeneralData$.subscribe((result) => {
            if (result) {
                this.isGeneralJounralData = "Notempty";
                this.JournalData = result;
                this.journalLinktype = result.LINKTYPE;
                if (
                    this.journalLinktype == "Receipt" ||
                    this.journalLinktype == "Invoice"
                ) {
                    this.journalText = "View";
                } else if (
                    this.journalLinktype == "Income" ||
                    this.journalLinktype == "General Journal" ||
                    this.journalLinktype == "Expenditure" ||
                    this.journalLinktype == "Payable" ||
                    this.journalLinktype == "Disbursement"
                ) {
                    this.journalText = "Edit";
                }
            } else {
                this.isGeneralJounralData = "empty";
            }
        });

        this.behaviorService.MainTimeEntryData$.subscribe((result) => {
            this.timeEntryData = result;
            if (result != null) {
                this.isTimeEntryData = "Notempty";
                this.DisEnTimeEntryToolbar =
                    result.INVOICEGUID == "-1" || result.INVOICEGUID == -1
                        ? "undo"
                        : "write_off";
            } else {
                this.isTimeEntryData = "empty";
                this.DisEnTimeEntryToolbar = "write_off";
            }
        });

        this.behaviorService.MainAuthorityData$.subscribe((result) => {
            this.MainAuthorityData = result;
            if (result != null) {
                if (result.AUTHORITY != undefined) {
                    if (result.AUTHORITY.WEBADDRESS != "") {
                        this.mainlegalAuthWebUrl = result.WEBADDRESS
                            ? result.WEBADDRESS
                            : "";
                    } else {
                        this.mainlegalAuthWebUrl = "";
                    }
                    this.DisMainAuthorityToolbar = "Autho_yes";
                } else {
                    this.DisMainAuthorityToolbar = "Autho_no";
                    this.mainlegalAuthWebUrl = "";
                }
            }
        });

        this.behaviorService.LegalAuthorityData$.subscribe((result) => {
            if (result != null) {
                this.LegalAuthorityData = result;
                if (result.AUTHORITY != undefined) {
                    this.mainlegalAuthWebUrl = result.WEBADDRESS
                        ? result.WEBADDRESS
                        : "";
                    this.LegalAuthotool = "addMatterHide";
                } else {
                    this.mainlegalAuthWebUrl = "";
                    this.LegalAuthotool = "";
                }
            }
        });
        this.behaviorService.LegalAuthorityForSubAuthToolbar$.subscribe((result) => {
            if (result != null) {
                this.LegalAuthoritySubAuthata = result;
                if (result.WEBADDRESS != "") {
                    this.LegalSubAuthotool = result.WEBADDRESS;
                } else {
                    this.LegalSubAuthotool = "";
                }
            }
        });
        //Trust Chart Account Behaviour
        this.behaviorService.TrustDuplicateModuleHandling$.subscribe((result) => {
            const currentUser = JSON.parse(localStorage.getItem("currentUser"));
            if (currentUser && currentUser.OPENINNEWTAB) {
                const materIDofTab = window.name.split("_");
                this.windowNameId =
                    materIDofTab && materIDofTab[1]
                        ? materIDofTab[1]
                        : undefined;
                const windowAcc = JSON.parse(
                    localStorage.getItem(
                        this.windowNameId
                            ? this.windowNameId
                            : "set_active_matters"
                    )
                );
                this.chartAccountDetail = windowAcc;
            }
            if (
                this.chartAccountDetail &&
                this.chartAccountDetail.accountTypeData
            ) {
                this.valOfIsTrust =
                    this.chartAccountDetail.accountTypeData.UseTrust;
                this.isStoreTrustVal = result;
                this.ShowMatLable =
                    this.chartAccountDetail.accountTypeData.Lable;
                if (
                    this.chartAccountDetail.accountTypeData.Lable ==
                    "Select Account" ||
                    this.chartAccountDetail.accountTypeData.Lable ==
                    "CHART OF ACCOUNTS" ||
                    this.chartAccountDetail.accountTypeData.Lable ==
                    "CHART OF ACCOUNTS"
                ) {
                    this.recouncileTab = "ACCOUNTS RECONCILIATION";
                    this.ShowTranstionsLable = "ACCOUNT TRANSACTIONS";
                } else if (
                    this.chartAccountDetail.accountTypeData.Lable ==
                    "Select Trust Account" ||
                    this.chartAccountDetail.accountTypeData.Lable ==
                    "TRUST CHART OF ACCOUNTS"
                ) {
                    this.recouncileTab = "TRUST ACCOUNTS RECONCILIATION";
                    this.ShowTranstionsLable = "TRUST ACCOUNT TRANSACTIONS";
                }
                this.behaviorService.setactiveTabName(this.recouncileTab);
            } else {
                if (result != null) {
                    this.valOfIsTrust = result.UseTrust;
                    this.isStoreTrustVal = result;
                    this.ShowMatLable = result.Lable;
                    if (
                        result.Lable == "Select Account" ||
                        result.Lable == "CHART OF ACCOUNTS" ||
                        result.Lable == "CHART OF ACCOUNTS"
                    ) {
                        this.recouncileTab = "ACCOUNTS RECONCILIATION";
                        this.ShowTranstionsLable = "ACCOUNT TRANSACTIONS";
                    } else if (
                        result.Lable == "Select Trust Account" ||
                        result.Lable == "TRUST CHART OF ACCOUNTS"
                    ) {
                        this.recouncileTab = "TRUST ACCOUNTS RECONCILIATION";
                        this.ShowTranstionsLable = "TRUST ACCOUNT TRANSACTIONS";
                    }
                    this.behaviorService.setactiveTabName(this.recouncileTab);
                }
            }
        });
        //common MainBar hideShow
        this.behaviorService.CommonToolbarHS$.subscribe((result) => {
            if (result) {
                this.CommonToolbarHSData = result;
                this.ClickTypeForTrustChartHD = result.ClickType;
                this.AccountClassForTrustChartHD = result.AccountClass;
            }
        });
        this.behaviorService.ChartAccountData$.subscribe((result) => {
            if (result) {
                this.chartAccountDetail = result;
                this.AccountGUID = result.ACCOUNTGUID;
            }
        });
        this.behaviorService.ChartAccountDataEdit$.subscribe((result) => {
            if (result) {
                this.AccountId = result.ACCOUNTNUMBER;
                this.accountClassName = result.ACCOUNTCLASSNAME;
                this.isChartaccountData =
                    result.ACCOUNTNUMBER == "1000" ||
                        this.accountClassName == "Controlled Money Account"
                        ? "Notempty"
                        : "empty";
                this.isMainAccount =
                    result.ACCOUNTTYPENAME == "Header" ? true : false;
                this.isAccountEditable = result.EDITABLE == 1 ? true : false;
            } else {
                this.isChartaccountData = "empty";
            }
        });
        this.behaviorService.packs$
            .subscribe((result) => {
                if (result != null) {
                    this.KitName = result.KITNAME;
                    this.KitGUid = result.KITGUID;
                    if (result.TEMPLATETYPEDESC == "Email") {
                        this.packsToobar = "Email";
                    } else if (result.TEMPLATETYPEDESC == "Template") {
                        this.packsToobar = "Template";
                    } else {
                        this.packsToobar = "Packs";
                    }
                }
            });
        // invoice
        this.behaviorService.matterInvoice$
            .subscribe((result) => {
                if (result != null) {
                    this.isInvoiceData = "Notempty";
                    this.ShowGenerateInvoice = "yes";
                } else {
                    this.isInvoiceData = "empty";
                    this.ShowGenerateInvoice = "no";
                }
            });
        // spend money
        this.behaviorService.SpendMoneyData$.subscribe((result) => {
            if (result != null) {
                this.isSpendMoneyData = "Notempty";
                this.SendMoney_dataGUID = result;
            } else {
                this.isSpendMoneyData = "empty";
            }
        });
        // For Task
        this.behaviorService.TaskData$.subscribe((result) => {
            if (result != null) {
                this.isTaskData = "Notempty";
                this.TaskData = result;
            } else {
                this.isTaskData = "empty";
            }
        });
        // safe custody
        this.behaviorService.SafeCustody$.subscribe((result) => {
            if (result) {
                this.SafeCustodyData = result;
                this.isSafeCustody = "Notempty";
            } else {
                this.isSafeCustody = "empty";
            }
        });
        // for pack
        this.behaviorService.Packets$.subscribe((result) => {
            if (result != null) {
                this.isSafePackData = "Notempty";
                this.PacketsData = result;
            } else {
                this.isSafePackData = "Notempty";
            }
        });
        this.behaviorService.UserData$.subscribe((result) => {
            if (result) {
                this.isUserData = "Notempty";
            } else {
                this.isUserData = "empty";
            }
        });
        this.behaviorService.estimatelegalData$
            .subscribe((result) => {
                if (result != null) {
                    this.isEstimateData = "Notempty";
                    this.estimateData = result;
                } else {
                    this.isEstimateData = "empty";
                }
            });
        this.behaviorService.deliverableData$
            .subscribe((result) => {
                if (result != null) {
                    this.isdeliverable = "Notempty";
                    this.deliverableDatalegel = result;
                } else {
                    this.isdeliverable = "empty";
                }
            });
        this.behaviorService.matterInvoice$
            .subscribe((result) => {
                if (result != null) {
                    this.isMatterInvoiceData = "Notempty";
                } else {
                    this.isMatterInvoiceData = "empty";
                }
            });
        this.behaviorService.CheckForCredits$.subscribe((result) => {
            if (result != null) {
                this.isforCredits = "Notempty";
            } else {
                this.isforCredits = "empty";
            }
        });
        this.behaviorService.LegalChronologyData$.subscribe((result) => {
            if (result != null) {
                this.isChronoData = "Notempty";
                this.ChronologyLegalData = result;
            } else {
                this.isChronoData = "empty";
            }
        });
        this.behaviorService.FileNotesData$.subscribe((result) => {
            if (result != null) {
                this.isFileNoteData = "Notempty";
                this.FileNotesData = result;
            } else {
                this.isFileNoteData = "empty";
            }
        });
        this.behaviorService.DocumentRegisterData$.subscribe((result) => {
            if (result != null) {
                this.isOpenDesktopWord = false;
                this.isDocRegData = "Notempty";
                this.DocRegData = result;
                if (
                    this.DocRegData.type == "docx" ||
                    this.DocRegData.type == "doc" ||
                    this.DocRegData.type == "dotx" ||
                    this.DocRegData.type == "dot" ||
                    this.DocRegData.type == "docm" ||
                    this.DocRegData.type == "dotm"
                ) {
                    this.isOpenDesktopWord = true;
                }
            } else {
                this.isDocRegData = "empty";
            }
        });
        this.behaviorService.TrustMoneyList$.subscribe((result) => {
            if (result) {
                this.isTrustMoneyListData = result;
                if (
                    result.TRANSACTIONTYPE == "Reversal" ||
                    result.TRUSTTRANSACTIONLINKGUID != ""
                )
                    this.isTrustMoneyList = false;
                else this.isTrustMoneyList = true;
            } else {
                this.isTrustMoneyList = false;
            }
        });
        this.behaviorService.PastRecouncileData$.subscribe((result) => {
            if (result) {
                this.isPastRecouncile = true;
                this.storePastRecouncileData = result;
            } else {
                this.isPastRecouncile = false;
            }
        });
        this.behaviorService.serachingData$
            .subscribe((result) => {
                this.isSerachingDataShow = result ? false : true;
                this.isSerachingData = result ? result : {};
            });
        this.behaviorService.courtFormData$
            .subscribe((result) => {
                if (result) this.courtFormData = result;
            });
        this.behaviorService.TrustEndofMonth$.subscribe((result) => {
            this.TrustEndofMonthReport = result;
            this.isTrustEndofMonthReport = result ? false : true;
        });
        this.behaviorService.TemplateGenerateData$.subscribe((result) => {
            if (result) {
                this.TemplateGenerateData = result;
                this.isDocTempletType = result.TEMPLATETYPE !== "Folder";
                if (
                    result.TEMPLATETYPE === "Folder" ||
                    result.TEMPLATETYPE === "Go_Back"
                ) {
                    this.isDocTempletFolder = true;
                } else {
                    this.isDocTempletFolder = false;
                }
            }
        });

        this.behaviorService.recounciliationItem$
            .subscribe((result) => {
                this.isUnusedAdjustment = false;
                if (result) {
                    this.recouncileItemdata = result;
                    if (this.recouncileItemdata.LINKTYPE == "Adjustment") {
                        this.isUnusedAdjustment = true;
                    }
                }
            });
        this.behaviorService.SelectedEmailList$.subscribe((result) => {
            this.selectedEmailList = result;
        });

        this.behaviorService.PrintBankingSlip$.subscribe((result) => {
            this.PrintBankingSlipVal = result;
        });
    }
    // common behaviour call start

    // common behaviour call end
    /* ---------------------------------------------------------------------help Licence start--------------------------------------------------------------------------  */
    openLicence(Data) {
        let w = Data == "LI" ? "50%" : "25%";
        const dialogRef = this.dialog.open(LicenceAgreementComponent, {
            disableClose: true,
            //width: w,
            data: { action: Data },
        });
        dialogRef.afterClosed().subscribe((result) => { });
    }
    /* ---------------------------------------------------------------------help Licence end--------------------------------------------------------------------------  */

    AddAuthorityFromLegal(val) {
        this.behaviorService.LegalAuthorityToolbar(val);
    }

    /* ---------------------------------------------------------------------Activity Start--------------------------------------------------------------------------  */
    //add edit and duplicat ActivityDialog
    ActivityDialog(actionType, name, matteris?: any) {
        this.toolbarServiceService.SetOldistrackid("open");
        let popupData: any = {};
        if (actionType == "new") {
            popupData = { action: actionType };
        } else if (actionType == "edit" || actionType == "Duplicate") {
            let ActivityData = JSON.parse(
                localStorage.getItem("current_ActivityData")
            );
            if (!ActivityData) {
                this.toastr.error("Please Select Activity");
                return false;
            }
            popupData = {
                action: actionType,
                ACTIVITYGUID: ActivityData.ACTIVITYGUID,
            };
        }
        const dialogRef = this.dialog.open(ActivityDialogComponent, {
            disableClose: true,
            panelClass: "Activity-dialog",
            data: {
                popupData,
                popupname: name,
                matteris: matteris ? matteris : null,
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");
            if (result) {
                $("#refreshWorkInprogress").click();
            }
        });
    }
   
    /**
     * This unction is used to open the Activity Sundry Dialog
     * @param actionType -action type
     * @returns -data value
     */
    ActivitySundryDialog(actionType) {
        this.toolbarServiceService.SetOldistrackid("open");
        let popupData: any = {};
        if (actionType == "new") {
            popupData = { action: actionType };
        } else if (actionType == "edit" || actionType == "Duplicate") {
            let ActivityData = JSON.parse(
                localStorage.getItem("current_ActivityData")
            );
            if (!ActivityData) {
                this.toastr.error("Please Select Activity");
                return false;
            }
            popupData = {
                action: actionType,
                ACTIVITYGUID: ActivityData.ACTIVITYGUID,
            };
        }
        const dialogRef = this.dialog.open(ActivitySundryDialogComponent, {
            disableClose: true,
            panelClass: "Activity-dialog",
            data: popupData,
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");

            if (result) {
                $("#refreshActivities").click();
            }
        });
    }

    /**
     * This function is used to delete the Activity dialog
     */
    DeleteActivityDialog(): void {
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",
            }
        );
        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to delete?";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let ActivityData = JSON.parse(
                    localStorage.getItem("current_ActivityData")
                );
                let postData = {
                    ACTION: "delete",
                    DATA: { ACTIVITYGUID: ActivityData.ACTIVITYGUID },
                };
                let RefreshIdArray: any = ["#refreshActivities"];
                this.toolbarServiceService.SetVariableForRetry(
                    postData,
                    "activity",
                    RefreshIdArray
                );
                this._mainAPiServiceService
                    .getSetData(postData, "activity")
                    .subscribe((res) => {
                        if (res.STATUS == "success" && res.CODE == 200) {
                            localStorage.setItem(
                                "istrackid",
                                this.oldIsTrackId
                            );
                            $("#refreshActivities").click();
                            this.toastr.success("Deleted successfully");
                        } else if (res.CODE == 451 && res.STATUS == "warning") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "activity"
                            );
                        } else if (res.CODE == 450 && res.STATUS == "error") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "activity"
                            );
                        } else if (res.MESSAGE == "Not logged in") {
                            this.dialogRef.close(false);
                        }
                    });
            }
            this.confirmDialogRef = null;
        });
    }
    /* ---------------------------------------------------------------------Activity Sundry End--------------------------------------------------------------------------  */
    /* ---------------------------------------------------------------------------USERS Start-------------------------------------------------------------------------  */
  
    /**
     * This function is used to open the user dialog data value
     * @param actionType -action type value data
     * @returns -data value
     */
    userDialog(actionType) {
        this.toolbarServiceService.SetOldistrackid("open");
        let popupData: any = {};
        if (actionType == "new") {
            popupData = { action: actionType };
        } else if (actionType == "edit" || actionType == "duplicate") {
            let ActiveUserData = JSON.parse(
                localStorage.getItem("current_user_Data")
            );
            if (!ActiveUserData) {
                this.toastr.error("Please Select User");
                return false;
            }
            popupData = {
                action: actionType,
                USERGUID: ActiveUserData.USERGUID,
                USERID: ActiveUserData.USERID,
                USERNAME: ActiveUserData.USERNAME,
            };
        }
        const dialogRef = this.dialog.open(UserDialogComponent, {
            disableClose: true,
            panelClass: "User-dialog",
            data: popupData,
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");
            if (result) {
                $("#refreshUser").click();
            }
        });
    }
   
    /**
     * This function is used to delete the user data
     */
    DeleteUser(): void {
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",
            }
        );
        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to delete?";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let ActiveUserData = JSON.parse(
                    localStorage.getItem("current_user_Data")
                );
                let postData = {
                    Action: "delete",
                    data: { USERGUID: ActiveUserData.USERGUID },
                };
                let RefreshIdArray: any = ["#refreshUser"];
                this.toolbarServiceService.SetVariableForRetry(
                    postData,
                    "user",
                    RefreshIdArray
                );
                this._mainAPiServiceService
                    .getSetData(postData, "user")
                    .subscribe((res) => {
                        if (res.STATUS == "success" && res.CODE == 200) {
                            localStorage.setItem(
                                "istrackid",
                                this.oldIsTrackId
                            );
                            $("#refreshUser").click();
                            this.toastr.success("Deleted successfully");
                        } else if (res.CODE == 451 && res.STATUS == "warning") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "user"
                            );
                        } else if (res.CODE == 450 && res.STATUS == "error") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "user"
                            );
                        } else if (res.MESSAGE == "Not logged in") {
                            this.dialogRef.close(false);
                        }
                    });
            }
        });
    }
    /* ---------------------------------------------------------------------------USERS End-------------------------------------------------------------------------  */
    /* ---------------------------------------------------------------------------start of timer add-------------------------------------------------------------------------  */
/**
 * This function is used to toggle the sidebar data value
 * @param key -key data value
 */
    toggleSidebarOpen(key) {
        this.displayMattterList();
        /*Keep open timer box once timer added*/
        // this._fuseSidebarService.getSidebar(key).toggleOpen();
        if (key == "navbar") {
            if ($(".navbar-fuse-sidebar").hasClass("open")) {
                $(".navbar-fuse-sidebar").removeClass("open");
                $(".sidebarwrap .overlaywrap").remove();
            } else {
                $(".navbar-fuse-sidebar").addClass("open animations-enabled");
                $(".navbar-fuse-sidebar").after(
                    '<div class="fuse-sidebar-overlay show overlaywrap"></div>'
                );
            }
        } else {
            this._fuseSidebarService.getSidebar(key).toggleOpen();
        }
    }

    /**
     * This function is used to display the Matters list data
     */
    async displayMattterList() {
        this.prevMatterArray = [];
        if (localStorage.getItem(this.timerId)) {
            let timerObj = JSON.parse(localStorage.getItem(this.timerId));
            clearTimeout(this.timerInterval);
            if (timerObj) {
                if (!timerObj.length) {
                    timerObj = [timerObj];
                }

                await timerObj.forEach((items) => {
                    this.prevMatterArray.push({
                        startTime: items.startTime,
                        endTime: items.endTime,
                        uuid: items.uuid,
                        WORKITEMGUID: items.WORKITEMGUID,
                        matter_id: items.matter_id,
                        matterguid: items.matterguid,
                        time: this.secondsToHms(items.time),
                        isStart: items.isStart,
                    });
                    if (
                        localStorage.getItem("start_" + items.uuid) &&
                        items.isStart
                    ) {
                        this.currentTimer = localStorage.getItem(
                            "start_" + items.uuid
                        );
                        this.startTimer(items.uuid);
                    }
                });
                this.cd.markForCheck();
            }
        }
    }

    /**
     * This function is used to refresh the matters list data value
     */
    refreshMattterList() {
        this.prevMatterArray = [];
        if (localStorage.getItem(this.timerId)) {
            let timerObj = JSON.parse(localStorage.getItem(this.timerId));
            clearTimeout(this.timerInterval);
            if (timerObj) {
                timerObj.forEach((items) => {
                    this.prevMatterArray.push({
                        startTime: items.startTime,
                        endTime: items.endTime,
                        uuid: items.uuid,
                        WORKITEMGUID: items.WORKITEMGUID,
                        matter_id: items.matter_id,
                        matterguid: items.matterguid,
                        time: this.secondsToHms(items.time),
                        isStart: items.isStart,
                    });
                    if (
                        localStorage.getItem("start_" + items.uuid) &&
                        items.isStart
                    ) {
                        this.currentTimer = localStorage.getItem(
                            "start_" + items.uuid
                        );
                        this.startTimer(items.uuid);
                    }
                });
            }
        }
    }
   
    /**
     * this function is used to convert the second to HMS
     * @param d -data of d
     * @returns data value
     */
    secondsToHms(d: any) {
        d = Number(d);
        var hours =
            Math.floor(d / 3600) < 10
                ? ("00" + Math.floor(d / 3600)).slice(-2)
                : Math.floor(d / 3600);
        var minutes = ("00" + Math.floor((d % 3600) / 60)).slice(-2);
        var seconds = ("00" + ((d % 3600) % 60)).slice(-2);
        return hours + ":" + minutes + ":" + seconds;
    }

    /**
     * This function is used to stopmatter back
     * @param uuid -uuid data value
     */
    stopMatterBack(uuid: any) {
        let dateFo = new Date();
        let currentUTC = dateFo.getTime() + dateFo.getTimezoneOffset();
        clearTimeout(this.timerInterval);
        let tempArray: any[] = [];
        let timerObj = JSON.parse(localStorage.getItem(this.timerId));
        timerObj.forEach((items) => {
            if (items.uuid === uuid) {
                items.isStart = false;
                items.endTime = currentUTC;
                items.time =
                    Number(items.time) +
                    Math.round(
                        Math.abs(
                            (Number(items.endTime) - Number(items.startTime)) /
                            1000
                        )
                    );
                tempArray.push(items);
                localStorage.removeItem("start_" + items.uuid);
            } else {
                tempArray.push(items);
            }
        });
        this.currentTimer = 0;
        localStorage.setItem(this.timerId, JSON.stringify(tempArray));
        this.prevMatterArray = tempArray;
        this.displayMattterList();
    }

    /**
     * This function is used to load the notification data
     */
    LoadNotificationData() {
        this._mainAPiServiceService
            .getSetData({ Action: "GetData", Filters: {} }, "notifications")
            .subscribe(
                (res) => {
                    if (res.CODE == 200 || res.STATUS == "success") {
                        if (res.DATA && res.DATA.RECORDS) {
                            this.notificationBadge = res.DATA.RECORDS.length;
                            this.notificationList = res.DATA.RECORDS;
                        }
                    } else if (res.CODE == 451 && res.STATUS == "warning") {
                        this.toastr.warning(res.MESSAGE);
                        this.isspiner = false;
                    } else if (res.CODE == 450 && res.STATUS == "error") {
                        this.toastr.warning(res.MESSAGE);
                    }
                },
                (err) => {
                    // this.isLoadingResults = false;
                    // this.toastr.error(err);
                }
            );
    }

    /**
     * This function is used to Load the setup Guid data
     */
    LoadSetUpGuideData() {
        this.isLoadingResults = true;
        let PostData: any = {
            Action: "GetData",
            Filters: {
                UserGuid: this.currentUser ? this.currentUser.UserGuid : "",
            },
        };
        this._mainAPiServiceService
            .getSetData(PostData, "setup-guide")
            .subscribe(
                (res) => {
                    if (res.CODE == 200 || res.STATUS == "success") {
                        if (res.DATA && res.DATA.RECORDS) {
                            this.SetUpGuideData = res.DATA.RECORDS;
                            this.isLoadingResults = false;
                            // this.ProgressValue = Number(this.SetUpGuideDataTask?.COMPLETEDTASKCOUNT)*10
                            // this.SetUpGuideDataList = res.DATA.RECORDS.TASKS;
                        }
                    } else if (res.CODE == 451 && res.STATUS == "warning") {
                        this.toastr.warning(res.MESSAGE);
                        this.isLoadingResults = false;
                    } else if (res.CODE == 450 && res.STATUS == "error") {
                        this.toastr.warning(res.MESSAGE);
                    }
                },
                (err) => {
                    // this.isLoadingResults = false;
                    // this.toastr.error(err);
                }
            );
    }
   
    /**
     * This function is used to Start MatterBack data value
     * @param uuid -uuid data value
     */
    startMatterBack(uuid: any) {
        let dateFo = new Date();
        let currentUTC = dateFo.getTime() + dateFo.getTimezoneOffset();
        clearTimeout(this.timerInterval);
        this.currentTimer = 0;
        let tempArray: any[] = [];
        let timerObj = JSON.parse(localStorage.getItem(this.timerId));
        timerObj.forEach((items) => {
            if (items.isStart) {
                items.isStart = false;
                items.endTime = currentUTC;
                items.time =
                    Number(items.time) +
                    Math.round(
                        Math.abs(
                            (Number(items.endTime) - Number(items.startTime)) /
                            1000
                        )
                    );
                tempArray.push(items);
                localStorage.removeItem("start_" + items.uuid);
            } else if (items.uuid === uuid) {
                this.currentTimerHMS = this.secondsToHms(items.time);
                items.isStart = true;
                items.startTime = currentUTC;
                tempArray.push(items);
                localStorage.setItem("start_" + items.uuid, items.time);
                this.currentTimer = items.time;
                this.startTimer(items.uuid);
            } else {
                tempArray.push(items);
            }
        });
        localStorage.setItem(this.timerId, JSON.stringify(tempArray));
        this.prevMatterArray = tempArray;
        this.displayMattterList();
    }

    /**
     * This function is used to start the timer data
     * @param uuid -uuid data value data
     */
    startTimer(uuid: any) {
        if (localStorage.getItem("start_" + uuid)) {
            let items = this.findTimer(uuid);
            this.timerInterval = setInterval(() => {
                let dateFo = new Date();
                let currentUTC = dateFo.getTime() + dateFo.getTimezoneOffset();
                this.currentTimer =
                    Number(items.time) +
                    Math.round(
                        Math.abs(
                            (Number(currentUTC) - Number(items.startTime)) /
                            1000
                        )
                    );
                this.currentTimerHMS = this.secondsToHms(this.currentTimer);
                localStorage.setItem("start_" + uuid, this.currentTimer);
                // this.cd.detectChanges(); //for evry detection when tab is swap then praority is taking low.
                if (!this.cd["destroyed"]) {
                    this.cd.detectChanges();
                }
            }, 100);
        }
    }

    /**
     * This function is used to find the timer
     * @param uuid -uuid data
     * @returns data
     */
    findTimer(uuid) {
        let AllTimer = JSON.parse(localStorage.getItem(this.timerId));
        let index = AllTimer.findIndex((item) => item.uuid === uuid);
        return AllTimer[index];
    }

    /**
     * This function is used to stop timer data value
     */
    stopTimer() {
        clearTimeout(this.timerInterval);
    }

    /**
     * this function is used to end Matter back
     * @param -data
     */
    endMatterBack(uuid: any) {
        let AllTimer = JSON.parse(localStorage.getItem(this.timerId));
        let matterDataTime;
        let WORKITEMGUID;
        let previewTimer: any = {};
        let dateFo = new Date();
        let currentUTC = dateFo.getTime() + dateFo.getTimezoneOffset();
        for (var i = AllTimer.length - 1; i >= 0; --i) {
            if (AllTimer[i].uuid == uuid) {
                if (
                    localStorage.getItem("start_" + AllTimer[i].uuid) &&
                    AllTimer[i].isStart
                ) {
                    let items: any = AllTimer[i];
                    AllTimer[i].time =
                        Number(items.time) +
                        Math.round(
                            Math.abs(
                                (Number(currentUTC) - Number(items.startTime)) /
                                1000
                            )
                        );
                    clearTimeout(this.timerInterval);
                    matterDataTime = this.secondsToHms(AllTimer[i].time);
                    WORKITEMGUID = AllTimer[i].WORKITEMGUID;
                    localStorage.removeItem("start_" + AllTimer[i].uuid);
                    this.currentTimer = 0;
                } else {
                    matterDataTime = this.secondsToHms(AllTimer[i].time);
                    WORKITEMGUID = AllTimer[i].WORKITEMGUID;
                }
                previewTimer = AllTimer[i];
                AllTimer.splice(i, 1);
            }
        }
        if (AllTimer.length == 0) {
            localStorage.removeItem(this.timerId);
        } else {
            localStorage.setItem(this.timerId, JSON.stringify(AllTimer));
        }
        this._fuseSidebarService.getSidebar("quickPanel").toggleOpen();
        this.behaviorService.setTimerCountData();
        if (WORKITEMGUID && WORKITEMGUID != "") {
            this.ResumeTimePopup("resume", {
                time: matterDataTime,
                WORKITEMGUID: WORKITEMGUID,
            });
        } else {
            const dialogRef = this.dialog.open(ResumeTimerComponent, {
                width: "100%",
                disableClose: true,
                data: { type: "stopResume", matterData: previewTimer },
            });
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    $("#refreshTimeEntryTab").click();
                    $("#refreshWorkInprogress").click();
                }
            });
        }
    }

    /**
     * This function is used to split the time entry data value
     * @param type -type of the data value
     */
    splittimeentry(type) {
        let popupData: any = {
            action: type,
            WORKITEMGUID: this.WorkInProgressData,
        };
        //  this.toolbarServiceService.SetOldistrackid('open');
        const dialogRef = this.dialog.open(SplitTimeEntryComponent, {
            width: "100%",
            disableClose: true,
            data: popupData,
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");
            if (result) {
                $("#refreshTimeEntryTab").click();
                $("#refreshWorkInprogress").click();
            }
        });
    }

    /**
     * This function is used to resume time popup
     * @param type -type of the resume time popup
     * @param timerData -timer data value
     */
    ResumeTimePopup(type: any, timerData: any) {
        this.toolbarServiceService.SetOldistrackid("open");
        const dialogRef = this.dialog.open(ResumeTimerComponent, {
            width: "100%",
            disableClose: true,
            data: { type: type, matterData: timerData },
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");
            if (result) {
                $("#refreshTimeEntryTab").click();
                $("#refreshWorkInprogress").click();
            }
        });
    }

    /**
     * This function is used to add the deleverable data value.
     * @param actionType -Action type value
     */
    adddeliverable(actionType: any) {
        let matterData = JSON.parse(localStorage.getItem("set_active_matters"));
        this.toolbarServiceService.SetOldistrackid("open");
        this.toolbarServiceService.SetOldistrackid("open");
        let EstimatePopdata = {};
        if (actionType == "New") {
            EstimatePopdata = { action: actionType };
        } else if (actionType == "edit" || actionType == "duplicate") {
            // let PostData: any = { Action: "GetData",Filters: {MATTERDELIVERABLEGUID:matterData.MATTERDELIVERABLEGUID }};
            // this._mainAPiServiceService.getSetData( PostData, 'matter-deliverable ').subscribe(response => {
            //   if (response.CODE == 200 && response.STATUS == "success") {
            //     if (response) {

            //     }
            //   }
            // }, error => {
            //   this.toastr.error(error);
            // });
            EstimatePopdata = { action: actionType };
        }
        const dialogRef = this.dialog.open(DeliverableDailogComponent, {
            width: "100%",
            disableClose: true,
            data: { type: actionType },
        });

        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");
            if (result) {
                $("#refresheDeliverableTab").click();
                // $('#refreshWorkInprogress').click();
            }
        });
    }

    /**
     * This function is used to delete the delevarables data value
     */
    deletedeliverable() {
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",
            }
        );
        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to delete?";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let postData = {
                    FormAction: "delete",
                    DATA: {
                        MATTERDELIVERABLEGUID:
                            this.deliverableDatalegel.MATTERDELIVERABLEGUID,
                    },
                };
                let RefreshIdArray: any = ["#refresheDeliverableTab"];
                this.toolbarServiceService.SetVariableForRetry(
                    postData,
                    "matter-deliverable",
                    RefreshIdArray
                );
                this._mainAPiServiceService
                    .getSetData(postData, "matter-deliverable")
                    .subscribe((res) => {
                        if (res.STATUS == "success" && res.CODE == 200) {
                            localStorage.setItem(
                                "istrackid",
                                this.oldIsTrackId
                            );
                            $("#refresheDeliverableTab").click();
                            this.toastr.success("Deleted successfully");
                        } else if (res.CODE == 451 && res.STATUS == "warning") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "matter-deliverable"
                            );
                        } else if (res.CODE == 450 && res.STATUS == "error") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "matter-deliverable"
                            );
                        } else if (res.MESSAGE == "Not logged in") {
                            this.dialogRef.close(false);
                        }
                    });
            }
            this.confirmDialogRef = null;
        });
    }

    /**
     * This function is used to Edit the TimeEntry data value
     */
    EditTimeEntryFromWIP() {
        if (this.WorkInProgressData.ITEMTYPEDESC == "Disbursement") {
            this.toolbarServiceService.spendmoneypopup("edit");
        } else if (
            this.WorkInProgressData.ITEMTYPEDESC == "Activity" ||
            this.WorkInProgressData.ITEMTYPEDESC == "Sundry"
        ) {
            this.toolbarServiceService.SetOldistrackid("open");
            let popupData: any = {
                action: "edit",
                WORKITEMGUID: this.WorkInProgressData.WORKITEMGUID,
            };
            const dialogRef = this.dialog.open(ActivityDialogComponent, {
                disableClose: true,
                panelClass: "Activity-dialog",
                data: {
                    popupData,
                    popupname: this.WorkInProgressData.ITEMTYPEDESC,
                },
            });
            dialogRef.afterClosed().subscribe((result) => {
                this.toolbarServiceService.SetOldistrackid("close");
                if (result) {
                    $("#refreshTimeEntryTab").click();
                    $("#refreshWorkInprogress").click();
                }
            });
        } else {
            this.toolbarServiceService.addNewTimeEntry("Edit", "", "", "");
        }
    }

    /**
     * This function is used to get the Write off time entry data value
     * @param type -type data value
     */
    WriteOffTimeEntry(type) {
        let WorkItemData =
            type == "WIP" ? this.WorkInProgressData : this.timeEntryData;
        let ItemTypDesc = WorkItemData.ITEMTYPEDESC;
        if (type == "WIP") {
            let selectedWipCheckBox: any = JSON.parse(
                localStorage.getItem("SelectedWIPCheckbox")
            );
            if (!selectedWipCheckBox || selectedWipCheckBox.length < 2) {
                if (selectedWipCheckBox[0]) {
                    WorkItemData = selectedWipCheckBox[0];
                    ItemTypDesc = selectedWipCheckBox[0].ITEMTYPEDESC;
                }
                if (
                    ItemTypDesc == "WIP" ||
                    ItemTypDesc == "Activity" ||
                    ItemTypDesc == "Sundry"
                ) {
                    this.OpenWriteOffDialog(type);
                } else {
                    this.DirectWriteOff(WorkItemData);
                }
            } else {
                this.MultiDirectWriteOff(selectedWipCheckBox);
            }
        } else {
            this.DirectWriteOff(WorkItemData);
        }
        // if (ItemTypDesc == 'WIP' || ItemTypDesc == 'Activity' || ItemTypDesc == 'Sundry') {
        //     this.OpenWriteOffDialog(type);
        // } else {
        //     this.DirectWriteOff(WorkItemData);
        // }
    }

    /**
     * This function is used to get the Multi DirecWrite off data
     * @param Data -data value
     */
    MultiDirectWriteOff(Data) {
        this.RequestGuid = this._mainAPiServiceService.generateUniqSerial();
        let multiWriteoff = [];
        Data.forEach((element) => {
            let WriteoffElement: any = {
                ADDITIONALTEXT: element.ADDITIONALTEXT,
                COMMENT: element.COMMENT,
                FEEEARNER: element.FEEEARNER,
                ITEMTYPE: element.ITEMTYPE,
                ITEMDATE: element.ITEMDATE,
                ITEMTIME: element.ITEMTIME,
                MATTERGUID: element.MATTERGUID,
                PRICE: element.PRICE,
                PRICEINCGST: element.PRICEINCGST,
                QUANTITY: element.QUANTITY,
                WRITEOFFQUANTITY: element.QUANTITY,
                REMAININGQUANTITY: "",
                WorkItemGuid: element.WORKITEMGUID,
            };
            if (element.QUANTITYTYPE == "hh:mm") {
                WriteoffElement.WRITEOFFQUANTITY = this.secondsToHms(
                    element.QUANTITY
                );
                WriteoffElement.REMAININGQUANTITY = this.secondsToHms("");
            }
            if (element.ITEMTYP == "2" || element.ITEMTYPE == "3") {
                WriteoffElement.FEETYPE = element.QUANTITYTYPE;
                WriteoffElement.QUANTITYTYPE = "";
            } else {
                WriteoffElement.QUANTITYTYPE = element.QUANTITYTYPE;
            }
            multiWriteoff.push(WriteoffElement);
        });

        let PostTimeEntryData: any = {
            FormAction: "write off",
            VALIDATEONLY: true,
            RequestGuid: this.RequestGuid,
            MULTIRECORD: true,
            Data: multiWriteoff,
        };
        this.TimersServiceI.SetWorkItems(PostTimeEntryData)
            .subscribe(
                (res) => {
                    if (res.CODE == 200 && res.STATUS == "success") {
                        this.checkValidationWrite(
                            res.DATA.VALIDATIONS,
                            PostTimeEntryData
                        );
                    } else if (res.CODE == 451 && res.STATUS == "warning") {
                        this.checkValidationWrite(
                            res.DATA.VALIDATIONS,
                            PostTimeEntryData
                        );
                    } else if (res.CODE == 450 && res.STATUS == "error") {
                        this.checkValidationWrite(
                            res.DATA.VALIDATIONS,
                            PostTimeEntryData
                        );
                    }
                },
                (err) => {
                    this.isspiner = false;
                    this.toastr.error(err);
                }
            );
    }

    /**
     * This function is used to Direct Write off
     * @param Data -data value
     */
    DirectWriteOff(Data) {
        let PostData: any = {
            ADDITIONALTEXT: Data.ADDITIONALTEXT,
            COMMENT: Data.COMMENT,
            FEEEARNER: Data.FEEEARNER,
            ITEMTYPE: Data.ITEMTYPE,
            ITEMDATE: Data.ITEMDATE,
            ITEMTIME: Data.ITEMTIME,
            MATTERGUID: Data.MATTERGUID,
            PRICE: Data.PRICE,
            PRICEINCGST: Data.PRICEINCGST,
            QUANTITY: Data.QUANTITY,
            WRITEOFFQUANTITY: Data.QUANTITY,
            REMAININGQUANTITY: "",
            WorkItemGuid: Data.WORKITEMGUID,
        };
        if (Data.QUANTITYTYPE == "hh:mm") {
            PostData.WRITEOFFQUANTITY = this.secondsToHms(Data.QUANTITY);
            PostData.REMAININGQUANTITY = this.secondsToHms("");
        }
        if (Data.ITEMTYP == "2" || Data.ITEMTYPE == "3") {
            PostData.FEETYPE = Data.QUANTITYTYPE;
            PostData.QUANTITYTYPE = "";
        } else {
            PostData.QUANTITYTYPE = Data.QUANTITYTYPE;
        }
        let PostTimeEntryData: any = {
            FormAction: "write off",
            VALIDATEONLY: true,
            RequestGuid: this.RequestGuid,
            Data: PostData,
        };
        this.TimersServiceI.SetWorkItems(PostTimeEntryData)
            .subscribe(
                (res) => {
                    if (res.CODE == 200 && res.STATUS == "success") {
                        this.checkValidationWrite(
                            res.DATA.VALIDATIONS,
                            PostTimeEntryData
                        );
                    } else if (res.CODE == 451 && res.STATUS == "warning") {
                        this.checkValidationWrite(
                            res.DATA.VALIDATIONS,
                            PostTimeEntryData
                        );
                    } else if (res.CODE == 450 && res.STATUS == "error") {
                        this.checkValidationWrite(
                            res.DATA.VALIDATIONS,
                            PostTimeEntryData
                        );
                    }
                },
                (err) => {
                    this.isspiner = false;
                    this.toastr.error(err);
                }
            );
    }

    /**
     * This function is used to open the body data value
     * @param bodyData -bodydata value
     * @param PostTimeEntryData -post time entry data value
     */
    checkValidationWrite(bodyData: any, PostTimeEntryData: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        // errorData
        bodyData.forEach(function (value) {
            if (value.VALUEVALID == "No" || value.VALUEVALID == "NO") {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (
                value.VALUEVALID == "WARNING" ||
                value.VALUEVALID == "Warning"
            ) {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        if (Object.keys(errorData).length != 0) {
            this.toastr.error(errorData);
            this.isspiner = false;
        } else if (Object.keys(warningData).length != 0) {
            this.isspiner = false;
            this.confirmDialogRef = this.MatDialog.open(
                FuseConfirmDialogComponent,
                {
                    disableClose: true,
                    width: "100%",
                    data: warningData,
                }
            );
            this.confirmDialogRef.componentInstance.confirmMessage =
                "Are you sure you want to Save?";
            this.confirmDialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.isspiner = true;
                    this.saveTimeEntryWrite(PostTimeEntryData);
                }
                this.confirmDialogRef = null;
            });
        } else if (
            Object.keys(warningData).length == 0 &&
            Object.keys(errorData).length == 0
        ) {
            this.saveTimeEntryWrite(PostTimeEntryData);
        }
    }

    /**
     * This function is used to saveTimeEntryWrite off data value
     * @param PostTimeEntryData -data value data
     */
    saveTimeEntryWrite(PostTimeEntryData: any) {
        //this.RequestGuid =  this._mainAPiServiceService.generateUniqSerial();
        PostTimeEntryData.VALIDATEONLY = false;
        PostTimeEntryData.RequestGuid = this.RequestGuid;
        this.TimersServiceI.SetWorkItems(PostTimeEntryData)
            .subscribe(
                (res) => {
                    if (res.CODE == 200 && res.STATUS == "success") {
                        this.toastr.success("Update success");
                        $("#refreshTimeEntryTab").click();
                        $("#refresheWorkEtimateTab").click();
                    } else if (res.CODE == 451 && res.STATUS == "warning") {
                        this.toastr.warning(res.MESSAGE);
                        this.isspiner = false;
                    } else if (res.CODE == 450 && res.STATUS == "error") {
                        this.toastr.warning(res.MESSAGE);
                        this.isspiner = false;
                    }
                },
                (err) => {
                    this.isspiner = false;
                    this.toastr.error(err);
                }
            );
    }

    /**
     * This function is used to open the Write off Dialog data
     * @param type -type of the data
     */
    OpenWriteOffDialog(type) {
        this.toolbarServiceService.SetOldistrackid("open");
        const dialogRef = this._matDialog.open(WriteOffTimeEntryComponent, {
            width: "100%",
            disableClose: true,
            data: { type: type },
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");
            if (result) {
                $("#refreshTimeEntryTab").click();
                $("#refresheWorkEtimateTab").click();
            }
        });
    }

    /**
     * This function is used to UndoWrite off time entry
     * @param type -type of the time entry
     */
    UndoWriteOffTimeEntry(type) {
        this.RequestGuid = this._mainAPiServiceService.generateUniqSerial();
        let workerId =
            type == "WIP"
                ? this.WorkInProgressData.WORKITEMGUID
                : this.timeEntryData.WORKITEMGUID;
        let postData = {
            FormAction: "undo write off",
            RequestGuid: this.RequestGuid,
            DATA: { WORKITEMGUID: workerId },
        };
        this.TimersServiceI.SetWorkItems(postData)
            .subscribe((res) => {
                if (res.STATUS == "success" && res.CODE == 200) {
                    $("#refreshTimeEntryTab").click();
                    $("#refresheWorkEtimateTab").click();
                    this.toastr.success("Undo successfully");
                }
            });
    }

    /**
     * This function isnused to create the diary data value
     */
    CreateDiary() {
        $("#saveCreateDiary").click();
    }

    /**
     * This function is used to generate the invoice data
     */
    GenerateInvoice() {
        // this.behaviorService.matterInvoice$.subscribe(result => {
        //     if (result != null) {
        //         this.isInvoiceData = 'Notempty';
        //         this.ShowGenerateInvoice = "yes";
        //     } else {
        //         this.isInvoiceData = 'empty';
        //         this.ShowGenerateInvoice = "no";
        //     }
        // });
        const dialogRef = this._matDialog.open(GenerateInvoiceComponent, {
            width: "100%",
            disableClose: true,
            data: {},
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                // $('#refreshTimeEntryTab').click();
            }
        });
    }

    /**
     * This function is used to Generate the Receipt data value
     */
    GenerateReceipt() {
        let receiData = JSON.parse(localStorage.getItem("receiptData"));
        //Before Income class is : 'Income'
        let passdata = {
            Context: receiData.INCOMECLASS,
            ContextGuid: receiData.INCOMEGUID,
            knownby: "Template",
            Type: "Template",
            Template: "<DEFRECTEMP>",
        };
        const dialogRef = this._matDialog.open(GenerateDocumentPopupComponent, {
            width: "100%",
            disableClose: true,
            data: passdata,
        });
        dialogRef.afterClosed().subscribe((result) => { });
    }

/**
 * THIS function is used to refresh the recouncil button
 */
    RecouncileAccount() {
        $("#SetRecouncilItem").click();
    }

    /**
     * This function is used to undo the Recouncil btn
     */
    undoRecouncilebtn() {
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",
            }
        );
        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to undo the last Reconciliation? This will reverse the last Reconciliation!";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let Trustval: any =
                    this.isStoreTrustVal.UseTrust == "Yes" ||
                        this.isStoreTrustVal.UseTrust == true
                        ? true
                        : false;
                let postData = {
                    FormAction: "delete",
                    USETRUST: Trustval,
                    DATA: { ACCOUNTGUID: this.chartAccountDetail.ACCOUNTGUID },
                };

                this.toolbarServiceService.SetVariableForRetry(
                    postData,
                    "SetReconciliation",
                    []
                );
                this._mainAPiServiceService
                    .getSetData(postData, "SetReconciliation")
                    .subscribe((res) => {
                        if (res.STATUS == "success" && res.CODE == 200) {
                            localStorage.setItem(
                                "istrackid",
                                this.oldIsTrackId
                            );
                            this.toastr.success(
                                "Undo Last Reconciliation successfully"
                            );
                            $("#pastRecouncilliationRefresh").click();
                            $("#refreshRecouncilItem").click();
                        } else if (res.CODE == 451 && res.STATUS == "warning") {
                            this.toastr.warning(res.MESSAGE);
                            this.checkValidationUndo(res.DATA.VALIDATIONS);
                        } else if (res.CODE == 450 && res.STATUS == "error") {
                            this.toastr.warning(res.MESSAGE);
                            this.checkValidationUndo(res.DATA.VALIDATIONS);
                        } else if (res.MESSAGE == "Not logged in") {
                            this.dialogRef.close(false);
                        }
                    });
            }
            this.confirmDialogRef = null;
        });
    }

    /**
     * This function is used to check the validation data.
     * @param bodyData -body data
     */
    checkValidationUndo(bodyData: any) {
        let errorData: any = [];
        let warningData: any = [];
        bodyData.forEach(function (value) {
            if (value.VALUEVALID == "No" || value.VALUEVALID == "Error") {
                errorData.push(value.ERRORDESCRIPTION);
            } else if (value.VALUEVALID == "Warning") {
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        if (Object.keys(errorData).length != 0) {
            this.toastr.error(errorData);
        } else if (Object.keys(warningData).length != 0) {
            this.toastr.warning(warningData);
        }
    }

    /**
     * This function is used to remove UnusedAdjustment data
     */
    removeUnusedAdjustment() {
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",
            }
        );
        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to this unused adjustment?";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let postData = {
                    FormAction: "delete",
                    DATA: { ADJUSTMENTGUID: this.recouncileItemdata.LINKGUID },
                };
                this.toolbarServiceService.SetVariableForRetry(
                    postData,
                    "SetReconciliationAdjustment",
                    []
                );
                this._mainAPiServiceService
                    .getSetData(postData, "SetReconciliationAdjustment")
                    .subscribe((res) => {
                        if (res.STATUS == "success" && res.CODE == 200) {
                            localStorage.setItem(
                                "istrackid",
                                this.oldIsTrackId
                            );
                            this.toastr.success("Deleted successfully");
                            $("#refreshRecouncilItem").click();
                        } else if (res.CODE == 451 && res.STATUS == "warning") {
                            this.checkValidationUndo(res.DATA.VALIDATIONS);
                        } else if (res.CODE == 450 && res.STATUS == "error") {
                            this.checkValidationUndo(res.DATA.VALIDATIONS);
                        } else if (res.MESSAGE == "Not logged in") {
                            this.dialogRef.close(false);
                        }
                    });
            }
            this.confirmDialogRef = null;
        });
    }
    /* ---------------------------------------------------------------------end of timer add--------------------------------------------------------------------------  */
   /**
    * This function is used to report popup data
    * @param ReportData -report data value
    */
    Reportpopup(ReportData) {
        let type: number;
        if (ReportData.REPORTGROUP == "Management") type = 27;
        else if (ReportData.REPORTGROUP == "Accounts") type = 26;
        else if (ReportData.REPORTGROUP == "Trust") type = 25;
        const dialogRef = this.dialog.open(ReportsComponent, {
            width: "100%",
            data: ReportData,
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe((result) => { });
    }
    // // File PopUp
    // FileNotePopup(actionType , matterIs?:any) {
    //     this.toolbarServiceService.SetOldistrackid('open');
    //     let FileNotePopdata : any = {}
    //     if (actionType == 'new') {
    //         FileNotePopdata = { action: actionType }
    //     } else if (actionType == 'edit' || actionType == 'duplicate') {
    //         FileNotePopdata = { action: actionType }
    //     }

    //     if(matterIs){
    //         FileNotePopdata.globalMatter = matterIs;
    //     };

    //     const dialogRef = this.dialog.open(FileNoteDialogComponent, {
    //         disableClose: true, panelClass: 'Document-dialog', data: FileNotePopdata
    //     });
    //     dialogRef.afterClosed().subscribe(result => {
    //         this.toolbarServiceService.SetOldistrackid('close');
    //         if (result) {
    //             $('#refreshFileNote').click();
    //         }
    //     });
    // }

    /**
     * This functionis used to delete the file noted data
     */
    DeleteFileNotes() {
        if (this.FileNotesData == null) {
            this.toastr.error("No Data Selected");
        } else {
            this.confirmDialogRef = this._matDialog.open(
                FuseConfirmDialogComponent,
                {
                    disableClose: true,
                    width: "100%",
                }
            );
            this.confirmDialogRef.componentInstance.confirmMessage =
                "Are you sure you want to delete?";
            this.confirmDialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    let postData = {
                        FormAction: "delete",
                        DATA: { FILENOTEGUID: this.FileNotesData.FILENOTEGUID },
                    };
                    let RefreshIdArray: any = ["#refreshFileNote"];
                    this.toolbarServiceService.SetVariableForRetry(
                        postData,
                        "file-note",
                        RefreshIdArray
                    );
                    // old is SetFileNote new is  file-note
                    this._mainAPiServiceService
                        .getSetData(postData, "file-note")
                        .subscribe((res) => {
                            if (res.STATUS == "success" && res.CODE == 200) {
                                localStorage.setItem(
                                    "istrackid",
                                    this.oldIsTrackId
                                );
                                $("#refreshFileNote").click();
                                this.toastr.success("Deleted successfully");
                            } else if (
                                res.CODE == 451 &&
                                res.STATUS == "warning"
                            ) {
                                this.checkValidation(
                                    res.DATA.VALIDATIONS,
                                    postData,
                                    "file-note"
                                );
                            } else if (
                                res.CODE == 450 &&
                                res.STATUS == "error"
                            ) {
                                this.checkValidation(
                                    res.DATA.VALIDATIONS,
                                    postData,
                                    "file-note"
                                );
                            } else if (res.MESSAGE == "Not logged in") {
                                this.dialogRef.close(false);
                            }
                        });
                }
                this.confirmDialogRef = null;
            });
        }
    }

/**
 * This function is used to delete the spend MOney data value
 */
    Deletespendmoneypopup(): void {
        if (this.SendMoney_dataGUID == null) {
            this.toastr.error("No Data Selected");
        } else {
            let postData = {
                FormAction: "delete",
                VALIDATEONLY: true,
                DATA: {
                    EXPENDITUREGUID: this.SendMoney_dataGUID.EXPENDITUREGUID,
                },
            };
            let RefreshIdArray: any = ["#refreshSpendMoneyTab"];
            this.toolbarServiceService.SetVariableForRetry(
                postData,
                "SetExpenditure",
                RefreshIdArray
            );
            this._mainAPiServiceService
                .getSetData(postData, "SetExpenditure")
                .subscribe((res) => {
                    if (res.STATUS == "success" && res.CODE == 200) {
                        // localStorage.setItem('istrackid', this.oldIsTrackId);
                        // $('#refreshSpendMoneyTab').click();
                        this.ConformPopupSpendMonyDelete();
                    } else if (res.CODE == 451 && res.STATUS == "warning") {
                        this.checkValidation(
                            res.DATA.VALIDATIONS,
                            postData,
                            "SetExpenditure"
                        );
                    } else if (res.CODE == 450 && res.STATUS == "error") {
                        this.checkValidation(
                            res.DATA.VALIDATIONS,
                            postData,
                            "SetExpenditure"
                        );
                    }
                    // else if (res.CODE == 462 && res.STATUS == 'error') {
                    //     this.checkValidation(res.DATA.VALIDATIONS, postData, 'SetMatter');
                    // }
                    else if (res.MESSAGE == "Not logged in") {
                        this.dialogRef.close(false);
                    }
                });
        }
    }

    /**
     * Spend money pop up delete
     */
    ConformPopupSpendMonyDelete() {
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",
            }
        );
        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to delete?";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let postData = {
                    FormAction: "delete",
                    VALIDATEONLY: false,
                    DATA: {
                        EXPENDITUREGUID:
                            this.SendMoney_dataGUID.EXPENDITUREGUID,
                    },
                };
                let RefreshIdArray: any = ["#refreshSpendMoneyTab"];
                this.toolbarServiceService.SetVariableForRetry(
                    postData,
                    "SetExpenditure",
                    RefreshIdArray
                );
                this._mainAPiServiceService
                    .getSetData(postData, "SetExpenditure")
                    .subscribe((res) => {
                        if (res.STATUS == "success" && res.CODE == 200) {
                            localStorage.setItem(
                                "istrackid",
                                this.oldIsTrackId
                            );
                            $("#refreshSpendMoneyTab").click();
                            // this.spendmoney.refreshSpendMoneyTab();
                            this.toastr.success("Deleted successfully");
                        } else if (res.CODE == 451 && res.STATUS == "warning") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "SetExpenditure"
                            );
                        } else if (res.CODE == 450 && res.STATUS == "error") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "SetExpenditure"
                            );
                        } else if (res.MESSAGE == "Not logged in") {
                            this.dialogRef.close(false);
                        }
                    });
            }
            this.confirmDialogRef = null;
        });
    }

    /**
     * This function is used to add New Estimate data.
     * @param actionType -action type
     */
    addNewEstimate(actionType) {
        this.toolbarServiceService.SetOldistrackid("open");
        let EstimatePopdata = {};
        if (actionType == "new") {
            EstimatePopdata = { action: actionType };
        } else if (actionType == "edit" || actionType == "duplicate") {
            EstimatePopdata = { action: actionType };
        }
        const dialogRef = this.dialog.open(EstimateDilogComponent, {
            disableClose: true,
            panelClass: "Document-dialog",
            data: EstimatePopdata,
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");

            if (result) $("#refresheEtimateTab").click();
        });
    }
   
    /**
     * This function is used to delete the estimate the data. 
     */
    deleteEstimate() {
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",
            }
        );
        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to delete?";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let postData = {
                    FormAction: "delete",
                    DATA: {
                        ESTIMATEITEMGUID: this.estimateData.ESTIMATEITEMGUID,
                    },
                };
                let RefreshIdArray: any = ["#refresheEtimateTab"];
                this.toolbarServiceService.SetVariableForRetry(
                    postData,
                    "matter-estimate-item",
                    RefreshIdArray
                );
                this._mainAPiServiceService
                    .getSetData(postData, "matter-estimate-item")
                    .subscribe((res) => {
                        if (res.STATUS == "success" && res.CODE == 200) {
                            localStorage.setItem(
                                "istrackid",
                                this.oldIsTrackId
                            );
                            $("#refresheEtimateTab").click();
                            $('#Legal_Details').click()
                            this.toastr.success("Deleted successfully");
                        } else if (res.CODE == 451 && res.STATUS == "warning") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "matter-estimate-item"
                            );
                        } else if (res.CODE == 450 && res.STATUS == "error") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "matter-estimate-item"
                            );
                        } else if (res.MESSAGE == "Not logged in") {
                            this.dialogRef.close(false);
                        }
                    });
            }
            this.confirmDialogRef = null;
        });
    }
  
    /**
     * This function is used to deleteContact data
     */
    deleteContact(): void {
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",
            }
        );
        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to delete?";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let getContactGuId = localStorage.getItem("contactGuid");
                let postData = {
                    Action: "delete",
                    data: { CONTACTGUID: getContactGuId },
                };
                let RefreshIdArray: any = ["#refreshContactTab"];
                this.toolbarServiceService.SetVariableForRetry(
                    postData,
                    "contact",
                    RefreshIdArray
                );
                this._mainAPiServiceService
                    .getSetData(postData, "contact")
                    .subscribe(
                        (response) => {
                            if (
                                response.CODE == 200 &&
                                (response.STATUS == "OK" ||
                                    response.STATUS == "success")
                            ) {
                                localStorage.setItem(
                                    "istrackid",
                                    this.oldIsTrackId
                                );
                                let contactData = JSON.parse(
                                    localStorage.getItem("contactData")
                                );
                                this.behaviorService.setChildContactTableData({
                                    CONTACTGUID: contactData.CONTACTGUID,
                                    action: "deleteData",
                                    contactType: contactData.CONTACTTYPE,
                                });
                                $("#refreshContactTab").click();
                                $('#refreshContactTa').click();
                                this.toastr.success("Deleted successfully");
                                // this.checkValidation(response.DATA.VALIDATIONS, postData, 'SetContact');
                            } else if (
                                response.CODE == 451 &&
                                response.STATUS == "warning"
                            ) {
                                this.checkValidation(
                                    response.DATA.VALIDATIONS,
                                    postData,
                                    "contact"
                                );
                            } else if (
                                response.CODE == 450 &&
                                response.STATUS == "error"
                            ) {
                                this.checkValidation(
                                    response.DATA.VALIDATIONS,
                                    postData,
                                    "contact"
                                );
                            } else if (response.MESSAGE == "Not logged in") {
                                this.dialogRef.close(false);
                            }
                        },
                        (error) => {
                            this.toastr.error(error);
                        }
                    );
            }
            this.confirmDialogRef = null;
        });
    }
    async deleteTimeEntry(): Promise<void> {
        if (this.dissabledeleteButton && this.router.url == '/matter-details/work-in-progress') {
            let FilterItemTypeDesc: any[] = [];
            
            // Wait for the subscription to resolve
            try {
                const datavalue = await firstValueFrom(this.behaviorService.selectedWorkinProgressMatter$);
                if (datavalue && datavalue.length !== 0) {
                    FilterItemTypeDesc = datavalue.filter(data => data.ItemTypDesc !== 'Search');
                }
            } catch (error) {
                console.error('Error fetching data from observable:', error);
            }
    
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: true,
                width: "100%",
            });
            this.confirmDialogRef.componentInstance.confirmMessage = 
                `Are you sure you want to delete ${FilterItemTypeDesc.length} entries?`;
    
            // Wait for the dialog to close and get the result
            const result = await this.confirmDialogRef.afterClosed().toPromise();
            if (result) {
                for (let i = 0; i < FilterItemTypeDesc.length; i++) {
                    const item = FilterItemTypeDesc[i];
                    const WORKITEMGUID = item?.WORKITEMGUID || localStorage.getItem("edit_WORKITEMGUID");
                    const postData = item.ITEMTYPEDESC !== "Disbursement"
                        ? { FormAction: "delete", data: { WORKITEMGUID } }
                        : { FormAction: "delete", data: { EXPENDITUREGUID: item.EXPENDITUREGUID } };
                    const apiName = item.ITEMTYPEDESC !== "Disbursement" ? "workitem" : "SetExpenditure";
    
                    // Set retry variables
                    const RefreshIdArray = ["#refreshTimeEntryTab", "#refreshWorkInprogress"];
                    this.toolbarServiceService.SetVariableForRetry(postData, apiName, RefreshIdArray);
    
                    // Perform API call and handle responses
                    const res = await this._mainAPiServiceService.getSetData(postData, apiName).toPromise();
                    if (res.STATUS == "success" && res.CODE == 200) {
                        localStorage.setItem("istrackid", this.oldIsTrackId);
                        this.toastr.success("Successfully Deleted");
                        if(i == FilterItemTypeDesc.length-1){
                            $("#refreshTimeEntryTab").click();
                            $("#refreshWorkInprogress").click();
                            $('#Legal_Details').click();
                            $("#refreshtabledetails").click();
                            $("#refreshreciptsDAta").click();
                        }
                        
                    } else if (res.CODE == 451 || res.CODE == 450) {
                        await this.checkValidation(res.DATA.VALIDATIONS, postData, apiName);
                    } else if (res.MESSAGE == "Not logged in") {
                        this.dialogRef.close(false);
                    }
                }
            }
    
            this.confirmDialogRef = null;
        
    }else{
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",
            }
        );
        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to delete?";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let WORKITEMGUID;
                if (this.WorkInProgressData) {
                    WORKITEMGUID = this.WorkInProgressData.WORKITEMGUID;
                } else {
                    WORKITEMGUID = localStorage.getItem("edit_WORKITEMGUID");
                }
                // });
                if (this.WorkInProgressData.ITEMTYPEDESC != "Disbursement") {
                    let postData = {
                        FormAction: "delete",
                        data: { WORKITEMGUID: WORKITEMGUID },
                    };
                    let RefreshIdArray: any = [
                        "#refreshTimeEntryTab",
                        "#refreshWorkInprogress",
                    ];
                    RefreshIdArray[0] = "#refreshTimeEntryTab";
                    this.toolbarServiceService.SetVariableForRetry(
                        postData,
                        "workitem",
                        RefreshIdArray
                    );
                    this._mainAPiServiceService
                        .getSetData(postData, "workitem")
                        .subscribe((res) => {
                            if (res.STATUS == "success" && res.CODE == 200) {
                                localStorage.setItem(
                                    "istrackid",
                                    this.oldIsTrackId
                                );
                                this.toastr.success("Successfully Deleted");
                                $("#refreshTimeEntryTab").click();
                                $("#refreshWorkInprogress").click();
                                $('#Legal_Details').click()
                                $("#refreshtabledetails").click();
                                $("#refreshreciptsDAta").click();
                            } else if (
                                res.CODE == 451 &&
                                res.STATUS == "warning"
                            ) {
                                this.checkValidation(
                                    res.DATA.VALIDATIONS,
                                    postData,
                                    "workitem"
                                );
                            } else if (
                                res.CODE == 450 &&
                                res.STATUS == "error"
                            ) {
                                this.checkValidation(
                                    res.DATA.VALIDATIONS,
                                    postData,
                                    "workitem"
                                );
                            } else if (res.MESSAGE == "Not logged in") {
                                this.dialogRef.close(false);
                            }
                        });
                } else {
                    let postData = {
                        FormAction: "delete",
                        data: {
                            EXPENDITUREGUID:
                                this.WorkInProgressData.EXPENDITUREGUID,
                        },
                    };
                    let RefreshIdArray: any = [
                        "#refreshTimeEntryTab",
                        "#refreshWorkInprogress",
                    ];
                    this.toolbarServiceService.SetVariableForRetry(
                        postData,
                        "SetExpenditure",
                        RefreshIdArray
                    );
                    this._mainAPiServiceService
                        .getSetData(postData, "SetExpenditure")
                        .subscribe((res) => {
                            if (res.STATUS == "success" && res.CODE == 200) {
                                localStorage.setItem(
                                    "istrackid",
                                    this.oldIsTrackId
                                );
                                $("#refreshTimeEntryTab").click();
                                $("#refreshWorkInprogress").click();
                                $('#Legal_Details').click()
                                this.toastr.success("Deleted successfully");
                            } else if (
                                res.CODE == 451 &&
                                res.STATUS == "warning"
                            ) {
                                this.checkValidation(
                                    res.DATA.VALIDATIONS,
                                    postData,
                                    "SetExpenditure"
                                );
                            } else if (
                                res.CODE == 450 &&
                                res.STATUS == "error"
                            ) {
                                this.checkValidation(
                                    res.DATA.VALIDATIONS,
                                    postData,
                                    "SetExpenditure"
                                );
                            } else if (res.MESSAGE == "Not logged in") {
                                this.dialogRef.close(false);
                            }
                        });
                }
            }
            this.confirmDialogRef = null;
        });

       } 
        
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
        this._unsubscribeAll.next("");
        this.openDifferentTabunsub?.unsubscribe();
        this._unsubscribeAll.complete();
        this.destoryCurrentState?.unsubscribe();
    }

    /**
     * This function is used to change the password
     */
    ChangePass() {
        const dialogRef = this.dialog.open(ChangePasswordComponent, {
            disableClose: true,
            panelClass: "change-password",
        });
        dialogRef.afterClosed().subscribe((result) => { });
    }
    
    /**
     * This function is used to open the Document popup
     * @param actionType -action type data
     */
    DocumntPop(actionType) {
        this.toolbarServiceService.SetOldistrackid("open");
        let DcoumentPopdata = {};
        if (actionType == "new") {
            DcoumentPopdata = { action: actionType };
        } else if (actionType == "edit" || actionType == "duplicate") {
            DcoumentPopdata = { action: actionType };
        }
        let dialogRef: any;
        if (actionType == "rename") {
            dialogRef = this.dialog.open(MatterFolderDailogComponent, {
                width: "100%",
                data: { docData: this.DocRegData, type: "rename" },
            });
        } else {
            dialogRef = this.dialog.open(DocumentDailogComponent, {
                disableClose: true,
                panelClass: "Document-dialog",
                data: DcoumentPopdata,
            });
        }
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");
            if (result) $("#refreshDOCREGTab").click();
        });
    }
    ///Spend Money

    // spendmoneypopup(actionType: any, val: any = '', flag?: any, FLAG?: any , matterIs ?: any) {
    //     this.toolbarServiceService.SetOldistrackid('open');
    //     const dialogRef = this.dialog.open(SpendMoneyAddComponent, {
    //         disableClose: true,
    //         panelClass: 'SpendMoney-dialog',
    //         data: { action: actionType, FromWhere: val, flag: flag, FLAG: FLAG , matterIs : (matterIs)?matterIs:null }
    //     });
    //     dialogRef.afterClosed().subscribe(result => {
    //         this.toolbarServiceService.SetOldistrackid('close');
    //         if (result) {
    //             $("#refreshSpendMoneyTab").click();
    //             $('#refreshRecouncilItem').click();
    //             $('#refreshWorkInprogress').click();
    //             $('#refreshGeneral').click();
    //         }
    //     });
    // }

    /**
     * This function is used to delete the document 
     */
    DeleteDocument(): void {
        this.confirmDialogRef2 = this.MatDialog.open(ConfirmPopupComponent, {
            disableClose: true,
            width: "100%",
            data: "",
        });
        this.confirmDialogRef2.componentInstance.confirmMessage =
            "Do you want to delete this file from your " +
            this.currentUser.DOCUMENTSYSTEM +
            " also?";
        this.confirmDialogRef2.componentInstance.confirmTitle = "Confirm";
        this.confirmDialogRef2.componentInstance.confirm1 = "Yes";
        this.confirmDialogRef2.componentInstance.confirm2 = "No";
        this.confirmDialogRef2.componentInstance.confirm3 = "Cancel";
        this.confirmDialogRef2.afterClosed().subscribe((result) => {
            // if (result) {

            //     let postData = { FormAction: "delete", data: { DOCUMENTGUID: this.DocRegData.DOCUMENTGUID } }
            //     let RefreshIdArray: any = ['#refreshDOCREGTab'];
            //     this.toolbarServiceService.SetVariableForRetry(postData, 'SetDocument', RefreshIdArray);
            //     this._mainAPiServiceService.getSetData(postData, 'SetDocument').subscribe(res => {
            //         if (res.STATUS == "success" && res.CODE == 200) {
            //             localStorage.setItem('istrackid', this.oldIsTrackId);
            //             $('#refreshDOCREGTab').click();
            //             this.toastr.success('Deleted successfully');
            //         } else if (res.CODE == 451 && res.STATUS == 'warning') {
            //             this.checkValidation(res.DATA.VALIDATIONS, postData, 'SetDocument');
            //           } else if (res.CODE == 450 && res.STATUS == 'error') {
            //             this.checkValidation(res.DATA.VALIDATIONS, postData, 'SetDocument');
            //           }else if (res.MESSAGE == 'Not logged in') {
            //             this.dialogRef.close(false);
            //         }
            //     });
            // }
            if (result && result !== 2) {
                let postData = {
                    FormAction: "delete",
                    DELETENETDRIVEFILE: true,
                    data: { DOCUMENTGUID: this.DocRegData.DOCUMENTGUID },
                };
                let RefreshIdArray: any = ["#refreshDOCREGTab"];
                this.toolbarServiceService.SetVariableForRetry(
                    postData,
                    "SetDocument",
                    RefreshIdArray
                );
                this._mainAPiServiceService
                    .getSetData(postData, "SetDocument")
                    .subscribe((res) => {
                        if (res.STATUS == "success" && res.CODE == 200) {
                            localStorage.setItem(
                                "istrackid",
                                this.oldIsTrackId
                            );
                            $("#refreshDOCREGTab").click();
                            this.toastr.success("Deleted successfully");
                        }
                    });
            } else if (result == false) {
                let postData = {
                    FormAction: "delete",
                    data: { DOCUMENTGUID: this.DocRegData.DOCUMENTGUID },
                };
                let RefreshIdArray: any = ["#refreshDOCREGTab"];
                this.toolbarServiceService.SetVariableForRetry(
                    postData,
                    "SetDocument",
                    RefreshIdArray
                );
                this._mainAPiServiceService
                    .getSetData(postData, "SetDocument")
                    .subscribe((res) => {
                        if (res.STATUS == "success" && res.CODE == 200) {
                            localStorage.setItem(
                                "istrackid",
                                this.oldIsTrackId
                            );
                            $("#refreshDOCREGTab").click();
                            this.toastr.success("Deleted successfully");
                        }
                    });
            }
        });
    }

    /**
     * This function is used to open the matter data
     * @param Type -type value data
     * @param page -page number data
     * @returns data value
     */
    OpenMatter(Type: any, page: any) {
        // this.selectedMatterTab = JSON.parse(
        //     localStorage.getItem(
        //         this.windowNameId ? this.windowNameId : "set_active_matters"
        //     )
        // );
        this.ApiRepoParm1 = Type;
        this.ApiRepoParm2 = page;
        this.isGenrateReport = true;
        let sendData: any = {};
        // let APIURL = "NetDriveGetDocument";
        let APIURL = 'cloud-storage';
        if (Type == "DOCUMENT") {
            if (page == "chronology") {
                sendData = {
                    FileType: Type,
                    MATTERGUID: this.ChronologyLegalData.MATTERGUID,
                    DocumentName: this.ChronologyLegalData.DOCUMENTNAME,
                };
            } else {
                sendData = {
                    FileType: Type,
                    MATTERGUID: this.DocRegData.MATTERGUID,
                    DocumentName: this.DocRegData.DOCUMENTNAME,
                };
            }
        } else if (Type == "DOCUMENTSAFE" && page == "safecustody") {
            sendData = {
                FileType: Type,
                MATTERGUID: this.SafeCustodyData.MATTERGUID,
                DocumentName: this.SafeCustodyData.DOCUMENTNAME,
            };
        } else if (Type == "MATTERFOLDER" && page == "matter_document") {
            if (
                this.DocumentRegisterSelectedFolder &&
                this.DocumentRegisterSelectedFolder.length > 0
            ) {
                let lasFolderData =
                    this.DocumentRegisterSelectedFolder[
                    this.DocumentRegisterSelectedFolder.length - 1
                    ];
                this.isGenrateReport = false;
                window.open(lasFolderData.WEBURL, "_blank");
                return false;
            } else {
                if (page == "matter_document") {
                    sendData = {
                        FileType: Type,
                        MATTERGUID: this.selectedMatterTab.MATTERGUID,
                    };
                } else {
                    sendData = {
                        FileType: Type,
                        MATTERGUID: this.DocRegData.MATTERGUID,
                    };
                }
            }
        } else if (Type == "MATTERFOLDER") {
            if (page == "matter_page") {
                sendData = {
                    FileType: Type,
                    MATTERGUID: this.selectedMatterTab.MATTERGUID,
                };
            } else {
                sendData = {
                    FileType: Type,
                    MATTERGUID: this.DocRegData.MATTERGUID,
                };
            }
        } else if (Type == "LOADDOCUMENTS") {
            this.isGenrateReport = false;
            sendData = { MATTERGUID: this.selectedMatterTab.MATTERGUID };
            this.dialog.open(MatterFolderDailogComponent, {
                width: "100%",
                data: { list: "", postData: sendData },
            });
        }
        if (Type != "LOADDOCUMENTS") {
            this._mainAPiServiceService
                .getSetData({ Action:'GetDocument', Data: sendData }, APIURL)
                .subscribe(
                    (DriveGetDocument) => {
                        if (
                            DriveGetDocument.CODE == 200 &&
                            DriveGetDocument.STATUS == "success"
                        ) {
                            this.toastr.success(
                                "Document Generate successfully"
                            );
                            this.isGenrateReport = false;
                            window.open(
                                DriveGetDocument.DATA.DOCUMENTS[0]
                                    .DRIVEFILEWEBURL,
                                "_blank"
                            );
                        } else if (DriveGetDocument.CODE == 432) {
                            let currentUser: any = JSON.parse(
                                localStorage.getItem("currentUser")
                            );
                            this.isGenrateReport = false;
                            // window.open(
                            //     environment.APIEndpoint +
                            //     "OAuth2Start?AuthType=NetDrive&SessionToken=" +
                            //     currentUser.SessionToken,
                            //     "targetWindow",
                            //     `toolbar=no,
                            //     location=no,
                            //     status=no,
                            //     menubar=no,
                            //     scrollbars=yes,
                            //     resizable=yes,
                            //     width=800,
                            //     height=600`
                            // );

                            let payload = {
                                "Action": "OAuth2Start",
                                "VALIDATEONLY": false,
                                "Data": {
                                    "USERGUID": this.currentUser.UserGuid,
                                    "AuthType": "NetDrive"
                                }
                            }
                            this._mainAPiServiceService
                            .getSetData(payload, 'oauth-connections')
                            .subscribe((response) => {
                                if(response.CODE == 200 && response.STATUS == "success") {
                                    window.open(
                                        response.DATA[0].AuthorizationUrl,
                                        "targetWindow",
                                        `toolbar=no,
                                        location=no,
                                        status=no,
                                        menubar=no,
                                        scrollbars=yes,
                                        resizable=yes,
                                        width=800,
                                        height=600`
                                    );
                                }
                            });
                        } else {
                            this.isGenrateReport = false;
                        }
                    },
                    (error) => {
                        this.isGenrateReport = false;
                        this.toastr.error(error);
                    }
                );
        }
    }

    /**
     * This function is used to get the pdfDocument data.
     */
    PdfDocument() {
        const dialogRef = this._matDialog.open(GenerateDocumentPopupComponent, {
            width: "100%",
            disableClose: true,
            data: {
                doc: this.DocRegData,
                convertPdf: true,
                sendEmail: false,
                knownby: "Template",
                isRegeisterDocPDF: true,
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
            }
        });
    }

    /**
     * This function is used to pdfEmailDocument
     */
    PdfEmailDocument() {
        const dialogRef = this._matDialog.open(GenerateDocumentPopupComponent, {
            width: "100%",
            disableClose: true,
            data: {
                doc: this.DocRegData,
                convertPdf: true,
                sendEmail: true,
                knownby: "Template",
                isRegeisterDocPDFEmail: true,
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
            }
        });
    }

    /**
     * This function is used to Generate Email data
     * @param val -val of email
     */
    GenarateEmail(val: any = "") {
        this.behaviorService.EmailGenerateData$.subscribe((result) => {
            if (result) {
                this.EmailtemplateData = result;
            }
        });
        if (
            this.router.url == "/create-document/email-invoice-template" ||
            this.router.url == "/create-document/packs-invoice-template"
        ) {
            let invoiceGUid = localStorage.getItem("edit_invoice_id");
            let passdata = {
                Context: "Invoice",
                ContextGuid: invoiceGUid,
                knownby: "Email",
                Type: "Email",
                Folder: "",
                Template: this.EmailtemplateData.NAME,
            };
            this.ForEmailDialogOpen(passdata);
        } else if (
            this.router.url == "/create-document/email-matter-template" ||
            this.router.url == "/create-document/packs-matter-template" ||
            val == "inboxWithTemplate"
        ) {
            let matterData = JSON.parse(
                localStorage.getItem(
                    this.windowNameId ? this.windowNameId : "set_active_matters"
                )
            );
            let passdata = {
                Context: "Matter",
                ContextGuid: this.selectedMatterRow
                    ? this.selectedMatterRow.MATTERGUID
                    : matterData.MATTERGUID,
                knownby: "Email",
                Type: "Email",
                Folder: "",
                Template: this.EmailtemplateData.NAME,
                ExtraData: "inboxWithTemplate",
            };
            this.ForEmailDialogOpen(passdata);
        } else if (
            this.router.url ==
            "/create-document/email-receive-money-template" ||
            this.router.url == "/create-document/packs-receive-money-template"
        ) {
            let ReceiptData = JSON.parse(localStorage.getItem("receiptData"));
            let passdata = {
                Context: "Income",
                ContextGuid: ReceiptData.INCOMEGUID,
                knownby: "Email",
                Type: "Email",
                Folder: "",
                Template: this.EmailtemplateData.NAME,
            };
            this.ForEmailDialogOpen(passdata);
        } else if (
            this.router.url == "/create-document/email-contact-template" ||
            this.router.url == "/create-document/packs-contact-template"
        ) {
            let ContactGuID = localStorage.getItem("contactGuid");
            let passdata = {
                Context: "Contact",
                ContextGuid: ContactGuID,
                knownby: "Email",
                Type: "Email",
                Folder: "",
                Template: this.EmailtemplateData.NAME,
            };
            this.ForEmailDialogOpen(passdata);
        } else if (
            this.router.url == "/create-document/email-safe-custody-template" ||
            this.router.url == "/create-document/packs-safe-custody-template"
        ) {
            let passdata = {
                Context: "Safe Custody",
                ContextGuid: this.SafeCustodyData.SAFECUSTODYGUID,
                knownby: "Email",
                Type: "Email",
                Folder: "",
                Template: this.TemplateGenerateData.TEMPLATENAME,
            };
            this.ForEmailDialogOpen(passdata);
        } else if (
            this.router.url ==
            "/create-document/email-document-register-template" ||
            this.router.url ==
            "/create-document/packs-document-register-template"
        ) {
            let matterData = JSON.parse(
                localStorage.getItem(
                    this.windowNameId ? this.windowNameId : "set_active_matters"
                )
            );
            //old
            // let passdata = { 'Context': "Document Register", 'ContextGuid': this.DocRegData.DOCUMENTGUID, "knownby": "Email", "Type": "Email", "Folder": '', "Template": this.EmailtemplateData.NAME, "DocumentData": this.DocRegData.DOCUMENTGUID, "DocumentName": this.DocRegData.DOCUMENTNAME }

            let passdata = {
                Context: "Matter",
                ContextGuid: matterData.MATTERGUID,
                knownby: "Email",
                Type: "Email",
                Folder: "",
                Template: this.EmailtemplateData.NAME,
                DocumentData: this.DocRegData.DOCUMENTGUID,
                DocumentName: this.DocRegData.DOCUMENTNAME,
            };
            this.ForEmailDialogOpen(passdata);
        } else if (
            this.router.url ==
            "/create-document/email-invoice-multiple-document" ||
            this.router.url ==
            "/create-document/packs-invoice-multiple-document"
        ) {
            let invoiceGUid = localStorage.getItem("edit_invoice_id");
            let passdata = {
                Context: "Invoice",
                ContextGuid: "",
                knownby: "Email",
                Type: "Email",
                Folder: "",
                Template: this.EmailtemplateData.NAME,
                isMultipleDocument: true,
            };
            this.ForEmailDialogOpen(passdata);
        }
    }

    /**
     * This function is used Generate packs
     */
    GenaratePacks() {
        if (this.router.url == "/create-document/packs-invoice-template") {
            let invoiceGUid = localStorage.getItem("edit_invoice_id");
            let passdata = {
                Context: "Invoice",
                ContextGuid: invoiceGUid,
                knownby: "Pack",
                Type: "Pack",
                Folder: "",
                Template: this.KitName,
            };
            this.ForEmailDialogOpen(passdata);
        } else if (
            this.router.url == "/create-document/packs-matter-template"
        ) {
            let matterData = JSON.parse(
                localStorage.getItem(
                    this.windowNameId ? this.windowNameId : "set_active_matters"
                )
            );
            let passdata = {
                Context: "Matter",
                ContextGuid: matterData.MATTERGUID,
                knownby: "Pack",
                Type: "Pack",
                Folder: "",
                Template: this.KitName,
            };
            this.ForEmailDialogOpen(passdata);
        } else if (
            this.router.url == "/create-document/packs-receive-money-template"
        ) {
            let ReceiptData = JSON.parse(localStorage.getItem("receiptData"));
            let passdata = {
                Context: "Income",
                ContextGuid: ReceiptData.INCOMEGUID,
                knownby: "Pack",
                Type: "Pack",
                Folder: "",
                Template: this.KitName,
            };
            this.ForEmailDialogOpen(passdata);
        } else if (
            this.router.url == "/create-document/packs-contact-template"
        ) {
            let ContactGuID = localStorage.getItem("contactGuid");
            let passdata = {
                Context: "Contact",
                ContextGuid: ContactGuID,
                knownby: "Pack",
                Type: "Pack",
                Folder: "",
                Template: this.KitName,
            };
            this.ForEmailDialogOpen(passdata);
        } else if (
            this.router.url == "/create-document/packs-safe-custody-template"
        ) {
            let passdata = {
                Context: "Safe Custody",
                ContextGuid: this.SafeCustodyData.SAFECUSTODYGUID,
                knownby: "Pack",
                Type: "Pack",
                Folder: "",
                Template: this.TemplateGenerateData.TEMPLATENAME,
            };
            this.ForEmailDialogOpen(passdata);
        } else if (
            this.router.url ==
            "/create-document/packs-document-register-template"
        ) {
            let passdata = {
                Context: "Document Register",
                ContextGuid: this.DocRegData.DOCUMENTGUID,
                knownby: "Pack",
                Type: "Pack",
                Folder: "",
                Template: this.KitName,
            };
            this.ForEmailDialogOpen(passdata);
        }
    }

    /**
     * This function is used to Generate the Court Form data
     */
    GenarateCourtForm() {
        if (
            this.router.url ==
            "/create-document/court-forms-invoice-template" ||
            this.router.url ==
            "/create-document/court-invoice-multiple-document"
        ) {
            let invoiceGUid = localStorage.getItem("edit_invoice_id");
            let passdata = {
                FORMID: this.courtFormData.FORMID,
                nameShow: this.courtFormData.DESCRIPTION,
                Context: "Invoice",
                ContextGuid: invoiceGUid,
                knownby: "Template",
                Type: "Court Form",
            };
            this.toolbarServiceService.ForDocDialogOpen(passdata);
        } else if (
            this.router.url == "/create-document/court-forms-matter-template"
        ) {
            let matterData = JSON.parse(
                localStorage.getItem(
                    this.windowNameId ? this.windowNameId : "set_active_matters"
                )
            );
            let passdata = {
                FORMID: this.courtFormData.FORMID,
                nameShow: this.courtFormData.DESCRIPTION,
                Context: "Matter",
                ContextGuid: matterData.MATTERGUID,
                knownby: "Template",
                Type: "Court Form",
            };
            this.toolbarServiceService.ForDocDialogOpen(passdata);
        } else if (
            this.router.url ==
            "/create-document/court-forms-receive-money-template"
        ) {
            let ReceiptData = JSON.parse(localStorage.getItem("receiptData"));
            let passdata = {
                FORMID: this.courtFormData.FORMID,
                nameShow: this.courtFormData.DESCRIPTION,
                Context: "Income",
                ContextGuid: ReceiptData.INCOMEGUID,
                knownby: "Template",
                Type: "Court Form",
            };
            this.toolbarServiceService.ForDocDialogOpen(passdata);
        } else if (
            this.router.url == "/create-document/court-forms-contact-template"
        ) {
            let ContactGuID = localStorage.getItem("contactGuid");
            let passdata = {
                FORMID: this.courtFormData.FORMID,
                nameShow: this.courtFormData.DESCRIPTION,
                Context: "Contact",
                ContextGuid: ContactGuID,
                knownby: "Template",
                Type: "Court Form",
            };
            this.toolbarServiceService.ForDocDialogOpen(passdata);
        } else if (
            this.router.url ==
            "/create-document/court-forms-safe-custody-template"
        ) {
            let passdata = {
                FORMID: this.courtFormData.FORMID,
                nameShow: this.courtFormData.DESCRIPTION,
                Context: "Safe Custody",
                ContextGuid: this.SafeCustodyData.SAFECUSTODYGUID,
                knownby: "Template",
                Type: "Court Form",
            };
            this.toolbarServiceService.ForDocDialogOpen(passdata);
        } else if (
            this.router.url ==
            "/create-document/court-forms-document-register-template"
        ) {
            let passdata = {
                FORMID: this.courtFormData.FORMID,
                nameShow: this.courtFormData.DESCRIPTION,
                Context: "Document Register",
                ContextGuid: this.DocRegData.DOCUMENTGUID,
                knownby: "Template",
                Type: "Court Form",
            };
            this.toolbarServiceService.ForDocDialogOpen(passdata);
        }
    }

    /**
     * This function is used to Generate the Precedents data
     */
    GenaratePrecedents() {
        if (this.router.url == "/create-document/precedents-invoice-template") {
            let invoiceGUid = localStorage.getItem("edit_invoice_id");
            let passdata = {
                FORMID: this.courtFormData.FORMID,
                nameShow: this.courtFormData.DESCRIPTION,
                Context: "Invoice",
                ContextGuid: invoiceGUid,
                knownby: "Template",
                Type: "PRECEDENT FORM",
            };
            this.toolbarServiceService.ForDocDialogOpen(passdata);
        } else if (
            this.router.url == "/create-document/precedents-matter-template"
        ) {
            let matterData = JSON.parse(
                localStorage.getItem(
                    this.windowNameId ? this.windowNameId : "set_active_matters"
                )
            );
            let passdata = {
                FORMID: this.courtFormData.FORMID,
                nameShow: this.courtFormData.DESCRIPTION,
                Context: "Matter",
                ContextGuid: matterData.MATTERGUID,
                knownby: "Template",
                Type: "PRECEDENT FORM",
            };
            this.toolbarServiceService.ForDocDialogOpen(passdata);
        } else if (
            this.router.url ==
            "/create-document/precedents-receive-money-template"
        ) {
            let ReceiptData = JSON.parse(localStorage.getItem("receiptData"));
            let passdata = {
                FORMID: this.courtFormData.FORMID,
                nameShow: this.courtFormData.DESCRIPTION,
                Context: "Income",
                ContextGuid: ReceiptData.INCOMEGUID,
                knownby: "Template",
                Type: "PRECEDENT FORM",
            };
            this.toolbarServiceService.ForDocDialogOpen(passdata);
        } else if (
            this.router.url == "/create-document/precedents-contact-template"
        ) {
            let ContactGuID = localStorage.getItem("contactGuid");
            let passdata = {
                FORMID: this.courtFormData.FORMID,
                nameShow: this.courtFormData.DESCRIPTION,
                Context: "Contact",
                ContextGuid: ContactGuID,
                knownby: "Template",
                Type: "PRECEDENT FORM",
            };
            this.toolbarServiceService.ForDocDialogOpen(passdata);
        } else if (
            this.router.url ==
            "/create-document/precedents-safe-custody-template"
        ) {
            let passdata = {
                FORMID: this.courtFormData.FORMID,
                nameShow: this.courtFormData.DESCRIPTION,
                Context: "Safe Custody",
                ContextGuid: this.SafeCustodyData.SAFECUSTODYGUID,
                knownby: "Template",
                Type: "PRECEDENT FORM",
            };
            this.toolbarServiceService.ForDocDialogOpen(passdata);
        } else if (
            this.router.url ==
            "/create-document/precedents-document-register-template"
        ) {
            let passdata = {
                FORMID: this.courtFormData.FORMID,
                nameShow: this.courtFormData.DESCRIPTION,
                Context: "Document Register",
                ContextGuid: this.DocRegData.DOCUMENTGUID,
                knownby: "Template",
                Type: "PRECEDENT FORM",
            };
            this.toolbarServiceService.ForDocDialogOpen(passdata);
        }
    }

    /**
     * This function is used to open the Email Dialog popup
     * @param passdata -pass data value
     */
    ForEmailDialogOpen(passdata) {
        if (passdata.knownby == "Pack") {
            const dialogRef = this._matDialog.open(
                GeneratePacksPopupComponent,
                {
                    width: "100%",
                    disableClose: true,
                    data: passdata,
                }
            );
            dialogRef.afterClosed().subscribe((result) => { });
        } else {
            const dialogRef = this._matDialog.open(
                MatterDialogComponentForTemplate,
                {
                    width: "100%",
                    disableClose: true,
                    data: passdata,
                }
            );
            dialogRef.afterClosed().subscribe((result) => { });
        }
    }

    /**
     * This function is used to open the email template popup
     * @param actionType -actionType data
     */
    EmailTempletePopUp(actionType) {
        this.toolbarServiceService.SetOldistrackid("open");
        let EmailPopdata = {};
        if (actionType == "new") {
            EmailPopdata = { action: actionType };
        } else if (actionType == "edit" || actionType == "copy") {
            EmailPopdata = { action: actionType };
        }
        const dialogRef = this.dialog.open(EmailDailogComponent, {
            disableClose: true,
            panelClass: "Email-dialog",
            data: EmailPopdata,
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");
            if (result) {
                $("#refreshEmailTab").click();
            }
        });
    }

    /**
     * This function is used to delete the the Email template
     */
    DeleteEmailTemplete() {
        this.behaviorService.EmailGenerateData$.subscribe((result) => {
            if (result) {
                this.EmailtemplateData = result;
            }
        });
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",
            }
        );
        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to delete?";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let postData = {
                    FormAction: "delete",
                    data: { EMAILGUID: this.EmailtemplateData.EMAILGUID },
                };
                let RefreshIdArray: any = ["#refreshEmailTab"];
                this.toolbarServiceService.SetVariableForRetry(
                    postData,
                    "template-email",
                    RefreshIdArray
                );
                this._mainAPiServiceService
                    .getSetData(postData, "template-email")
                    .subscribe((res) => {
                        if (res.STATUS == "success" && res.CODE == 200) {
                            localStorage.setItem(
                                "istrackid",
                                this.oldIsTrackId
                            );
                            $("#refreshEmailTab").click();
                            this.toastr.success("Deleted successfully");
                        } else if (res.CODE == 451 && res.STATUS == "warning") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "template-email"
                            );
                        } else if (res.CODE == 450 && res.STATUS == "error") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "template-email"
                            );
                        } else if (res.MESSAGE == "Not logged in") {
                            this.dialogRef.close(false);
                        }
                    });
            }
            this.confirmDialogRef = null;
        });
    }

    /**
     * This function is used to get the pack module data 
     * @param actionType -action type data
     */
    PackModule(actionType) {
        this.toolbarServiceService.SetOldistrackid("open");
        let PackPopdata = {};
        if (actionType == "new") {
            PackPopdata = { action: actionType };
        } else {
            PackPopdata = { action: actionType };
        }
        const dialogRef = this.dialog.open(PacksDailogComponent, {
            disableClose: true,
            panelClass: "Pack-dialog",
            data: PackPopdata,
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");
            if (result) {
                $("#refreshKitTab").click();
            }
        });
    }

    /**
     * This function is used to Delete the Pack
     */
    DeletePack(): void {
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",
            }
        );
        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to delete?";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let postData = {
                    ACTION: "delete",
                    data: { KITGUID: this.KitGUid },
                };
                let RefreshIdArray: any = ["#refreshKitTab"];
                this.toolbarServiceService.SetVariableForRetry(
                    postData,
                    "document-pack",
                    RefreshIdArray
                );
                this._mainAPiServiceService
                    .getSetData(postData, "document-pack")
                    .subscribe((res) => {
                        if (res.STATUS == "success" && res.CODE == 200) {
                            localStorage.setItem(
                                "istrackid",
                                this.oldIsTrackId
                            );
                            $("#refreshKitTab").click();
                            this.toastr.success("Deleted successfully");
                        } else if (res.CODE == 451 && res.STATUS == "warning") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "document-pack"
                            );
                        } else if (res.CODE == 450 && res.STATUS == "error") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "document-pack"
                            );
                        } else if (res.MESSAGE == "Not logged in") {
                            this.dialogRef.close(false);
                        }
                    });
            }
        });
    }

    /**
     * This function is used to conflict start
     */
    ConflictStart() {
        $("#refreshConflict").click();
    }
    
    /**
     * This function is used to Chart Account open data
     * @param val -val data
     */
    ChartAccount(val) {
        if (val == "WithoutTrust") {
            this.ChartHandlingData = {
                ClickType: val,
                UseTrust: false,
                PopUp: "",
                Lable: "CHART OF ACCOUNTS",
            };
            localStorage.setItem(
                "ChartURL",
                JSON.stringify(this.ChartHandlingData)
            );
        } else if (val == "WithTrust") {
            this.ChartHandlingData = {
                ClickType: val,
                UseTrust: true,
                PopUp: "",
                Lable: "TRUST CHART OF ACCOUNTS",
            };
            localStorage.setItem(
                "ChartURL",
                JSON.stringify(this.ChartHandlingData)
            );
        }
        this.behaviorService.TrustDuplicateModuleHandling(
            this.ChartHandlingData
        );
    }

    
    /**
     * This function is used to open the GeneralJournal 
     * @param val -val data
     */
    GeneralJournal(val) {
        if (val == "WithoutTrust") {
            this.ChartHandlingData = {
                ClickType: val,
                UseTrust: "No",
                PopUp: "",
                Lable: "GENERAL JOURNAL",
            };
            localStorage.setItem(
                "ChartURL",
                JSON.stringify(this.ChartHandlingData)
            );
        } else if (val == "WithTrust") {
            this.ChartHandlingData = {
                ClickType: val,
                UseTrust: "Yes",
                PopUp: "",
                Lable: "TRUST GENERAL JOURNAL",
            };
            localStorage.setItem(
                "ChartURL",
                JSON.stringify(this.ChartHandlingData)
            );
        }
        this.behaviorService.TrustDuplicateModuleHandling(
            this.ChartHandlingData
        );
    }

    /**
     * This function is used to select the matter data.
     */
    SelectMatter() {
        const dialogRef = this.MatDialog.open(MatterDialogComponent, {
            width: "100%",
            disableClose: true,
            data: {},
        });
        dialogRef.afterClosed().subscribe((result) => {
            localStorage.setItem("set_active_matters", JSON.stringify(result));
        });
    }

    /**
     * This function is used to open the Account popup data value
     * @param actionType -Action type data
     */
    AccountPop(actionType) {
        this.toolbarServiceService.SetOldistrackid("open");
        if (this.router.url == "/trust-chart-account" && actionType == "new") {
            const dialogRef = this._matDialog.open(MatterDialogComponent, {
                width: "100%",
                disableClose: true,
                data: null,
            });
            dialogRef.afterClosed().subscribe((result) => {
                this.toolbarServiceService.SetOldistrackid("close");
                if (result) {
                    localStorage.setItem(
                        "set_active_matters",
                        JSON.stringify(result)
                    );
                    this.CommonAccountPopUp(actionType);
                }
            });
        } else {
            this.CommonAccountPopUp(actionType);
        }
    }

    BankFeedInstruct(action){
        const dialogRef = this.dialog.open(BankFeedInstructionsComponent, {
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe((result) => {
            if(result){
                this.BankPop(action);
            }
            // this.toolbarServiceService.SetOldistrackid("close");
        });
    }

    /**
     * This function is used to open the bank popup 
     */
    BankPop(Action) {
        // let AccountPopdata = {};
        // AccountPopdata = { action: "Connect Bank Feed" };
        // const dialogRef = this.dialog.open(ConnectBankFeedComponent, {
        //     disableClose: true,
        //     panelClass: "ChartAc-dialog",
        //     data: AccountPopdata,
        // });
        // dialogRef.afterClosed().subscribe((result) => {
        //     this.toolbarServiceService.SetOldistrackid("close");
        // });
        // this.isLoadingResults = true;
        let postData = { Action: Action, Data: {} };
        this.sub2 = this._mainAPiServiceService
            .getSetData(postData, "bank-feed")
            .subscribe((res) => {
                if (res.STATUS == "success" && res.CODE == 200) {
                    // this.isLoadingResults = false;
                    this.AUTHORISEIFRAMEURL = res.DATA.AUTHORISEIFRAMEURL;
                    // this.AUTHORISEURL = res.DATA.AUTHORISEURL;
                    // this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
                    //     this.AUTHORISEIFRAMEURL
                    // );
                    // window.open(this.AUTHORISEIFRAMEURL, '_blank');
                    var win = window.open(this.AUTHORISEIFRAMEURL, 'targetWindow',
                        `toolbar=no,
                        location=no,
                        status=no,
                        menubar=no,
                        scrollbars=yes,
                        resizable=yes,
                        width=800,
                        height=600`);
                    var timer = setInterval(() => {
                        if (win.closed) {
                            clearInterval(timer);
                            $("#authorisedAccouts").click();
                        }
                    }, 1000);
                } else {
                    // this.isLoadingResults = false;
                }
            });
    }

    /**
     * this function is used to action type get data 
     * @param actionType -action type data
     */
    CommonAccountPopUp(actionType) {
        this.toolbarServiceService.SetOldistrackid("open");
        let AccountPopdata = {};
        if (actionType == "new") {
            AccountPopdata = { action: actionType };
        } else if (actionType == "edit" || actionType == "duplicate") {
            AccountPopdata = { action: actionType };
        }
        const dialogRef = this.dialog.open(ChartAcDailogComponent, {
            disableClose: true,
            panelClass: "ChartAc-dialog",
            data: AccountPopdata,
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");
        });
    }
    
    /**
     * This function is used to Delete Account data
     */
    DeleteAccount(): void {
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",
            }
        );
        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to delete?";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let postData = {
                    FormAction: "delete",
                    DATA: { ACCOUNTGUID: this.AccountGUID },
                };
                let RefreshIdArray: any = ["#refreshChartACCTab"];
                this.toolbarServiceService.SetVariableForRetry(
                    postData,
                    "SetAccount",
                    RefreshIdArray
                );
                this._mainAPiServiceService
                    .getSetData(postData, "SetAccount")
                    .subscribe((res) => {
                        if (res.STATUS == "success" && res.CODE == 200) {
                            localStorage.setItem(
                                "istrackid",
                                this.oldIsTrackId
                            );
                            $("#refreshChartACCTab").click();
                            this.toastr.success("Deleted successfully");
                        } else if (res.CODE == 451 && res.STATUS == "warning") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "SetAccount"
                            );
                        } else if (res.CODE == 450 && res.STATUS == "error") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "SetAccount"
                            );
                        } else if (res.MESSAGE == "Not logged in") {
                            this.dialogRef.close(false);
                        }
                    });
            }
        });
    }
  
    /**
     * This function is used to open the authority dialog
     * @param val - value data
     */
    AuthorityDialog(val) {
        this.toolbarServiceService.SetOldistrackid("open");
        const dialogRef = this.dialog.open(AuthorityDialogComponent, {
            disableClose: true,
            panelClass: "ChartAc-dialog",
            data: {
                action: val,
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");
            if (result) {
                $("#refresMainAuthority").click();
            }
        });
    }

    /**
     * This function is used to topic dialog open 
     * @param val -val of the topic data
     */
    TopicDialog(val) {
        this.toolbarServiceService.SetOldistrackid("open");
        const dialogRef = this.dialog.open(TopicDialogComponent, {
            disableClose: true,
            panelClass: "ChartAc-dialog",
            data: { action: val },
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");
            if (result) {
                $("#refresMainTopic").click();
            }
        });
    }

    /***
     * This function is used to open the safecustody popup data value
     */
    SafeCustodyPopup(val) {
        const dialogRef = this.dialog.open(AuthorityDialogComponent, {
            disableClose: true,
            panelClass: "ChartAc-dialog",
            data: {
                action: val,
            },
        });
        dialogRef.afterClosed().subscribe((result) => { });
    }

    /**
     * This function is used to delete the authority data value
     */
    deleteAuthority(): void {
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",
            }
        );
        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to delete?";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let postData = {
                    Action: "delete",
                    data: {
                        AUTHORITYGUID: this.MainAuthorityData.AUTHORITYGUID,
                    },
                };
                let RefreshIdArray: any = ["#refresMainAuthority"];
                this.toolbarServiceService.SetVariableForRetry(
                    postData,
                    "authority",
                    RefreshIdArray
                );
                this._mainAPiServiceService
                    .getSetData(postData, "authority")
                    .subscribe((res) => {
                        if (res.STATUS == "success") {
                            localStorage.setItem(
                                "istrackid",
                                this.oldIsTrackId
                            );
                            $("#refresMainAuthority").click();
                            this.toastr.success(res.STATUS);
                        } else if (res.CODE == 451 && res.STATUS == "warning") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "authority"
                            );
                        } else if (res.CODE == 450 && res.STATUS == "error") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "authority"
                            );
                        } else if (res.MESSAGE == "Not logged in") {
                            this.dialogRef.close(false);
                        } else {
                            this.toastr.error(
                                "You Can't Delete Contact Which One Is To Related to Matters"
                            );
                        }
                    });
            }
            this.confirmDialogRef = null;
        });
    }

    /**
     * This function is used to Delete Topic data
     */
    DeleteTopicData(): void {
        this.behaviorService.MainTopicData$.subscribe((result) => {
            if (result) {
                this.TopicMaindata = result;
            }
        });
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",
            }
        );
        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to delete?";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let postData = {
                    Action: "delete",
                    data: { TOPICGUID: this.TopicMaindata },
                };
                let RefreshIdArray: any = ["#refresMainTopic"];
                this.toolbarServiceService.SetVariableForRetry(
                    postData,
                    "topic",
                    RefreshIdArray
                );
                this._mainAPiServiceService
                    .getSetData(postData, "topic")
                    .subscribe((res) => {
                        if (res.STATUS == "success") {
                            localStorage.setItem(
                                "istrackid",
                                this.oldIsTrackId
                            );
                            $("#refresMainTopic").click();
                            this.toastr.success(res.STATUS);
                        } else if (res.CODE == 451 && res.STATUS == "warning") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "topic"
                            );
                        } else if (res.CODE == 450 && res.STATUS == "error") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "topic"
                            );
                        } else if (res.MESSAGE == "Not logged in") {
                            this.dialogRef.close(false);
                        } else {
                            this.toastr.error(
                                "You Can't Delete Contact Which One Is To Related to Matters"
                            );
                        }
                    });
            }
            this.confirmDialogRef = null;
        });
    }

   /**
    * Thisd function is used to open the NewGeneral component data
    * @param actionType -action type data value
    */
    NewGeneral(actionType) {
        this.toolbarServiceService.SetOldistrackid("open");
        const dialogRef = this.dialog.open(GeneralDailogComponent, {
            disableClose: true,
            panelClass: "Chrone-dialog",
            data: { action: actionType },
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("open");
            if (result) {
                $("#refreshGeneral").click();
            }
        });
    }
   
    /**
     * This function is used to View Details data.
     */
    ViewDetails() {
        if (
            this.journalLinktype == "Expenditure" ||
            this.journalLinktype == "Payable" ||
            this.journalLinktype == "Disbursement"
        ) {
            this.behaviorService.SpendMoneyData({
                EXPENDITUREGUID: this.JournalData.LINKGUID,
            });
            this.toolbarServiceService.spendmoneypopup("edit");
        } else if (this.journalLinktype == "Receipt") {
            localStorage.setItem(
                "receiptData",
                JSON.stringify({ INCOMEGUID: this.JournalData.LINKGUID })
            );
            this.ViewReceipt("view");
        } else if (this.journalLinktype == "Invoice") {
            localStorage.setItem("edit_invoice_id", this.JournalData.LINKGUID);
            this.InvoiceDetail("view");
        } else if (this.journalLinktype == "Income") {
            localStorage.setItem(
                "receiptData",
                JSON.stringify({ INCOMEGUID: this.JournalData.LINKGUID })
            );
            this.NewGeneralReceipt("edit");
        } else if (this.journalLinktype == "General Journal") {
            this.NewGeneral("edit");
        }
    }
    
    /**
     * This function is used to delete the General
     */
    DeleteGeneral() {
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",
            }
        );
        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to delete?";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let postData = {
                    FormAction: "delete",
                    data: { JOURNALGUID: this.JournalData.JOURNALGUID },
                };
                let RefreshIdArray: any = ["#refreshGeneral"];
                this.toolbarServiceService.SetVariableForRetry(
                    postData,
                    "SetJournal",
                    RefreshIdArray
                );
                this._mainAPiServiceService
                    .getSetData(postData, "SetJournal")
                    .subscribe((res) => {
                        if (res.STATUS == "success" && res.CODE == 200) {
                            localStorage.setItem(
                                "istrackid",
                                this.oldIsTrackId
                            );
                            $("#refreshGeneral").click();
                            this.toastr.success("Deleted successfully");
                        } else if (res.CODE == 451 && res.STATUS == "warning") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "SetJournal"
                            );
                        } else if (res.CODE == 450 && res.STATUS == "error") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "SetJournal"
                            );
                        } else if (res.MESSAGE == "Not logged in") {
                            this.dialogRef.close(false);
                        }
                    });
            }
        });
    }
    //DuplicateGeneral
    DuplicateGeneral() { }
    
    /**
     * This function is used to open the Report filtercomponet data
     * @param val 
     */
    PrintGj(val) {
        const dialogRef = this.dialog.open(ReportFilterComponent, {});
        dialogRef.afterClosed().subscribe((result) => { });
    }

/**
 * This function is used to open the banking Dialog popup
 * @param type -type of the banking data
 * @param forPoPUpHandel -popup handle data
 * @param ForTrust -fortrust data
 */
    BankingDialogOpen(type: any, forPoPUpHandel: any, ForTrust: any) {
        this.toolbarServiceService.SetOldistrackid("open");
        // for trust handling starting
        if (ForTrust == "WithoutTrust") {
            this.ChartHandlingData = {
                ClickType: ForTrust,
                UseTrust: "No",
                PopUp: "",
                Lable: "CHART OF ACCOUNTS",
            };
            localStorage.setItem(
                "ChartURL",
                JSON.stringify(this.ChartHandlingData)
            );
            this.behaviorService.TrustDuplicateModuleHandling(
                this.ChartHandlingData
            );
        } else if (ForTrust == "WithTrust") {
            this.ChartHandlingData = {
                ClickType: ForTrust,
                UseTrust: "Yes",
                PopUp: "",
                Lable: "TRUST CHART OF ACCOUNTS",
            };
            localStorage.setItem(
                "ChartURL",
                JSON.stringify(this.ChartHandlingData)
            );
            this.behaviorService.TrustDuplicateModuleHandling(
                this.ChartHandlingData
            );
        }
        // for trust handling Ending
        if (forPoPUpHandel) {
            this.PathOfRouter = forPoPUpHandel;
        } else {
            this.PathOfRouter = "";
        }
        const dialogRef = this.dialog.open(BankingDialogComponent, {
            disableClose: true,
            width: "100%",
            data: {
                AccountType: type,
                RoterPath: this.PathOfRouter,
                UseTrust: this.ChartHandlingData.UseTrust,
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.behaviorService.TrustDuplicateModuleHandling(
                    this.ChartHandlingData
                );
                this.chartAccountDetail = result;
            }
            this.toolbarServiceService.SetOldistrackid("close");
        });
    }

    /**
     * This function is used to close year of account data
     * @param type -type 
     */
    CloseYearAccounts(type: any) {
        const pipe = this.tierpermission.transform("Close Year Accounts");
        if (pipe.ALLOWED !== 1) {
            this.goTo(pipe.URL);
        } else {
            this.toolbarServiceService.SetOldistrackid("open");

            const dialogRef = this.dialog.open(
                CloseYearAccountsDialogComponent,
                {
                    disableClose: true,
                    width: "100%",
                    data: {
                        AccountType: type,
                        RoterPath: this.PathOfRouter,
                        UseTrust: this.ChartHandlingData.UseTrust,
                    },
                }
            );
            dialogRef.afterClosed().subscribe((result) => {
                this.toolbarServiceService.SetOldistrackid("close");
            });
        }
    }
    /** Chronology Module's Function's */

/**
 * this function is used to open the chronology popup. 
 */
    ChronPopup(actionType) {
        this.toolbarServiceService.SetOldistrackid("open");
        let ChronePopData = {};
        if (actionType == "new") {
            ChronePopData = { action: actionType };
        } else if (actionType == "edit" || actionType == "duplicate") {
            ChronePopData = { action: actionType };
        }
        const dialogRef = this.dialog.open(ChronItemDailogComponent, {
            disableClose: true,
            panelClass: "Chrone-dialog",
            data: ChronePopData,
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");
            if (result) {
                $("#refreshLegalChronology").click();
            }
        });
    }
   
    /**
     * This function is used to delete the chron
     */
    DeleteChron() {
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",
            }
        );
        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to delete?";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let postData = {
                    Action: "delete",
                    data: {
                        CHRONOLOGYGUID: this.ChronologyLegalData.CHRONOLOGYGUID,
                    },
                };
                let RefreshIdArray: any = ["#refreshLegalChronology"];
                this.toolbarServiceService.SetVariableForRetry(
                    postData,
                    "chronology",
                    RefreshIdArray
                );
                this._mainAPiServiceService
                    .getSetData(postData, "chronology")
                    .subscribe((res) => {
                        if (res.STATUS == "success") {
                            localStorage.setItem(
                                "istrackid",
                                this.oldIsTrackId
                            );
                            $("#refreshLegalChronology").click();
                            this.toastr.success(res.STATUS);
                        } else if (res.CODE == 451 && res.STATUS == "warning") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "chronology"
                            );
                        } else if (res.CODE == 450 && res.STATUS == "error") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "chronology"
                            );
                        } else if (res.MESSAGE == "Not logged in") {
                            this.dialogRef.close(false);
                        } else {
                            this.toastr.error(
                                "You Can't Delete Contact Which One Is To Related to Matters"
                            );
                        }
                    });
            }
            this.confirmDialogRef = null;
        });
    }

    /* settlement Module's Function's - allowance **/
    // New - Edit Dialog
    AllowancePopup(actionType) {
        this.toolbarServiceService.SetOldistrackid("open");
        let AllowancePopData = {};
        if (actionType == "new") {
            AllowancePopData = { action: actionType };
        } else if (actionType == "edit" || actionType == "duplicate") {
            AllowancePopData = { action: actionType };
        }
        const dialogRef = this.dialog.open(AllowancePopupComponent, {
            disableClose: true,
            panelClass: "Allowance-dialog",
            data: AllowancePopData,
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("open");

            if (result) {
                // $('#refreshLegalChronology').click();
            }
        });
    }

    /**
     * This function is used to save settelement
     */
    saveSettlement() {
        $("#saveSettlement").click();
    }

    /**
     * This function is used to recalc the amounts
     */
    RecalcAmounts() {
        $("#RecalcAmounts").click();
    }
   
    /**
     * This function is used to Delete the Allowance 
     */
    DeleteAllowance() {
        this.behaviorService.allowancesData$
            .subscribe((result) => {
                this.allowancesData = result;
            });
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            { disableClose: true, width: "100%" }
        );
        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to delete?";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                // this.DairyData.DairyRowClickData
                let postData = {
                    FormAction: "delete",
                    data: {
                        SETTLEMENTALLOWANCEGUID:
                            this.allowancesData.SETTLEMENTALLOWANCEGUID,
                    },
                };
                let RefreshIdArray: any = ["#refreshAllowances"];
                this.toolbarServiceService.SetVariableForRetry(
                    postData,
                    "settlement-allowance",
                    RefreshIdArray
                );
                this._mainAPiServiceService
                    .getSetData(postData, "settlement-allowance")
                    .subscribe((res) => {
                        if (res.STATUS == "success") {
                            localStorage.setItem(
                                "istrackid",
                                this.oldIsTrackId
                            );
                            $("#refreshAllowances").click();
                            this.toastr.success(res.STATUS);
                        } else if (res.CODE == 451 && res.STATUS == "warning") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "settlement-allowance"
                            );
                        } else if (res.CODE == 450 && res.STATUS == "error") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "settlement-allowance"
                            );
                        } else if (res.MESSAGE == "Not logged in") {
                            this.dialogRef.close(false);
                        } else {
                            this.toastr.error("You Can't Delete");
                        }
                    });
            }
            this.confirmDialogRef = null;
        });
    }
    /* settlement Module's Function's - Cheque **/
    // New - Edit Dialog
    ChequePopup(actionType) {
        this.toolbarServiceService.SetOldistrackid("open");
        let ChequePopData = {};
        if (actionType == "new") {
            ChequePopData = { action: actionType };
        } else if (actionType == "edit" || actionType == "duplicate") {
            ChequePopData = { action: actionType };
        }
        const dialogRef = this.dialog.open(ChequePopupComponent, {
            disableClose: true,
            panelClass: "Chque-dialog",
            data: ChequePopData,
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");
            if (result) {
            }
        });
    }

   /**
    * This function is used to Delete the cheqe
    */
    DeleteCheque() {
        this.behaviorService.chequeStatment$.subscribe((result) => {
            this.chequeData = result;
        });
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",
            }
        );
        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to delete?";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                // this.DairyData.DairyRowClickData
                let postData = {
                    FormAction: "delete",
                    data: {
                        SETTLEMENTCHEQUEGUID:
                            this.chequeData.SETTLEMENTCHEQUEGUID,
                    },
                };
                let RefreshIdArray: any = ["#refreshStatment"];
                this.toolbarServiceService.SetVariableForRetry(
                    postData,
                    "settlement-cheque",
                    RefreshIdArray
                );
                this._mainAPiServiceService
                    .getSetData(postData, "settlement-cheque")
                    .subscribe((res) => {
                        if (res.STATUS == "success") {
                            localStorage.setItem(
                                "istrackid",
                                this.oldIsTrackId
                            );
                            $("#refreshStatment").click();
                            this.toastr.success(res.STATUS);
                        } else if (res.CODE == 451 && res.STATUS == "warning") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "settlement-cheque"
                            );
                        } else if (res.CODE == 450 && res.STATUS == "error") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "settlement-cheque"
                            );
                        } else if (res.MESSAGE == "Not logged in") {
                            this.dialogRef.close(false);
                        } else {
                            this.toastr.error("You Can't Delete");
                        }
                    });
            }
            this.confirmDialogRef = null;
        });
    }

    /* Dairy Appointment Module's Function's **/
    //New - Edit - Duplicate Appointment Dialog
    DiaryAppointment(actionType, origin?, matterIs?: any) {
        let DiaryPopupData: any = {};
        let DiaryRowData: any = {};

        if (this.DairyData) {
            DiaryRowData = this.DairyData;
        } else {
            let todayDateString = new Date().toString();
            let todayDateStringSplit = todayDateString.split(" ");
            todayDateStringSplit[4] = "09:00:00";
            todayDateString = todayDateStringSplit.join(" ");
            const modifiedDate = new Date(todayDateString);
            DiaryRowData = { startTime: modifiedDate, groupIndex: 0 };
            // DiaryRowData = ({ startTime: new Date(), groupIndex: 0 });
        }
        // this.behaviorService.forDiaryRefersh$.subscribe(res => {
        //     if (res) {
        //         DiaryRowData = res;
        //     } else {
        //         this.behaviorService.forDiaryRefersh({ startTime: new Date(), groupIndex: 0 });
        //     }
        // });

        if (actionType == "new") {
            // this.behaviorService.forDiaryRefersh({ startTime: new Date() });
            DiaryPopupData = {
                action: actionType,
                event: { groupIndex: DiaryRowData.groupIndex, ...DiaryRowData },
                origin: origin,
            };
        } else if (actionType == "edit" || actionType == "duplicate") {
            if (origin == "matter-details-new") {
                const users = [
                    {
                        FULLNAME: this.currentUser.UserName,
                        USERGUID: this.currentUser.UserGuid,
                        OwnerColor: "#43A047",
                    },
                ];
                const itemsdata = this.diaryMatterInfo;

                this.DiaryRowData = {
                    StartTime: this.dateformat(
                        this.changeformat(itemsdata.APPOINTMENTDATE) +
                        " " +
                        this.convertTime12to24(itemsdata.APPOINTMENTTIME)
                    ),
                    EndTime: this.dateformat(
                        this.changeformat(itemsdata.APPOINTMENTDATE) +
                        " " +
                        this.convertTime12to24(itemsdata.APPOINTMENTENDTIME)
                    ),
                    Subject:
                        "(" +
                        this.tConvert(itemsdata.APPOINTMENTTIME) +
                        ") -" +
                        itemsdata.SUBJECT,
                    IsAllDay: itemsdata.ALLDAYEVENT == 0 ? false : true,
                    DairyRowClickData: itemsdata.APPOINTMENTGUID,
                    Id: itemsdata.APPOINTMENTGUID,
                    OwnerId: users[0].USERGUID,
                    USERGUID: users[0].USERGUID,
                    FULLNAME: users[0].FULLNAME,
                    Color:
                        this.setedDiaryViewtype != "Combined"
                            ? `#${itemsdata.CATEGORYCOLOUR}`
                            : "",
                };
                DiaryPopupData = {
                    action: actionType,
                    event: { groupIndex: 0, ...this.DiaryRowData },
                    origin: origin,
                };
            } else {
                DiaryPopupData = {
                    action: actionType,
                    event: { groupIndex: 0, ...DiaryRowData },
                    origin: origin,
                };
            }
        }

        if (matterIs) {
            DiaryPopupData.matterIs = matterIs ? matterIs : null;
        }

        const dialogRef = this.dialog.open(DairyDailogComponent, {
            disableClose: true,
            panelClass: "Dairy-dialog",
            data: DiaryPopupData,
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.behaviorService.refreshDiaryData({});
        });
    }
   
    /**
     * This function is used to delete the appointment data value
     * @param origin -origin data value
     */
    DeleteAppointment(origin?) {
        this.behaviorService.forDiaryRefersh$
            .subscribe((result) => {
                this.DairyData = result;
            });
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",
            }
        );

        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to delete?";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let postData = {};
                if (origin && origin == "matter-details-new") {
                    postData = {
                        FormAction: "delete",
                        data: {
                            APPOINTMENTGUID:
                                this.diaryMatterInfo.APPOINTMENTGUID,
                        },
                    };
                } else {
                    postData = {
                        FormAction: "delete",
                        data: {
                            APPOINTMENTGUID: this.DairyData.DairyRowClickData,
                        },
                    };
                }

                // this.DairyData.DairyRowClickData
                this.toolbarServiceService.SetVariableForRetry(
                    postData,
                    "SetAppointment",
                    []
                );
                this._mainAPiServiceService
                    .getSetData(postData, "SetAppointment")
                    .subscribe((res) => {
                        this.behaviorService.refreshDiaryData({});
                        this.diaryMatterInfo = undefined;
                        if (res.STATUS == "success") {
                            localStorage.setItem(
                                "istrackid",
                                this.oldIsTrackId
                            );
                            this.behaviorService.forDiaryRefersh2("call");
                            // $('#refreshLegalChronology').click();
                            this.toastr.success(res.STATUS);
                            this.behaviorService.forDiaryRefersh(null);
                        } else if (res.CODE == 451 && res.STATUS == "warning") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "SetAppointment"
                            );
                        } else if (res.CODE == 450 && res.STATUS == "error") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "SetAppointment"
                            );
                        } else if (res.MESSAGE == "Not logged in") {
                            this.dialogRef.close(false);
                        } else {
                            this.toastr.error(
                                "You Can't Delete Contact Which One Is To Related to Matters"
                            );
                        }
                    });
            } else {
                this.diaryMatterInfo = undefined;
            }
            this.confirmDialogRef = null;
        });
    }

    /**
     * This function is used to Change the user data
     */
    ChangeUser() {
        this.behaviorService.forDiaryRefersh$
            .subscribe((result) => {
                this.DairyData = result;
            });

        const dialogRef = this.MatDialog.open(UserSelectPopupComponent, {
            width: "100%",
            disableClose: true,
            data: { multi: "Multi" },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                result.forEach((element) => {
                    if (!element.OwnerColor || element.OwnerColor == "")
                        element.OwnerColor =
                            "#" +
                            Math.floor(Math.random() * 16777215).toString(16);
                });
                localStorage.setItem(
                    "SelectedDiaryUsers",
                    JSON.stringify(result)
                );
                this.behaviorService.forDiaryRefersh2("call");
            }
        });
    }

    /**
     * This function is used to change the user of user Activity data
     */
    ChangeUserOfUserActivity() {
        this.behaviorService.forDiaryRefersh$
            .subscribe((result) => {
                this.DairyData = result;
            });

        const dialogRef = this.MatDialog.open(UserSelectPopupComponent, {
            width: "100%",
            disableClose: true,
            data: { multi: "Multi" },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                localStorage.setItem(
                    "SelectedUserActivityUsers",
                    JSON.stringify(result)
                );
                this.behaviorService.forUserActivity("call");
            }
        });
    }

    /**
     * This function is used to set viem type data
     * @param params -params data
     */
    setViewType(params: any) {
        const pipe = this.tierpermission.transform("Combined Calendar");
        if (pipe.ALLOWED !== 1 && params == "Combined") {
            this.goTo(pipe.URL);
        } else {
            this.behaviorService.UseCalanderViewType$.subscribe((result) => {
                if (result != null) {
                    this.CurrentDate = result;
                } else {
                    this.CurrentDate = new Date();
                }
                // this.getEvents();
            });
            this.behaviorService.setCalanderViewType(params);
            this.behaviorService.UseCalanderViewType(this.CurrentDate);
        }
    }

    /**
     * this function is used to set time scale data
     * @param params -params data value
     */
    setTimeScale(params: any) {
        this.behaviorService.setTimeScale(params);
    }
    //_____________________________________________________________________________________________________
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    logOutUser() {
        this.authenticationService.logout();
        window.active = true;
        this.behaviorService.SetclossAllBrowser(true);
    }

    /**
     * This function is used to make the nsvbsr setting
     * @param value 
     */
    navBarSetting(value: any) {
        let temActiveTab = 1;
        let x = value.split("/");
        this.activeMenu = x[1];
        this.activeSubMenu = x[2] ? x[2] : "";
        this.isInvoice = x[3] ? x[3] : "";
        if (x[1] == "matters" || x[1] == "") {
            temActiveTab = 1;
        } else if (x[1] == "contact") {
            temActiveTab = 2;
        } else if (x[1] == "time-billing") {
            temActiveTab = 3;
        } else if (x[1] == "legal-details" || x[1] == "matter-details") {
            temActiveTab = 4;
        } else if (
            x[1] == "diary" ||
            x[1] == "diary?calander=day" ||
            x[1] == "diary?calander=week" ||
            x[1] == "diary?calander=month"
        ) {
            temActiveTab = 5;
            if (x[2] == "create-diary") {
                temActiveTab = 26;
            }
        } else if (x[1] == "time-entries") {
            temActiveTab = 6;
        } else if (x[1] == "invoice") {
            temActiveTab = 7;
        } else if (x[1] == "spend-money") {
            temActiveTab = 8;
        } else if (x[1] == "receive-money") {
            temActiveTab = 9;
        } else if (x[1] == "create-document") {
            this.activedocument = x[1];
            temActiveTab = 10;
            if (
                x[2] == "matter-template" ||
                x[2] == "email-matter-template" ||
                x[2] == "packs-matter-template" ||
                x[2] == "court-forms-matter-template" ||
                x[2] == "precedents-matter-template"
            ) {
                this.TemplateUrlHandel = "/create-document/matter-template";
                this.emailrouting = "/create-document/email-matter-template";
                this.packrouting = "/create-document/packs-matter-template";
                this.courtFormsRouting =
                    "/create-document/court-forms-matter-template";
                this.precedentsRouting =
                    "/create-document/precedents-matter-template";
                this.packsToobar = "Packs";
            } else if (
                x[2] == "invoice-template" ||
                x[2] == "email-invoice-template" ||
                x[2] == "packs-invoice-template" ||
                x[2] == "court-forms-invoice-template" ||
                x[2] == "precedents-invoice-template"
            ) {
                this.packsToobar = "Packs";
                this.TemplateUrlHandel = "/create-document/invoice-template";
                this.emailrouting = "/create-document/email-invoice-template";
                this.packrouting = "/create-document/packs-invoice-template";
                this.courtFormsRouting =
                    "/create-document/court-forms-invoice-template";
                this.precedentsRouting =
                    "/create-document/precedents-invoice-template";
            } else if (
                x[2] == "contact-template" ||
                x[2] == "email-contact-template" ||
                x[2] == "packs-contact-template" ||
                x[2] == "court-forms-contact-template" ||
                x[2] == "precedents-contact-template"
            ) {
                this.packsToobar = "Packs";
                this.TemplateUrlHandel = "/create-document/contact-template";
                this.emailrouting = "/create-document/email-contact-template";
                this.packrouting = "/create-document/packs-contact-template";
                this.courtFormsRouting =
                    "/create-document/court-forms-contact-template";
                this.precedentsRouting =
                    "/create-document/precedents-contact-template";
            } else if (
                x[2] == "receive-money-template" ||
                x[2] == "email-receive-money-template" ||
                x[2] == "packs-receive-money-template" ||
                x[2] == "court-forms-receive-money-template" ||
                x[2] == "precedents-receive-money-template"
            ) {
                this.packsToobar = "Packs";
                this.TemplateUrlHandel =
                    "/create-document/receive-money-template";
                this.emailrouting =
                    "/create-document/email-receive-money-template";
                this.packrouting =
                    "/create-document/packs-receive-money-template";
                this.courtFormsRouting =
                    "/create-document/court-forms-receive-money-template";
                this.precedentsRouting =
                    "/create-document/precedents-receive-money-template";
            } else if (
                x[2] == "safe-custody-template" ||
                x[2] == "email-safe-custody-template" ||
                x[2] == "packs-safe-custody-template" ||
                x[2] == "court-forms-safe-custody-template" ||
                x[2] == "precedents-safe-custody-template"
            ) {
                this.packsToobar = "Packs";
                this.TemplateUrlHandel =
                    "/create-document/safe-custody-template";
                this.emailrouting =
                    "/create-document/email-safe-custody-template";
                this.packrouting =
                    "/create-document/packs-safe-custody-template";
                this.courtFormsRouting =
                    "/create-document/court-forms-safe-custody-template";
                this.precedentsRouting =
                    "/create-document/precedents-safe-custody-template";
            } else if (
                x[2] == "document-register-template" ||
                x[2] == "email-document-register-template" ||
                x[2] == "packs-document-register-template" ||
                x[2] == "court-forms-document-register-template" ||
                x[2] == "precedents-document-register-template"
            ) {
                this.packsToobar = "Packs";
                this.emailrouting =
                    "/create-document/email-document-register-template";
                this.TemplateUrlHandel =
                    "/create-document/document-register-template";
                this.packrouting =
                    "/create-document/packs-document-register-template";
                this.courtFormsRouting =
                    "/create-document/court-forms-document-register-template";
                this.precedentsRouting =
                    "/create-document/precedents-document-register-template";
            } else if (
                x[2] == "invoice-multiple-document" ||
                x[2] == "email-invoice-multiple-document" ||
                x[2] == "packs-invoice-multiple-document" ||
                x[2] == "court-invoice-multiple-document" ||
                x[2] == "precedents-invoice-multiple-document"
            ) {
                this.packsToobar = "Packs";
                this.emailrouting =
                    "/create-document/email-invoice-multiple-document";
                this.TemplateUrlHandel =
                    "/create-document/invoice-multiple-document";
                this.packrouting =
                    "/create-document/packs-invoice-multiple-document";
                this.courtFormsRouting =
                    "/create-document/court-invoice-multiple-document";
                this.precedentsRouting =
                    "/create-document/precedents-invoice-multiple-document";
            }
        } else if (x[1] == "system-setting") {
            if (x[2] == "users") {
                temActiveTab = 12;
            } else if (x[2] == "useractivity") {
                temActiveTab = 33;
            } else {
                temActiveTab = 11;
            }
        } else if (x[1] == "users") {
            temActiveTab = 12;
        } else if (x[1] == "activities") {
            temActiveTab = 13;
        } else if (x[1] == "document-register") {
            temActiveTab = 14;
        } else if (x[1] == "chart-account" || x[1] == "trust-chart-account") {
            temActiveTab = 15;
        } else if (
            x[1] == "general-journal" ||
            x[1] == "trust-general-journal"
        ) {
            temActiveTab = 16;
        } else if (x[1] == "conflict-check") {
            temActiveTab = 17;
        } else if (x[1] == "authorities") {
            temActiveTab = 18;
        } else if (x[1] == "searching") {
            temActiveTab = 19;
        } else if (x[1] == "account-reconciliation") {
            temActiveTab = 20;
        } else if (x[1] == "account-management") {
            if (x[2] == "useractivity") {
                temActiveTab = 33;
            } else {
                temActiveTab = 21;
            }
        } else if (x[1] == "full-Safe-Custody") {
            temActiveTab = 22;
        } else if (x[1] == "trust-money") {
            temActiveTab = 23;
        } else if (x[1] == "trust-end-month") {
            temActiveTab = 24;
        } else if (x[1] == "task") {
            temActiveTab = 25;
        } else if (x[1] == "trust-general-journal") {
            temActiveTab = 28;
        } else if (x[1] == "dashboard") {
            temActiveTab = 29;
        } else if (x[1] == "main-report") {
            temActiveTab = 30;
        } else if (x[1] == "email") {
            temActiveTab = 31;
        } else if (x[1] == "audit-logs") {
            temActiveTab = 34;
        }  else if (x[1] == "bank-feed") {
            temActiveTab = 36;
        } else if (
            x[1] == "account" &&
            (x[2] == "transactions" || x[2] == "trust-transactions")
        ) {
            temActiveTab = 32;
        } else if (x[1] == "template-viewer") {
            temActiveTab = 35;
        } else {
            temActiveTab = 1;
        }
        this.behaviorService.setactiveTab(temActiveTab);
        this.activeSubMenu = x[2];
        this.behaviorService.SetActiveSubMenu(this.activeSubMenu);
        // this.behaviorService.navigation(this.router.url);
        // this.selectedIndex=0;
        this.setTab("");
    }

    /**
     * This function is used to set the tab
     * @param event 
     */
    setTab(event: any) {
        if (this.isTabShow != 30) {
            this.selectedIndex = 0;
            //  this.selectedIndex = undefined;
            // setTimeout(() =>
            //     this.selectedIndex = 0;
            // }, 500);
        } else {
            this.selectedIndex = 2;
        }
    }
    // ****************************************** START Invoice related funtion like create update delete view*********************************************
    selectMatterInvoice() {
        const dialogRef = this._matDialog.open(MatterDialogComponent, {
            width: "100%",
            disableClose: false,
            data: null,
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                localStorage.setItem(
                    "set_active_matters",
                    JSON.stringify(result)
                );
                sessionStorage.setItem('SetclickMaterFromTimeLine', JSON.stringify(result));
                this.behaviorService.SetclickMaterFromTimeLine(result);
                // this.router.navigate(['time-billing/work-in-progress/invoice']);
                this.toolbarServiceService.createInvoice("");
            }
        });
    }

    /**
     * This function is used to open the time entry popup data 
     * @param type-type data value 
     */
    NewTimeEntry(type: any) {
        this.toolbarServiceService.SetOldistrackid("open");
        if (type == "timeBill") {
            // let temMatterData = JSON.parse(
            //     localStorage.getItem(
            //         this.windowNameId ? this.windowNameId : "set_active_matters"
            //     )
            // );
            const dialogRef = this.dialog.open(ResumeTimerComponent, {
                width: "100%",
                disableClose: true,
                data: { type: "new", matterData: this.selectedMatterTab },
            });
            dialogRef.afterClosed().subscribe((result) => {
                this.toolbarServiceService.SetOldistrackid("close");
                if (result) {
                    $("#refreshTimeEntryTab").click();
                    $("#refreshWorkInprogress").click();
                }
            });
        } else {
            const dialogRef = this._matDialog.open(MatterDialogComponent, {
                width: "100%",
                disableClose: true,
                data: null,
            });
            dialogRef.afterClosed().subscribe((result) => {
                this.toolbarServiceService.SetOldistrackid("close");
                if (result) {
                    const dialogRef = this.dialog.open(ResumeTimerComponent, {
                        width: "100%",
                        disableClose: true,
                        data: { type: "new", matterData: result },
                    });
                    dialogRef.afterClosed().subscribe((result) => {
                        this.toolbarServiceService.SetOldistrackid("close");
                        if (result) {
                            localStorage.setItem(
                                "set_active_matters",
                                JSON.stringify(result)
                            );
                            $("#refreshTimeEntryTab").click();
                            $("#refreshWorkInprogress").click();
                        }
                    });
                }
            });
        }
    }
   
    /**
     * This function is used to isInvoiceclick
     */
    isInvoiceClick() {
        this.clickedBtn = "invoiceDoc";
    }

    /**
     * This function is used to isMatter Click
     */
    isMatterClick() {
        this.clickedBtn = "matterDoc";
    }

    /**
     * This function is used to create gloable invoice data value
     * @param type -type of the invoice data
     * @param matterIs -value of matter data
     * @returns -invoice data 
     */
    createGlobalInvoice(type: any, matterIs?: any) {
        this.toolbarServiceService.SetOldistrackid("open");
        this.behaviorService.selectedMatters$
            .subscribe((res) => {
                if (res.length > 0) {
                    this.selectedMatterForinvoice = res;
                } else {
                    this.selectedMatterForinvoice = [];
                }
            });
        // if selected then return. window timeand billing in any situation matter tiked not tiked any type
        if (type == "" || type == "preview" || type == "Invoice All WIP") {
            const dialogRef = this._matDialog.open(InvoiceAddDailogComponent, {
                width: "100%",
                disableClose: true,
                data: { type, matterIs: matterIs ? matterIs : null },
            });

            dialogRef.afterClosed().subscribe((result) => {
                this.toolbarServiceService.SetOldistrackid("close");
                if (result) {
                    $("#refreshWorkInprogress").click();
                    $("#refreshTimeEntryTab").click();
                }
            });

            return;
        }

        if (
            this.selectedMatterForinvoice.length > 0 &&
            type != "Invoice All WIP"
        ) {
            if (type == "") {
                const dialogRef = this._matDialog.open(
                    InvoiceAddDailogComponent,
                    {
                        width: "100%",
                        disableClose: true,
                        data: { type, matterIs: matterIs ? matterIs : null },
                    }
                );

                dialogRef.afterClosed().subscribe((result) => {
                    this.toolbarServiceService.SetOldistrackid("close");
                    if (result) {
                        $("#refreshWorkInprogress").click();
                        $("#refreshTimeEntryTab").click();
                    }
                });
                return;
            }
            if (
                type == "Invoice All WIP" &&
                this.selectedMatterForinvoice.length > 0
            ) {
                this.toolbarServiceService.wipallinvoiceCalled(
                    this.selectedMatterForinvoice
                );
            }
            if (type == "preview") {
                let passdata: any = {
                    Context: "previewall",
                    ContextGuid: "",
                    knownby: "Template",
                    Type: "Template",
                    data: this.selectedMatterForinvoice,
                    Template: this.TemplateGenerateData.TEMPLATENAME,
                    isMultipleDocument: true,
                };
                this.toolbarServiceService.ForDocDialogOpen(passdata);
                return;
            }
        }

        if (
            type == "Invoice All WIP" &&
            this.selectedMatterForinvoice.length > 0
        ) {
            this.toolbarServiceService.wipallinvoiceCalled(
                this.selectedMatterForinvoice
            );
        }
        if (
            (type == "Invoice All WIP" &&
                this.selectedMatterForinvoice.length <= 0) ||
            type == "preview"
        ) {
            const dialogRef = this._matDialog.open(InvoiceAddDailogComponent, {
                width: "100%",
                disableClose: true,
                data: { type, matterIs: matterIs ? matterIs : null },
            });

            dialogRef.afterClosed().subscribe((result) => {
                this.toolbarServiceService.SetOldistrackid("close");
                if (result) {
                    $("#refreshWorkInprogress").click();
                    $("#refreshTimeEntryTab").click();
                }
            });
        }
    }

   /**
    * This function is ued to Move the WIP
    * @returns moveWIP data
    */

    moveWIP() {
        this.toolbarServiceService.SetOldistrackid("open");
        let moveMatterSelectorData = JSON.parse(
            localStorage.getItem("SelectedWIPCheckbox")
        );

        if (moveMatterSelectorData && moveMatterSelectorData.length == 0) {
            this.toastr.error(
                "Please tick at least one item that you want to move another matter."
            );
            return;
        }
        const dialogRef = this._matDialog.open(MoveEntryComponent, {
            width: "100%",
            disableClose: true,
            data: "",
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");
            if (result) {
                // $('#refreshWorkInprogress').click();
                // $('#refreshTimeEntryTab').click();
            }
        });
    }


    /**
     * This function is used to create the ReceiptForTimeBilling data
     */
    createReceiptForTimeBilling() {
        this.toolbarServiceService.SetOldistrackid("open");
        // let result = JSON.parse(localStorage.getItem('set_active_matters'));
        // let matterId = window.name.split("_");
        // this.windowNameId = matterId && matterId[1] ? matterId[1] : undefined;
        // let result = JSON.parse(
        //     localStorage.getItem(
        //         this.windowNameId ? this.windowNameId : "set_active_matters"
        //     )
        // );
        const dialogRef = this._matDialog.open(ReceiptDilogComponent, {
            width: "100%",
            disableClose: true,
            data: { action: "add", type: " ", matterData: this.selectedMatterTab },
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");
            if (result) {
                $("#refreshReceiceMoany").click();
                $("#refresheReceiptsTab").click();
                $("#refreshreciptsDAta").click();
                $("#getmatterinvoice").click();
            }
        });
    }

    /**
     * This function is used to View Receipt For Time Billing
     */
    ViewReceiptForTimeBilling() {
        this.toolbarServiceService.SetOldistrackid("open");
        const dialogRef = this._matDialog.open(ReceiptDilogComponent, {
            width: "100%",
            disableClose: true,
            data: { action: "editForTB" },
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");
            if (result) {
                $("#refreshReceiceMoany").click();
                $("#refresheReceiptsTab").click();
                $("#refreshreciptsDAta").click();
            }
        });
    }

    /**
     * This function is used to check the view Receipt data
     * @param type -type value data
     */
    ViewReceipt(type: any) {
        this.toolbarServiceService.SetOldistrackid("open");
        let dialogRef: any = "";
        if (
            this.receiptData.INCOMECLASS == "Receipt" ||
            this.receiptData.INCOMECLASS == "Write Off"
        ) {
            dialogRef = this._matDialog.open(ReceiptDilogComponent, {
                width: "100%",
                disableClose: true,
                data: { action: type },
            });
        } else {
            dialogRef = this._matDialog.open(GeneralReceiptDilogComponent, {
                width: "100%",
                disableClose: true,
                data: { type: "edit" },
            });
        }
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");
            if (result) {
                localStorage.removeItem("matterName");
                $("#refreshReceiceMoany").click();
                $("#refreshreciptsDAta").click();
                $("#refreshRecouncilItem").click();
                $("#refreshGeneral").click();
            }
        });
    }

    /**
     * This function is used to get the invoiceWriteoff data value
     */
    InvoiceWriteoff() {
        this.toolbarServiceService.SetOldistrackid("open");
        let INVOICEGUID = "";
        this.behaviorService.matterInvoice$
            .subscribe((matterInvoiceData) => {
                if (matterInvoiceData)
                    INVOICEGUID = matterInvoiceData.INVOICEGUID;
            });
        const dialogRef = this._matDialog.open(WriteOffInvoiceComponent, {
            width: "100%",
            disableClose: true,
            data: { type: "edit", INVOICEGUID: INVOICEGUID },
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");
            if (result) {
                $("#getmatterinvoice").click();
                $("#refreshMatterInvoice").click();
                $("#refreshInvoiceTab").click();
            }
        });
    }
    /**
     * Thisfunction is used to get the invoice details infromation data value
     * @param isType -istype data value
     */
    InvoiceDetail(isType) {
        this.toolbarServiceService.SetOldistrackid("open");
        let INVOICEGUID = "";
        let editType = "edit";
        if (isType == "isTime") {
            this.behaviorService.matterInvoice$
                .subscribe((matterInvoiceData) => {
                    if (matterInvoiceData)
                        INVOICEGUID = matterInvoiceData.INVOICEGUID;
                });
        } else if (isType == "view") {
            editType = "view";
            INVOICEGUID = localStorage.getItem("edit_invoice_id");
        } else if (isType == "TBView") {
            editType = "pre_view";
            this.behaviorService.workInProgress$
                .subscribe((workInProgress) => {
                    if (workInProgress)
                        INVOICEGUID = workInProgress.INVOICEGUID;
                });
        } else {
            INVOICEGUID = localStorage.getItem("edit_invoice_id");
        }
        const dialogRef = this._matDialog.open(InvoiceDetailComponent, {
            width: "100%",
            disableClose: true,
            data: { type: editType, INVOICEGUID: INVOICEGUID },
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");
            if (result) {
            }
        });
    }
    
/**
 * This function is used to delete the invoice data
 * @param isType -type of the invoice data value.
 */

    deleteInvoice(isType): void {
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",
            }
        );
        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to Delete?";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let INVOICEGUID = "";
                if (isType == "isTime") {
                    this.behaviorService.matterInvoice$.subscribe(
                        (matterInvoiceData) => {
                            if (matterInvoiceData)
                                INVOICEGUID = matterInvoiceData.INVOICEGUID;
                        }
                    );
                } else {
                    INVOICEGUID = localStorage.getItem("edit_invoice_id");
                }
                this.GloballyDelete({
                    API: "SetInvoice",
                    DATA: { INVOICEGUID: INVOICEGUID },
                });
            }
            this.confirmDialogRef = null;
        });
    }
   
    /**
     * This functionis used to delete the receive money window
     */
    deleteReceiceMoany(): void {
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",
            }
        );
        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to delete?";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let receiptData = JSON.parse(
                    localStorage.getItem("receiptData")
                );
                let postData = {
                    FormAction: "delete",
                    DATA: { INCOMEGUID: receiptData.INCOMEGUID },
                };
                let RefreshIdArray: any = [
                    "#refreshReceiceMoany",
                    "#refresheReceiptsTab",
                ];
                this.toolbarServiceService.SetVariableForRetry(
                    postData,
                    "SetIncome",
                    RefreshIdArray
                );
                this._mainAPiServiceService
                    .getSetData(postData, "SetIncome")
                    .subscribe((res) => {
                        if (res.STATUS == "success" && res.CODE == 200) {
                            localStorage.setItem(
                                "istrackid",
                                this.oldIsTrackId
                            );
                            $("#refreshReceiceMoany").click();
                            $("#refresheReceiptsTab").click();
                            $("#refreshreciptsDAta").click();
                            this.toastr.success("Deleted successfully");
                        } else if (res.CODE == 411 && res.STATUS == "error") {
                            // this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                            //     disableClose: true, width: '100%',
                            // });
                            // this.confirmDialogRef.componentInstance.confirmMessage = res.MESSAGE;
                            // this.confirmDialogRef.afterClosed().subscribe(result => {
                            //     if (result) {
                            //     }
                            // })
                        } else if (res.CODE == 451 && res.STATUS == "warning") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "SetIncome"
                            );
                        } else if (res.CODE == 450 && res.STATUS == "error") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "SetIncome"
                            );
                        } else if (res.MESSAGE == "Not logged in") {
                            this.dialogRef.close(false);
                        }
                    });
            }
            this.confirmDialogRef = null;
        });
    }

    /**
     * This function is used to open the New General Receipt data
     * @param type -type value
     * @param matterIs -matteris data value
     */
    NewGeneralReceipt(type: any, matterIs?: any): void {
        this.toolbarServiceService.SetOldistrackid("open");
        const dialogRef = this._matDialog.open(GeneralReceiptDilogComponent, {
            width: "100%",
            disableClose: true,
            data: { type: type, Id: "", matterIs: matterIs ? matterIs : null },
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");
            if (result) {
                $("#refreshReceiceMoany").click();
                $("#refreshRecouncilItem").click();
                $("#refreshGeneral").click();
            }
        });
    }

  /**
   * This function is used to Check for credits data value
   */
    CheckForCredits() {
        // Note-->Check for credits is not available. due to that to do i apply if condition in code.

        // if(this.isforCredits == 'empty'){
        //     this.toastr.error('There are no credits');
        // }else{

        this.toolbarServiceService.SetOldistrackid("open");
        // let MatterData: any = JSON.parse(localStorage.getItem('set_active_matters'));
        // let matterId = window.name.split("_");
        // this.windowNameId = matterId && matterId[1] ? matterId[1] : undefined;
        // let MatterData = JSON.parse(
        //     localStorage.getItem(
        //         this.windowNameId ? this.windowNameId : "set_active_matters"
        //     )
        // );
        let InvoiceData = "";
        this.behaviorService.matterInvoice$
            .subscribe((matterInvoiceData) => {
                if (matterInvoiceData) InvoiceData = matterInvoiceData;
            });
        const dialogRef = this._matDialog.open(CheckForCreditsComponent, {
            width: "100%",
            disableClose: true,
            data: {
                type: "view",
                MatterData: this.selectedMatterTab,
                InvoiceData: InvoiceData,
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");
            if (result) {
            }
        });

        // }
    }

    /**
     * This function is used to open the trust Recouncileitem popup
     * @param type -type data value
     */
    TrustRecouncileitemPopup(type: any) {
        const dialogRef = this._matDialog.open(AdjustmentDialogComponent, {
            width: "100%",
            disableClose: true,
            data: { type: type },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
            }
            $("#refreshRecouncilItem").click();
        });
    }

    /**
     * This function is used to open the dialog data value
     */
    openDialog() {
        const dialogRef = this._matDialog.open(
            FirstTimeLoginBasicInformationComponent,
            { width: "100%", disableClose: true, data: null }
        );
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");
            if (result) {
            }
        });
    }
    

/**
 * This function is used to open the tasks Dialog data value
 * @param val -value data 
 */
    TaskDialoge(val) {
        let UserPermisstion = localStorage.getItem("MatterWindow_Open_Status");
        if (val == "new matter") {
            if (UserPermisstion) {
                this.toolbarServiceService.SetOldistrackid("open");
                const dialogRef = this._matDialog.open(MatterDialogComponent, {
                    width: "100%",
                    disableClose: true,
                    data: null,
                });
                dialogRef.afterClosed().subscribe((result) => {
                    this.toolbarServiceService.SetOldistrackid("close");
                    if (result) {
                        localStorage.setItem(
                            "set_active_matters",
                            JSON.stringify(result)
                        );
                        this.OpenTaskPopUp(val, result);
                        $("#refreshTask").click();
                        return result;
                    }
                });
            } else {
                this.OpenTaskPopUp(val);
            }
        } else {
            this.OpenTaskPopUp(val);
        }
    }

    /**
     * This function is used to open the Tasks popup data
     * @param actionType -action type data value
     * @param result -result data value
     */
    OpenTaskPopUp(actionType, result?: any) {
        this.toolbarServiceService.SetOldistrackid("open");
        let TaskPopdata = {};
        if (actionType == "new matter" || "new general") {
            TaskPopdata = { action: actionType, matter: result };
        } else if (
            actionType == "edit" ||
            actionType == "copy" ||
            actionType == "copy legal" ||
            actionType == "edit legal"
        ) {
            TaskPopdata = { action: actionType };
        }
        const dialogRef = this.dialog.open(TaskDialogeComponent, {
            disableClose: true,
            panelClass: "Task-dialog",
            data: TaskPopdata,
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");
            if (result) {
                $("#refreshTask").click();
                $("#refreshLegalTask").click();
            }
        });
    }

    /**
     * This function is used to delete the tasks
     */
    deleteTask() {
        //befor is SetTask but now as per new change is task.
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",
            }
        );
        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to delete?";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let postData = {
                    FormAction: "delete",
                    DATA: { TASKGUID: this.TaskData.TASKGUID },
                };
                let RefreshIdArray: any = ["#refreshTask", "#refreshLegalTask"];
                this.toolbarServiceService.SetVariableForRetry(
                    postData,
                    "task",
                    RefreshIdArray
                );
                this._mainAPiServiceService
                    .getSetData(postData, "task")
                    .subscribe((res) => {
                        if (res.STATUS == "success" && res.CODE == 200) {
                            localStorage.setItem(
                                "istrackid",
                                this.oldIsTrackId
                            );
                            $("#refreshTask").click();
                            $("#refreshLegalTask").click();
                            this.toastr.success("Deleted successfully");
                        } else if (res.CODE == 451 && res.STATUS == "warning") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "task"
                            );
                        } else if (res.CODE == 450 && res.STATUS == "error") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "task"
                            );
                        } else if (res.MESSAGE == "Not logged in") {
                            this.dialogRef.close(false);
                        }
                    });
            }
            this.confirmDialogRef = null;
        });
    }
    //////// End  Task///////////////////
    // clickToolbarbtn() {
    //     this.isDocumentGenerateHide = "yes";
    // }
    // clickToolbarbtn2() {
    //     this.isDocumentGenerateHide = "no";
    // }
    /** PACKETS MODULE FUNCTION'S */
    OpenPacket(actionType) {
        this.toolbarServiceService.SetOldistrackid("open");
        let PacketPopData = {};
        if (actionType == "new") {
            PacketPopData = { action: actionType };
        } else if (actionType == "edit" || actionType == "duplicate") {
            PacketPopData = { action: actionType };
        }
        const dialogRef = this.dialog.open(PacketsDialogComponent, {
            disableClose: true,
            panelClass: "Packets-dialog",
            data: PacketPopData,
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");
            if (result) {
                $("#refereshpacketsdata").click();
            }
        });
    }

    /**
     * this function is used to delete the packet
     */
    DeletePacket(): void {
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",
            }
        );
        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to delete?";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let postData = {
                    Action: "delete",
                    data: {
                        SAFECUSTODYPACKETGUID:
                            this.PacketsData.SAFECUSTODYPACKETGUID,
                    },
                };
                let RefreshIdArray: any = ["#refereshpacketsdata"];
                this.toolbarServiceService.SetVariableForRetry(
                    postData,
                    "safe-custody-packet",
                    RefreshIdArray
                );
                this._mainAPiServiceService
                    .getSetData(postData, "safe-custody-packet")
                    .subscribe((res) => {
                        if (res.STATUS == "success") {
                            localStorage.setItem(
                                "istrackid",
                                this.oldIsTrackId
                            );
                            $("#refereshpacketsdata").click();
                            this.toastr.success("Deleted successfully");
                        } else if (res.CODE == 451 && res.STATUS == "warning") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "safe-custody-packet"
                            );
                        } else if (res.CODE == 450 && res.STATUS == "error") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "safe-custody-packet"
                            );
                        } else if (res.MESSAGE == "Not logged in") {
                            this.dialogRef.close(false);
                        }
                    });
            }
            this.confirmDialogRef = null;
        });
    }
   
    /**
     * This function is used to open the New safe custody data value
     * @param actionType -action type data
     * @param matterIs -matterIs data value
     */
    OpenNewSafeCustody(actionType, matterIs?: any) {
        this.toolbarServiceService.SetOldistrackid("open");
        if (actionType === "new client") {
            // const dialogRef = this._matDialog.open(ContactSelectDialogComponent, { width: '100%', disableClose: true, data: { type: null } });
            // dialogRef.afterClosed().subscribe(result => {
            //     this.toolbarServiceService.SetOldistrackid('close');
            // if (result) {
            const dialogRef = this._matDialog.open(
                ContactSelectDialogComponent,
                { width: "100%", disableClose: true, data: "" }
            );
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    localStorage.setItem(
                        "istrackid",
                        "SafeCustodyDialogeComponent"
                    );
                    this.SafeCustodyPoup(
                        { action: actionType, result: result },
                        matterIs
                    );
                } else if (result.CODE == 406 && result.STATUS == "error") {
                    this.toastr.error(result.MESSAGE);
                }
            });
            // }
            // });
        } else if (actionType == "copy") {
            this.SafeCustodyPoup({ action: actionType, result: "" }, matterIs);
        } else if (actionType === "new matter") {
            const dialogRef = this._matDialog.open(MatterDialogComponent, {
                width: "100%",
                disableClose: true,
            });
            dialogRef.afterClosed().subscribe((result) => {
                this.toolbarServiceService.SetOldistrackid("close");
                if (result) {
                    localStorage.setItem(
                        "set_active_matters",
                        JSON.stringify(result)
                    );
                    this.SafeCustodyPoup(
                        { action: actionType, result: result },
                        matterIs
                    );
                }
            });
        } else if (actionType === "newlegal") {
            this.SafeCustodyPoup({ action: actionType, result: "" }, matterIs);
        } else {
            this.SafeCustodyPoup({ action: actionType, result: "" }, matterIs);
        }
    }

    /**
     * This function is used to open the Safecustody popup data
     * @param safeCustodyData -safecustody popup data value
     * @param matterIs -data values
     */
    SafeCustodyPoup(safeCustodyData: any, matterIs?: any) {
        this.toolbarServiceService.SetOldistrackid("open");
        const dialogRef = this.dialog.open(SafeCustodyDialogeComponent, {
            disableClose: true,
            panelClass: "Safe-Custody-dialog",
            data: { safeCustodyData, matterIs: matterIs ? matterIs : null },
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");
            if (result) {
                $("#mainsafecusday").click();
                $("#Legalsafecusday").click();
            }
        });
    }

    /**
     * This function is used to Delete the safecustody data 
     */
    DeleteSafeCustody(): void {
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",
            }
        );

        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to delete?";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let postData = {
                    Action: "delete",
                    DATA: {
                        SAFECUSTODYGUID: this.SafeCustodyData.SAFECUSTODYGUID,
                    },
                };
                let RefreshIdArray: any = [
                    "#mainsafecusday",
                    "#Legalsafecusday",
                ];
                this.toolbarServiceService.SetVariableForRetry(
                    postData,
                    "safe-custody",
                    RefreshIdArray
                );
                this._mainAPiServiceService
                    .getSetData(postData, "safe-custody")
                    .subscribe((res) => {
                        if (res.STATUS == "success" && res.CODE == 200) {
                            localStorage.setItem(
                                "istrackid",
                                this.oldIsTrackId
                            );
                            $("#mainsafecusday").click();
                            $("#Legalsafecusday").click();
                            this.toastr.success("Deleted successfully");
                        } else if (res.CODE == 451 && res.STATUS == "warning") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "safe-custody"
                            );
                        } else if (res.CODE == 450 && res.STATUS == "error") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "safe-custody"
                            );
                        } else if (res.MESSAGE == "Not logged in") {
                            this.dialogRef.close(false);
                        }
                    });
            }
            this.confirmDialogRef = null;
        });
    }
   /**
    * This function is used to open the Trust New Account data
    * @param actionType 
    */
    TrustNewAccount(actionType) {
        if (actionType === "new") {
            const dialogRef = this._matDialog.open(MatterDialogComponent, {
                width: "100%",
                disableClose: true,
            });
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    localStorage.setItem(
                        "set_active_matters",
                        JSON.stringify(result)
                    );
                    this.TrustNewChartAccount(actionType, result);
                    // $("#mainsafecusday").click();
                }
            });
        } else {
            this.TrustNewChartAccount(actionType, "");
        }
    }

    /**
     * This function is used to open TrustNewChart Account popup
     * @param actionType -action type data value
     * @param result -result data value
     */
    TrustNewChartAccount(actionType, result) {
        let TrustNewChartAccountData = {};
        if (actionType == "new") {
            TrustNewChartAccountData = { action: actionType, result };
        } else if (actionType == "edit" || actionType == "duplicate") {
            TrustNewChartAccountData = { action: actionType, result };
        }
        const dialogRef = this.dialog.open(TrustChartOfAccountDailogComponent, {
            disableClose: true,
            panelClass: "Trust-Chart-Account-Dailog",
            data: TrustNewChartAccountData,
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                // $("#mainsafecusday").click();
            }
        });
    }

    /**
     * This function is used to Trust delete account data
     */
    TrustDeleteAccount(): void {
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",
            }
        );
        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to delete?";
    }
    // ******************************************END Invoice related funtion like create update delete view*********************************************
    //***********************************************************START Select Matter Contact*************************************************************************
   
    /**
     * This function is used to Generate the Document data value
     */
    GenarateDocument() {
        this.behaviorService.TemplateGenerateData$.subscribe((result) => {
            if (result) {
                this.TemplateGenerateData = result;
            }
        });
        this.behaviorService.templateSubFolder$
            .subscribe((result) => {
                if (result) {
                    this.storeFolder = result;
                } else if (result == "") {
                    this.storeFolder = result;
                }
            });
        if (
            this.router.url == "/create-document/invoice-template" ||
            this.router.url == "/create-document/packs-invoice-template"
        ) {
            let invoiceGUid = localStorage.getItem("edit_invoice_id");
            let passdata = {
                Context: "Invoice",
                ContextGuid: invoiceGUid,
                knownby: "Template",
                Type: "Template",
                Folder: this.storeFolder,
                Template: this.TemplateGenerateData.TEMPLATENAME,
            };
            this.toolbarServiceService.ForDocDialogOpen(passdata);
        } else if (
            this.router.url == "/create-document/matter-template" ||
            this.router.url == "/create-document/packs-matter-template"
        ) {
            let matterData = JSON.parse(
                localStorage.getItem(
                    this.windowNameId ? this.windowNameId : "set_active_matters"
                )
            );
            let passdata = {
                Context: "Matter",
                ContextGuid: matterData.MATTERGUID,
                knownby: "Template",
                Type: "Template",
                Folder: this.storeFolder,
                Template: this.TemplateGenerateData.TEMPLATENAME,
            };
            this.toolbarServiceService.ForDocDialogOpen(passdata);
        } else if (
            this.router.url == "/create-document/receive-money-template" ||
            this.router.url == "/create-document/packs-receive-money-template"
        ) {
            let ReceiptData = JSON.parse(localStorage.getItem("receiptData"));
            let passdata = {
                Context: "Receipt",
                ContextGuid: ReceiptData.INCOMEGUID,
                knownby: "Template",
                Type: "Template",
                Folder: this.storeFolder,
                Template: this.TemplateGenerateData.TEMPLATENAME,
            };
            this.toolbarServiceService.ForDocDialogOpen(passdata);
        } else if (
            this.router.url == "/create-document/contact-template" ||
            this.router.url == "/create-document/packs-contact-template"
        ) {
            let ContactGuID = localStorage.getItem("contactGuid");
            let passdata = {
                Context: "Contact",
                ContextGuid: ContactGuID,
                knownby: "Template",
                Type: "Template",
                Folder: this.storeFolder,
                Template: this.TemplateGenerateData.TEMPLATENAME,
            };
            this.toolbarServiceService.ForDocDialogOpen(passdata);
        } else if (
            this.router.url == "/create-document/safe-custody-template" ||
            this.router.url == "/create-document/packs-safe-custody-template"
        ) {
            let passdata = {
                Context: "Safe Custody",
                ContextGuid: this.SafeCustodyData.SAFECUSTODYGUID,
                knownby: "Template",
                Type: "Template",
                Folder: this.storeFolder,
                Template: this.TemplateGenerateData.TEMPLATENAME,
            };
            this.toolbarServiceService.ForDocDialogOpen(passdata);
        } else if (
            this.router.url == "/create-document/document-register-template" ||
            this.router.url ==
            "/create-document/packs-document-register-template"
        ) {
            let passdata = {
                Context: "Document Register",
                ContextGuid: this.DocRegData.DOCUMENTGUID,
                knownby: "Template",
                Type: "Template",
                Folder: this.storeFolder,
                Template: this.TemplateGenerateData.TEMPLATENAME,
            };
            this.toolbarServiceService.ForDocDialogOpen(passdata);
        } else if (
            this.router.url == "/create-document/invoice-multiple-document" ||
            this.router.url ==
            "/create-document/packs-invoice-multiple-document"
        ) {
            let passdata = {
                Context: "Invoice",
                ContextGuid: "",
                knownby: "Template",
                Type: "Template",
                Folder: this.storeFolder,
                Template: this.TemplateGenerateData.TEMPLATENAME,
                isMultipleDocument: true,
            };
            this.toolbarServiceService.ForDocDialogOpen(passdata);
        } else {
            let matterData = JSON.parse(
                localStorage.getItem(
                    this.windowNameId ? this.windowNameId : "set_active_matters"
                )
            );
            let passdata = {
                Context: "Matter",
                ContextGuid: matterData.MATTERGUID,
                knownby: "Template",
                Type: "Template",
                Folder: this.storeFolder,
                Template: this.TemplateGenerateData.TEMPLATENAME,
            };
            this.toolbarServiceService.ForDocDialogOpen(passdata);
        }
    }

    /**
     * This function is used to open the copytemplate component
     */
    CopyTemplatePopup() {
        const dialogRef = this._matDialog.open(CopyTemplateComponent, {
            width: "100%",
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                // localStorage.setItem('set_active_matters', JSON.stringify(result));
            }
        });
    }

/**
 * This function is used to SetLetterHead
 */
    SetLetterHeadPopup() {
        const dialogRef = this._matDialog.open(SetLetterHeadComponent, {
            width: "100%",
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                // localStorage.setItem('set_active_matters', JSON.stringify(result));
            }
        });
    }

    /**
     * This function is used to EditTemplate Popup
     */
    EditTemplatePopup() {
        const dialogRef = this._matDialog.open(EditTemplateComponent, {
            width: "100%",
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                // localStorage.setItem('set_active_matters', JSON.stringify(result));
            }
        });
    }

    /**
     * This function is used to Open the Trust money popup
     * @param val -val data
     */
    TrustMoneyPopup(val) {
        this.toolbarServiceService.SetOldistrackid("open");
        const dialogRef = this._matDialog.open(TrustMoneyDialogeComponent, {
            width: "100%",
            disableClose: true,
            data: { action: val, forPDF: "No" },
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");
            if (result) {
                $("#trustMoneyRefersh").click();
                $("#refreshMatterInvoice").click();
                $('#Legal_Details').click()
                //localStorage.setItem('set_active_matters', JSON.stringify(result));
            }
        });
    }


    /**
     * This function is used to RecalcTimeEntry data 
     */
    ReCalcTimeEntriClick() {
        this.toolbarServiceService.SetOldistrackid("open");
        const dialogRef = this._matDialog.open(
            ReCalcTimeEntriesDialogeComponent,
            {
                width: "100%",
                disableClose: true,
                data: { action: "" },
            }
        );
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");
            if (result == true) {
                this.confirmDialogRef = this._matDialog.open(
                    FuseConfirmDialogComponent,
                    {
                        disableClose: true,
                        width: "100%",
                        data: {
                            errorData: ["There are no Entries to change"],
                        },
                    }
                );
                this.confirmDialogRef.componentInstance.confirmMessage =
                    "Recalculate Time Entries";
            } else {
            }
        });
    }

    /**
     * This function is used to searchAndReplace WorkItem Onclick
     */
    searchAndReplaceWorkItemOnClick() {
        this.toolbarServiceService.SetOldistrackid("open");
        const dialogRef = this._matDialog.open(
            SearchandreplaceworkitemComponent,
            {
                width: "100%",
                disableClose: true,
                data: { action: "" },
            }
        );
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");
            if (result) {
            }
        });
    }
    //***********************************************************END Select Matter Contact*************************************************************************

   /**
    * This function is used to gloabally delete the data value
    * @param getData -get Data value
    */
    GloballyDelete(getData) {
        let details = {
            FormAction: "delete",
            VALIDATEONLY: true,
            Data: getData.DATA,
        };
        this._mainAPiServiceService
            .getSetData(details, getData.API)
            .subscribe(
                (response) => {
                    //array empty of save item
                    if (
                        response.CODE == 200 &&
                        (response.STATUS == "OK" ||
                            response.STATUS == "success")
                    ) {
                        this.checkValidation(
                            response.DATA.VALIDATIONS,
                            details,
                            getData.API
                        );
                    } else if (
                        response.CODE == 451 &&
                        response.STATUS == "warning"
                    ) {
                        this.toastr.warning(response.MESSAGE);
                        this.isspiner = false;
                        this.checkValidation(
                            response.DATA.VALIDATIONS,
                            details,
                            getData.API
                        );
                    } else if (
                        response.CODE == 450 &&
                        response.STATUS == "error"
                    ) {
                        this.toastr.error(response.MESSAGE);
                        this.isspiner = false;
                        this.checkValidation(
                            response.DATA.VALIDATIONS,
                            details,
                            getData.API
                        );
                    } else if (response.MESSAGE == "Not logged in") {
                        this.dialogRef.close(false);
                    }
                },
                (error) => {
                    this.toastr.error(error);
                }
            );
    }

    /**
     * This function is used to check the validation data
     * @param bodyData -bodydata value
     * @param details -details value
     * @param ApiName -Api Name
     */
    async checkValidation(bodyData: any, details: any, ApiName: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
    
        // Categorize error and warning data
        bodyData.forEach((value: any) => {
            if (
                value.VALUEVALID == "No" ||
                value.VALUEVALID == "NO" ||
                value.VALUEVALID == "Error"
            ) {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == "Warning") {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
    
        if (Object.keys(errorData).length !== 0) {
            localStorage.setItem("confEWshow", "error");
    
            // Open the error dialog asynchronously
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: true,
                width: "100%",
                data: { errorData: errorData },
            });
    
            this.confirmDialogRef.componentInstance.confirmMessage = "";
    
            // Wait for the dialog to close
            const result = await this.confirmDialogRef.afterClosed().toPromise();
            localStorage.removeItem("confEWshow");
    
            if (result) {
                // Additional logic for handling error dialog confirmation, if any
            }
    
            this.confirmDialogRef = null;
        } else if (Object.keys(warningData).length !== 0) {
            localStorage.setItem("confEWshow", "warning");
    
            // Open the warning dialog asynchronously
            this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                disableClose: true,
                width: "100%",
                data: warningData,
            });
    
            this.confirmDialogRef.componentInstance.confirmMessage =
                ApiName == "SetInvoice"
                    ? ""
                    : "Are you sure you want to delete?";
    
            // Wait for the dialog to close
            const result = await this.confirmDialogRef.afterClosed().toPromise();
            localStorage.removeItem("confEWshow");
    
            if (result) {
                // Execute delete operation after warning dialog confirmation
                await this.toolbarServiceService.DeleteGData(details, ApiName);
            }
    
            this.confirmDialogRef = null;
        } else if (
            Object.keys(warningData).length == 0 &&
            Object.keys(errorData).length == 0
        ) {
            // If no errors or warnings, execute delete operation
            await this.toolbarServiceService.DeleteGData(details, ApiName);
        }
    }
    // DeleteGData(data: any, ApiName) {
    //     data.VALIDATEONLY = false;
    //     this._mainAPiServiceService.getSetData(data, ApiName).subscribe(response => {
    //         if (response.CODE == 200 && (response.STATUS == "OK" || response.STATUS == "success")) {
    //             this.toastr.success('Deleted successfully');
    //             $('#refreshInvoiceTab').click();
    //             $('#refreshMatterInvoice').click();
    //         } else if (response.CODE == 451 && response.STATUS == 'warning') {
    //             this.toastr.warning(response.MESSAGE);
    //         } else if (response.CODE == 450 && response.STATUS == 'error') {
    //             this.toastr.error(response.MESSAGE);
    //         } else if (response.MESSAGE == 'Not logged in') {
    //             this.dialogRef.close(false);
    //         }
    //     }, error => {
    //         this.toastr.error(error);
    //     });
    // }

    /**
     * This function is used to Generate the eport  
     * @param ReportID -ReportID data
     * @param title -title data value
     */
    ReportGenerate(ReportID, title) {
        this.ApiRepoParm1 = ReportID;
        this.ApiRepoParm2 = title;
        this.isGenrateReport = true;
        let ReportData: any = {
            REPORTID: ReportID,
        };
        if (ReportID == "reporttrustrequest") {
            ReportData.TrustTransactionGuid =
                this.isTrustMoneyListData.TRUSTTRANSACTIONGUID;
            if (this.ENDOFMONTHDATE && this.ENDOFMONTHDATE.Date) {
                ReportData.ENDOFMONTHDATE = this.ENDOFMONTHDATE.Date;
            }
        } else if (ReportID == "reportaccountreconciliation") {
            if (title == "Print Bank Rec Report") {
                ReportData.SHOWDETAILS = false;
            } else {
                ReportData.SHOWDETAILS = true;
            }
            ReportData.USETRUST = this.valOfIsTrust == "Yes" ? true : false;
            ReportData.ACCOUNTGUID = this.storePastRecouncileData.ACCOUNTGUID;
            ReportData.PERIODENDDATE =
                this.storePastRecouncileData.PERIODENDDATE;
        } else if (ReportID == "TrustSingleControlledAccountStatement") {
            ReportData.ACCOUNTGUID =
                this.isTrustMoneyListData.TRUSTTRANSACTIONGUID;
            if (this.ENDOFMONTHDATE && this.ENDOFMONTHDATE.Date) {
                ReportData.ENDOFMONTHDATE = this.ENDOFMONTHDATE.Date;
            }
        } else if (ReportID == "TrustSingleTrustAccountStatement") {
            ReportData.MATTERGUID =
                this.isTrustMoneyListData.TRUSTTRANSACTIONGUID;
            if (this.ENDOFMONTHDATE && this.ENDOFMONTHDATE.Date) {
                ReportData.ENDOFMONTHDATE = this.ENDOFMONTHDATE.Date;
            }
        } else {
            ReportData.ENDOFMONTHGUID =
                this.TrustEndofMonthReport.TRUSTENDOFMONTHGUID;
        }
        //Api Report Generate
        this._mainAPiServiceService
            .SetReportGenerate(ReportData, "ReportGenerate")
            .subscribe(
                (reportgenerateData) => {
                    if (
                        reportgenerateData.PDF_Generation.CODE == 200 &&
                        reportgenerateData.PDF_Generation.STATUS == "success"
                    ) {
                        this.toastr.success("Report generated successfully");
                        $("#setendofMonth").click();
                        window.open(
                            environment.ReportUrl +
                            reportgenerateData.PDF_Generation.DATA
                                .PDFFILENAME,
                            "_blank"
                        );
                        let Data = {
                            ReportGenerateData: {
                                title: title,
                                PdfFileName:
                                    reportgenerateData.PDF_Generation.DATA
                                        .PDFFILENAME,
                            },
                        };
                        //open pop-up
                        //this.dialog.open(ReportsComponent, { width: '100%', data: Data });
                    } else if (
                        reportgenerateData.PDF_Generation.CODE == 415 &&
                        reportgenerateData.PDF_Generation.STATUS == "error"
                    ) {
                        this.toastr.error(
                            reportgenerateData.PDF_Generation.MESSAGE
                        );
                    }
                    this.isGenrateReport = false;
                },
                (error) => {
                    this.toastr.error(error);
                    this.isGenrateReport = false;
                }
            );
    }

    /**
     * This function is used to Start Info Dialog 
     * @param type -type data
     */
    StartInfoDialog(type: any) {
        this.toolbarServiceService.SetOldistrackid("open");
        const dialogRef = this._matDialog.open(MatterDialogComponent, {
            width: "100%",
            disableClose: true,
            data: null,
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");
            if (result) {
                this.viewOnlineDocument(type, result.MATTERGUID);
            }
        });
    }

    /**
     * this function is used to view online document data
     * @param type -type data
     * @param matterId -id
     */
    viewOnlineDocument(type: any, matterId: any) {

        if (!matterId) {
            //     this.selectedMatterTab = JSON.parse(
            //         localStorage.getItem(
            //             this.windowNameId ? this.windowNameId : "set_active_matters"
            //         )
            //     );
            //     matterId = this.selectedMatterTab.MATTERGUID;
            // }
            /**
         *  Subscribes to the clickMaterFromTimeLine$ observable provided by behaviorService.
         * @remarks
         * It is particularly relevant for scenarios where the behaviorService controls the "open in new tab"
         * feature(on or off), and the selection of matterGuid is influenced by whether multiple tabs are open.
         */
            this.behaviorService.clickMaterFromTimeLine$
                .pipe(
                    tap(result => {
                        if (result) {
                            this.selectedMatterTab = result;
                        } else {
                            this.selectedMatterTab = JSON.parse(localStorage.getItem(this.windowNameId || 'set_active_matters')) || result;
                            localStorage.setItem(this.windowNameId || 'set_active_matters', JSON.stringify(this.matterData));
                        }
                        matterId = this.selectedMatterTab.MATTERGUID;
                    })
                )
                .subscribe();
        }

        this.toolbarServiceService.SetOldistrackid("open");
        this.isGenrateReport = true;
        let sendData: any = {};
        if (type == "matter") {
            sendData.MatterGuid = matterId;
            this.GetCostRecoveryAPIFun(sendData);
        } else if (type == "WebVOI") {
            const dialogRef = this._matDialog.open(
                ContactCorresDetailsComponent,
                {
                    width: "100%",
                    disableClose: true,
                    data: { ContextGuid: matterId, callType: "select" },
                }
            );
            dialogRef.afterClosed().subscribe((result) => {
                this.toolbarServiceService.SetOldistrackid("close");
                if (result) {
                    sendData.MatterGuid = matterId;
                    sendData.CONTACTGUID = result.MATTERCONTACTGUID;
                    sendData.URLTYPE = "WebVOI";
                    this.GetCostRecoveryAPIFun(sendData);
                } else {
                    this.isGenrateReport = false;
                }
            });
        } else if (type == "CourtSync") {
            sendData.MatterGuid = matterId;
            sendData.URLTYPE = "CourtSync";
            this.GetCostRecoveryAPIFun(sendData);
        } else if (type == "GlobalXSearch") {
            sendData.data = { MatterGuid: matterId };
            sendData.URLTYPE = "GlobalXSearch";
            this.GetCostRecoveryAPIFun(sendData);
        } else {
            sendData = {
                URLType: type,
                CostRecoveryGuid: this.isSerachingData.COSTRECOVERYGUID,
            };
            this.GetCostRecoveryAPIFun(sendData);
        }

        // if (type != 'matter') {
        //     sendData = { 'URLType': type, CostRecoveryGuid: this.isSerachingData.COSTRECOVERYGUID }
        // } else {
        //     sendData = { "MatterGuid": matterId };
        // }
    }

    /**
     * This function is used to getCostRecoveryAPI Function
     * @param sendData -send data value
     */
    GetCostRecoveryAPIFun(sendData) {
        this.toolbarServiceService.SetOldistrackid("open");
        this.ApiRepoParm1 = sendData;
        const URL =
            sendData.URLTYPE == "GlobalXSearch"
                ? "GlobalX"
                : "GetCostRecoveryURL";
        this._mainAPiServiceService
            .getSetData(sendData, URL)
            .subscribe(
                (OnlineDocument) => {
                    this.toolbarServiceService.SetOldistrackid("close");
                    if (
                        OnlineDocument.CODE == 200 &&
                        OnlineDocument.STATUS == "success"
                    ) {
                        this.isGenrateReport = false;
                        window.open(OnlineDocument.DATA.RETURNURL, "_blank");
                    } else if (OnlineDocument.CODE == 432) {
                        let currentUser: any = JSON.parse(
                            localStorage.getItem("currentUser")
                        );
                        this.isGenrateReport = false;
                //         window.open(
                //             environment.APIEndpoint +
                //             "OAuth2Start?AuthType=NetDrive&SessionToken=" +
                //             currentUser.SessionToken,
                //             "targetWindow",
                //             `toolbar=no,
                //  location=no,
                //  status=no,
                //  menubar=no,
                //  scrollbars=yes,
                //  resizable=yes,
                //  width=800,
                //  height=600`
                //         );
                        let payload = {
                            "Action": "OAuth2Start",
                            "VALIDATEONLY": false,
                            "Data": {
                                "USERGUID": this.currentUser.UserGuid,
                                "AuthType": "NetDrive"
                            }
                        }
                        this._mainAPiServiceService
                        .getSetData(payload, 'oauth-connections')
                        .subscribe((response) => {
                            if(response.CODE == 200 && response.STATUS == "success") {
                                window.open(
                                    response.DATA[0].AuthorizationUrl,
                                    "targetWindow",
                                    `toolbar=no,
                                    location=no,
                                    status=no,
                                    menubar=no,
                                    scrollbars=yes,
                                    resizable=yes,
                                    width=800,
                                    height=600`
                                );
                            }
                        });
                    } else if (OnlineDocument.CODE == 465) {
                        let currentUser: any = JSON.parse(
                            localStorage.getItem("currentUser")
                        );
                        this.isGenrateReport = false;
                        window.open(
                            environment.APIEndpoint +
                            "OAuth2Start?AuthType=Searching&SessionToken=" +
                            currentUser.SessionToken,
                            "targetWindow",
                            `toolbar=no,
                 location=no,
                 status=no,
                 menubar=no,
                 scrollbars=yes,
                 resizable=yes,
                 width=800,
                 height=600`
                        );
                    } else if (OnlineDocument.CODE == 437) {
                        this.isGenrateReport = false;
                        this.confirmDialogRef = this._matDialog.open(
                            FuseConfirmDialogComponent,
                            {
                                disableClose: true,
                                width: "100%",
                                data: {
                                    isSearch: true,
                                    SignupUrl: OnlineDocument.DATA.RETURNURL,
                                },
                            }
                        );
                        this.confirmDialogRef.componentInstance.confirmMessage =
                            "You have not configured the username and password for InfoTrack which is required for it to work.";
                        this.confirmDialogRef.componentInstance.confirmTitle =
                            "InfoTrack Search";
                        this.confirmDialogRef
                            .afterClosed()
                            .subscribe((result) => {
                                if (result) {
                                    this.router.navigate([
                                        "system-setting/search-provider",
                                    ]);
                                }
                                this.confirmDialogRef = null;
                            });
                    } else {
                        this.isGenrateReport = false;
                    }
                },
                (error) => {
                    this.isGenrateReport = false;
                    this.toastr.error(error);
                }
            );
    }

    /**
     * This function is used to PrintTrustCashbook
     */
    PrintTrustCashbook() {
        this.confirmDialogRef2 = this.MatDialog.open(ConfirmPopupComponent, {
            disableClose: true,
            width: "100%",
        });
        this.confirmDialogRef2.componentInstance.confirmMessage =
            "Do you want to Print the Trust Cashbook?";
        this.confirmDialogRef2.componentInstance.confirmTitle =
            "Print Trust Cashbook";
        this.confirmDialogRef2.afterClosed().subscribe((result) => {
            this.PrintTrialBalance();
            if (result) {
                this.ReportGenerate("trustreportscashbook", "Trust Cashbook");
            }
        });
    }

    /**
     * This function is used to print Trial Balance data
     */
    PrintTrialBalance() {
        this.confirmDialogRef2 = this.MatDialog.open(ConfirmPopupComponent, {
            disableClose: true,
            width: "100%",
        });
        this.confirmDialogRef2.componentInstance.confirmMessage =
            "Do you want to Print the Trial Balance?";
        this.confirmDialogRef2.componentInstance.confirmTitle =
            "Print Trial Balance";
        this.confirmDialogRef2.afterClosed().subscribe((result) => {
            this.PrintControlAccount();
            if (result) {
                this.ReportGenerate("trusttrialbalance", "Trial Balance");
            }
        });
    }

    /**
     * This function is used to Print control account
     */
    PrintControlAccount() {
        this.confirmDialogRef2 = this.MatDialog.open(ConfirmPopupComponent, {
            disableClose: true,
            width: "100%",
        });
        this.confirmDialogRef2.componentInstance.confirmMessage =
            "Do you want to Print the Control Account?";
        this.confirmDialogRef2.componentInstance.confirmTitle =
            "Print Control Account";
        this.confirmDialogRef2.afterClosed().subscribe((result) => {
            this.PrintTransferJournal();
            if (result) {
                this.ReportGenerate("trustcontrolaccount", "Control Account");
            }
        });
    }

    /**
     * This function is used to print transfer journal data
     */
    PrintTransferJournal() {
        this.confirmDialogRef2 = this.MatDialog.open(ConfirmPopupComponent, {
            disableClose: true,
            width: "100%",
        });
        this.confirmDialogRef2.componentInstance.confirmMessage =
            "Do you want to Print the Transfer Journal?";
        this.confirmDialogRef2.componentInstance.confirmTitle =
            "Print Transfer Journal";
        this.confirmDialogRef2.afterClosed().subscribe((result) => {
            this.PrintDebitBalance();
            if (result) {
                this.ReportGenerate("trusttransferjournal", "Transfer Journal");
            }
        });
    }

    /**
     * This function is used to print debit Balance 
     */
    PrintDebitBalance() {
        this.confirmDialogRef2 = this.MatDialog.open(ConfirmPopupComponent, {
            disableClose: true,
            width: "100%",
        });
        this.confirmDialogRef2.componentInstance.confirmMessage =
            "Do you want to Print the Debit Balance?";
        this.confirmDialogRef2.componentInstance.confirmTitle =
            "Print Debit Balance";
        this.confirmDialogRef2.afterClosed().subscribe((result) => {
            this.PrintAuditLog();
            if (result) {
                this.ReportGenerate("trustdebitbalance", "Debit Balance");
            }
        });
    }

    /**
     * This function is used to print audit log data value
     */
    PrintAuditLog() {
        this.confirmDialogRef2 = this.MatDialog.open(ConfirmPopupComponent, {
            disableClose: true,
            width: "100%",
        });
        this.confirmDialogRef2.componentInstance.confirmMessage =
            "Do you want to Print the Audit Log?";
        this.confirmDialogRef2.componentInstance.confirmTitle =
            "Print Audit Log";
        this.confirmDialogRef2.afterClosed().subscribe((result) => {
            this.PrintControlledListing();
            if (result) {
                this.ReportGenerate("filemaintenanceaudit", "Audit Log");
            }
        });
    }

    /**
     * This function is used to print controlled listing
     */
    PrintControlledListing() {
        this.confirmDialogRef2 = this.MatDialog.open(ConfirmPopupComponent, {
            disableClose: true,
            width: "100%",
        });
        this.confirmDialogRef2.componentInstance.confirmMessage =
            "Do you want to Print the Controlled Listing?";
        this.confirmDialogRef2.componentInstance.confirmTitle =
            "Print Controlled Listing";
        this.confirmDialogRef2.afterClosed().subscribe((result) => {
            this.PrintControlledCashbook();
            if (result) {
                this.ReportGenerate(
                    "trustreportcontrolledaccountlisting",
                    "Controlled Listing"
                );
            }
        });
    }

    /**
     * This function is used to printcontroled cashbook
     */
    PrintControlledCashbook() {
        this.confirmDialogRef2 = this.MatDialog.open(ConfirmPopupComponent, {
            disableClose: true,
            width: "100%",
        });
        this.confirmDialogRef2.componentInstance.confirmMessage =
            "Do you want to Print the Controlled Cashbook?";
        this.confirmDialogRef2.componentInstance.confirmTitle =
            "Print Controlled Cashbook";
        this.confirmDialogRef2.afterClosed().subscribe((result) => {
            if (result) {
                this.ReportGenerate(
                    "trustreportcontrolledcashbook",
                    "Controlled Cashbook"
                );
            }
        });
    }
    // SetEndOfMonthData(ismultiple) {
    //     this.confirmDialogRef3 = this.MatDialog.open(EndOfMonthReportsComponent, { disableClose: true, width: '100%', data: { ENDOFMONTHGUID: '' } });
    //     this.confirmDialogRef3.afterClosed().subscribe(result => {
    //         if (result) {
    //         }
    //     });
    // }

    /**
     * This function is used to setendofmonth data value.
     * @param ismultiple -ismultiple data value
     */
    SetEndOfMonthData(ismultiple) {
        this.confirmDialogRef2 = this.MatDialog.open(ConfirmPopupComponent, {
            disableClose: true,
            width: "100%",
        });
        this.confirmDialogRef2.componentInstance.confirmMessage =
            "You are about to close the month ending " +
            this.ENDOFMONTHDATE.Date +
            " once you have closed a month you will no longer be able to enter data into that period. Are you sure you want to close the month?";
        this.confirmDialogRef2.componentInstance.confirmTitle = "Close Month";
        this.confirmDialogRef2.afterClosed().subscribe((result) => {
            if (result) {
                this.isGenrateReport = true;
                let sendPostData: any = {
                    CLOSEDBY: this.currentUser.UserName,
                    ENDOFMONTHDATE: this.ENDOFMONTHDATE.Date,
                };
                this._mainAPiServiceService
                    .getSetData(
                        {
                            DATA: sendPostData,
                            VALIDATEONLY: false,
                            FormAction: "insert",
                            UseTrust: true,
                        },
                        "SetTrustEndOfMonth"
                    )
                    .subscribe(
                        (response) => {
                            if (
                                response.CODE == 200 &&
                                response.STATUS == "success"
                            ) {
                                this.toastr.success(
                                    "Close the month successfully"
                                );
                                this.isGenrateReport = false;
                                this.behaviorService.setTrustEndofMonth({
                                    ENDOFMONTHGUID:
                                        response.DATA.TRUSTENDOFMONTHGUID,
                                });
                                // this.PrintTrustCashbook();
                                this.confirmDialogRef3 = this.MatDialog.open(
                                    EndOfMonthReportsComponent,
                                    {
                                        disableClose: true,
                                        width: "100%",
                                        data: {
                                            ENDOFMONTHGUID:
                                                response.DATA
                                                    .TRUSTENDOFMONTHGUID,
                                        },
                                    }
                                );
                                this.confirmDialogRef3
                                    .afterClosed()
                                    .subscribe((result) => {
                                        if (result) {
                                        }
                                    });
                            } else if (
                                response.CODE == 450 &&
                                response.STATUS == "error"
                            ) {
                                let bodyData = response.DATA.VALIDATIONS;
                                let errorData: any = [];
                                let warningData: any = [];
                                bodyData.forEach(function (value: {
                                    VALUEVALID: string;
                                    ERRORDESCRIPTION: any;
                                    FIELDNAME: any;
                                }) {
                                    if (value.VALUEVALID == "No") {
                                        errorData.push(value.ERRORDESCRIPTION);
                                    } else if (value.VALUEVALID == "Warning") {
                                        warningData.push(
                                            value.ERRORDESCRIPTION
                                        );
                                    }
                                });
                                if (Object.keys(errorData).length != 0) {
                                    this.toastr.error(errorData);
                                    this.isGenrateReport = false;
                                }
                                if (Object.keys(warningData).length != 0) {
                                    this.toastr.warning(warningData);
                                    this.isGenrateReport = false;
                                }
                            } else {
                                this.isGenrateReport = false;
                            }
                        },
                        (error) => {
                            this.isGenrateReport = false;
                            this.toastr.error(error);
                        }
                    );
            }
        });
    }

    /**
     * This function is used to processEnd of month data
     * @param Row -row data value
     */
    processEndOfMonthData(Row) {
        if (Row.ITEMTYPE == "1 .Banking") {
            this.behaviorService.ChartAccountData({
                ACCOUNTGUID: Row.ITEMGUID,
            });
            this.behaviorService.TrustDuplicateModuleHandling({
                ClickType: "WithTrust",
                UseTrust: "Yes",
                PopUp: "",
                Lable: "TRUST CHART OF ACCOUNTS",
                ACCOUNTTYPENAME: "Trust Account",
            });
            const dialogRef = this.dialog.open(SelectBankingDialogComponent, {
                disableClose: true,
                panelClass: "ChartAc-dialog",
                data: null,
            });
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    $("#setendofMonth").click();
                }
            });
        } else if (Row.ITEMTYPE == "2. Account Reconciliations") {
            this.behaviorService.ChartAccountData({
                ACCOUNTGUID: Row.ITEMGUID,
                isEOM: true,
            });
            this.behaviorService.RecouncileConstName("");
            localStorage.setItem(
                "ChartAccountData",
                JSON.stringify({
                    name: "",
                    class: "",
                    ACCOUNTGUID: Row.ITEMGUID,
                    LASTSTATEMENTDATE: "",
                    ACCOUNTTYPE: "",
                    index: "",
                    parent: "",
                    level: "",
                })
            );
            this.behaviorService.TrustDuplicateModuleHandling({
                ClickType: "WithTrust",
                UseTrust: "Yes",
                PopUp: "",
                Lable: "TRUST CHART OF ACCOUNTS",
                ACCOUNTTYPENAME: "Trust Account",
            });
            this.router.navigate([
                "/account-reconciliation/reconciliation-item",
            ]);
        } else if (Row.ITEMTYPE == "3. Incomplete Account Details") {
            this.behaviorService.TrustDuplicateModuleHandling({
                ClickType: "WithTrust",
                UseTrust: "Yes",
                PopUp: "",
                Lable: "TRUST CHART OF ACCOUNTS",
                ACCOUNTTYPENAME: "Trust Account",
            });
            this.behaviorService.ChartAccountData({
                ACCOUNTGUID: Row.ITEMGUID,
            });
            const dialogRef = this.dialog.open(ChartAcDailogComponent, {
                disableClose: true,
                panelClass: "ChartAc-dialog",
                data: {
                    action: "edit",
                    UseTrust: "Yes",
                    isDefultAccount: "Controlled Money Account",
                    matterGuid: "",
                },
            });
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    $("#setendofMonth").click();
                }
            });
        } else if (Row.ITEMTYPE == "4. Unprinted Transactions") {
            this.behaviorService.setTrustMoneyList({
                TRUSTTRANSACTIONGUID: Row.ITEMGUID,
            });
            this.ReportGenerate("reporttrustrequest", "Trust Print Item");
        } else if (Row.ITEMTYPE == "5. Trust Account Statements") {
            this.behaviorService.setTrustMoneyList({
                TRUSTTRANSACTIONGUID: Row.ITEMGUID,
            });
            this.ReportGenerate(
                "TrustSingleTrustAccountStatement",
                "Trust Print Item"
            );
        } else if (Row.ITEMTYPE == "6. Controlled Money Statements") {
            this.behaviorService.setTrustMoneyList({
                TRUSTTRANSACTIONGUID: Row.ITEMGUID,
            });
            this.ReportGenerate(
                "TrustSingleControlledAccountStatement",
                "Trust Print Item"
            );
        }
    }
    // processEndOfMonthTagged() {
    //     this.EndOfMonthDataSelected.forEach(element => {
    //         this.processEndOfMonthData(element);
    //     });
    // }

    /**
     * This function is used to processEndOfMonthTagged
     */
    async processEndOfMonthTagged() {
        let endOfMonthDate = "";
        if (this.ENDOFMONTHDATE && this.ENDOFMONTHDATE.Date) {
            endOfMonthDate = this.ENDOFMONTHDATE.Date;
        }
        this.confirmDialogRef3 = this.MatDialog.open(
            EndOfMonthReportsComponent,
            {
                disableClose: true,
                width: "100%",
                data: {
                    isEndOfMonthData: true,
                    ENDOFMONTHDATE: endOfMonthDate,
                    processEndOfMonthData: this.EndOfMonthDataSelected,
                },
            }
        );
        this.confirmDialogRef3.afterClosed().subscribe((result) => {
            if (result) {
                //("close tag modal ");
                this.behaviorService.setEndOfMonthDataSelected({
                    isClear: true,
                });
            }
        });
    }

    /**
     * This function is used to calculate the Intrest rate
     */
    public CalculateInterest() {
        this.toolbarServiceService.SetOldistrackid("open");
        const dialogRef = this.dialog.open(CalculateInterestPopupComponent, {
            width: "100%",
            disableClose: true,
            panelClass: "calculate_interest_main",
            data: {},
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");

            if (result) {
                $("#getmatterinvoice").click();
            }
        });
    }

    /**
     * This function is used to syc to xero
     */
    SyncToXero() {
        let dialogRef: any = this.dialog.open(SyncToXeroPopupComponent, {
            disableClose: true,
            panelClass: "Document-dialog",
            data: {},
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
            }
        });
    }

    /**
     * This function is used to upload the templates
     */
    UploadTemplates() {
        let dialogRef: any = this.dialog.open(UploadTemplatesPopupComponent, {
            disableClose: true,
            panelClass: "Document-dialog",
            data: {},
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
            }
        });
    }

    /**
     * This function is used to Download the templates data
     * @param obj 
     */
    DownloadTemplates(obj) {
        this.isGenrateReport = true;
        let fileName = "";
        let folder = "";
        this.toolbarServiceService.SetOldistrackid("open");
        if (obj === "single") {
            this.confirmDialogRef2 = this.MatDialog.open(
                ConfirmPopupComponent,
                { disableClose: true, width: "100%" }
            );
            this.confirmDialogRef2.componentInstance.confirmMessage =
                "Due to the default behaviour of Word, in order to edit this template, you will need to open Microsoft Word first and then open the template from inside Word.";
            this.confirmDialogRef2.componentInstance.confirmMessage1 =
                "To do this, go to File --> Open --> navigate to your downloads folder and find the template there";
            this.confirmDialogRef2.componentInstance.confirmTitle =
                "Download Templates";
            this.confirmDialogRef2.componentInstance.confirm1 = "Ok";
            this.confirmDialogRef2.componentInstance.confirm2 = "";

            this.confirmDialogRef2.afterClosed().subscribe((result) => {
                this.behaviorService.TemplateGenerateData$.subscribe(
                    (result) => {
                        if (result) {
                            fileName = result.TEMPLATENAME;
                        }
                    }
                );
                this.behaviorService.templateSubFolder$.subscribe((result) => {
                    if (result) {
                        folder = result;
                    } else if (result == "") {
                        folder = result;
                    }
                });
                this.DownloadTemplatesCall(fileName, folder);
            });
        } else {
            this.DownloadTemplatesCall(fileName, folder);
        }
    }

    /**
     * This function is used to download template calls
     * @param fileName -file name value
     * @param folder -folder data value
     */
    DownloadTemplatesCall(fileName: any, folder: any) {
        this._mainAPiServiceService
            .getSetData(
                { FILENAME: fileName, FOLDER: folder },
                "TemplateDownload"
            )
            .subscribe(
                (response) => {
                    this.toolbarServiceService.SetOldistrackid("close");
                    if (
                        response.FileUpload_response.CODE == 200 &&
                        response.FileUpload_response.STATUS === "success"
                    ) {
                        window.open(
                            environment.DocumentsUrl +
                            response.FileUpload_response.DATA.DOWNLOADFILE
                        );
                    } else if (
                        response.FileUpload_response.MESSAGE == "Not logged in"
                    ) {
                    }
                    this.isGenrateReport = false;
                },
                (error) => {
                    this.toolbarServiceService.SetOldistrackid("close");
                    this.toastr.error(error);
                    this.isGenrateReport = false;
                }
            );
    }

    /**
     * This function is used to export the data value
     * @param type 
     */
    exportData(type: any) {
        this.confirmDialogRef2 = this.MatDialog.open(ConfirmPopupComponent, {
            disableClose: true,
            width: "100%",
            data: { is_exportData: true, exportDataType: type },
        });
        if (type == "MYOB") {
            this.confirmDialogRef2.componentInstance.confirmMessage =
                "Are you sure you want to export your accounting data? It will export all data that has not previously been exported into the " +
                this.currentUser.EXPORTACCOUNTSSYSTEM +
                " " +
                this.currentUser.EXPORTACCOUNTSVERSION +
                " format. This process may take a number of minutes and when complete the export files will be emailed to " +
                this.currentUser.EXPORTACCOUNTSEMAIL +
                ".";
        } else {
            this.confirmDialogRef2.componentInstance.confirmMessage =
                "Are you sure you want to export your accounting data? It will export all data that has not previously been exported into the QIF format. This process may take a number of minutes and when complete the export files will be emailed to " +
                this.currentUser.EXPORTACCOUNTSEMAIL +
                ".";
        }
        this.confirmDialogRef2.componentInstance.confirmTitle = type;
        this.confirmDialogRef2.componentInstance.confirm1 = "Export";
        this.confirmDialogRef2.componentInstance.confirm2 = "Cancel";

        this.confirmDialogRef2.afterClosed().subscribe((result) => {
            if (result) {
            }
        });
    }

    /**
     * This function is used to create new folder
     * @param type -folder type value
     */
    createNewFolder(type) {
        if (type == "delete") {
            this.confirmDialogRef = this._matDialog.open(
                FuseConfirmDialogComponent,
                {
                    disableClose: true,
                    width: "100%",
                }
            );
            this.confirmDialogRef.componentInstance.confirmMessage =
                "Are you sure you want to delete?";
            this.confirmDialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.isGenrateReport = true;
                    let folder = localStorage.getItem("Folder");
                    const folderPath = folder
                        ? folder + "/" + this.TemplateGenerateData.TEMPLATENAME
                        : "/" + this.TemplateGenerateData.TEMPLATENAME;
                    let postData = {
                        FormAction: "delete",
                        DATA: { FOLDERNAME: folderPath },
                    };
                    let RefreshIdArray: any = ["#refreshTemplatelistTab"];
                    this.toolbarServiceService.SetVariableForRetry(
                        postData,
                        "TemplateFolder",
                        RefreshIdArray
                    );
                    this._mainAPiServiceService
                        .getSetData(postData, "TemplateFolder")
                        .subscribe((res) => {
                            if (res.STATUS == "success" && res.CODE == 200) {
                                $("#refreshTemplatelistTab").click();
                                this.toastr.success("Deleted successfully");
                            } else if (
                                res.CODE == 451 &&
                                res.STATUS == "warning"
                            ) {
                                this.checkValidation(
                                    res.DATA.VALIDATIONS,
                                    postData,
                                    "TemplateFolder"
                                );
                            } else if (
                                res.CODE == 450 &&
                                res.STATUS == "error"
                            ) {
                                this.checkValidation(
                                    res.DATA.VALIDATIONS,
                                    postData,
                                    "TemplateFolder"
                                );
                            } else if (res.MESSAGE == "Not logged in") {
                                this.dialogRef.close(false);
                            }
                            this.isGenrateReport = false;
                        });
                }
                this.confirmDialogRef = null;
            });
        } else {
            let dialogRef: any = this.dialog.open(FolderCreateComponent, {
                disableClose: true,
                panelClass: "Document-dialog",
                data: { type: type, temlateData: this.TemplateGenerateData },
            });
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    $("#refreshTemplatelistTab").click();
                }
            });
        }
    }

    /**
     * This function is used to moveSearchItem
     */
    moveSearchItem() {
        this.confirmDialogRef2 = this.MatDialog.open(ConfirmPopupComponent, {
            disableClose: true,
            width: "100%",
            data: "",
        });
        this.confirmDialogRef2.componentInstance.confirmMessage =
            "Are you sure you want to move Search Item?";
        this.confirmDialogRef2.componentInstance.confirmTitle =
            "Mover Search Item";
        this.confirmDialogRef2.componentInstance.confirm1 = "Yes";
        this.confirmDialogRef2.componentInstance.confirm2 = "No";

        this.confirmDialogRef2.afterClosed().subscribe((result) => {
            if (result) {
                const dialogRef = this.MatDialog.open(MatterDialogComponent, {
                    width: "100%",
                    disableClose: true,
                    data: {},
                });
                dialogRef.afterClosed().subscribe((result) => {
                    let selectDetailToMove: any = {};
                    if (result) {
                        let recovery_data: any = [];
                        let costRecoveryData: any;
                        this.destoryCurrentState =
                            this.behaviorService.collectSelectedRecovery$.subscribe(
                                (res) => {
                                    if (res.type == "multi") {
                                        res.data.forEach((element) => {
                                            costRecoveryData = {
                                                MATTERGUID: result.MATTERGUID,
                                                CostRecoveryGuid:
                                                    element.COSTRECOVERYGUID,
                                            };
                                            recovery_data.push(
                                                costRecoveryData
                                            );
                                        });
                                    }

                                    if (
                                        recovery_data.length < 1 &&
                                        res.type == "single"
                                    ) {
                                        costRecoveryData = {
                                            MATTERGUID: result.MATTERGUID,
                                            CostRecoveryGuid:
                                                res.data[0].COSTRECOVERYGUID,
                                        };
                                        recovery_data.push(costRecoveryData);
                                    }

                                    selectDetailToMove.FormAction =
                                        "movematter";
                                    selectDetailToMove.VALIDATEONLY = false;
                                    selectDetailToMove.data =
                                        recovery_data.length > 1
                                            ? recovery_data
                                            : recovery_data[0];
                                    selectDetailToMove.MultiRecord =
                                        recovery_data.length > 1 ? true : false;
                                }
                            );

                        this._mainAPiServiceService
                            .getSetData(selectDetailToMove, "SetCostRecovery")
                            .subscribe((response) => {
                                if (
                                    response.CODE == 200 &&
                                    response.STATUS == "success"
                                ) {
                                    //("response data :" , response);
                                    this.behaviorService.refreshSearch$.next(
                                        true
                                    );
                                    this.toastr.success(
                                        "Successfully moved search Item."
                                    );
                                } else if (
                                    response.CODE == 450 &&
                                    response.STATUS == "error"
                                ) {
                                    let resData =
                                        response.DATA.VALIDATIONS.filter(
                                            (e) =>
                                                e.VALUEVALID == "No" || "NO" || "no"
                                        );
                                    let errorMess =
                                        resData.length !== 0
                                            ? resData[0].ERRORDESCRIPTION
                                            : "Server Error";
                                    this.toastr.error(errorMess);
                                    this.behaviorService.refreshSearch$.next(
                                        true
                                    );
                                }
                            });
                    }
                    // localStorage.setItem('set_active_matters', JSON.stringify(result));
                });
            }
        });
    }

    /**
     * This function is uysed to open the desktop word file
     */
    OpenInDesktopWord() {
        // FileType: "MATTERFOLDER",
      let  sendData =
        {
            Action: 'GetDocument',
            Data: {
                FileType: "DOCUMENT",
                MATTERGUID: this.DocRegData.MATTERGUID,
                DocumentName: this.DocRegData.DOCUMENTNAME,
            }
        }
        this._mainAPiServiceService
            .getSetData(sendData, "cloud-storage")
            .subscribe(
                (response) => {
                    if (response.CODE == 200 && response.STATUS === "success") {
                        // response.DATA.DOCUMENTS[0].DRIVEFILEDESKTOPURL
                        window.open(
                            response.DATA.DOCUMENTS[0].DRIVEFILEDESKTOPURL
                        );
                    } else if (response.MESSAGE == "Not logged in") {
                    }
                    this.isGenrateReport = false;
                },
                (error) => {
                    this.toastr.error(error);
                    this.isGenrateReport = false;
                }
            );
    }

    /**
     * This function is used to compose the email data
     * @param val -value data
     */
    composeEmail(val) {
        if (val === "withoutTemplate") {
            this.behaviorService.MatterPopupStatus$.subscribe((data) => {
                this.ShowMatterPopupFlag = data;
            });
            if (this.ShowMatterPopupFlag == true) {
                const dialogRef1 = this.MatDialog.open(MatterDialogComponent, {
                    width: "100%",
                    disableClose: true,
                    data: null,
                });
                dialogRef1.afterClosed().subscribe((result) => {
                    if (result) {
                        // var matterselected:any
                        const dialogRef = this._matDialog.open(
                            SendEmailComponent,
                            {
                                disableClose: true,
                                panelClass: "",
                                data: { data: "", type: "new" },
                            }
                        );
                        dialogRef.afterClosed().subscribe((result) => {
                            if (result) {
                                dialogRef.close();
                            }
                        });
                    }
                });
            } else {
                const dialogRef = this._matDialog.open(SendEmailComponent, {
                    disableClose: true,
                    panelClass: "",
                    data: { data: "", type: "new" },
                });
                dialogRef.afterClosed().subscribe((result) => {
                    if (result) {
                        dialogRef.close();
                    }
                });
            }
        } else {
            const dialogRef = this.MatDialog.open(MatterDialogComponent, {
                width: "100%",
                disableClose: true,
                data: null,
            });
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.windowNameId = this.windowNameId ? this.windowNameId : 'set_active_matters'
                    localStorage.setItem(
                        this.windowNameId,
                        JSON.stringify(result)
                    );
                    const dialogRef = this._matDialog.open(
                        SelectEmailPopupComponent,
                        { width: "100%", disableClose: true }
                    );
                    dialogRef.afterClosed().subscribe((result) => {
                        if (result) {
                            // this.behaviorService.EmailGenerateData(result);
                            this.GenarateEmail("inboxWithTemplate");
                            // const dialogRef1 = this._matDialog.open(ContactSelectDialogComponent, { width: '100%', disableClose: true, data: { type: "", } });
                            // dialogRef1.afterClosed().subscribe(result => {
                            //     if (result) {
                            //         let passdata = { 'Context': "Contact", 'ContextGuid': result.CONTACTGUID, "knownby": "Email", "Type": "Email", "Folder": '', "Template": EmailtemplateData.NAME, emailType: 'composeInboxMail' }
                            //         this.ForEmailDialogOpen(passdata);
                            //     }
                            // });
                        }
                    });
                }
            });
        }
    }

    /**
     * This function is used to open the signature dialog
     */
    signaturDialog() {
        const dialogRef = this._matDialog.open(SignaturesPopupComponent, {
            disableClose: true,
            panelClass: "",
            data: { data: "" },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                dialogRef.close();
            }
        });
    }

    /**
     * This function is used to save Multi Emails.
     */
    saveMultiEmails() {
        this.behaviorService.MatterPopupStatus$.subscribe((data) => {
            this.ShowMatterPopupFlag = data;
        });
        if (this.ShowMatterPopupFlag == true) {
            const dialogRef = this.MatDialog.open(MatterDialogComponent, {
                width: "100%",
                disableClose: true,
                data: null,
            });
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    const dialogRef2 = this.MatDialog.open(
                        RenameEmailComponent,
                        {
                            width: "100%",
                            disableClose: true,
                            data: {
                                response: "",
                                attachments: [],
                                mail: this.selectedEmailList,
                                isMultiEmails: true,
                            },
                        }
                    );
                    dialogRef2.afterClosed().subscribe((result2) => {
                        if (result2) {
                        }
                    });
                }
            });
        } else {
            const dialogRef2 = this.MatDialog.open(RenameEmailComponent, {
                width: "100%",
                disableClose: true,
                data: {
                    response: "",
                    attachments: [],
                    mail: this.selectedEmailList,
                    isMultiEmails: true,
                },
            });
            dialogRef2.afterClosed().subscribe((result2) => {
                if (result2) {
                }
            });
        }
    }

    /**
     * This function is used to Print the Bankin slip.
     */
    printBankingSlip() {
        this.isGenrateReport = true;
        let data: any = {
            ACCOUNTGUID: this.PrintBankingSlipVal.ACCOUNTGUID,
            UseTrust: this.PrintBankingSlipVal.userIsTrust,
            BANKINGSLIPID: this.PrintBankingSlipVal.BANKINGSLIPID,
        };
        this._mainAPiServiceService
            .getSetData(data, "PrintBankingSlip")
            .subscribe(
                (response) => {
                    if (response.CODE == 200 && response.STATUS === "success") {
                        window.open(
                            environment.ReportUrl + response.DATA.PDFFILENAME
                        );
                    } else if (response.MESSAGE == "Not logged in") {
                    }
                    this.isGenrateReport = false;
                },
                (error) => {
                    this.toastr.error(error);
                    this.isGenrateReport = false;
                }
            );
    }

    /**
     * This function is used to get the estate Assets
     * @param actionType -action type value
     */
    estateAssets(actionType) {
        localStorage.setItem("istrackid", "estateAssets");
        if (actionType == "new") {
            // const dialogRef = this._matDialog.open(ContactSelectDialogComponent, { width: '100%', disableClose: true, data: { type: null } });
            // dialogRef.afterClosed().subscribe(result => {
            //     this.toolbarServiceService.SetOldistrackid('close');
            //     if (result) {

            const dialogRef = this.dialog.open(EstateAssetsComponent, {
                disableClose: true,
                width: "100%",
                data: { action: actionType, result: "" },
            });

            //     }
            // });
        } else if (actionType == "edit") {
            const dialogRef = this.dialog.open(EstateAssetsComponent, {
                disableClose: true,
                width: "100%",
                data: { action: actionType },
            });
            dialogRef.afterClosed().subscribe((result) => {
                this.toolbarServiceService.SetOldistrackid("close");
                if (result) {
                    // $("#mainsafecusday").click();
                }
            });
        } else if (actionType == "delete") {
            let estateAssets;
            this.behaviorService.estateAssets$.subscribe((result) => {
                estateAssets = result;
            });
            this.confirmDialogRef = this._matDialog.open(
                FuseConfirmDialogComponent,
                { disableClose: true, width: "100%" }
            );
            this.confirmDialogRef.componentInstance.confirmMessage =
                "Are you sure you want to delete?";
            this.confirmDialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    // this.DairyData.DairyRowClickData
                    let postData = {
                        Action: "delete",
                        data: { ESTATEASSETGUID: estateAssets.ESTATEASSETGUID },
                    };
                    let RefreshIdArray: any = ["#getEstateAssets"];
                    this.toolbarServiceService.SetVariableForRetry(
                        postData,
                        "estate-asset",
                        RefreshIdArray
                    );
                    this._mainAPiServiceService
                        .getSetData(postData, "estate-asset")
                        .subscribe((res) => {
                            if (res.STATUS == "success") {
                                localStorage.setItem(
                                    "istrackid",
                                    this.oldIsTrackId
                                );
                                $("#getEstateAssets").click();
                                this.toastr.success(res.STATUS);
                            } else if (
                                res.CODE == 451 &&
                                res.STATUS == "warning"
                            ) {
                                this.checkValidation(
                                    res.DATA.VALIDATIONS,
                                    postData,
                                    "estate-asset"
                                );
                            } else if (
                                res.CODE == 450 &&
                                res.STATUS == "error"
                            ) {
                                this.checkValidation(
                                    res.DATA.VALIDATIONS,
                                    postData,
                                    "estate-asset"
                                );
                            } else if (res.MESSAGE == "Not logged in") {
                                this.dialogRef.close(false);
                            } else {
                                this.toastr.error("You Can't Delete");
                            }
                        });
                }
                this.confirmDialogRef = null;
            });
        }
    }

    /**
     * This function is used to get the estate liability value
     * @param actionType -action type value
     */
    estateLiability(actionType) {
        localStorage.setItem("istrackid", "estateLiability");
        if (actionType == "new") {
            // const dialogRef = this._matDialog.open(ContactSelectDialogComponent, { width: '100%', disableClose: true, data: { type: null } });
            // dialogRef.afterClosed().subscribe(result => {
            //     this.toolbarServiceService.SetOldistrackid('close');
            //     if (result) {

            const dialogRef = this.dialog.open(EstateLiabilityComponent, {
                disableClose: true,
                width: "100%",
                data: { action: actionType, result: "" },
            });
            // dialogRef.afterClosed().subscribe(result => {
            //     if (result) {
            //         // $("#mainsafecusday").click();
            //     }
            // })
            //     }
            // });
        } else if (actionType == "edit") {
            const dialogRef = this.dialog.open(EstateLiabilityComponent, {
                disableClose: true,
                width: "100%",
                data: { action: actionType },
            });
            dialogRef.afterClosed().subscribe((result) => {
                this.toolbarServiceService.SetOldistrackid("close");
                if (result) {
                    // $("#mainsafecusday").click();
                }
            });
        } else if (actionType == "delete") {
            let estateLiability;
            this.behaviorService.estateliability$
                .subscribe((result) => {
                    estateLiability = result;
                });
            this.confirmDialogRef = this._matDialog.open(
                FuseConfirmDialogComponent,
                { disableClose: true, width: "100%" }
            );
            this.confirmDialogRef.componentInstance.confirmMessage =
                "Are you sure you want to delete?";
            this.confirmDialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    // this.DairyData.DairyRowClickData
                    let postData = {
                        Action: "delete",
                        data: {
                            ESTATELIABILITYGUID:
                                estateLiability.ESTATELIABILITYGUID,
                        },
                    };
                    let RefreshIdArray: any = ["#geEstatetLiability"];
                    this.toolbarServiceService.SetVariableForRetry(
                        postData,
                        "estate-liability",
                        RefreshIdArray
                    );
                    this._mainAPiServiceService
                        .getSetData(postData, "estate-liability")
                        .subscribe((res) => {
                            if (res.STATUS == "success") {
                                localStorage.setItem(
                                    "istrackid",
                                    this.oldIsTrackId
                                );
                                $("#getEstateLiability").click();
                                this.toastr.success(res.STATUS);
                            } else if (
                                res.CODE == 451 &&
                                res.STATUS == "warning"
                            ) {
                                this.checkValidation(
                                    res.DATA.VALIDATIONS,
                                    postData,
                                    "estate-liability"
                                );
                            } else if (
                                res.CODE == 450 &&
                                res.STATUS == "error"
                            ) {
                                this.checkValidation(
                                    res.DATA.VALIDATIONS,
                                    postData,
                                    "estate-liability"
                                );
                            } else if (res.MESSAGE == "Not logged in") {
                                this.dialogRef.close(false);
                            } else {
                                this.toastr.error("You Can't Delete");
                            }
                        });
                }
                this.confirmDialogRef = null;
            });
        }
    }

    /**
     * This function is used to get the estateBenificeary data
     * @param actionType -action type data value
     */
    estateBeneficiary(actionType) {
        localStorage.setItem("istrackid", "estateBeneficiary");
        if (actionType == "new") {
            // const dialogRef = this._matDialog.open(ContactSelectDialogComponent, { width: '100%', disableClose: true, data: { type: null } });
            // dialogRef.afterClosed().subscribe(result => {
            //     this.toolbarServiceService.SetOldistrackid('close');
            //     if (result) {

            const dialogRef = this.dialog.open(EstateBeneficiaryComponent, {
                disableClose: true,
                width: "100%",
                data: { action: actionType, result: "" },
            });
            //     }
            // });
        } else if (actionType == "edit") {
            const dialogRef = this.dialog.open(EstateBeneficiaryComponent, {
                disableClose: true,
                width: "100%",
                data: { action: actionType },
            });
            dialogRef.afterClosed().subscribe((result) => {
                this.toolbarServiceService.SetOldistrackid("close");
                if (result) {
                    // $("#mainsafecusday").click();
                }
            });
        } else if (actionType == "delete") {
            let estateBeneficiary;
            this.behaviorService.estateBeneficiary$
                .subscribe((result) => {
                    estateBeneficiary = result;
                });
            this.confirmDialogRef = this._matDialog.open(
                FuseConfirmDialogComponent,
                { disableClose: true, width: "100%" }
            );
            this.confirmDialogRef.componentInstance.confirmMessage =
                "Are you sure you want to delete?";
            this.confirmDialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    // this.DairyData.DairyRowClickData
                    let postData = {
                        Action: "delete",
                        data: {
                            ESTATEBENEFICIARYGUID:
                                estateBeneficiary.ESTATEBENEFICIARYGUID,
                        },
                    };
                    let RefreshIdArray: any = ["#getEstateBeneficiary"];
                    this.toolbarServiceService.SetVariableForRetry(
                        postData,
                        "estate-beneficiary",
                        RefreshIdArray
                    );
                    this._mainAPiServiceService
                        .getSetData(postData, "estate-beneficiary")
                        .subscribe((res) => {
                            if (res.STATUS == "success") {
                                localStorage.setItem(
                                    "istrackid",
                                    this.oldIsTrackId
                                );
                                $("#getEstateBeneficiary").click();
                                this.toastr.success(res.STATUS);
                            } else if (
                                res.CODE == 451 &&
                                res.STATUS == "warning"
                            ) {
                                this.checkValidation(
                                    res.DATA.VALIDATIONS,
                                    postData,
                                    "estate-beneficiary"
                                );
                            } else if (
                                res.CODE == 450 &&
                                res.STATUS == "error"
                            ) {
                                this.checkValidation(
                                    res.DATA.VALIDATIONS,
                                    postData,
                                    "estate-beneficiary"
                                );
                            } else if (res.MESSAGE == "Not logged in") {
                                this.dialogRef.close(false);
                            } else {
                                this.toastr.error("You Can't Delete");
                            }
                        });
                }
                this.confirmDialogRef = null;
            });
        }
    }

    /**
     * This function is used to get the estate details data
     */
    estateDetails() {
        const dialogRef = this.dialog.open(EstateDetailsComponent, {
            disableClose: true,
            width: "100%",
        });
    }
    // toolbarServiceService.SetVariableForRetry(PostData: any, Methode: any, RefreshArray: any) {
    //     this.oldIsTrackId = localStorage.getItem('istrackid');
    //     this.DeletePostData = PostData;
    //     this.ApiMethod = Methode;
    //     this.refreshId = RefreshArray;
    //     localStorage.setItem('istrackid', 'DeleteDataClick');
    // }
    // set previous istrckid
    // toolbarServiceService.SetOldistrackid(type) {
    //     if (type === 'open') {
    //         this.oldIsTrackId = localStorage.getItem('istrackid');
    //     } else {
    //         localStorage.setItem('istrackid', this.oldIsTrackId);
    //     }
    // }

    /**
     * This function is used to delete the retry the call
     * @param data -data value
     * @param method -method data
     * @param refresh -refresh flag value
     * @param oldIsTrackId -track id data
     */
    DeleteRetryCall(data: any, method: any, refresh: any, oldIsTrackId: any) {
        this._mainAPiServiceService
            .getSetData(data, method)
            .subscribe((res) => {
                if (res.STATUS == "success" && res.CODE == 200) {
                    localStorage.setItem("istrackid", oldIsTrackId);
                    refresh.forEach((element) => {
                        $(element).click();
                    });

                    this.toastr.success("Deleted successfully");
                }
            });
    }

   /**
    * This function is used to get the permission data
    */
    getPermission(permission) {
        if (this.accessTierPermission) {
            return this.accessTierPermission.filter(
                (e) => e.FEATURENAME == permission
            )[0];
        } else {
            return "";
        }
    }

    /**
     * This function is used to go to URL.
     * @param url 
     */
    goTo(url) {
        if (url) {
            this.openWebsite = this.sanitizer.bypassSecurityTrustResourceUrl(
                url.replace("http://", "https://")
            );
        } else {
            this.openWebsite = undefined;
        }

        if (this.openWebsite) {
            window.open(
                this.openWebsite.changingThisBreaksApplicationSecurity,
                "_blank"
            );
        }
    }

    /**
     * This function is used to go to quick time entry
     */
    quickTimeEntrygoTo() {
        const pipe = this.tierpermission.transform("Split Time Entries");
        if (pipe.ALLOWED !== 1) {
            this.goTo(pipe.URL);
        }
    }

    /**
     * This function is used to Move to another Matter
     */
    MovetoAnotherMatter() {
        const dialogRef = this.MatDialog.open(MatterDialogComponent, {
            width: "100%",
            disableClose: true,
            data: null,
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.behaviorService.timeEntryes$.next(result);
            }
        });
    }

    /**
     * This function is used to go firm directory 
     */
    gotoFirmDirectory() {
        this.hidematterTab = false;
        setTimeout(() => {
            this.selectedIndex = 0;
        }, 1200);
    }

   /**
    * This function is used to User activity click 
    */
    UserActivityClick() {
        // this.router.navigate(["/users/useractivity"]);

        this.behaviorService.loadingUserMenuResult(true);
        // this.location.replaceState("/users/useractivity");
        this.location.replaceState("/system-setting/users/useractivity");
        this.selUserMenuButton = "UserActivity";

        setTimeout(() => {
            this.behaviorService.setUserMenuName("userActivity");
        }, 10);
        this.cd.detectChanges();
        this.isUsersUserActivity = true;
    }

    /**
     * This function is used to go to loged on user data
     */
    loggedOnUser() {
        // this.router.navigate(["/users/loggedon-user"]);
        this.behaviorService.loadingUserMenuResult(true);

        // this.location.replaceState("/users/loggedon-user");
        this.location.replaceState("/users/loggedon-user");
        this.selUserMenuButton = "LoggedOnUser";
        setTimeout(() => {
            this.behaviorService.setUserMenuName("loggedOnUser");
        }, 10);
        this.cd.detectChanges();
    }

    /**
     * This function is used to go to back user
     */
    backToUser() {
        // this.router.navigate(["/users"]);
        // this.behaviorService.loadingUserMenuResult(true);

        // this.location.replaceState("/users");
        this.location.replaceState("/system-setting/users");
        this.selUserMenuButton = "Users";
        setTimeout(() => {
            this.behaviorService.setUserMenuName(null);
        }, 10);
        this.cd.detectChanges();
        this.isTabShow = 12;
    }

    /**
     * This function is used to open the user loged on user dialog popup value
     * @param actionType -action type value
     */
    loggedOnUserDialog(actionType) {
        const dialogRef = this.dialog.open(LoggedonUserComponent, {
            disableClose: true,
            panelClass: "User-Loggedon-dialog",
        });
        dialogRef.afterClosed().subscribe((result) => { });
    }

    /**
     * This function is used to open the trust pop up window.
     * @param actionType -action type
     */
    OpenTrustPopUp(actionType) {
        if (actionType == "update") {
            this.behaviorService.ProtectedTrustList$.subscribe((result) => {
                if (result) {
                    this.ProtectedTrustList = result;
                }
            });
        }
        const dialogRef = this.dialog.open(ProtectedTrustDialogComponent, {
            width: "100%",
            disableClose: true,
            data: {
                action: actionType,
                EditId:
                    actionType == "update"
                        ? this.ProtectedTrustList.PROTECTEDTRUSTGUID
                        : "",
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
            }
        });
    }

    /**
     * This function is used to delete protected trust window
     * @param action -action data
     */
    deleteProtectedTrust(action: any) {
        if (action == "DELETE") {
            this.behaviorService.ProtectedTrustList$.subscribe((result) => {
                if (result) {
                    this.ProtectedTrustList = result;
                }
            });
        }

        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: true,
                width: "100%",
            }
        );

        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to delete?";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let postData = {
                    ACTION: "delete",
                    DATA: {
                        PROTECTEDTRUSTGUID:
                            this.ProtectedTrustList.PROTECTEDTRUSTGUID,
                    },
                };
                this._mainAPiServiceService
                    .getSetData(postData, "trust-protected")
                    .subscribe((res) => {
                        if (res.STATUS == "success" && res.CODE == 200) {
                            localStorage.setItem(
                                "istrackid",
                                this.oldIsTrackId
                            );
                            this.toastr.success("Deleted successfully");
                            setTimeout(() => {
                                this.behaviorService.CallProtectedTrustApi$.next(
                                    true
                                );
                            }, 600);
                        } else if (res.CODE == 451 && res.STATUS == "warning") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "trust-protected"
                            );
                        } else if (res.CODE == 450 && res.STATUS == "error") {
                            this.checkValidation(
                                res.DATA.VALIDATIONS,
                                postData,
                                "trust-protected"
                            );
                        } else if (res.MESSAGE == "Not logged in") {
                            this.dialogRef.close(false);
                        }
                    });
            }
            this.confirmDialogRef = null;
        });
    }

    // ends here ~ navigate user

    // change date format
    changeformat(dates) {
        var d = dates;
        var datess = d.split("/").reverse().join("/");
        var year = datess.substring(0, 4);
        var month = datess.substring(5, 7);
        var day = datess.substring(8, 10);
        return month + "/" + day + "/" + year;
    }

    /**
     * This function is used to convert the time data
     * @param time -time data value
     * @returns -data value
     */
    convertTime12to24(time) {
        var hours = parseInt(time.substr(0, 2));
        if (
            (time.indexOf("am") != -1 || time.indexOf("AM") != -1) &&
            hours == 12
        ) {
            time = time.replace("12", "0");
        }
        if (
            (time.indexOf("pm") != -1 || time.indexOf("PM") != -1) &&
            hours < 12
        ) {
            time = time.replace(hours, hours + 12);
        }
        return time.replace(/(am|pm|AM|PM)/, "");
    }

    /**
     * This function is used to convert the time value
     * @param time -time data
     * @returns -data
     */
    tConvert(time: any) {
        if (time) {
            const [sHours, minutes] = time
                .match(/([0-9]{1,2}):([0-9]{2})/)
                .slice(1);
            const period = +sHours < 12 ? "AM" : "PM";
            const hours = +sHours % 12 || 12;
            return `${hours}:${minutes} ${period}`;
        }
        return `09:00 AM`;
    }

    /**
     * This function is used to get the date Format
     * @param d -data value
     * @returns data
     */
    dateformat(d) {
        let date = new Date(d);
        return date.toISOString();
    }
    // ends here ~ change date format

    /**
     * This function is used to add data value
     * @param data -data value
     * @returns -data
     */
    addData(data: any) {
        this.gloableWindowName = data;
        const matterId = window.name.split("_");
        this.windowNameId = matterId && matterId[1] ? matterId[1] : undefined;
        this.selectedMatterTab = JSON.parse(
            localStorage.getItem(
                this.windowNameId ? this.windowNameId : "set_active_matters"
            )
        );

        if (data.name == "New Task") {
            this.TaskDialoge("new matter");
            return;
        } else if (data.name == "Matter receipt") {
            this.toolbarServiceService.createReceipt("select_matter");
            return;
        } else if (data.name == "Instant invoice") {
            this.toolbarServiceService.createInstantInvoice(true);
            return;
        } else if (data.name == "New Matter") {
            this.toolbarServiceService.matterpopup(
                "new",
                this.selectedMatterTab
            );
            return;
        } else if (data.name == "New Contact") {
            this.toolbarServiceService.ContactsDialog("new");
            return;
        } else if (data.name == "New Spend Money") {
            this.toolbarServiceService.spendmoneypopup(
                "new",
                "",
                null,
                null,
                this.selectedMatterTab
            );
            return;
        }
        // else if(data.name == 'New Time Entry'){
        //     this.toolbarServiceService.addNewTimeEntryNew('TRUE');
        //     return
        // }
        // else if(data.name == 'New File Note'){
        //     this.toolbarServiceService.FileNotePopup('new')
        //     return
        // }

        let UserMatterPopupPermisstion = localStorage.getItem(
            "MatterWindow_Open_Status"
        );
        if (
            UserMatterPopupPermisstion == "true" &&
            data.name != "New Time Entry"
        ) {
            const dialogRef = this.MatDialog.open(MatterDialogComponent, {
                width: "100%",
                disableClose: true,
                data: null,
            });
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    // localStorage.setItem('set_active_matters', JSON.stringify(result));
                    this.setGlobalMenu(data, result);
                } else {
                    this.toolbarServiceService.SetOldistrackid("close");
                }
            });
        } else {
            this.setGlobalMenu(data, this.selectedMatterTab);
        }
    }

    /**
     * This function is used to Set the gloable menu data
     * @param data -data value 
     * @param result -result value
     */
    setGlobalMenu(data: any, result: any) {
        if (data.name == "New File Note") {
            this.toolbarServiceService.FileNotePopup("new", result);
        } else if (data.name == "New Time Entry") {
            this.toolbarServiceService.addNewTimeEntryNew("TRUE", result);
        } else if (data.name == "New Activity") {
            this.ActivityDialog("new", "Activities", result);
        } else if (data.name == "New Sundry") {
            this.ActivityDialog("new", "Sundries", result);
            // }else if(data.name == 'New Spend Money'){
            //     this.toolbarServiceService.spendmoneypopup('new' , '' , null , null , result)
        } else if (data.name == "New General income") {
            this.NewGeneralReceipt("new", result);
        } else if (data.name == "New Safe custody") {
            this.OpenNewSafeCustody("new client", result);
        } else if (data.name == "New Appointment") {
            this.DiaryAppointment("new", "matter-details-new", result);
        } else if ((data.name == "New Itemised invoice", result)) {
            this.createGlobalInvoice("", result);
        }
    }

    /**
     * This function is used to close year Account WIP 
     * @param type -type data
     */
    CloseYearAccountsWIP(type: any) {
        const pipe = this.tierpermission.transform("Close Year Accounts");
        if (pipe.ALLOWED !== 1) {
            this.goTo(pipe.URL);
        } else {
            this.toolbarServiceService.SetOldistrackid("open");

            const dialogRef = this.dialog.open(CutofdateComponent, {
                disableClose: true,
                width: "100%",
                data: {},
            });
            dialogRef.afterClosed().subscribe((result) => {
                this.toolbarServiceService.SetOldistrackid("close");
            });
        }
    }

    /**
     * This function is used to get Details Form Customer data
     */
    getDetailsFormCustomer() {
        const dialogRef = this._matDialog.open(
            FirstTimeLoginBasicInformationComponent,
            { width: "100%", disableClose: true, data: null }
        );
        dialogRef.afterClosed().subscribe((result) => {
            this.toolbarServiceService.SetOldistrackid("close");
            if (result) {
            }
        });
    }

    /**
     * This function is used to setupGuidOpenToggle.
     * @param key -key data
     */
    SetUpGuideOpenToggle(key: string): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * This function is used to set the thempanel
     * @param val -Thim panel value
     * @param event -event data value
     * @param data -data value
     */
    themePanel(val, event?: any, data?: any) {
        let preferenceObjectData = JSON.parse(
            localStorage.getItem("currentUser")
        );
        if (data == "preferenceComponent") {
            this.USEV11UIValue = event;
        } else if (data == "toolbarUSEV11UI") {
            this.USEV11UIValue = event.checked;
        }
        if (val == "USEV11UI" && data == "toolbarUSEV11UI") {
            this.dialogRef = this._matDialog.open(LogoutErrorComponent, {
                disableClose: true,
                width: "100%",
                data: {
                    MESSAGE:
                        "You need to logout of SILQ and close all SILQ tabs. Then log back in to access the selected UI. <br/><br/> Are you sure you want to change the UI?",
                    USEV11UI: true,
                },
            });

            this.dialogRef.afterClosed().subscribe((result) => {
                if (!result) {
                    preferenceObjectData.USEV11UI =
                        !preferenceObjectData.USEV11UI;
                    this.USEV11UIValue = !preferenceObjectData.USEV11UI;
                    localStorage.setItem(
                        "currentUser",
                        JSON.stringify(preferenceObjectData)
                    );
                    return;
                } else {
                    preferenceObjectData.USEV11UI = this.USEV11UIValue;
                    localStorage.setItem(
                        "currentUser",
                        JSON.stringify(preferenceObjectData)
                    );
                }
                this.behaviorService.GetPreferences$.next(preferenceObjectData);
                this.preferenceSave();
            });
        }
    }

    /**
     * This function is used to save the preferences data.
     */
    preferenceSave() {
        this.isspiner = true;
        let details = {
            FormAction: "update",
            VALIDATEONLY: false,
            Data: this.preferenceObjectData,
        };
        this._mainAPiServiceService
            .getSetData(details, "preference")
            .subscribe(
                (response) => {
                    if (
                        response.CODE == 200 &&
                        (response.STATUS == "OK" ||
                            response.STATUS == "success")
                    ) {
                        localStorage.setItem(
                            "currentUser",
                            JSON.stringify(this.preferenceObjectData)
                        );

                        this.toastr.success(" update successfully");
                    } else if (
                        response.CODE == 451 &&
                        response.STATUS == "warning"
                    ) {
                        this.isspiner = false;
                        this.toastr.warning(response.MESSAGE);
                    } else if (
                        response.CODE == 450 &&
                        response.STATUS == "error"
                    ) {
                        this.isspiner = false;
                        this.toastr.error(response.MESSAGE);
                    } else if (response.MESSAGE == "Not logged in") {
                        // this.dialogRef.close(false);
                    }
                },
                (error) => {
                    this.isspiner = false;
                    this.toastr.error(error);
                }
            );
    }
    /**
     * This function is avoid the multiple time load the DOM elemnts.
     * @param index->Use to pass the unique identity to function.
     *  */

    trackByFn(index: number): number {
        return index; // or item.id
    }

    /**
     * This function is used to get the bulk invoices
     */
    CallingBulInvoices(data){
      // $("#BulkInvoices").click();
       this.behaviorService.bulkFlag$.next(data)
    }

    /**
     * This function is used to Shoe the Default Mail Tab
     * @param flag -flag value
     */
    SendEmail(flag):void{
     this.behaviorService.showMails$.next(flag);
    }



 /**
     * This function is used to open the banking dialog data.
     * @param type -name of the type
     * @param clickType -type of the click
     * @param evt -value of the event
     * @returns 
     */
 BankingDialogOpenonly(type: any) {
    const dialogRef = this.MatDialog.open(UnpaidAccountComponent, { disableClose: true, width: "100%", data:'' });
    dialogRef.afterClosed().subscribe((result) => {
       if(result == true){
        $("#BatchPayment").click();
       }

    });
}


 /**
     * This function is used to delete protected trust window
     * @param action -action data
     */
 deleteBatchPayment(action: any) {
    if (action == "DELETE") {
        this.behaviorService.BatchPaymentData$.subscribe((result) => {
            if (result) {
                this.BatchPaymentData = result;
            }
        });
    }

    this.confirmDialogRef = this._matDialog.open(
        FuseConfirmDialogComponent,
        {
            disableClose: true,
            width: "100%",
        }
    );

    this.confirmDialogRef.componentInstance.confirmMessage =
        "Are you sure you want to delete?";
    this.confirmDialogRef.afterClosed().subscribe((result) => {
        if (result) {
            let postData = {
                ACTION: "delete",
                DATA: {
                    "EXPENDITUREBATCHGUID": this.BatchPaymentData.EXPENDITUREBATCHGUID
                },
            };
            this._mainAPiServiceService
                .getSetData(postData, "expenditure-batch")
                .subscribe((res) => {
                    if (res.STATUS == "success" && res.CODE == 200) {
                        localStorage.setItem(
                            "istrackid",
                            this.oldIsTrackId
                        );
                        this.toastr.success("Deleted successfully");
                            $("#BatchPayment").click();
                    } else if (res.CODE == 451 && res.STATUS == "warning") {
                        this.checkValidation(
                            res.DATA.VALIDATIONS,
                            postData,
                            "expenditure-batch"
                        );
                    } else if (res.CODE == 450 && res.STATUS == "error") {
                        this.checkValidation(
                            res.DATA.VALIDATIONS,
                            postData,
                            "expenditure-batch"
                        );
                    } else if (res.MESSAGE == "Not logged in") {
                        this.dialogRef.close(false);
                    }
                });
        }
        this.confirmDialogRef = null;
    });
}

DownloadBatchPayment(){
    this.isDownloadABAFile=true;

    this.behaviorService.BatchPaymentData$.subscribe((result) => {
        if (result) {
            this.BatchPaymentData = result;
        }
    });

    let payload={
        "Action": "DownloadABAFile",
        "Data": {
            "EXPENDITUREBATCHGUID": this.BatchPaymentData.EXPENDITUREBATCHGUID
        },
    }

    this._mainAPiServiceService.getSetData(payload, "expenditure-batch").subscribe(res=>{
        if (res.STATUS == "success" && res.CODE == 200) {
            this.toastr.success("ABA File Download successfully");
               this.http.get(res.DATA.ABAFILENAME, { responseType: 'blob' }).subscribe(
                (response: Blob) => {
                  this.fileSaverService.save(response, this.extractFileName(res.DATA.ABAFILENAME));
                  this.isDownloadABAFile=false;
                },
                error => {
                  console.error('Download error:', error);
                  this.isDownloadABAFile=false;
                }
              );
        } else if (res.CODE == 451 && res.STATUS == "warning") {
            this.isDownloadABAFile=false;

        } else if (res.CODE == 450 && res.STATUS == "error") {
            this.isDownloadABAFile=false;

        } else if (res.MESSAGE == "Not logged in") {
            this.dialogRef.close(false);
            this.isDownloadABAFile=false;

        }
    })
}

 extractFileName(url: string): string {
    try {
      // Parse the URL to ensure it's valid
      const parsedUrl = new URL(url);
  
      // Get the pathname and extract the file name dynamically
      const pathname = parsedUrl.pathname;
      return pathname.substring(pathname.lastIndexOf("/") + 1, pathname.indexOf("?") > -1 ? pathname.indexOf("?") : undefined);
    } catch (error) {
      console.error("Invalid URL:", error);
      return ""; // Return an empty string if URL is invalid
    }
  }

}

//2 pair Data Convert
function chunks(arr, size = 3) {
    return arr
        .map((x, i) => i % size == 0 && arr.slice(i, i + size))
        .filter((x) => x);
}
