import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { Subject } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { FuseSidebarService } from "src/@fuse/components/sidebar/sidebar.service";
import { BehaviorService } from "@_services/Behavior.service";
import { SocketService } from "@_services/socket.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-notifications",
    templateUrl: "./notifications.component.html",
    styleUrls: ["./notifications.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class NotificationsComponent implements OnInit, OnDestroy, OnChanges {
    private _unsubscribeAll: Subject<any>;
    @Input() notificationList: any;
    data: any;
    reportgenerateData: any;
    isPDFGenerated: boolean = false;
    singledataarray = [];
    base_url: string = environment.ReportUrl;
    btnText = "";
    progress: any = 5;
    title: any;
    accountClass: any;
    showwarningdiv = "false";
    matteridarray = [];
    finalReportData: any;
    constructor(
        public MatDialog: MatDialog,
        public behaviorService: BehaviorService,
        private _fuseSidebarService: FuseSidebarService,
        private socketService: SocketService
    ) {
        this._unsubscribeAll = new Subject();
        // this.socketService.getWebSocketReportGenerationSubject().subscribe((details) => {
        //     if (details) {
        //         this.progress = details.PROGRESS;
        //         this.btnText = details.DESCRIPTION;
        //         if(this.progress == "100" && this.btnText == "Report Complete" && details.APIRESPONSE) {
        //             this.finalReportData = details.APIRESPONSE.DATA;
        //         }
        //     }
        // })

        // this.behaviorService.reportdata$.subscribe((reportdata) => {
        //     if(reportdata){
        //         this.isPDFGenerated = true;
        //         this.data = reportdata;
        //     }
        // })

        // this.behaviorService.singldata$.subscribe((singledata) => {
        //     if(singledata){
        //         this.singledataarray = singledata;
        //     }
        // })

        // this.behaviorService.reportgenerateData$.subscribe((reportgeneratedata) => {
        //     if(reportgeneratedata){
        //         this.reportgenerateData = reportgeneratedata;
        //     }
        // })

        // this.behaviorService.reportTitle$.subscribe((reportTitle) => {
        //     if(reportTitle){
        //         this.title = reportTitle;
        //     }
        // })

        // this.behaviorService.accountClass$.subscribe((accountClass) => {
        //     if(accountClass){
        //         this.accountClass = accountClass;
        //     }
        // })

        // this.behaviorService.warning$.subscribe((warning) => {
        //     if(warning){
        //         this.showwarningdiv = warning;
        //     }
        // })

        // this.behaviorService.matteridarray$.subscribe((matteridarray) => {
        //     if(matteridarray){
        //         this.matteridarray = matteridarray;
        //     }
        // })
    }

      /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() { }
    ngOnChanges() {
        // create header using child_id
        if (this.notificationList) {
            this.getNotificationList();
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    /**
     * This function is used to get the notification list data value. 
     */
    getNotificationList():void {
        this.notificationList.forEach((element) => {
            if (element.LINKTYPE == 4) {
                element.ICON = "notifications";
            } else if (element.LINKTYPE == 6) {
                element.ICON = "cake";
            }
        });
    }

    /**
     * This function is used to open the side bar data value.
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    ToEmailthedocument(){

    }
}
