import {
    Component,
    OnInit,
    Inject,
    ElementRef,
    ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialog,
} from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@angular/common";
import { environment } from "../../../environments/environment";
// import { MatterDialogComponent } from '../pages/time-entries/matter-dialog/matter-dialog.component';
// import { BankingDialogComponent } from "../pages/banking/banking-dialog.component";
// import { SelectEmailPopupComponent } from '../pages/template/packs/select-email-popup/select-email-popup.component';
// import { MatterDialogComponentForTemplate } from '../pages/template/matter-dialog/matter-dialog.component';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { debounceTime, distinctUntilChanged, Observable, Subject, Subscription, switchMap } from "rxjs";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatterDialogComponent } from "@pages/time-entries/matter-dialog/matter-dialog.component";
import { BankingDialogComponent } from "@pages/banking/banking-dialog.component";
import { SelectEmailPopupComponent } from "@pages/template/packs/select-email-popup/select-email-popup.component";
import { MatterDialogComponentForTemplate } from "@pages/template/matter-dialog/matter-dialog.component";
import { MainAPiServiceService } from "@_services/main-api-service.service";
import { BehaviorService } from "@_services/Behavior.service";
import { SocketService } from "@_services/socket.service";
import { FuseSidebarService } from "src/@fuse/components/sidebar/sidebar.service";
@Component({
    selector: "app-reports",
    templateUrl: "./reports.component.html",
    styleUrls: ["./reports.component.scss"],
})
export class ReportsComponent implements OnInit {
    ReportForm: FormGroup;
    reportList: any = [];
    pdfdata: any = [];
    title: string;
    responseData: any = [];
    PDF_Generation: any;
    FEEEARNERS2Details: any = [];
    base_url: string;
    isLoadingResults: boolean = false;
    isLoadingResultsU: boolean = false;
    isPDFGenerated: boolean = false;
    SendFE2id: any;
    selectedCategory: any;
    indexLIST2: any;
    storeMatterGuid: any = [];
    singalAccountGuid: any;
    multiAccount: any = [];
    multiAccountGuid: any;
    reportData1: any = [];
    // webSocket = new WebSocket(environment.MainSocketUrl);
    btnText = "";
    progress: any = 5;
    isMulti: any;
    currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
    ChartHandlingData: {
        ClickType: string;
        UseTrust: string;
        PopUp: string;
        Lable: string;
    };
    currantQuarter = {
        "0": "1st Quarter (1 Jan – 31 March)",
        "1": "1st Quarter (1 Jan – 31 March)",
        "2": "1st Quarter (1 Jan – 31 March)",
        "3": "2nd Quarter ( 1 April – 30 June)",
        "4": "2nd Quarter ( 1 April – 30 June)",
        "5": "2nd Quarter ( 1 April – 30 June)",
        "6": "3rd Quarter  (1 July – 30 Sept)",
        "7": "3rd Quarter  (1 July – 30 Sept)",
        "8": "3rd Quarter  (1 July – 30 Sept)",
        "9": "4th Quarter  (1 Oct – 31 Dec)",
        "10": "4th Quarter  (1 Oct – 31 Dec)",
        "11": "4th Quarter  (1 Oct – 31 Dec)",
    };
    @ViewChild("MatterInput1", { static: false })
    MatterInput: ElementRef<HTMLInputElement>;
    exportdatavalue: any;
    matterlist: Observable<string[]>;
    mattername: any = [];
    selectedData: any = [];
    MatterFeildShow: any = false;
    DataGetAccount: any = [];
    searchMatter: any;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    searchData: any;
    selected: any = [];
    multiFeeEarnerList: any = [];
    reportgenerateData: any;
    accountClass: any;
    obj: any = {};
    isNoneSelect: boolean = false;
    showwarningdiv = "false";
    errorlength = [];
    loopValue = 0;
    matteridarray = [];
    succeslist: any = [];
    storeMatterShortName: any;
    singledataarray = [];
    storeAllMultipleSelectedData = [];
    CSVFILENAME: any;
    CSVFILENAMEARRAY = [];
    private searchTerms = new Subject<string>();
    subscription: Subscription;
    reportSubmitted: boolean = false;
    uniqueIDs: any[] = [];
    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<ReportsComponent>,
        private _formBuilder: FormBuilder,
        public datepipe: DatePipe,
        private toastr: ToastrService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _mainAPiServiceService: MainAPiServiceService,
        public behaviorService: BehaviorService,
        private socketService: SocketService,
        private _fuseSidebarService: FuseSidebarService,
    ) {
        // this.socketService
        //     .openWSConnection(
        //         this.currentUser.SessionToken,
        //         "ReportGenerateStatus"
        //     )
        //     .subscribe((details) => {
        //         if (details) {
        //             this.progress = details.PROGRESS;
        //             this.btnText = details.DESCRIPTION;
        //         }
        //     });
        //API Call
        this.socketService.getWebSocketReportGenerationSubject().subscribe((details) => {
            if (details) {
                this.progress = details.PROGRESS;
                this.btnText = details.DESCRIPTION;
            }
        })
        this.behaviorService.multiAccountStore$.subscribe((result) => {
            if (result) {
                this.multiAccount = result;
            }
        });
        this.behaviorService.APIretryCall$.subscribe((result) => {
            if (result && result.module == "ReportGenerate") {
                this.behaviorService.setAPIretryCall(null);
                this.ReportSubmit();
            }
        });
        this.ReportRequestFilterDialog();
        localStorage.setItem("istrackid", "ReportDialogsComponent");
        this.behaviorService.APIretryCall$.subscribe((result) => {
            if (
                localStorage.getItem("istrackid") == "ReportDialogsComponent" &&
                result["click"] &&
                result["data"]["ReportRequestFilter"]
            ) {
                this.ReportRequestFilterDialog();
            }
            if (
                localStorage.getItem("istrackid") == "ReportDialogsComponent" &&
                result["click"] &&
                result["data"]["ReportGenerate"]
            ) {
                this.ReportSubmit();
            }
        });
        this.behaviorService.dialogClose$.subscribe((result) => {
            if (result != null) {
                if (result.MESSAGE == "Not logged in") {
                    this.dialogRef.close(false);
                }
            }
        });
    }
    ngOnInit() {
        this.MatterFeildShow = false;
        this.ReportForm = this._formBuilder.group({
            DATERANGE: ["", [Validators.required]],
            ReportDateStart: [""],
            DateEnd: [""],
            FEEEARNERNAME: ["", [Validators.required]],
            FEEEARNERNAME2: ["", [Validators.required]],
            LIST1TEXT: ["", [Validators.required]],
            LIST2TEXT: ["", [Validators.required]],
            OPTIONTEXT1: ["", [Validators.required]],
            OPTIONTEXT2: ["", [Validators.required]],
            OPTIONTEXT3: ["", [Validators.required]],
            OPTIONTEXT4: ["", [Validators.required]],
            OPTIONTEXT5: ["", [Validators.required]],
            OPTIONTEXT6: ["", [Validators.required]],
            OPTIONTEXT7: ["", [Validators.required]],
            OPTIONTEXT8: ["", [Validators.required]],
            OPTIONTEXT9: ["", [Validators.required]],
            OPTIONTEXT10: ["", [Validators.required]],
            MULTIFEEEARNERLIST: [""],
            SHORTNAME: [""],
            MATTERGUID: [""],
        });
        this.ReportForm.get("ReportDateStart").disable();
        this.ReportForm.get("DateEnd").disable();

        this.subscription = this.searchTerms.pipe(
            debounceTime(300), // Wait for 300ms pause in events
            distinctUntilChanged(), // Ignore if the next search term is the same as the previous one
            switchMap((term: string) => this.selectMatterFormSearch(term)) // Switch to new observable on new term
        ).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.exportdatavalue = response.DATA;
                this.searchData = response.DATA.RECORDS;
                this.matterlist = this.searchData;
            }
        });
    }

    getSocketReportsData(){
        const uniqueId = this.socketService.generateUniqueId();
        let data = {
            "Action": "New Report",
            "TrackingGuid": uniqueId,
            "ReportName": this.title
        }

        let json = {
            "Action": "default",
            "Data": {
                "changeTopic": "TrackReport",
                "message": JSON.stringify(data)
            },
        }
        this._mainAPiServiceService.getSetData(json,'websocket-send-data').subscribe((response1) =>{
            if(response1.STATUS == "success") {
            }   
        })
    }
    
    get f() {
        return this.ReportForm.controls;
    }

    /**
     * This function is used to open the matter dialog matter 
     */
    SelectMatter() {
        this.searchData = [];
        this.matterlist = this.searchData;
        const dialogRef = this.dialog.open(MatterDialogComponent, {
            width: "100%",
            disableClose: true,
            data: { multi: this.isMulti },
        });
        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                if (this.isMulti == "Multi") {
                    // this.storeMatterGuid = result;
                    // if (this.storeMatterGuid && this.storeMatterGuid.findIndex(e => e.MATTERGUID == element.MATTERGUID) == -1) {
                    await result.forEach((element) => {
                        if (
                            this.mattername.indexOf(
                                element.SELECTEDDESCRIPTION
                            ) == -1
                        ) {
                            this.storeMatterGuid =
                                this.storeMatterGuid.concat(element);
                            this.mattername.push(element.SELECTEDDESCRIPTION);
                            // this.storeMatterGuid = result.MATTERGUID;
                        }
                    });
                } else {
                    this.mattername = [result.SELECTEDDESCRIPTION];
                    this.storeMatterGuid = result.MATTERGUID;
                    this.storeMatterShortName = result.SHORTNAME;
                }
            }
        });
    }

    /**
     * This function is used to open the banking dialog popup
     */
    ReportRequestFilterDialog() {
        // this.openWSConnection(this.currentUser.SessionToken);
        this.isLoadingResults = true;
        this._mainAPiServiceService
            .getSetData(
                {
                    reportId: this.data.REPORTID,
                    SELECTACCOUNTS: "Multi",
                    SELECTMATTER: 1,
                },
                "ReportRequestFilter"
            )
            .subscribe(
                (response) => {
                    this.isLoadingResults = false;
                    if (response.CODE == 200 && response.STATUS == "success") {
                        this.isMulti = response.DATA.SELECTACCOUNTS;
                        this.accountClass = response.DATA.ACCOUNTCLASS;
                        // this.behaviorService.setReportAccountClass(this.accountClass);
                        this.ReportForm.controls["DATERANGE"].setValue(
                            response.DATA.DEFAULTDATETYPE
                        );
                        if (response.DATA.SELECTMATTER == 1) {
                            this.MatterFeildShow = response.DATA.SELECTMATTER;
                            // const dialogRef = this.dialog.open(MatterDialogComponent, { width: '100%', disableClose: true, data: { multi: this.isMulti } });
                            // dialogRef.afterClosed().subscribe(result => {

                            //   localStorage.setItem('istrackid', 'ReportDialogsComponent');
                            //   if (result) {
                            //     if (this.isMulti == 'Multi') {
                            //       this.storeMatterGuid = result;
                            //     } else {
                            //       this.storeMatterGuid = result.MATTERGUID;
                            //       this.storeMatterShortName=result.SHORTNAME
                            //     }
                            //   }
                            // });
                        } else if (
                            this.isMulti == "Single" ||
                            this.isMulti == "Multi" ||
                            response.DATA.SELECTSINGLEACCOUNT
                        ) {
                            this.behaviorService.isAccountMulti(this.isMulti);
                            let UseTrust = "Yes";

                            if (
                                response.DATA.SELECTSINGLEACCOUNT &&
                                response.DATA.SELECTSINGLEACCOUNT ==
                                    "AllPractice"
                            ) {
                                UseTrust = "No";
                                this.isMulti = "Single";
                                response.DATA.ACCOUNTCLASS = "ALL";
                            } else if (
                                response.DATA.SELECTSINGLEACCOUNT &&
                                response.DATA.SELECTSINGLEACCOUNT == "AllTrust"
                            ) {
                                response.DATA.ACCOUNTCLASS = "ALL";
                                this.isMulti = "Single";
                            }
                            this.ChartHandlingData = {
                                ClickType: "WithTrust",
                                UseTrust: UseTrust,
                                PopUp: "",
                                Lable: "Select Account",
                            };
                            localStorage.setItem(
                                "ChartURL",
                                JSON.stringify(this.ChartHandlingData)
                            );
                            this.behaviorService.TrustDuplicateModuleHandling(
                                this.ChartHandlingData
                            );
                            const dialogRef = this.dialog.open(
                                BankingDialogComponent,
                                {
                                    disableClose: true,
                                    width: "100%",
                                    data: {
                                        AccountType: response.DATA.ACCOUNTCLASS,
                                        FromWhere: "",
                                        UseTrust: UseTrust,
                                    },
                                }
                            );
                            dialogRef.afterClosed().subscribe((result) => {
                                localStorage.setItem(
                                    "istrackid",
                                    "ReportDialogsComponent"
                                );
                                if (result) {
                                    if (this.isMulti == "Single") {
                                        let ac = {
                                            ACCOUNTGUID: result.ACCOUNTGUID,
                                        };
                                        this.singalAccountGuid =
                                            result.ACCOUNTGUID;
                                    } else {
                                        this.multiAccountGuid =
                                            this.multiAccount;
                                    }
                                }
                            });
                        }
                        this.responseData = response.DATA;
                        if (
                            this.responseData.REPORTTITLE ==
                                "Fee Earner Summary" ||
                            this.responseData.REPORTTITLE ==
                                "Primary Fee Earner Summary" ||
                            this.responseData.REPORTTITLE == "Owner Summary"
                        ) {
                            this.selectedCategory = 2;
                            this.indexLIST2 = 3;
                        } else {
                            this.selectedCategory = 0;
                            this.indexLIST2 = 0;
                        }
                        this.title = response.DATA.REPORTTITLE;
                        // this.getSocketReportsData();
                        // this.behaviorService.setReportTitle(this.title);
                        if (response.DATA.SHOWFEEEARNERS2 != 0) {
                            this.FEEEARNERS2Details = response.DATA.FEEEARNERS2;
                            this.SendFE2id =
                                response.DATA.FEEEARNERS2[0].FEEEARNERID;
                        }
                        let DatefilterReports: any = [
                            "TrustSingleTrustAccountStatement",
                            "TrustReportsCashbook",
                            "TrustTrialBalance",
                            "TrustDebitBalance",
                            "TrustControlAccount",
                            "TrustTransferJournal",
                            "TrustSingleMatterLedger",
                            "TrustMatterLedgers",
                            "ReportSingleUnknownLedger",
                            "ControlledMatterLedgers",
                            "ControlledSingleMatterLedger",
                            "TrustAllTrustAccountStatements",
                            "TrustSingleControlledAccountStatement",
                            "TrustAllControlledAccountStatements",
                            "TrustReportControlledCashbook",
                            "TrustReportControlledAccountListing",
                        ];
                        // get date after innitializ
                        // if (this.data.REPORTID == 'TrustReportControlledCashbook') {
                        //   this.selectedCategory = response.DATA.DATERANGE.reduce(function (a, e, i) {
                        //     if (e.TEXT === "Date Range")
                        //       return i;
                        //   }, []);
                        //   this.ReportForm.controls['ReportDateStart'].setValue(new Date());
                        //   this.ReportForm.controls['DateEnd'].setValue(new Date());
                        // } else
                        if (DatefilterReports.includes(this.data.REPORTID)) {
                            // this.ReportForm.controls['ReportDateStart'].setValue(null);
                        } else if (this.title == "Aged Debtors") {
                            this.ReportForm.controls[
                                "ReportDateStart"
                            ].setValue(new Date());
                            this.ReportForm.controls["DateEnd"].setValue(
                                new Date()
                            );
                        } else {
                            this.ReportForm.controls["DateEnd"].setValue(
                                new Date()
                            );
                        }

                        this.hideshowdatepicker(response.DATA.DEFAULTDATETYPE);
                        //option1
                        if (response.DATA.OPTIONTEXT1 != "") {
                            if (response.DATA.OPTIONVALUE1 == 0) {
                                this.ReportForm.controls[
                                    "OPTIONTEXT1"
                                ].setValue(false);
                            } else if (response.DATA.OPTIONVALUE1 == 1) {
                                this.ReportForm.controls[
                                    "OPTIONTEXT1"
                                ].setValue(true);
                            }
                        }
                        //option2
                        if (response.DATA.OPTIONTEXT2 != "") {
                            if (response.DATA.OPTIONVALUE2 == 0) {
                                this.ReportForm.controls[
                                    "OPTIONTEXT2"
                                ].setValue(false);
                            } else if (response.DATA.OPTIONVALUE2 == 1) {
                                this.ReportForm.controls[
                                    "OPTIONTEXT2"
                                ].setValue(true);
                            }
                        }
                        //option3
                        if (response.DATA.OPTIONTEXT3 != "") {
                            if (response.DATA.OPTIONVALUE3 == 0) {
                                this.ReportForm.controls[
                                    "OPTIONTEXT3"
                                ].setValue(false);
                            } else if (response.DATA.OPTIONVALUE3 == 1) {
                                this.ReportForm.controls[
                                    "OPTIONTEXT3"
                                ].setValue(true);
                            }
                        }
                        //option4
                        if (response.DATA.OPTIONTEXT4 != "") {
                            if (response.DATA.OPTIONVALUE4 == 0) {
                                this.ReportForm.controls[
                                    "OPTIONTEXT4"
                                ].setValue(false);
                            } else if (response.DATA.OPTIONVALUE4 == 1) {
                                this.ReportForm.controls[
                                    "OPTIONTEXT4"
                                ].setValue(true);
                            }
                        }
                        //option5
                        if (response.DATA.OPTIONTEXT5 != "") {
                            if (response.DATA.OPTIONVALUE5 == 0) {
                                this.ReportForm.controls[
                                    "OPTIONTEXT5"
                                ].setValue(false);
                            } else if (response.DATA.OPTIONVALUE5 == 1) {
                                this.ReportForm.controls[
                                    "OPTIONTEXT5"
                                ].setValue(true);
                            }
                        }
                        //option6
                        if (response.DATA.OPTIONTEXT6 != "") {
                            if (response.DATA.OPTIONVALUE6 == 0) {
                                this.ReportForm.controls[
                                    "OPTIONTEXT6"
                                ].setValue(false);
                            } else if (response.DATA.OPTIONVALUE6 == 1) {
                                this.ReportForm.controls[
                                    "OPTIONTEXT6"
                                ].setValue(true);
                            }
                        }
                        //option7
                        if (response.DATA.OPTIONTEXT7 != "") {
                            if (response.DATA.OPTIONVALUE7 == 0) {
                                this.ReportForm.controls[
                                    "OPTIONTEXT7"
                                ].setValue(false);
                            } else if (response.DATA.OPTIONVALUE7 == 1) {
                                this.ReportForm.controls[
                                    "OPTIONTEXT7"
                                ].setValue(true);
                            }
                        }
                        //option8
                        if (response.DATA.OPTIONTEXT8 != "") {
                            if (response.DATA.OPTIONVALUE8 == 0) {
                                this.ReportForm.controls[
                                    "OPTIONTEXT8"
                                ].setValue(false);
                            } else if (response.DATA.OPTIONVALUE8 == 1) {
                                this.ReportForm.controls[
                                    "OPTIONTEXT8"
                                ].setValue(true);
                            }
                        }
                        //option9
                        if (response.DATA.OPTIONTEXT9 != "") {
                            if (response.DATA.OPTIONVALUE9 == 0) {
                                this.ReportForm.controls[
                                    "OPTIONTEXT9"
                                ].setValue(false);
                            } else if (response.DATA.OPTIONVALUE9 == 1) {
                                this.ReportForm.controls[
                                    "OPTIONTEXT9"
                                ].setValue(true);
                            }
                        }
                        //option10
                        if (response.DATA.OPTIONTEXT10 != "") {
                            if (response.DATA.OPTIONVALUE10 == 0) {
                                this.ReportForm.controls[
                                    "OPTIONTEXT10"
                                ].setValue(false);
                            } else if (response.DATA.OPTIONVALUE10 == 1) {
                                this.ReportForm.controls[
                                    "OPTIONTEXT10"
                                ].setValue(true);
                            }
                        }
                    } else {
                        this.toastr.error(response.MESSAGE);
                        this.dialogRef.close(true);
                    }
                },
                (error) => {
                    this.toastr.error(error);
                }
            );
    }
    
    async ReportSubmit() {
        // this.uniqueIDs = [];
        this.isLoadingResults = this.isLoadingResultsU = true;
        // await this.socketService.openSocket();
        const multiFreeErner = this.ReportForm.get("MULTIFEEEARNERLIST").value;
        if (multiFreeErner) {
            multiFreeErner.forEach((element) => {
                this.multiFeeEarnerList.push({ FEEEARNERGUID: element });
            });
        }
        let ReportData: any = {
            REPORTID: this.data.REPORTID,
            ACCOUNTGUID: this.singalAccountGuid,
            // ACCOUNTSLIST: this.multiAccountGuid,
            DateRangeSelection: this.f.DATERANGE.value,
            DateFrom: "",
            DateTo: "",
            FEEEARNERGUID: this.f.FEEEARNERNAME.value,
            FEEEARNERGUID2: this.f.FEEEARNERNAME2.value,
            MULTIFEEEARNERLIST: this.multiFeeEarnerList,
            FEEEARNERID2: this.SendFE2id,
            List1Selection: this.f.LIST1TEXT.value,
            List2Selection: this.f.LIST2TEXT.value,
            OptionValue1: valuechange(this.f.OPTIONTEXT1.value),
            OptionValue2: valuechange(this.f.OPTIONTEXT2.value),
            OptionValue3: valuechange(this.f.OPTIONTEXT3.value),
            OptionValue4: valuechange(this.f.OPTIONTEXT4.value),
            OptionValue5: valuechange(this.f.OPTIONTEXT5.value),
            OptionValue6: valuechange(this.f.OPTIONTEXT6.value),
            OptionValue7: valuechange(this.f.OPTIONTEXT7.value),
            OptionValue8: valuechange(this.f.OPTIONTEXT8.value),
            OptionValue9: valuechange(this.f.OPTIONTEXT9.value),
            OptionValue10: valuechange(this.f.OPTIONTEXT10.value),
        };
        if (this.data.REPORTID == "TrustSingleTrustAccountStatement") {
            ReportData.MATTERGUID = this.storeMatterGuid[0].MATTERGUID
                ? this.storeMatterGuid[0].MATTERGUID
                : this.storeMatterGuid;
            //  ReportData.ACCOUNTSLIST = this.storeMatterGuid[0].MATTERGUID? this.storeMatterGuid[0].MATTERGUID:this.storeMatterGuid
        }

        if (
            this.data.REPORTID == "ReportSingleMatterUnbilledWork" ||
            this.data.REPORTID == "ReportMatterFinancials" ||
            this.data.REPORTID == "TrustSingleMatterLedger"
        ) {
            ReportData.MATTERGUID = this.storeMatterGuid[0].MATTERGUID
                ? this.storeMatterGuid[0].MATTERGUID
                : this.storeMatterGuid;
            delete ReportData.ACCOUNTGUID;
        }

        if (
            this.f.ReportDateStart.value != "Invalid Date" &&
            this.f.ReportDateStart.value
        ) {
            ReportData.DateFrom = this.datepipe.transform(
                this.f.ReportDateStart.value,
                "dd/MM/yyyy"
            );
        }
        if (this.f.DateEnd.value != "Invalid Date" && this.f.DateEnd.value) {
            ReportData.DateTo = this.datepipe.transform(
                this.f.DateEnd.value,
                "dd/MM/yyyy"
            );
        }
        if (!ReportData.DateFrom) {
            ReportData.DateFrom = "";
        }
        // if (this.isMulti == "Multi" && this.storeMatterGuid) {
        //   let ides: any = [];
        //   this.storeMatterGuid.forEach(element => {
        //     ides.push({ AccountGuid: element.MATTERGUID });
        //   });
        //   // ReportData.ACCOUNTSLIST = ides;
        //   // ReportData.ACCOUNTSLIST =  this.storeMatterGuid[i].MATTERGUIDi;
        // } else {
        //   ReportData.MATTERGUID = this.storeMatterGuid;
        // }

        keydelete(ReportData);
        if (this.data.REPORTID == "ReportMatterCompensationSummary") {
            delete ReportData["DateTo"];
        }

        if (
            this.data.REPORTID !== "TrustAllTrustAccountStatements" &&
            this.data.REPORTID !== "TrustMatterLedgers"
        ) {
            // this.reportSubmitted = true;
            const uniqueId = this.socketService.generateUniqueId();
            // this.uniqueIDs.push(uniqueId);
            this.socketService.openSocketReportGeneration(uniqueId);
            this.obj.AccountGuid = this.storeMatterGuid;
            // this.obj.AccountGuid = (this.storeMatterGuid[0].MATTERGUID ?  this.storeMatterGuid[0].MATTERGUID:this.storeMatterGuid )
            ReportData.ACCOUNTSLIST = this.obj;
            this.isLoadingResults = this.isLoadingResultsU = true;

            if (
                this.data.REPORTID == "ReportSingleMatterUnbilledWork" ||
                this.data.REPORTID == "ReportMatterFinancials" ||
                this.data.REPORTID == "TrustSingleMatterLedger"
            ) {
                delete ReportData.ACCOUNTSLIST;
            }
            ReportData.TrackingGuid = uniqueId;
            // ReportData.SCHEDULEREQUEST = true;
            this._mainAPiServiceService
                .SetReportGenerate(ReportData, "ReportGenerate")
                .subscribe(
                    async (reportgenerateData) => {
                        let singldata1 = ReportData;
                        // this.behaviorService.setReportData(this.data);
                        if (
                            reportgenerateData.PDF_Generation.CODE == 200 &&
                            reportgenerateData.PDF_Generation.STATUS ==
                                "success"
                        ) {
                            this.reportData1.push(reportgenerateData);
                            this.isPDFGenerated = true;
                            this.title = this.title;
                            // reportgenerateData.PDF_Generation.DATA.CSVFILENAME
                            this.CSVFILENAME =
                                reportgenerateData.PDF_Generation.DATA.CSVFILENAME;

                            this.base_url = environment.ReportUrl;
                            this.reportgenerateData =
                                reportgenerateData.PDF_Generation;
                            this.PDF_Generation =
                                reportgenerateData.PDF_Generation.DATA.PDFFILENAME;
                            this.toastr.success(
                                "Report generated successfully"
                            );
                            if (
                                reportgenerateData.PDF_Generation.DATA
                                    .MATTERGUID
                            ) {
                                if (
                                    this.storeMatterShortName ||
                                    (this.storeMatterGuid[0] &&
                                        this.storeMatterGuid[0].SHORTNAME)
                                ) {
                                    singldata1.SHORTNAME = this
                                        .storeMatterShortName
                                        ? this.storeMatterShortName
                                        : this.storeMatterGuid[0].SHORTNAME;
                                }
                                // singldata1.SHORTNAME = this.storeMatterGuid[0].SHORTNAME?this.storeMatterGuid[0].SHORTNAME:this.storeMatterGuid
                            }
                            this.singledataarray.push(
                                JSON.parse(JSON.stringify(singldata1))
                            );
                            // this.behaviorService.setSinglData(this.singledataarray);
                            // this.webSocket.send("unwatch channel " + this.currentUser.SessionToken + " ReportGenerateStatus");
                            // this.webSocket.close();
                            this.isLoadingResults = this.isLoadingResultsU =
                                false;
                            // this.behaviorService.setReportGenerateData(this.reportgenerateData);
                            // this.dialogRef.close(false)
                            // this.socketService.closeSocket(this.currentUser.SessionToken, "ReportGenerateStatus");
                            this.socketService.disconnectWebSocketReportGeneration(uniqueId);
                        } else if (
                            reportgenerateData.PDF_Generation.CODE == 415 &&
                            reportgenerateData.PDF_Generation.STATUS == "error"
                        ) {
                            if (
                                this.data.REPORTID ==
                                    "ReportSingleMatterUnbilledWork" ||
                                this.data.REPORTID ==
                                    "ReportMatterFinancials" ||
                                this.data.REPORTID ==
                                    "TrustSingleTrustAccountStatement" ||
                                this.data.REPORTID == "TrustSingleMatterLedger"
                            ) {
                                // "TrustMatterLedgers"
                                this.showwarningdiv = "true";
                                // this.behaviorService.setWarning(this.showwarningdiv);
                                let singldata = ReportData;
                                singldata.MESSAGE =
                                    reportgenerateData.PDF_Generation.MESSAGE;
                                if (
                                    this.storeMatterShortName ||
                                    (this.storeMatterGuid[0] &&
                                        this.storeMatterGuid[0].SHORTNAME)
                                ) {
                                    singldata.SHORTNAME = this
                                        .storeMatterShortName
                                        ? this.storeMatterShortName
                                        : this.storeMatterGuid[0].SHORTNAME;
                                }
                                this.singledataarray.push(
                                    JSON.parse(JSON.stringify(singldata))
                                );
                                this.matteridarray.push(singldata.SHORTNAME);
                                // this.behaviorService.setMatteridarray(this.matteridarray);
                                this.isLoadingResults = this.isLoadingResultsU =
                                    false;
                            } else {
                                this.toastr.error(
                                    reportgenerateData.PDF_Generation.MESSAGE
                                );
                                this.dialogRef.close(true);
                                this.isLoadingResults = this.isLoadingResultsU =
                                    false;
                            }
                            this.socketService.disconnectWebSocketReportGeneration(uniqueId);
                        } else if (
                            reportgenerateData.PDF_Generation.CODE == 432 &&
                            reportgenerateData.PDF_Generation.STATUS == "error"
                        ) {
                            this.toastr.error(
                                reportgenerateData.PDF_Generation.MESSAGE
                            );
        //                     window.open(
        //                         environment.APIEndpoint +
        //                             "OAuth2Start?AuthType=NetDrive&SessionToken=" +
        //                             this.currentUser.SessionToken,
        //                         "targetWindow",
        //                         `toolbar=no,
        //    location=no,
        //    status=no,
        //    menubar=no,
        //    scrollbars=yes,
        //    resizable=yes,
        //    width=800,
        //    height=600`
        //                     );
                            let payload = {
                                "Action": "OAuth2Start",
                                "VALIDATEONLY": false,
                                "Data": {
                                    "USERGUID": this.currentUser.UserGuid,
                                    "AuthType": "NetDrive"
                                }
                            }
                            this._mainAPiServiceService
                            .getSetData(payload, 'oauth-connections')
                            .subscribe((response) => {
                                if(response.CODE == 200 && response.STATUS == "success") {
                                    window.open(
                                        response.DATA[0].AuthorizationUrl,
                                        "targetWindow",
                                        `toolbar=no,
                                        location=no,
                                        status=no,
                                        menubar=no,
                                        scrollbars=yes,
                                        resizable=yes,
                                        width=800,
                                        height=600`
                                    );
                                }
                            });
                            this.dialogRef.close(true);
                            this.socketService.disconnectWebSocketReportGeneration(uniqueId);
                        }
                    },
                    async (error) => {
                        this.toastr.error(error);
                    }
                );
        }

        //Api Report Generate
        if (
            this.data.REPORTID == "TrustAllTrustAccountStatements" ||
            this.data.REPORTID == "TrustMatterLedgers"
        ) {
            // this.reportSubmitted = true;
            this.succeslist = [];
            this.storeAllMultipleSelectedData = [];
            this.CSVFILENAMEARRAY = [];
            let temp = 1;
            for (var i = 0; i < this.storeMatterGuid.length; i++) {
                const uniqueId = this.socketService.generateUniqueId();
                // this.uniqueIDs.push(uniqueId);
                this.socketService.openSocketReportGeneration(uniqueId);
                this.obj.AccountGuid = this.storeMatterGuid[i].MATTERGUID;
                ReportData.ACCOUNTSLIST = this.obj;
                ReportData.MATTERGUID = this.obj.AccountGuid;
                if (this.data.REPORTID == "TrustMatterLedgers") {
                    this.data.REPORTID == "TrustMatterLedgers";
                } else {
                    ReportData.REPORTID = "TrustSingleTrustAccountStatement";
                }
                this.isLoadingResults = this.isLoadingResultsU = true;
                ReportData.TrackingGuid = uniqueId;
                // ReportData.SCHEDULEREQUEST = true;
                await new Promise<void>(async (next, reject) => {
                    this._mainAPiServiceService
                        .SetReportGenerate(ReportData, "ReportGenerate")
                        .subscribe(
                            async (reportgenerateData) => {
                                if (
                                    reportgenerateData.PDF_Generation.CODE ==
                                        200 &&
                                    reportgenerateData.PDF_Generation.STATUS ==
                                        "success"
                                ) {
                                    this.reportData1.push(reportgenerateData);
                                    this.isPDFGenerated = true;
                                    this.title = this.title;

                                    if (
                                        reportgenerateData.PDF_Generation.DATA
                                            .CSVFILENAME != ""
                                    ) {
                                        this.CSVFILENAMEARRAY.push(
                                            reportgenerateData.PDF_Generation
                                                .DATA
                                        );
                                    }
                                    this.base_url = environment.ReportUrl;
                                    this.reportgenerateData =
                                        reportgenerateData.PDF_Generation;
                                    this.PDF_Generation =
                                        reportgenerateData.PDF_Generation.DATA.PDFFILENAME;
                                    this.toastr.success(
                                        "Report generated successfully"
                                    );
                                    // this.webSocket.send("unwatch channel " + this.currentUser.SessionToken + " ReportGenerateStatus");
                                    // this.webSocket.close();
                                    this.isLoadingResults =
                                        this.isLoadingResultsU = false;
                                    this.succeslist.push(
                                        this.storeMatterGuid[i].MATTERGUID
                                    );
                                    // this.socketService.closeSocket(this.currentUser.SessionToken, "ReportGenerateStatus");
                                    this.reportList.push(ReportData);
                                    this.pdfdata.push(
                                        JSON.parse(
                                            JSON.stringify(
                                                reportgenerateData.PDF_Generation
                                            )
                                        )
                                    );
                                    let userData = ReportData;
                                    userData.TEMPERROR = false;
                                    userData.SHORTNAME =
                                        this.storeMatterGuid[i].SHORTNAME;
                                    this.storeAllMultipleSelectedData.push(
                                        JSON.parse(JSON.stringify(userData))
                                    );

                                    if (i + 1 == this.storeMatterGuid.length) {
                                        // this.socketService.closeSocket(
                                        //     this.currentUser.SessionToken,
                                        //     "ReportGenerateStatus"
                                        // );
                                    }
                                    next();
                                    this.socketService.disconnectWebSocketReportGeneration(uniqueId);
                                } else if (
                                    reportgenerateData.PDF_Generation.CODE ==
                                        415 &&
                                    reportgenerateData.PDF_Generation.STATUS ==
                                        "error"
                                ) {
                                    this.showwarningdiv = "true";
                                    // this.behaviorService.setWarning(this.showwarningdiv);
                                    let userData1 = ReportData;
                                    userData1.TEMPERROR = true;
                                    userData1.SHORTNAME =
                                        this.storeMatterGuid[i].SHORTNAME;
                                    userData1.MESSAGE =
                                        reportgenerateData.PDF_Generation.MESSAGE;
                                    this.storeAllMultipleSelectedData.push(
                                        JSON.parse(JSON.stringify(userData1))
                                    );
                                    next();

                                    this.matteridarray.push(
                                        this.storeMatterGuid[i].SHORTNAME
                                    );
                                    // this.behaviorService.setMatteridarray(this.matteridarray);
                                    this.socketService.disconnectWebSocketReportGeneration(uniqueId);
                                    // this.toastr.error(reportgenerateData.PDF_Generation.MESSAGE);
                                    // this.dialogRef.close(false);
                                    this.isLoadingResults =
                                        this.isLoadingResultsU = false;
                                } else if (
                                    reportgenerateData.PDF_Generation.CODE ==
                                        432 &&
                                    reportgenerateData.PDF_Generation.STATUS ==
                                        "error"
                                ) {
                                    next();
                                    this.toastr.error(
                                        reportgenerateData.PDF_Generation
                                            .MESSAGE
                                    );
            //                         window.open(
            //                             environment.APIEndpoint +
            //                                 "OAuth2Start?AuthType=NetDrive&SessionToken=" +
            //                                 this.currentUser.SessionToken,
            //                             "targetWindow",
            //                             `toolbar=no,
            //    location=no,
            //    status=no,
            //    menubar=no,
            //    scrollbars=yes,
            //    resizable=yes,
            //    width=800,
            //    height=600`
            //                         );
                                    let payload = {
                                        "Action": "OAuth2Start",
                                        "VALIDATEONLY": false,
                                        "Data": {
                                            "USERGUID": this.currentUser.UserGuid,
                                            "AuthType": "NetDrive"
                                        }
                                    }
                                    this._mainAPiServiceService
                                    .getSetData(payload, 'oauth-connections')
                                    .subscribe((response) => {
                                        if(response.CODE == 200 && response.STATUS == "success") {
                                            window.open(
                                                response.DATA[0].AuthorizationUrl,
                                                "targetWindow",
                                                `toolbar=no,
                                                location=no,
                                                status=no,
                                                menubar=no,
                                                scrollbars=yes,
                                                resizable=yes,
                                                width=800,
                                                height=600`
                                            );
                                        }
                                    });
                                    this.dialogRef.close(true);
                                    this.socketService.disconnectWebSocketReportGeneration(uniqueId);
                                } else {
                                    next();
                                    this.socketService.disconnectWebSocketReportGeneration(uniqueId);
                                }
                            },
                            async (error) => {
                                this.toastr.error(error);
                            }
                        );
                });
            }
        }
        // }
    }

    // CompleteSubmit(){
    //     const responseData = {
    //         uniqueIDs: this.uniqueIDs,
    //         title: this.title,
    //         singledataarray: this.singledataarray,
    //         accountClass: this.accountClass,
    //         showwarningdiv: this.showwarningdiv,
    //         matteridarray: this.matteridarray,
    //         storeAllMultipleSelectedData: this.storeAllMultipleSelectedData,
    //         pdfdata: this.pdfdata,
    //         CSVFILENAMEARRAY: this.CSVFILENAMEARRAY,
    //         isPDFGenerated: this.isPDFGenerated,
    //         reportgenerateData: this.reportgenerateData,
    //         storeMatterGuid: this.storeMatterGuid,
    //         reportData1: this.reportData1,
    //         isMulti: this.isMulti
    //     }
    //     this.dialogRef.close(responseData);
    // }


    // this.reportSubmitted = false;
        // this._fuseSidebarService.getSidebar('notifications').toggleOpen();
    // openWSConnection(SessionToken: any) {
    //   try {
    //     let demoSoket = this.webSocket;
    //     demoSoket.onopen = (openEvent) => {
    //       demoSoket.send("watch channel " + SessionToken + " ReportGenerateStatus");
    //     };
    //     demoSoket.onclose = (closeEvent) => {
    //     };

    //     demoSoket.onerror = (errorEvent) => {
    //     };

    //     demoSoket.onmessage = (messageEvent) => {
    //       let data = JSON.parse(messageEvent.data);
    //       let details = JSON.parse(data.value);
    //       this.progress = details.PROGRESS;
    //       this.btnText = details.DESCRIPTION;
    //     };
    //   } catch (exception) {
    //     console.error(exception);
    //   }
    // }
    //dialog Close
    // openWSConnection(SessionToken: any) {
    //   this.socketService.watchMessageOnSocket(SessionToken).subscribe(details => {
    //     this.progress = details.PROGRESS;
    //     this.btnText = details.DESCRIPTION;
    //   })
    // }
    ondialogcloseClick(): void {
        this.dialogRef.close(true);
    }
    FE2Click(val, selectedvalue) {
        let getFE2Array = this.FEEEARNERS2Details.find(
            (c) => c["FEEEARNERNAME"] == selectedvalue["triggerValue"]
        );
        this.SendFE2id = getFE2Array.FEEEARNERID;
    }

    /**
     * This function is used to unsubscribe the Observale stream after destroyed the component
     */
    ngOnDestroy():void {
        this.behaviorService.isAccountMulti(null);
        this.subscription?.unsubscribe();
    }


    /**
     * This function is used to hide and show the date picker
     * @param val -value of the date picker
     */
    hideshowdatepicker(val) {
        let ToDate: any = "";
        let FromDate: any = "";
        if (this.responseData && this.responseData.DATERANGE) {
            this.responseData.DATERANGE.find((o) => {
                if (o.TEXT === val) {
                    FromDate = o.STARTDATE.split("/");
                    FromDate = new Date(
                        FromDate[1] + "/" + FromDate[0] + "/" + FromDate[2]
                    );
                    ToDate = o.ENDDATE.split("/");
                    ToDate = new Date(
                        ToDate[1] + "/" + ToDate[0] + "/" + ToDate[2]
                    );
                }
            });
        }
        this.ReportForm.controls["DateEnd"].setValue(ToDate);
        this.ReportForm.controls["ReportDateStart"].setValue(FromDate);

        if (val == "Date Range") {
            this.ReportForm.get("ReportDateStart").enable();
            this.ReportForm.get("DateEnd").enable();
        } else {
            this.ReportForm.get("ReportDateStart").disable();
            this.ReportForm.get("DateEnd").disable();
        }
    }

    /**
     * This function is used to emailing the Document
     * @param index -index number
     * @param item -document data.
     */
    ToEmailthedocument(index: any, item: any) {
        // let DocumentData: any = { DOCUMENTGUID: this.reportgenerateData.DATA.DOCUMENTGUID };
        // let DocumentName: any = { name: (this.reportgenerateData && this.reportgenerateData.DATA && this.reportgenerateData.DATA.DOCUMENTNAME) ? this.reportgenerateData.DATA.DOCUMENTNAME : '', index: 9999, };
        // let DocumentName: any = { name: '', index: 9999, };
        // let DocumentData: any = { DOCUMENTGUID: (selectedItem.DATA)?selectedItem.DATA.DOCUMENTGUID : ''};
        // let DocumentName: any = { name: (selectedItem && selectedItem.DATA && selectedItem.DATA.DOCUMENTNAME) ? selectedItem.DATA.DOCUMENTNAME : '', index: 9999, };
        // let DocumentData: any = { DOCUMENTGUID: (selectedItem.DATA)?selectedItem.DATA.DOCUMENTGUID : ''};
        // let DocumentName: any = { name: (selectedItem && selectedItem.DATA && selectedItem.DATA.DOCUMENTNAME) ? selectedItem.DATA.DOCUMENTNAME : '', index: 9999, };

        const dialogRef = this.dialog.open(SelectEmailPopupComponent, {
            width: "100%",
            disableClose: true,
        });
        this.behaviorService.SafeCustody$.subscribe((result) => {
            if (result) {
                // this.SafeCustodyData = result;
            }
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let EmailtemplateData: any = "";
                this.behaviorService.EmailGenerateData$.subscribe((result) => {
                    if (result) {
                        EmailtemplateData = result;
                    }
                });

                if (
                    this.data.REPORTID == "TrustAllTrustAccountStatements" ||
                    this.data.REPORTID == "TrustMatterLedgers"
                ) {
                    let selectpdf = this.pdfdata.filter(
                        (ele) => ele.DATA.MATTERGUID == item.MATTERGUID
                    );
                    if (this.isMulti) {
                        // let uniquedata = this.matteridarray.findIndex(e => e == this.storeMatterGuid[index].SHORTNAME)
                        // if (uniquedata == -1) {
                        // if(this.matteridarray.length != 0){
                        //   this.matteridarray.forEach((element,i) => {
                        //     this.storeMatterGuid.splice(i,1)
                        //   });

                        // this.reportgenerateData.DATA.MATTERGUID = this.succeslist[index]
                        this.reportgenerateData.DATA.MATTERGUID =
                            selectpdf[0] && selectpdf[0].DATA
                                ? selectpdf[0].DATA.MATTERGUID
                                : selectpdf.DATA.MATTERGUID;

                        // }
                    } else {
                        this.reportgenerateData.DATA.MATTERGUID =
                            selectpdf[0] && selectpdf[0].DATA
                                ? selectpdf[0].DATA.MATTERGUID
                                : selectpdf.DATA.MATTERGUID;
                    }
                    // }
                } else {
                    this.reportgenerateData.DATA.MATTERGUID = this
                        .storeMatterGuid[0].MATTERGUID
                        ? this.storeMatterGuid[0].MATTERGUID
                        : this.storeMatterGuid;
                }

                let indexid = this.reportData1.findIndex(
                    (e) =>
                        e.PDF_Generation.DATA.MATTERGUID ==
                        this.reportgenerateData.DATA.MATTERGUID
                );
                let selectedItem;
                if (index !== -1) {
                    selectedItem = this.reportData1[indexid];
                } else {
                    selectedItem = this.reportData1[0];
                }

                selectedItem =
                    selectedItem && selectedItem.PDF_Generation
                        ? selectedItem.PDF_Generation
                        : selectedItem;
                let DocumentData: any = {
                    DOCUMENTGUID: selectedItem.DATA
                        ? selectedItem.DATA.DOCUMENTGUID
                        : "",
                };
                let DocumentName: any = {
                    name:
                        selectedItem &&
                        selectedItem.DATA &&
                        selectedItem.DATA.DOCUMENTNAME
                            ? selectedItem.DATA.DOCUMENTNAME
                            : "",
                    index: 9999,
                };

                // let matterData = (selectedItem && selectedItem.DATA && selectedItem.DATA.MATTERGUID)?selectedItem.DATA : JSON.parse(localStorage.getItem('set_active_matters'));
                let matterData =
                    this.reportgenerateData.DATA &&
                    this.reportgenerateData.DATA.MATTERGUID != ""
                        ? this.reportgenerateData.DATA
                        : JSON.parse(
                              localStorage.getItem("set_active_matters")
                          );
                this.behaviorService.MatterData(null);
                let passdata = {
                    Context: "Matter",
                    ContextGuid: matterData.MATTERGUID,
                    knownby: "Email",
                    Type: "Email",
                    Folder: "",
                    Template: EmailtemplateData.NAME,
                    DocumentData: DocumentData,
                    DocumentName: DocumentName,
                    fromType: "matter",
                };
                this.ForEmailDialogOpen(passdata);
            }
        });
    }

    /**
     * This function is used to open the help window.
     * @param i 
     * @param item 
     */
    helpWindow(i, item?: any) {
        let selectpdf = this.pdfdata.filter(
            (ele) => ele.DATA.MATTERGUID == item.MATTERGUID
        );
        window.open(this.base_url + selectpdf[0].DATA.PDFFILENAME, "_blank");
    }

    /**
     * This function is used to open the data in excell 
     * @param i 
     * @param item 
     */
    openCSV(i, item?: any):void {
        let selectpdf = this.pdfdata.filter(
            (ele) => ele.DATA.MATTERGUID == item.MATTERGUID
        );
        window.open(this.base_url + selectpdf[0].DATA.CSVFILENAME, "_blank");
    }

/**
 * This function is used to  open the Dialog of the email
 * @param passdata -emaildata.
 */
    ForEmailDialogOpen(passdata) {
        const dialogRef = this.dialog.open(MatterDialogComponentForTemplate, {
            width: "100%",
            disableClose: true,
            data: passdata,
        });
        dialogRef.afterClosed().subscribe((result) => {});
    }

    /**
     * This Function is used to change the Multi fee earner
     * @param value -multi feeearner data.
     */
    multiFeeEarnerChange(value):void {
        // this.multiFeeEarnerList = [];
        // this.selected.forEach(element => {
        //   this.multiFeeEarnerList.push({ FEEEARNERGUID: element });
        // });
        if (value && value.includes("<None>")) {
            if (value.length > 1) {
                if (this.isNoneSelect) {
                    const none = ["<None>"];
                    this.ReportForm.controls["MULTIFEEEARNERLIST"].setValue(
                        none
                    );
                    this.isNoneSelect = false;
                } else {
                    if (value.includes("-1")) {
                        const array = [];
                        var filteredAry = this.responseData.FEEEARNERS.filter(
                            (e) => e.FEEEARNERGUID !== "<None>"
                        );
                        filteredAry.forEach((user: any) => {
                            array.push(user.FEEEARNERGUID);
                        });
                        array.push("-1");
                        this.ReportForm.controls["MULTIFEEEARNERLIST"].setValue(
                            array
                        );
                        this.isNoneSelect = true;
                    } else {
                        var filteredAry = value.filter((e) => e !== "<None>");
                        this.ReportForm.controls["MULTIFEEEARNERLIST"].setValue(
                            filteredAry
                        );
                        this.isNoneSelect = true;
                    }
                }
            } else {
                const none = ["<None>"];
                this.ReportForm.controls["MULTIFEEEARNERLIST"].setValue(none);
                this.isNoneSelect = true;
            }
        } else {
            if (value.includes("-1")) {
                const array = [];
                var filteredAry = this.responseData.FEEEARNERS.filter(
                    (e) => e.FEEEARNERGUID !== "<None>"
                );
                filteredAry.forEach((user: any) => {
                    array.push(user.FEEEARNERGUID);
                });
                array.push("-1");
                this.ReportForm.controls["MULTIFEEEARNERLIST"].setValue(array);
                this.isNoneSelect = true;
            }
        }
    }

    /**
     * This Function is used to get the selected value.
     * @param event -selected value
     */
    selectedValue(event: MatAutocompleteSelectedEvent): void {
        if (this.isMulti) {
            if (
                this.storeMatterGuid &&
                this.storeMatterGuid.findIndex(
                    (e) => e.MATTERGUID == event.option.value
                ) == -1
            ) {
                this.storeMatterGuid.push({
                    MATTERGUID: event.option.value,
                    SHORTNAME: event.option.viewValue.split("--")[0],
                });
                this.storeMatterShortName =
                    event.option.viewValue.split("--")[0];
                if (
                    this.mattername.indexOf(
                        event.option.viewValue.replace(/^\s+|\s+$/gm, "")
                    ) == -1
                ) {
                    this.mattername.push([event.option.viewValue]);
                    // this.mattername = [event.option.viewValue]
                    this.MatterInput.nativeElement.value = "";
                    this.ReportForm.controls["SHORTNAME"].setValue(null);
                }
            }
        } else {
            this.storeMatterGuid = event.option.value;
            this.storeMatterShortName = event.option.viewValue.split("--")[0];
            if (
                this.mattername.indexOf(
                    event.option.viewValue.replace(/^\s+|\s+$/gm, "")
                ) == -1
            ) {
                this.mattername.push([event.option.viewValue]);
                // this.mattername = [event.option.viewValue]
                this.MatterInput.nativeElement.value = "";
                this.ReportForm.controls["SHORTNAME"].setValue(null);
            }
        }
    }

    onKeyUp(event: any): void {
        const searchValue = event.target.value;
        this.searchTerms.next(searchValue);
    }

    /**
     * This function is used to get the matter data after search the matter name
     * @param event -Matter data.
     */
    public selectMatterFormSearch(event) {
        // let searchobj = { FastSearch: 'True', SearchString: event }

        let searchobj = {
            Action: "GetData",
            Filters: { FastSearch: "True", SearchString: event },
        }

        return this._mainAPiServiceService.getSetData(searchobj, "matter")
    }

    /**
     * This function is used to set the matter data to the matter chiep
     * @param data -matter data.
     */
    selectedmatterguid(data: any) {
        //  this.ReportForm.controls['MATTERGUID'].setValue(data.MATTERGUID);
        if (
            this.selectedData.map((ele, index) =>
                ele.MATTERGUID == data.MATTERGUID ? index : -1
            )[0] == -1 ||
            this.selectedData.length == 0
        ) {
            this.selectedData = [data];
        }
    }

    /**
     * This function is used to get the Matter data after click thr tab button
     * @param event -selected matter data.
     */
    async datashow(event) {
        if (this.isMulti) {
            this.exportdatavalue.RECORDS.forEach(async (element, i) => {
                if (i == 0) {
                    let data = element;
                    if (
                        this.storeMatterGuid &&
                        this.storeMatterGuid.findIndex(
                            (e) => e.MATTERGUID == data.MATTERGUID
                        ) == -1
                    ) {
                        this.storeMatterGuid = this.storeMatterGuid.concat([
                            data,
                        ]);
                        this.mattername.push([data.SELECTEDDESCRIPTION]);
                        if (event.input) {
                            event.input.value = "";
                        } else {
                            this.MatterInput.nativeElement.value = "";
                        }

                        this.ReportForm.controls["MATTERGUID"].setValue(
                            data.MATTERGUID
                        );
                        if (
                            this.selectedData.map((ele, index) =>
                                ele.MATTERGUID == data.MATTERGUID ? index : -1
                            )[0] == -1 ||
                            this.selectedData.length == 0
                        ) {
                            this.selectedData = [data];
                        }
                    }
                }
            });
        } else {
            this.exportdatavalue.RECORDS.forEach(async (element, i) => {
                if (i == 0) {
                    let data = element;
                    this.mattername.push([data.SELECTEDDESCRIPTION]);
                    // this.mattername = [data.SHORTNAME + '--' + data.MATTER]
                    this.storeMatterGuid = data.MATTERGUID;
                    this.storeMatterShortName = data.SHORTNAME;

                    if (event.input) {
                        event.input.value = "";
                    } else {
                        this.MatterInput.nativeElement.value = "";
                    }

                    this.ReportForm.controls["MATTERGUID"].setValue(
                        data.MATTERGUID
                    );
                    if (
                        this.selectedData.map((ele, index) =>
                            ele.MATTERGUID == data.MATTERGUID ? index : -1
                        )[0] == -1 ||
                        this.selectedData.length == 0
                    ) {
                        this.selectedData = [data];
                    }
                }
            });
        }
    }

    /**
     * This function is used to Remove the matter Data
     * @param fruit -Matter data
     */
    remove(fruit: any):void {
        if (this.isMulti) {
            const index = this.mattername.findIndex((e) => e == fruit);
            let sortname = this.mattername.splice(index, 1);
            if (
                this.storeMatterGuid.findIndex(
                    (e) => e.SHORTNAME == fruit[0].split("--")[0]
                ) != -1
            ) {
                const index1 = this.storeMatterGuid.findIndex(
                    (e) => e.SHORTNAME == fruit[0].split("-")[0]
                );
                this.storeMatterGuid.splice(index1, 1);
            }

            if (sortname) {
                this.rm(sortname[0]);
            }
        } else {
            const index = this.mattername.findIndex(
                (e) => e.ACCOUNTGUID == fruit.ACCOUNTGUID
            );
            if (index >= 0) {
                this.mattername.splice(index, 1);
                this.ReportForm.controls["SHORTNAME"].setValue("");
                this.ReportForm.controls["MATTERGUID"].setValue("");
            }
            let sortname = fruit[0].split("-");
            this.rm(sortname[0]);
        }
    }

    /**
     * This function is used to remove the matter
     * @param sortname -MAtter data
     */
    rm(sortname):void {
        if (this.isMulti) {
            this.selectedData.forEach((element: any, index: any) => {
                if (
                    element.SHORTNAME == sortname[0].replace(/^\s+|\s+$/gm, "")
                ) {
                    this.selectedData.splice(index, 1);
                }
            });
        } else {
            this.selectedData.forEach((element: any, index: any) => {
                if (element.SHORTNAME == sortname.replace(/^\s+|\s+$/gm, "")) {
                    this.selectedData.splice(index, 1);
                }
            });
        }
    }

    /**
     * This function is used to Add the Matter in the chiep
     * @param event -get the matter data
     */
    add(event: MatChipInputEvent): void {
        const value = (event.value || "").trim();
        this.matterlist.forEach((element: any) => {
            if (element.SHORTNAME == event.value) {
                // Add our fruit
                const array3 = this.mattername;
                if (value) {
                    if (
                        this.mattername.indexOf(
                            value.replace(/^\s+|\s+$/gm, "")
                        ) == -1
                    ) {
                        this.mattername = [value];
                    }
                }
            }
        });

        // Clear the input value
        if (event.input) {
            event.input.value = "";
        }
        //  event.chipInput!.clear();

        this.ReportForm.controls["SHORTNAME"].setValue(null);
    }
}

/**
 * Set the value on checkbox like(0 or 1).
 * @param check 
 * @returns 
 */
function valuechange(check) {
    if (check) {
        if (check == true) {
            return 1;
        } else if (check == false) {
            return 0;
        }
    } else {
        return "";
    }
}

/**
 * this function is used to delete the blank key in the array. 
 * @param ReportData -Array data.
 */
function keydelete(ReportData):void {
    Object.keys(ReportData).forEach(function (key) {
        let value = ReportData[key];
        if ((value === "" || value === null) && key != "DateFrom") {
            delete ReportData[key];
        }
    });
}
