import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { TimersService } from '@_services/timers.service';
import { BehaviorService } from '@_services/Behavior.service';
import { Subject, takeUntil } from 'rxjs';
import { CurrentUser } from 'src/app/_models/CurrentUser';
import { Router } from '@angular/router';

@Component({
    selector: 'app-searchandreplaceworkitem',
    templateUrl: './searchandreplaceworkitem.component.html',
    styleUrls: ['./searchandreplaceworkitem.component.scss']
})
export class SearchandreplaceworkitemComponent implements OnInit, OnDestroy {

    isspiner = false;
    searchTerm = '';
    replaceTerm = '';
    private _unsubscribeAll$: Subject<void> = new Subject();
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    @Input() SettingForm: FormGroup;
    @Input() errorWarningData: any;

    addData: any = [];
    TemplateName: any;
    getSelectedWIP: any;
    getSelectedWIPNewPrice: any = [];
    IsWipData: boolean = false;
    isLoadingResults: boolean = true;
    successMsg: any = 'Time entry update successfully';
    workItem: any;
    workItemUpdatedText = '';
    currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    FeeErner = (this.currentUser.WORKINGONBEHALFOFGUID) ? this.currentUser.WORKINGONBEHALFOFGUID : this.currentUser.UserId;
    listofUnbuildtimeEntry = [];
    showStartCancel: boolean;
    CurrentAdditionalText: any;
    UpdatedAdditionalext: any;
    readonly: boolean;
    Apicount=0;
    filterData: any[];
    constructor(private _mainAPiServiceService: MainAPiServiceService,
        private Timersservice: TimersService,
        private toastr: ToastrService,
        public MatDialog: MatDialog,
        private cd: ChangeDetectorRef,
        public dialogRef: MatDialogRef<SearchandreplaceworkitemComponent>,
        private behaviourService: BehaviorService,
        private router:Router) {

            if(this.router.url =="/invoice/bulk-invoices"){
                this.showStartCancel=true;
                }else{
                this.showStartCancel=false;
                }
        this.behaviourService.defultFeeErnerData$.pipe(takeUntil(this._unsubscribeAll$)).subscribe((result) => {
            if (result) {
                this.FeeErner = result;
            }
        })

    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        this.behaviourService.workInProgress$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(result => {
            if (result) {
                this.getSelectedWIP = result;
            }
        });

        this.loadData();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
    }

    /**
     * This function is used to replaceterm To UnbilledTime Entry.
     */
    async replaceTermToUnbilledTimeEntries() {
        if(this.router.url =="/invoice/bulk-invoices"){
            if (this.searchTerm.trim() == "" || this.searchTerm.trim() == " ") {
                this.toastr.error("Search Term is Required.");
                return;
            }
             
            if(this.Apicount == 0){
                this.filterData = this.listofUnbuildtimeEntry.filter((unbuildData) => {
                    return unbuildData.ADDITIONALTEXT.trim().toLowerCase().indexOf(this.searchTerm.trim().toLowerCase()) > -1 && (unbuildData.ITEMTYPEDESC == 'WIP' || unbuildData.ITEMTYPEDESC == 'Activity' || unbuildData.ITEMTYPEDESC == 'Sundry');
                });
            }
            //console.log(this.filterData,this.filterData.length);
            if (this.filterData.length == 0) {
                this.toastr.error("There are no time entries/fixed fees available for search and replace!");
                return;
            }

            // let re = new RegExp(`\\b${this.searchTerm.trim().toLowerCase()}\\b`, 'gi');
            let re = new RegExp(`${this.searchTerm.trim().toLowerCase()}`, 'gi');
            if(this.Apicount <= this.filterData.length-1){
                this.workItem = this.filterData[this.Apicount];
                this.isspiner = true;
                const str: string = this.filterData[this.Apicount].ADDITIONALTEXT;
                this.workItemUpdatedText = str.replace(re, this.replaceTerm);
                await this.ChangePrice().then(async() => {
                    // This is for increase the API Call by one
                    this.Apicount += this.filterData.length == 1 ? 0 : 1;
                    this.CurrentAdditionalText=(this.filterData.length-1 >= this.Apicount) ? this.filterData[this.Apicount].ADDITIONALTEXT :  this.UpdateDataValue();
                    this.UpdatedAdditionalext=(this.filterData.length-1 >= this.Apicount) ? this.filterData[this.Apicount].ADDITIONALTEXT.replace(re, this.replaceTerm) :  this.UpdateDataValue();
                })
            }else{
                  this.UpdateDataValue()  
            }
        }else{
            let finalLoadUnbuildTimeEntryList: any = [];
        if (this.searchTerm.trim() == "" || this.searchTerm.trim() == " ") {
            this.toastr.error("Search Term is Required.");
            return;
        };

        let filterData = this.listofUnbuildtimeEntry.filter((unbuildData) => {
            return unbuildData.ADDITIONALTEXT.trim().toLowerCase().indexOf(this.searchTerm.trim().toLowerCase()) > -1 && (unbuildData.ITEMTYPEDESC == 'WIP' || unbuildData.ITEMTYPEDESC == 'Activity' || unbuildData.ITEMTYPEDESC == 'Sundry');
        })
        if (filterData.length == 0) {
            this.toastr.error("There are no time entries/fixed fees available for search and replace!");
            return;
        }
            // let re = new RegExp(`\\b${this.searchTerm.trim().toLowerCase()}\\b`, 'gi');
            let re = new RegExp(`${this.searchTerm.trim().toLowerCase()}`, 'gi');
            for (let i = 0; i < filterData.length; i++) {
                await new Promise(async (next, reject) => {
                    this.workItem = await filterData[i];
                    this.confirmDialogRef = await this.MatDialog.open(FuseConfirmDialogComponent, {
                        disableClose: true,
                        width: '100%',
                    });
    
                    this.confirmDialogRef.componentInstance.confirmMessage = `Are you sure you want to change "${filterData[i].ADDITIONALTEXT}"  to  "${filterData[i].ADDITIONALTEXT.replace(re, this.replaceTerm)}"?`;
    
                    await this.confirmDialogRef.afterClosed().subscribe(async result => {
                        if (result) {
                            this.isspiner = true;
                            this.workItemUpdatedText = filterData[i].ADDITIONALTEXT.replace(re, this.replaceTerm);
                            this.UpdatedAdditionalext=this.workItemUpdatedText;
                            await this.ChangePrice().then(() => {
                                finalLoadUnbuildTimeEntryList.push(true);
                                next(true);
                            })
                        } else {
                            this.workItemUpdatedText = '';
                            finalLoadUnbuildTimeEntryList.push(false)
                            next(false);
                        }
                        this.confirmDialogRef = null;
                    });
                });
            };
    
            await Promise.all([finalLoadUnbuildTimeEntryList]).then((dataResponse) => {
                this.dialogRef.close(true);
                $('#refreshWorkInprogress').click();
                if(this.router.url=="/invoice/bulk-invoices"){
                    $("#refreshtabledetails").click();
                }
            }); 
        }
        
    };

    /**
     * This function is used to change the Price 
     */
    ChangePrice() {
        return new Promise((next, rej) => {
            this.isspiner = true;
            // this.workItem.ADDITIONALTEXT = this.replaceTerm;
            if (this.workItemUpdatedText !== '') {
               // this.workItem.ADDITIONALTEXT = this.workItemUpdatedText;
               this.workItem.ADDITIONALTEXT = this.UpdatedAdditionalext;
            };

            let PostData: any = this.workItem;
            this.successMsg = 'Search Term Update successfully';
            let PostTimeEntryData: any = { FormAction: 'update', VALIDATEONLY: true, Data: PostData };
            this.Timersservice.SetWorkItems(PostTimeEntryData).pipe(takeUntil(this._unsubscribeAll$)).subscribe(res => {
                if (res.CODE == 200 && res.STATUS == "success") {
                    this.checkValidation(res.DATA.VALIDATIONS, PostTimeEntryData).then(() => {
                        next('Done');
                    })
                } else if (res.CODE == 451 && res.STATUS == 'warning') {
                    this.checkValidation(res.DATA.VALIDATIONS, PostTimeEntryData).then(() => {
                        next('Done');
                    })
                } else if (res.CODE == 450 && res.STATUS == 'error') {
                    this.checkValidation(res.DATA.VALIDATIONS, PostTimeEntryData).then(() => {
                        next('Done');
                    })
                } else if (res.MESSAGE == 'Not logged in') {
                    this.dialogRef.close(false);
                    next(true);
                }
            }, err => {
                this.isspiner = false;
                this.toastr.error(err);
            });
        });
    };

    /**
     * This function is used to check the validation.
     */
    checkValidation(bodyData: any, PostTimeEntryData: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        return new Promise((next) => {
            // errorData
            bodyData.forEach(function (value) {
                if (value.VALUEVALID == 'NO') {
                    errorData.push(value.ERRORDESCRIPTION);
                    tempError[value.FIELDNAME] = value;
                } else if (value.VALUEVALID == 'WARNING') {
                    tempWarning[value.FIELDNAME] = value;
                    warningData.push(value.ERRORDESCRIPTION);
                }
            });

            this.errorWarningData = { "Error": tempError, 'warning': tempWarning };
            if (Object.keys(errorData).length != 0) {
                this.toastr.error(errorData);
                this.isspiner = false;
            } else if (Object.keys(warningData).length != 0) {
                // this.toastr.warning(warningData);
                this.confirmDialogRef = this.MatDialog.open(FuseConfirmDialogComponent, {
                    disableClose: true,
                    width: '100%',
                    data: warningData
                });
                this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
                this.confirmDialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        this.isspiner = true;
                        this.saveTimeEntry(PostTimeEntryData).then(() => {
                            this.confirmDialogRef = null;
                            next(true);
                        })
                    }
                    this.confirmDialogRef = null;
                });
            } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
                this.saveTimeEntry(PostTimeEntryData).then(() => {
                    next(true);
                })
            }
        });
    };

    /**
     * This function is used to save the time entry
     */
    saveTimeEntry(PostTimeEntryData: any) {
        return new Promise((next, rej) => {
            PostTimeEntryData.VALIDATEONLY = false;
            this.Timersservice.SetWorkItems(PostTimeEntryData).subscribe(res => {
                if (res.CODE == 200 && res.STATUS == "success") {
                    this.toastr.success(this.successMsg);
                    this.behaviourService.setnextReCalcTimeEntrie("saveTimeEntry");
                    next(true);
                    // this.dialogRef.close(true);
                    this.isspiner = false;
                } else if (res.CODE == 451 && res.STATUS == 'warning') {
                    this.toastr.warning(res.MESSAGE);
                    this.isspiner = false;
                    next(true);
                } else if (res.CODE == 450 && res.STATUS == 'error') {
                    this.toastr.warning(res.MESSAGE);
                    this.isspiner = false;
                    next(true);
                } else if (res.MESSAGE == 'Not logged in') {
                    this.dialogRef.close(false);
                    next(true);
                } else {
                    next(true);
                }
            }, err => {
                this.isspiner = false;
                this.toastr.error(err);
                next(true);
            });
        });
    }

    /**
     * This function is used to load the Data value.
     */
    async loadData() {
        this.isLoadingResults = true;
        let feData;
        let filterVal = JSON.parse(localStorage.getItem("wip_config"));
        let filterDataVal = localStorage.getItem("FEEEARNERDATA");
        let matterGuid = JSON.parse(localStorage.getItem('set_active_matters'));
        if (!this.getSelectedWIP) {
            this.getSelectedWIP = matterGuid;
        }
        feData = this.router.url == '/invoice/bulk-invoices' ? '' : (filterVal && filterVal.FEEEARNER) ? filterVal.FEEEARNER : this.FeeErner; 

        let potData = { 'MatterGuid': this.getSelectedWIP.MATTERGUID, INVOICING: true, FEEEARNER: feData, INVOICED: "No", ITEMDATESTART: "", ITEMDATEEND: "" };
        this._mainAPiServiceService.getSetData({ "Action": "GetData", "Filters": potData }, 'workitem').pipe(takeUntil(this._unsubscribeAll$)).subscribe(async res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.listofUnbuildtimeEntry = res.DATA.WORKITEMS;
            } else {
                this.listofUnbuildtimeEntry = [];
            };
            this.isLoadingResults = false;
        }, err => {
            this.isLoadingResults = false;
            this.toastr.error(err);
        });

    }

    /**
     * This function is used to Check the TermToUNbilledTimeENTRIES
     */
    CheckTermToUnbilledTimeEntries(){
        if (this.searchTerm.trim() == "" || this.searchTerm.trim() == " ") {
            this.toastr.error("Search Term is Required.");
            return;
        };

        // let filterData = this.listofUnbuildtimeEntry.filter((unbuildData) => {
        //   return unbuildData.ADDITIONALTEXT.toLowerCase() == this.searchTerm.toLowerCase() && (unbuildData.ITEMTYPEDESC == 'WIP' || unbuildData.ITEMTYPEDESC == 'Activity' || unbuildData.ITEMTYPEDESC == 'Sundry');
        // });


        let filterData = this.listofUnbuildtimeEntry.filter((unbuildData) => {
            return unbuildData.ADDITIONALTEXT.trim().toLowerCase().indexOf(this.searchTerm.trim().toLowerCase()) > -1 && (unbuildData.ITEMTYPEDESC == 'WIP' || unbuildData.ITEMTYPEDESC == 'Activity' || unbuildData.ITEMTYPEDESC == 'Sundry');
        });
        if (filterData.length == 0) {
            this.toastr.error("There are no time entries/fixed fees available for search and replace!");
            return;
        };

        //console.log(filterData,filterData.length);
        // this.CurrentAdditionalText=this.searchTerm;
        // this.UpdatedAdditionalext=this.replaceTerm;
        // let re = new RegExp(`\\b${this.searchTerm.trim().toLowerCase()}\\b`, 'gi');
        let re = new RegExp(`${this.searchTerm.trim().toLowerCase()}`, 'gi');
        this.CurrentAdditionalText=(filterData.length-1 >= this.Apicount) ? filterData[this.Apicount].ADDITIONALTEXT :  this.UpdateDataValue();
        const str: string = filterData[this.Apicount].ADDITIONALTEXT;
        this.UpdatedAdditionalext=(filterData.length-1 >= this.Apicount) ? str.replace(re, this.replaceTerm) :  this.UpdateDataValue();
            this.readonly=true;
            this.isLoadingResults=true;
            this.isspiner=true;
        setTimeout(() => {
            this.showStartCancel=false;
            this.isLoadingResults=false;
            this.isspiner=false
        },1000);

    }

    /**
     * This function is used to update the Data value.
     */
    UpdateDataValue(){
        this.dialogRef.close(true);
        $('#refreshWorkInprogress').click();
        if(this.router.url=="/invoice/bulk-invoices"){
            $("#refreshtabledetails").click();
        }
    }

}
