<div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [style.zoom]="0.5" *ngIf="isLoadingResults"></mat-spinner>
</div>
<div class="nameCount-bank" [ngClass]="{'light-theme-back': currentUser.THEMECOLOUR === 'theme-yellow-light'}" *ngIf="chartAccountDetail && this.chartAccountDetail.name.length >4" >
    {{ this.chartAccountDetail.name }}
</div>
<!-- FILTER START TO HERE -->
<form [formGroup]="SearchForm" class="align-center display-flex filter-menuwrap form-div-side" id="filter-form-menu-wrap">
    <div class="align-center display-flex flex-wrap filter-menublock">
        <div class="add-filterwrap">
        <mat-icon class="icon-btn" (click)="getList()">refresh</mat-icon>
        </div>
        <div class="position-relative">
            <mat-icon class="badge-ic"
            (click)="clearFilter('SearchString')">
            {{(SearchForm.get('search').value)?'cancel_circle' : ''}}
            </mat-icon>

            <button mat-button class="buttonwith-text" style="padding: 1px 5px;">
                Search<span class="filter-applytext display-flex align-center">

                        <input matInput  #searchField
                        (keyup)="onSearchFilter($event)"
                        formControlName="search" style="margin-top: 4px;text-align: left;" tabindex="6">
                        <mat-icon matSuffix (click)="onSearchFilter('Enter')">search</mat-icon>

                </span>
            </button>
        </div>

    <!-- filter for day range -->
    <div class="position-relative">
        <mat-icon class="badge-ic"
            (click)="(activeFilters && activeFilters.dayRangeDesc)?clearFilter('dayRange'):false">
            {{(activeFilters && activeFilters.dayRangeDesc && activeFilters.dayRangeDesc !='All time')?'cancel_circle' : 'add_circle'}} </mat-icon>
        <button mat-button [matMenuTriggerFor]="menuMatterClass"
            class="buttonwith-text dotted-button">
            Date Range<span class="filter-applytext display-flex align-center">{{(activeFilters &&
                activeFilters.dayRangeDesc)?activeFilters.dayRangeDesc : ''}}<mat-icon
                    class="rightdownarrow">
                    keyboard_arrow_down</mat-icon></span>
        </button>
        <mat-menu #menuMatterClass="matMenu">
            <h5 class="filter-title">Filter by Date Range</h5>
            <mat-radio-group aria-labelledby="filter-radio-group-label" class="filter-radio-group"
                formControlName="DayRange">
                <mat-radio-button class="filter-radio-button"
                    *ngFor="let dayRange of dayRangeOptions" value="{{dayRange.value}}"
                    (click)="Filterconfig(dayRange,'day_range');SelectDayRangeFilter(dayRange.value);">
                    {{dayRange.description}}
                </mat-radio-button>
            </mat-radio-group>
        </mat-menu>
    </div>
    <!-- ends here ~ filter for day range -->

     <!-- filter for date range -->
     <div class="position-relative" *ngIf="forHideShowDateRangePicker">
        <mat-icon class="badge-ic"
            (click)="(activeFilters && activeFilters.DateRange)?clearFilter('DateRange'):false">
            {{(activeFilters && activeFilters.DateRange)?'cancel_circle' : 'add_circle'}}
        </mat-icon>
        <button mat-button [matMenuTriggerFor]="menuFeeEarner"
            class="buttonwith-text dotted-button">
            Date Range<span class="filter-applytext display-flex align-center">{{(activeFilters &&
                activeFilters.DateRange)?activeFilters.DateRange : ''}}<mat-icon
                    class="rightdownarrow">
                    keyboard_arrow_down</mat-icon></span>
        </button>
        <mat-menu #menuFeeEarner="matMenu" [ngClass]="COLOR">
            <h5 class="filter-title">Filter by Date Range</h5>
            <mat-form-field appearance="outline" class="example dateinput">
                <input matInput [satDatepicker]="picker2" formControlName="date"
                    (dateChange)="Filterconfig($event,'date_range')">
                <sat-datepicker #picker2 [rangeMode]="true"> </sat-datepicker>
                <sat-datepicker-toggle matSuffix [for]="picker2"></sat-datepicker-toggle>
            </mat-form-field>

            <button mat-raised-button color="primary" class="width-full accent"
                (click)="DateRangeFilter('change',activeFilters.DateRange)">Apply</button>
        </mat-menu>
    </div>
    <!-- ends here ~ filter for date range -->

    </div>
</form>
<!-- FILTER END FROM HERE -->

<div class="header-class" [ngClass]="{'light-theme-back': currentUser.THEMECOLOUR === 'theme-yellow-light'}" *ngIf="bankFeedList.length > 0 && this.chartAccountDetail.name.length >4">
    <div class="header-one width-30">
        <!-- <a href="javascript:void(0)">What's this?</a><br> -->
        <h3 style="margin-top: 5px;margin-bottom: 0px;">Review your bank statement lines...</h3>
        <div class="border-line line-width-1"></div> 
    </div>
    <div>
    </div>
    <div class="header-two width-60">
        <h3 style="margin-top: 5px;margin-bottom: 0px;">... then match with your transactions in SILQ</h3>
        <div class="border-line line-width-2"></div> 
    </div>
</div>
<div class="main-list" [ngClass]="{'light-theme-back': currentUser.THEMECOLOUR === 'theme-yellow-light'}">
    <form [formGroup]="bankFeedForm" (keydown.enter)="preventFormSubmit($event)" *ngIf="bankFeedList.length > 0 && this.chartAccountDetail.name.length >4 ">
        <div formArrayName="bankAccounts">
            <div *ngFor="let bank of bankAccountsList().controls; let bankIndex = index;" class="one justify-content-between">
                <ng-container [formGroupName]="bankIndex">
                    <div class="first-div width-30 bankfeed-box" [ngClass]="{'light-theme-list': currentUser.THEMECOLOUR === 'theme-yellow-light', 'grey-theme-list': currentUser.THEMECOLOUR === 'theme-blue-gray-dark' }">
                        <div class="">
                            <div class="select-div">
                                <!-- <mat-form-field appearance="outline" class="bank-input">
                                    <mat-select placeholder="Options">
                                        <mat-option value="0">Create bank rule</mat-option>
                                        <mat-option value="1">Delete statement line</mat-option>
                                    </mat-select>
                                </mat-form-field> -->
                            </div>
                            <div class="main-disp justify-content-center">
                                <div class="left-disp px-5">
                                    <div>
                                        <b>{{bank.get('TRANSACTIONDATE').value}}</b>
                                    </div>
                                    <div>
                                        <b>{{bank.get('TRANSACTIONTYPE').value}}</b>
                                    </div>
                                    <!-- <div>
                                        <a href="javascript:void(0)" (click)="openMoreText(i)" class="btn-color">More Details</a>
                                    </div> -->
                                </div>
                                <div class="auth-btn px-5">
                                    <div>
                                        <span>Spent</span>
                                    </div>
                                    <div>
                                        <b>{{checkNum(bank.get('AMOUNT').value) === -1 ? bank.get('AMOUNT').value : ''}}</b>
                                    </div>
                                </div>
                                <div class="auth-btn px-5">
                                    <div>
                                        <span>Received</span>
                                    </div>
                                    <div>
                                        <b>{{checkNum(bank.get('AMOUNT').value) === 1 ? bank.get('AMOUNT').value : ''}}</b>
                                    </div>
                                </div>
                            </div>
                            <div class="more-details">
                                <!-- [ngClass]="selectedIndex == bankIndex ? 'show-more-text' : ''" -->
                                <b>{{bank.get('DETAILS').value}}</b>
                            </div>
                        </div>
                        <div class="dismiss-btn">
                            <button mat-raised-button class="mat-button mat-raised-button mat-accent" [disabled]="isspinerDF" (click)="DismissFeed(bank)">
                                <mat-spinner style=" zoom: 0.2;position: absolute;right: 0;left: 0; margin: auto;top: 0;bottom: 0;" *ngIf="isspinerDF"></mat-spinner>
                                Dismiss</button>
                        </div>
                    </div>
                    <div class="main-icon">
                        <mat-icon>
                            <img [src]="showgrayicon ? 'assets/icons/web_app/right-arrow-50.png' : 'assets/icons/web_app/arrow-right.svg'" alt="Arrow">>
                        </mat-icon>
                    </div>
                    <div class="second-div width-60" [ngClass]="{'second-div-grey': currentUser.THEMECOLOUR === 'theme-blue-gray-dark'}">
                        <!-- [(selectedIndex)]="demo1TabIndex" -->
                        <mat-tab-group animationDuration="0ms" #tab{{bankIndex}} (selectedTabChange)="onTabChangedForm(bank,$event, bankIndex);">
                            <mat-tab label="Create">
                                <div fxLayout="column" class="secd-list space-list" [ngClass]="{'light-theme-list': currentUser.THEMECOLOUR === 'theme-yellow-light', 'grey-theme-list': currentUser.THEMECOLOUR === 'theme-blue-gray-dark'}">
                                    <ng-container formGroupName="generalReceiptForm">
                                        <div fxLayout="row" class="receive-create" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="checkNum(bank.get('AMOUNT').value) === 1">
                                            <mat-form-field appearance="outline" class="pr-4 name-input-6" *ngIf="checkNum(bank.get('AMOUNT').value) === 1 && bank.get('SELECTEDINDEX').value == false">
                                                <mat-label>Class:</mat-label>
                                                <!-- [(ngModel)]="recClassType" -->
                                                <mat-select formControlName="INCOMECLASS" (selectionChange)="classChangeForm($event.value, bank)">
                                                    <mat-option value="Income">Income</mat-option>
                                                    <!-- <mat-option value="Receipt">Receipt</mat-option> -->
                                                    <mat-option value="Capital">Capital</mat-option>
                                                    <mat-option value="GST Refund"> GST Refund </mat-option>
                                                    <mat-option value="Tax Refund">Tax Refund</mat-option>
                                                    <mat-option value="Personal">Personal</mat-option>
                                                    <mat-option value="Other">Other</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="pr-4 name-input" *ngIf="checkNum(bank.get('AMOUNT').value) === 1 && bank.get('SELECTEDINDEX').value == false">
                                                <mat-label>Payor:</mat-label>
                                                <!-- <input matInput placeholder="Name of the contact...">
                                                <mat-icon matSuffix class="secondary-text"></mat-icon> -->
                                                <!-- <mat-chip-list #chipList aria-label="Matter selection"> -->
                                                    <!-- <mat-chip *ngFor="let Contactdata of Contactname" (removed)="removeContact(Contactdata)" class="mat-chips-selected">
                                                        {{Contactdata| slice:0:40}}<ng-container *ngIf="Contactdata.length>30">...</ng-container>
                                                        <button  matChipRemove class="canclebutton">
                                                            <mat-icon style="font-size: 18px !important;">cancel</mat-icon>
                                                        </button>
                                                    </mat-chip> -->
                                                    <!-- [(ngModel)]="recWho" -->
                                                    <!-- [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                        (matChipInputTokenEnd)="addContactForm($event, bank)" -->
                                                        <!-- (keyup)='selectContactFormSearch(MatterInput2?.value)' #MatterInput2-->
                                                    <input matInput
                                                        (keydown.Tab)="ContactdatashowForm($event, bank)" 
                                                         [matAutocomplete]="autoContact"
                                                         (input)="onReceiveChange(bank)" formControlName="PAYEE">
                                                         <mat-icon matSuffix  *ngIf="getCommonVal(bank,'generalReceiptForm','PAYEE') !== ''" (click)="removeContactGR(bank)" class="float-left reset-path-btn">close</mat-icon>
                                                        <!-- [ngClass]="Contactname.length > 0?'hideInput':'showInput'" -->
                                                <!-- </mat-chip-list> -->
                                                <mat-autocomplete #autoContact="matAutocomplete">
                                                    <mat-option class="mat-option_1" *ngFor="let ContactData of Contactlist" (click) ="selectedContactForm(ContactData, bank)" >
                                                        <!-- {{data.SHORTNAME}}--{{data.MATTER}} --><span *ngIf="ContactData && !ContactData?.CONTACTGUID && ContactData.CONTACTNAME"><b>Add  </b></span>&nbsp; <span *ngIf="ContactData && !ContactData?.CONTACTGUID && ContactData.CONTACTNAME"><b>"</b></span>{{ContactData.CONTACTNAME}}<span *ngIf="ContactData && !ContactData?.CONTACTGUID && ContactData.CONTACTNAME"><b>"</b></span>
                                                    </mat-option>
                                                </mat-autocomplete>
                                                <mat-icon matSuffix (click)="(getRMValue(bank) == '') ? selectClientForm(bank) : null">
                                                    <img src="assets/icons/web_app/icon_contact_d.ico">
                                                </mat-icon>
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="pr-4 name-input-6" *ngIf="checkNum(bank.get('AMOUNT').value) === 1 && bank.get('SELECTEDINDEX').value == false">
                                                <mat-label>{{formValueRM(bank)}}</mat-label>
                                                <!-- [(ngModel)]="recWhat" -->
                                                <input matInput formControlName="INCOMEACCOUNTGUIDTEXT">
                                                    <!-- <mat-option value="0">GST Exclusive</mat-option>
                                                    <mat-option value="1">GST Inclusive</mat-option>
                                                    <mat-option value="2">GST Free</mat-option> -->
                                                    <mat-icon matSuffix>
                                                        <button id="bank" class="matternews" (click)="BankingDialogOpenForm(bank)">
                                                            <img src="assets/icons/web_app/icon_accounts_small_d.ico"></button>
                                                    </mat-icon>
                                            </mat-form-field>
                                        </div>
                                        <div fxLayout="row" class="receive-create" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="checkNum(bank.get('AMOUNT').value) === 1">
                                            <mat-form-field appearance="outline" class="pr-4 name-input-5" *ngIf="checkNum(bank.get('AMOUNT').value) === 1 && bank.get('SELECTEDINDEX').value == false">
                                                <mat-label>Note:</mat-label>
                                                <!-- <mat-select>
                                                    <mat-option *ngFor="let val of incomeData" [value]="val.ACCOUNTGUID">{{val.ACCOUNTNAME}}</mat-option>
                                                </mat-select> -->
                                                <!-- [(ngModel)]="recWhy" -->
                                                <input matInput placeholder="Note" formControlName="NOTE">
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="pr-4 name-input-4" *ngIf="checkNum(bank.get('AMOUNT').value) === 1 && bank.get('SELECTEDINDEX').value == false">
                                                <mat-label>GST Type:</mat-label>
                                                <!-- [(ngModel)]="gstType" -->
                                                <mat-select (selectionChange)="gstTypeChangeForm($event.value, bank)" formControlName="gsttype" [disabled]="getCommonVal(bank,'generalReceiptForm','isHide')">
                                                    <mat-option *ngFor="let val of gsttypeData" [value]="val.id">{{val.text}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="pr-4 name-input-4" *ngIf="checkNum(bank.get('AMOUNT').value) === 1 && bank.get('SELECTEDINDEX').value == false && getCommonVal(bank,'generalReceiptForm','flaxValue') == 20">
                                                <mat-label>GST</mat-label>
                                                <!-- [(ngModel)]="gst" -->
                                                <input matInput formControlName="GST" class="sideText" (focusout)="gstValForm(bank)" currencyMask [readonly]="getCommonVal(bank,'generalReceiptForm','isGstReadonly')">
                                            </mat-form-field>
                                        </div>
                                        <div fxLayout="row" class="save-btn2 receive-create" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="bank.get('SELECTEDINDEX').value == false">
                                            <div>
                                                <button mat-raised-button class="mat-button mat-raised-button mat-primary mat-accent" *ngIf="checkNum(bank.get('AMOUNT').value) === 1" (click)="defaultRecMoneyDataForm(bank,bankIndex)">Matter Receipt</button>
                                            </div>
                                            <div>
                                                <button mat-raised-button *ngIf="checkNum(bank.get('AMOUNT').value) === 1" class="mat-button mat-raised-button mat-primary mat-accent" (click)="SaveReceiptAfterVAlidationForm(bank)">Ok</button>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container formGroupName="spendmoneyForm">
                                        <ng-container *ngIf="bank.get('MultiSpend').value == false">
                                            <div fxLayout="row" class="receive-create" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="checkNum(bank.get('AMOUNT').value) === -1">
                                                <mat-form-field appearance="outline" class="pr-4 name-input-6" *ngIf="checkNum(bank.get('AMOUNT').value) === -1 && bank.get('SELECTEDSPENDINDEX').value == false">
                                                    <mat-label>Class:</mat-label>
                                                    <!-- [(ngModel)]="spentClassType" -->
                                                    <mat-select formControlName="Class" (selectionChange)="ClasstypeForm($event.value,bank)">
                                                        <mat-option value="Expense">Expense</mat-option>
                                                        <ng-container *ngIf="appPermissions && appPermissions[2]">
                                                            <mat-option value="Matter Expense"  [disabled]="(appPermissions[2] && appPermissions[2]['Create Disbursements']==0)?true:false" [ngClass]="appPermissions[2] && appPermissions[2]['Create Disbursements']==0 ? 'disabled-click-cursor ribbon-section' : 'ribbon-section'">Matter Expense</mat-option>
                                                        </ng-container>
                                                        <mat-option value="Capital">Capital</mat-option>
                                                        <mat-option value="Pay GST">Pay GST</mat-option>
                                                        <mat-option value="Pay Tax">Pay Tax</mat-option>
                                                        <mat-option value="Personal">Personal</mat-option>
                                                        <mat-option value="Depreciation">Depreciation</mat-option>
                                                        <mat-option value="Other">Other</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <mat-form-field class="pr-4 spend-money-field" appearance="outline" fxFlex="20" *ngIf="getClassType(bank) == 'Matter Expense'">
                                                    <mat-label>Matter</mat-label>
                                                    <input [matAutocomplete]="autoMatter" matInput (keydown.Tab)="datashow($event)" formControlName="Matter" (input)='onSpentMatterChange(bank)'>
                                                    <mat-autocomplete #autoMatter="matAutocomplete" >
                                                        <mat-option class="mat-option_1" *ngFor="let data of matterlist" (click)="selectedmatterguid(bank,data)" value="{{data.MATTERGUID}}">
                                                            {{data.SELECTEDDESCRIPTION}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                    <!-- [ngClass]="getCommonVal(bank,'spendmoneyForm','hide') ? 'visibility-hidden' : ''" -->
                                                    <!-- [ngClass]="getCommonVal(bank,'spendmoneyForm','hide') ?'menu-disabled':''" -->
                                                    <mat-icon matSuffix>
                                                        <button id="mattersnew" class="matternews" (click)='selectMatterForm(bank)'><img src="assets/icons/web_app/icon_matter_d.ico" matTooltip matTooltipPosition="above">
                                                        </button>
                                                    </mat-icon>
                                                </mat-form-field>
                                                <mat-form-field *ngIf="checkNum(bank.get('AMOUNT').value) === -1" appearance="outline" class="pr-4 name-input">
                                                    <mat-label>Payee:</mat-label>
                                                   
                                                    <input matInput (keydown.Tab)="ContactPayeedatashowForm($event, bank)"
                                                         [matAutocomplete]="autoContactPayee" (input)="onSpendChange(bank)" formControlName="Payee">
                                                    <mat-autocomplete #autoContactPayee="matAutocomplete">
                                                        <mat-option class="mat-option_1" *ngFor="let ContactData of ContactlistPayee"
                                                            (click)="selectedContactPayeeForm(ContactData, bank)">
                                                            <!-- {{data.SHORTNAME}}--{{data.MATTER}} --><span *ngIf="ContactData && !ContactData?.CONTACTGUID && ContactData.CONTACTNAME"><b>Add  </b></span>&nbsp; <span *ngIf="ContactData && !ContactData?.CONTACTGUID && ContactData.CONTACTNAME"><b>"</b></span>{{ContactData.CONTACTNAME}}<span *ngIf="ContactData && !ContactData?.CONTACTGUID && ContactData.CONTACTNAME"><b>"</b></span>
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                    <mat-icon matSuffix (click)="(getFormValue(bank) == '')? ContactMatterForm(bank):null"  class="payee-icon"  [ngClass]="appPermissions[3]?.Access==0 ? 'disabled-click large-button' : 'large-button'">
                                                        <img src="assets/icons/web_app/icon_contact_d.ico">
                                                    </mat-icon>
                                                </mat-form-field>
                                                <mat-form-field appearance="outline" class="pr-4 name-input-6" *ngIf="checkNum(bank.get('AMOUNT').value) === -1 && bank.get('SELECTEDSPENDINDEX').value == false">
                                                    
                                                    <mat-label
                                                        *ngIf="getClassType(bank)=='Expense' || getClassType(bank)=='Matter Expense' || getClassType(bank)=='Depreciation' || getClassType(bank)=='Other'">
                                                        Expense a/c </mat-label>
                                                    <mat-label *ngIf="getClassType(bank) == 'Capital'">Asset a/c </mat-label>
                                                    <mat-label *ngIf="getClassType(bank) == 'Pay GST'">GST a/c </mat-label>
                                                    <mat-label *ngIf="getClassType(bank) == 'Pay Tax'">Tax a/c</mat-label>
                                                    <mat-label *ngIf="getClassType(bank) == 'Personal'">Equity a/c </mat-label>
                                                    <!-- <input matInput placeholder="Enter a description..."> -->
                                                    <!-- (input)="onInputChange($event.target.value)" -->
                                                    <input matInput formControlName="Expenseac"
                                                        (keyup)="getAccountInfoForm('ExpenseClick',bank, $event)">
                                                        <!-- [(ngModel)]="spentWhat" -->
                                                        <mat-icon matSuffix>
                                                            <button id="bank" class="matternews" (click)="BankingDialogOpenPayeeForm('','ExpenseClick',$event, bank)">
                                                                <img src="assets/icons/web_app/icon_accounts_small_d.ico"></button>
                                                        </mat-icon>
                                                </mat-form-field>
                                            </div>
                                            <div fxLayout="row" class="receive-create" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="checkNum(bank.get('AMOUNT').value) === -1">
                                                <mat-form-field appearance="outline" class="pr-4 name-input-5" *ngIf="checkNum(bank.get('AMOUNT').value) === -1">
                                                    <mat-label>Note:</mat-label>
                                                  
                                                    <input matInput formControlName="Notes">
                                                </mat-form-field>
                                                <mat-form-field appearance="outline" class="pr-4 name-input-4" *ngIf="bank.get('SELECTEDSPENDINDEX').value == false && checkNum(bank.get('AMOUNT').value) === -1">
                                                    <mat-label>GST Type:</mat-label>
                                                    
                                                    <mat-select formControlName="GSTType" (selectionChange)="GstTypeforSelectform($event.value, bank)">
                                                        <mat-option value="10% GST">10% GST</mat-option>
                                                        <mat-option value="No GST">No GST</mat-option>
                                                        <mat-option value="Less Than 10% GST">Less Than 10% GST</mat-option>
                                                        <mat-option *ngIf="getClassType(bank) ==='Matter Expense'" value="No GST(Charge GST)">No
                                                            GST(Charge
                                                            GST)
                                                        </mat-option>
                                                        <mat-option *ngIf="getClassType(bank) ==='Matter Expense'" value="Less Than 10% GST(Charge GST)">Less
                                                            Than 10% GST(Charge GST)</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <mat-form-field appearance="outline" class="pr-4 name-input-4" *ngIf="checkNum(bank.get('AMOUNT').value) === -1">
                                                    <mat-label>GST</mat-label>
                                                   
                                                    <input currencyMask class="sideText" [max]="maxVal"
                                                    [disabled]="GstTypeDiff =='Less Than 10% GST' || 'Less Than 10% GST(Charge GST)'" matInput
                                                    formControlName="GST1" (focusout)="amountCalForm(bank)">
                                                </mat-form-field>
                                            </div>
                                            <div fxLayout="row" class="save-btn2 receive-create" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="bank.get('SELECTEDSPENDINDEX').value == false">
                                                <div>
                                                    <button mat-raised-button class="mat-button mat-raised-button mat-primary mat-accent" *ngIf="checkNum(bank.get('AMOUNT').value) === -1" (click)="defaultSpenMoneyData(bank,bankIndex,true)">Multi line</button>
                                                </div>
                                                <div>
                                                    <button mat-raised-button *ngIf="checkNum(bank.get('AMOUNT').value) === -1" class="mat-button mat-raised-button mat-primary mat-accent" (click)="saveSpendMoneyDataForm(bank)">Ok</button>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="bank.get('MultiSpend').value == true">
                                            <div fxLayout="row" class="receive-create" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="checkNum(bank.get('AMOUNT').value) === -1">

                                                <mat-form-field *ngIf="checkNum(bank.get('AMOUNT').value) === -1" appearance="outline" class="pr-4 name-input">
                                                    <mat-label>Payee:</mat-label>
                                                   
                                                    <input matInput (keydown.Tab)="ContactPayeedatashowForm($event, bank)"
                                                         [matAutocomplete]="autoContactPayee" (input)="onSpendChange(bank)" formControlName="Payee">
                                                    <mat-autocomplete #autoContactPayee="matAutocomplete">
                                                        <mat-option class="mat-option_1" *ngFor="let ContactData of ContactlistPayee"
                                                            (click)="selectedContactPayeeForm(ContactData, bank)">
                                                            <!-- {{data.SHORTNAME}}--{{data.MATTER}} --><span *ngIf="ContactData && !ContactData?.CONTACTGUID && ContactData.CONTACTNAME"><b>Add  </b></span>&nbsp; <span *ngIf="ContactData && !ContactData?.CONTACTGUID && ContactData.CONTACTNAME"><b>"</b></span>{{ContactData.CONTACTNAME}}<span *ngIf="ContactData && !ContactData?.CONTACTGUID && ContactData.CONTACTNAME"><b>"</b></span>
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                    <mat-icon matSuffix (click)="(getFormValue(bank) == '')? ContactMatterForm(bank):null"  class="payee-icon"  [ngClass]="appPermissions[3]?.Access==0 ? 'disabled-click large-button' : 'large-button'">
                                                        <img src="assets/icons/web_app/icon_contact_d.ico">
                                                    </mat-icon>
                                                </mat-form-field>


                                                <mat-form-field appearance="outline" fxFlex="15" class="pr-4 spend-money-field">
                                                    <mat-label>GST Total</mat-label>
                                                        <!-- [(ngModel)]="GSTValForInGst" -->
                                                    <input style="text-align: right;" class="sideText" matInput type="text" formControlName="GstTotal" (focusout)="amountCalForm(bank)">

                                                </mat-form-field>

                                                <mat-form-field appearance="outline" fxFlex="15" class="pr-4 spend-money-field">
                                                    <mat-label>Amount
                                                        Total</mat-label>
                                                        <!-- [(ngModel)]="GSTValForInGst" -->
                                                    <input style="text-align: right;" class="sideText" matInput type="text" formControlName="AmountTotal" (focusout)="amountCalForm(bank)">

                                                </mat-form-field>


                                                <mat-form-field appearance="outline" fxFlex="15" class="pr-4 spend-money-field">
                                                    <mat-label>Out of Balance</mat-label>
                                                        <!-- [(ngModel)]="GSTValForInGst" -->
                                                    <input style="text-align: right;" class="sideText" matInput type="text" formControlName="OutofBalance" (focusout)="amountCalForm(bank)">

                                                </mat-form-field>



                                               

                                                <!-- <mat-form-field appearance="outline" class="pr-4 name-input-4" *ngIf="bank.get('SELECTEDINDEX').value == false && checkNum(bank.get('AMOUNT').value) === -1">
                                                    <mat-label>GST Type:</mat-label>
                                                   
                                                    <mat-select formControlName="GSTType" (selectionChange)="GstTypeforSelectform($event.value, bank)">
                                                        <mat-option value="10% GST">10% GST</mat-option>
                                                        <mat-option value="No GST">No GST</mat-option>
                                                        <mat-option value="Less Than 10% GST">Less Than 10% GST</mat-option>
                                                        <mat-option *ngIf="getClassType(bank) ==='Matter Expense'" value="No GST(Charge GST)">No
                                                            GST(Charge
                                                            GST)
                                                        </mat-option>
                                                        <mat-option *ngIf="getClassType(bank) ==='Matter Expense'" value="Less Than 10% GST(Charge GST)">Less
                                                            Than 10% GST(Charge GST)</mat-option>
                                                    </mat-select>
                                                </mat-form-field> -->

                                                
                                            </div>

                                            <div fxLayout="row" class="receive-create" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="checkNum(bank.get('AMOUNT').value) === -1">
                                                <mat-form-field appearance="outline" class="pr-4 note-input-5" *ngIf="checkNum(bank.get('AMOUNT').value) === -1">
                                                    <mat-label>Note:</mat-label>
                                                    <!-- <mat-select [(ngModel)]="spentWhy">
                                                    <mat-option *ngFor="let val of expenseData" [value]="val.ACCOUNTGUID">{{val.ACCOUNTNAME}}</mat-option>
                                                </mat-select> -->
                                                    <input matInput formControlName="Notes">
                                                </mat-form-field>

                                               

                                                <button mat-flat-button [disabled]="bank.get('MultiSpendTable').value==true" (click)="createSpendMoney(bank,bankIndex,true);" class="ml-4 ng-tns-c142-6 mdc-button spend-money-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary mat-mdc-button-base"><span class="mat-mdc-button-persistent-ripple mdc-button__ripple"></span>
                                                    <mat-icon role="img" class="mat-icon notranslate mat-icon-no-color" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="plus" data-mat-icon-namespace="heroicons_outline"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" fit height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                                                        </svg></mat-icon><span class="mdc-button__label"><span class="ml-2 mr-1">Add</span></span><span class="mat-mdc-focus-indicator"></span><span matripple class="mat-ripple mat-mdc-button-ripple"></span><span class="mat-mdc-button-touch-target"></span>
                                                </button>
                                            </div>
                                            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" style="padding: 15px;">
                                                <mat-card>
                                                    <div class="customer spend_scroll">
                                                        <table class="simple">
                                                            <tbody>
                                                                <tr class="text-center">
                                                                    <th>Expenditure Class</th>
                                                                    <th>{{barristerTitle}}</th>
                                                                    <th>Amount</th>
                                                                    <th>GST</th>
                                                                    <th>Expense A/C</th>
                                                                    <th>Note</th>
                                                                    <th></th>
                                                                </tr>
                                                                <ng-container *ngFor="let item of getDataForTable; let index = index">
                                                                    <mat-spinner
                                                                                                style=" zoom: 0.2;position: absolute;right: 0;left: 0;
                                                                                            margin: auto;top: 0;bottom: 0;" *ngIf="isspiner && OpenLoader == bankIndex"></mat-spinner>
                                                                <ng-container *ngIf="item.INDEX == bankIndex">
                                                                    <tr class="text-center" *ngIf="Object.keys(item).length !=0" (click)="highlightedRows=index" [style.background]="highlightedRows == index ? selectedColore : ''" [ngClass]="highlightedRows == index ? 'row-text-colore1' : ''" matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                                                                        <td>{{item.EXPENDITURECLASS}}</td>
                                                                        <td>{{item.SHORTNAME}}</td>
                                                                        <td>{{item.AMOUNT}}</td>
                                                                        <td>{{item.GST}}</td>
                                                                        <td>{{item.EXPENSEACCOUNTNUMBER}}</td>
                                                                        <td>{{item.NOTE}}</td>
                                                                        <td class="spend-last-td">
                                                                            <div class="ng-tns-c142-6"><button mat-stroked-button mat-button
                                                                                (click)="(bank.get('MultiSpendTable').value) ? ExpandDiv(index,false,bank,bankIndex): ExpandDiv(index,true,bank,bankIndex);editRowData(item,bank)"
                                                                                 class="min-w-10 min-h-7 h-7 px-2 leading-6 spend-money-dropdown ng-tns-c142-6 mdc-button mdc-button--outlined mat-mdc-outlined-button mat-unthemed mat-mdc-button-base"><span class="mat-mdc-button-persistent-ripple mdc-button__ripple"></span>
                                                                                    <mat-icon>{{(index==lengthsize
                                                                            && bank.get('MultiSpendTable').value) ?
                                                                            'keyboard_arrow_up'
                                                                            :
                                                                            'keyboard_arrow_down'
                                                                            }}</mat-icon><span class="mdc-button__label"></span><span class="mat-mdc-focus-indicator"></span><span matripple class="mat-ripple mat-mdc-button-ripple"></span><span class="mat-mdc-button-touch-target"></span>
                                                                                </button></div>
                                                                        </td>
                                                                    </tr>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="bank.get('MultiSpendTable').value == true"> 
                                                                        <tr *ngIf="index==lengthsize" class="text-center" style="box-shadow: 2px 0px 8px 0px #888888;">

                                                                            <td [attr.colspan]="7">
                                                                                <div class="display-flex spend-mooney">

                                                                                    <div class="div" style="width: 130px;">
                                                                                        <mat-form-field class="example" fxFlex="10" class="pr-4 spend-money-field" appearance="outline">
                                                                                            <mat-label>Class</mat-label>
                                                                                            <mat-select formControlName="Class" (selectionChange)="ClasstypeForm($event.value,bank)">
                                                                                                <mat-option value="Expense">Expense</mat-option>
                                                                                                <mat-option value="Matter Expense">Matter
                                                                                                    Expense</mat-option>
                                                                                                <mat-option value="Capital">Capital</mat-option>
                                                                                                <mat-option value="Pay GST">Pay
                                                                                                    GST</mat-option>
                                                                                                <mat-option value="Pay Tax">Pay
                                                                                                    Tax</mat-option>
                                                                                                <mat-option value="Personal">Personal</mat-option>
                                                                                                <mat-option value="Depreciation">Depreciation</mat-option>
                                                                                                <mat-option value="Other">Other</mat-option>
                                                                                            </mat-select>

                                                                                        </mat-form-field>
                                                                                    </div>
                                                                                    <div class="div">

                                                                                        <mat-form-field class="pr-4 spend-money-field" appearance="outline" fxFlex="20">
                                                                                            <mat-label>Matter</mat-label>
                                                                                           
                                                                                            <input [matAutocomplete]="autoMatter" matInput (keydown.Tab)="datashow($event)" formControlName="Matter" (input)='onSpentMatterChange(bank)'>
                                                                                            <mat-autocomplete #autoMatter="matAutocomplete" >
                                                                                                <mat-option class="mat-option_1" *ngFor="let data of matterlist" (click)="selectedmatterguid(bank,data)" value="{{data.MATTERGUID}}">
                                                                                                    {{data.SELECTEDDESCRIPTION}}
                                                                                                </mat-option>
                                                                                            </mat-autocomplete>
                                                                                            <mat-icon matSuffix [ngClass]="getCommonVal(bank,'spendmoneyForm','hide') ? 'visibility-hidden' : ''">
                                                                                                <button id="mattersnew" class="matternews" [ngClass]="getCommonVal(bank,'spendmoneyForm','hide') ?'menu-disabled':''" (click)='selectMatterForm(bank)'><img src="assets/icons/web_app/icon_matter_d.ico" matTooltip matTooltipPosition="above">
                                                                                                </button>
                                                                                            </mat-icon>
                                                                                        </mat-form-field>
                                                                                    </div>
                                                                                    <div class="div">

                                                                                        <mat-form-field appearance="outline" fxFlex="15" class="pr-4 spend-money-field">
                                                                                            <mat-label>Amount
                                                                                                Inc GST.</mat-label>
                                                                                                <!-- [(ngModel)]="GSTValForInGst" -->
                                                                                            <input style="text-align: right;" class="sideText" matInput type="text" formControlName="AmountIncGST" (focusout)="amountCalForm(bank)">

                                                                                        </mat-form-field>
                                                                                    </div>
                                                                                    <div class="div">
                                                                                        <mat-form-field class=" spend-money-field pr-4" fxFlex="15" appearance="outline">
                                                                                            <mat-label>GST Type</mat-label>
                                                                                            <mat-select formControlName="GSTType" (selectionChange)="GstTypeforSelectform($event.value, bank)">
                                                                                                <mat-option value="10% GST">10%
                                                                                                    GST</mat-option>
                                                                                                <mat-option value="No GST">No
                                                                                                    GST</mat-option>
                                                                                                <mat-option value="Less Than 10% GST">Less
                                                                                                    Than 10%
                                                                                                    GST</mat-option>
                                                                                                <mat-option *ngIf="getClassType(bank) ==='Matter Expense'" value="No GST(Charge GST)">No
                                                                                                    GST(Charge
                                                                                                    GST)
                                                                                                </mat-option>
                                                                                                <mat-option *ngIf="getClassType(bank) ==='Matter Expense'" value="Less Than 10% GST(Charge GST)">Less
                                                                                                    Than 10%
                                                                                                    GST(Charge
                                                                                                    GST)</mat-option>
                                                                                            </mat-select>

                                                                                        </mat-form-field>
                                                                                    </div>

                                                                                    <div class="div">
                                                                                        <mat-form-field appearance="outline" fxFlex="10" class="pr-4 spend-money-field">
                                                                                            <mat-label>GST</mat-label>
                                                                                            <!-- [(ngModel)]="GSTValAfterCal" -->
                                                                                            <input type="text" mask="separator.2" thousandSeparator="," decimalMarker="." class="sideText" [max]="maxVal" [leadZero]="true" matInput formControlName="GST1" (focusout)="amountCalForm(bank)">

                                                                                        </mat-form-field>
                                                                                    </div>
                                                                                   
                                                                                </div>
                                                                                <div class="div display-flex spend-money-lastpoint">
                                                                                    <div class="div display-flex spend-money-lastp">
                                                                                        <div class="div">
                                                                                            <mat-form-field appearance="outline" fxFlex="15" class="pr-4 spend-money-field">
                                                                                                <mat-label *ngIf="getClassType(bank)=='Expense' || getClassType(bank)=='Matter Expense' || getClassType(bank)=='Depreciation' ||getClassType(bank)=='Other'">
                                                                                                    Expense
                                                                                                    a/c
                                                                                                </mat-label>
                                                                                                <mat-label *ngIf="getClassType(bank)=='Capital'">Asset
                                                                                                    a/c
                                                                                                </mat-label>
                                                                                                <mat-label *ngIf="getClassType(bank)=='Pay GST'">GST
                                                                                                    a/c
                                                                                                </mat-label>
                                                                                                <mat-label *ngIf="getClassType(bank)=='Pay Tax'">Tax
                                                                                                    a/c</mat-label>
                                                                                                <mat-label *ngIf="getClassType(bank)=='Personal'">Equity
                                                                                                    a/c
                                                                                                </mat-label>
                                                                                                <input matInput formControlName="Expenseac" (keyup)="getAccountInfoForm('ExpenseClick', bank, $event)" matTooltip="{{(toolTipList)?toolTipList.EXPENDITUREITEMS?.HOVER:''}}">
                                                                                                <mat-icon matSuffix>
                                                                                                    <button id="expac" class="matternews" (click)="BankingDialogOpenPayeeForm('','ExpenseClick',$event, bank)" [disabled]="getCommonVal(bank,'spendmoneyForm','expac')"><img src="assets/icons/web_app/icon_accounts_small_d.ico" matTooltip matTooltipPosition="above"></button>
                                                                                                </mat-icon>

                                                                                            </mat-form-field>
                                                                                        </div>
                                                                                        <div class="div spend-money-note">
                                                                                            <mat-form-field appearance="outline" fxFlex="20" class="pr-4 spend-money-field">
                                                                                                <mat-label>Note</mat-label>
                                                                                                <input matInput formControlName="Note" matTooltip="{{(toolTipList)?toolTipList.NOTE?.HOVER:''}}">

                                                                                            </mat-form-field>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div *ngIf="item.EXPENSEACCOUNTNUMBER && item.AMOUNT"
                                                                            class="spend-money-btns display-flex spend-money-deupt"
                                                                            >
                
                                                                            <button
                                                                                mat-flat-button
                                                                                
                                                                                mat-button
                                                                                (click)="updateSpendRowData(bank,index,false,bankIndex)"
                                                                                class="ng-tns-c142-6 mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary  spend-money-updates mat-mdc-button-base">
                                                                                Update </button>
                                                                            <button
                                                                                
                                                                                mat-button
                                                                                (click)="deleteSpendRowData(bank,index,false,bankIndex)"
                                                                                class=" ng-tns-c142-6 mdc-button mat-mdc-button mat-warn mat-mdc-button-base spend-money-deletes">
                                                                                Delete </button>
                                                                        </div>
                                                                                    <div class="spend-money-btns display-flex spend-money-deupt" *ngIf="!item.EXPENSEACCOUNTNUMBER && !item.AMOUNT">
                                                                                        <button mat-flat-button mat-button (click)="SaveItemDialog(bank,false,bankIndex)" class="ng-tns-c142-6 mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary  spend-money-updates mat-mdc-button-base">
                                                                                            <mat-spinner
                                                                                                style=" zoom: 0.2;position: absolute;right: 0;left: 0;
                                                                                            margin: auto;top: 0;bottom: 0;" *ngIf="isspiner"></mat-spinner>
                                                                                            Save </button>
                                                                                        <button mat-button (click)="deleteElement(bank,false,bankIndex)" class=" ng-tns-c142-6 mdc-button mat-mdc-button mat-warn mat-mdc-button-base spend-money-deletes">
                                                                                            Cancel </button>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-container>
                                                                </ng-container>


                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </mat-card>

                                            </div>

                                            <div fxLayout="row" class="save-btn2 receive-create" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="bank.get('SELECTEDSPENDINDEX').value == false">
                                                <div>
                                                    <button mat-raised-button class="mat-button mat-raised-button mat-primary mat-accent" *ngIf="checkNum(bank.get('AMOUNT').value) === -1" (click)="defaultSpenMoneyData(bank,bankIndex,false)">Single Line</button>
                                                </div>
                                                <div>
                                                    <button [disabled]="bank.controls['spendmoneyForm'].controls['OutofBalance'].value === '0.00'" mat-raised-button *ngIf="checkNum(bank.get('AMOUNT').value) === -1" class="mat-button mat-raised-button mat-primary mat-accent" (click)="saveSpendMoneyDataForm(bank,bankIndex)">
                                                        <mat-spinner style=" zoom: 0.2;position: absolute;right: 0;left: 0; margin: auto;top: 0;bottom: 0;" *ngIf="isspiner"></mat-spinner>
                                                        Ok</button>
                                                </div>
                                            </div>

                                        </ng-container>
                                    </ng-container>

                                    <div formGroupName="PrepareReceiptForm" fxLayout="column" [ngClass]="{'light-theme-list': currentUser.THEMECOLOUR === 'theme-yellow-light', 'grey-theme-list': currentUser.THEMECOLOUR === 'theme-blue-gray-dark'}" class="secd-list" *ngIf="bank.get('SELECTEDINDEX').value == true && checkNum(bank.get('AMOUNT').value) === 1">
                                        <div fxLayout="row" class="receive-create" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                            <mat-form-field appearance="outline" class="pr-4 name-input-6">
                                                <mat-label>Receipt Code</mat-label>
                                                <input matInput placeholder="Receipt Code" formControlName="INCOMECODE">
                                             <!-- <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INCOMECODE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.INCOMECODE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div> -->
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="pr-4 name-input-10">
                                                <mat-label>Payor</mat-label>
                                                <input matInput placeholder="Payor" formControlName="PAYEE" [matAutocomplete]="payeeText" (input)="onRMPayorChange(bank)" #payorInputField (keydown.Tab)="selPayorOnPressTabForm(bank,$event)">
                                                <mat-autocomplete #payeeText="matAutocomplete" [panelWidth]="400" (optionSelected)="PayeeTypeChangeForm(bank,$event.option.value)">
                                                    <mat-option *ngFor="let val of getPayourarray" [value]="val.CONTACTNAME">
                                                        <span *ngIf="val && !val?.CONTACTGUID && val.CONTACTNAME"><b>Add  </b></span>&nbsp; <span *ngIf="val && !val?.CONTACTGUID && val.CONTACTNAME"><b>"</b></span>{{val.CONTACTNAME}}<span *ngIf="val && !val?.CONTACTGUID && val.CONTACTNAME"><b>"</b></span>
                                                    </mat-option>
                                                </mat-autocomplete>
                                                <mat-icon matSuffix (click)="selectClientMEForm(bank,'payee')" [ngClass]="appPermissions[3]?.Access==0 ? 'disabled-click large-button' : 'large-button'">
                                                    <img src="assets/icons/web_app/icon_contact_d.ico" matTooltip="" matTooltipPosition="above">
                                                </mat-icon>
                                             <!-- <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.PAYEE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.PAYEE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div> -->
                                            </mat-form-field>
                                        </div>
                                        <div fxLayout="row" class="receive-create" fxLayoutAlign="start center" fxFlex="1 0 auto">                
                                            <mat-form-field *ngIf="isValueCheck(bank,'INCOMETYPE') !== 'Write Off'" appearance="outline" class="pr-4 name-input-6">
                                                <mat-label>Income Type</mat-label>
                                                <mat-select formControlName="INCOMETYPE" [value]="incomeType">
                                                    <mat-option value="Cash">Cash</mat-option>
                                                    <mat-option value="Cheque">Cheque</mat-option>
                                                    <mat-option value="Credit Card">Credit Card</mat-option>
                                                    <mat-option value="Direct Deposit">Direct Deposit</mat-option>
                                                    <mat-option value="Direct Withdrawal">Direct Withdrawal</mat-option>
                                                    <mat-option value="Money Order">Money Order</mat-option>
                                                    <mat-option value="Transfer">Transfer</mat-option>
                                                    <mat-option value="SilqPay">SilqPay</mat-option>
                                                </mat-select>
                                             <!-- <div  *ngIf="testBool && toolTipList" class="tooltip-wrap" (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.INCOMETYPE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.INCOMETYPE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div> -->
                                            </mat-form-field>
                                            <mat-form-field *ngIf="isValueCheck(bank,'INCOMETYPE') == 'Write Off'" appearance="outline" class="pr-4 name-input-4">
                                                <mat-label>Write Off</mat-label>
                                                <input matInput  disabled readonly placeholder="Write Off" formControlName="INCOMETYPE">
                                            </mat-form-field>
                                            <!-- name-input-50 -->
                                            <mat-form-field appearance="outline" class="pr-4 name-input-10">
                                                <mat-label>Note</mat-label>
                                                <textarea matInput formControlName="NOTE"></textarea>
                                             <!-- <div  *ngIf="testBool && toolTipList" class="tooltip-wrap"  (click)="tooltipService.copyTooltipText((toolTipList)?toolTipList?.NOTE?.COPYVALUE:'')"><input id="myInput"  readonly disabled value="{{toolTipList?.NOTE?.COPYVALUE}}"><mat-icon >file_copy</mat-icon></div> -->
                                            </mat-form-field>
                                        </div>
                                        <div fxLayout="row" class="receive-create" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                            <!-- [ngClass]="hide ? 'menu-disabled' : ''" -->
                                            <mat-form-field appearance="outline" class="pr-4 name-input-show">
                                                <mat-label>Show?</mat-label>
                                                <mat-select formControlName="SHOW" (selectionChange)="onChangeShowForm(bank,$event)">
                                                    <mat-option [value]=4>Show specific unpaid invoice </mat-option>
                                                    <mat-option *ngIf="!(bank.controls['PrepareReceiptForm']).controls['Drop1'].value" [value]=1>Show unpaid invoices for matter </mat-option>
                                                    <mat-option *ngIf="!(bank.controls['PrepareReceiptForm']).controls['Drop2'].value" [value]=2>Show unpaid invoices for client </mat-option>

                                                    <mat-option *ngIf="(bank.controls['PrepareReceiptForm']).controls['Drop1'].value" [value]=1>{{(bank.controls['PrepareReceiptForm']).controls['Drop1'].value}} </mat-option>
                                                    <mat-option *ngIf="(bank.controls['PrepareReceiptForm']).controls['Drop2'].value" [value]=2>{{(bank.controls['PrepareReceiptForm']).controls['Drop2'].value}} </mat-option>
                                                    <!-- <mat-option *ngIf="(bank.controls['PrepareReceiptForm']).controls['Drop3'].value" [value]=3>{{(bank.controls['PrepareReceiptForm']).controls['Drop3'].value}} </mat-option> -->
                                                    <mat-option [value]=3>Show all unpaid invoices </mat-option>
                                                </mat-select>
                                            </mat-form-field>
        
                                            <mat-form-field appearance="outline" class="pr-4 name-input-90" *ngIf="isValueCheck(bank,'SHOW') === 4">
                                                <!-- <mat-label>Search by </mat-label> -->
                                                <input matInput formControlName="searchInvoice" placeholder="Search specific unpaid invoice" (keyup)="onSearchInvoice(bank, 'searchInvoice', $event)">
                                                <!-- <mat-icon matSuffix class="secondary-text"></mat-icon> -->
                                                <mat-icon class="main-icon" matSuffix (click)="getInvoicesSearch(bank)">
                                                    <img src="assets/icons/web_app/icon_search_d.ico">
                                                </mat-icon>
                                            </mat-form-field>

                                            <!-- <div *ngIf="isValueCheck(bank,'SHOW') === 4">
                                                <button mat-raised-button class="mat-button mat-raised-button mat-primary mat-accent" 
                                                (click)="getInvoicesSearch(bank)">Search</button>
                                            </div> -->

                                            <mat-form-field appearance="outline" class="pr-4 name-input-21" *ngIf="isValueCheck(bank,'SHOW') === 2">
                                                <mat-label>Client</mat-label>
                                                
                                                <input matInput (keydown.Tab)="datashowReceiveForm(bank,$event)" 
                                                        formControlName="Clientmattertext" #MatterInputRC [matAutocomplete]="autoMatter"
                                                         (keyup)='selectMatterFormSearchRCForm(bank,MatterInputRC?.value)'>
                                                <mat-icon matSuffix  *ngIf="getCommonVal(bank,'PrepareReceiptForm','Clientmattertext') !== ''" (click)="removeRCClient(bank)" class="float-left reset-path-btn">close</mat-icon>
                                                <mat-autocomplete #autoMatter="matAutocomplete" (optionSelected)="selectedValueRCForm(bank,$event)" >
                                                    <mat-option class="mat-option_1" *ngFor="let data of matterlistRC" (click) ="selectedmatterguidRCForm(bank,data)">
                                                        <!-- {{data.SHORTNAME}}--{{data.MATTER}} --><span *ngIf="data && !data?.CONTACTGUID && data.CONTACTNAME"><b>Add  </b></span>&nbsp; <span *ngIf="data && !data?.CONTACTGUID && data.CONTACTNAME"><b>"</b></span>{{data.CONTACTNAME}}<span *ngIf="data && !data?.CONTACTGUID && data.CONTACTNAME"><b>"</b></span>
                                                    </mat-option>
                                                </mat-autocomplete>
                                                <mat-icon matSuffix (click)="ContactMatterReceiveForm(bank)" [ngClass]="appPermissions[3]?.Access==0 ? 'disabled-click large-button' : 'large-button'">
                                                    <img src="assets/icons/web_app/icon_contact_d.ico" matTooltip="" matTooltipPosition="above">
                                                </mat-icon>
                                            </mat-form-field>
                                            <mat-form-field class="pr-4 name-input-45"
                                            appearance="outline" *ngIf="isValueCheck(bank,'SHOW') === 1">
                                            <mat-label>Matter</mat-label>
                                            <!-- <mat-chip-list #chipListRM aria-label="Matter selection">
                                                <mat-chip *ngFor="let matterdata of matternameRM"
                                                    (removed)="removeRMForm(bank,matterdata)"
                                                    class="mat-chips-selected">
                                                    {{matterdata| slice:0:50}}<ng-container
                                                        *ngIf="matterdata.length>50">...</ng-container> -->
                                                        <!-- [ngClass]="hide?'visibility-hidden':''" -->
                                                    <!-- <button matChipRemove class="canclebutton">
                                                        <mat-icon
                                                            style="font-size: 18px !important;">cancel</mat-icon>
                                                    </button>
                                                </mat-chip> -->
                                            <!-- </mat-chip-list> -->
                                            <!-- #MatterInputRM -->
                                            <!-- [ngClass]="matternameRM.length > 0?'hideInput':'showInput'" -->
                                            <!-- [matChipInputFor]="chipListRM"
                                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                    (matChipInputTokenEnd)="addRMForm(bank,$event)" -->
                                                <input matInput (keydown.Tab)="datashowRMForm(bank,$event)"
                                                    formControlName="Matter"
                                                    [matAutocomplete]="autoMatterRM"
                                                    (input)='onRMMatterChange(bank)'>
                                                    <mat-icon matSuffix  *ngIf="getCommonVal(bank,'PrepareReceiptForm','Matter') !== ''" (click)="removeRCClient(bank)" class="float-left reset-path-btn">close</mat-icon>
                                            <mat-autocomplete #autoMatterRM="matAutocomplete"
                                                (optionSelected)="selectedValueRMForm(bank,$event)">
                                                <mat-option class="mat-option_1"
                                                    *ngFor="let data of matterlistRM"
                                                    (click)="selectedmatterguidRMForm(bank,data)"
                                                    value="{{data.MATTERGUID}}">
                                                    {{data.SELECTEDDESCRIPTION}}
                                                </mat-option>
                                            </mat-autocomplete>
                                            <!-- [ngClass]="hide ? 'menu-disabled' : ''" -->
                                            <!-- [ngClass]="hide ? 'visibility-hidden' : ''" -->
                                            <mat-icon matSuffix>
                                                <button id="mattersnew" class="matternews"
                                                    (click)='selectRecieveMatterForm(bank)'><img class="ipad-icon"
                                                        src="assets/icons/web_app/icon_matter_d.ico">
                                                </button>
                                            </mat-icon>
                                        </mat-form-field>
                                        </div>
        
                                        <!-- <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="unallocated_div space-allocate">
                                        </div> -->
                                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="allocate_btn_div space-allocate apply-button">
                                            Invoices To allocate Against
                                            <!-- <button mat-raised-button color="accent"  (click)="PreviousSetLetter()" cdkFocusInitial>Previous</button> -->
                                            <button mat-raised-button color="accent" class="mat-accent" (click)="clickClearAllocationForm(bank)">Clear
                                                Allocations</button>
                                            <button mat-raised-button color="accent" class="mat-accent" (click)="clickAutoAllocationForm(bank,'AutoAllocation')">Auto
                                                Allocate</button> &nbsp;&nbsp;&nbsp;&nbsp;
                                               <p> Unallocated</p>&nbsp;&nbsp;&nbsp;&nbsp;
                                            <mat-form-field appearance="outline" fxFlex="17" class="pr-4 readonly_apply">
                                                <mat-label>Unallocated</mat-label>
                                                <input currencyMask matInput placeholder="Unallocated" readonly formControlName="Unallocated">
                                            </mat-form-field>
                                        </div>
                                        <div class="invoice_receipt_main_div">
                                            <div class="example-containerdata">
                                                <div class="grid-overlay" *ngIf="isDisplay">
                                                    <div class="grid-overlay-msg"> There is no data to display. </div>
                                                </div>
                                                <table mat-table #table [dataSource]="(bank.controls['PrepareReceiptForm']).controls['PrepareReceiptData'].value" [@animateStagger]="{value:'50'}" matSort class="mat-elevation-z8">
                                                    <ng-container matColumnDef="INVOICECODE">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Invoice</th>
                                                        <td mat-cell *matCellDef="let row">
                                                            <p class="job-title text-truncate">
                                                                <span>
                                                                    {{row.INVOICECODE}}
                                                                </span>
                                                            </p>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="INVOICETOTAL">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Total</th>
                                                        <td mat-cell *matCellDef="let row">
                                                            <p class="company text-truncate">
                                                                <span>
                                                                    <!-- {{ row.INVOICETOTALINCGST | toFixedAmount:true}} -->
                                                                    {{ row.INVOICETOTALINCGST }}
                                                                </span>
                                                            </p>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="AMOUNTOUTSTANDINGINCGST">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Outstanding</th>
                                                        <td mat-cell *matCellDef="let row">
                                                            <p class="company text-truncate">
                                                                <span>
                        
                                                                    <!-- {{  row.AMOUNTOUTSTANDINGINCGST  | toFixedAmount:true}} -->
                                                                    {{ row.AMOUNTOUTSTANDINGINCGST }}
                                                                </span>
                                                            </p>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ALLOCATED">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Allocated</th>
                                                        <td mat-cell *matCellDef="let row">
                                                            <p class="company text-truncate">
                                                                <span *ngIf="row.ALLOCATED">
                                                                    ${{row.ALLOCATED}}
                                                                </span>
                                                                <span *ngIf="!row.ALLOCATED">
                                                                    $0.00
                                                                </span>
                                                            </p>
                                                        </td>
                                                    </ng-container>
                        
                                                    <!-- <ng-container *ngIf="action != 'add'" matColumnDef="ALLOCATED">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Allocated</th>
                                                        <td mat-cell *matCellDef="let row">
                                                            <p class="company text-truncate">
                                                                <span>
                                                                   ${{row.AMOUNT}}
                                                                </span>
                                                            </p>
                                                        </td>
                                                    </ng-container> -->
                        
                        
                                                    <!-- <ng-container matColumnDef="ALLOCATED">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Allocated</th>
                                                        <td mat-cell *matCellDef="let row">
                                                            <p class="company text-truncate">
                                                                <span *ngIf="row.ALLOCATED">
                                                                {{ (action != 'edit' && action !='editForTB') ?  row.ALLOCATED :  row.AMOUNT  }}
                                                               </span>
                                                                <span *ngIf="!row.ALLOCATED && (action != 'edit' && action !='editForTB') ">
                                                                    0.00
                                                               </span>
                                                            </p>
                                                        </td>
                                                    </ng-container> -->
                        
                                                    <ng-container matColumnDef="MATTERGUID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Matter</th>
                                                        <td mat-cell *matCellDef="let row">
                                                            <p class="company text-truncate">
                                                                <span>
                                                                    {{ row.SHORTNAME }}
                                                                </span>
                                                            </p>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row class="tbl_fix_header" *matHeaderRowDef="displayedColumnsMR; sticky: true">
                                                    </tr>
                                                    <!-- sticky: true" -->
                                                    <tr mat-row *matRowDef="let row; columns: displayedColumnsMR;" [style.background]="(bank.controls['PrepareReceiptForm']).controls['highlightedRowsPRD'].value == row?.INVOICEGUID ? selectedColore : ''" [ngClass]="(bank.controls['PrepareReceiptForm']).controls['highlightedRowsPRD'].value == row?.INVOICEGUID ? 'row-text-colore' : ''" class="contact" (click)="clickRowForm(bank,row)"
                                                        matRipple [@animate]="{value:'*',params:{y:'100%'}}">
                                                    </tr>
                                                </table>
                                            </div>
                                            <mat-paginator [pageSizeOptions]="[10, 20 ,50,100]" showFirstLastButtons></mat-paginator>
                                        </div>
                                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="unallocated_div space-allocate apply-button">
                                            <p fxFlex="40"> Enter the amount to allocate to the selected invoice and press tab</p>
                                            <mat-form-field appearance="outline" fxFlex="16.6" class="pr-4">
                                                <input currencyMask formControlName="allocatedSelected" matInput placeholder="Unallocated">
                                                <!-- <span class="currency_sign" matPrefix>$&nbsp;</span>
                                                <mat-icon matSuffix class="secondary-text">currency</mat-icon> -->
                                            </mat-form-field>
                                            <button fxFlex="15" class="buttonsize" mat-raised-button color="accent" (click)="ApplyReceiptForm(bank)">Apply</button>
                                        </div>
                                        <div fxLayout="row" class="save-btn2 receive-create" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                            <div>
                                                <button mat-raised-button class="mat-button mat-raised-button mat-primary mat-accent" (click)="cancelMEForm(bank)">General Receipt</button>
                                            </div>
                                            <div>
                                                <button mat-raised-button style="margin: 0 10px 0 0;" class="mat-button mat-raised-button mat-primary mat-accent" (click)="SaveReceiptForm(bank)" [disabled]="isspinerRF">
                                                    <mat-spinner *ngIf="isspinerRF"></mat-spinner>OK
                                                </button>    
                                            </div>
                                        </div>
                                        <!-- <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                        </div> -->
                                    </div>
                                </div>
                            </mat-tab>
                            <mat-tab label="Match">
                                <mat-accordion>
                                    <mat-expansion-panel hidetoggle [expanded]="panelOpenState === 0 && selectedIndex === bankIndex" (opened)="setStep(0, bankIndex)">
                                      <mat-expansion-panel-header>
                                        <mat-panel-title class="h3-text">
                                            <h3><b>1. Find & select matching transactions</b></h3>
                                        </mat-panel-title>
                                      </mat-expansion-panel-header>
                                      <div fxLayout="row wrap" class="secd-list" [ngClass]="{'light-theme-list': currentUser.THEMECOLOUR === 'theme-yellow-light', 'grey-theme-list': currentUser.THEMECOLOUR === 'theme-blue-gray-dark'}">
                                        <div class="match-box">
                                            <!-- <span style="margin: 0 10px;">
                                                <p><mat-checkbox>Show Spent Items</mat-checkbox></p>
                                                <p><mat-checkbox>Show AUD items only</mat-checkbox></p>
                                            </span> -->
                                            <div>
                                                <mat-form-field appearance="outline" class="pr-4 name-input-2">
                                                    <mat-label>Search by name or reference</mat-label>
                                                    <input matInput formControlName="searchName" placeholder="Search by name or reference..." (input)="onSearch(bank, 'name')" tabindex="6">
                                                    <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                </mat-form-field>
                                            </div>
                                            <div>
                                                <mat-form-field appearance="outline" class="pr-4 name-input-7">
                                                    <mat-label>Search by amount</mat-label>
                                                    <input matInput type="text" (keypress)="numberOnly($event)" formControlName="searchAmount" placeholder="Search by amount..." (input)="onSearch(bank, 'amount')">
                                                    <mat-icon matSuffix class="secondary-text"></mat-icon>
                                                </mat-form-field>
                                            </div>
                                            <div>
                                                <button mat-raised-button class="mat-button mat-raised-button mat-primary mat-accent" 
                                                (click)="checkNum(bank.get('AMOUNT').value) === 1 ? loadIData(bank,bankIndex) : loadEData(bank,bankIndex)">Go</button>
                                            </div>
                                            <div>
                                                <button mat-button class="btn-color" (click)="clearSearch(bank)">Clear search</button>
                                            </div>
                                        </div>
                                        <div class="example-containerTable resizable-table" *ngIf="receiveMoneydata && selectedTable == bankIndex">
                                            <div class="grid-overlay" *ngIf="isDisplayRM">
                                                <div class="grid-overlay-msg"> There are no data to display. </div>
                                            </div>
                                            <mat-table #table [dataSource]="receiveMoneydata"
                                            [@animateStagger]="{value:'50'}" (matSortChange)="sortData($event)" matSortActive="{{sortactive}}"
                                            matSortDirection="{{sortDirection}}" matSortDisableClear matSort>
                                            <ng-container *ngFor="let item of displayedColumns; let i = index"
                                                matColumnDef="{{item}}">
                                                <span *ngIf="item == 'select'">
                                                    <!-- <mat-header-cell *matHeaderCellDef [resizeColumn]="true"
                                                    [index]="i"> -->
                                                    <mat-header-cell *matHeaderCellDef style="width: 50px">
                                                        <!-- <div class="resize-handle-right" mwlResizeHandle
                                                            [resizeEdges]="{ right: true }"></div> -->
                                                        <mat-checkbox  
                                                            (change)="$event ? masterToggle() : null;"
                                                            [checked]="selection.hasValue() && isAllSelected()"
                                                            [indeterminate]="selection.hasValue() && !isAllSelected()"
                                                            [aria-label]="checkboxLabel()">
                                                        </mat-checkbox>
                                                    </mat-header-cell>
                                                    <mat-cell *matCellDef="let row" style="width: 20px">
                                                      <mat-checkbox
                                                      (change)="$event ? selection.toggle(row) : null;collectSelectedVal()"
                                                      [(ngModel)]="row.TAGGED" 
                                                      [checked]="selection.isSelected(row)"  
                                                      [aria-label]="checkboxLabel(row)"> 
                                                      </mat-checkbox>
                                                    </mat-cell>
                                              </span>
                                              <span *ngIf="item != 'select'">
                                                <mat-header-cell *matHeaderCellDef class="header_title">
                                                    <span class="desc_cell" mat-sort-header>{{ tempColobj[item].DESCRIPTION }}</span> </mat-header-cell>
                                                 <mat-cell *matCellDef="let row"
                                                     class="text-{{tempColobj[item].JUSTIFICATION}}-align mat-column-{{item}}"
                                                     [ngStyle]="{'color': '#' + row['ROWCOLOUR']}"
                                                     [ngClass]="{'word-wrap-class':tempColobj[item].WIDEDATA}">
                                                     <span *ngIf="row[item]!==undefined && ((Object.keys(hyperLinkInfo).indexOf(item) == -1)  || hyperLinkInfo[item].LINKFIELD == '' || row[hyperLinkInfo[item].LINKFIELD] == undefined || row[hyperLinkInfo[item].LINKFIELD] == '')">{{row[item] |
                                                         toFixedAmount:tempColobj[item].CURRENCY |
                                                         wordwrap:tempColobj[item].WIDEDATA}}</span>
             
                                                         <span *ngIf="row[item] && (Object.keys(hyperLinkInfo).indexOf(item) >= 0) && hyperLinkInfo[item].LINKFIELD != '' && row[hyperLinkInfo[item].LINKFIELD] != undefined && row[hyperLinkInfo[item].LINKFIELD] != ''">
                                                             <!-- [style]="highlightedRows == row.INCOMEGUID && currentUserData.ProductType == 'Solicitor' ? 'color: #43a047 !important' : highlightedRows == row.INCOMEGUID && currentUserData.ProductType == 'Barrister' ? 'color: #4E2083 !important':'' " [ngClass]="HyperlinkColor" -->
                                                             {{row[item]}}
                                                         </span>
                                                     </mat-cell>
                                                </span>
                                                </ng-container>
                                                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                                                </mat-header-row>
                                                <mat-row class="contact"
                                                    *matRowDef="let row; columns: displayedColumns;" matRipple
                                                    [@animate]="{value:'*',params:{y:'100%'}}">
                                                </mat-row>
                                            </mat-table>
                                        </div>
                                        <!-- <mat-paginator *ngIf="receiveMoneydata && selectedTable == bankIndex" [pageSizeOptions]="[10, 20,50,100]" [pageSize]="pageSize?.bank_feed"
                                                (page)="onPaginateChange($event)" showFirstLastButtons></mat-paginator> -->
        
                                                <div class="example-containerTable resizable-table" *ngIf="spentMoneydata && selectedTable == bankIndex">
                                                    <mat-table #table [dataSource]="spentMoneydata"
                                                    [@animateStagger]="{value:'50'}" (matSortChange)="sortSpentData($event)" matSortActive="{{sortspentactive}}"
                                                    matSortDirection="{{sortspentDirection}}" matSortDisableClear matSort>
                                                    <ng-container *ngFor="let item of displayedColumns2; let i = index"
                                                        matColumnDef="{{item}}">
                                                        <span *ngIf="item == 'select'">
                                                            <!-- <mat-header-cell *matHeaderCellDef [resizeColumn]="true"
                                                            [index]="i"> -->
                                                            <mat-header-cell *matHeaderCellDef style="width: 50px">
                                                                <!-- <div class="resize-handle-right" mwlResizeHandle
                                                                    [resizeEdges]="{ right: true }"></div> -->
                                                                <mat-checkbox  
                                                                    (change)="$event ? masterToggle2() : null;"
                                                                    [checked]="selection2.hasValue() && isAllSelectedSpent()"
                                                                    [indeterminate]="selection2.hasValue() && !isAllSelectedSpent()"
                                                                    [aria-label]="checkboxLabel2()">
                                                                </mat-checkbox>
                                                            </mat-header-cell>
                                                            <mat-cell *matCellDef="let row" style="width: 20px">
                                                              <mat-checkbox
                                                              (change)="$event ? selection2.toggle(row) : null;collectSelectedVal2()"
                                                              *ngIf="row.TAGGED !== undefined"
                                                              [(ngModel)]="row.TAGGED" 
                                                              [checked]="selection2.isSelected(row)"  
                                                              [aria-label]="checkboxLabel2(row)"> 
                                                              </mat-checkbox>
                                                            </mat-cell>
                                                      </span>
                                                      <span *ngIf="item != 'select'">
                                                        <mat-header-cell *matHeaderCellDef class="header_title">
                                                            <span class="desc_cell" mat-sort-header>{{ tempColobj2[item].DESCRIPTION }}</span> </mat-header-cell>
                                                         <mat-cell *matCellDef="let row"
                                                             class="text-{{tempColobj2[item].JUSTIFICATION}}-align mat-column-{{item}}">
                                                             <span *ngIf="row[item]!==undefined && ((Object.keys(hyperLinkInfoSpent).indexOf(item) == -1)  || hyperLinkInfoSpent[item].LINKFIELD == '' || row[hyperLinkInfoSpent[item].LINKFIELD] == undefined || row[hyperLinkInfoSpent[item].LINKFIELD] == '')">
                                                                {{row[item] |
                                                                 toFixedAmount:tempColobj2[item].CURRENCY |
                                                                 wordwrap:tempColobj2[item].WIDEDATA}}</span>
                     
                                                                 <span *ngIf="row[item] && (Object.keys(hyperLinkInfoSpent).indexOf(item) >= 0) && hyperLinkInfoSpent[item].LINKFIELD != '' && row[hyperLinkInfoSpent[item].LINKFIELD] != undefined && row[hyperLinkInfoSpent[item].LINKFIELD] != ''">
                                                                     <!-- [style]="highlightedRows == row.INCOMEGUID && currentUserData.ProductType == 'Solicitor' ? 'color: #43a047 !important' : highlightedRows == row.INCOMEGUID && currentUserData.ProductType == 'Barrister' ? 'color: #4E2083 !important':'' " [ngClass]="HyperlinkColor" -->
                                                                     {{row[item]}}
                                                                 </span>
                                                             </mat-cell>
                                                        </span>
                                                        </ng-container>
                                                        <mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true">
                                                        </mat-header-row>
                                                        <mat-row class="contact"
                                                            *matRowDef="let row; columns: displayedColumns2;" matRipple
                                                            [@animate]="{value:'*',params:{y:'100%'}}">
                                                        </mat-row>
                                                    </mat-table>
                                                </div>
                                                <!-- <mat-paginator *ngIf="spentMoneydata && selectedTable == bankIndex" [pageSizeOptions]="[10, 20,50,100]" [pageSize]="pageSize?.bank_feed_spent"
                                                        (page)="onSpentPaginateChange($event)" showFirstLastButtons></mat-paginator> -->
                                        </div>
                                    </mat-expansion-panel>
                                    <mat-expansion-panel [expanded]="panelOpenState === 1 && selectedIndex === bankIndex" (opened)="setStep(1, bankIndex)">
                                      <mat-expansion-panel-header>
                                        <mat-panel-title class="h3-text">
                                            <h3><b>2. View your selected transactions. Add new transactions, as needed.</b></h3>
                                        </mat-panel-title>
                                        <!-- <mat-panel-description>
                                          Currently I am {{panelOpenState ? 'open' : 'closed'}}
                                        </mat-panel-description> -->
                                      </mat-expansion-panel-header>
                                      <div fxLayout="row" class="secd-list" [ngClass]="{'light-theme-list': currentUser.THEMECOLOUR === 'theme-yellow-light', 'grey-theme-list': currentUser.THEMECOLOUR === 'theme-blue-gray-dark'}">
                                        <div class="example-containerTable resizable-table" *ngIf="receiveMoneySelecteddata && selectedTable == bankIndex">
                                            <mat-table class="showed-columns" #table [dataSource]="receiveMoneySelecteddata"
                                            [@animateStagger]="{value:'50'}" (matSortChange)="sortData($event)" matSortActive="{{sortactive}}"
                                            matSortDirection="{{sortDirection}}" matSortDisableClear matSort>
                                                <ng-container *ngFor="let item of showeddisplayedColumns; let i = index"
                                                    matColumnDef="{{item}}">
                                                    <span *ngIf="item == 'select'">
                                                        <!-- <mat-header-cell *matHeaderCellDef [resizeColumn]="true"
                                                        [index]="i"> -->
                                                        <mat-header-cell *matHeaderCellDef style="width: 50px">
                                                            <!-- <div class="resize-handle-right" mwlResizeHandle
                                                                [resizeEdges]="{ right: true }"></div> -->
                                                            <!-- <mat-checkbox  
                                                                (change)="$event ? masterToggle() : null;"
                                                                [checked]="selection.hasValue() && isAllSelected()"
                                                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                                                [aria-label]="checkboxLabel()">
                                                            </mat-checkbox> -->
                                                        </mat-header-cell>
                                                        <mat-cell *matCellDef="let row" style="width: 20px">
                                                        <mat-checkbox
                                                        (change)="$event ? selection.toggle(row) : null;collectSelectedVal()"
                                                        [(ngModel)]="row.TAGGED" 
                                                        [checked]="selection.isSelected(row)"  
                                                        [aria-label]="checkboxLabel(row)"> 
                                                        </mat-checkbox>
                                                        </mat-cell>
                                                    </span>
                                                    <span *ngIf="item != 'select'">
                                                        <mat-header-cell *matHeaderCellDef class="header_title">
                                                            <span class="desc_cell" mat-sort-header>{{ tempColobj[item].DESCRIPTION }}</span> </mat-header-cell>
                                                        <mat-cell *matCellDef="let row"
                                                            class="text-{{tempColobj[item].JUSTIFICATION}}-align mat-column-{{item}}"
                                                            [ngStyle]="{'color': '#' + row['ROWCOLOUR']}"
                                                            [ngClass]="{'word-wrap-class':tempColobj[item].WIDEDATA}">
                                                            <span *ngIf="row[item]!==undefined && ((Object.keys(hyperLinkInfo).indexOf(item) == -1)  || hyperLinkInfo[item].LINKFIELD == '' || row[hyperLinkInfo[item].LINKFIELD] == undefined || row[hyperLinkInfo[item].LINKFIELD] == '')">{{row[item] |
                                                                toFixedAmount:tempColobj[item].CURRENCY |
                                                                wordwrap:tempColobj[item].WIDEDATA}}</span>
                    
                                                                <span *ngIf="row[item] && (Object.keys(hyperLinkInfo).indexOf(item) >= 0) && hyperLinkInfo[item].LINKFIELD != '' && row[hyperLinkInfo[item].LINKFIELD] != undefined && row[hyperLinkInfo[item].LINKFIELD] != ''">
                                                                    <!-- [style]="highlightedRows == row.INCOMEGUID && currentUserData.ProductType == 'Solicitor' ? 'color: #43a047 !important' : highlightedRows == row.INCOMEGUID && currentUserData.ProductType == 'Barrister' ? 'color: #4E2083 !important':'' " [ngClass]="HyperlinkColor" -->
                                                                    {{row[item]}}
                                                                </span>
                                                            </mat-cell>
                                                        </span>
                                                    </ng-container>
                                                    <!-- <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                                                    </mat-header-row> -->
                                                    <mat-row class="contact"
                                                        *matRowDef="let row; columns: showeddisplayedColumns;" matRipple
                                                        [@animate]="{value:'*',params:{y:'100%'}}">
                                                    </mat-row>
                                                </mat-table>
                                            </div>
                                            <div style="margin-left: 15px;" *ngIf="receiveMoneySelecteddata && selectedTable == bankIndex">
                                                <mat-form-field appearance="outline" class="bank-input">
                                                    <mat-select placeholder="New Transaction" (selectionChange)="changeRNT($event)">
                                                        <mat-option value="0">New Matter Receipt</mat-option>
                                                        <mat-option value="1">New General Receipt</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div fxLayout="row" class="secd-list" [ngClass]="{'light-theme-list': currentUser.THEMECOLOUR === 'theme-yellow-light', 'grey-theme-list': currentUser.THEMECOLOUR === 'theme-blue-gray-dark'}">
                                            <div class="example-containerTable resizable-table" *ngIf="spentMoneySelecteddata && selectedTable == bankIndex">
                                                <mat-table class="showed-columns" #table [dataSource]="spentMoneySelecteddata"
                                                    [@animateStagger]="{value:'50'}">
                                                    <ng-container *ngFor="let item of showeddisplayedColumns2; let i = index"
                                                        matColumnDef="{{item}}">
                                                        <span *ngIf="item == 'select'">
                                                            <!-- <mat-header-cell *matHeaderCellDef [resizeColumn]="true"
                                                            [index]="i"> -->
                                                            <mat-header-cell *matHeaderCellDef style="width: 50px">
                                                                <!-- <div class="resize-handle-right" mwlResizeHandle
                                                                    [resizeEdges]="{ right: true }"></div> -->
                                                                <!-- <mat-checkbox  
                                                                    (change)="$event ? masterToggle2() : null;"
                                                                    [checked]="selection2.hasValue() && isAllSelectedSpent()"
                                                                    [indeterminate]="selection2.hasValue() && !isAllSelectedSpent()"
                                                                    [aria-label]="checkboxLabel2()">
                                                                </mat-checkbox> -->
                                                            </mat-header-cell>
                                                            <mat-cell *matCellDef="let row" style="width: 20px">
                                                                <mat-checkbox
                                                                (change)="$event ? selection2.toggle(row) : null;collectSelectedVal2()"
                                                                [(ngModel)]="row.TAGGED"
                                                                [checked]="selection2.isSelected(row)"  
                                                                [aria-label]="checkboxLabel2(row)"> 
                                                                </mat-checkbox>
                                                            </mat-cell>
                                                        </span>
                                                        <span *ngIf="item != 'select'">
                                                        <mat-header-cell *matHeaderCellDef class="header_title">
                                                            <span class="desc_cell" mat-sort-header>{{ tempColobj2[item].DESCRIPTION }}</span> </mat-header-cell>
                                                            <mat-cell *matCellDef="let row"
                                                                class="text-{{tempColobj2[item].JUSTIFICATION}}-align mat-column-{{item}}">
                                                                <span *ngIf="row[item]!==undefined && ((Object.keys(hyperLinkInfoSpent).indexOf(item) == -1)  || hyperLinkInfoSpent[item].LINKFIELD == '' || row[hyperLinkInfoSpent[item].LINKFIELD] == undefined || row[hyperLinkInfoSpent[item].LINKFIELD] == '')">{{row[item] |
                                                                    toFixedAmount:tempColobj2[item].CURRENCY |
                                                                    wordwrap:tempColobj2[item].WIDEDATA}}</span>
                        
                                                                    <span *ngIf="row[item] && (Object.keys(hyperLinkInfoSpent).indexOf(item) >= 0) && hyperLinkInfoSpent[item].LINKFIELD != '' && row[hyperLinkInfoSpent[item].LINKFIELD] != undefined && row[hyperLinkInfoSpent[item].LINKFIELD] != ''">
                                                                        <!-- [style]="highlightedRows == row.INCOMEGUID && currentUserData.ProductType == 'Solicitor' ? 'color: #43a047 !important' : highlightedRows == row.INCOMEGUID && currentUserData.ProductType == 'Barrister' ? 'color: #4E2083 !important':'' " [ngClass]="HyperlinkColor" -->
                                                                        {{row[item]}}
                                                                </span>
                                                        </mat-cell>
                                                    </span>
                                                    </ng-container>
                                                    <!-- <mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true">
                                                    </mat-header-row> -->
                                                    <mat-row class="contact"
                                                        *matRowDef="let row; columns: showeddisplayedColumns2;" matRipple
                                                        [@animate]="{value:'*',params:{y:'100%'}}">
                                                    </mat-row>
                                                </mat-table>
                                            </div>
                                            <!-- <div style="margin-left: 15px;" *ngIf="spentMoneySelecteddata && selectedTable == bankIndex">
                                                <mat-form-field appearance="outline" class="bank-input">
                                                    <mat-select placeholder="New Transaction" (selectionChange)="changeNT($event)">
                                                        <mat-option value="0" selected>Select</mat-option>
                                                        <mat-option value="1">New Spend Money</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div> -->
                                        </div>
                                    </mat-expansion-panel>
                                    <mat-expansion-panel [expanded]="panelOpenState === 2 && selectedIndex === bankIndex" (opened)="setStep(2, bankIndex)">
                                        <mat-expansion-panel-header>
                                          <mat-panel-title class="h3-text">
                                              <h3><b>3. The sum of your selected transactions must match the money received. Make adjustments, as needed.</b></h3>
                                          </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div class="total-sub">
                                            <div class="total-disp-2">
                                                <div class="total-sub">
                                                    <div>Subtotal</div>
                                                    <div>{{totalAmount}}</div>
                                                </div>
                                                <div class="border-sub">
                                                </div>
                                                <div class="total-sub h3-text">
                                                    <h3>Must match money received: {{checkNum(bank.get('AMOUNT').value) === -1 ? convertPos(bank.get('AMOUNT').value) : bank.get('AMOUNT').value}} </h3>
                                                    <!-- <h3>{{totalAmount}}</h3> -->
                                                    <div style="color: #db3245; margin-top: 5px;"><mat-icon class="info-icon">info</mat-icon> Total is out by: {{finalAm(bank.get('AMOUNT').value,totalAmount)}}</div>
                                                </div>
                                            </div>
                                            <!-- <div class="total-disp-1">
                                                <div style="color: #db3245;"><mat-icon class="info-icon">info</mat-icon> Total is out by: {{finalAm(data.AMOUNT,totalAmount)}}</div>
                                            </div> -->
                                            <!-- <div class="total-disp-3">
                                                <mat-form-field appearance="outline" class="bank-input">
                                                    <mat-select placeholder="Adjustments">
                                                        <mat-option value="0">Option 1</mat-option>
                                                        <mat-option value="1">Option 2</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div> -->
                                        </div>
                                        <div>
                                            <button *ngIf="checkNum(bank.get('AMOUNT').value) === 1" mat-raised-button class="mat-button mat-raised-button mat-primary mat-accent" (click)="saveReconcileForm(bank)">Match</button>
                                            <button *ngIf="checkNum(bank.get('AMOUNT').value) === -1" mat-raised-button class="mat-button mat-raised-button mat-primary mat-accent" (click)="saveReconcileSMForm(bank)">Match</button>&nbsp;
                                            <button mat-raised-button (click)="closePanel(bankIndex)">Cancel</button>
                                        </div>
                                      </mat-expansion-panel>
                                </mat-accordion>
                            </mat-tab>
                            <mat-tab label="Discuss">
                                <div fxLayout="column" class="secd-list" [ngClass]="{'light-theme-list': currentUser.THEMECOLOUR === 'theme-yellow-light', 'grey-theme-list': currentUser.THEMECOLOUR === 'theme-blue-gray-dark'}">
                                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="text-area">
                                        <mat-form-field appearance="outline" class="pr-4 name-input-discuss">
                                            <textarea #discussTextRef matInput formControlName="COMMENT"></textarea>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="save-btn receive-create text-area-button">
                                        <button mat-raised-button class="mat-button mat-raised-button mat-primary mat-accent" (click)="sendCommentForm(bank)">Save</button>
                                    </div>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </ng-container>
            </div>
        </div>
    </form>
    <div *ngIf="bankFeedList.length == 0 && !isLoadingResults && this.chartAccountDetail.name.length > 4" class="center-div">
        <div class="align-center" *ngIf="!isError && !MESSAGE">
            <h1>Great job!</h1>
            <h1>You've processed all the transactions for this account!</h1>
        </div>
        <div class="align-center" *ngIf="isError && MESSAGE">
            <h1> {{MESSAGE}} </h1>
        </div>
        <br>
        <div class="align-center" *ngIf="CODE">
            <button mat-raised-button class="mat-button mat-raised-button mat-primary mat-accent" (click)="BankPop()">Connect Bank Feed</button>
        </div>
    </div>
    <div *ngIf="this.chartAccountDetail.name.length == 4 && !isLoadingResults" class="center-div">
        <div class="align-center" *ngIf="!isError && !MESSAGE">
            <!-- <h1>Great job!</h1> -->
            <h1>This account has not been linked. Please return to the <a [routerLink]="'/bank-feed/bank-feed-dashboard'">Dashboard</a> and link this account and then you will be able to process the transactions.</h1>
        </div>
        <!-- <div class="align-center" *ngIf="isError && MESSAGE">
            <h1> {{MESSAGE}} </h1>
        </div> -->
        <!-- <br> -->
        <!-- <div class="align-center" *ngIf="CODE">
            <button mat-raised-button class="mat-button mat-raised-button mat-primary mat-accent" (click)="BankPop()">Connect Bank Feed</button>
        </div> -->
    </div>
</div>
<!-- <div class="main-list">
    <div *ngIf="bankFeedList.length > 0">
        <div *ngFor="let data of bankFeedList; let i = index" class="one flex-wrap justify-content-between">            
            <div class="second-div width-60">
                <mat-tab-group animationDuration="0ms" #tab{{i}} (selectedTabChange)="onTabChanged(data,$event, i);">
                    <mat-tab label="Create">
                        <div fxLayout="column" class="secd-list">
                            <div class="border-line"></div>
                            <br>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</div> -->
