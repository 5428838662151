import { Component, OnInit, Inject, AfterViewInit, ViewChild, ElementRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
import { DatePipe } from '@angular/common';
import { MatterDialogComponent } from '../matter-dialog/matter-dialog.component';
import { FuseConfirmDialogComponent } from 'src/@fuse/components/confirm-dialog/confirm-dialog.component';
import * as moment from 'moment';
import { Observable, Subscription, fromEvent, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { FuseSidebarService } from 'src/@fuse/components/sidebar/sidebar.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Router } from '@angular/router';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { TimersService } from '@_services/timers.service';
import { BehaviorService } from '@_services/Behavior.service';
import { GlobalFunctionsService } from '@_services/global-functions.service';
import { TooltipService } from '@_services/tooltip.service';
import { TierPermissionPipe } from '@_pipes/tier-permission.pipe';
import { CurrentUser } from 'src/app/_models/CurrentUser';
import { SocketService } from '@_services/socket.service';

@Component({
    selector: 'app-time-entry-dialog',
    templateUrl: './time-entry-dialog.component.html',
    styleUrls: ['./time-entry-dialog.component.scss'],
    providers: [DatePipe, TierPermissionPipe]
})
export class TimeEntryDialogComponent implements OnInit, AfterViewInit, OnDestroy {
    allToolTip_Msg: any = {}
    filteredOptions: any;
    filteredOptionsAuto:Observable<string[]>;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    @ViewChild('MatterInput1', { static: false }) MatterInput: ElementRef<HTMLInputElement>;
    rateid: any = ''
    rateidarray: any = [''];
    timeStops: any = [];
    isGStFree: Boolean = false;
    IncWith = 50;
    ExWith = 50;
    LookupsList: any;
    errorWarningData: any = {};
    errorWarningDataArray: any = {};
    userList: any;
    matterList: any = [];
    isreadonly: boolean = false;
    isspiner: boolean = false;
    isLoadingResults: boolean = false;
    ActivityList: any = [];
    successMsg: any;
    PRICEINCGSTVAL: any = 0.00;
    isDisable: boolean = false;
    PRICEVAL: any = "0.00";
    lastPrice: any;
    lastGstPrice: any;
    storePreviousDate: any;
    StoreExGST: any;
    StoreIncGST: any;
    matterDataForFree: any;
    toltipText1: any = "";
    toltipText2: any = "";
    toltipText3: any = "";
    searchMatter: any;
    exportdatavalue: any;
    searchData: any;
    // matterlist: Observable<string[]>;
    matterlist: any;
    mattername: any = [];
    selectedData: any = [];
    isDisabled: boolean = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];


    optionList: any = [
        { 'ACTIVITYID': 'hh:mm', 'DESCRIPTION': 'hh:mm' },
        { 'ACTIVITYID': 'Hours', 'DESCRIPTION': 'Hours' },
        { 'ACTIVITYID': 'Minutes', 'DESCRIPTION': 'Minutes' },
        { 'ACTIVITYID': 'Days', 'DESCRIPTION': 'Days' },
        { 'ACTIVITYID': 'Units', 'DESCRIPTION': 'Units' },
        { 'ACTIVITYID': 'Fixed', 'DESCRIPTION': 'Fixed' }
    ];
    ITEMDATEVLAUE: any;
    action: any = 'Add';
    dialogTitle: any = 'Add New Time Entry';
    buttonText: any = 'Save';
    calculateData: any = {
        MatterGuid: '', QuantityType: '', Quantity: '', FeeEarner: '', FeeType: '', rateid: ''
    };
    ITEMDATEModel: Date;
    matterTimerData: any;
    isStartButton: boolean = false;
    previewTimer: any;
    QuantityTypeLabel: any = 'Quantity Type';
    currentTimeMatter: any = '';
    priviousQuntitychnage: any;
    timeEntryForm: FormGroup;
    matterautoVal: any;
    maaterguid: any;
    windowNameId: any;
    toolTipPostion = "above";
    appPermissions: any = JSON.parse(localStorage.getItem('app_permissions'));
    activeUrl: any = '';
    RequestGuid: any;
    Deliverable: any;
    DeliverableField: any;
    DerivableDropShow: boolean = false;
    accessTierPermission: any = JSON.parse(localStorage.getItem('set_tier_permission'))
    currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    testBool: boolean = false;
    toolTipList: any;

    App_StartTime: any;
    App_EndTime: any;
    modeIs: any;
    disabledData: boolean = true;
    sub: Subscription;
    @ViewChild(MatAutocomplete) autocomplete: MatAutocomplete;
    @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;
    isAutocompleteOpen :boolean= false
    constructor(
        private _mainAPiServiceService: MainAPiServiceService,
        public dialogRef: MatDialogRef<TimeEntryDialogComponent>,
        public MatDialog: MatDialog,
        private Timersservice: TimersService,
        private toastr: ToastrService,
        private _formBuilder: FormBuilder,
        public datepipe: DatePipe,
        private behaviorService: BehaviorService,
        private _fuseSidebarService: FuseSidebarService,
        public globalFunService: GlobalFunctionsService,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private router: Router,
        private tierpermission: TierPermissionPipe,
        public tooltipService: TooltipService,
        public socketService: SocketService,
        private _changeDetectorRef: ChangeDetectorRef
        ) {

        localStorage.setItem('istrackid', 'timeentriesdialogcomponent');


        // this.behaviorService.MatterData$.subscribe(result => {
        //   if (result) {
        //     this.matterDataForFree = result;
        //   }
        // });
        // this.behaviorService.MatterData$.subscribe(result => {
        //     const materIDofTab = window.name.split("_");
        //     this.windowNameId = (materIDofTab && materIDofTab[1]) ? materIDofTab[1] : undefined;
        //     this.matterDataForFree = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));

        //     //Global matter selection.
        //     if (this._data.matterIs) {
        //         this.matterDataForFree = this._data.matterIs
        //     };

        //     if (this.matterDataForFree) {
        //     } else {
        //         if (result) {
        //             this.matterDataForFree = result;
        //             localStorage.setItem(this.windowNameId, JSON.stringify(this.matterDataForFree))
        //         }
        //     }
        // });
        /**
  /**
         *  Subscribes to the clickMaterFromTimeLine$ observable provided by behaviorService.
         * @remarks
         * It is particularly relevant for scenarios where the behaviorService controls the "open in new tab"
         * feature(on or off), and the selection of matterGuid is influenced by whether multiple tabs are open.
         */
        this.behaviorService.clickMaterFromTimeLine$
            .pipe(
                tap(result => {
                    if (result) {
                        this.matterDataForFree = result;
                    } else {
                        this.matterDataForFree = JSON.parse(localStorage.getItem(this.windowNameId || 'set_active_matters')) || result;
                        localStorage.setItem(this.windowNameId || 'set_active_matters', JSON.stringify(this.matterDataForFree));
                    }
                    //Global matter selection.
                        if (this._data.matterIs) {
                            this.matterDataForFree = this._data.matterIs
                        };
                })
            )
            .subscribe();
        if (_data.edit == 'Edit' || _data.edit == 'Add' || _data.edit == "Duplicate" || _data.edit == "NewTimeEntryMatter") {
            // const pipe = this.tierpermission.transform('Matter Deliverables');
            //   if(pipe && this.currentUser.PRODUCTTYPE!='Barrister' && pipe.ALLOWED ==1){
            //   this.isLoadingResults = true;
            //   if(_data.edit !== 'Add'){
            //     this._mainAPiServiceService.getSetData({ Action: "GetData",Filters: {MATTERGUID: this.matterDataForFree.MATTERGUID }, }, 'matter-deliverable').subscribe(responses => {
            //       if (responses.CODE === "200" && responses.STATUS === 'success') {
            //          this.isLoadingResults = false;
            //          this.Deliverable = responses.DATA.RECORDS;
            //       }else{

            //         this.isLoadingResults = false;
            //       }
            //     },error=>{
            //       this.isLoadingResults = false;

            //     });
            //   }

            // }
            // getSetData({ 'LookupType': 'Rates' }, 'GetLookups')
            this._mainAPiServiceService.getSetData({ Action: 'getdata', Filters: { 'LookupType': 'Rates' } }, 'lookup').subscribe(responses => {
                if (responses.CODE === 200 && responses.STATUS === 'success') {
                    localStorage.setItem('Rates_lookups', JSON.stringify(responses.DATA.LOOKUPS));
                    this.rateid = responses.DATA.LOOKUPS;
                }
            });
            this.isDisable = _data.edit == 'Edit' ? true : false;
            this.action = _data.edit;
            if (this.action === 'Edit') {
                this.dialogTitle = 'Update Time Entry';
            } else if (this.action == 'Duplicate') {
                this.dialogTitle = 'Duplicate Time Entry'
            } else {
                this.dialogTitle = 'Add New Time Entry';
                this.currentTimeMatter = _data.matterData;
                this.ITEMDATEModel = new Date();
            }
            this.GStFreecheck();
            this.buttonText = _data.edit === 'Edit' ? 'Update' : 'Save';
            this.isStartButton = false;
        } else {
            this.isDisable = false;
            this.isStartButton = true;
            this.previewTimer = _data.previewTimer;
            // this.currentTimeMatter = _data.edit;
            this.matterTimerData = _data.matterData;
            this.ITEMDATEModel = new Date();
        }
        this.behaviorService.MatterData$.subscribe(result => {
            if (result) {
                this.maaterguid = result;
            }
        });
        // this.behaviorService.MatterData$.subscribe(result => {
        //        const materIDofTab = window.name.split("_").pop();
        // this.windowNameId = (materIDofTab && materIDofTab[1])?materIDofTab[1] : undefined;

        //   this.maaterguid = JSON.parse(localStorage.getItem(this.windowNameId? this.windowNameId : 'set_active_matters'));
        //   if (this.maaterguid) {
        //   } else {
        //     if (result) {
        //       this.maaterguid = result;
        //       localStorage.setItem(this.windowNameId,JSON.stringify(this.maaterguid))
        //     }
        //   }
        // });

        this.behaviorService.dialogClose$.subscribe(result => {
            if (result != null) {
                if (result.MESSAGE == 'Not logged in') {
                    this.dialogRef.close(false);
                }
            }
        });
        this.behaviorService.APIretryCall$.subscribe((result) => {
            if (localStorage.getItem('istrackid') == 'timeentriesdialogcomponent' && result['click'] && result['data']['lookup']['matter-deliverable']) {
                this.ngOnInit();
            }
            if (localStorage.getItem('istrackid') == 'timeentriesdialogcomponent' && result['click'] && result['data']['workitem']) {
                this.SaveClickTimeEntry();
            }
            if (localStorage.getItem('istrackid') == 'timeentriesdialogcomponent' && result['click'] && result['data']['workitem']) {
                this.setTimeEntryData();
            }

        });


        const url = this.router.url;
        const URL = url.split('/');
        this.activeUrl = URL;
    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit() {
        this.behaviorService.DELIVERABLES$.subscribe(data => {
            this.DeliverableField = data
            if (this.DeliverableField == "No") {
                this.DerivableDropShow = true
            } else {
                this.DerivableDropShow = false
            }
        })
        let today = new Date();
        let time = today.toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");
        let currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
        this.timeStops = this.getTimeStops('00:00', '23:30');
        this.ActivityList = this.optionList;
        this.timeEntryForm = this._formBuilder.group({
            matterautoVal: [''],
            MATTERGUID: ['', Validators.required],
            ITEMTYPE: [''],
            QUANTITYTYPE: [''],
            ITEMDATE: ['', Validators.required],
            FEEEARNER: [''],
            MATTERDELIVERABLEGUID: [''],
            QUANTITY: [''],
            PRICE: [0,{ updateOn: 'change' }],
            PRICEINCGST: [0,{ updateOn: 'change' }],
            SendPRICEINCGST: [''],
            SendPRICE: [''],
            ITEMTIME: [(this.currentUser.PREFILLTIMEOFDAY) ? moment(time, 'hh:mm A').format("HH:mm") : ''],
            ADDITIONALTEXTSELECT: [''],
            ADDITIONALTEXT: ['', Validators.required],
            COMMENT: [''],
            RATEID: [''],
            start: [''],
            Finish: [''],
            APPOINTMENTTIME: [''],
            TimeSlot2: [''],
            INVOICEDATE: [this.datepipe.transform(new Date(), 'dd/MM/yyyy'),
            ],
        });
        this.RequestGuid = this._mainAPiServiceService.generateUniqSerial();
        // this.timeEntryForm.controls['PRICE'].setValue(0.00);
        // this.timeEntryForm.controls['PRICEINCGST'].setValue(0.00);
        // this.timeEntryForm.controls['SendPRICE'].setValue(0.00);
        // this.timeEntryForm.controls['SendPRICEINCGST'].setValue(0.00);

        if (this.action === 'Add' || this.action === 'NewTimeEntryMatter') {
            this.setDefault();
        }
        let currentMatter = JSON.parse(localStorage.getItem(this.windowNameId ? this.windowNameId : 'set_active_matters'));
        if (currentMatter) {
            this.timeEntryForm.controls['matterautoVal'].setValue(currentMatter.SHORTNAME);
            if (this._data.ActiveMatterData) {
                this.mattername = [this._data.ActiveMatterData.SELECTEDDESCRIPTION]; // Edit time set matter.
                this.timeEntryForm.controls['MATTERGUID'].setValue(currentMatter.MATTERGUID);
                this.timeEntryForm.controls['matterautoVal'].setValue(currentMatter.SHORTNAME);
            } else {
                if (this.activeUrl && this.activeUrl[1] == 'matters' || this.activeUrl && this.activeUrl[1] == 'time-billing') {
                    // this.mattername = [currentMatter.SELECTEDDESCRIPTION]; // Edit time set matter.
                    // this.timeEntryForm.controls['MATTERGUID'].setValue(currentMatter.MATTERGUID);
                    // this.timeEntryForm.controls['matterautoVal'].setValue(currentMatter.SHORTNAME);

                    let matterStatus: any = localStorage.getItem('MatterWindow_Open_Status');
                    if (this.router.url.split("/")[1] == 'matter-details' || this.router.url.split("/")[1] == 'matters' || matterStatus == true) {
                        this.mattername = [currentMatter.SELECTEDDESCRIPTION];
                        this.timeEntryForm.controls['MATTERGUID'].setValue(currentMatter.MATTERGUID);
                        this.timeEntryForm.controls['matterautoVal'].setValue(currentMatter.SHORTNAME);
                    }

                    if (this.action !== 'Edit' && this.action !== "Duplicate") {
                        this.getDeliverabledata(currentMatter)
                    }
                } else {
                    this.mattername = '';
                    this.timeEntryForm.controls['matterautoVal'].setValue('');
                    this.timeEntryForm.controls['MATTERGUID'].setValue('');
                }
            };

            this.calculateData.MatterGuid = currentMatter.MATTERGUID
            // this.timeEntryForm.controls['MATTERGUID'].setValue(currentMatter.MATTERGUID);
            this.calculateData.QuantityType = currentUser.DefaultQuantityType;
        }
        this.timeEntryForm.controls['ITEMTYPE'].setValue('wip');
        let userType = JSON.parse(localStorage.getItem('currentUser'));
        if (userType) {
            this.timeEntryForm.controls['FEEEARNER'].setValue((userType.WORKINGONBEHALFOFGUID) ? userType.WORKINGONBEHALFOFGUID : userType.UserId);
        }
        this.timeEntryForm.controls['QUANTITY'].setValue(0);
        this.isLoadingResults = true;
        let LookupsList = JSON.parse(localStorage.getItem('lookups_list'));
        if (LookupsList && LookupsList != null) {
            this.LookupsList = LookupsList;
            this.filteredOptionsAuto = this.f.ADDITIONALTEXTSELECT.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
            this.filteredOptions = this.LookupsList
        } else {
            this.Timersservice.GetLookupsData({ Action: 'getdata', Filters: { 'LookupType': 'Time Entry' } }).subscribe(res => {
                if (res.CODE == 200 && res.STATUS == "success") {
                    this.LookupsList = res.DATA.LOOKUPS;
                    localStorage.setItem('lookups_list', JSON.stringify(res.DATA.LOOKUPS));
                    this.filteredOptions = this.LookupsList

                     this.filteredOptionsAuto = this.f.ADDITIONALTEXTSELECT.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
                } else if (res.MESSAGE == 'Not logged in') {
                    this.dialogRef.close(false);
                } else {
                    this.LookupsList = [];
                }
                // this.isLoadingResults = false;
            }, err => {
                this.toastr.error(err);
            });
        }
        // this.isLoadingResults = true;
        let userList = JSON.parse(localStorage.getItem('users_list'));
        if (userList && userList != null) {
            this.userList = this.Timersservice.removeUSerWithoutId(userList);
            this.isLoadingResults = false;
        } else {
            this.Timersservice.GetUsers({}).subscribe(res => {
                if (res.CODE == 200 && res.STATUS == "success") {
                    this.userList = this.Timersservice.removeUSerWithoutId(res.DATA.USERS);
                    localStorage.setItem('users_list', JSON.stringify(res.DATA.USERS));
                } else if (res.MESSAGE == 'Not logged in') {
                    this.dialogRef.close(false);
                } else {
                    this.userList = [];
                }
                this.isLoadingResults = false;
            }, err => {
                this.toastr.error(err);
            });
        }
        // this.isLoadingResults = true;
        if (this.action === 'Edit' || this.action == "Duplicate") {
            this.setTimeEntryData();
            this.isreadonly = false;
        } else if (this.currentTimeMatter != '') {
            this.isreadonly = true;
            if (this._data.isReadOnly) {
                this.isreadonly = false;
            }

            // this.timeEntryForm.controls['MATTERGUID'].setValue(this.currentTimeMatter);
            // this.calculateData.QuantityType = currentUser.DefaultQuantityType
            // if (this.f.QUANTITYTYPE.value == "Units") {
            //   this.priviousQuntitychnage = 'U';
            //   let sendData = {
            //     FromQuantityType: 'X',
            //     toQuantityType: 'U',
            //     Quantity: 0
            //   }
            //   this.Timersservice.ChangeQuantityType(sendData).subscribe(response => {
            //     if (response.CODE == 200 && response.STATUS == "success") {
            //       this.timeEntryForm.controls['QUANTITY'].setValue(response.DATA.QUANTITY);
            //       this.matterChange('MatterGuid', this.currentTimeMatter);
            //     } else if (response.MESSAGE == 'Not logged in') {
            //       this.dialogRef.close(false);
            //     }
            //   }, err => {
            //     this.isLoadingResults = false;
            //     this.toastr.error(err);
            //   });
            // }
        }

        this.sub = this.timeEntryForm.valueChanges.subscribe((form) => {
            const newFormControl = form;
            if (newFormControl.ADDITIONALTEXTSELECT) {
                newFormControl.ADDITIONALTEXT = newFormControl.ADDITIONALTEXTSELECT
            }
            this.changeForControl(newFormControl)
        });

        this.loadMatter(this.matterDataForFree);

        if (this.appPermissions[2]) {
            if (this.appPermissions[2]['Override WIP Price'] == 1) {
                this.timeEntryForm.controls['PRICEINCGST'].enable();
                this.timeEntryForm.controls['PRICE'].enable();
            } else {
                this.timeEntryForm.controls['PRICEINCGST'].disable();
                this.timeEntryForm.controls['PRICE'].disable();

            }
        }
        this.setTooltipData()
    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe();
    }

    async setTooltipData() {
        let TooltipData = JSON.parse(localStorage.getItem('FieldTooltipData'));
        if (TooltipData && TooltipData['WorkItems']) {
            this.toolTipList = TooltipData['WorkItems'];
        } else {
            this.tooltipService.setToolTipData = ('WorkItems');
        }
    };

    ToggleForUpadte() {
        this.testBool = !this.testBool;
        this.setTooltipData()
    };
    loadMatter(result: any) {
        this.matterDataForFree = result;
        let matterStatus: any = localStorage.getItem('MatterWindow_Open_Status');
        if (this.router.url.split("/")[1] == 'matter-details' || this.router.url.split("/")[1] == 'matters' || matterStatus == true) {
            if (this._data?.ActiveMatterData || this._data?.matterIs) {
                result = (this._data?.ActiveMatterData) ? this._data.ActiveMatterData : this._data?.matterIs;
            };

            this.timeEntryForm.controls['MATTERGUID'].setValue(result.MATTERGUID);
            this.timeEntryForm.controls['matterautoVal'].setValue(result.SHORTNAME + ' : ' + result.MATTER);
            this.mattername = [result.SELECTEDDESCRIPTION];
        }

        this.matterChange('MatterGuid', result.MATTERGUID);
        if (this.action != 'Edit' && this.action != "Duplicate") {
            this.getDeliverabledata(result);
        }
    }



    async getDeliverabledata(data) {
        const pipe = this.tierpermission.transform('Matter Deliverables');
        if (pipe && this.currentUser.PRODUCTTYPE != 'Barrister' && pipe.ALLOWED == 1) {
            return new Promise(async (next, rej) => {
                await this._mainAPiServiceService.getSetData({ Action: "GetData", Filters: {"INCLUDEBLANKROW": true, MATTERGUID: data?.MATTERGUID, ACTIVE: "Active", MUSTINCLUDEGUID: (data?.MATTERDELIVERABLEGUID) ? data?.MATTERDELIVERABLEGUID :'' }, }, 'matter-deliverable').subscribe(responses => {
                    if (responses.CODE == "200" && responses.STATUS == 'success') {
                        this.isLoadingResults = false;
                        this.Deliverable = responses.DATA.RECORDS;
                        next(this.Deliverable);
                    } else {
                        this.isLoadingResults = false;
                        next(this.Deliverable);
                    }
                }, error => {
                    this.isLoadingResults = false;
                    next(true);
                });
            })
        }
    }

    changeForControl(formControl) {
        const dubObj = { ...this.errorWarningDataArray.Error };
        let element = this.errorWarningData.Error;
        for (const [key, value] of Object.entries(dubObj)) {
            const KEY = (key).toLocaleUpperCase();

            if (formControl[KEY] != undefined && formControl[KEY] != null && formControl[KEY]) {
                delete element[key];
                dubObj[key] = value;
                this.errorWarningDataArray.Error = { ...dubObj }
            } else if (!formControl[KEY]) {
                element[key] = dubObj[key];
            }
        }

        const dubObj_1 = { ...this.errorWarningDataArray.Warning };
        let element_1 = this.errorWarningData.Warning;
        for (const [key, value] of Object.entries(dubObj_1)) {
            const KEY = (key).toLocaleUpperCase();
            if (formControl[KEY] != undefined && formControl[KEY] != null && formControl[KEY]) {
                delete element_1[key];
                dubObj_1[key] = value;
                this.errorWarningDataArray.Warning = { ...dubObj_1 }
            } else if (!formControl[KEY]) {
                element_1[key] = dubObj_1[key];
            }
        }
    }

    setDefaultQuantityType(DefaultQuantityType: any) {
        this.calculateData.QuantityType = DefaultQuantityType;
        switch (DefaultQuantityType) {
            case 'X': {
                this.timeEntryForm.controls['QUANTITYTYPE'].setValue('hh:mm')
                break;
            }
            case 'H': {
                this.timeEntryForm.controls['QUANTITYTYPE'].setValue('Hours')
                break;
            }
            case 'M': {
                this.timeEntryForm.controls['QUANTITYTYPE'].setValue('Minutes')
                break;
            }
            case 'D': {
                this.timeEntryForm.controls['QUANTITYTYPE'].setValue('Days')
                break;
            }
            case 'U': {
                this.timeEntryForm.controls['QUANTITYTYPE'].setValue('Units')
                break;
            }
            case 'F': {
                this.timeEntryForm.controls['QUANTITYTYPE'].setValue('Fixed')
                break;
            }
            default: {
                this.timeEntryForm.controls['QUANTITYTYPE'].setValue('Fixed')
                break;
            }
        }
    }
    setDefault() {
        let defultData = {
            'Minutes': 'M',
            'hh:mm': 'X',
            'Hours': 'H',
            'Days': 'D',
            'Units': 'U',
            'Fixed': 'F',
        };
        let todayTime = new Date();
        let timeToday = todayTime.toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");
        let currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
        this.isLoadingResults = true;
        this.Timersservice.SetWorkItems({ FormAction: 'default', VALIDATEONLY: true, RequestGuid: this.RequestGuid, DATA: {} }).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                let timeEntryData = response.DATA.DEFAULTVALUES;
                this.toolTipList = response.DATA.FIELDTIPS
                if (timeEntryData.ITEMTYPE == "activity" || timeEntryData.ITEMTYPE == "sundry") {
                    this.timeEntryForm.controls['QUANTITYTYPE'].setValue(timeEntryData.FEETYPE);
                }
                this.timeEntryForm.controls['QUANTITY'].setValue(timeEntryData.QUANTITY);
                if (timeEntryData.ITEMTIME != '') {
                    // Old
                    // let ttyData = moment(timeEntryData.ITEMTIME, 'hh:mm');
                    // this.timeEntryForm.controls['ITEMTIME'].setValue(moment(ttyData).format('hh:mm A'));

                    //new as per this todo : - When adding a Time Entry the time of day on has 30 minute options
                    let ttyData = this.convert12to24Fomat(timeEntryData.ITEMTIME);
                    this.timeEntryForm.controls['ITEMTIME'].setValue(ttyData);
                } else {
                    const tt = (this.currentUser.PREFILLTIMEOFDAY) ? moment(timeToday, 'hh:mm A').format("HH:mm") : '';
                    this.timeEntryForm.controls['ITEMTIME'].setValue(tt);
                };
                let tempDate = timeEntryData.ITEMDATE.split("/");
                this.timeEntryForm.controls['INVOICEDATE'].setValue(timeEntryData.ITEMDATE);
                this.ITEMDATEModel = new Date(tempDate[1] + '/' + tempDate[0] + '/' + tempDate[2]);
                this.timeEntryForm.controls['SendPRICEINCGST'].setValue(timeEntryData.PRICEINCGST);
                this.timeEntryForm.controls['SendPRICE'].setValue(timeEntryData.PRICE);
                this.timeEntryForm.controls['PRICEINCGST'].setValue((Number(timeEntryData.PRICEINCGST)).toFixed(2));
                // this.timeEntryForm.controls['PRICE'].setValue((Number(timeEntryData.PRICE)).toFixed(2));
                this.timeEntryForm.controls['PRICE'].setValue(timeEntryData.PRICE);
                this.timeEntryForm.controls['ADDITIONALTEXT'].setValue(timeEntryData.ADDITIONALTEXT);
                this.timeEntryForm.controls['ADDITIONALTEXTSELECT'].setValue(timeEntryData.ADDITIONALTEXT);
                this.timeEntryForm.controls['COMMENT'].setValue(timeEntryData.COMMENT);
                this.timeEntryForm.controls['RATEID'].setValue((timeEntryData.RATEID).toString());
                this.timeEntryForm.controls['MATTERDELIVERABLEGUID'].setValue(timeEntryData.MATTERDELIVERABLEGUID);


                let matterStatus: any = localStorage.getItem('MatterWindow_Open_Status');
                if (this.router.url.split("/")[1] !== 'matter-details' && this.router.url.split("/")[1] !== 'matters' && this.router.url.split("/")[1] !== 'time-entries') {
                    this.timeEntryForm.controls['MATTERGUID'].setValue('');
                    this.timeEntryForm.controls['matterautoVal'].setValue('')
                    this.mattername = []
                } else {
                    if (this._data?.ActiveMatterData || this._data?.matterIs) {
                        let result = (this._data?.ActiveMatterData) ? this._data.ActiveMatterData : this._data?.matterIs;
                        this.timeEntryForm.controls['MATTERGUID'].setValue(result.MATTERGUID);
                        this.timeEntryForm.controls['matterautoVal'].setValue(result.SHORTNAME + ' : ' + result.MATTER)
                    }
                }

                this.CheckdefultValidation(response.DATA.VALIDATIONS);
                this.setDefaultQuantityType(defultData[timeEntryData.QUANTITYTYPE]);
                // this.setDefaultQuantityType(defultData[timeEntryData.] currentUser.DefaultQuantityType);
            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
            this.isLoadingResults = false;
        }, err => {
            this.isLoadingResults = false;
            this.toastr.error(err);
        });
    }
    CheckdefultValidation(bodyData: any) {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value: { VALUEVALID: string; ERRORDESCRIPTION: any; FIELDNAME: any; }) {

            if (value.VALUEVALID == 'No' || value.VALUEVALID == 'NO') {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == 'Warning') {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
        this.errorWarningDataArray = { "Error": tempError, 'Warning': tempWarning };
    }
    GStFreecheck() {
        if (this.matterDataForFree) {
            if (this.matterDataForFree.GSTTYPEDESC == 'GST Free' || this.matterDataForFree.GSTTYPEDESC == "Export") {
                this.isGStFree = true;
                this.IncWith = 0;
                this.ExWith = 100;
            } else {
                this.isGStFree = false;
                this.IncWith = 50;
                this.ExWith = 50;
            }
        } else {
            this.isGStFree = false;
            this.IncWith = 50;
            this.ExWith = 50;
        }
    }
    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.LookupsList.filter(option => (option.LOOKUPFULLVALUE.toLowerCase().includes(filterValue)));
    }
    startTimer(TimerData: any) {
        this.Timersservice.addTimeEnrtS(TimerData);
        this.dialogRef.close(false);
        this.behaviorService.setTimerCountData();
        this._fuseSidebarService.getSidebar('quickPanel').toggleOpen();
    }
    getTimeStops(start, end) {
        var startTime = moment(start, 'hh:mm');
        var endTime = moment(end, 'hh:mm');
        if (endTime.isBefore(startTime)) {
            endTime.add(1, 'day');
        }
        var timeStops = [];
        while (startTime <= endTime) {
            timeStops.push(moment(startTime).format('hh:mm A'));
            startTime.add(30, 'minutes');
        }
        return timeStops;
    }
    getTime() {
        let enterVal = this.f.ITEMTIME.value;
        let getArr = enterVal.split(/[\s.]+/);
        let finalVal;
        if (getArr[1]) {
            let num = getArr[1].match(/\d+/g);
            let first2 = num[0].substring(0, 2);
            let letr = getArr[1].match(/[a-zA-Z]+/g);
            let finalSecondStr = first2 + letr[0];
            let newVal = getArr[0] + finalSecondStr;
            finalVal = moment(newVal.toString(), "hmmA").format("HH:mm A")
        } else {
            finalVal = moment(getArr[0], "hA").format("HH:mm A")
        }
        if (finalVal == 'Invalid date') {
            this.timeEntryForm.controls['ITEMTIME'].setValue(this.storePreviousDate);
        } else {
            this.timeEntryForm.controls['ITEMTIME'].setValue(finalVal);
            this.storePreviousDate = this.f.ITEMTIME.value;
        }
    }

    // Helper function to truncate to 2 decimal places
    truncateToTwoDecimals(value: number): number {
        return Math.floor(value * 100) / 100;
    }

    calcPE() {
        let priceTemp: number;
        if (Number(this.StoreExGST) == Number(this.f.PRICE.value)) {
            priceTemp = Number(this.f.SendPRICE.value);
        } else {
            priceTemp = Number(this.f.PRICE.value);
        }
        if (typeof priceTemp === 'undefined') {
            priceTemp = 0;
        }
        // priceTemp = parseFloat(priceTemp.toFixed(2));
        priceTemp = this.truncateToTwoDecimals(priceTemp);
        this.PRICEVAL = priceTemp;
        this.timeEntryForm.controls['SendPRICE'].setValue(priceTemp);
        // if (this.f.PRICE.value != this.lastPrice) {
        // const priceTempGst = parseFloat((priceTemp * 1.1).toFixed(2));
        const priceTempGst = this.truncateToTwoDecimals(priceTemp * 1.1);
        // }
        this.timeEntryForm.controls['SendPRICEINCGST'].setValue(priceTempGst);
        this.PRICEINCGSTVAL = priceTempGst;
        this.StoreIncGST = priceTempGst;
        this.lastPrice = this.f.PRICE.value;
        this.addTooltip();
    }

    calcPI() {
        let temGst: number;
        if (Number(this.StoreIncGST) == Number(this.f.PRICEINCGST.value)) {
            temGst = Number(this.f.SendPRICEINCGST.value);
        } else {
            temGst = Number(this.f.PRICEINCGST.value);
        }
        // this.StoreIncGST = Number(this.PRICEINCGSTVAL);
        //  temGst = this.f.PRICEINCGST.value;
        if (typeof temGst === 'undefined') {
            temGst = 0;
        }
        // temGst = parseFloat(temGst.toFixed(2));
        temGst = this.truncateToTwoDecimals(temGst);
        this.timeEntryForm.controls['SendPRICEINCGST'].setValue(temGst);
        // const formattedPrice = parseFloat(CalcWorkItemCharge.PRICE.toFixed(2));
        this.PRICEINCGSTVAL = temGst;
        // const priceExGst = parseFloat((temGst / 1.1).toFixed(2));
        const priceExGst = this.truncateToTwoDecimals(temGst / 1.1);
        // temGst = temGst / 1.1;
        this.timeEntryForm.controls['SendPRICE'].setValue(priceExGst);
        this.PRICEVAL = priceExGst;
        this.StoreExGST = priceExGst;
        this.addTooltip();
    }

    public selectMatter() {
        this.searchData = [];
        this.matterlist = this.searchData;

        const dialogRef = this.MatDialog.open(MatterDialogComponent, { width: '100%', disableClose: true, data: null });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                localStorage.setItem('istrackid', 'timeentriesdialogcomponent');
                this.matterDataForFree = result;
                this.timeEntryForm.controls['MATTERGUID'].setValue(result.MATTERGUID);
                this.timeEntryForm.controls['matterautoVal'].setValue(result.SHORTNAME + ' : ' + result.MATTER);
                this.mattername = [result.SELECTEDDESCRIPTION];
                this.matterChange('MatterGuid', result.MATTERGUID);
                this.getDeliverabledata(result);
                this.GStFreecheck();
            }
        });
    }
    setTimeEntryData() {
        this.isLoadingResults = true;
        let workerGuid;
        this.behaviorService.workInProgress$.subscribe(workInProgressData => {
            if (workInProgressData) {
                workerGuid = workInProgressData.WORKITEMGUID;
            } else {
                workerGuid = localStorage.getItem('edit_WORKITEMGUID');
            }
        });
       
        this.Timersservice.getTimeEnrtyData({ 'WorkItemGuid': this._data.TABLEGUID ? this._data.TABLEGUID : workerGuid }).subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                // added by web19 19/06
               
                this.toolTipList = response.DATA.FIELDTIPS;
                if (response.DATA.WORKITEMS[0] && response.DATA.WORKITEMS[0].MATTERGUID) {
                    this.getDeliverabledata(response.DATA.WORKITEMS[0]);
                };

                this.matterChange('MatterGuid', response.DATA.WORKITEMS[0].MATTERGUID);
                this.matterChange('QuantityType', response.DATA.WORKITEMS[0].QUANTITYTYPE);
                this.timeEntryForm.controls['matterautoVal'].setValue(response.DATA.WORKITEMS[0].SHORTNAME);
                // this.mattername = [response.DATA.WORKITEMS[0].SELECTEDDESCRIPTION]; // Edit time set matter.
                this.mattername = (!response.DATA.WORKITEMS[0].SELECTEDDESCRIPTION || response.DATA.WORKITEMS[0].SELECTEDDESCRIPTION == '') ? [] : [response.DATA.WORKITEMS[0].SELECTEDDESCRIPTION]; // Edit time set matter.
                this.timeEntryForm.controls['RATEID'].setValue(response.DATA.WORKITEMS[0].RATEID.toString());
                localStorage.setItem('edit_WORKITEMGUID', response.DATA.WORKITEMS[0].WORKITEMGUID);
                let timeEntryData = response.DATA.WORKITEMS[0];
                this.itemTypeChange(timeEntryData.ITEMTYPE);
                if (timeEntryData.ITEMTYPE == "activity" || timeEntryData.ITEMTYPE == "sundry") {
                    this.timeEntryForm.controls['QUANTITYTYPE'].setValue(timeEntryData.FEETYPE);
                } else {
                    this.timeEntryForm.controls['QUANTITYTYPE'].setValue(timeEntryData.QUANTITYTYPE);
                }
                this.timeEntryForm.controls['QUANTITY'].setValue(timeEntryData.QUANTITY);
                this.timeEntryForm.controls['MATTERGUID'].setValue(timeEntryData.MATTERGUID);
                this.timeEntryForm.controls['ITEMTYPE'].setValue(timeEntryData.ITEMTYPE);
                if (timeEntryData.ITEMTIME != '') {
                    //old
                    // let ttyData = moment(timeEntryData.ITEMTIME, 'hh:mm');
                    // this.timeEntryForm.controls['ITEMTIME'].setValue(timeEntryData.ITEMTIME);

                    //new changes As per this Todo - When adding a Time Entry the time of day on has 30 minute options.
                    let ttyData = this.convert12to24Fomat(timeEntryData.ITEMTIME);
                    this.timeEntryForm.controls['ITEMTIME'].setValue(ttyData);

                    // this.timeEntryForm.controls['ITEMTIME'].setValue(moment(ttyData).format('hh:mm A'));
                } else {
                    this.timeEntryForm.controls['ITEMTIME'].setValue('');
                }
                this.timeEntryForm.controls['FEEEARNER'].setValue(timeEntryData.FEEEARNER);

                this.timeEntryForm.controls['MATTERDELIVERABLEGUID'].setValue(timeEntryData.MATTERDELIVERABLEGUID);

                let tempDate = timeEntryData.ITEMDATE.split("/");
                this.timeEntryForm.controls['INVOICEDATE'].setValue(timeEntryData.ITEMDATE);
                if (this.action == "Duplicate") {
                    this.ITEMDATEVLAUE = this.datepipe.transform(new Date(), 'dd/MM/yyyy');
                    this.ITEMDATEModel = new Date();
                } else {
                    this.ITEMDATEModel = new Date(tempDate[1] + '/' + tempDate[0] + '/' + tempDate[2]);
                }

                this.timeEntryForm.controls['SendPRICEINCGST'].setValue(timeEntryData.PRICEINCGST);
                this.timeEntryForm.controls['SendPRICE'].setValue(timeEntryData.PRICE);
                this.timeEntryForm.controls['PRICEINCGST'].setValue((Number(timeEntryData.PRICEINCGST)).toFixed(2));
                // this.timeEntryForm.controls['PRICE'].setValue((Number(timeEntryData.PRICE)).toFixed(2));
                this.timeEntryForm.controls['PRICE'].setValue(timeEntryData.PRICE);
                this.timeEntryForm.controls['ADDITIONALTEXT'].setValue(timeEntryData.ADDITIONALTEXT);
                // this.timeEntryForm.controls['MATTERDELIVERABLEGUID'].setValue(timeEntryData.DELIVERABLES);
                this.timeEntryForm.controls['ADDITIONALTEXTSELECT'].setValue(timeEntryData.ADDITIONALTEXT);
                this.timeEntryForm.controls['COMMENT'].setValue(timeEntryData.COMMENT);
                this.timeEntryForm.controls['RATEID'].setValue(timeEntryData.RATEID.toString());
                this.timeEntryForm.controls['MATTERDELIVERABLEGUID'].setValue(timeEntryData.MATTERDELIVERABLEGUID);

            } else if (response.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
            this.isLoadingResults = false;
        }, err => {
            this.isLoadingResults = false;
            this.toastr.error(err);
        });
    }
    addTooltip() {
        // if (Number(this.matterDataForFree.ESTIMATEFROMTOTALEXGST) < Number(this.f.PRICE.value)) {
        //   this.toltipText1 = "This will exceed the estimate for this matter!";
        //   this.toltipText2 = 'Minimum Estimate : $' + Number(this.matterDataForFree.ESTIMATEFROMTOTALEXGST) + '(Inc-GST)';
        //   this.toltipText3 = 'Amount Charged : $' + Number(this.matterDataForFree.ESTIMATEFROMTOTALEXGST) + '(Inc-GST)';
        // } else {
        //   this.toltipText1 = "";
        //   this.toltipText2 = "";
        //   this.toltipText3 = "";
        // }
    }
    choosedDate(type: string, event: MatDatepickerInputEvent<Date>) {
        this.ITEMDATEVLAUE = this.datepipe.transform(event.value, 'dd/MM/yyyy');
    }

  /**
     * It runs only once after the component's view has been rendered.
     */
    ngAfterViewInit(): void {
        $('#time_Control').attr('placeholder', 'Select time');
        fromEvent(this.MatterInput.nativeElement,'keyup').pipe(
            map((event: any) => event.target.value),
            debounceTime(500),distinctUntilChanged()
          ).subscribe(data=>{
            this.selectMatterFormSearch(data);
        })
    }
    reateId(event) {
        this.calculateData.rateid = this.f.RATEID.value.toString();
        this.matterChange('RateID', event);;
    }
    matterChange(key: any, event: any) {
        if (this.f.FEEEARNER.value && key != "FeeEarner") {
            this.calculateData.FeeEarner = this.f.FEEEARNER.value;
        }
        if (key == "MatterGuid") {
            this.timeEntryForm.controls['MATTERGUID'].setValue(event);
            this.calculateData.MatterGuid = event;
        } else if (key == "FeeEarner") {
            this.calculateData.FeeEarner = event;
        } else if (key == "QuantityType") {
            switch (event) {
                case 'hh:mm': {
                    this.calculateData.QuantityType = 'X';
                    break;
                }
                case 'Hours': {
                    this.calculateData.QuantityType = 'H';
                    break;
                }
                case 'Minutes': {
                    this.calculateData.QuantityType = 'M';
                    break;
                }
                case 'Days': {
                    this.calculateData.QuantityType = 'D';
                    break;
                }
                case 'Units': {
                    this.calculateData.QuantityType = 'U';
                    break;
                }
                case 'Fixed': {
                    this.calculateData.QuantityType = 'F';
                    break;
                }
                default: {
                    this.calculateData.FeeType = event;
                    this.calculateData.QuantityType = 'F';
                    break;
                }
            }
        }
        this.priviousQuntitychnage = this.calculateData.QuantityType;
        this.calculateData.Quantity = this.f.QUANTITY.value;
        this.calculateData.rateid = this.f.RATEID.value.toString();
        if (this.calculateData.MatterGuid != '' && this.calculateData.Quantity != '' && (this.calculateData.QuantityType != '' || this.calculateData.FeeType != '')) {
            this.isLoadingResults = true;
            this.Timersservice.calculateWorkItems(this.calculateData).subscribe(response => {
                if (response.CODE == 200 && response.STATUS == "success") {
                    let CalcWorkItemCharge = response.DATA;
                    this.lastPrice = 0;
                    this.lastGstPrice = Number(CalcWorkItemCharge.PRICEINCGST).toFixed(2);
                    const formattedPrice = parseFloat(CalcWorkItemCharge.PRICE.toFixed(2));
                    this.timeEntryForm.controls['PRICE'].setValue(formattedPrice);
                    const formattedPriceIncGst = parseFloat(CalcWorkItemCharge.PRICEINCGST.toFixed(2));
                    this.timeEntryForm.controls['PRICEINCGST'].setValue(formattedPriceIncGst);
                    this.timeEntryForm.controls['SendPRICE'].setValue(Number(CalcWorkItemCharge.PRICE).toFixed(2));
                    this.timeEntryForm.controls['SendPRICEINCGST'].setValue(Number(CalcWorkItemCharge.PRICEINCGST).toFixed(2));
                    //   this.StoreExGST = Number(CalcWorkItemCharge.PRICE);
                    //   this.StoreIncGST  = Number(CalcWorkItemCharge.PRICEINCGST);
                    this.addTooltip();
                    this.isLoadingResults = false;
                } else if (response.MESSAGE == 'Not logged in') {
                    this.dialogRef.close(false);
                }
            }, err => {
                this.isLoadingResults = false;
                this.toastr.error(err);
            });
        }
    }

    /**
     * This function is used to changes the Quantity types.  
     */
    quntityTypeChange(key: any, event: any) {
        if (this.f.FEEEARNER.value && key != "FeeEarner") {
            this.calculateData.FeeEarner = this.f.FEEEARNER.value;
        }
        if (key == "MatterGuid") {
            this.timeEntryForm.controls['MATTERGUID'].setValue(event);
            this.calculateData.MatterGuid = event;
        } else if (key == "FeeEarner") {
            this.calculateData.FeeEarner = event;
        } else if (key == "QuantityType") {
            switch (event) {
                case 'hh:mm': {
                    this.calculateData.QuantityType = 'X';
                    break;
                }
                case 'Hours': {
                    this.calculateData.QuantityType = 'H';
                    break;
                }
                case 'Minutes': {
                    this.calculateData.QuantityType = 'M';
                    break;
                }
                case 'Days': {
                    this.calculateData.QuantityType = 'D';
                    break;
                }
                case 'Units': {
                    this.calculateData.QuantityType = 'U';
                    break;
                }
                case 'Fixed': {
                    this.calculateData.QuantityType = 'F';
                    break;
                }
                default: {
                    this.calculateData.FeeType = event;
                    this.calculateData.QuantityType = 'F';
                    break;
                }
            }
        }
        this.calculateData.Quantity = this.f.QUANTITY.value;
        // if (this.calculateData.MatterGuid != '' && this.calculateData.Quantity != '' && (this.calculateData.QuantityType != '' || this.calculateData.FeeType != '')) {
        if (this.calculateData.MatterGuid != '') {
            this.isLoadingResults = true;
            let sendData = {
                FromQuantityType: this.priviousQuntitychnage,
                toQuantityType: this.calculateData.QuantityType,
                Quantity: this.f.QUANTITY.value
            }
            this.Timersservice.ChangeQuantityType(sendData).subscribe(response => {
                if (response.CODE == 200 && response.STATUS == "success") {
                    this.timeEntryForm.controls['QUANTITY'].setValue(response.DATA.QUANTITY);
                    this.matterChange('QuantityType', event);
                    this.priviousQuntitychnage = response.DATA.TOQUANTITYTYPE
                    this.isLoadingResults = false;
                } else if (response.MESSAGE == 'Not logged in') {
                    this.dialogRef.close(false);
                }
            }, err => {
                this.isLoadingResults = false;
                this.toastr.error(err);
            });
        }
    }
    get f() {
        return this.timeEntryForm.controls;
    }

    /**
     * This function is used to Change the item type value
     * @param value -value data
     */
    itemTypeChange(value: any) {
        this.isLoadingResults = true;
        if (value == 'activity' || value == 'sundry') {
            this.QuantityTypeLabel = value == 'activity' ? 'Activity' : 'Sundry';
            let callType = value == 'activity' ? 'Activity' : 'Sundries';
            let postData: any = {
                "Action": "GetData",
                "Filters": {
                    "ACTIVITYTYPE": callType
                }
            }
            this.Timersservice.GetActivity(postData).subscribe(res => {
                if (res.CODE == 200 && res.STATUS == "success") {
                    this.ActivityList = res.DATA.ACTIVITIES;
                    this.isLoadingResults = false;
                }
            }, err => {
                this.isLoadingResults = false;
                this.toastr.error(err);
            });
            this.timeEntryForm.controls['QUANTITY'].setValue(1);
        } else {
            this.QuantityTypeLabel = 'Quantity Type';
            this.ActivityList = this.optionList;
            this.isLoadingResults = false;
        }
    }

    /**
     * This function is used to Change the lookups 
     */
    LookupsChange(value: any) {

       // this.timeEntryForm.controls['ADDITIONALTEXTSELECT'].setValue(value);
        if (this.timeEntryForm.controls['ADDITIONALTEXTSELECT'].value != ''){
            this.confirmDialogRef = this.MatDialog.open(FuseConfirmDialogComponent, { disableClose: true, width: '100%', data: { toggleAutocompleteDropdown:'toggleAutocompleteDropdown'}});
            this.confirmDialogRef.componentInstance.confirmMessage = "Do you want to replace the text with the selected item or append the item to the existing text?";
            this.confirmDialogRef.componentInstance.confirmTitle = "Predictive Text";

            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    let data = this.timeEntryForm.controls['ADDITIONALTEXTSELECT'].value +' '+ value
                    this.timeEntryForm.controls['ADDITIONALTEXTSELECT'].setValue(data);
                } else {
                    this.timeEntryForm.controls['ADDITIONALTEXTSELECT'].setValue(value);
                }
            })
        } else {
            this.timeEntryForm.controls['ADDITIONALTEXTSELECT'].setValue(value);
        }
    }

    /**
     * This function is used to change the lookups automatically
     */
    LookupsChangeAuto(value: any) {
        this.timeEntryForm.controls['ADDITIONALTEXTSELECT'].setValue(value);
    }

    /**
     * This function is used to Save the Click Time Entry
     */
    SaveClickTimeEntry(save?: any) {
        this.modeIs = save;
        this.RequestGuid = this._mainAPiServiceService.generateUniqSerial();
        if (this.ITEMDATEVLAUE == "" || this.ITEMDATEVLAUE == null || this.ITEMDATEVLAUE == undefined) {
            this.ITEMDATEVLAUE = this.f.INVOICEDATE.value;
        }
        this.isspiner = true;
        let PostData: any = {
            "ADDITIONALTEXT": this.f.ADDITIONALTEXTSELECT.value,
            "COMMENT": this.f.COMMENT.value,
            "FEEEARNER": this.f.FEEEARNER.value,
            "ITEMTYPE": this.f.ITEMTYPE.value,
            "ITEMDATE": this.ITEMDATEVLAUE,
            "ITEMTIME": this.f.ITEMTIME.value,
            "MATTERGUID": this.f.MATTERGUID.value,
            "PRICE": Number(this.f.SendPRICE.value),
            "PRICEINCGST": this.isGStFree ? Number(this.f.SendPRICE.value) : Number(this.f.SendPRICEINCGST.value),
            "QUANTITY": this.f.QUANTITY.value,
            "RATEID": (this.f.RATEID.value.toString()),
            "MATTERDELIVERABLEGUID": this.f.MATTERDELIVERABLEGUID.value
        }
        if (this.f.ITEMTYPE.value == "activity" || this.f.ITEMTYPE.value == "sundry") {
            PostData.FEETYPE = this.f.QUANTITYTYPE.value;
            PostData.QUANTITYTYPE = '';
        } else {
            PostData.QUANTITYTYPE = this.f.QUANTITYTYPE.value;
        }

        this.successMsg = 'Time entry added successfully';
        let FormAction = this.action == 'Edit' ? 'update' : 'insert';
        if (this.currentUser.PRODUCTTYPE == 'Barrister') {
            delete PostData.FEEEARNER
        }
        if (this.action == 'Edit' || this.action == "Duplicate") {
            PostData.WorkItemGuid = localStorage.getItem('edit_WORKITEMGUID');
            this.successMsg = 'Time entry update successfully';
        } else if (this.action == "Duplicate")
            this.successMsg = 'Time entry Duplicate successfully';

        let PostTimeEntryData: any = { FormAction: FormAction, VALIDATEONLY: true, RequestGuid: this.RequestGuid, Data: PostData };
        this.Timersservice.SetWorkItems(PostTimeEntryData).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                this.checkValidation(res.DATA.VALIDATIONS, PostTimeEntryData);
            } else if (res.CODE == 451 && res.STATUS == 'warning') {
                this.checkValidation(res.DATA.VALIDATIONS, PostTimeEntryData);
                this.isspiner = false;
            } else if (res.CODE == 450 && res.STATUS == 'error') {
                this.checkValidation(res.DATA.VALIDATIONS, PostTimeEntryData);
                this.isspiner = false;
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
            }
        }, err => {
            this.isspiner = false;
            this.toastr.error(err);
        });
    }

    /**
     * This function is used to check the validation 
     */
    async checkValidation(bodyData: any, PostTimeEntryData: any) {
        // let errorData: any = [];
        // let warningData: any = [];
        // let tempError: any = [];
        // let tempWarning: any = [];
        // // errorData
        // bodyData.forEach(function (value) {
        //   if (value.VALUEVALID == 'NO' || value.VALUEVALID == 'No') {
        //     errorData.push(value.ERRORDESCRIPTION);
        //     tempError[value.FIELDNAME] = value;
        //   } else if (value.VALUEVALID == 'WARNING' || value.VALUEVALID == 'Warning') {
        //     tempWarning[value.FIELDNAME] = value;
        //     warningData.push(value.ERRORDESCRIPTION);
        //   }
        // });
        // this.errorWarningData = { "Error": tempError, 'Warning': tempWarning };
        // this.errorWarningDataArray  = { "Error": tempError, 'Warning': tempWarning };

        // if (Object.keys(errorData).length != 0) {
        //   this.toastr.error(errorData);
        //   this.isspiner = false;
        // } else if (Object.keys(warningData).length != 0) {
        //   // this.toastr.warning(warningData);
        //   this.isspiner = false;
        //   this.confirmDialogRef = this.MatDialog.open(FuseConfirmDialogComponent, {
        //     disableClose: true,
        //     width: '100%',
        //     data: warningData
        //   });
        //   this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Save?';
        //   this.confirmDialogRef.afterClosed().subscribe(result => {
        //     if (result) {
        //       this.isspiner = true;
        //       this.saveTimeEntry(PostTimeEntryData);
        //     }
        //     this.confirmDialogRef = null;
        //   });
        // } else if (Object.keys(warningData).length == 0 && Object.keys(errorData).length == 0) {
        //   this.saveTimeEntry(PostTimeEntryData);
        // }

        await this.globalFunService.checkValidation(bodyData, PostTimeEntryData)
            .subscribe(data => {
                if (data) {
                    this.errorWarningData = data.errorWarningData;
                    this.errorWarningDataArray = data.errorWarningData;
                    if (data.callback) {
                        this.isspiner = true
                        this.saveTimeEntry(PostTimeEntryData);
                    } else {
                        this.isspiner = false;
                    }
                };
                //this.isspiner = false;
            });
    }
    radioChange(event) {
        //  this.timeEntryForm.controls['MATTERDELIVERABLEGUID'].setValue(0);
    }

    /**
     * This function is used to Save the Time entry
     */
    saveTimeEntry(PostTimeEntryData: any) {
        PostTimeEntryData.VALIDATEONLY = false;
        this.Timersservice.SetWorkItems(PostTimeEntryData).subscribe(res => {
            if (res.CODE == 200 && res.STATUS == "success") {
                if(this.router.url == '/matter-details/matter-detail-dashboard') {
                    $('#Legal_Details').click();   
                }
                this.toastr.success(this.successMsg);
                this.isspiner = false;
                if (this.modeIs == 'SaveWithNew') {
                    this.setDefault();
                    $('#refrshWorkinprogress').click();
                } else {
                    this.dialogRef.close(true);
                }
            } else if (res.CODE == 451 && res.STATUS == 'warning') {
                this.toastr.warning(res.MESSAGE);
                this.isspiner = false;
            } else if (res.CODE == 450 && res.STATUS == 'error') {
                this.toastr.warning(res.MESSAGE);
                this.isspiner = false;
            } else if (res.MESSAGE == 'Not logged in') {
                this.dialogRef.close(false);
                this.isspiner = false;
            }
        }, err => {
            this.isspiner = false;
            this.toastr.error(err);
        });
    }

   /**
    * This function is used to select the matter Form Search
    */
    public selectMatterFormSearch(event) {

        // let searchobj = { FastSearch: 'True', SearchString: event }
        let searchobj = {
            "Action": "GetData",
            "Filters": { FastSearch: 'True', SearchString: event }
        };

        if (this.searchMatter) {
            this.searchMatter.unsubscribe()
        }
        this.searchMatter = this._mainAPiServiceService.getSetData(searchobj, 'matter').subscribe(response => {
            if (response.CODE == 200 && response.STATUS == "success") {
                this.exportdatavalue = response.DATA
                this.searchData = response.DATA.RECORDS;
                this.matterlist = this.searchData;
            }
        })

    }

    /**
     * This function is used to show the data.
     */
    datashow(event) {
        this.exportdatavalue.RECORDS.forEach((element, i) => {
            if (i == 0) {
                let data = element
                this.mattername = [data.SELECTEDDESCRIPTION]
                this.timeEntryForm.controls['matterautoVal'].setValue(null);

                if (event.input) {
                    event.input.value = '';
                } else {
                    this.MatterInput.nativeElement.value = '';
                }

                this.timeEntryForm.controls['MATTERGUID'].setValue(data.MATTERGUID);
                this.matterChange('MatterGuid', data.MATTERGUID);
                this.getDeliverabledata(data)
                if (this.selectedData.map((ele, index) => ele.MATTERGUID == data.MATTERGUID ? index : -1)[0] == -1 || this.selectedData.length == 0) {
                    this.selectedData = [data];
                }
            }
        });
    }

    /**
     * This function is used to remove the matter chip
     */
    remove(fruit: any) {
        const index = this.mattername.indexOf(fruit);
        if (index >= 0) {
            this.mattername.splice(index, 1);
            this.timeEntryForm.controls['MATTERGUID'].setValue('');
            this.Deliverable = [];
        }
        let sortname = fruit.split('-')[0]
        this.rm(sortname)
    };

    rm(sortname) {
        this.selectedData.forEach((element: any, index: any) => {
            if (element.SHORTNAME.toString() == sortname.replace(/^\s+|\s+$/gm, '')) {
                this.selectedData.splice(index, 1);
            }
        })
    }

    /**
     * This function is used to add the mattername value.
     */
    add(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        this.matterlist.forEach((element: any) => {
            if (element.SHORTNAME == event.value) {
                // Add our fruit
                const array3 = this.mattername;
                if (value) {
                    if (this.mattername.indexOf(value.replace(/^\s+|\s+$/gm, '')) == -1) {
                        this.mattername = [value]
                    }
                }
            }
        });

        // Clear the input value
        if (event.input) {
            event.input.value = '';
        }
        this.timeEntryForm.controls['matterautoVal'].setValue(null);
    };

    selectedValue(event: MatAutocompleteSelectedEvent): void {
        if (this.mattername.indexOf(event.option.viewValue.replace(/^\s+|\s+$/gm, '')) == -1) {
            this.mattername = [event.option.viewValue]
            this.MatterInput.nativeElement.value = '';
            this.timeEntryForm.controls['matterautoVal'].setValue(null);
        }
    }

    /**
     * This function is used to select the matter guid 
     */
    selectedmatterguid(data: any) {
        this.timeEntryForm.controls['MATTERGUID'].setValue(data.MATTERGUID);
        this.matterChange('MatterGuid', data.MATTERGUID);
        this.getDeliverabledata(data)
        // const array3 = this.selectedData;
        // if (array3.length < 1) {
        if (this.selectedData.map((ele, index) => ele.MATTERGUID == data.MATTERGUID ? index : -1)[0] == -1 || this.selectedData.length == 0) {
            this.selectedData = [data];
        }
        // }
    };


    /**
     * This unction is used to convert the time format
     */
    convert12to24Fomat(time: string): string {
        var [shours, sminutes] = time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
        var hours = Number(shours);
        var minutes = Number(sminutes);
        var AMPM = time.slice(-2);
        if (AMPM == "PM" && hours < 12) hours = hours + 12;
        if (AMPM == "AM" && hours == 12) hours = hours - 12;
        var sHours = hours.toString();
        var sMinutes = minutes.toString();
        if (hours < 10) sHours = "0" + sHours;
        if (minutes < 10) sMinutes = "0" + sMinutes;
        return sHours + ":" + sMinutes;
    }
    
    /**
     * This function is used to set the default reminder
     */
    DefaultReminderTime(): string {
        var event = new Date().toLocaleString("en-US", { timeZone: "Australia/sydney" });
        let time = event.toLocaleString();
        return new Date(time).getHours() + 1 + ":" + new Date(time).getMinutes();
    }

    /**
     * This function is used to toggle Auto complete Drop down data
     */
    toggleAutocompleteDropdown() {
        // Open the autocomplete dropdown
        if (this.autocompleteTrigger) {
            this.autocompleteTrigger.openPanel();
        }

    }
}
