<div class="notifications-panel main_setting_div" fusePerfectScrollbar
    id="app_preferences">
    <div class="header" fxLayoutAlign="flex-end">
        <button mat-icon-button class="close-button"
            (click)="toggleSidebarOpen('notifications')">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="header">
        <span class="title">Notifications</span>
    </div>
    <mat-divider cdkFocusRegionEnd></mat-divider>

    <!-- <div class="one" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row" style="width: 100%;">
        <div class="popup_body document_generated_main_div" style="width: 100%;">
            <div style="position: relative;z-index: 99999;width: 100%;">
            
                <h3><strong>{{title}}</strong> is being generated, please give it a moment. </h3><br>
                <mat-progress-bar style="height: 2em;" mode="determinate" [value]="progress"></mat-progress-bar>
                <span style="position: absolute; top: 2.2em;" [ngStyle]="{'left': progress + '%'}"></span>
            </div>
        </div> -->
        <!-- <ng-container *ngIf="data">
            <div *ngIf="data.REPORTID !== 'TrustAllTrustAccountStatements'  && data.REPORTID !== 'TrustMatterLedgers'" >
                <div *ngFor="let item1 of singledataarray; let i = index" >
                    <div class="popup_body document_generated_main_div" *ngIf="isPDFGenerated" style="width: 100%;">
                        <div fxLayout="row" class="document_generated_main_first" fxLayoutAlign="start center"
                            fxFlex="1 0 auto">
                            <div class="middle" *ngIf="item1.SHORTNAME">The {{item1.SHORTNAME}} report has been produced.</div>
                            <div class="middle" *ngIf="!item1.SHORTNAME">The {{title}} report has been produced.</div>
                        </div>
                        <div fxLayout="row" class="document_generated_main_last" fxLayoutAlign="start center"
                            fxFlex="1 0 auto" class="button">
                            <a href="{{this.base_url}}{{finalReportData?.PDFFILENAME}}"  target="_blank">
                                <button  mat-raised-button  color="accent" class="mr-4">Click here to view PDF file</button>
                            </a>
                            <button mat-raised-button  color="accent" (click)="ToEmailthedocument()"
                                *ngIf="finalReportData?.DOCUMENTGUID && finalReportData?.MATTERGUID && accountClass == 'Trust Matter Ledger'">
                                Click here to email it</button>
    
                                <a *ngIf="finalReportData?.CSVFILENAME !=''" href="{{this.base_url}}{{finalReportData?.CSVFILENAME}}" target="_blank">
                                    <button   mat-raised-button color="accent"  > Click here to view CSV file</button>
                                </a>
                            
                                <a href="{{this.base_url}}{{finalReportData?.PDFFILENAME}}" target="_blank">
                                    <button  *ngIf="singledataarray==''" mat-raised-button  color="accent" class="mr-4">Click here to view
                                        it</button>
                                </a>
                                <button  mat-raised-button  color="accent" (click)="ToEmailthedocument()"
                                    *ngIf=" singledataarray=='' && finalReportData?.DOCUMENTGUID && finalReportData?.MATTERGUID && accountClass == 'Trust Matter Ledger'">
                                    Click here to email it</button>
                        </div>
                    </div>
                    <ng-container *ngIf="showwarningdiv=='true'" style="margin-top: 4px">
                        <div style=" margin-top: 20px;font-size: 15px;font-weight: 900;">
                            {{item1.MESSAGE}}
                        </div>
                        <div *ngIf ="matteridarray.length != 0">
                        <div  style="font-weight:bold;margin-top: 2px" *ngFor="let data of matteridarray; let i = index">
                            Matter Number is- {{data}}
                        </div>
                    </div>
                    </ng-container>
                </div>
       
            </div>
        </ng-container> -->
    <!-- </div> -->

    <mat-card class="example-card notification-card mt-12"
        *ngFor="let notification of notificationList;">
        <mat-card-title class="font-weight-700">
            <mat-icon class="float-right">close</mat-icon>
            <span class="title">{{notification.HEADING}}</span>
        </mat-card-title>
        <mat-card-content>
            <div class="content mb-12">{{notification.DESCRIPTION}} </div>
            <mat-chip-list>
                <mat-chip>
                    <mat-icon class="content-icon">access_time</mat-icon>
                    {{notification.DATEANDTIME}}</mat-chip>
            </mat-chip-list>
        </mat-card-content>
    </mat-card>
</div>
