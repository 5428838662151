import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fuseAnimations } from 'src/@fuse/animations';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { MainAPiServiceService } from '@_services/main-api-service.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-folder-create',
  templateUrl: './folder-create.component.html',
  styleUrls: ['./folder-create.component.scss'],
  animations: fuseAnimations
})
export class FolderCreateComponent implements OnInit, OnDestroy {
  documentData: any = {};
  isLoadingResults: boolean = false;
  isspiner: boolean = false;
  titleText: any = "Create New Folder";
  folder: any;
  sub: Subscription;
  constructor(@Inject(MAT_DIALOG_DATA) public _data: any,
    private _mainAPiServiceService: MainAPiServiceService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<FolderCreateComponent>,) {
       this.folder=localStorage.getItem("Folder");
      if(this.folder){
        // As per new requirement folder starting name no need to show
       // this.documentData.NEWFOLDERNAME=this.folder+'/';
      }
  }

   /**
     * It runs once after the component's view has been fully initialized.
    */
  ngOnInit() {
    if(this._data.Title !== undefined){
      this.titleText=this._data.Title;
    }

    if (this._data.type == "update") {
      if(this.folder){
     // this.documentData.FOLDERNAME = this.folder+'/'+this._data.temlateData.TEMPLATENAME;
     this.documentData.FOLDERNAME =this._data.temlateData.TEMPLATENAME;
      this.documentData.NEWFOLDERNAME=this.folder+'/';
      }else{
        this.documentData.FOLDERNAME =this._data.temlateData.TEMPLATENAME;
      this.documentData.NEWFOLDERNAME='';
      }
    }
  }


  /**
   * THis function ismused to destroy the session
   */
  ngOnDestroy(): void {
      this.sub?.unsubscribe();
  }

  /**
   * This function is used to save the folder
   */
  saveFolder() {
    this.isspiner = true;
    let postData: any;
    if (this._data.type == "update") {
      postData = { FormAction: 'update', Data: { NEWFOLDERNAME: this.documentData.NEWFOLDERNAME, FOLDERNAME: this.documentData.FOLDERNAME } };
    } else {
     // postData = { FormAction: 'insert', Data: { NEWFOLDERNAME: this.documentData.NEWFOLDERNAME } };
     postData = { FormAction: 'insert', Data: { NEWFOLDERNAME:(this.folder && this.folder !==null) ? this.folder+'/'+this.documentData.NEWFOLDERNAME :this.documentData.NEWFOLDERNAME  } };
    }
    this.sub = this._mainAPiServiceService.getSetData(postData, 'TemplateFolder').subscribe(response => {
      if (response.CODE == 200 && response.STATUS == "success") {
        this.toastr.success("Folder created successfully");
        this.dialogRef.close(true);
      } else if (response.CODE == 432 || response.CODE == '432') {
        let currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
        // window.open(environment.APIEndpoint + 'OAuth2Start?AuthType=NetDrive&SessionToken=' + currentUser.SessionToken, 'targetWindow',
        // `toolbar=no,
        //  location=no,
        //  status=no,
        //  menubar=no,
        //  scrollbars=yes,
        //  resizable=yes,
        //  width=800,
        //  height=600`);
        let payload = {
          "Action": "OAuth2Start",
          "VALIDATEONLY": false,
          "Data": {
              "USERGUID": currentUser.UserGuid,
              "AuthType": "NetDrive"
          }
        }
        this._mainAPiServiceService
        .getSetData(payload, 'oauth-connections')
        .subscribe((response) => {
            if(response.CODE == 200 && response.STATUS == "success") {
                window.open(
                    response.DATA[0].AuthorizationUrl,
                    "targetWindow",
                    `toolbar=no,
                    location=no,
                    status=no,
                    menubar=no,
                    scrollbars=yes,
                    resizable=yes,
                    width=800,
                    height=600`
                );
            }
        });
      }
      this.isspiner = false;
    }, error => {
      this.isspiner = false;
      this.toastr.error(error);
    });
  }
}
