import { Injectable } from '@angular/core';
// import { InMemoryDbService } from 'angular-in-memory-web-api';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class SortingBehaviourService {

    public matterScreenSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public allowancesScreenSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public ChequeScreenSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public contactScreenSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public timeEntryScreenSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public invoiceScreenSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public spendScreenSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public BatchPaymentScreenSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public ReciveMoneyScreenSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public safeCustodyScreenSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public TrustMoneyScreenSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public ProtectedTrustScreenSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public TrustEndOfMOnthScreenSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public TrustTODOScreenSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public userScreenSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public auditLogsScreenSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public EstimateScreenSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public DeliverableScreenSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public MatterInvoiceScreenSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public MaterTrustScreenSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public receiptCraditsScreenSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public WIPScreenSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public CIScreenSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public templateSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public EmailSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public TaskScreenSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public accountSystemSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public searchingSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public chronologySorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public fileNoteSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public legalTtaskSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public legalSafeCustodySorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public legalSearchSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public generalJuronalSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public documentRegisterSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public activitySorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public accountRecouncileSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public bankAccountSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public bankAccountSpentSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public pastRecouncileSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public pastBankingSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public conflictCheckSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public createDiarySorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public transactionsSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public matterAppointmentSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public AssetsScreenSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public LiabilityScreenSorting$: BehaviorSubject<any> = new BehaviorSubject(null);
    public BeneficiaryScreenSorting$: BehaviorSubject<any> = new BehaviorSubject(null);



    //other data
    public inboxData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public inboxMenu$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('last_inbox')));

    public WorkInprogressFilterData$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('Work_in_progress_filter')));
    public ActivityFilterData$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('activity_filter')));
    public contactFilterData$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('contact_Filter')));
    public invoiceFilterData$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('matter_invoice_filter')));
    public timeEntryFilterData$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('Time_entry_Filter')));
    public MatterFilterData$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('Matterscreen_Data_Filter')));
    public spendMoneyFilterData$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('Spend_money_Filter')));
    public reciveMoneyFilterData$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('Recive_money_Filter')));
    public mainTaskFilterData$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('Main_Task_Filter')));
    public MainSearchingFilterData$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('Main_Search_Filter')));
    public MainSafeCustodyFilterData$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('Main_SafeCustody_Filter')));
    public TrustMoneyFilterData$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('Trust_Money_Filter')));
    public UserFilterData$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('User_Data_Filter')));
    public TrustChartAccFilterData$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('TrustChartAcc_Data_Filter')));
    public ChartAccFilterData$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('ChartAcc_Data_Filter')));
    public TrustRecounItemFilterData$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('TrustRecounItem_Filter')));
    public NoTrustRecounItemFilterData$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('No_TrustRecounItem_Filter')));

    ///////////// Data Filtering

    public PastBankingFilterData$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('Pastbanking_Filter')));
    public TransactionFilterData$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('Transactions_Filter')));
    public GeneralJournalFilterData$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('GeneralJournals_Filter')));
    public DocumentRegisterFilterData$: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('DocumentRegister_Filter')));



    constructor(private httpClient: HttpClient) { }


    /**
     * This function is used to sort the transaction table data
     */
    transactionsSorting(Sorting: any) {
        localStorage.setItem('transaction_screen_sorting', JSON.stringify(Sorting));
        this.transactionsSorting$.next(Sorting);
    }

    /**
     * This function is used to sort the create diary component
     */
    createDiarySorting(createDiarySorting: any) {
        localStorage.setItem('create_diary_screen_sorting', JSON.stringify(createDiarySorting));
        this.createDiarySorting$.next(createDiarySorting);
    }

    /**
     * This function is used to sort the conflict check table
     */
    conflictCheckSorting(conflictCheckSorting: any) {
        localStorage.setItem('conflick_check_screen_sorting', JSON.stringify(conflictCheckSorting));
        this.conflictCheckSorting$.next(conflictCheckSorting);
    }

    /**
     * This function is used to sort the past banking table
     */
    pastBankingSorting(pastBankingSorting: any) {
        localStorage.setItem('past_banking_screen_sorting', JSON.stringify(pastBankingSorting));
        this.pastBankingSorting$.next(pastBankingSorting);
    }

    /**
     * This function is used to past Recouncile sorting the data value
     */
    pastRecouncileSorting(pastRecouncileSorting: any) {
        localStorage.setItem('past_recounclie_screen_sorting', JSON.stringify(pastRecouncileSorting));
        this.pastRecouncileSorting$.next(pastRecouncileSorting);
    }

    /**
     * This function is used to sort the account Recouncile Window
     */
    accountRecouncileSorting(accountRecouncileSorting: any) {
        localStorage.setItem('account_reconcile_screen_sorting', JSON.stringify(accountRecouncileSorting));
        this.accountRecouncileSorting$.next(accountRecouncileSorting);
    }

    /**
     * This function is used to sort the bank account table
     */
    bankAccountSorting(accountRecouncileSorting: any) {
        localStorage.setItem('bank_account_screen_sorting', JSON.stringify(accountRecouncileSorting));
        this.bankAccountSorting$.next(accountRecouncileSorting);
    }

    /**
     * This function is used to sort the spen bank account table
     */
    bankAccountSpentSorting(accountRecouncileSorting: any) {
        localStorage.setItem('bank_account_spent_screen_sorting', JSON.stringify(accountRecouncileSorting));
        this.bankAccountSpentSorting$.next(accountRecouncileSorting);
    }

    /**
     * This function is used to sort the activity table
     */
    activitySorting(activitySorting: any) {
        localStorage.setItem('activity_screen_sorting', JSON.stringify(activitySorting));
        this.activitySorting$.next(activitySorting);
    }

    /**
     * This function is used to sort the Document Register table
     */
    documentRegisterSorting(documentRegisterSorting: any) {
        localStorage.setItem('documnet_register_screen_sorting', JSON.stringify(documentRegisterSorting));
        this.documentRegisterSorting$.next(documentRegisterSorting);
    }

    /**
     * This function is used to sort the general journal sorting
     */
    generalJuronalSorting(generalJuronalSorting: any) {
        localStorage.setItem('general_juronal_screen_sorting', JSON.stringify(generalJuronalSorting));
        this.generalJuronalSorting$.next(generalJuronalSorting);
    }

    /**
     * This function is use to sort the legal Search table 
     */
    legalSearchSorting(legalSearchSorting: any) {
        localStorage.setItem('legal_search_screen_sorting', JSON.stringify(legalSearchSorting));
        this.legalSearchSorting$.next(legalSearchSorting);
    }

    /**
     * This function is used to sort the legal safe custody table.
     */
    legalSafeCustodySorting(legalSafeCustodySorting: any) {
        localStorage.setItem('legal_safe_custody_screen_sorting', JSON.stringify(legalSafeCustodySorting));
        this.legalSafeCustodySorting$.next(legalSafeCustodySorting);
    }

    /**
     * This function is used to sort the legal Task table
     */
    legalTtaskSorting(legalTtaskSorting: any) {
        localStorage.setItem('legal_task_screen_sorting', JSON.stringify(legalTtaskSorting));
        this.legalTtaskSorting$.next(legalTtaskSorting);
    }

    /**
     * This function is used to sort the File note table
     */
    fileNoteSorting(fileNoteSorting: any) {
        localStorage.setItem('file_note_screen_sorting', JSON.stringify(fileNoteSorting));
        this.fileNoteSorting$.next(fileNoteSorting);
    }

    /**
     * This function is used to sort the chronology table
     */
    chronologySorting(chronologySorting: any) {
        localStorage.setItem('chronology_screen_sorting', JSON.stringify(chronologySorting));
        this.chronologySorting$.next(chronologySorting);
    }

    /**
     * This function is used to sort the searching table data value.
     */
    searchingSorting(searchingSorting: any) {
        localStorage.setItem('search_screen_sorting', JSON.stringify(searchingSorting));
        this.searchingSorting$.next(searchingSorting);
    }

    /**
     * This function is used to sort the account system table
     */
    accountSystemSorting(accountSystemSorting: any) {
        localStorage.setItem('account_system_screen_sorting', JSON.stringify(accountSystemSorting));
        this.accountSystemSorting$.next(accountSystemSorting);
    }

    /**
     * This function is used to sort the TaskScreen sorting
     */
    TaskScreenSorting(TaskScreenSorting: any) {
        localStorage.setItem('task_screen_sorting', JSON.stringify(TaskScreenSorting));
        this.TaskScreenSorting$.next(TaskScreenSorting);
    }

    /**
     * This function is used to sort the Email table
     */
    EmailSorting(EmailSorting: any) {
        localStorage.setItem('email_screen_sorting', JSON.stringify(EmailSorting));
        this.EmailSorting$.next(EmailSorting);
    }

    /**
     * This function is used to sort the template table
     */
    templateSorting(templateSorting: any) {
        localStorage.setItem('template_screen_sorting', JSON.stringify(templateSorting));
        this.templateSorting$.next(templateSorting);
    }

    /**
     * This function is used to sort the WIP screen
     */
    WIPScreenSorting(WIPScreenSorting: any) {
        localStorage.setItem('work_i_p_screen_sorting', JSON.stringify(WIPScreenSorting));
        this.WIPScreenSorting$.next(WIPScreenSorting);
    }

    /**
     * This function is used to sort the CIS table 
     */
    CIScreenSorting(WIPScreenSortingdata: any) {
        localStorage.setItem('Create_Invoice_screen_sorting', JSON.stringify(WIPScreenSortingdata));
        this.CIScreenSorting$.next(WIPScreenSortingdata);
    }

    /**
     * This function is used to sort the receipt credit table 
     */
    receiptCraditsScreenSorting(receiptCraditsScreenSorting: any) {
        localStorage.setItem('receipt_credits_screen_sorting', JSON.stringify(receiptCraditsScreenSorting));
        this.receiptCraditsScreenSorting$.next(receiptCraditsScreenSorting);
    }

    /**
     * This function is used to sort the MaterTrust screen table
     */
    MaterTrustScreenSorting(MaterTrustScreenSorting: any) {
        localStorage.setItem('matter_trust_screen_sorting', JSON.stringify(MaterTrustScreenSorting));
        this.MaterTrustScreenSorting$.next(MaterTrustScreenSorting);
    }

    /**
     * This function is used to sort the Matter Invoice screen 
     */
    MatterInvoiceScreenSorting(MatterInvoiceScreenSorting: any) {
        localStorage.setItem('matter_invoice_screen_sorting', JSON.stringify(MatterInvoiceScreenSorting));
        this.MatterInvoiceScreenSorting$.next(MatterInvoiceScreenSorting);
    }

    /**
     * This function is used to Estimate Screen Sorting
     */
    EstimateScreenSorting(EstimateScreenSorting: any) {
        localStorage.setItem('estimate_screen_sorting', JSON.stringify(EstimateScreenSorting));
        this.EstimateScreenSorting$.next(EstimateScreenSorting);
    }

    /**
     * This function is used to Diliverable Screen Sorting
     */
    DeliverableScreenSorting(DeliverableScreenSorting: any) {
        localStorage.setItem('Deliverable_screen_sorting', JSON.stringify(DeliverableScreenSorting));
        this.DeliverableScreenSorting$.next(DeliverableScreenSorting);
    }

    /**
     * This function is used to sort the mattr screen data value
     */
    matterScreenSorting(matterScreenSorting: any) {
        localStorage.setItem('matter_screen_sorting', JSON.stringify(matterScreenSorting))
        this.matterScreenSorting$.next(matterScreenSorting);
    }

    /**
     * This function is used to sort the allowances screen sorting
     */
    allowancesScreenSorting(allowancesScreenSorting: any) {
        localStorage.setItem('allowance_screen_sorting', JSON.stringify(allowancesScreenSorting));
        this.allowancesScreenSorting$.next(allowancesScreenSorting);
    }

    /**
     * This function is used to ChequeScreen Sorting
     */
    ChequeScreenSorting(ChequeScreenSorting: any) {
        localStorage.setItem('cheque_screen_sorting', JSON.stringify(ChequeScreenSorting));
        this.ChequeScreenSorting$.next(ChequeScreenSorting);
    }

    /**
     * This function is used to sort the contact screen table
     */
    contactScreenSorting(contactScreenSorting: any) {
        localStorage.setItem('contact_screen_sorting', JSON.stringify(contactScreenSorting));
        this.contactScreenSorting$.next(contactScreenSorting);
    }

    /**
     * This function is used to sort the time entry screen table
     */
    timeEntryScreenSorting(timeEntryScreenSorting: any) {
        localStorage.setItem('time_entry_screen_sorting', JSON.stringify(timeEntryScreenSorting));
        this.timeEntryScreenSorting$.next(timeEntryScreenSorting);
    }

    /**
     * This function is used to sort the Invoice Screen table
     */
    invoiceScreenSorting(invoiceScreenSorting: any) {
        localStorage.setItem('invoice_screen_sorting', JSON.stringify(invoiceScreenSorting));
        this.invoiceScreenSorting$.next(invoiceScreenSorting);
    }

    /**
     * This function is used to sort the spend screen table.
     */
    spendScreenSorting(spendScreenSorting: any) {
        localStorage.setItem('spend_money_screen_sorting', JSON.stringify(spendScreenSorting));
        this.spendScreenSorting$.next(spendScreenSorting);
    }

    BatchPaymentScreenSorting(data){
        localStorage.setItem('Batch_payment_screen_sorting', JSON.stringify(data));
        this.BatchPaymentScreenSorting$.next(data); 
    }

    /**
     * This function is used to sort the Receive money Screen data value.
     */
    ReciveMoneyScreenSorting(ReciveMoneyScreenSorting: any) {
        localStorage.setItem('receive_money_screen_sorting', JSON.stringify(ReciveMoneyScreenSorting));
        this.ReciveMoneyScreenSorting$.next(ReciveMoneyScreenSorting);
    }

    /**
     * This function is used to sort the safecustody screen table
     */
    safeCustodyScreenSorting(safeCustodyScreenSorting: any) {
        localStorage.setItem('safe_custody_screen_sorting', JSON.stringify(safeCustodyScreenSorting));
        this.safeCustodyScreenSorting$.next(safeCustodyScreenSorting);
    }

    /**
     * This function is used to sort the trust money Screen table
     */
    TrustMoneyScreenSorting(TrustMoneyScreenSorting: any) {
        this.TrustMoneyScreenSorting$.next(TrustMoneyScreenSorting);
    }

    /**
     * This function is used to sort the protected-Trust Screen table
     */
    ProtectedTrustScreenSorting(ProtectedTrustScreenSorting: any) {
        this.ProtectedTrustScreenSorting$.next(ProtectedTrustScreenSorting);
    }

    /**
     * This function is used to sort the Trust-endof-Month Screen table
     */
    TrustEndOfMOnthScreenSorting(TrustEndOfMOnthScreenSorting: any) {
        localStorage.setItem('trust_money_screen_sorting', JSON.stringify(TrustEndOfMOnthScreenSorting));
        this.TrustEndOfMOnthScreenSorting$.next(TrustEndOfMOnthScreenSorting);
    }

    /**
     * This function is used to sort the Trust TODOS Screen table
     */
    TrustTODOScreenSorting(TrustTODOScreenSorting: any) {
        localStorage.setItem('trust_todos_screen_sorting', JSON.stringify(TrustTODOScreenSorting));
        this.TrustTODOScreenSorting$.next(TrustTODOScreenSorting);
    }

     /**
     * This function is used to sort the users Screen table
     */
    userScreenSorting(userScreenSorting: any) {
        localStorage.setItem('user_screen_sorting', JSON.stringify(userScreenSorting));
        this.userScreenSorting$.next(userScreenSorting);
    }

    /**
     * This function is used to sort the auditLogs Screen table
     */
    auditLogsScreenSorting(auditLogsScreenSorting: any) {
        localStorage.setItem('audit_logs_screen_sorting', JSON.stringify(auditLogsScreenSorting));
        this.auditLogsScreenSorting$.next(auditLogsScreenSorting);
    }

     /**
     * This function is used to sort the  matter-Appointment Screen table
     */
    matterAppointmentSorting(matterAppointmentSorting: any) {
        localStorage.setItem('matter_appointment_screen_sorting', JSON.stringify(matterAppointmentSorting));
        this.matterAppointmentSorting$.next(matterAppointmentSorting);
    }

     /**
     * This function is used to sort the  Assets Screen table
     */
    AssetsScreenSorting(AssetsScreenSorting: any) {
        localStorage.setItem('assets_screen_sorting', JSON.stringify(AssetsScreenSorting));
        this.AssetsScreenSorting$.next(AssetsScreenSorting);
    }

    /**
     * This function is used to sort the  Liability Screen table
     */
    LiabilityScreenSorting(LiabilityScreenSorting: any) {
        localStorage.setItem('liability_screen_sorting', JSON.stringify(LiabilityScreenSorting));
        this.LiabilityScreenSorting$.next(LiabilityScreenSorting);
    }

    /**
     * This function is used to sort the  Beneficiary Screen table
     */
    BeneficiaryScreenSorting(BeneficiaryScreenSorting: any) {
        localStorage.setItem('beneficiary_screen_sorting', JSON.stringify(BeneficiaryScreenSorting));
        this.BeneficiaryScreenSorting$.next(BeneficiaryScreenSorting);
    }
   
    /**
     * This function is used to set Inbox Data
     */
    setInboxData(inboxData: any) {
        this.inboxData$.next(inboxData);
    }

     /**
     * This function is used to set Inbox Menu
     */
    setInboxMenu(inboxItem: any) {
        localStorage.setItem('last_inbox', JSON.stringify(inboxItem));
        this.inboxMenu$.next(inboxItem);
    }

    /**
     * This function is used to set WorkInprogress Filter Data
     */
    setWorkInprogressFilterData(WIPFilter: any) {
        localStorage.setItem('Work_in_progress_filter', JSON.stringify(WIPFilter));
        this.WorkInprogressFilterData$.next(WIPFilter);
    }

    /**
     * This function is used to set Activity Filter Data
     */
    setActivityFilterData(Filter: any) {
        localStorage.setItem('activity_filter', JSON.stringify(Filter));
        this.ActivityFilterData$.next(Filter);
    }

    /**
     * This function is used to set Contact Filter Data
     */
    setContactFilterData(Filter: any) {
        localStorage.setItem('contact_Filter', JSON.stringify(Filter));
        this.contactFilterData$.next(Filter);
    }

    /**
     * This function is used to set Invoice Filter Data
     */
    setInvoiceFilterData(Filter: any) {
        localStorage.setItem('matter_invoice_filter', JSON.stringify(Filter));
        this.invoiceFilterData$.next(Filter);
    }

    /**
     * This function is used to Set timeEntry Filter Data
     */
    SettimeEntryFilterData(Filter: any) {
        localStorage.setItem('Time_entry_Filter', JSON.stringify(Filter));
        this.timeEntryFilterData$.next(Filter);
    }

     /**
     * This function is used to Set Spend Money Filter Data
     */
    SetSpendMoneyFilterData(Filter: any) {
        localStorage.setItem('Spend_money_Filter', JSON.stringify(Filter));
        this.spendMoneyFilterData$.next(Filter);
    }

     /**
     * This function is used to Set recive Money Filter Data
     */
    SetreciveMoneyFilterData(Filter: any) {
        localStorage.setItem('Recive_money_Filter', JSON.stringify(Filter));
        this.reciveMoneyFilterData$.next(Filter);
    }

     /**
     * This function is used to Set Main Task Filter Data
     */
    SetMainTaskFilterData(Filter: any) {
        localStorage.setItem('Main_Task_Filter', JSON.stringify(Filter));
        this.mainTaskFilterData$.next(Filter);
    }

     /**
     * This function is used to Set Main Searching Filter Data
     */
    SetMainSearchingFilterData(Filter: any) {
        localStorage.setItem('Main_Search_Filter', JSON.stringify(Filter));
        this.MainSearchingFilterData$.next(Filter);
    }

    /**
     * This function is used to Set MainSafeCustody Filter Data
     */
    SetMainSafeCustodyFilterData(Filter: any) {
        localStorage.setItem('Main_SafeCustody_Filter', JSON.stringify(Filter));
        this.MainSafeCustodyFilterData$.next(Filter);
    }

    /**
     * This function is used to Set TrustMoney Filter Data
     */
    SetTrustMoneyFilterData(Filter: any) {
        localStorage.setItem('Trust_Money_Filter', JSON.stringify(Filter));
        this.TrustMoneyFilterData$.next(Filter);
    }

    /**
     * This function is used to Set User Filter Data
     */
    SetUserFilterData(Filter: any) {
        localStorage.setItem('User_Data_Filter', JSON.stringify(Filter));
        this.UserFilterData$.next(Filter);
    }

    /**
     * This function is used to Set TrustChartAccount Filter Data
     */
    SetTrustChartAccFilterData(Filter: any) {
        localStorage.setItem('TrustChartAcc_Data_Filter', JSON.stringify(Filter));
        this.TrustChartAccFilterData$.next(Filter);
    }

    /**
     * This function is used to Set ChartAccount Filter Data
     */
    SetChartAccFilterData(Filter: any) {
        localStorage.setItem('ChartAcc_Data_Filter', JSON.stringify(Filter));
        this.ChartAccFilterData$.next(Filter);
    }

    /**
     * This function is used to Set Matter Filter Data
     */
    SetMatterFilterData(Filter: any) {
        localStorage.setItem('Matterscreen_Data_Filter', JSON.stringify(Filter));
        this.MatterFilterData$.next(Filter);
    }

    /**
     * This function is used to Set TrustRecounItem Filter Data
     */
    SetTrustRecounItemFilterData(Filter: any) {
        localStorage.setItem('TrustRecounItem_Filter', JSON.stringify(Filter));
        this.TrustRecounItemFilterData$.next(Filter);
    }

    /**
     * This function is used to Set NoTrustRecounItem Filter Data
     */
    SetNoTrustRecounItemFilterData(Filter: any) {
        localStorage.setItem('No_TrustRecounItem_Filter', JSON.stringify(Filter));
        this.NoTrustRecounItemFilterData$.next(Filter);
    }

    /**
     * This function is used to Set PastBanking Filter Data
     */
    SetPastBankingFilterData(Filter: any) {
        localStorage.setItem('Pastbanking_Filter', JSON.stringify(Filter));
        this.PastBankingFilterData$.next(Filter);
    }

    /**
     * This function is used to Set Transaction Filter Data
     */
    SetTransactionFilterData(Filter: any) {
        localStorage.setItem('Transactions_Filter', JSON.stringify(Filter));
        this.TransactionFilterData$.next(Filter);
    }

    /**
     * This function is used to Set GeneralJournal Filter Data
     */
    SetGeneralJournalFilterData(Filter: any) {
        localStorage.setItem('GeneralJournals_Filter', JSON.stringify(Filter));
        this.GeneralJournalFilterData$.next(Filter);
    }

    /**
     * This function is used to Set DocumentRegister Filter Data
     */
    SetDocumentRegisterFilterData(Filter: any) {
        localStorage.setItem('DocumentRegister_Filter', JSON.stringify(Filter));
        this.DocumentRegisterFilterData$.next(Filter);
    }



}
