import { BehaviorService } from '@_services/Behavior.service';
import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-bulk-progress',
  templateUrl: './bulk-progress.component.html',
  styleUrls: ['./bulk-progress.component.scss']
})
export class BulkProgressComponent implements OnInit {
  public DialogTitle: string = "";
  private _unsubscribeAll$: Subject<void> = new Subject();
  countdata: any;
  constructor(private bh:BehaviorService) {
   bh.updateBulkdeleteAPICount$.pipe(takeUntil(this._unsubscribeAll$)).subscribe(data=>{
    if(data && data !=null){
      console.log(data.data);
      this.countdata=data.data;
    }
   })

   }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this._unsubscribeAll$.next();
    this._unsubscribeAll$.complete();
}
}
