import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnInit,
    Renderer2,
    ViewChild,
} from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { ResizeEvent } from "angular-resizable-element";
import moment from "moment";
import { ToastrService } from "ngx-toastr";
import { fuseAnimations } from "src/@fuse/animations";
import { SortingDialogComponent } from "src/app/main/sorting-dialog/sorting-dialog.component";
import * as $ from "jquery";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { FilterSearchOptions } from "src/app/_constant/dynamic-search-option.const";
import { HyperlinkNavigationService } from "@_services/hyperlink-navigation.service";
import { TableColumnsService } from "@_services/table-columns.service";
import { MainAPiServiceService } from "@_services/main-api-service.service";
import { BehaviorService } from "@_services/Behavior.service";
import { SortingBehaviourService } from "@_services/sorting-behaviour.service";
import { map } from "rxjs";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";

@Component({
    selector: "app-protected-trust",
    templateUrl: "./protected-trust.component.html",
    styleUrls: ["./protected-trust.component.scss"],
    animations: fuseAnimations,
})
export class ProtectedTrustComponent implements OnInit, AfterViewInit {
    @ViewChild(MatTable, { read: ElementRef }) private matTableRef: ElementRef;
    isLoadingResults: boolean = false;
    isDisplay: boolean = false;
    hyperLinkInfo: any = {};
    Object: any = {};
    displayedColumns: any;
    tempColobj: any;
    ColumnsObj = [];
    dateColFilter = [];
    sortingDefaultState: any = {};
    currentUserData = JSON.parse(window.localStorage.getItem("currentUser"));
    pageSize: any;
    ProtectedTrustdata: any = [];
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    detailHeight: any;
    sortactive: any;
    sortDirection: any;
    highlightedRows: any;
    selectedColore: string = "rgb(217, 217, 217)";
    isGetallData: any = false;
    isCallDownloadFunction: boolean = false;
    IsTrustMoneyList: boolean = false;
    ProtectedTrustForm: FormGroup;
    FILTERS: FormArray;
    FILTERSLIST: FormArray;
    showFiltersList: boolean = true;
    optionalFilterList = [];
    saveAdvanceFilter = [];
    _filterList = [];
    optionalFilters = [];
    optionalDateFilterPayload = [];
    selectedFilterIs: any;
    activeOptionalFilter: any;
    addFilter;
    activeFilter = FilterSearchOptions.activeFilter;
    unbuildWorkFilter = FilterSearchOptions.unbuildWorkFilter;
    dateFilter = FilterSearchOptions.dateFilter;
    currencyFilter = FilterSearchOptions.currencyFilter;
    manullyOptionalFilter = FilterSearchOptions.manuallyOptionalFilter;
    finalPayloaddata: any;
    potData;
    COLOR: any;
    currentMatter: any
    public COLOR$=this.behaviorService.ColorSystemSetting$.asObservable().pipe(map(data=>this.COLOR=data));
    HyperlinkColor: any;
    public HyperlinkColor$=this.behaviorService.ColorSystemSettingHyperLink$.asObservable().pipe(map(data=>this.HyperlinkColor = data));
    public SORTING$=this.SortingbehaviorService.ProtectedTrustScreenSorting$.asObservable().pipe(map(result=>{
        if (result) {
                        this.sortingDefaultState = result;
                        localStorage.setItem(
                            "Protected_trust_screen_sorting",
                            JSON.stringify(result)
                        );
                    } else {
                        this.sortingDefaultState = JSON.parse(
                            localStorage.getItem("Protected_trust_screen_sorting")
                        );
                    }
    }))

                 /**
                 *  Subscribes to the clickMaterFromTimeLine$ observable provided by behaviorService.
                 * @remarks
                 * It is particularly relevant for scenarios where the behaviorService controls the "open in new tab"
                 * feature(on or off), and the selection of matterGuid is influenced by whether multiple tabs are open.
                 */
                 public SELECTEDMATTER$= this.behaviorService.clickMaterFromTimeLine$.pipe(map(result => {
                 if (result) {
                   this.selectedMatter = result;
                   if(result && result !=null && result != undefined){
                    this.LoadData();
                   }
                 }   
           })
       )

       public loaddata$=this.behaviorService.CallProtectedTrustApi$.asObservable().pipe(map(data=>(data && data==true && this.selectedMatter && this.selectedMatter !=null && this.selectedMatter != undefined) ? this.LoadData() : ''))
    resizableMousemove: () => void;
    resizableMouseup: () => void;
    selectedMatter:any
    constructor(
        private _hyperlink: HyperlinkNavigationService,
        private TableColumnsService: TableColumnsService,
        private _router: Router,
        private _mainAPiServiceService: MainAPiServiceService,
        private toastr: ToastrService,
        private behaviorService: BehaviorService,
        private SortingbehaviorService: SortingBehaviourService,
        private renderer: Renderer2,
        private dialog: MatDialog,
        private _formBuilder: FormBuilder,
        private cd: ChangeDetectorRef,
        private titleService: Title
    ) {
        this.Object = Object;
        if (!localStorage.getItem("Protected_trust_list_columns")) {
            this.getTableFilter();
            setTimeout(() => {
                this.getFilter();
            }, 1000);
        }
               
        this.addFilter = {
            OPERATION: "",
            VALUE: "",
            VALUE2: "",
            FILTERTYPE: "",
        };

        this._hyperlink.setColorSetting();
        this._hyperlink.setColorSettingWithHyperlink();
        this.behaviorService.clickMaterFromTimeLine$.subscribe(result => {
            this.currentMatter = result
            // let headerTitle = `Matters & Details (${this.currentMatter.SHORTNAME}) - ${this.activatedRoute.snapshot.routeConfig.path}`
            // let headerTitle = (result && result !=undefined) ? result : this.currentMatter
            // this.titleService.setTitle((this.currentMatter?.USEV11UI == true) ? headerTitle + '-' + 'Trust' : 'Time & Billing (' + headerTitle + ') - Matter Trust');
            // this.titleService.setTitle( headerTitle.SHORTNAME + '-' + 'Protected Trust');
          //this.titleService.setTitle(headerTitle.CONTACTNAME + '-' + 'Protected Trust');
            let headerTitle = result ? result.SHORTNAME : this.currentMatter.SHORTNAME;
            this.titleService.setTitle( headerTitle + '-' + 'Protected Trust');

        })
    }

     /**
     * It runs once after the component's view has been fully initialized.
    */
    ngOnInit(): void {
        let windowHeight = $(window).height();
        let toolBaarMain = $("#tool_baar_main").height();
        let serchHeight = $(".sticky_search_div").height();
        this.detailHeight =
            windowHeight - (toolBaarMain + serchHeight + 52.5) + "px";
        if (this.sortingDefaultState) {
            this.sortactive = this.sortingDefaultState.active;
            this.sortDirection = this.sortingDefaultState.direction;
        } else {
            this.sortactive = "DATEENTERED";
            this.sortDirection = "desc";
        }

        this.behaviorService.resizeTableForAllView();
        $(window).resize(() => {
            this.behaviorService.resizeTableForAllView();
        });

        // $('.example-containerdata').css('height', ($(window).height() - ($('#filtermenu-wrap-form').height() + 295)) + 'px');
        // $(window).resize(function () {
        //   $('.example-containerdata').css('height', ($(window).height() - ($('#filtermenu-wrap-form').height() + 295)) + 'px');
        // });
        //this.LoadData();
        this.getTableFilter();
        this.getFilter();
        this.ProtectedTrustForm = this._formBuilder.group({
            FILTERS: new FormArray([]),
            FILTERSLIST: new FormArray([]),
            MAINFILTER: [""],
        });
        this.setDefaultAdvanceFilter();
    }

    /**
     * It runs only once after the component's view has been rendered.
     */
    ngAfterViewInit(): void {
        this.TableColumnsService.setTableResize(
            this.matTableRef.nativeElement.clientWidth
        );
        this.behaviorService.CallProtectedTrustApi$.next(false);
    }

    /**
     * Used to sort the data.
     * @param val -Sorting value
     */
    sortData(val):void {
        this.SortingbehaviorService.ProtectedTrustScreenSorting(val);
        this.setDefaultWidth(this.displayedColumns, 0);
    }

    /**
     * Used to Set the default width.
     * @param displayedColumns -column list
     * @param timeout - time
     */
    setDefaultWidth(displayedColumns, timeout):void {
        setTimeout(() => {
            displayedColumns.forEach((element) => {
                let temWidth = this.tempColobj[element]["WIDTH"];
                const cssValue = temWidth + "px";
                const columnElts = document.getElementsByClassName(
                    "mat-column-" + element
                );
                for (let i = 0; i < columnElts.length; i++) {
                    const currentEl = columnElts[i] as HTMLDivElement;
                    currentEl.style.visibility = "inherit";
                    currentEl.style.width = cssValue;
                }
            });
        }, timeout);
    }
    /**
     * Used to resize the table column
     * @param event -getting the current column name.
     * @param columnName -updated column name.
     */
    onResizing(event: ResizeEvent, columnName): void {
        if (event.edges.right) {
            const cssValue = event.rectangle.width + "px";
            const columnElts = document.getElementsByClassName(
                "mat-column-" + columnName
            );
            for (let i = 0; i < columnElts.length; i++) {
                const currentEl = columnElts[i] as HTMLDivElement;
                currentEl.style.width = cssValue;
            }
            const indexData = this.ColumnsObj.findIndex(
                (col) => col.COLUMNID === columnName
            );
            this.ColumnsObj[indexData]["WIDTH"] = event.rectangle.width;
            localStorage.setItem(
                "Protected_trust_list_columns",
                JSON.stringify({
                    ColumnsObj: this.ColumnsObj,
                    displayedColumns: this.displayedColumns,
                })
            );
        }
    }

    /**
     * Used to check the Resize process is stop or not.
     * @param event -event data.
     * @param columnName -updated column data
     */
    onResizeEnd(event: ResizeEvent, columnName): void {
        this.TableColumnsService.SaveWidthData(
            this.ColumnsObj,
            "trust money",
            "protected"
        );
    }

    @HostListener("window:resize", ["$event"])
    onResize(event) {
        this.TableColumnsService.setTableResize(
            this.matTableRef.nativeElement.clientWidth
        );
    }

    /**
     * To check the pagination process
     */
    onPaginateChange(event) :void{
        this.setDefaultWidth(this.displayedColumns, 0);
        this.pageSize = event.pageSize;

        localStorage.setItem(
            "PROTECTEDTRUST_lastPageSize",
            JSON.stringify(this.pageSize)
        );
    }

    /**
     * Used to set the default table filter value.
     */
    getTableFilter():void {
        let trustmoneyListColumns: any = JSON.parse(
            localStorage.getItem("Protected_trust_list_columns")
        );
        if (trustmoneyListColumns && trustmoneyListColumns != null) {
            let data = this.TableColumnsService.filtertableColum(
                trustmoneyListColumns.ColumnsObj
            );
            this.hyperLinkInfo = this.TableColumnsService.hyperLinkInfo(
                trustmoneyListColumns.ColumnsObj
            );
            this.displayedColumns = trustmoneyListColumns.displayedColumns;
            this.ColumnsObj = trustmoneyListColumns.ColumnsObj;
            this.tempColobj = data.tempColobj;
            this.dateColFilter = data.dateCol;
        } else {
            this.TableColumnsService.getTableFilter(
                "trust money",
                "protected"
            ).subscribe(
                (response) => {
                    if (response.CODE == 200 && response.STATUS == "success") {
                        let data = this.TableColumnsService.filtertableColum(
                            response.DATA.RECORDS
                        );
                        this.hyperLinkInfo =
                            this.TableColumnsService.hyperLinkInfo(
                                response.DATA.RECORDS
                            );

                        this.displayedColumns = data.showcol;
                        this.ColumnsObj = data.colobj;
                        this.tempColobj = data.tempColobj;
                        this.dateColFilter = data.dateCol;
                        localStorage.setItem(
                            "Protected_trust_list_columns",
                            JSON.stringify({
                                ColumnsObj: data.colobj,
                                displayedColumns: data.showcol,
                            })
                        );
                    }
                },
                (error) => {
                    this.toastr.error(error);
                }
            );
        }
    }

    /**
     * Used to open the Mat dialog
     */
    openDialog():void{
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = "100%";
        dialogConfig.disableClose = true;
        dialogConfig.data = {
            data: this.ColumnsObj,
            type: "trust money",
            list: "protected",
        };
        //open pop-up
        const dialogRef = this.dialog.open(
            SortingDialogComponent,
            dialogConfig
        );
        //Save button click
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.displayedColumns = result.columObj;
                this.ColumnsObj = result.columnameObj;
                this.tempColobj = result.tempColobj;
                this.dateColFilter = result.dateCol;
                localStorage.setItem(
                    "Protected_trust_list_columns",
                    JSON.stringify({
                        displayedColumns: result.columObj,
                        ColumnsObj: result.columnameObj,
                    })
                );
                if (!result.columObj) {
                    this.ProtectedTrustdata = new MatTableDataSource([]);
                    this.ProtectedTrustdata.paginator = this.paginator;
                    this.ProtectedTrustdata.sort = this.sort;
                    this.isDisplay = true;
                } else {
                    // this.filterData = JSON.parse(localStorage.getItem("trustMoney_filter"));
                    // this.LoadData(this.filterData);
                }
            }
        });
    }

    /**
     * Used to get the clicking row data.
     * @param row -get the row data
     */
    RowClick(row):void {
        let windowHeight = $(window).height();
        let toolBaarMain = $("#tool_baar_main").height();
        let serchHeight = $(".sticky_search_div").height();
        this.detailHeight =
            windowHeight - (toolBaarMain + serchHeight + 52.5) + "px";
        this.behaviorService.setProtectedTrustList(row);
    }

    /**
     * Used to get the prtected trust data.
     * @param data -updated data.
     */
    LoadData(data?): void {
        if (this._router.url == '/matter-details/protected-trust') {
            this.potData = {
                ACTION: "GetData",
                FILTERS: { MATTERGUID: this.selectedMatter?.MATTERGUID }
            };
        } else {
            this.potData = { ACTION: "GetData", FILTERS: {} };
        }
        if (data && data != null && data != undefined && data.length !== 0) {
            // this.potData = {
            //     ACTION: "GetData",
            //     AdvancedFilters: data,
            // };
            this.potData.AdvancedFilters = data;
        } else {
            // this.potData = { ACTION: "GetData" };
        }

        const advanceFilterPayload =
            this.TableColumnsService.loadAdvanceFilterPayload("ProtectedTrust");
        this.potData.AdvancedFilters = advanceFilterPayload
            ? advanceFilterPayload
            : [];

        if (
            this.potData.AdvancedFilters &&
            this.potData.AdvancedFilters.length == 0
        ) {
            delete this.potData.AdvancedFilters;
            localStorage.removeItem("ProtectedTrust_Advance_Filter");
        }

        // ends here ~ for optional filter
        const finalBasicPayload = JSON.parse(JSON.stringify(this.potData));

        delete finalBasicPayload.DateRange;
        delete finalBasicPayload.dayRange;
        this.isLoadingResults = true;
        this._mainAPiServiceService
            .getSetData(finalBasicPayload, "trust-protected")
            .subscribe(
                (res) => {
                    if (res.CODE == 200 && res.STATUS == "success") {
                        if (res.DATA.RECORDS[0]) {
                            this.isDisplay = false;
                            this.RowClick(res.DATA.RECORDS[0]);
                            this.highlightedRows =
                                res.DATA.RECORDS[0].PROTECTEDTRUSTGUID;
                            // this.behaviorService.TaskData(res.DATA.TASKS[0]);
                        } else {
                            this.isDisplay = true;
                        }
                        this.ProtectedTrustdata = new MatTableDataSource(
                            res.DATA.RECORDS
                        );
                        this.ProtectedTrustdata.sort = this.sort;
                        this.ProtectedTrustdata.paginator = this.paginator;
                        this.isLoadingResults = false;
                        this.sortingDate();
                        // if (res.DATA.TRUSTDETAILSINCOMPLETE == 1) {
                        //   this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, { disableClose: true, width: '100%', data: { trstData: true } });
                        //   this.confirmDialogRef.componentInstance.confirmMessage = 'The bank Details must be completed for the trust account before you can process any transactions';
                        //   this.confirmDialogRef.afterClosed().subscribe(result => {
                        //     if (result) {
                        //       let isTrust = true;
                        //       let ChartHandlingData = { ClickType: 'WithTrust', UseTrust: 'Yes', PopUp: '', Lable: "TRUST CHART OF ACCOUNTS", ACCOUNTTYPENAME: "Trust Account" };
                        //       this.behaviorService.TrustDuplicateModuleHandling(ChartHandlingData);
                        //       const dialogRef = this.dialog.open(ChartAcDailogComponent, { disableClose: true, panelClass: 'ChartAc-dialog', data: { action: 'edit', UseTrust: 'Yes', isDefultAccount: '', matterGuid: '' } });
                        //       dialogRef.afterClosed().subscribe(result => {
                        //       });
                        //     }
                        //     this.confirmDialogRef = null;
                        //   });
                        // }
                        this.setDefaultWidth(this.displayedColumns, 500);
                        if (this.isCallDownloadFunction == true) {
                            setTimeout(() => {
                                this.downloadFile();
                                this.isCallDownloadFunction = false;
                            }, 1000);
                        }
                    } else if (
                        res.CODE == 406 &&
                        res.MESSAGE == "Permission denied"
                    ) {
                        this.ProtectedTrustdata = new MatTableDataSource([]);
                        this.ProtectedTrustdata.paginator = this.paginator;
                        this.ProtectedTrustdata.sort = this.sort;
                        this.isLoadingResults = false;
                        this.sortingDate();
                        this.isDisplay = true;
                        this.setDefaultWidth(this.displayedColumns, 500);
                    } else if (
                        res.CODE == 417 &&
                        res.MESSAGE == "No filter was supplied"
                    ) {
                        this.ProtectedTrustdata = new MatTableDataSource(
                            res.DATA.TRUSTTRANSACTIONS
                        );
                        this.ProtectedTrustdata.paginator = this.paginator;
                        this.ProtectedTrustdata.sort = this.sort;
                        this.isLoadingResults = false;
                        this.sortingDate();
                        this.setDefaultWidth(this.displayedColumns, 500);
                        this.isDisplay = true;
                    }
                    this.isLoadingResults = false;
                },
                (err) => {
                    this.isLoadingResults = false;
                    this.toastr.error(err);
                }
            );
        this.pageSize = JSON.parse(
            localStorage.getItem("PROTECTEDTRUST_lastPageSize")
        );
        //  delete data.AdvancedFilters;
        this.SortingbehaviorService.ProtectedTrustScreenSorting(data);
    }

    /**
     * To download the data in CSV Format
     * @param data -downloaded data.
     */
    downloadFile(data?):void {
        const sortedData = this.ProtectedTrustdata.sortData(
            this.ProtectedTrustdata.filteredData,
            this.ProtectedTrustdata.sort
        );
        this.TableColumnsService.exportDataIntoCsv(
            this.displayedColumns,
            sortedData,
            "Protected_Trust_money_data_",
            this.tempColobj
        );
        // this.TableColumnsService.exportDataIntoCsv(this.displayedColumns, data, "Trust_money_data_", this.tempColobj);
    }

    /**
     * Used to sort the date
     */
    sortingDate():void {
        this.ProtectedTrustdata.sortingDataAccessor = (item, property) => {
            let FildeValue = this.dateColFilter;
            if (FildeValue.includes(property)) {
                if (item[property] && property == "DATEENTERED") {
                    let momentDate = moment(
                        moment(item[property], "DD/MM/YYYY hh:mm A")
                    ).format("YYYY-MM-DD HH:mm:ss");
                    let timestamp = Date.parse(momentDate);
                    return timestamp / 1000;
                } else if (item[property] && property != "DATEENTERED") {
                    let tempDate = item[property].split("/");
                    let Sd = new Date(
                        tempDate[1] + "/" + tempDate[0] + "/" + tempDate[2]
                    );
                    let newDate = new Date(Sd);
                    return newDate;
                }
                return item[property];
            } else {
                return item[property];
            }
        };
        // proper shorting for date
        this.ProtectedTrustdata.sort = this.sort;
    }

    /**
     * Used to select the hyper link.
     * @param row -selected row data.
     * @param item -selected item data.
     * @param hyperLinkInfo -hyperlink information
     */
    selectHyperLink(row, item, hyperLinkInfo: any):void {
        this._hyperlink.selectHyperLink(row, item, hyperLinkInfo);
    }

    /**
     * Used to download the all table data in csv format in angular
     */
    DownloadAllData():void{
        this.isGetallData = true;
        this.isCallDownloadFunction = true;
        this.LoadData();
    }

    /**
     * Used to go to the back filter
     * @param id -filter selected id
     */
    backToFilter(id):void {
        $("#" + id).removeClass("active");
        setTimeout(() => {
            $(".mat-menu-content").removeClass("active");
            $(".mat-menu-content").removeClass("mat-menu-content_height");
        }, 50);
        if (this.FILTERS) {
            this.FILTERSLIST.reset();
        }
    }

    /**
     * Used to get the filter data.
     */
    getFilter():void {
        let opFilterList = JSON.parse(
            localStorage.getItem("Protected_trust_list_columns")
        );
        const filterData = this.TableColumnsService.getDynamicFilter(
            opFilterList,
            this.optionalFilterList,
            "Protected_trust_list_columns"
        );
        this.refreshFilterList();
    }

    /**
     * Get the data After Refresh the filter data.
     */
    refreshFilterList():void {
        //AdvanceFilter Refresh
        const advDynamicFilter = JSON.parse(
            localStorage.getItem("ProtectedTrust_Advance_Filter")
        );
        this.optionalFilterList.map((x) => {
            if (
                advDynamicFilter &&
                advDynamicFilter.findIndex((e) => e.COLUMNID == x.COLUMNID) !==
                    -1
            ) {
                x.FILTERAPPLY = true;
            }
        });
    }

    /**
     * Used to reset the all filter data.
     */
    resetFilter():void {
        // reset FILTERAPPLY value
        this.optionalFilterList.forEach((filter, index) => {
            filter.FILTERAPPLY = false;
        });

        localStorage.removeItem("ProtectedTrust_Advance_Filter");
        this.saveAdvanceFilter = [];
        // ends here ~ reset FILTERAPPLY value
        this.optionalFilters = [];
        this.optionalDateFilterPayload = [];
        if (this.FILTERS) {
            this.FILTERS.reset();
            //  this.FILTERSLIST.reset();
        }
        this.LoadData();
    }

    /**
     * Used to refresh the protected trust data.
     */
    refreshProtectedTrustTab():void {
        this.LoadData(this.finalPayloaddata);
    }

    /**
     * Used to set the default value to the filter
     * @param event -event data
     */
    preventDefault(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    /**
     * Used  to get the active filter data.
     * @param id -filter id
     * @param data -filter data.
     */
    activeCurrentFilter(id, data?: any):void {
        $("#" + id).addClass("active");
        setTimeout(() => {
            $(".mat-menu-content").addClass("active");
            $(".mat-menu-content").addClass("mat-menu-content_height");
        }, 200);

        //this.matterFilterForm.controls['MAINFILTER'].setValue(data.FILTERTYPE);
        this.selectedFilterIs = "";
        this.ProtectedTrustForm.controls["MAINFILTER"].reset();
        this.activeOptionalFilter = data;
    }
    @ViewChild("operationFilter", { static: false }) operationFilter: MatSelect;

    /**
     * Used to add new filter data dynamically
     * @param event -data
     */
    addNewFilter(event):void {
        // this.showFilterOption = false;
        setTimeout(() => {
            this.operationFilter.options.first.select();
        }, 200);

        this.addFilter.VALUE = "";
        this.addFilter.VALUE2 = "";

        if (this.FILTERSLIST) {
            this.FILTERSLIST.controls.splice(0, 1);
            //this.FILTERSLIST.reset();
        }

        if (event.FILTERTYPE == "DATE") {
            event.DATEFILTERLIST = this.dateFilter;
            event.DATEINNETFILTER = [
                {
                    id: 1,
                    value: "days",
                },
                {
                    id: 2,
                    value: "months",
                },
            ];
        }
        if (
            event.FILTERTYPE !== "DATE" &&
            event.FILTERTYPE !== "CURRENCY" &&
            event.FILTERTYPE !== "NUMBER"
        ) {
            event.DATEFILTERLIST = this.manullyOptionalFilter;
        }
        if (event.FILTERTYPE == "CURRENCY" || event.FILTERTYPE == "NUMBER") {
            event.DATEFILTERLIST = FilterSearchOptions.numberCurrencyFilter(
                event.FILTERTYPE
            );
        }
        this.addFilter.FILTERTYPE = event.FILTERTYPE;

        // OPTIONAL FILTER
        this.FILTERSLIST = this.ProtectedTrustForm.get(
            "FILTERSLIST"
        ) as FormArray;
        this.FILTERSLIST.push(this.createOPFilter(event.FILTERTYPE));
        const localFormIndex = this.FILTERSLIST.controls.length - 1;
        this.addFilter.OPERATION =
            event.FILTERTYPE == "DATE"
                ? "is in the last"
                : event.FILTERTYPE == "CURRENCY" || event.FILTERTYPE == "NUMBER"
                ? "is equal to"
                : "contains";
        this._filterList = [event];
        this.cd.detectChanges();
    }

/**
 * Create the Dynamic filter data
 * @param type -filter type
 * @returns -return the formGroup
 */
    createOPFilter(type): FormGroup {
        return this._formBuilder.group({
            OPERATION:
                type == "DATE"
                    ? "is in the last"
                    : type == "CURRENCY" || type == "NUMBER"
                    ? "is equal to"
                    : "contains",
            VALUE: "",
            VALUE2: "",
            FILTERTYPE: type,
        });
    }

    /**
     * Used to apply the optional date filter
     * @param event -event data
     * @param filterIs -filter object of values
     * @param val -current object value.
     * @param index -index of the filter data.
     */
    optionalDateFilter(event, filterIs, val, index):void {
        if (val && val == "active") {
            this.selectedFilterIs = event.value;
        } else {
            filterIs.selectedFilter = event.value;
            this.selectedFilterIs = event.value;
        }
        if (index != -1) {
            this.ProtectedTrustForm.controls.FILTERS["controls"][
                index
            ].controls["VALUE"].reset();
            this.ProtectedTrustForm.controls.FILTERS["controls"][
                index
            ].controls["VALUE2"].reset();
        }

        this.addFilter["VALUE"] = "";
        this.addFilter["VALUE2"] = "";
    }

    /**
     * Used to add the filter dynamicaly
     * @param IsfilterData -filter data
     * @param oprationalFilter -optional filter data
     * @param evt -evt data
     * @returns updated filter
     */
    async addfilter(IsfilterData, oprationalFilter, evt) {
        if (
            !this.TableColumnsService.checkValidation(
                this.addFilter.VALUE,
                this.addFilter.VALUE2,
                this.addFilter.OPERATION,
                IsfilterData.FILTERTYPE
            )
        ) {
            evt.preventDefault();
            evt.stopPropagation();
            return false;
        }
        await this.addDynamicFilter(IsfilterData);

        let sIndex = this.optionalFilters.length - 1;

        this.ProtectedTrustForm.value.FILTERS[sIndex].VALUE =
            this.addFilter.VALUE;
        this.ProtectedTrustForm.value.FILTERS[sIndex].VALUE2 =
            this.addFilter.VALUE2;
        this.ProtectedTrustForm.value.FILTERS[sIndex].OPERATION =
            this.addFilter.OPERATION;
        this.ProtectedTrustForm.value.FILTERS[sIndex].FILTERTYPE =
            IsfilterData.FILTERTYPE;
        IsfilterData.AdvancedFilter =
            this.ProtectedTrustForm.value.FILTERS[sIndex];
        let advFilters = JSON.parse(
            localStorage.getItem("ProtectedTrust_Advance_Filter")
        );
        if (advFilters) {
            this.saveAdvanceFilter = advFilters;
        }

        this.saveAdvanceFilter.push(IsfilterData);
        localStorage.setItem(
            "ProtectedTrust_Advance_Filter",
            JSON.stringify(this.saveAdvanceFilter)
        );
        if (this.FILTERS.length == 1) {
            this.ProtectedTrustForm.patchValue({
                FILTERS: [
                    {
                        OPERATION: this.addFilter.OPERATION,
                        VALUE: this.addFilter.VALUE,
                        VALUE2: this.addFilter.VALUE2,
                        FILTERTYPE: IsfilterData.FILTERTYPE,
                    },
                ],
            });
        } else {
            this.FILTERS.value.forEach((filterData, index) => {
                if (sIndex == index) {
                    filterData = {
                        OPERATION: this.addFilter.OPERATION,
                        VALUE: this.addFilter.VALUE,
                        VALUE2: this.addFilter.VALUE2,
                        FILTERTYPE: IsfilterData.FILTERTYPE,
                    };
                }
            });

            this.ProtectedTrustForm.patchValue({
                FILTERS: this.FILTERS.value,
            });
        }

        //After getting data appy filter.
        this.applayOptionalfilter(IsfilterData, sIndex, oprationalFilter);

        $(".example-containerdata").css(
            "height",
            $(window).height() -
                ($("#filter-form-menu-wrap").height() + 265) +
                "px"
        );
    }

    /**
     * Used to add the dynamic filter value
     * @param event -UPdated data
     * @param type -filter type
     */
    addDynamicFilter(event, type?):void {
        if (event.FILTERTYPE == "DATE") {
            event.DATEFILTERLIST = this.dateFilter;
            event.DATEINNETFILTER = [
                {
                    id: 1,
                    value: "days",
                },
                {
                    id: 2,
                    value: "months",
                },
            ];
        }
        if (
            event.FILTERTYPE !== "DATE" &&
            event.FILTERTYPE !== "CURRENCY" &&
            event.FILTERTYPE !== "NUMBER"
        ) {
            event.DATEFILTERLIST = this.manullyOptionalFilter;
        }
        if (event.FILTERTYPE == "CURRENCY" || event.FILTERTYPE == "NUMBER") {
            event.DATEFILTERLIST = FilterSearchOptions.numberCurrencyFilter(
                event.FILTERTYPE
            );
        }
        event.FILTERAPPLY = true;
        if (!type) {
            this.optionalFilters.push(event);
        }
        this.FILTERS = this.ProtectedTrustForm.get("FILTERS") as FormArray;
        this.FILTERS.push(this.createOPFilter(event.FILTERTYPE));
    }

    /**
     * Apply the optional filter in dynamic format
     * @param FilterData -Filter data
     * @param isIndex -current filter index
     * @param OpretionIs -current operation value
     * @param evt -evt data
     * @returns optional filter
     */
    applayOptionalfilter(FilterData, isIndex, OpretionIs?: any, evt?: any):boolean {
        let saveAdvancefilterData = JSON.parse(
            localStorage.getItem("ProtectedTrust_Advance_Filter")
        );
        const field1Val =
            this.ProtectedTrustForm.controls.FILTERS["controls"][isIndex]
                .controls["VALUE"].value;
        const field2Val =
            this.ProtectedTrustForm.controls.FILTERS["controls"][isIndex]
                .controls["VALUE2"].value;
        const operationVal =
            this.ProtectedTrustForm.controls.FILTERS["controls"][isIndex]
                .controls["OPERATION"].value;
        const filterTypeVal =
            this.ProtectedTrustForm.controls.FILTERS["controls"][isIndex]
                .controls["FILTERTYPE"].value;

        if (
            !this.TableColumnsService.checkValidation(
                field1Val,
                field2Val,
                operationVal,
                filterTypeVal
            )
        ) {
            evt.preventDefault();
            evt.stopPropagation();
            return false;
        }

        let addData = JSON.parse(JSON.stringify(FilterData));
        addData.selectedFilter = OpretionIs.triggerValue;
        const applyFilterData = this.TableColumnsService.Optionalfilter(
            addData,
            isIndex,
            this.optionalDateFilterPayload,
            this.ProtectedTrustForm
        );
        this.optionalDateFilterPayload = applyFilterData;
        this.TableColumnsService.setAdvanceFilter(
            saveAdvancefilterData,
            this.saveAdvanceFilter,
            isIndex,
            FilterData,
            field1Val,
            field2Val,
            operationVal,
            filterTypeVal,
            applyFilterData,
            "ProtectedTrust"
        );
        this.doFilter(this.optionalDateFilterPayload);
    }

    /**
     * used to start the filtering process
     * @param mainFilter -mainfilter data.
     */
    doFilter(mainFilter: any):void {
        const payloadData = JSON.parse(JSON.stringify(mainFilter));
        const tempPayload = Object.assign(
            JSON.parse(JSON.stringify(mainFilter))
        );

        const finalPayload = payloadData
            .filter((d) => d["value"] !== null)
            .map((e) => {
                if (e["value"] && e["value"] !== null) {
                    delete e["value"].COLUMNID;
                    return e["value"];
                }
            });
        this.finalPayloaddata = finalPayload;
        this.reload(finalPayload);
    }

    /**
     * Used to reload the data
     * @param filterVal -filter data
     */
    reload(filterVal):void {
        this.LoadData(filterVal);
    }

    /**
     * Used to set the dynamic filter UI.
     * @param filter -filter data
     * @param index -filter index
     */
    setFilterUi(filter, index):void {
        this.selectedFilterIs =
            this.optionalDateFilterPayload[index]["value"]["OPERATION"];
    }

    /**
     * Used to set the default advanced filter data.
     */
    async setDefaultAdvanceFilter():Promise<void> {
        let advanceFiltes = JSON.parse(
            localStorage.getItem("ProtectedTrust_Advance_Filter")
        );
        if (advanceFiltes) {
            this.optionalDateFilterPayload = [];
            this.optionalFilters = advanceFiltes;
            await advanceFiltes.forEach(async (filterData, index) => {
                await this.addDynamicFilter(
                    filterData.AdvancedFilter,
                    "default"
                );

                this.FILTERS.value[index].OPERATION = await filterData
                    .AdvancedFilter.OPERATION;
                this.FILTERS.value[index].FILTERTYPE = await filterData
                    .AdvancedFilter.FILTERTYPE;
                this.FILTERS.value[index].VALUE = await filterData
                    .AdvancedFilter.VALUE;
                this.FILTERS.value[index].VALUE2 = await filterData
                    .AdvancedFilter.VALUE2;
                await this.optionalDateFilterPayload.push(
                    filterData.FilterHeadings
                );

                await this.ProtectedTrustForm.patchValue({
                    FILTERS: this.FILTERS.value,
                });
            });
        }
    }

    /**
     * Used to reset the Optional filter data.
     * @param event -event for getting the event data.
     * @param type -filter type.
     * @param isindex -updated index
     */
    resetOptionalFilter(event, type, isindex) {
        let index = this.optionalFilters.findIndex(
            (e) => e.COLUMNID == event.COLUMNID
        );
        event.FILTERAPPLY = false; //for filter hide/show from optional filter list.
        this.saveAdvanceFilter.splice(index, 1);
        this.optionalFilters.splice(index, 1);
        this.optionalDateFilterPayload.splice(isindex, 1);
        this.FILTERS.controls.splice(isindex, 1);
        this.ProtectedTrustForm.value.FILTERS.splice(isindex, 1);
        const ArrayResetOptional = JSON.parse(
            JSON.stringify(this.ProtectedTrustForm.value.FILTERS)
        );
        this.TableColumnsService.refrshAdvanceFilter(event, "ProtectedTrust");
        const ArrayResetOptionalNew = ArrayResetOptional.map((data, index) => {
            if (this.optionalFilters[index]) {
                data.COLUMNNAME = this.optionalFilters[index].COLUMNNAME;
            }
            return data;
        });

        const finalPayload = ArrayResetOptionalNew.filter(
            (d) => d["VALUE"] !== null && d["VALUE"] !== ""
        ).map((e) => {
            if (e["VALUE"] && e["VALUE"] !== "") {
                delete e.COLUMNID;
                if (e.FILTERTYPE == "DATE") {
                    let date1 = e.VALUE;
                    let date2 = e.VALUE2;

                    let date1Split = date1.split("T");
                    if (date1Split.length > 1) {
                        date1 =
                            e && e.VALUE !== ""
                                ? moment(e.VALUE).format("DD/MM/YYYY")
                                : "";
                    }

                    let date2Split = date2.split("T");
                    if (date2Split.length > 1) {
                        date2 =
                            e && e.VALUE2 !== "" && e.OPERATION == "is between"
                                ? moment(e.VALUE2).format("DD/MM/YYYY")
                                : "";
                    }

                    if (e.OPERATION == "is in the last") {
                        e.VALUE = e.VALUE;
                        e.VALUE2 = e.VALUE2;
                    } else {
                        e.VALUE = date1;
                        e.VALUE2 = date2;
                    }
                }

                if (e.VALUE2 == null) {
                    e.VALUE2 = "";
                }
                return e;
            }
        });

        this.finalPayloaddata = finalPayload;
        this.reload(finalPayload);
    }
}
