import { BankingDialogComponent } from "../../../banking/banking-dialog.component";
import { BehaviorService } from "@_services/Behavior.service";
import { Component, OnInit, OnDestroy, inject } from "@angular/core";
import { DatePipe } from "@angular/common";
import { FormBuilder, FormGroup } from "@angular/forms";
import { GlobalFunctionsService } from "@_services/global-functions.service";
import { MainAPiServiceService } from "@_services/main-api-service.service";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { Subject, Subscription, takeUntil, tap } from "rxjs";
import { ToastrService } from "ngx-toastr";
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA,} from "@angular/material/dialog";
import { Router } from "@angular/router";

@Component({
    selector: "app-protected-trust-dialog",
    templateUrl: "./protected-trust-dialog.component.html",
    styleUrls: ["./protected-trust-dialog.component.scss"],
})
export class ProtectedTrustDialogComponent implements OnInit, OnDestroy {
protected _router=inject(Router);
protected globalFunService=inject(GlobalFunctionsService);
protected dialogRef=inject(MatDialogRef<ProtectedTrustDialogComponent>);
protected datepipe=inject(DatePipe);
protected data=inject(MAT_DIALOG_DATA);
protected _mainAPiServiceService=inject(MainAPiServiceService);
protected toastr=inject(ToastrService);
protected formbuilder=inject(FormBuilder);
protected dialog=inject(MatDialog);
protected behaviorService=inject(BehaviorService);
    btntext: string;
    private _unsubscribeAll$: Subject<void> = new Subject();
    headertext: string;
    errorWarningData: any = {};
    errorWarningDataArray: any = {};
    ProtectedTrust: FormGroup;
    oldIsTrackId: string;
    MatterStatusLookupsData: any;
    confirmDialogRef: any;
    isLoadingResults: boolean = false;
    isspiner: boolean = false;
    payload: any;
    PROTECTIONTYPE: any;
    selectedMatter:any
    IsReverse = "No";
    sub: Subscription;

                 /**
                 *  Subscribes to the clickMaterFromTimeLine$ observable provided by behaviorService.
                 * @remarks
                 * It is particularly relevant for scenarios where the behaviorService controls the "open in new tab"
                 * feature(on or off), and the selection of matterGuid is influenced by whether multiple tabs are open.
                 */
    public CLICKMATTER$=this.behaviorService.clickMaterFromTimeLine$
    .pipe(
        tap(result => {
            if (result) {
                this.selectedMatter = result;
                this.structureOfProtectionCrud();
            }
        })
    )
    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete();
        this.sub?.unsubscribe();
    }

    ngOnInit(): void {
        this.formDiclear();      
    }

    /**
     *Function is used To Declared The Form
     */
    formDiclear():void {
        this.ProtectedTrust = this.formbuilder.group({
            MATTERGUID: [""],
            DATE: [""],
            NOTE: [""],
            AMOUNT: [""],
            AMOUNTUSED: [""],
            ACCOUNTNAME: [""],
            PROTECTIONTYPE: [""],
            DATETEXT: [""],
            PROTECTEDTRUSTGUID: [""],
            ACCOUNTGUID: [""],
        });

        this.sub = this.ProtectedTrust.valueChanges.subscribe((formControl) => {
            this.changeForControl(formControl);
        });
    }

    /**
     * Used to MAKE the crud structure
     */
    structureOfProtectionCrud():void {
        if (this.data.action == "insert") {
            this.defaultProtectedData();
            this.btntext = "Save";
            this.headertext = "New Protected Trust";
        } else if (this.data.action == "update") {
            this.changeForControl(this.f);
            this.btntext = "Update";
            this.headertext = "Update Protected Trust";
            this.getProtectedTrustEditedData(this.data.EditId);
        }
        this.getLookups();
    }

    /**
     * Used to get all lookups
     */
    getLookups():void {
        this._mainAPiServiceService
            .getSetData(
                {
                    ACTION: "GetData",
                    Filters: { LookupType: "protected trust type" },
                },
                "lookup"
            )
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe((res) => {
                if (res.CODE == 200 && res.STATUS == "success") {
                    this.MatterStatusLookupsData = res.DATA.LOOKUPS;
                }
            });
    }

    /**
     * Used to get the Default Protected data.
     */
    defaultProtectedData():void {
        this.isLoadingResults = true;
        let data
        // if (this._router.url == '/matter-details/protected-trust') {
            data = { Action: "Default", VALIDATEONLY: true, DATA: { MATTERGUID: this.selectedMatter.MATTERGUID } }
        // } else {
        //     data = { Action: "Default", VALIDATEONLY: true, DATA: {} }
        // }
        this._mainAPiServiceService
            .getSetData(
                data,
                "trust-protected"
            )
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe(
                (res) => {
                    if (res.CODE == 200 && res.STATUS == "success") {
                        const Matterdata = res.DATA.DEFAULTVALUES;
                        this.ProtectedTrust.patchValue({
                            MATTERGUID: Matterdata["MATTERGUID"],
                            DATE: Matterdata["PROTECTEDDATE"],
                            NOTE: Matterdata["REASON"],
                            AMOUNT: Matterdata["AMOUNT"],
                            AMOUNTUSED: Matterdata["AMOUNTUSED"],
                            PROTECTIONTYPE: Matterdata["PROTECTIONTYPE"],
                            DATETEXT: Matterdata["PROTECTEDDATE"],
                            PROTECTEDTRUSTGUID:
                                Matterdata["PROTECTEDTRUSTGUID"],
                            ACCOUNTGUID: Matterdata["ACCOUNTGUID"],
                            ACCOUNTNAME:Matterdata['ACCOUNTNAME']
                        });
                        this.isLoadingResults = false;
                    } else if (res.CODE == 451 && res.STATUS == "warning") {
                        this.toastr.warning(res.MESSAGE);
                        this.isLoadingResults = false;
                    } else if (res.CODE == 450 && res.STATUS == "error") {
                        this.toastr.error(res.MESSAGE);
                        this.isLoadingResults = false;
                    } else if (res.MESSAGE == "Not logged in") {
                        this.dialogRef.close(false);
                    }

                    this.CheckdefultValidation(res.DATA.VALIDATIONS);
                    this.isLoadingResults = false;
                },
                (err) => {
                    this.isLoadingResults = false;
                    this.toastr.error(err);
                }
            );
    }

    /**
     * Used to check the default validation.
     * @param bodyData -All default data.
     */
    async CheckdefultValidation(bodyData: any):Promise<void> {
        let errorData: any = [];
        let warningData: any = [];
        let tempError: any = [];
        let tempWarning: any = [];
        bodyData.forEach(function (value: {
            VALUEVALID: string;
            ERRORDESCRIPTION: any;
            FIELDNAME: any;
        }) {
            if (value.VALUEVALID == "No") {
                errorData.push(value.ERRORDESCRIPTION);
                tempError[value.FIELDNAME] = value;
            } else if (value.VALUEVALID == "Warning") {
                tempWarning[value.FIELDNAME] = value;
                warningData.push(value.ERRORDESCRIPTION);
            }
        });
        this.errorWarningData = await {
            Error: tempError,
            Warning: tempWarning,
        };
        this.errorWarningDataArray = await {
            Error: tempError,
            Warning: tempWarning,
        };
        // await this.changeForControl(this.f);
    }

    /**
     * To change the Formcontrol value
     * @param formControl -get updated form control value
     */
    changeForControl(formControl:any):void {
        const dubObj = { ...this.errorWarningDataArray.Error };
        let element = this.errorWarningData.Error;
        for (const [key, value] of Object.entries(dubObj)) {
            const KEY = key.toLocaleUpperCase();
            if (
                formControl[KEY] != undefined &&
                formControl[KEY] != null &&
                formControl[KEY]
            ) {
                delete element[key];
                dubObj[key] = value;
                this.errorWarningDataArray.Error = { ...dubObj };
            } else if (!formControl[KEY]) {
                element[key] = dubObj[key];
            }
        }

        const dubObj_1 = { ...this.errorWarningDataArray.Warning };
        let element_1 = this.errorWarningData.Warning;
        for (const [key, value] of Object.entries(dubObj_1)) {
            const KEY = key.toLocaleUpperCase();
            if (
                formControl[KEY] != undefined &&
                formControl[KEY] != null &&
                formControl[KEY]
            ) {
                delete element_1[key];
                dubObj_1[key] = value;
                this.errorWarningDataArray.Warning = { ...dubObj_1 };
            } else if (!formControl[KEY]) {
                element_1[key] = dubObj_1[key];
            }
        }
    }

    /**
     * Used to return the Formcontrol value.
     */
    get f() {
        return this.ProtectedTrust.controls;
    }

/**
 *
 */
    AfterViewInitNewMatter() {
        let tempError: any = [];
        let tempWarning: any = [];
        tempWarning["NOTE"] = {};
        tempWarning["DATE"] = {};
        tempWarning["MATTERGUID"] = {};

        this.errorWarningData = { Error: tempError, Warning: tempWarning };
        this.errorWarningDataArray = { Error: tempError, Warning: tempWarning };
    }

    /**
     * Used to open the bankdialog box.
     */
    openSelectWindow():void {
        const dialogRef = this.dialog.open(BankingDialogComponent, {
            disableClose: true,
            width: "100%",
            data: {
                AccountType: "Matter Ledger",
                RoterPath: "protected-trust",
                UseTrust: "Yes",
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.ProtectedTrust.controls["ACCOUNTNAME"].setValue(
                    result.data.name
                );
                this.ProtectedTrust.controls["MATTERGUID"].setValue(
                    result.data.MATTERGUID
                );
                this.ProtectedTrust.controls["ACCOUNTGUID"].setValue(
                    result.data.ACCOUNTGUID
                );
            }

            this.SetOldistrackid("close");
        });
    }

    /**
     * Used to open the old id
     * @param type -type of the id
     */
    SetOldistrackid(type):void {
        if (type === "open") {
            this.oldIsTrackId = localStorage.getItem("istrackid");
        } else {
            localStorage.setItem("istrackid", this.oldIsTrackId);
        }
    }

    /**
     * Used to get the default protected data.
     */
    getProtectedTrustData():void {
        this.isspiner = true;
        let matterGuid;
        if (this._router.url == '/matter-details/protected-trust') {
            matterGuid = this.selectedMatter.MATTERGUID;
        } else {
            matterGuid = this.ProtectedTrust.get("MATTERGUID").value;
        }
        // this.payload = {
        //    // MATTERGUID: this.ProtectedTrust.get("MATTERGUID").value,
        //     PROTECTEDDATE: this.ProtectedTrust.get("DATE").value,
        //     PROTECTIONTYPE: this.ProtectedTrust.get("PROTECTIONTYPE").value,
        //     AMOUNT: this.ProtectedTrust.get("AMOUNT").value,
        //     AMOUNTUSED: this.ProtectedTrust.get("AMOUNTUSED").value,
        //     REASON: this.ProtectedTrust.get("NOTE").value,
        //     PROTECTEDTRUSTGUID:
        //         this.ProtectedTrust.get("PROTECTEDTRUSTGUID").value,
        //     ACCOUNTGUID: this.ProtectedTrust.get("ACCOUNTGUID").value,
        // };

        // if (this.data.action == "update") {
        //     this.payload = {
        //      //   MATTERGUID: this.ProtectedTrust.get("MATTERGUID").value,
        //         PROTECTEDDATE: this.ProtectedTrust.get("DATE").value,
        //         PROTECTIONTYPE: this.ProtectedTrust.get("PROTECTIONTYPE").value,
        //         AMOUNT: this.ProtectedTrust.get("AMOUNT").value,
        //         AMOUNTUSED: this.ProtectedTrust.get("AMOUNTUSED").value,
        //         REASON: this.ProtectedTrust.get("NOTE").value,
        //         ACCOUNTGUID: this.ProtectedTrust.get("ACCOUNTGUID").value,
        //         PROTECTEDTRUSTGUID:
        //             this.ProtectedTrust.get("PROTECTEDTRUSTGUID").value,
        //     };
        // }
        const commonPayload = {
            PROTECTEDDATE: this.ProtectedTrust.get("DATE").value,
            PROTECTIONTYPE: this.ProtectedTrust.get("PROTECTIONTYPE").value,
            AMOUNT: this.ProtectedTrust.get("AMOUNT").value,
            AMOUNTUSED: this.ProtectedTrust.get("AMOUNTUSED").value,
            REASON: this.ProtectedTrust.get("NOTE").value,
            PROTECTEDTRUSTGUID: this.ProtectedTrust.get("PROTECTEDTRUSTGUID").value,
            ACCOUNTGUID: this.ProtectedTrust.get("ACCOUNTGUID").value,
        };
        let finalpayload = {
            Action: this.data.action,
            VALIDATEONLY: true,
            DATA: {
                MATTERGUID: matterGuid,
                ...commonPayload,
            },
        };
        this._mainAPiServiceService
            .getSetData(finalpayload, "trust-protected")
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe(
                (res) => {
                    if (res.CODE == 200 && res.STATUS == "success") {
                        this.checkValidation(
                            res.DATA.VALIDATIONS,
                            finalpayload,
                            "trust-protected"
                        );
                    } else if (res.CODE == 451 && res.STATUS == "warning") {
                        this.checkValidation(
                            res.DATA.VALIDATIONS,
                            finalpayload,
                            "trust-protected"
                        );
                    } else if (res.CODE == 450 && res.STATUS == "error") {
                        this.checkValidation(
                            res.DATA.VALIDATIONS,
                            finalpayload,
                            "trust-protected"
                        );
                    } else if (res.MESSAGE == "Not logged in") {
                        this.dialogRef.close(false);
                    }
                },
                (err) => {
                    this.isLoadingResults = false;
                    this.toastr.error(err);
                }
            );
    }

    /**
     * Used to get the updated data.
     * @param type -
     * @param event -get the updated date
     */
    CommencementDate(type: string, event: MatDatepickerInputEvent<Date>):void {
        this.ProtectedTrust.controls["DATE"].setValue(
            this.datepipe.transform(event.value, "dd/MM/yyyy")
        );
    }

    /**
     * Used to check the validation status
     * @param bodyData -Data whitch is come to the API.
     * @param details -Updated data
     * @param APIURL -API end point
     */
    async checkValidation(bodyData: any, details: any, APIURL: any):Promise<void> {
        this.isspiner = false;
        await this.globalFunService
            .checkValidation(bodyData, details)
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe((data) => {
                if (data) {
                    this.errorWarningData = data.errorWarningData;
                    // this.errorWarningDataArray = data.errorWarningData;
                    if (data.callback) {
                        //this.SafeCusodySave(details, APIURL)
                        this.saveProtectedTrustData(details, APIURL);
                    }
                }
            });
    }

    /**
     * Used to save the protected trust data
     * @param data -Data which we need to pass to request body.
     * @param APIURL -API end point.
     */
    saveProtectedTrustData(data: any, APIURL:string):void {
        data.VALIDATEONLY = false;
        this._mainAPiServiceService
            .getSetData(data, APIURL)
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe(
                (response) => {
                    if (
                        response.CODE == 200 &&
                        (response.STATUS == "OK" ||
                            response.STATUS == "success")
                    ) {
                        this.behaviorService.CallProtectedTrustApi$.next(true);
                        this.toastr.success("save successfully");
                        this.isspiner = false;
                        this.dialogRef.close(true);
                    } else if (
                        response.CODE == 451 &&
                        response.STATUS == "warning"
                    ) {
                        this.toastr.warning(response.MESSAGE);
                    } else if (
                        response.CODE == 450 &&
                        response.STATUS == "error"
                    ) {
                        this.toastr.error(response.MESSAGE);
                    } else if (response.MESSAGE == "Not logged in") {
                        this.dialogRef.close(false);
                    }
                    this.isspiner = false;
                },
                (error) => {
                    this.toastr.error(error);
                }
            );
    }

    /**
     * Used to edit the data.
     * @param id -id which want to pass the api.
     */
    getProtectedTrustEditedData(id: any):void {
        this.isLoadingResults = true;
        this._mainAPiServiceService
            .getSetData(
                { Action: "GetData", Filters: { PROTECTEDTRUSTGUID: id } },
                "trust-protected"
            )
            .pipe(takeUntil(this._unsubscribeAll$))
            .subscribe((res) => {
                if (res.CODE == 200 && res.STATUS == "success") {
                    this.ProtectedTrust.patchValue({
                        MATTERGUID: res.DATA.RECORDS[0]["MATTERGUID"],
                        DATE: res.DATA.RECORDS[0]["PROTECTEDDATE"],
                        NOTE: res.DATA.RECORDS[0]["REASON"],
                        AMOUNT: res.DATA.RECORDS[0]["AMOUNT"],
                        AMOUNTUSED: res.DATA.RECORDS[0]["AMOUNTUSED"],
                        PROTECTIONTYPE: res.DATA.RECORDS[0]["PROTECTIONTYPE"],
                        DATETEXT: res.DATA.RECORDS[0]["PROTECTEDDATE"],
                        PROTECTEDTRUSTGUID:
                            res.DATA.RECORDS[0]["PROTECTEDTRUSTGUID"],
                        ACCOUNTNAME: res.DATA.RECORDS[0]["ACCOUNTNAME"],
                        ACCOUNTGUID: res.DATA.RECORDS[0]["ACCOUNTGUID"],
                    });
                    this.PROTECTIONTYPE = res.DATA.RECORDS[0]["PROTECTIONTYPE"];
                    let DATE1 = res.DATA.RECORDS[0]["PROTECTEDDATE"].split("/");
                    this.ProtectedTrust.controls["DATETEXT"].setValue(
                        new Date(DATE1[1] + "/" + DATE1[0] + "/" + DATE1[2])
                    );
                    this.isLoadingResults = false;
                } else if (res.CODE == 451 && res.STATUS == "warning") {
                    this.toastr.warning(res.MESSAGE);
                    this.isLoadingResults = false;
                } else if (res.CODE == 450 && res.STATUS == "error") {
                    this.toastr.error(res.MESSAGE);
                    this.isLoadingResults = false;
                } else if (res.MESSAGE == "Not logged in") {
                    this.dialogRef.close(false);
                }
            });
    }
}
