import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { environment } from "../../environments/environment";
// import { io } from 'socket.io-client';
// import 'rxjs/add/observable/of';

@Injectable({
    providedIn: "root",
})
export class SocketService {
    // currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    // webSocket = new WebSocket(environment.MainSocketUrl + `?SessionToken=${this.currentUser.SessionToken}`);
    webSocket : WebSocket | null = null;
    // private socket = io(environment.MainSocketUrl,{
    //     extraHeaders: {
    //       Authorization: this.currentUser.SessionToken
    //     }
    // });
    // webSocket;
    sessionToken = localStorage.getItem("session_token");
    // webSocketMatter = new WebSocket(environment.MainSocketMatterUrl + `?SessionToken=${this.sessionToken}`);
    webSocketMatter : WebSocket | null = null;
    webSocketReports : WebSocket | null = null;
    webSocketReportGeneration : WebSocket | null = null;
    webSocketDocumentGeneration : WebSocket | null = null;
    private socketsDocumentGeneration = [];
    private socketsReportGeneration = [];
    private shouldReconnect = true;
    private shouldReconnectReports = true;

    private webSocketMatterSubject = new BehaviorSubject<any>(null);
    private webSocketReportsSubject = new BehaviorSubject<any>(null);
    private webSocketReportGenerationSubject = new BehaviorSubject<any>(null);
    private webSocketDocumentGenerationSubject = new BehaviorSubject<any>(null);

    constructor() { 
        // this.initWebSocket();
    }

    /**
     * This function is used to open the Socket
     * @param SessionToken -session token value
     */
    // async openSocket(SessionToken?) {
    //     if (this.webSocket && this.webSocket.readyState === WebSocket.OPEN) {
    //         return;
    //     }
    //     this.webSocket = new WebSocket(environment.MainSocketUrl);
    //     // const queryParams = `?SessionToken=${this.currentUser.SessionToken}`;
    //     await new Promise<void>(async (next, reject) => {
    //         // this.webSocket = new WebSocket(environment.MainSocketUrl + queryParams);
    //         this.webSocket.onopen = function (e) {
    //             console.log('WebSocket connection opened for apitest',e);
    //             // this.openWSConnection(SessionToken);
    //             next();
    //         };

    //         this.webSocket.onerror = (error) => {
    //             console.error('WebSocket error occurred', error);
    //             setTimeout(() => this.openSocket(), 500);
    //         };
    
    //         // this.webSocket.ondisconnect = (error) => {
    //         //     console.error('WebSocket error occurred', error);
    //         // };
        
    //         this.webSocket.onclose = (event) => {
    //             console.error('WebSocket error occurred for closed connection', event);
    //             setTimeout(() => this.openSocket(), 500);
    //         };
    //     });
    //     // await new Promise<void>(async (next, reject) => {
    //     //     this.socket.on("connect", () => {
    //     //         next();
    //     //     })
    //     // });
    //     // this.socket.connect();
    // }

    // async openSocketMatter(SessionToken?): Promise<void> {

    //     if (this.webSocketMatter && this.webSocketMatter.readyState === WebSocket.OPEN) {
    //         return Promise.resolve();
    //     }

    //     // const queryParams = `?SessionToken=${this.sessionToken ? this.sessionToken : SessionToken}`;
    //     const token = SessionToken || this.sessionToken;
    //     const queryParams = `?SessionToken=${token}`;
    //     const url = environment.MainSocketMatterUrl + queryParams;

    //     return new Promise<void>((next, reject) => {
    //         if (this.webSocketMatter) {
    //             this.webSocketMatter.close(); // Ensure any existing connection is closed
    //         }
    //         this.webSocketMatter = new WebSocket(url);
    //         // const demoSocket = (this.webSocketMatter && this.webSocketMatter.url.includes('null')) ? new WebSocket(environment.MainSocketMatterUrl + queryParams) : this.webSocketMatter;
    //         // const demoSocket = this.webSocketMatter;
    //         // const demoSocket = this.webSocketMatter;
    //         this.webSocketMatter.onopen = () => {
    //             console.log("WebSocket connection opened.");
    //             next();
    //             this.resolveConnection();
    //         };
            
    //         this.webSocketMatter.onerror = (error) => {
    //             reject(error);
    //             this.rejectConnection(error);
    //         };
            
    //         this.webSocketMatter.onclose = (event) => {
    //             next();
    //         };

    //         this.webSocketMatter.onmessage = (messageEvent) => {
    //             try {
    //                 const data = JSON.parse(messageEvent.data);
    //                 const details = JSON.parse(data.message);
    //                 this.behaviorService.matterChangeValues(details);
    //                 // observer.next(details);
    //             } catch (error) {
    //                 // observer.error(error);
    //             }
    //         };
    //     });
    // }

    public initWebSocketReports() {
        const sessionToken = localStorage.getItem('session_token');
        if (!sessionToken) {
            return;
        }
        const url = `${environment.MainSocketMatterUrl}?SessionToken=${sessionToken}&changeTopic=TrackReport`;
        this.webSocketReports = new WebSocket(url);
    
        this.webSocketReports.onopen = () => {
          console.log('WebSocket connection opened');
        };

        this.webSocketReports.onmessage = (messageEvent) => {
            const data = JSON.parse(messageEvent.data);
            const details = data;
            this.webSocketReportsSubject.next(details);
            const eventKey = `websocket_event_${Date.now()}`;
            localStorage.setItem(eventKey, JSON.stringify(details));
            setTimeout(() => localStorage.removeItem(eventKey), 500);
        };
    
        this.webSocketReports.onerror = (error) => {
            console.error('WebSocket error occurred', error);
            setTimeout(() => this.initWebSocketReports(), 500);
        };

        // this.webSocketReports.ondisconnect = (error) => {
        //     console.error('WebSocket error occurred', error);
        // };
    
        this.webSocketReports.onclose = (event) => {
            if (this.shouldReconnectReports) {
                setTimeout(() => this.initWebSocketReports(), 500);
            }
        };
    }


    public initWebSocketMatter() {
        const sessionToken = localStorage.getItem('session_token');
        if (!sessionToken) {
            return;
        }
        const url = `${environment.MainSocketMatterUrl}?SessionToken=${sessionToken}&changeTopic=Matter-DataChange`;
        this.webSocketMatter = new WebSocket(url);
    
        this.webSocketMatter.onopen = () => {
          console.log('WebSocket connection opened');
        };

        this.webSocketMatter.onmessage = (messageEvent) => {
            const data = JSON.parse(messageEvent.data);
            const details = data;
            this.webSocketMatterSubject.next(details);
            const eventKey = `websocket_event_${Date.now()}`;
            localStorage.setItem(eventKey, JSON.stringify(details));
            setTimeout(() => localStorage.removeItem(eventKey), 500);
        };
    
        this.webSocketMatter.onerror = (error) => {
            console.error('WebSocket error occurred', error);
            setTimeout(() => this.initWebSocketMatter(), 500);
        };

        // this.webSocketMatter.ondisconnect = (error) => {
        //     console.error('WebSocket error occurred', error);
        // };
    
        this.webSocketMatter.onclose = (event) => {
            console.error('WebSocket close event occurred', event);
            if (this.shouldReconnect) {
                setTimeout(() => this.initWebSocketMatter(), 500);
            }
        };
    }

    public initWebSocketReportGeneration(uniqueId) {
        // const uniqueId = this.generateUniqueId();
        const sessionToken = localStorage.getItem('session_token');
        if (!sessionToken) {
            return;
        }
        const url = `${environment.MainSocketMatterUrl}?SessionToken=${sessionToken}&TrackingGuid=${uniqueId}&changeTopic=ReportGenerateStatus`;
        const webSocketReportGeneration = new WebSocket(url);

        this.socketsReportGeneration.push({ socket: webSocketReportGeneration, id: uniqueId });

        webSocketReportGeneration.onopen = () => {
          console.log('WebSocket connection opened');
        };

        webSocketReportGeneration.onmessage = (messageEvent) => {
            const data = JSON.parse(messageEvent.data);
            const details = data;
            this.webSocketReportGenerationSubject.next(details);
            const eventKey = `websocketRG_event_${Date.now()}`;
            localStorage.setItem(eventKey, JSON.stringify(details));
            setTimeout(() => localStorage.removeItem(eventKey), 500);
        };
    
        webSocketReportGeneration.onerror = (error) => {
            console.error('WebSocket error occurred', error);
            setTimeout(() => this.initWebSocketReportGeneration(uniqueId), 500);
        };

        // this.webSocketMatter.ondisconnect = (error) => {
        //     console.error('WebSocket error occurred', error);
        // };
    
        webSocketReportGeneration.onclose = (event) => {
            console.error('WebSocket close event occurred', event);
            // if (this.shouldReconnect) {
            //     setTimeout(() => this.initWebSocketReportGeneration(uniqueId), 500);
            // }
        };
    }

    public disconnectWebSocketReportGeneration(uniqueId: string) {
        const socketIndex = this.socketsReportGeneration.findIndex(s => s.id === uniqueId);
        if (socketIndex !== -1) {
            const socket = this.socketsReportGeneration[socketIndex].socket;
            socket.close(); // Close the WebSocket connection
            this.socketsReportGeneration.splice(socketIndex, 1); // Remove it from the array
        } else {
            console.warn(`No WebSocket connection found with ID ${uniqueId}`);
        }
    }

    public initWebSocketDocumentGeneration(uniqueId) {
        // const uniqueId = this.generateUniqueId();
        const sessionToken = localStorage.getItem('session_token');
        if (!sessionToken) {
            return;
        }
        const url = `${environment.MainSocketMatterUrl}?SessionToken=${sessionToken}&TrackingGuid=${uniqueId}&changeTopic=TemplateGenerateStatus`;
        const webSocketDocumentGeneration = new WebSocket(url);

        // this.socketsDocumentGeneration.push(this.webSocketDocumentGeneration);
        this.socketsDocumentGeneration.push({ socket: webSocketDocumentGeneration, id: uniqueId });

        webSocketDocumentGeneration.onopen = () => {
          console.log('WebSocket connection opened', uniqueId);
        };

        webSocketDocumentGeneration.onmessage = (messageEvent) => {
            const data = JSON.parse(messageEvent.data);
            const details = data;
            this.webSocketDocumentGenerationSubject.next(details);
            const eventKey = `websocketDG_event_${Date.now()}_${uniqueId}`;
            localStorage.setItem(eventKey, JSON.stringify(details));
            setTimeout(() => localStorage.removeItem(eventKey), 500);
        };
    
        webSocketDocumentGeneration.onerror = (error) => {
            console.error('WebSocket error occurred', error);
            setTimeout(() => this.initWebSocketDocumentGeneration(uniqueId), 500);
        };

        // this.webSocketMatter.ondisconnect = (error) => {
        //     console.error('WebSocket error occurred', error);
        // };
    
        webSocketDocumentGeneration.onclose = (event) => {
            console.error('WebSocket close event occurred', event);
            // this.removeSocket(this.webSocketDocumentGeneration)
            // if (this.shouldReconnect) {
            //     setTimeout(() => this.initWebSocketDocumentGeneration(uniqueId), 500);
            // }
        };
    }

    public disconnectWebSocketDocumentGeneration(uniqueId: string) {
        const socketIndex = this.socketsDocumentGeneration.findIndex(s => s.id === uniqueId);
        if (socketIndex !== -1) {
            const socket = this.socketsDocumentGeneration[socketIndex].socket;
            socket.close(); // Close the WebSocket connection
            this.socketsDocumentGeneration.splice(socketIndex, 1); // Remove it from the array
        } else {
            console.warn(`No WebSocket connection found with ID ${uniqueId}`);
        }
    }
    
    // Remove a WebSocket from the list on close
    // private removeSocket(socket: WebSocket) {
    //     this.socketsDocumentGeneration = this.socketsDocumentGeneration.filter(s => s !== socket);
    // }

    generateUniqueId(): string {
        // Create a unique ID using the current timestamp and a random number
        const timestamp = new Date().getTime().toString(36);
        const randomNumber = Math.random().toString(36).substring(2, 15);
        return `${timestamp}-${randomNumber}`;
    }

    public getWebSocketSubjectReports() {
        return this.webSocketReportsSubject.asObservable();
    }


    public getWebSocketSubject() {
        return this.webSocketMatterSubject.asObservable();
    }

    public getWebSocketReportGenerationSubject() {
        return this.webSocketReportGenerationSubject.asObservable();
    }

    public getWebSocketDocumentGenerationSubject() {
        return this.webSocketDocumentGenerationSubject.asObservable();
    }

    public openSocketDocumentGeneration(uniqueId: string, sessionToken?: string) {
        // if (this.webSocketDocumentGeneration && this.webSocketDocumentGeneration.readyState === WebSocket.OPEN) {
        //   return;
        // }
    
        if (sessionToken) {
          localStorage.setItem('session_token', sessionToken);
        }
        this.shouldReconnect = true; // Allow reconnection
        this.initWebSocketDocumentGeneration(uniqueId);
    }

    public openSocketReportGeneration(uniqueId: string, sessionToken?: string) {
        // if (this.webSocketReportGeneration && this.webSocketReportGeneration.readyState === WebSocket.OPEN) {
        //   return;
        // }
    
        if (sessionToken) {
          localStorage.setItem('session_token', sessionToken);
        }
        this.shouldReconnect = true; // Allow reconnection
        this.initWebSocketReportGeneration(uniqueId);
    }

    public openSocketMatter(sessionToken?: string) {
        if (this.webSocketMatter && this.webSocketMatter.readyState === WebSocket.OPEN) {
          return;
        }
    
        if (sessionToken) {
          localStorage.setItem('session_token', sessionToken);
        }
        this.shouldReconnect = true; // Allow reconnection
        this.initWebSocketMatter();
    }

    public openSocketReports(sessionToken?: string) {
        if (this.webSocketReports && this.webSocketReports.readyState === WebSocket.OPEN) {
          return;
        }
    
        if (sessionToken) {
          localStorage.setItem('session_token', sessionToken);
        }
        this.shouldReconnectReports = true; // Allow reconnection
        this.initWebSocketReports();
    }

    // waitForConnection(): Promise<void> {
    //     return this.connectionPromise;
    // }

    /**
     * This function is used to close the socket data
     */
    public closeSocketMatterTE(): void {
        this.shouldReconnect = false; // Prevent reconnection
        if (this.webSocketMatter) {
          this.webSocketMatter.close();
          this.webSocketMatter = null;
        }
    }

    public closeSocketReports(): void {
        this.shouldReconnectReports = false; // Prevent reconnection
        if (this.webSocketReports) {
          this.webSocketReports.close();
          this.webSocketReports = null;
        }
    }
    
    /**
     * This function is used to check web socket conect or not.
     */
    public isWebSocketConnected(): boolean {
        return this.webSocketMatter?.readyState === WebSocket.OPEN;
    }


    public isWebSocketReportsConnected(): boolean {
        return this.webSocketReports?.readyState === WebSocket.OPEN;
    }

    // async closeSocket(SessionToken, APIEndpoint, message?) {
        // await new Promise<void>(async (next, reject) => {
        //     this.socket.on(message, (data) => {
        //         next();
        //     })
        // });
        // if (this.socket && this.socket.hasListeners(message)) {
        //     // Remove the event listener for 'specific-event'
        //     this.socket.off(message);
        // }
        // this.socket.disconnect();
    // }

    /**
     * This function is used to close the Socket
     * @param SessionToken -session token
     * @param APIEndpoint -APIEnd point
     */
    async closeSocket(SessionToken, APIEndpoint) {
        await new Promise<void>(async (next, reject) => {
            await this.webSocket.send(
                " unwatch channel " + SessionToken + " " + APIEndpoint
            );
            await this.webSocket.close();
            this.webSocket.onclose = await function (event) {
                if (event.wasClean) {
                    next();
                } else {
                    // e.g. server process killed or network down
                    // event.code is usually 1006 in this case

                    next();
                }
            };
        });
    }

    /**
     * This function is used to open the WS connection data value.
     */
    openWSConnection(SessionToken, APIEndpoint, message?): Observable<any> {
        // return new Observable<any>(observer => {
        //     try {
        //         this.socket.on(message, (data) => {
        //             observer.next(data);
        //         });
        //         this.socket.on('error', (error) => {
        //             console.error('Socket error:', error);
        //             // You can handle the error here, such as displaying an error message to the user or performing other error-handling logic
        //         });
        //         return () => { this.socket.disconnect(); };  
        //     } catch(exception) {
        //         console.error(exception);
        //     }
        // });
        this.webSocket = new WebSocket(environment.MainSocketUrl);
        // const queryParams = `?SessionToken=${this.currentUser.SessionToken}`;
        return new Observable<any>((observer) => {
            try {
                let demoSoket = this.webSocket;
                demoSoket.onopen = (openEvent) => {
                    // demoSoket.send(
                    //     "watch channel " + this.currentUser.SessionToken + " " + APIEndpoint
                    // );
                    demoSoket.send(
                        "watch channel " + SessionToken + " " + APIEndpoint
                    );
                    if (APIEndpoint == "NetAccountsSyncStatus") {
                        observer.next("connected");
                    }
                };
                demoSoket.onclose = (closeEvent) => {
                    observer.next(100);
                };
                demoSoket.onerror = (errorEvent) => {
                };
                // demoSoket.onmessage = (messageEvent) => {
                //     console.log('messageEvent',messageEvent);
                //     let data = JSON.parse(messageEvent.data);
                //     console.log('data',data);
                //     // let details = JSON.parse(data.body);
                //     let details = data.body;
                //     observer.next(details);
                // };
                demoSoket.onmessage = (messageEvent) => {
                    let data = JSON.parse(messageEvent.data);
                    let details = JSON.parse(data.value);
                    observer.next(details);
                };
            } catch (exception) {
                console.error(exception);
            }
        });
    }

    // openWSConnectionMatter(SessionToken?): Observable<any> {
    //     const queryParams = `?SessionToken=${this.sessionToken ? this.sessionToken : SessionToken}`;
    //     console.log('queryParams',queryParams);
    //     // this.webSocketMatter = new WebSocket(environment.MainSocketMatterUrl + queryParams);
    //     let demoSoket = this.webSocketMatter;
    //     return new Observable<any>((observer) => {
    //         try {
    //             // console.log('demoSoket',demoSoket);
    //             // demoSoket.onopen = (e) => {
    //             //     console.log('openEvent',e);
    //             //     // this.openWSConnection(SessionToken);
    //             //     // demoSoket.send(
    //             //     //     "watch channel " + queryParams + " " + 'Matter-DataChange'
    //             //     // );
    //             //     observer.next();
    //             // };
    //             demoSoket.onclose = (closeEvent) => {
    //                 observer.next(100);
    //             };
    //             demoSoket.onerror = (errorEvent) => {
    //             };
    //             demoSoket.onmessage = (messageEvent) => {
    //                 let data = JSON.parse(messageEvent.data);
    //                 let details = JSON.parse(data.message);
    //                 console.log('details',details);
    //                 observer.next(details);
    //                 // this.behaviorService.matterChangeValues(details);
    //             };
    //         }
    //         catch (exception) {
    //             console.error(exception);
    //         }
    //     });
    // }

    // openWSConnectionMatter(): Observable<any> {
    //     return new Observable<any>((observer) => {
    //         const demoSocket = this.webSocketMatter;
    
    //         demoSocket.onclose = (closeEvent) => {
    //             console.log("WebSocket closed:", closeEvent);
    //             observer.next();
    //         };
    
    //         demoSocket.onerror = (errorEvent) => {
    //             console.error("WebSocket error:", errorEvent);
    //             observer.error(errorEvent);
    //         };
    
    //         demoSocket.onmessage = (messageEvent) => {
    //             try {
    //                 console.log("Message received:", messageEvent.data);
    //                 const data = JSON.parse(messageEvent.data);
    //                 const details = JSON.parse(data.message);
    //                 console.log('Received details:', details);
    //                 observer.next(details);
    //             } catch (error) {
    //                 console.error("Error parsing message data:", error);
    //                 observer.error(error);
    //             }
    //         };
    
    //         return () => {
    //             console.log("Unsubscribing and closing WebSocket.");
    //             demoSocket.close();
    //         };
    //     });
    // }

    // openWSConnectionMatter(SessionToken, APIEndpoint): Observable<any> {
    //     const queryParams = `?SessionToken=${this.sessionToken}`;
    //     this.webSocketMatter = new WebSocket(environment.MainSocketMatterUrl + queryParams);
    //     console.log('this.webSocketMatter',this.webSocketMatter);
    //     return new Observable<any>((observer) => {
    //         try {
    //             this.webSocketMatter.onopen = (openEvent) => {
    //                 console.log('openEvent',openEvent);
    //                 observer.next();
    //             };
    //             this.webSocketMatter.onclose = (closeEvent) => {
    //                 observer.next(100);
    //             };
    //             this.webSocketMatter.onerror = (errorEvent) => {
    //             };
    //             this.webSocketMatter.onmessage = (messageEvent) => {
    //                 console.log('messageEvent',messageEvent);
    //                 let data = JSON.parse(messageEvent.data);
    //                 console.log('data',data);
    //                 let details = JSON.parse(data.message);
    //                 observer.next(details);
    //             };
    //         } catch (exception) {
    //             console.error(exception);
    //         }
    //     });
    // }
}
